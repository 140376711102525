import { compressState, decompressState } from '@power/power-components/utils/state-compression';

class Route {
  constructor(routeName = '', routeLink = '', routeTail = '', stateConfig = '', routeModule = '') {
    this.routeName = routeName;
    this.routeLink = routeLink;
    this.routeTail = routeTail;
    this.stateConfig = stateConfig;
    this.routeModule = routeModule;

    /* FEAT: Save & Share */
    this.userReportId = '';
    this.userReportName = '';
    this.userReportCanEdit = true;
    this.userReportConfig = null;
    this.userReportCreatedBy = '';
    this.userReportCreatedAt = new Date();
  }
}

class State {
  constructor() {
    this.routeList = [];
    this.hasUpdate = false;
    this.obligatoryUpdate = false;
    this.nextRouteName = null;
    this.showHelp = null;
    this.applicationType = '';
    this.operationalSystem = '';
  }

  get storageId() {
    return 'stateReducer';
  }

  get storageType() {
    return 'sessionStorage';
  }

  get actions() {
    return {
      newRoute: 'NEW_ROUTE',
      nextRoute: 'NEXT_ROUTE',
      updateRoute: 'UPDATE_ROUTE',
      previousRoute: 'PREVIOUS_ROUTE',
      clearRouteList: 'CLEAR_ROUTE_LIST',
      nextRouteSingle: 'NEXT_ROUTE_SINGLE',
      updateUsageInformation: 'UPDATE_USAGE_INFORMATION',
    };
  }

  static NEW_ROUTE(state, data) {
    if ('routeName' in data) {
      window.dataLayer.push({ page_title: data.routeSubName ? data.routeSubName : data.routeName });
    }

    if (
      'routeName' in data &&
      'routeLink' in data &&
      'routeTail' in data &&
      'stateConfig' in data &&
      'routeModule' in data
    ) {
      return Object.assign(new State(), state, {
        routeList: [
          new Route(
            data.routeName,
            data.routeLink,
            data.routeTail,
            data.stateConfig,
            data.routeModule,
          ),
        ],
      });
    }

    if (
      'routeName' in data &&
      'routeLink' in data &&
      'routeTail' in data &&
      'stateConfig' in data
    ) {
      return Object.assign(new State(), state, {
        routeList: [
          new Route(
            data.routeName,
            data.routeLink,
            data.routeTail,
            data.stateConfig,
            data.routeModule,
          ),
        ],
      });
    }
    return state;
  }

  static NEXT_ROUTE(state, data) {
    if ('routeName' in data) {
      window.dataLayer.push({ page_title: data.routeSubName ? data.routeSubName : data.routeName });
    }

    if (
      'routeName' in data &&
      'routeLink' in data &&
      'routeTail' in data &&
      'stateConfig' in data &&
      'routeModule' in data
    ) {
      return Object.assign(new State(), state, {
        routeList: state.routeList.concat(
          new Route(
            data.routeName,
            data.routeLink,
            data.routeTail,
            data.stateConfig,
            data.routeModule,
          ),
        ),
      });
    }

    if (
      'routeName' in data &&
      'routeLink' in data &&
      'routeTail' in data &&
      'stateConfig' in data
    ) {
      return Object.assign(new State(), state, {
        routeList: state.routeList.concat(
          new Route(data.routeName, data.routeLink, data.routeTail, data.stateConfig),
        ),
      });
    }

    if ('routeName' in data && 'routeLink' in data && 'stateConfig' in data) {
      return Object.assign(new State(), state, {
        routeList: state.routeList.concat(new Route(data.routeName, data.routeLink, null, null)),
      });
    }
    return state;
  }

  static NEXT_ROUTE_SINGLE(state, data) {
    if ('routeName' in data) {
      window.dataLayer.push({ page_title: data.routeSubName ? data.routeSubName : data.routeName });
    }

    if ('routeName' in data && 'routeLink' in data && 'stateConfig' in data) {
      state.nextRouteSingle = true;

      return Object.assign(new State(), state, {
        routeList: [new Route(data.routeName, data.routeLink, null, data.stateConfig)],
      });
    }

    return state;
  }

  static UPDATE_ROUTE(state, data) {
    return Object.assign(new State(), state, data);
  }

  static PREVIOUS_ROUTE(state, data) {
    if ('index' in data) {
      const routeList = state.routeList.splice(0, data.index + 1);
      const route = routeList[routeList.length - 1];

      window.dataLayer.push({
        page_title: route.stateConfig?.routeSubName
          ? route.stateConfig?.routeSubName
          : route.routeName,
      });

      return Object.assign(new State(), state, {
        routeList,
      });
    }

    return state;
  }

  static CLEAR_ROUTE_LIST(state) {
    return Object.assign(new State(), state, { routeList: [] });
  }

  static UPDATE_USAGE_INFORMATION(state, data) {
    if ('applicationType' in data && 'operationalSystem' in data) {
      return Object.assign(new State(), state, {
        applicationType: data.applicationType,
        operationalSystem: data.operationalSystem,
      });
    }

    return state;
  }
}

class Session {
  constructor() {
    this.refreshFilter = false;
    this.qtdUser = '';
    this.loginId = '';
    this.clientId = '';
    this.application = '';
    this.mapProvider = '';
    this.updateLocalStorage = false;
    this.needSelectAccount = false;
    this.keepSession = false;
    this.token = '';
    this.isAdm = false;
    this.isTrip = false;
    this.isSingleSignon = false;
    this.userId = '';
    this.userEmail = '';
    this.userName = '';
    this.companyName = '';
    this.permissions = [];
    this.modules = [];
    this.userPhoto = '/assets/images/user-photo-placeholder.svg';
    this.headerIcon = '/assets/images/telemetry-icon.svg';
    this.headerLogo = '/assets/images/golfleet.svg';
    this.headerIconAllowColorChange = true;
    this.tradeMarkIcon = '/assets/images/golfleet-icon.svg';
    this.applicationName = 'Telemetria';
    this.applicationNameVisibility = true;
    this.deviceId = '';
    this.hasUpdate = false;
    this.hasRequiredUpdate = false;
    this.hasChangeToken = false;
    this.navigationList = [];
    this.adminNavigationList = [];
    this.onAdmNavigationMode = false;
    this.otpActive = false;
    this.otpClientRequest = false;
    this.otpBlockSwitch = false;
    this.themeColors = {
      primaryColor: '#B400FF',
      primaryTextColor: '#FFF',
      headerColor: '#FFF',
      headerLogoColor: '#B400FF',
      headerTextColor: '#949494',
      headerBadgeColor: '#B400FF',
      navigationColor: '#9300D0',
      navigationDarkColor: '#9300D0',
      navigationLightColor: '#B400FF',
      navigationSelectedColor: 'rgba(0, 0, 0, 0.1)',
      navigationTextColor: '#FFF',
      navigationTextDarkColor: '#62008B',
      navigationTextLightColor: '#FFF',
      navigationTextSelectedColor: '#FFF',
      navigationAdminColor: '#282828',
      navigationAdminDarkColor: '#202020',
      navigationAdminLightColor: '#323232',
      navigationAdminTextColor: 'rgba(255, 255, 255, 0.66)',
      navigationAdminTextLightColor: '#FFF',
    };
    this.privacyPolicyStatus = {
      id: 0,
      url: '',
      version: '',
      needUserAccept: false,
    };
    this.isVideoTelemetry = false;
    this.trainingMode = false;
    this.userDefaultScreen = null;
    this.userDefaultActiveView = null;
    this.fleetCamProvider = null;
  }

  get storageId() {
    return 'sessionReducer';
  }

  get storageType() {
    if (this._storageType !== 'sessionStorage' && this._storageType !== 'localStorage') {
      return 'sessionStorage';
    }

    return this._storageType;
  }

  set storageType(type) {
    if (type === 'sessionStorage' || type === 'localStorage') this._storageType = type;
  }

  get actions() {
    return {
      signin: 'SIGNIN',
      signout: 'SIGNOUT',
      singleSignon: 'SINGLE_SIGNON',
      appUpdated: 'APP_UPDATED',
      appHasUpdate: 'APP_HAS_UPDATE',
      appHasPrivacyPolicyUpdate: 'APP_HAS_PRIVACY_POLICY_UPDATE',
      newUserPhoto: 'NEW_USER_PHOTO',
      toggleNavigationMode: 'TOGGLE_NAVIGATION_MODE',
      appHasChangeToken: 'APP_HAS_CHANGE_TOKEN',
      updateNavigationMenu: 'UPDATE_NAVIGATION_MENU',
      updateIsAdm: 'UPDATE_IS_ADM',
      updateQuantityUser: 'UPDATE_QUANTITY_USER',
      updatePemissions: 'UPDATE_PERMISSIONS',
      updateModules: 'UPDATE_MODULES',
      updateMapProvider: 'UPDATE_MAP_PROVIDER',
      updateUserReportQuantity: 'UPDATE_USER_REPORT_QUANTITY',
      updateUserReportSharedQuantity: 'UPDATE_USER_REPORT_SHARED_QUANTITY',
      updateUserAutoSendQuantity: 'UPDATE_USER_AUTO_SEND_QUANTITY',
      isVideoTelemetry: 'UPDATE_IS_VIDEOTELEMETRY',
      updateCompanyIconAllowColorChange: 'UPDATE_HEADER_ICON_ALLOW_COLOR_CHANGE',
      updateUserDefaultScreen: 'UPDATE_USER_DEFAULT_SCREEN',
      fleetCamProvider: 'UPDATE_FLEET_CAM_PROVIDER',
      otpActive: 'UPDATE_OTP_ACTIVE',
    };
  }

  static SIGNIN(state, data) {
    if ('application' in data) {
      window.dataLayer.push({
        application: data.application,
      });
    }
    if ('companyName' in data) {
      window.dataLayer.push({
        company_name: data.companyName,
      });
    }
    if ('isAdm' in data) {
      window.dataLayer.push({
        user_type: data.isAdm ? 'Admin' : 'Common',
      });
    }
    if ('userEmail' in data) {
      window.dataLayer.push({
        user: data.userEmail,
      });
    }

    return Object.assign(new Session(), state, data, {
      storageType: data.keepSession && data.updateLocalStorage ? 'localStorage' : 'sessionStorage',
    });
  }

  static SIGNOUT(state) {
    return Object.assign(new Session(), { isSingleSignon: state.isSingleSignon });
  }

  static SINGLE_SIGNON(state, data) {
    if ('isSingleSignon' in data) return Object.assign(new Session(), state, data);
    return state;
  }

  static APP_UPDATED(state) {
    return Object.assign(state, { hasUpdate: false, hasRequiredUpdate: false });
  }

  static APP_HAS_UPDATE(state, data) {
    if ('hasRequiredUpdate' in data)
      return Object.assign(new Session(), state, data, { hasUpdate: true });
    return state;
  }

  static APP_HAS_PRIVACY_POLICY_UPDATE(state, data) {
    if ('privacyPolicyStatus' in data) {
      const { privacyPolicyStatus } = data;

      if (!!privacyPolicyStatus && 'version' in privacyPolicyStatus) {
        return Object.assign(new Session(), state, { privacyPolicyStatus });
      }
    }
    return state;
  }

  static APP_HAS_CHANGE_TOKEN(state, data) {
    if ('hasChangeToken' in data) return Object.assign(new Session(), state, data);
    return state;
  }

  static NEW_USER_PHOTO(state, data) {
    if ('userPhoto' in data) return Object.assign(new Session(), state, data);
    return state;
  }

  static TOGGLE_NAVIGATION_MODE(state) {
    return Object.assign(new Session(), state, {
      onAdmNavigationMode: !state.onAdmNavigationMode,
    });
  }

  static UPDATE_NAVIGATION_MENU(state, data) {
    const { adminNavigationList, navigationList } = data || {};
    if (!!adminNavigationList && !!navigationList) {
      return Object.assign(new Session(), state, {
        adminNavigationList,
        navigationList,
      });
    }
    return state;
  }

  static UPDATE_QUANTITY_USER(state, data) {
    if ('qtdUser' in data) return Object.assign(new Session(), state, data);
    return state;
  }

  static UPDATE_IS_ADM(state, data) {
    if ('isAdm' in data) return Object.assign(new Session(), state, data);
    return state;
  }

  static UPDATE_PERMISSIONS(state, data) {
    if ('permissions' in data) return Object.assign(new Session(), state, data);
    return state;
  }

  static UPDATE_MODULES(state, data) {
    if ('modules' in data) return Object.assign(new Session(), state, data);
    return state;
  }

  static UPDATE_MAP_PROVIDER(state, data) {
    if ('mapProvider' in data) {
      const { mapProvider } = data;
      return Object.assign(new Session(), state, { mapProvider });
    }
    return state;
  }

  static UPDATE_USER_REPORT_QUANTITY(state, data) {
    if ('userReportQuantity' in data) return Object.assign(new Session(), state, data);
    return state;
  }

  static UPDATE_USER_REPORT_SHARED_QUANTITY(state, data) {
    if ('userReportSharedQuantity' in data) return Object.assign(new Session(), state, data);
    return state;
  }

  static UPDATE_USER_AUTO_SEND_QUANTITY(state, data) {
    if ('userAutoSendQuantity' in data) return Object.assign(new Session(), state, data);
    return state;
  }

  static UPDATE_IS_VIDEOTELEMETRY(state, data) {
    if ('isVideoTelemetry' in data) return Object.assign(new Session(), state, data);
    return state;
  }

  static UPDATE_HEADER_ICON_ALLOW_COLOR_CHANGE(state, data) {
    if ('headerIconAllowColorChange' in data) return Object.assign(new Session(), state, data);
    return state;
  }

  static UPDATE_USER_DEFAULT_SCREEN(state, data) {
    const { userDefaultScreen, userDefaultActiveView } = data || {};
    return Object.assign(new Session(), state, {
      userDefaultScreen,
      userDefaultActiveView,
    });
  }

  static UPDATE_FLEET_CAM_PROVIDER(state, data) {
    if ('fleetCamProvider' in data) return Object.assign(new Session(), state, data);
    return state;
  }

  static UPDATE_OTP_ACTIVE(state, data) {
    if ('otpActive' in data) return Object.assign(new Session(), state, data);
    return state;
  }
}

function getState(state) {
  const localState = window.localStorage.getItem(state.storageId);
  const sessionState = window.sessionStorage.getItem(state.storageId);

  const localCompressionLevel = window.localStorage.getItem(`${state.storageId}-compression-level`);
  const sessionCompressionLevel = window.sessionStorage.getItem(
    `${state.storageId}-compression-level`,
  );

  if (sessionState)
    return Object.assign(state, decompressState(sessionState, sessionCompressionLevel));
  if (localState) {
    window.sessionStorage.setItem(state.storageId, localState);
    window.sessionStorage.setItem(`${state.storageId}-compression-level`, localCompressionLevel);
    return Object.assign(state, decompressState(localState, localCompressionLevel));
  }

  return state;
}

function createReducer(StateClass) {
  return (state, action) => {
    if (!state) return getState(new StateClass());

    if (action.type in StateClass) {
      const newState = StateClass[action.type](state, action.data);
      const { result: compressedState, level: compressionLevel } = compressState(newState);

      window[newState.storageType].setItem(newState.storageId, compressedState);

      window[newState.storageType].setItem(
        `${newState.storageId}-compression-level`,
        compressionLevel,
      );

      if (action.type === 'SIGNOUT') {
        if (window.localStorage.getItem(newState.storageId)) {
          window.localStorage.setItem(newState.storageId, compressedState);
        }
        if (window.localStorage.getItem(`${newState.storageId}-compression-level`)) {
          window.localStorage.setItem(`${newState.storageId}-compression-level`, compressionLevel);
        }
      }

      return newState;
    }

    return state;
  };
}

const stateReducer = createReducer(State);
const sessionReducer = createReducer(Session);

export { stateReducer, sessionReducer };
