import angular from 'angular';

import '../power-dropdown/power-dropdown';
import '../power-period/power-period';
import { PowerFilterController, PowerFilter } from '../power-filter/power-filter';

import template from './power-filter-period.html';
import './power-filter-period.scss';

class PowerFilterPeriodController extends PowerFilterController {
  static get $inject() {
    return ['$element', '$scope', '$http', 'urlApi', '$ngRedux'];
  }

  constructor($element, $scope, $http, urlApi, $ngRedux) {
    super($element, $scope, $http, urlApi, $ngRedux);

    this.activeView = 'period';
    this.__appInheritBehavior = $ngRedux.connect(behavior =>
      Object({
        isTrip: behavior.session.isTrip,
      }),
    )(this);
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
    Object.assign(this.$, { loading: true });
  }
  /* */

  /* Private */
  _formatDescription() {
    if (!this.isTrip) {
      // Only for NON-TRIP
      const selectedOption = this._getSelectedOption();
      this.description = this._formatDescriptionWords(
        selectedOption ? selectedOption.description : 'Período customizado',
      );
    } else {
      const periodElement = this.$.querySelector('power-period');
      const values = periodElement.getValue();

      const isFull = values.every(
        value => Number(value).toString(2) === String(1).repeat(periodElement.getPeriodType()),
      );

      this.description = isFull ? 'Qualquer período' : 'Período customizado';
    }
  }

  _initializeFilter() {
    this.$.loading = false;

    if (this.condition) {
      this.$.querySelector('power-period').setValue(this.condition.value);
    } else if (this.default) {
      this.$.querySelector('power-period').setValue(this.default);
    }
  }

  _getOptions() {
    return this.$http({
      url: `${this.urlApi}/${this.requestApi}`,
      method: 'POST',
      data: { ...this.requestData },
    }).then(
      success => {
        if (success.data && success.data.data) {
          success.data.data.forEach(item => {
            if (item.type && item.bits) {
              // Only for NON-TRIP
              const optionId = item.type == 1 ? 5 : 4;
              const optionValue = item.bits.split(',');
              if (this.options.filter(option => option.id == optionId).length == 0) {
                if (!this.isTrip) {
                  this.options = this.options.concat({
                    id: optionId,
                    value: optionValue,
                    description:
                      optionId == 4 ? 'Dentro do horário comercial' : 'Fora do horário comercial',
                  });
                }
              }

              this.options.sort((a, b) => a.id - b.id);
            } else if (this.options.filter(option => option.id == item.id).length == 0) {
              // Only for TRIP
              this.options = this.options.concat({
                id: item.id,
                value: item.extra.split(','),
                description: item.description,
              });

              this.options.sort((a, b) => a.description - b.description);
            }
          });
        }

        this._initializeFilter();
      },
      () => {
        this.options = [];
        this.$.querySelector('power-period').setValue([]);
        this._initializeFilter();
      },
    );
  }

  _getSelectedOption() {
    return this.options.find(option => option.selected);
  }

  _selectOption(selected) {
    // Only for NON-TRIP
    if (!selected) {
      this.options = this.options.map(option => Object.assign(option, { selected: !option.value }));
      return;
    }

    this.options = this.options.map(option =>
      Object.assign(option, { selected: option.id == selected.id }),
    );
    this.$.querySelector('power-period').setValue(selected.value);
  }

  _presetOptionSelection(selected) {
    this._changeActiveView('period');

    if (!selected) {
      this.options = this.options.map(option => Object.assign(option, { selected: !option.value }));
      return;
    }

    this.options = this.options.map(option =>
      Object.assign(option, { selected: option.id == selected.id }),
    );
    this.$.querySelector('power-period').setValue(selected.value);
  }

  _togglePeriodMaxValue() {
    const periodElement = this.$.querySelector('power-period');
    periodElement.toggleMaxValueOptions();
  }

  _togglePeriodValue() {
    const periodElement = this.$.querySelector('power-period');
    periodElement.toggleValueOptions();
  }

  __periodValueChanged({ detail }) {
    const { value } = detail;
    this.setFilterValue(value);

    if (!this.isTrip) {
      this.options.forEach(item => {
        item.selected = (item.value || []).join() === value.join();
      });

      if (!this.options.find(item => item.selected)) {
        this.options
          .filter(item => item.value === null)
          .forEach(item => {
            item.selected = true;
          });
      }
    }

    this._formatDescription();
  }
  /* */
}

class PowerFilterPeriod extends PowerFilter {
  constructor() {
    super();
    Object.assign(this.bindings, {
      conditional: '=?',
    });
    this.template = template;
    this.controller = PowerFilterPeriodController;
  }
}

angular
  .module('power-filter-period', ['power-dropdown', 'power-period'])
  .component('powerFilterPeriod', new PowerFilterPeriod());

export { PowerFilterPeriodController, PowerFilterPeriod };
