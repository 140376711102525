import angular from 'angular';
import 'ng-redux';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-toolbar-report/power-toolbar-report';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-crud/power-crud';
import '@power/power-components/components/power-popup-share/power-popup-share';
import '@power/power-components/components/power-popup-delete/power-popup-delete';
import '@power/power-components/components/power-toast/power-toast';
import '../golfleet-grid-driver/golfleet-grid-driver';
import '../golfleet-popup-driver-privacy-policy/golfleet-popup-driver-privacy-policy';
import '../golfleet-popup-golfleet-id-privacy-policy/golfleet-popup-golfleet-id-privacy-policy';
import { PowerReportDriversController } from '@power/power-components/components/power-report-drivers/power-report-drivers';

import template from './golfleet-report-drivers.html';
import './golfleet-report-drivers.scss';

class GolfleetReportDriversController extends PowerReportDriversController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );
  }

  /* Lifecycle */
  /* */

  /* Private */
  _onClickImportButton(importOption) {
    this.statusImport = 0;
    this.importOption = importOption;

    const mainReport = this.stateConfig.customReportId;

    if (mainReport && mainReport == 'mainDrivers') {
      if (
        this.modules.includes('IDENTIFICACAO_IA') ||
        this.modules.includes('IDENT_BLUETOOTH_BUZZER') ||
        this.modules.includes('IDENT_BLUETOOTH_DESBLOQUEIO')
      ) {
        this.typeByModule = 'GOLFLEETID';
      } else {
        this.typeByModule = 'APPDRIVER';
      }
    } else {
      this.typeByModule = 'DEFAULT';
    }

    if (
      (this.typeByModule == 'APPDRIVER' || this.typeByModule == 'GOLFLEETID') &&
      this.importOption == 0
    ) {
      this._privacyPolicyAppDriverOpen();
    } else {
      this._evtCallPopupLink();
    }
  }

  _privacyPolicyAppDriverOpen() {
    const privacyPolicyPopup = this.$.querySelector('#golfleet-popup-driver-privacy-policy-report');

    if (privacyPolicyPopup) {
      privacyPolicyPopup.show();
    }
  }

  _privacyPolicyGolfleetIdOpen() {
    const privacyPolicyGidPopup = this.$.querySelector(
      '#golfleet-popup-golfleet-id-privacy-policy-report',
    );

    if (privacyPolicyGidPopup) {
      privacyPolicyGidPopup.show();
    }
  }

  _evtCallPopupLink() {
    if (this.typeByModule == 'GOLFLEETID') {
      const fieldModelDownload =
        this.stateConfig.toolbarConfig.importConfig[this.importOption].popup.body.fields[2];
      if (
        fieldModelDownload &&
        fieldModelDownload.fileUrlGid &&
        fieldModelDownload.name == 'Download de modelo'
      ) {
        fieldModelDownload.fileUrl = fieldModelDownload.fileUrlGid;
        fieldModelDownload.fileHint = fieldModelDownload.fileHintGid;
      }
    }

    this.$.querySelector('#popup-import').toggle();
  }

  _evtCallPopupDriverAfterClose() {
    if (this.typeByModule == 'GOLFLEETID') {
      this._privacyPolicyGolfleetIdOpen();
    } else {
      this._evtCallPopupLink();
    }
  }
  /* */
}

class GolfleetReportDrivers {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetReportDriversController;
  }
}

angular
  .module('golfleet-report-drivers', [
    'ngRedux',
    'power-fab',
    'power-dropdown',
    'power-toolbar-report',
    'golfleet-grid-driver',
    'power-footer',
    'power-pagination',
    'power-popup',
    'power-crud',
    'power-popup-share',
    'power-popup-delete',
    'golfleet-popup-driver-privacy-policy',
    'golfleet-popup-golfleet-id-privacy-policy',
    'power-toast',
  ])
  .component('golfleetReportDrivers', new GolfleetReportDrivers());
