import angular from 'angular';
import 'ng-redux';

import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-toast/power-toast';
import '@power/power-components/components/power-toolbar/power-toolbar';
import '@power/power-components/components/power-crud/power-crud';
import '@power/power-components/components/power-accordion/power-accordion';
import { isValidEmail } from '@power/power-components/utils/email-util.js';
import '../golfleet-popup-driver-privacy-policy/golfleet-popup-driver-privacy-policy';
import '../golfleet-popup-golfleet-id-privacy-policy/golfleet-popup-golfleet-id-privacy-policy';
import '../golfleet-popup-golfleet-id-app-invite/golfleet-popup-golfleet-id-app-invite';
import '../golfleet-popup-driver-app-invite/golfleet-popup-driver-app-invite';
import { PowerRecordDriverController } from '@power/power-components/components/power-record-driver/power-record-driver';

import template from './golfleet-record-driver.html';
import './golfleet-record-driver.scss';

/**
 * Items Type (Enum)
 */
export const GolfleetIdInviteStatusEnum = {
  ACCEPTED: 1,
  REVOKED: 2,
  SENT: 3,
  NOT_SENT: 4,
};

class GolfleetRecordDriverController extends PowerRecordDriverController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$state',
      '$scope',
      '$http',
      'recordServices',
      'urlApi',
      'driverServices',
    ];
  }

  // eslint-disable-next-line no-useless-constructor
  constructor($element, $ngRedux, $state, $scope, $http, recordServices, urlApi, driverServices) {
    super($element, $ngRedux, $state, $scope, $http, recordServices, urlApi, driverServices);
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();

    this.$scope.$watch(() => this.stateData, this._onStateDataChanged.bind(this));
  }
  /* */

  /* Private */
  async _evtClickBtnPopup(type, popupId, dataset) {
    if (type == 'close') {
      switch (popupId) {
        case 'popup-driver-invite': {
          this.$.querySelector('#popup-driver-invite').toggle();
          this._getDataset();
          break;
        }
        case 'popup-golfleetId-invite': {
          this.$.querySelector('#popup-driver-invite').toggle();

          if (!this.stateData.hasActiveIa && this.modules.includes('IDENTIFICACAO_IA')) {
            await this._toggleDriverIa(true, false);
          }

          this._getDataset();

          break;
        }
        default: {
          super._evtClickBtnPopup(type, popupId, dataset);
          break;
        }
      }
    } else if (type == 'primaryBtn') {
      switch (popupId) {
        case 'popup-driver-invite': {
          if (!this.stateData.hasLoginApp) {
            this._sendInvite('driver');
          } else {
            this._removeAccessApp();
          }
          break;
        }
        case 'popup-golfleetId-invite': {
          if (!this.stateData.hasGolfleetIdLoginApp) {
            this._sendInvite('golfleetid');
          } else {
            this._removeAccessAppGolfleetId();
          }
          break;
        }
        default: {
          super._evtClickBtnPopup(type, popupId, dataset);
          break;
        }
      }
    } else if (type == 'cancel') {
      switch (popupId) {
        case 'popup-driver-invite': {
          this.$.querySelector('#popup-driver-invite').toggle();
          break;
        }
        case 'popup-golfleetId-invite': {
          this.$.querySelector('#popup-driver-invite').toggle();
          break;
        }
        default: {
          super._evtClickBtnPopup(type, popupId, dataset);
          break;
        }
      }
    } else {
      super._evtClickBtnPopup(type, popupId, dataset);
    }
  }

  _actionFunction(itemReturn) {
    switch (itemReturn.actionType) {
      case 'driverInvite': {
        this.driverEmail = this.stateData.email || '';
        this.driverEmailWarning = '';
        this.popupContent = this.stateData.hasLoginApp ? 2 : 0;
        this.golfleetIdInvite = false;
        this._privacyPolicyOpen();
        break;
      }
      case 'golfleetIdInvite': {
        this.driverEmail = this.stateData.email || '';
        this.driverEmailWarning = '';
        this.popupContent = this.stateData.hasGolfleetIdLoginApp ? 2 : 0;
        this.golfleetIdInvite = true;
        this._privacyPolicyOpen();
        break;
      }
      case 'removeAndReindex': {
        this.$.querySelector('power-photo-indexation-stand-alone').removeAndReindex();
        this._toggleRemoveImagePopup();
        break;
      }

      case 'popupGolfleetId': {
        this._evtCallPopupGolfleetIdAppInvite(1);
        break;
      }
      case 'popupDriverApp': {
        this._evtCallPopupGolfleetDriverAppInvite();
        break;
      }

      case 'bluetoothVehicleQty': {
        this._evtCallPopupGolfleetIdAppInvite(2);
        break;
      }
      case 'linkIaVehicleQty': {
        this._evtCallPopupGolfleetIdAppInvite(3);
        break;
      }

      default:
        super._actionFunction(itemReturn);
        break;
    }
  }

  _privacyPolicyOpen() {
    let privacyPolicyPopup = null;

    if (this.golfleetIdInvite) {
      privacyPolicyPopup = this.$.querySelector('#golfleet-popup-golfleet-id-privacy-policy');
    } else {
      privacyPolicyPopup = this.$.querySelector('#golfleet-popup-driver-privacy-policy');
    }

    if (privacyPolicyPopup) {
      privacyPolicyPopup.show();
    }
  }

  _evtCallPopupGolfleetDriverAppInvite() {
    const popupDriver = this.$.querySelector('golfleet-popup-driver-app-invite');

    if (popupDriver) {
      popupDriver.toggle();
    }
  }

  _sendInvite(type) {
    if (!isValidEmail(this.driverEmail)) {
      this.driverEmailWarning = 'E-mail inválido';
      return;
    }

    const payload = {
      driverId: this.stateData.id,
      userLoginApp: this.driverEmail,
    };

    this._toggleLoader(true);

    if (type === 'driver') {
      this.driverServices
        .createUserAppDriver(payload)
        .then(
          success => {
            if (success.status && success.status !== 200) {
              if (success.data) {
                this.toastObj = {
                  text: success.data.data.toString(),
                };
              } else {
                this.toastObj = {
                  text: 'Erro ao enviar o convite. Tente novamente!',
                };
              }
              this.$.querySelector('#toast-obj').toggle(true);
            }
            if (success.data && !success.data.hasError) {
              this.popupContent = 1;
            }
          },
          error => {
            this.toastObj = {
              text: error.data.data[0],
            };
            this.$.querySelector('#toast-obj').toggle(true);
          },
        )
        .finally(() => {
          this._toggleLoader(false);
        });
    }

    if (type === 'golfleetid') {
      this.driverServices
        .createUserDriverId(payload)
        .then(
          success => {
            if (success.status && success.status !== 200) {
              if (success.data) {
                this.toastObj = {
                  text: success.data.data.toString(),
                };
              } else {
                this.toastObj = {
                  text: 'Erro ao enviar o convite. Tente novamente!',
                };
              }
              this.$.querySelector('#toast-obj').toggle(true);
            }
            if (success.data && !success.data.hasError) {
              this.popupContent = 1;
            }
          },
          error => {
            this.toastObj = {
              text: error.data.data[0],
            };
            this.$.querySelector('#toast-obj').toggle(true);
          },
        )
        .finally(() => {
          this._toggleLoader(false);
        });
    }
  }

  _removeAccessApp() {
    this._toggleLoader(true);

    const payload = { objects: [{ objectId: this.stateData.id }] };

    this.driverServices.removeAccessApp(payload).then(
      success => {
        if (!success.data.hasError) {
          this.toastObj = {
            text: 'Convite removido com sucesso!',
          };
          this.$.querySelector('#popup-driver-invite').toggle();
          this._getDataset();
        } else {
          this.toastObj = {
            text: 'Não foi possível remover o acesso!',
          };
          this.$.querySelector('#toast-obj').toggle(true);
          this._toggleLoader(false);
        }
      },
      () => {
        this.toastObj = {
          text: 'Não foi possível remover o acesso!',
        };
        this.$.querySelector('#toast-obj').toggle(true);
        this._toggleLoader(false);
      },
    );
  }

  _removeAccessAppGolfleetId() {
    this._toggleLoader(true);

    const payload = { objects: [{ objectId: this.stateData.id }] };

    this.driverServices.removeGolfleetIdAccessApp(payload).then(
      async success => {
        if (!success.data.hasError) {
          this.toastObj = {
            text: 'Convite removido com sucesso!',
          };
          this.$.querySelector('#popup-driver-invite').toggle();
          if (this.stateData.hasActiveIa && this.modules.includes('IDENTIFICACAO_IA')) {
            await this._toggleDriverIa(false, false);
          }
          this._getDataset();
        } else {
          this.toastObj = {
            text: 'Não foi possível remover o acesso!',
          };
          this.$.querySelector('#toast-obj').toggle(true);
          this._toggleLoader(false);
        }
      },
      () => {
        this.toastObj = {
          text: 'Não foi possível remover o acesso!',
        };
        this.$.querySelector('#toast-obj').toggle(true);
        this._toggleLoader(false);
      },
    );
  }

  _evtCallPopupEmail() {
    if (isValidEmail(this.driverEmail)) {
      this.mailIsReadOnly = true;
      this.popupConfig = [
        {
          title: 'Enviar Convite',
          subTitle: 'O condutor será convidado no email abaixo',
          action: 'primaryBtn',
        },
        {
          title: 'Convite enviado',
          subTitle: this.golfleetIdInvite
            ? 'O convite para a instalação do App Golfleet ID foi enviado para o seguinte email'
            : 'O convite para a instalação do App Golfleet Driver foi enviado para o seguinte email',
          action: 'close',
        },
        {
          title: this.golfleetIdInvite
            ? 'Remover acesso ao Golfleet ID?'
            : 'Remover acesso ao Golfleet Driver?',
          subTitle:
            'O usuário será desconectado do aplicativo mobile e não poderá mais efetuar o login. Tem certeza que deseja remover o acesso?',
          action: 'primaryBtn',
        },
      ];
    } else {
      this.mailIsReadOnly = false;
    }
    this.$.querySelector('#popup-driver-invite').toggle();
  }

  async _evtCallPopupGolfleetIdAppInvite(tab) {
    const popupGolfleetIdInvite = this.$.querySelector('golfleet-popup-golfleet-id-app-invite');

    if (popupGolfleetIdInvite) {
      popupGolfleetIdInvite.toggle(tab);
    }
  }

  _onStateDataChanged() {
    if (this.stateData) {
      const popupGolfleetIdInvite = this.$.querySelector('golfleet-popup-golfleet-id-app-invite');

      if (popupGolfleetIdInvite) {
        popupGolfleetIdInvite.setup(this.stateData?.email || '');
      }
    }
  }

  _onChangeDriverIaStatus(event) {
    event.preventDefault();
    this.$.querySelector('#popup-confirmation').toggle();
    this.confirmationPopup = {
      open: true,
      text: 'Identificação via IA',
      subtext: this.stateData.hasActiveIa
        ? 'Deseja realmente desativar a identificação por IA do condutor?'
        : 'Deseja realmente ativar a identificação por IA do condutor?',
      confirm: () => {
        this.$.querySelector('#popup-confirmation').toggle();
        this._toggleDriverIa(!this.stateData.hasActiveIa, true);
      },
      cancel: () => {
        this.$.querySelector('#popup-confirmation').toggle();
      },
    };
  }

  _toggleDriverIa(openTimeline, refreshPage) {
    const payload = {
      id: this.stateData.id,
      openTimeline,
    };

    return this.driverServices.toggleDriverIa(payload).then(
      success => {
        if (success.status && success.status !== 200) {
          this.toastObj = {
            text: `Erro ao ${
              this.stateData.hasActiveIa ? 'desativar' : 'ativar'
            } a identificação por IA. Tente novamente!`,
          };
          this.$.querySelector('#toast-obj').toggle(true);
        }
        if (success.data.data && !success.data.hasError) {
          this.toastObj = {
            text: `Identificação por IA ${
              this.stateData.hasActiveIa ? 'desativada' : 'ativada'
            } com sucesso!`,
          };
          this.$.querySelector('#toast-obj').toggle(true);

          if (refreshPage) {
            this._getDataset();
          }
        }
      },
      error => {
        this.toastObj = {
          text: error.data.data[0],
        };
        this.$.querySelector('#toast-obj').toggle(true);
      },
    );
  }

  _canSwitchDriverIa() {
    return ![GolfleetIdInviteStatusEnum.NOT_SENT, GolfleetIdInviteStatusEnum.REVOKED].includes(
      this.stateData?.statusAppPowerId || 0,
    );
  }
  /* */
}

class GolfleetDriverRecord {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetRecordDriverController;
  }
}

angular
  .module('golfleet-record-driver', [
    'ngRedux',
    'infinite-scroll',
    'ng-tippy',
    'power-dropdown',
    'power-footer',
    'power-popup',
    'power-toast',
    'power-toolbar',
    'power-crud',
    'power-accordion',
    'golfleet-popup-driver-privacy-policy',
    'golfleet-popup-golfleet-id-privacy-policy',
    'golfleet-popup-golfleet-id-app-invite',
    'golfleet-popup-driver-app-invite',
  ])
  .component('golfleetRecordDriver', new GolfleetDriverRecord());
