import angular from 'angular';

import template from './power-popup-data-limit-reached.html';
import './power-popup-data-limit-reached.scss';

class PowerPopupDataLimitReachedController {
  static get $inject() {
    return ['$element'];
  }

  constructor($element) {
    Object.assign(this, { $: $element[0] });
    this.resultLimit = 0;
  }

  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
      setup: this.setup.bind(this),
    });
  }

  toggle() {
    if (!this.$.hasAttribute('open')) this.$.setAttribute('open', '');
    else this.$.removeAttribute('open');
  }

  setup({ resultLimit }) {
    this.resultLimit = resultLimit;
  }
}

class PowerPopupDataLimitReached {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = PowerPopupDataLimitReachedController;
  }
}
angular
  .module('power-popup-data-limit-reached', [])
  .component('powerPopupDataLimitReached', new PowerPopupDataLimitReached());
