import angular from 'angular';

class InfiniteScrollLink {
  constructor($scope, element) {
    Object.assign(this, { $: element[0], $scope });

    this.$.addEventListener('scroll', this._onScroll.bind(this));

    this.$scope.$watch(() => this.$scope.limitIndex, this.__onLimitIndexChanged.bind(this));
    this.$scope.$watch(() => this.$scope.listLength, this.__onListLengthChanged.bind(this));
  }

  _onScroll() {
    this.$scope.limitIndex = this.$scope.limitIndex || 0;
    const scrollDistance = parseInt(
      this.$.scrollHeight - this.$.scrollTop - this.$.clientHeight,
      10,
    );
    if (this.$scope.listLength - this.$scope.displayLength > 0) {
      if (scrollDistance === 0 && this.$.scrollTop !== 0) {
        if (
          this.$scope.limitIndex + this.$scope.displayLength <=
          this.$scope.listLength - this.$scope.displayLength
        ) {
          this.$.scrollTop = 8;
          this.$scope.limitIndex += this.$scope.displayLength;
          this.$scope.$apply();
        } else if (
          this.$scope.limitIndex + this.$scope.displayLength >
          this.$scope.listLength - this.$scope.displayLength
        ) {
          this.$scope.limitIndex = this.$scope.listLength - this.$scope.displayLength;
          this.$scope.$apply();
        }
      } else if (scrollDistance !== 0 && this.$.scrollTop === 0) {
        const amountHidden = this.$scope.limitIndex - this.$scope.displayLength;
        if (amountHidden > 0) {
          this.$.scrollTop = this.$.scrollHeight - this.$.clientHeight - 8;
          this.$scope.limitIndex = amountHidden;
          this.$scope.$apply();
        } else if (amountHidden > -this.$scope.displayLength) {
          this.$.scrollTop = this.$.scrollHeight - this.$.clientHeight - 8;
          this.$scope.limitIndex = 0;
          this.$scope.$apply();
        }
      }
    }
  }

  __onLimitIndexChanged(newValue) {
    if (newValue === 0) this.$.scrollTop = 0;
  }

  __onListLengthChanged(newValue, oldValue) {
    if (newValue !== oldValue) {
      this.$scope.limitIndex = 0;
      this.$.scrollTop = 8;
    }
  }
}

class InfiniteScroll {
  static get $$ngIsClass() {
    return true;
  }

  constructor() {
    this.restrict = 'A';
    this.scope = {
      limitIndex: '=',
      listLength: '=',
      displayLength: '=',
    };
  }

  link($scope, element) {
    return new InfiniteScrollLink($scope, element);
  }
}

angular.module('infinite-scroll', []).directive('infiniteScroll', InfiniteScroll);
