import angular from 'angular';

import { FilterCondition } from '@power/power-components/components/power-filter/power-filter';
import '@power/power-components/components/power-toolbar/power-toolbar';
import '@power/power-components/components/power-popup-addon-request/power-popup-addon-request';
import { GetRouteConfig } from '../../configs/routes/routes.config';

import '@power/power-components/directives/trust-src/trust-src';

import template from './golfleet-dashboard-announcements.html';
import './golfleet-dashboard-announcements.scss';

/**
 * Creates an instance of DashboardAnnouncementsController.
 * @memberof DashboardAnnouncementsController
 */
class GolfleetDashboardAnnouncementsController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$state',
      '$scope',
      '$http',
      'dashboardServices',
      'commonServices',
      'filterServices',
      'scriptServices',
      '$sce',
      'urlApi',
      '$transitions',
    ];
  }

  constructor(
    $element,
    $ngRedux,
    $state,
    $scope,
    $http,
    dashboardServices,
    commonServices,
    filterServices,
    scriptServices,
    $sce,
    urlApi,
    $transitions,
  ) {
    Object.assign(this, {
      $: $element[0],
      $ngRedux,
      $state,
      $scope,
      $http,
      dashboardServices,
      commonServices,
      filterServices,
      scriptServices,
      $sce,
      urlApi,
      $transitions,
    });
    this.data = [];
    this.videoSearchText = '';
    this.videoList = [];
    this.playerConfig = {
      selectedTab: 'video',
      parentId: null,
      openedVideo: null,
      videoList: null,
    };
    this.vimeoApi = null;
    this.vimeoPlayer = null;
    this.youtubeApi = null;
    this.youtubePlayer = null;
    this.queryMobile = window.matchMedia('(max-width: 425px)');
    this.queryTablet = window.matchMedia('(max-width: 1024px)');
    this.toastDashboard = {};
    this.searchBarActive = false;
    this.hasActiveFilter = false;
    this.sortVideoOrder = 'asc';
    this.menuConfiguration = null;

    this.__appBehavior = $ngRedux.connect(behavior => {
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];
      return Object({
        /* State Storage */
        currentState: currentState || {},
        stateConfig: currentState
          ? currentState.stateConfig
          : { screenName: 'Dashboard', toolbarName: 'Announcements' },
        isSingleSignon: behavior.session.isSingleSignon,
        adminNavigationList: behavior.session.adminNavigationList,
        navigationList: behavior.session.navigationList,
      });
    })(this);
  }

  $onInit() {
    Object.assign(this.$, {
      requestDataset: this.requestDataset.bind(this),
    });
    this._getStateConfig();
    this.$scope.$watch(() => this.videoSearchText, this.__onVideoSearchTextChanged.bind(this));
    this.toastDashboard = {
      text: '',
    };
    this._allowManagement();
  }

  /* Public */
  requestDataset() {
    this._getDashboardData();
  }
  /* */

  async _getStateConfig() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this.dashboardServices
      .getDashboardConfig('Announcements')
      .then(response => {
        this.stateData = { ...this.responseData };

        if (response.status && response.status === 500) {
          this.toastDashboard.text =
            'Ops! Ocorreu um erro ao carregar as configurações do seu Dashboard. Por favor, atualize a página.';
          this.$.querySelector('#toast-obj').toggle(true);
          return;
        }

        this.configList = response;
        this._getDashboardData();
      })
      .finally(() => {
        this._initPlayerSdk();
      });

    this.filterServices
      .getPowerFilters(this.stateConfig.screenName, 'Announcements')
      .then(getFiltersResult => {
        if (getFiltersResult) {
          const { drilldownMap } = getFiltersResult;
          const filterConfigList = getFiltersResult.filters;

          this.stateConfig.filterConfig = filterConfigList.map(filterConfig =>
            Object.clone({}, filterConfig),
          );

          if (
            this.lastState &&
            drilldownMap.length > 0 &&
            this.lastState.stateConfig.filterConfig
          ) {
            const lastFilterConfigList = this.lastState.stateConfig.filterConfig;

            this.stateConfig.filterConfig = this.stateConfig.filterConfig.map(actualFilter => {
              const drilldown = drilldownMap.filter(
                // eslint-disable-next-line no-shadow
                drilldown => drilldown.toId == actualFilter.id,
              )[0];

              if (drilldown) {
                const previousFilter = lastFilterConfigList.filter(
                  filter => filter.id == drilldown.fromId,
                )[0];
                return {
                  ...actualFilter,
                  condition: !previousFilter ? null : Object.clone(previousFilter.condition),
                };
              }

              return actualFilter;
            });
          }
          if (this.stateConfig.getDataFilters) {
            this.stateConfig.filterConfig = this.stateConfig.filterConfig.map(actualFilter => {
              const fixedFilters = this.stateConfig.getDataFilters.filter(
                condition => condition.id == actualFilter.id,
              )[0];
              return fixedFilters
                ? {
                    ...actualFilter,
                    condition: new FilterCondition(
                      actualFilter.id,
                      actualFilter.field || actualFilter.filters[fixedFilters.activeView].field,
                      fixedFilters.default,
                    ),
                  }
                : actualFilter;
            });
          }
        }
      });
  }

  async _getDashboardData() {
    const filters = {
      filter: {
        conditions: this.stateConfig.filterConditions,
      },
    };

    this._checkActiveFilters();

    if (this.configList) {
      this.dashboardServices
        .getGenericData(this.configList.moduleName, this.configList.methodChart, filters)
        .then(response => {
          this.data = response.data;

          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: true },
              bubbles: true,
              composed: true,
            }),
          );

          if (response.status && response.status === 500) {
            this.toastDashboard.text =
              'Ops! Ocorreu um erro ao carregar as configurações do seu Dashboard. Por favor, atualize a página.';
            this.$.querySelector('#toast-obj').toggle(true);
            return;
          }

          if (this.data && !this.data.hasError) {
            this.configList.section.forEach(item => {
              switch (item.type) {
                case 'headline': {
                  const headlineItems = this.data.filter(
                    dataItem => dataItem.descricaoTipo === 'Destaque',
                  );
                  item.value = this._parseObjects(headlineItems, item.fields);
                  item.value = item.value.map(headlineItem => ({
                    ...headlineItem,
                    itemId: headlineItem.item.length > 0 ? headlineItem.item[0].id : null,
                    progressId:
                      headlineItem.item.length > 0 && headlineItem.item[0].itemUsuario
                        ? headlineItem.item[0].itemUsuario.id
                        : null,
                    seen:
                      headlineItem.item.length > 0 && headlineItem.item[0].itemUsuario
                        ? headlineItem.item[0].itemUsuario.visualizado
                        : false,
                    active: false,
                  }));
                  item.value.sort((a, b) => a.seen - b.seen);
                  break;
                }
                case 'card': {
                  const cardItems = this.data
                    .filter(dataItem => dataItem.descricaoTipo === 'Card')
                    .sort((a, b) => (a.dataCadastro < b.dataCadastro ? 1 : -1))
                    .slice(0, 3);
                  item.value = this._parseObjects(cardItems, item.fields);
                  break;
                }
                case 'video': {
                  const videoItems = this.data.filter(
                    dataItem => dataItem.descricaoTipo === 'Video',
                  );

                  item.value = this._parseObjects(videoItems, item.fields);
                  item.value.forEach(videoData => {
                    videoData.videoList = this._parseObjects(
                      videoData.videoList,
                      item.videoListFields,
                    );
                  });

                  item.value.forEach(itemValue => {
                    if (itemValue.videoList.length > 0) {
                      itemValue.duration = itemValue.videoList.reduce(
                        (acc, video) => acc + parseInt(video.duration),
                        0,
                      );
                      itemValue.progress = itemValue.videoList.reduce(
                        (acc, video) =>
                          video.progress != null ? acc + parseInt(video.progress) : acc + 0,
                        0,
                      );

                      let watchedCount = 0;
                      itemValue.videoList.forEach(video => {
                        if (video.progress != null) {
                          watchedCount += (video.progress / video.duration) * 100 > 85 ? 1 : 0;
                        }
                      });
                      if (watchedCount == itemValue.videoList.length) {
                        itemValue.progressPercentage = 100;
                      } else {
                        itemValue.progressPercentage = this._parsePercentage(
                          itemValue.duration,
                          itemValue.progress,
                        );
                      }
                    }
                  });

                  this.videoList = item.value;
                  break;
                }
                default:
                  break;
              }
            });

            this.configList.section.forEach(sectionItem => {
              if (sectionItem.type === 'headline') {
                this._toggleHeadlineImage(sectionItem.value[0]);
              }
            });
          } else {
            this.$.querySelector('#toast-obj').toggle(true);
            this.toastDashboard.text = 'Nenhum dado encontrado.';
          }
        })
        .finally(() => {
          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          );
        });
    } else {
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: false },
          bubbles: true,
          composed: true,
        }),
      );
    }
  }

  _parseObjects(objectList, fields) {
    const fieldList = Object.keys(fields);
    return objectList.map(obj => {
      // Lista os campos do config e cria um novo objeto com os dados recebidos
      const data = fieldList.reduce((a, c) => {
        const levels = fields[c].split('.');
        const fieldValue = levels.reduce((acc, item) => (acc ? acc[item] : null), obj);
        return { ...a, [c]: fieldValue };
      }, {});
      return data;
    });
  }

  _actionFunction(item) {
    const proceed = this._hasAccess(item);
    switch (item.linkType) {
      case 'Interno': {
        if (proceed) {
          const routeConfig = GetRouteConfig(item.link);

          if (routeConfig) {
            const routeData = {
              ...routeConfig.data,
              stateConfig: routeConfig.data.stateConfig
                ? routeConfig.data.stateConfig({
                    isCustomReport: false,
                    customReportId: '',
                  })
                : null,
            };

            if (item.link == 'tcoEntryReport') {
              this.$ngRedux.dispatch({ type: 'CLEAR_ROUTE_LIST' });
              this.$state.go(item.link, {}, { reload: true });
            } else {
              this.$state.go(routeConfig.name, {}, { reload: true });
              this.$ngRedux.dispatch({
                type: 'NEXT_ROUTE',
                data: routeData,
              });
            }
          }
        }
        break;
      }
      default: {
        this.videoList.forEach(video => {
          if (video.id == parseInt(item.link)) {
            this._toggleVideo(video);
          }
        });

        break;
      }
    }
  }

  _allowManagement() {
    this.allowManagement =
      this.adminNavigationList.filter(item => item.id == 'mainAnnouncementReportAdm').length > 0;
  }

  _hasAccess(item) {
    if (item && item.linkType == 'Interno') {
      let hasScreenAccess = false;
      this.navigationList.forEach(menu => {
        if (menu.route == item.link) hasScreenAccess = true;
        menu.itens.forEach(subitem => {
          if (subitem.route == item.link) hasScreenAccess = true;
        });
      });

      this.adminNavigationList.forEach(menu => {
        if (menu.route == item.link) hasScreenAccess = true;
        menu.itens.forEach(subitem => {
          if (subitem.route == item.link) hasScreenAccess = true;
        });
      });

      return hasScreenAccess;
    }
    return true;
  }

  _openReport() {
    const routeConfig = GetRouteConfig('reportAnnouncementsAdm');

    if (routeConfig) {
      const routeData = {
        ...routeConfig.data,
        stateConfig: routeConfig.data.stateConfig({
          isCustomReport: false,
          customReportId: '',
        }),
      };

      this.$state.go(routeConfig.name, {}, { reload: true });
      this.$ngRedux.dispatch({
        type: 'NEXT_ROUTE',
        data: routeData,
      });
    }
  }

  _toggleHeadlineImage(item) {
    if (item) {
      this.configList.section.forEach(sectionItem => {
        if (sectionItem.type === 'headline') {
          sectionItem.value.forEach(headlineItem => {
            headlineItem.active = false;
          });
        }
      });

      item.active = !item.active;

      if (!item.seen) {
        this._updateSeenContent(item);
      }
    }
  }

  _nextHeadlineImage() {
    let activeHeadline;

    this.configList.section.forEach(sectionItem => {
      if (sectionItem.type === 'headline') {
        const activeElement = sectionItem.value.map(i => i.active).indexOf(true);
        sectionItem.value[activeElement].active = false;

        if (activeElement == sectionItem.value.length - 1) {
          sectionItem.value[0].active = true;
          // eslint-disable-next-line prefer-destructuring
          activeHeadline = sectionItem.value[0];
        } else {
          sectionItem.value[activeElement + 1].active = true;
          activeHeadline = sectionItem.value[activeElement + 1];
        }
      }
    });
    if (!activeHeadline.seen) {
      this._updateSeenContent(activeHeadline);
    }
  }

  _previousHeadlineImage() {
    let activeHeadline;

    this.configList.section.forEach(sectionItem => {
      if (sectionItem.type === 'headline') {
        const activeElement = sectionItem.value.map(i => i.active).indexOf(true);
        sectionItem.value[activeElement].active = false;

        if (activeElement == 0) {
          sectionItem.value[sectionItem.value.length - 1].active = true;
          activeHeadline = sectionItem.value[sectionItem.value.length - 1];
        } else {
          sectionItem.value[activeElement - 1].active = true;
          activeHeadline = sectionItem.value[activeElement - 1];
        }
      }
    });

    if (!activeHeadline.seen) {
      this._updateSeenContent(activeHeadline);
    }
  }

  _parseMinute(time) {
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }

  _parsePercentage(total, part) {
    return Math.floor((part / total) * 100);
  }

  async _toggleVideo(item) {
    if (this.vimeoPlayer) this.vimeoPlayer.destroy();

    this.playerConfig = {
      selectedTab: 'video',
      parentId: item.id,
      openedVideo: { ...item.videoList[0], active: true },
      videoList: item.videoList.map(obj => ({ ...obj, active: false })),
      isPaused: false,
    };
    this.playerConfig.videoList[0].active = true;

    let playerFrame = this.$.querySelector('#popup-vimeo-player-frame > iframe');
    if (!playerFrame) {
      playerFrame = document.createElement('iframe');
      this.$.querySelector('#popup-vimeo-player-frame').appendChild(playerFrame);
    }
    playerFrame.allow = 'fullscreen; autoplay';
    playerFrame.setAttribute('src', this._trustSrc(item.videoList[0].videoSource));
    playerFrame.setAttribute('display', 'none');

    this.vimeoPlayer = new this.vimeoApi.Player(playerFrame);
    this.vimeoPlayer
      .setColor(document.documentElement.style.getPropertyValue('--primary-color'))
      .catch(() => {});
    this.vimeoPlayer.on('play', () => {
      this.playerConfig.isPaused = false;
      this.$scope.$apply();
    });
    this.vimeoPlayer.on('pause', () => {
      this.playerConfig.isPaused = true;
      this.$scope.$apply();
    });

    this._setVideoTime(this.vimeoPlayer, this.playerConfig.openedVideo.progress || 0);
    this.$.querySelector('#popup-video-player').toggle();
    playerFrame.setAttribute('display', 'block');
  }

  async _onVideoClose() {
    await this._updateVideoProgress(this.playerConfig.openedVideo);
    this.playerConfig = {
      selectedTab: 'video',
      openedVideo: null,
      videoList: null,
    };
    this.vimeoPlayer.pause();
  }

  _trustSrc(src) {
    if (src !== '') {
      return this.$sce.trustAsResourceUrl(src);
    }
    return '';
  }

  _getVideoThumbnailSrc(item) {
    if (item.thumbnailSource) return item.thumbnailSource;

    if (item.videoList.length > 0) {
      return item.videoList[0].thumbnailSource != '' && item.videoList[0].thumbnailSource != null
        ? item.videoList[0].thumbnailSource
        : item.videoList[0].defaultThumbnailSource;
    }
    return '';
  }

  _sortVideos(option, order) {
    this.configList.section.forEach(item => {
      if (item.type === 'video') {
        switch (order) {
          case 'asc': {
            this.videoList.sort((a, b) => (a[option.field] > b[option.field] ? 1 : -1));
            break;
          }
          case 'desc': {
            this.videoList.sort((a, b) => (a[option.field] < b[option.field] ? 1 : -1));
            break;
          }
          default:
            break;
        }

        item.sortList.selectedField = {
          description: option.description,
          field: option.field,
          order,
        };
      }
    });
  }

  __onVideoSearchTextChanged() {
    if (this.configList) {
      this.configList.section.forEach(item => {
        if (item.type === 'video') {
          this.videoList = item.value.filter(dataItem =>
            item.searchFields.some(field =>
              dataItem[field].toUpperCase().includes(this.videoSearchText.toUpperCase()),
            ),
          );
        }
      });
    }
  }

  _closeVideoPlayer() {
    this.$.querySelector('#popup-video-player').toggle();
  }

  _selectPlayerTab(selectedTab) {
    const iframeElement = this.$.querySelector('#popup-vimeo-player-frame');
    document.documentElement.style.setProperty(
      '--player-height',
      `${iframeElement.offsetHeight}px`,
    );
    this.playerConfig.selectedTab = selectedTab;
  }

  async _selectPlaylistVideo(video) {
    if (this.playerConfig.openedVideo.id == video.id) {
      if (this.playerConfig.isPaused) {
        this.playerConfig.selectedTab = 'video';
        this.vimeoPlayer.play();
      } else {
        this.vimeoPlayer.pause();
      }
    } else {
      await this._updateVideoProgress(this.playerConfig.openedVideo);

      if (this.vimeoPlayer) this.vimeoPlayer.destroy();
      let playerFrame = this.$.querySelector('#popup-vimeo-player-frame > iframe');
      if (!playerFrame) {
        playerFrame = document.createElement('iframe');
        playerFrame.allow = 'fullscreen; autoplay';
        this.$.querySelector('#popup-vimeo-player-frame').appendChild(playerFrame);
      }

      playerFrame.setAttribute('display', 'none');
      playerFrame.setAttribute('src', this._trustSrc(video.videoSource));

      this.vimeoPlayer = new this.vimeoApi.Player(playerFrame);
      this.vimeoPlayer
        .setColor(document.documentElement.style.getPropertyValue('--primary-color'))
        .catch(() => {});
      this.vimeoPlayer.on('play', () => {
        this.playerConfig.isPaused = false;
        this.$scope.$apply();
      });
      this.vimeoPlayer.on('pause', () => {
        this.playerConfig.isPaused = true;
        this.$scope.$apply();
      });

      let videoProgress = 0;
      if (video.progress) {
        if (video.progress < video.duration - 5) {
          videoProgress = video.progress;
        } else {
          videoProgress = video.duration - 5;
        }
      }

      this._setVideoTime(this.vimeoPlayer, videoProgress);
      playerFrame.setAttribute('display', 'block');

      this.playerConfig.videoList.forEach(item => {
        item.active = false;
      });
      video.active = true;

      this.playerConfig = {
        ...this.playerConfig,
        selectedTab: 'video',
        openedVideo: video,
      };
      this.$scope.$apply();
    }
  }

  _initPlayerSdk() {
    const scriptElement = this.scriptServices.loadJsScript(
      'https://player.vimeo.com/api/player.js',
    );
    scriptElement.onload = () => {
      // eslint-disable-next-line no-undef
      this.vimeoApi = Vimeo;
    };
  }

  _toggleFilterMenu() {
    this.searchBarActive = false;
    this.videoSearchText = '';
    document.querySelector('#app-filter-menu').toggle();
  }

  _updateVideoProgress(video) {
    return new Promise(resolve => {
      this.vimeoPlayer.getCurrentTime().then(seconds => {
        if (seconds > video.progress) {
          video.progress = Math.floor(seconds);

          const requestBody = { announcementItemId: video.id, progress: Math.floor(seconds) };

          this.dashboardServices
            .getGenericData(
              this.configList.moduleName,
              this.configList.updateVideoMethod,
              requestBody,
            )
            .then(success => {
              if (success.data) {
                this.videoList.forEach(item => {
                  item.videoList.forEach(videoItem => {
                    if (videoItem.id == video.id) {
                      if (!videoItem.progressId) {
                        videoItem.progressId = success.data.id;
                      }
                      videoItem.progress = Math.floor(seconds);
                    }
                  });

                  item.progress = item.videoList.reduce(
                    (a, c) => (c.progress != null ? a + parseInt(c.progress) : a + 0),
                    0,
                  );

                  let watchedCount = 0;
                  item.videoList.forEach(videoItem => {
                    if (videoItem.progress != null) {
                      watchedCount += (videoItem.progress / videoItem.duration) * 100 > 85 ? 1 : 0;
                    }
                  });
                  if (watchedCount == item.videoList.length) {
                    item.progressPercentage = 100;
                  } else {
                    item.progressPercentage = this._parsePercentage(item.duration, item.progress);
                  }
                });
              }
            });
        }
        resolve();
      });
    });
  }

  _updateSeenContent(item) {
    const requestBody = { announcementItemId: item.itemId, seen: true, progress: 0 };

    this.dashboardServices
      .getGenericData(this.configList.moduleName, this.configList.updateHeadlineMethod, requestBody)
      .then(success => {
        if (success.data) {
          item.seen = true;
          item.progressId = success.data.id;
        }
      });
  }

  _setVideoTime(player, seconds) {
    player.setCurrentTime(seconds).then(() => player.play());
  }

  _getPropertyValue(data, prop) {
    if (data) {
      const levels = prop.split('.');
      return levels.reduce((acc, item) => acc[item], data);
    }
    return null;
  }

  _focusInput() {
    this.searchBarActive = true;
  }

  _checkActiveFilters() {
    let countSelectedFilters = 0;

    this.currentState?.stateConfig?.filterConfig?.forEach(filter => {
      if (filter.visible === false || filter.show === false) return;

      if (Array.isArray(filter.condition)) {
        countSelectedFilters += filter.condition.reduce((acc, condition) => {
          if (condition.value?.some(value => value === 0 || !!value)) {
            return ++acc;
          }
          return acc;
        }, 0);
      } else if (
        filter.condition?.value?.some(value => value === 0 || !!value) ||
        filter.condition?.options?.some(opt => opt.selected)
      ) {
        countSelectedFilters++;
      }
    });

    this.hasActiveFilter = countSelectedFilters > 0;
  }

  _closeSearch() {
    this.searchBarActive = false;
    this.videoSearchText = '';
  }

  _getVideoCount() {
    if (this.queryMobile.matches || this.queryTablet.matches) {
      return this.videoList.length <= 1
        ? `${this.videoList.length} vídeo`
        : `${this.videoList.length} vídeos`;
    }
    return this.videoList.length <= 1
      ? `Listando ${this.videoList.length} vídeo`
      : `Listando ${this.videoList.length} vídeos`;
  }
}

class GolfleetDashboardAnnouncements {
  /**
   * Creates an instance of Announcements.
   * @memberof DashboardAnnouncements
   */
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetDashboardAnnouncementsController;
  }
}

angular
  .module('golfleet-dashboard-announcements', ['power-toolbar', 'trust-src'])
  .component('golfleetDashboardAnnouncements', new GolfleetDashboardAnnouncements());
