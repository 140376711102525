/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import angular from 'angular';
import 'ng-redux';

import '../power-fab/power-fab';
import '../power-dropdown/power-dropdown';
import '../power-footer/power-footer';
import '../power-map-geofences/power-map-geofences';
import '../power-pagination/power-pagination';
import '../power-crud/power-crud';
import '../power-grid/power-grid';
import '../power-popup/power-popup';
import '../power-popup-delete/power-popup-delete';
import '../power-popup-share/power-popup-share';
import '../power-toast/power-toast';
import '../power-toolbar-report/power-toolbar-report';
import '../../directives/ng-resize/ng-resize';
import { PowerReportController } from '../power-report/power-report';

import template from './power-report-geofences.html';
import './power-report-geofences.scss';

class PowerReportGeofencesController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    this.legendList = [];
    this.mapModeList = [
      { type: 'all', description: 'Todos os Pontos', selected: true },
      { type: 'step', description: 'Ponto a ponto', selected: false, step: 0 },
    ];
    this.mapLayerList = [
      {
        id: 0,
        description: 'Áreas de Controle',
        layer: 'geofences',
        selected: true,
      },
    ];
    this.selectedRows = [];
    this.cantDeleteGeofences = [];
    this.deleteGeofences = [];
    this.onDeleteEvent = this._deleteSelectedItens.bind(this);
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();

    this.$.addEventListener('delete', this.onDeleteEvent);
    this.$.addEventListener('mapLoaded', () =>
      this.$.querySelector('#report-body-map')
        .getMap()
        .onMoveEnd(() =>
          this.mapLayerList.map(mapLayer => {
            if (mapLayer.selected) this._toggleMapLayer({ ...mapLayer, selected: false });

            return mapLayer;
          }),
        ),
    );

    this.$scope.$watch(() => this.reportDataset, this.__reportDatasetChanged.bind(this));
  }
  /* */

  /* Public */
  changeView(viewMode, fitLayer = true) {
    const mapComponent = this.$.querySelector('#report-body-map');
    this.stateConfig.viewMode = viewMode;
    this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });

    switch (viewMode) {
      case 'map':
        this.$.setAttribute('map-view', '');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('split-view');
        if (mapComponent.getMap) {
          this._adjustMap(mapComponent.getMap(), fitLayer);

          if (this._getSelectedMapMode().type === 'step') {
            mapComponent.openPopup(0, 'stepGeofences');
          }
        }

        break;
      case 'split':
        this.$.setAttribute('split-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('grid-view');
        if (mapComponent.getMap) {
          this._adjustMap(mapComponent.getMap(), fitLayer);
        }

        break;
      default:
        if (mapComponent.resizeMap) mapComponent.cancelDraw();
        this.$.setAttribute('grid-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('split-view');

        break;
    }
  }

  changePage(page) {
    super.changePage(page);

    const gridElement = this.$.querySelector('#report-body-grid');
    const step = this.stateConfig.gridConfig.pageSize * (page - 1);

    gridElement.setActiveRow({ index: step });

    if (this._getSelectedMapMode().type == 'step') {
      this._renderStepMode(step);
    } else {
      this.mapModeList = this.mapModeList.map(mode =>
        mode.type == 'step' ? { ...mode, step } : mode,
      );
    }
  }

  changeRow(page, row) {
    if (page != this.stateConfig.gridConfig.page) {
      this.changePage(page);
    }

    if (row >= 0) {
      this._renderStepMode(row);
    }
  }

  changePageSize(pageSize) {
    super.changePageSize(pageSize);

    const step = 1;

    if (this._getSelectedMapMode().type == 'step') {
      this._renderStepMode(step);
    } else {
      this.mapModeList = this.mapModeList.map(mode =>
        mode.type == 'step' ? { ...mode, step } : mode,
      );
    }
  }
  /* */

  /* Private */
  _validateAction(action) {
    if (action.actionType == 'edit') {
      if (this.selectedRows.length != 1) return true;
      return this.selectedRows[0].geoJson && this.selectedRows[0].geoJson.type == 'MultiPolygon';
    }
    return false;
  }

  _callAction(action) {
    const mapComponent = this.$.querySelector('#report-body-map');
    this.$.removeEventListener('drawEnded', this._evtDrawEnded);
    switch (action.actionType) {
      case 'add':
        {
          this.mapModeList = this.mapModeList.map(mode => ({
            ...mode,
            selected: mode.type == 'all',
          }));

          this._renderAllMode(this.reportDataset);
          this._validateRenderInToggle();
          mapComponent.startDraw({ callback: this._addItemData.bind(this) });
          this.changeView('map', this.stateConfig.viewMode == 'grid');

          const [layer] = this.mapLayerList.filter(item => item.layer === 'geofences');
          if (!layer.selected) {
            this._toggleMapLayer(layer);
          }
        }
        break;
      case 'edit':
        if (this.selectedRows[0].dealerId && this.selectedRows[0].dealerId !== this.clientId) {
          this._evtClickPopupCantEditGeofence();
        } else {
          this.mapModeList = this.mapModeList.map(mode => ({
            ...mode,
            selected: mode.type == 'all',
          }));
          this._renderAllMode(this.reportDataset);
          this._validateRenderInToggle();

          this.changeView('map', false);

          mapComponent.startDraw({
            callback: this._editItemData.bind(this),
            dataset: this.selectedRows[0],
          });

          const [layer] = this.mapLayerList.filter(item => item.layer === 'geofences');
          if (!layer.selected) {
            this._toggleMapLayer(layer);
          }
        }
        break;
      case 'delete':
        this.cantDeleteGeofences = [];
        this.deleteGeofences = [];

        Object.keys(this.selectedRows).reduce((acc, item) => {
          if (
            this.selectedRows[item].dealerId &&
            this.selectedRows[item].dealerId !== this.clientId
          ) {
            this.cantDeleteGeofences.push(this.selectedRows[item]);
          } else {
            this.deleteGeofences.push(this.selectedRows[item]);
          }
          return acc;
        }, []);

        if (this.deleteGeofences.length > 0) {
          this.$.querySelector('power-popup-delete').setData({
            deleteMethod: action.actionMethod,
            objects: this.deleteGeofences.map(row => ({
              id: row.id,
              description: row[this.stateConfig.gridConfig.descriptionField],
              value: row.id,
            })),
          });
          this.$.querySelector('power-popup-delete').toggle();
        } else if (this.cantDeleteGeofences.length > 0) {
          this._evtClickPopupCantDeleteGeofences();
        }
        break;
      default:
        break;
    }
  }

  _addItemData(geoJson) {
    if (
      geoJson.geometry.coordinates &&
      ((geoJson.geometry.type === 'Point' && geoJson.geometry.coordinates[0] === null) ||
        geoJson.geometry.coordinates[0].length === 0)
    ) {
      this.toastText = 'Selecione uma Área de controle no mapa';
      this.$.querySelector('power-toast#report-toast').toggle(true);
    } else {
      this.$.querySelector('#popup-add').toggle();
      this.stateConfig.toolbarConfig.addConfig.dataset = { geoJson };
    }
  }

  _addItem(method, data) {
    const payload = {
      ...data,
      address: {
        city: data.city,
        state: data.state,
        district: data.district,
        street: data.street,
      },
    };

    this.$.querySelector('#popup-add').toggle();
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'POST',
      data: {
        request: Object.assign(payload, {
          geoJson: this.stateConfig.toolbarConfig.addConfig.dataset.geoJson.geometry,
          radius: this.stateConfig.toolbarConfig.addConfig.dataset.geoJson.properties.radius
            ? parseInt(
                this.stateConfig.toolbarConfig.addConfig.dataset.geoJson.properties.radius,
                10,
              )
            : null,
        }),
      },
    }).then(
      () => {
        this.toastText = `${this.stateConfig.toolbarConfig.addConfig.title} adicionado.`;
        this.$.querySelector('power-toast#report-toast').toggle(true);
        this.requestDataset();
      },
      error => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
        this.toastText = 'Erro ao adicionar o item.';

        if (error.status != 500) {
          this.toastText = error.data.mensagem;
        }

        this.$.querySelector('power-toast#report-toast').toggle(true);
      },
    );
  }

  _errorAddItem() {
    this.toastText = 'Por favor verifique os campos';
    this.$.querySelector('power-toast#report-toast').toggle(true);
  }

  _editItemData(geoJson) {
    this.$.querySelector('#popup-edit').toggle();
    this.stateConfig.toolbarConfig.editConfig.dataset = {
      geoJson,
      id: this.selectedRows[0].id,
      name: this.selectedRows[0].description,
      categoryId: this.selectedRows[0].categoryId,
      privacy: this.selectedRows[0].privacyEnum,
      description: this.selectedRows[0].extendedDescription,
      city: this.selectedRows[0].addressDTO ? this.selectedRows[0].addressDTO.city : null,
      state: this.selectedRows[0].addressDTO ? this.selectedRows[0].addressDTO.state : null,
      district: this.selectedRows[0].addressDTO ? this.selectedRows[0].addressDTO.district : null,
      street: this.selectedRows[0].addressDTO ? this.selectedRows[0].addressDTO.street : null,
    };
  }

  _editItem(method, data) {
    const payload = {
      ...data,
      address: {
        city: data.city,
        state: data.state,
        district: data.district,
        street: data.street,
      },
    };

    this.$.querySelector('#popup-edit').toggle();
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'POST',
      data: {
        request: Object.assign(payload, {
          geoJson: this.stateConfig.toolbarConfig.editConfig.dataset.geoJson.geometry,
          radius: this.stateConfig.toolbarConfig.editConfig.dataset.geoJson.properties.radius
            ? parseInt(
                this.stateConfig.toolbarConfig.editConfig.dataset.geoJson.properties.radius,
                10,
              )
            : null,
        }),
      },
    }).then(
      () => {
        this.toastText = 'Área de controle editada.';
        this.$.querySelector('power-toast#report-toast').toggle(true);
        this.requestDataset();
      },
      error => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
        this.toastText = 'Erro ao editar o item.';

        if (error.status != 500) {
          this.toastText = error.data.mensagem;
        }

        this.$.querySelector('power-toast#report-toast').toggle(true);
      },
    );
  }

  _cancelEditItem() {
    this.$.querySelector('#popup-edit').toggle();
    this.stateConfig.toolbarConfig.editConfig.dataset = null;
  }

  _deleteSelectedItens(evt) {
    this.$.querySelector('power-popup-delete').toggle();
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    return this.$http({
      url: `${this.urlApi}/${evt.detail.method}`,
      method: 'POST',
      data: {
        objects: evt.detail.objects,
      },
    }).then(
      success => {
        if (!success.data.hasError) {
          this.toastText = 'Excluido com sucesso.';

          if (this.cantDeleteGeofences.length > 0) {
            this._evtClickPopupCantDeleteGeofences();
          }
          this.requestDataset();
        } else this.toastText = 'Não foi possivel Excluir.';
        this.$.querySelector('power-toast#report-toast').toggle(true);
      },
      () => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );
        this.toastText = 'Não foi possivel Excluir.';
        this.$.querySelector('power-toast#report-toast').toggle(true);
      },
    );
  }

  _goToLink(evt, evtParams) {
    if (evtParams.routeLink == 'map') {
      if (this.stateConfig.viewMode != 'map' && this.stateConfig.viewMode != 'split') {
        if (window.innerWidth > 878) this.changeView('split');
        else this.changeView('map');
      }

      if (this._getSelectedMapMode().type != 'step') {
        this._selectMapMode(this.mapModeList.filter(mode => mode.type == 'step')[0]);
      }

      const gridElement = this.$.querySelector('#report-body-grid');

      gridElement.setActiveRow({ index: evtParams.index }).then(() => {
        this.selectedVehicle = gridElement.getActiveRow();
      });
    } else {
      super._goToLink(evt, evtParams);
    }
  }

  _adjustMap(map, fitLayer) {
    this.$.querySelector('#report-body-map').resizeMap();
    if (!fitLayer) return;
    switch (this._getSelectedMapMode().type) {
      case 'all':
        this.$.querySelector('#report-body-map').fitLayers(['geofences']);
        break;
      case 'step':
        this.$.querySelector('#report-body-map').fitLayers(['stepGeofences']);
        break;
      default:
        break;
    }
  }

  _renderAllMode(dataset) {
    const mapComponent = this.$.querySelector('#report-body-map');
    const parsedDataset = mapComponent.sortDatasetByBounds(dataset);

    return mapComponent.renderDataset({
      dataset: parsedDataset
        .reverse()
        .map(data => ({ ...data, color: data.color || data.categoryColor })),
      layerName: 'geofences',
    });
  }

  async _renderStepMode(index) {
    const mapComponent = this.$.querySelector('#report-body-map');
    this.mapModeList = this.mapModeList.map(mode =>
      mode.type == 'step' ? { ...mode, step: index } : mode,
    );
    const data = this.reportDataset[index];

    await mapComponent.renderDataset({
      dataset: [{ ...data, color: data.color || data.categoryColor }],
      layerName: 'stepGeofences',
    });

    mapComponent.fitLayers(['stepGeofences']);
    if (!this.$.hasAttribute('grid-view')) {
      mapComponent.openPopup(0, 'stepGeofences');
    }
  }

  _getSelectedMapMode() {
    return this.mapModeList.filter(mode => mode.selected)[0];
  }

  _getStepPosition() {
    return this.mapModeList.filter(mode => mode.type == 'step')[0].step;
  }

  _selectMapMode(mapMode, clearActions = true) {
    const mapComponent = this.$.querySelector('#report-body-map');

    if (clearActions) {
      if (mapComponent.hasAttribute('draw')) mapComponent.cancelDraw();
    }

    if (mapMode.selected) return;
    this.mapModeList = this.mapModeList.map(mode => ({
      ...mode,
      selected: mode.type == mapMode.type,
    }));
    switch (mapMode.type) {
      case 'all':
        mapComponent.removeLayers(['stepGeofences']);
        this._renderAllMode(this.reportDataset).then(() => {
          mapComponent.resizeMap();
          if (!mapComponent.hasAttribute('draw')) {
            mapComponent.fitLayers(['geofences']);
          }
        });
        break;
      case 'step':
        mapComponent.removeLayers(['geofences']);
        if (mapMode.step === 0) {
          this._renderStepMode(
            this.stateConfig.gridConfig.pageSize * (this.stateConfig.gridConfig.page - 1),
          );
        }
        break;
      default:
        break;
    }
  }

  _evtClickPopupCantDeleteGeofences() {
    this.$.querySelector('#popup-cant-delete-geofence').toggle();

    /* Chrome Locked-Scroll Workaround */
    const scrollWorkaround = () => {
      const geofencesList = this.$.querySelector(
        '#popup-cant-delete-geofence .cant-delete-list-item',
      );
      setTimeout(() => {
        geofencesList.style.overflowY = 'hidden';
      }, 1100);
      setTimeout(() => {
        geofencesList.style.overflowY = 'auto';
      }, 1200);
    };

    if (this.$.querySelector('#popup-cant-delete-geofence')) {
      scrollWorkaround();
    }
  }

  _evtClickPopupCantEditGeofence() {
    this.$.querySelector('#popup-cant-edit-geofence').toggle();
  }

  _toggleMapLayer(mapLayer) {
    const mapComponent = this.$.querySelector('#report-body-map');
    mapLayer.selected = !mapLayer.selected;
    if (mapLayer.selected) this._renderAllMode(this.reportDataset);
    else {
      mapComponent.removeLayers([mapLayer.layer]);
      if (!mapComponent.hasAttribute('draw')) mapComponent.removeLayers(['stepGeofences']);
    }
  }

  _validateRenderInToggle() {
    const mapLayerGeofence = this.mapLayerList.find(map => map.layer == 'geofences');
    if (mapLayerGeofence.selected) this._renderAllMode(this.reportDataset);
    else this._toggleMapLayer({ ...mapLayerGeofence, selected: true });
  }
  /* */

  /* Observers */
  async __reportDatasetChanged(newValue) {
    if (newValue && newValue.length > 0) {
      await this.$.querySelector('#report-body-map').awaitRender();
      this._selectMapMode(Object.assign(this._getSelectedMapMode(), { selected: false }), false);
    }
  }

  __onRequestSyncVisualization(evt) {
    super.__onRequestSyncVisualization(evt);

    const gridElement = this.$.querySelector('#report-body-grid');
    const activeRow = gridElement.getActiveRow();

    this._renderStepMode(activeRow._index);
  }
  /* */
}

class PowerReportGeofences {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = PowerReportGeofencesController;
  }
}

angular
  .module('power-report-geofences', [
    'ngRedux',
    'power-fab',
    'power-crud',
    'power-dropdown',
    'power-footer',
    'power-grid',
    'power-map-geofences',
    'power-pagination',
    'power-popup',
    'power-popup-delete',
    'power-popup-share',
    'power-toast',
    'power-toolbar-report',
    'ng-resize',
  ])
  .component('powerReportGeofences', new PowerReportGeofences());

export { PowerReportGeofencesController };
