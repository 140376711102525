/* eslint-disable linebreak-style */
import angular from 'angular';
import 'angular-easy-masks';

import template from './power-crud-bar-select.html';
import './power-crud-bar-select.scss';

class PowerCrudBarSelectController {
  static get $inject() {
    return ['$element', '$scope'];
  }

  constructor($element, $scope) {
    Object.assign(this, { $: $element[0], $scope });

    this.disabled = false;

    this.steps = Array.from({ length: 5 }, (_, i) => i);
  }

  // #region Lifecycle
  $onInit() {
    Object.assign(this.$, {
      setWarning: this.setWarning.bind(this),
      setDisabled: this.setDisabled.bind(this),
    });
    this.steps = Array.from({ length: this.separator }, (_, i) => i);
    setTimeout(() => {
      this.stepsFill = Array.from({ length: this.value }, (_, i) => i);
    }, 300);
  }
  // #endregion Lifecycle

  // #region Public
  setWarning(text) {
    this.warning = text;
  }

  setDisabled(value) {
    this.disabled = value;
  }

  onModelChange() {
    this.stepsFill = Array.from({ length: this.value }, (_, i) => i);
  }

  // #endregion Public

  // #region Private

  // #endregion Private
}

class PowerCrudBarSelect {
  constructor() {
    this.template = template;
    this.bindings = {
      value: '=?',
      name: '=',
      hint: '=?',
      warning: '=?',
      disabled: '=?',
      maxLevel: '=?',
      separator: '=?',
    };
    this.controller = PowerCrudBarSelectController;
  }
}

angular
  .module('power-crud-bar-select', ['wt.easy'])
  .component('powerCrudBarSelect', new PowerCrudBarSelect());
