import angular from 'angular';
import 'ng-redux';

import { toDateTimeInput, toUTCDate } from '@power/power-components/utils/date-util.js';

import '../golfleet-file-list/golfleet-file-list';
import '@power/power-components/components/power-toast/power-toast';

import template from './golfleet-form-tco-accidents-crud.html';
import './golfleet-form-tco-accidents-crud.scss';

class PowerDropdownItem {
  /**
   * Creates an instance of PowerDropdownItem.
   * @param {Number} id
   * @param {String} description
   * @param {Boolean} selected
   * @param {any} value
   * @memberof PowerDropdownItem
   */
  constructor(id, description, selected, value) {
    this.id = id;
    this.description = description;
    this.selected = selected;
    this.value = value;
  }
}

class GolfleetFormTcoAccidentsCrudController {
  static get $inject() {
    return [
      '$element',
      '$sce',
      '$ngRedux',
      '$rootScope',
      '$state',
      '$http',
      '$scope',
      'commonServices',
      'urlApi',
      'crudServices',
    ];
  }

  /**
   * Creates an instance of GolfleetFormTcoAccidentsCrudController.
   * @memberof GolfleetFormTcoAccidentsCrudController
   */
  constructor(
    $element,
    $sce,
    $ngRedux,
    $rootScope,
    $state,
    $http,
    $scope,
    commonServices,
    urlApi,
    crudServices,
  ) {
    Object.assign(this, {
      $: $element[0],
      $sce,
      $ngRedux,
      $rootScope,
      $state,
      $http,
      $scope,
      commonServices,
      urlApi,
      crudServices,
    });

    this.__appInheritBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Storage */
        state: behavior.state,
      }),
    )(this);

    this.formConfig = [];
    this.form = [];
    this.staticFormData = [];
    this.canSubmit = true;

    this.formConfigReady = false;
    this.formViewData = [];
    this.comboboxToLoad = [];
    this.staticFormData = [];
    this.itemsAccident = null;
    this.itemsAccidentFormatted = null;
    this.accidentEditConfig = this._getAccidentIdFromState();
    this.dataset = null;
  }

  /* Lifecycle */
  $onInit() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    this.dataset = Object.clone({}, this.crudDataset);

    this.$scope.$on('triggerRequest', (event, eventObject) =>
      this._callTrigger(
        eventObject.triggerKey,
        eventObject.triggerOf,
        eventObject.triggerValue,
        eventObject.triggerCondition,
      ),
    );

    this._getFormConfig();
    this._checkReady();
  }

  _checkReady() {
    setTimeout(() => {
      if (this.formConfigReady) {
        this._getFormData();
        this._formViewDataParser();
        this._setDeleteButtonState();
      } else {
        this._checkReady();
      }
    }, 300);
  }

  _getAccidentIdFromState() {
    const route = this.state.routeList.filter(x => x.routeTail);
    if (route[0]) {
      const { stateConfig } = route[0];
      return {
        idAccident: route[0].routeTail,
        getDataMethod: stateConfig.getDataMethod,
      };
    }
    return null;
  }

  _getFormConfig() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    this.commonServices.getFormConfiguration('TcoAccident').then(data => {
      if (data.form) {
        this.formConfig = data.form;
        const sections = this.formConfig.map(item => item.sections);
        this.comboboxToLoad = sections.reduce((acc, item) => {
          item.forEach(arg => {
            const cb = arg.items.filter(
              c => c.type == 'link' && c.getDataMethod && c.field == 'linkItemsAccident',
            );
            cb.forEach(e => {
              const payload = e.getDataParams;
              this._getDataSet(e.getDataMethod, payload);
            });
            if (cb.length > 0) acc.push(cb);
          });
          return acc;
        }, []);
      }
      this.formConfigReady = true;
    });
  }

  _getDataSet(dataMethod, payload) {
    this.crudServices.getData(dataMethod, payload).then(result => {
      this.itemsAccident = result;
    });
  }

  _formViewDataParser() {
    this._syncScope();
  }

  _syncScope() {
    if (this.$scope.$$phase === null && this.$rootScope.$$phase === null) this.$scope.$apply();
  }

  _getFormViewData() {}

  _getFormData() {
    if (this.accidentEditConfig) {
      const { idAccident, getDataMethod } = this.accidentEditConfig;
      const payload = {
        id: idAccident,
      };
      this.crudServices
        .getData(getDataMethod, payload)
        .then(data => {
          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: true },
              bubbles: true,
              composed: true,
            }),
          );
          this.formConfig.map(config => {
            if (config.sections) {
              config.sections.forEach(section => {
                if (section.items && section.items.length > 0) {
                  section.items.map(item => {
                    const field = data[`${item.field}`];
                    if (item.field == 'competenciaAno') {
                      item.value = field.toString();
                    } else {
                      item.value = field;
                    }

                    if (item.type == 'combobox' && item.value) {
                      // eslint-disable-next-line no-return-assign
                      item.options.forEach(option => {
                        option.selected = option.value == item.value;
                      });

                      if (item.field == 'idVeiculo') {
                        item.disabled = true;
                        item.triggerBlock = true;
                      }
                    }

                    if (item.type == 'number' && item.value) {
                      // eslint-disable-next-line radix
                      item.value = parseInt(item.value);
                    }
                    if (item.inputType == 'datetime') {
                      item.value = toDateTimeInput({ date: item.value });
                    }

                    return item;
                  });
                }
              });
            }
            return config;
          });
          if (data.items) {
            const items = this.itemsAccident.filter(x => data.items.includes(x.id));
            items.forEach(e => {
              e.selected = true;
            });
            this.itemsAccident.selected = items;
            this.itemsAccidentFormatted = this.itemsAccident.selected
              .map(x => x.description)
              .join(', ');
          }

          if (data.accidentFiles.length > 0) {
            const files = data.accidentFiles;
            for (let i = 0, len = files.length; i < len; i++) {
              const file = {
                id: files[i].id,
                name: files[i].nome,
                extension: files[i].extensao,
                size: files[i].tamanho,
                type: files[i].tipo,
                contentBase64: files[i].conteudoBase64,
              };
              this.$.querySelector('golfleet-file-list').addFileList(file);
            }
          }
        })
        .finally(() =>
          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          ),
        );
    } else {
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: false },
          bubbles: true,
          composed: true,
        }),
      );
    }
    // this.formReady = true;
  }

  _setDeleteButtonState() {
    const button = this.$.querySelector('.delete-btn');
    if (button) {
      if (!this.accidentEditConfig) {
        button.setAttribute('disabled', '');
      } else if (this.accidentEditConfig && button.hasAttribute('disabled')) {
        button.removeAttribute('disabled');
      }
    }
  }

  _deleteCrudForm() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    const request = { objects: [{ objectId: this.accidentEditConfig.idAccident }] };

    this.crudServices
      .callDeleteMethod('Accident/DeleteAccident', request)
      .then(
        success => {
          if (success.data.data) {
            this.$.querySelector('power-toast#form-tco-accidents-crud-toast').toggle(true);
            Object.assign(this, { toast: { text: 'Sinistro excluído' } });
            setTimeout(() => {
              this._cancelCrudForm();
            }, 1500);
          } else {
            this.$.querySelector('power-toast#form-tco-accidents-crud-toast').toggle(true);
            Object.assign(this, { toast: { text: 'Ocorreu um erro ao excuir o sinistro' } });
          }
        },
        () => {},
      )
      .finally(() =>
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        ),
      );
  }

  _validateNumberFields(item) {
    if (item.simpleValue == 0) {
      item.simpleValue = null;
    }

    return item.simpleValue;
  }

  _validateFields() {
    const emptyValues = [];
    this.formConfig.map(config => {
      if (config.sections) {
        config.sections.forEach(section => {
          if (section.items && section.items.length > 0) {
            section.items.map(item => {
              if (item.required && !item.value) {
                item.warning = 'Campo obrigatório';
                emptyValues.push(item);
              }
              return item;
            });
          }
        });
      }
      return config;
    });
    if (emptyValues.length > 0) {
      return false;
    }
    return true;
  }

  _confirmCrudForm() {
    const parsedData = {};
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    // fields validation
    if (this._validateFields()) {
      if (this.accidentEditConfig) {
        parsedData.id = this.accidentEditConfig.idAccident;
      }
      // recover form values
      this.formConfig.forEach(form =>
        form.sections.forEach(section =>
          section.items
            .filter(x => x.value)
            .map(item => {
              if (this.accidentEditConfig && item.value.id) {
                parsedData[item.field] = item.value.id;
              } else if (item.inputType == 'datetime') {
                parsedData[item.field] = toUTCDate({ date: item.value })
              } else {
                parsedData[item.field] = item.value;
              }
              return parsedData;
            }),
        ),
      );

      // recover selected items
      if (this.itemsAccident.selected) {
        parsedData.items = this.itemsAccident.selected.map(x => x.value);
      }

      // recover images list
      const imagesList = this.$.querySelector('golfleet-file-list').getFileList();
      parsedData.accidentFiles = imagesList.map(item => ({
        id: item.id,
        nome: item.name,
        extensao: item.extension,
        tamanho: item.size,
        conteudoBase64: item.contentBase64,
        tipo: item.type,
      }));

      this._upsertAccident(parsedData);
    } else {
      this.$.querySelector('power-toast#form-tco-accidents-crud-toast').toggle(true);
      Object.assign(this, {
        toast: {
          text: 'Algumas informações são obrigatórias e precisam ser preenchidas.',
        },
      });
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: false },
          bubbles: true,
          composed: true,
        }),
      );
    }
  }

  _upsertAccident(request) {
    this.crudServices
      .callApiMethod('Accident/UpsertAccident', request)
      .then(
        success => {
          if (success.status == 200) {
            this.$.querySelector('power-toast#form-tco-accidents-crud-toast').toggle(true);
            Object.assign(this, { toast: { text: 'Sinistro salvo' } });
            setTimeout(() => {
              this._cancelCrudForm();
            }, 1500);
          } else {
            switch (success.status) {
              case 406:
              case 422:
                this.toastText = success.data.data.toString();
                break;
              default:
                this.toastText = 'Ocorreu um erro ao salvar o sinistro';
                break;
            }
            this.$.querySelector('power-toast#form-tco-accidents-crud-toast').toggle(true);
            Object.assign(this, { toast: { text: this.toastText } });
          }
        },
        () => {},
      )
      .finally(() =>
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        ),
      );
  }

  _removeErrorAttr(item) {
    item.error = false;
  }

  _removeLimitValuesErrorAttr(item) {
    // to clear the zeros when click in field uncomment the line below
    // this._validateNumberFields(item);

    item.limitValuesInvalid = false;
  }

  _cancelCrudForm() {
    this._backTo(1);
  }

  _backTo(index) {
    const backToState = this._reverseRouteList()[index];
    this.$ngRedux.dispatch({
      type: 'PREVIOUS_ROUTE',
      data: { index: this.state.routeList.length - 1 - index },
    });
    this.$state.go(backToState.routeLink, { tail: backToState.routeTail });
  }

  _reverseRouteList() {
    return Object.assign([], this.state.routeList).reverse();
  }

  _callTrigger(triggerKey, triggerOf, triggerValue, triggerCondition) {
    if (!this.formConfig) return;
    this.formConfig.forEach(form =>
      form.sections.forEach(section =>
        section.items
          .filter(x => x.key && x.key == triggerOf)
          .forEach(groupItem => {
            if (!triggerCondition || (triggerCondition && triggerValue == triggerCondition)) {
              if (groupItem.required === false) groupItem.required = true;
              if (groupItem.type == 'combobox') {
                const payload = { ...groupItem.getDataParams };
                if (this.dataset) {
                  // eslint-disable-next-line no-restricted-syntax
                  for (const attr in payload) {
                    if (payload[attr] == triggerKey) payload[attr] = triggerValue;
                    else payload[attr] = this.dataset[payload[attr]];
                  }
                }
                this.crudServices.getData(groupItem.getDataMethod, payload).then(result => {
                  if (result.status && result.status != 200) return;
                  let foundDefault = false;
                  groupItem.options = [];
                  result.forEach(item => {
                    if (groupItem.value && item.id == groupItem.value) {
                      foundDefault = true;
                      groupItem.value = new PowerDropdownItem(
                        item.id,
                        item.description,
                        true,
                        item.value,
                      );
                      groupItem.options.push(
                        new PowerDropdownItem(item.id, item.description, true, item.value),
                      );
                    } else
                      groupItem.options.push(
                        new PowerDropdownItem(item.id, item.description, false, item.value),
                      );
                  });
                  if (groupItem.options.length > 0) groupItem.disabled = false;
                  if (!foundDefault) {
                    groupItem.value = null;
                    this.dataset[groupItem.key] = null;
                    this._callTrigger(
                      groupItem.key,
                      groupItem.triggerOf,
                      null,
                      groupItem.triggerCondition,
                    );
                  }
                });
              } else {
                groupItem.disabled = false;
                groupItem.value = triggerValue;
                if ((!groupItem.value || groupItem.value === 0) && groupItem.triggerOf) {
                  this.dataset[groupItem.key] = null;
                  this._callTrigger(
                    groupItem.key,
                    groupItem.triggerOf,
                    null,
                    groupItem.triggerCondition,
                  );
                }
              }
            } else {
              groupItem.disabled = true;
              groupItem.value = null;
              this.dataset[groupItem.key] = null;
              if (groupItem.type == 'combobox') groupItem.options = [];
              if (groupItem.triggerOf)
                this._callTrigger(
                  groupItem.key,
                  groupItem.triggerOf,
                  null,
                  groupItem.triggerCondition,
                );
            }
          }),
      ),
    );
  }

  evtClickBtnPowerPopup(type) {
    if (type == 'primaryBtn') {
      this.itemsAccidentFormatted = this.itemsAccident.selected.map(x => x.description).join(', ');
    }
    this.$.querySelector('#popup-items-option-selected').toggle();
  }
}

class GolfleetFormTcoAccidentsCrud {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetFormTcoAccidentsCrudController;
  }
}

angular
  .module('golfleet-form-tco-accidents-crud', ['ng-tippy', 'power-toast', 'golfleet-file-list'])
  .component('golfleetFormTcoAccidentsCrud', new GolfleetFormTcoAccidentsCrud());
