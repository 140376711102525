import angular from 'angular';
import 'ng-redux';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-footer/power-footer';
import '../golfleet-map-suspected-accident/golfleet-map-suspected-accident';
import '@power/power-components/components/power-pagination/power-pagination';
import '../golfleet-grid-suspected-accident/golfleet-grid-suspected-accident';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-toast/power-toast';
import '@power/power-components/components/power-toolbar-report/power-toolbar-report';
import { PowerReportController } from '@power/power-components/components/power-report/power-report';

import template from './golfleet-report-suspected-accident.html';
import './golfleet-report-suspected-accident.scss';

class GolfleetReportSuspectedAccidentController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$rootScope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  constructor(
    $element,
    $scope,
    $rootScope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    Object.assign(this, { $rootScope });

    this.legendList = [];
    this.mapModeList = [
      { type: 'all', description: 'Todos os Pontos', selected: true },
      { type: 'step', description: 'Ponto a ponto', selected: false, step: 0 },
    ];
    this.mapLayerList = [
      {
        id: 0,
        description: 'Suspeitas de acidente',
        layer: 'points',
        selected: true,
      },
    ];

    this.eventConfirmationPopupData = {};
    this.messageInfo =
      'Os equipamentos podem gerar eventos de suspeita de acidente durante a instalação ou manutenção';
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();

    Object.assign(this.$, {
      setEventConfirmationPopupData: this.setEventConfirmationPopupData.bind(this),
    });

    this.$scope.$watch(() => this.reportDataset, this.__reportDatasetChanged.bind(this));
  }

  /* Public */
  changeView(viewMode, fitLayer = true) {
    const mapComponent = this.$.querySelector('#report-body-map');
    this.stateConfig.viewMode = viewMode;
    this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });

    switch (viewMode) {
      case 'map':
        this.$.setAttribute('map-view', '');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('split-view');

        if (mapComponent.getMap) {
          if (this.reportDataset.length > 0) {
            this._adjustMap(mapComponent.getMap(), fitLayer);
          } else {
            this._renderAllMode(this.reportDataset);
            mapComponent.removeLayers(['all', 'stepPoints']);
          }
        }

        break;
      case 'split':
        this.$.setAttribute('split-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('grid-view');

        if (mapComponent.getMap) {
          this._adjustMap(mapComponent.getMap(), fitLayer);
        }

        break;
      default:
        this.$.setAttribute('grid-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('split-view');
        break;
    }
  }

  changeRow(page, row) {
    if (page != this.stateConfig.gridConfig.page) {
      this.changePage(page);
    }

    if (row >= 0) {
      this._renderStepMode(row);
    }
  }

  changePageSize(pageSize) {
    super.changePageSize(pageSize);

    const step = 0;

    if (this._getSelectedMapMode().type == 'step') {
      this._renderStepMode(step);
    } else {
      this.mapModeList = this.mapModeList.map(mode =>
        mode.type == 'step' ? { ...mode, step } : mode,
      );
    }
  }

  setEventConfirmationPopupData(data) {
    this.eventConfirmationPopupData = { ...data };
  }

  _renderStepMode(index) {
    const mapComponent = this.$.querySelector('#report-body-map');
    this.mapModeList = this.mapModeList.map(mode =>
      mode.type == 'step' ? { ...mode, step: index } : mode,
    );
    const data = this.reportDataset[index];
    // mapComponent.setCurrentPoint(data);

    mapComponent
      .renderDataset({
        dataset: [{ ...data, color: data.color || data.categoryColor }],
        layerName: 'stepPoints',
      })
      .then(() => {
        mapComponent.fitLayers(['stepPoints']);
        if (!this.$.hasAttribute('grid-view')) {
          mapComponent.openPopup(0, 'stepPoints');
        }
      });
  }
  /* */

  /* Private */
  _validateAction(action) {
    if (action.actionType == 'edit') {
      if (this.selectedRows.length != 1) return true;
      return false;
    }
    return false;
  }

  _toggleMapLayer(mapLayer) {
    const mapComponent = this.$.querySelector('#report-body-map');
    mapLayer.selected = !mapLayer.selected;
    if (mapLayer.selected) this._renderAllMode(this.reportDataset);
    else {
      mapComponent.removeLayers([mapLayer.layer]);
      if (!mapComponent.hasAttribute('draw')) mapComponent.removeLayers(['stepPoints']);
    }
  }

  async _renderAllMode(dataset) {
    this._showGlobalLoader(true);

    const mapComponent = this.$.querySelector('#report-body-map');

    await mapComponent.renderDataset({
      dataset: dataset.filter(data => data.latitude && data.longitude),
      layerName: 'all',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#2196f3',
      icon: 'gs_fleet',
    });

    mapComponent.resizeMap();
    mapComponent.zoomTo({ latitude: -20.933402286553182, longitude: -44.95093750000001 }, 4);

    this._showGlobalLoader(false);
  }

  _showGlobalLoader(state = false) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: state },
        bubbles: true,
        composed: true,
      }),
    );
  }

  _setEventConfimation() {
    return this.$http({
      url: `${this.urlApi}/SuspectedAccident/ToggleEventConfirmation`,
      method: 'POST',
      data: {
        request: {
          mrvId: this.eventConfirmationPopupData.mrvId,
          veiId: this.eventConfirmationPopupData.idVeiculo,
          comentario: this.$.querySelector('#popup-event-confirmation-comment').value,
          eventoId: this.eventConfirmationPopupData.eventoId,
        },
      },
    }).then(() => {
      this._closeEventConfirmationPopup();
      this.requestDataset();
    });
  }

  _closeEventConfirmationPopup() {
    this.$.querySelector('#popup-event-confirmation').toggle();
    this.eventConfirmationPopupData = {};
  }

  _goToLink(evt, evtParams) {
    if (evtParams.routeLink == 'map') {
      if (this.stateConfig.viewMode != 'map' && this.stateConfig.viewMode != 'split') {
        if (window.innerWidth > 878) this.changeView('split');
        else this.changeView('map');
      } else {
        const mapComponent = this.$.querySelector('#report-body-map');

        if (!evtParams.tableRowData.latitude || !evtParams.tableRowData.longitude) {
          Object.assign(this, {
            toastText: 'Suspeita sem latitude/longitude',
          });

          mapComponent.resizeMap();
          mapComponent.removeLayers(['stepMarker']);
          mapComponent.zoomTo({ latitude: -15.34, longitude: -53.74 }, 4);
          this.$.querySelector('power-toast#report-toast').toggle(true);
        }

        mapComponent.zoomTo(evtParams.tableRowData);
      }

      const gridElement = this.$.querySelector('#report-body-grid');

      gridElement.setActiveRow({ index: evtParams.index }).then(() => {
        this.selectedVehicle = gridElement.getActiveRow();
      });
    } else {
      super._goToLink(evt, evtParams);
    }
  }

  _getStepPosition() {
    return this.mapModeList.filter(mode => mode.type == 'step')[0].step;
  }

  _adjustMap(map, fitLayer) {
    this.$.querySelector('#report-body-map').resizeMap();

    if (!fitLayer) return;

    this.$.querySelector('#report-body-map').fitLayers(['all']);
  }

  _validateRenderInToggle() {
    const mapLayerPoint = this.mapLayerList.find(map => map.layer == 'points');
    if (mapLayerPoint.selected) this._renderAllMode(this.reportDataset);
    else this._toggleMapLayer({ ...mapLayerPoint, selected: true });
  }

  __onRequestSyncVisualization(evt) {
    super.__onRequestSyncVisualization(evt);

    const gridElement = this.$.querySelector('#report-body-grid');
    const activeRow = gridElement.getActiveRow();

    this._renderStepMode(activeRow._index);
  }

  /* */

  /* Observers */
  async __reportDatasetChanged(newValue) {
    if (newValue && newValue.length > 0) {
      await this.$.querySelector('#report-body-map').awaitRender();
      this._renderAllMode(this.reportDataset);
    }
  }
  /* */
}

class GolfleetReportSuspectedAccident {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetReportSuspectedAccidentController;
  }
}

angular
  .module('golfleet-report-suspected-accident', [
    'ngRedux',
    'power-fab',
    'power-dropdown',
    'power-footer',
    'golfleet-grid-suspected-accident',
    'golfleet-map-suspected-accident',
    'power-pagination',
    'power-popup',
    'power-toast',
    'power-toolbar-report',
  ])
  .component('golfleetReportSuspectedAccident', new GolfleetReportSuspectedAccident());
