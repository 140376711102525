/* eslint-disable linebreak-style */
import angular from 'angular';
import 'angular-easy-masks';

import template from './power-crud-signature.html';
import './power-crud-signature.scss';

class PowerCrudSignatureController {
  static get $inject() {
    return ['$element', '$scope', '$rootScope', '$timeout'];
  }

  constructor($element, $scope, $rootScope, $timeout) {
    Object.assign(this, { $: $element[0], $scope, $rootScope, $timeout });

    this.disabled = false;

    this.canvas = null;
    this.ctx = null;
    this.drawing = false;
    this.lastPos = { x: 0, y: 0 };

    this.__mouseupEventInstance = this.stopDrawing.bind(this);
    this.__mouseoutEventInstance = this.stopDrawing.bind(this);
    this.__mousemoveEventInstance = this.draw.bind(this);

    this.__touchendEventInstance = this.stopDrawing.bind(this);
    this.__touchmoveEventInstance = this.draw.bind(this);

    this.__preventScrollEvent = this.preventDefaultScroll.bind(this);
  }

  // #region Lifecycle
  $onInit() {
    Object.assign(this.$, {
      setWarning: this.setWarning.bind(this),
      setDisabled: this.setDisabled.bind(this),
    });

    window.addEventListener('resize', this.resizeCanvas.bind(this));

    this.initCanvas();
  }

  $onDestroy() {
    // Remove o ouvinte de redimensionamento ao destruir o componente
    window.removeEventListener('resize', this.resizeCanvas.bind(this));
  }
  // #endregion Lifecycle

  // #region Public
  setWarning(text) {
    this.warning = text;
  }

  setDisabled(value) {
    this.disabled = value;
  }

  // Inicializa o canvas
  initCanvas() {
    setTimeout(() => {
      if (!this.photoUrl) {
        this.canvas = this.$.querySelector('#signatureCanvas');
        this.ctx = this.canvas.getContext('2d');
        this.resizeCanvas();

        // Adiciona os eventos para desenhar
        this.canvas.addEventListener('mousedown', this.startDrawing.bind(this));

        // Adiciona os eventos de toque para dispositivos móveis
        this.canvas.addEventListener('touchstart', this.startDrawing.bind(this));

        this.initialImageData = this.canvas.toDataURL();
      }
    }, 300);
  }

  // Redimensiona o canvas
  resizeCanvas() {
    if (this.canvas.offsetWidth > 0 && this.canvas.offsetHeight > 0) {
      this.canvas.width = this.canvas.offsetWidth;
      this.canvas.height = this.canvas.offsetHeight;
      this.clearCanvas();
    } else {
      this.$timeout(() => {
        this.resizeCanvas();
      });
    }
  }

  // Inicia o desenho
  startDrawing(event) {
    this.drawing = true;

    this.disableScroll();

    this.canvas.addEventListener('mouseup', this.__mouseupEventInstance);
    this.canvas.addEventListener('mouseout', this.__mouseoutEventInstance);
    this.canvas.addEventListener('mousemove', this.__mousemoveEventInstance);

    this.canvas.addEventListener('touchend', this.__touchendEventInstance);
    this.canvas.addEventListener('touchmove', this.__touchmoveEventInstance);

    this.lastPos = this.getMousePos(this.canvas, event);
  }

  // Para o desenho
  stopDrawing() {
    this.drawing = false;

    this.enableScroll();

    this.canvas.removeEventListener('mouseup', this.__mouseupEventInstance, true);
    this.canvas.removeEventListener('mouseout', this.__mouseoutEventInstance, true);
    this.canvas.removeEventListener('mousemove', this.__mousemoveEventInstance, true);

    this.canvas.removeEventListener('touchend', this.__touchendEventInstance, true);
    this.canvas.removeEventListener('touchmove', this.__touchmoveEventInstance, true);

    this.ctx.beginPath(); // Reinicia o caminho para evitar traços contínuos

    this.saveSignature();
  }

  // Desenha no canvas
  draw(event) {
    if (!this.drawing) return;

    const mousePos = this.getMousePos(this.canvas, event);
    this.ctx.lineWidth = 2;
    this.ctx.lineCap = 'round'; // Define o formato das extremidades da linha
    this.ctx.strokeStyle = '#000';

    this.ctx.lineTo(mousePos.x, mousePos.y);
    this.ctx.stroke();

    // Atualiza a posição do último ponto
    this.lastPos = mousePos;

    // Inicia um novo caminho
    this.ctx.beginPath();
    this.ctx.moveTo(this.lastPos.x, this.lastPos.y);
  }

  // Obtém a posição do mouse/touch
  getMousePos(canvas, event) {
    const rect = canvas.getBoundingClientRect();
    return {
      x: (event.clientX || event.touches[0].clientX) - rect.left,
      y: (event.clientY || event.touches[0].clientY) - rect.top,
    };
  }

  disableScroll() {
    window.addEventListener('touchmove', this.__preventScrollEvent, { passive: false });
    window.addEventListener('wheel', this.__preventScrollEvent, { passive: false });
  }

  enableScroll() {
    window.removeEventListener('touchmove', this.__preventScrollEvent, { passive: false });
    window.removeEventListener('wheel', this.__preventScrollEvent, { passive: false });
  }

  preventDefaultScroll(event) {
    event.preventDefault();
  }

  // Limpa o canvas
  clearCanvas() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.value = null;
    this._requestUpdate();
    this._onValueChanged(this.value);
  }

  // Salva a assinatura como imagem
  saveSignature() {
    this.value = this.canvas.toDataURL();
    if (this.value !== this.initialImageData) {
      this._requestUpdate();
      this._onValueChanged(this.value);
    } else {
      this.clearCanvas();
    }
  }

  _requestUpdate() {
    if (!this.$scope.$$phase && !this.$rootScope.$$phase) {
      this.$scope.$apply();
    }
  }

  _onValueChanged(newValue) {
    if (this.triggerOf) {
      this.$scope.$emit('triggerRequest', {
        triggerKey: this.key,
        triggerOf: this.triggerOf,
        triggerValue: newValue,
        triggerCondition: this.triggerCondition,
      });
    }
  }
}

class PowerCrudSignature {
  constructor() {
    this.template = template;
    this.bindings = {
      value: '=?',
      name: '=',
      hint: '=?',
      warning: '=?',
      disabled: '=?',
      triggerOf: '=?',
      photoUrl: '=?',
    };
    this.controller = PowerCrudSignatureController;
  }
}

angular
  .module('power-crud-signature', ['wt.easy'])
  .component('powerCrudSignature', new PowerCrudSignature());
