import 'ng-redux';

import '../../directives/ng-resize/ng-resize';
import '../../directives/ng-tippy/ng-tippy';

import { PowerReportController } from '../power-report/power-report';

import './power-report-ranking-details-speed-limit.scss';

class PowerReportRankingDetailsSpeedLimitController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    this.legendList = [];
    this.mapModeList = [
      { type: 'all', description: 'Todos os Pontos', selected: true },
      { type: 'heat', description: 'Mapa de Calor', selected: false },
      { type: 'step', description: 'Ponto a ponto', selected: false, step: 0 },
    ];

    this.listType = null;
    this.justificationInputValue = null;
    this.isBlockSuccessContent = false;
    this.toastSpeedLimit = {
      text: null,
    };
    this.pluralTexts = false;
    this.canSubmit = true;
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();

    this.$scope.$on('actionRequest', this._onActionRequest.bind(this));
    this.$scope.$on('actionFullscreen', this._onActionFullscreen.bind(this));
    this.$scope.$on('requestShowToast', this._onRequestShowToast.bind(this));

    this.$scope.$watch(() => this.reportDataset, this.__reportDatasetChanged.bind(this));
  }
  /* */

  /* Public */
  changeView(viewMode) {
    this.stateConfig.viewMode = viewMode;
    this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });
    switch (viewMode) {
      case 'map':
        this.$.setAttribute('map-view', '');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('split-view');
        if (this.$.querySelector('#report-body-map').getMap) this._adjustMap();
        break;
      case 'split':
        this.$.setAttribute('split-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('grid-view');
        if (this.$.querySelector('#report-body-map').getMap) this._adjustMap();
        break;
      default:
        this.$.setAttribute('grid-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('split-view');
        break;
    }
  }

  changePage(page) {
    super.changePage(page);

    const gridElement = this.$.querySelector('#report-body-grid');
    const step = this.stateConfig.gridConfig.pageSize * (page - 1);

    gridElement.setActiveRow({ index: step });

    if (this._getSelectedMapMode().type == 'step') {
      this._renderStepMode(step);
    } else {
      this.mapModeList = this.mapModeList.map(mode =>
        mode.type == 'step' ? { ...mode, step } : mode,
      );
    }
  }

  changeRow(page, row) {
    if (page != this.stateConfig.gridConfig.page) {
      this.changePage(page);
    }

    if (row >= 0) {
      this._renderStepMode(row);
    }
  }

  changePageSize(pageSize) {
    super.changePageSize(pageSize);

    const step = 0;

    if (this._getSelectedMapMode().type == 'step') {
      this._renderStepMode(step);
    } else {
      this.mapModeList = this.mapModeList.map(mode =>
        mode.type == 'step' ? { ...mode, step } : mode,
      );
    }
  }
  /* */

  /* Private */
  _callAction(action) {
    switch (action.actionType) {
      case 'share':
        this.$.querySelector('power-popup-share').setData({
          type: this.stateConfig.type,
          getMethod: action.getDataMethod,
          shareMethod: action.actionMethod,
          objects: this.selectedRows.map(row => ({ objectId: row.id })),
        });
        this.$.querySelector('power-popup-share').toggle();
        break;
      case 'edit':
        this._evtClickPopupViolationModify();
        break;
      default:
        break;
    }
  }

  _goToLink(evt, evtParams) {
    if (evtParams.routeLink == 'map') {
      if (this.stateConfig.viewMode != 'map' && this.stateConfig.viewMode != 'split') {
        if (window.innerWidth > 878) this.changeView('split');
        else this.changeView('map');
      } else {
        this.$.querySelector('#report-body-map').zoomTo(evtParams.tableRowData);
      }

      if (this._getSelectedMapMode().type != 'step') {
        this._selectMapMode(this.mapModeList.filter(mode => mode.type == 'step')[0]);
      }

      const gridElement = this.$.querySelector('#report-body-grid');

      gridElement.setActiveRow({ index: evtParams.index }).then(() => {
        this.selectedVehicle = gridElement.getActiveRow();
      });
    } else {
      super._goToLink(evt, evtParams);
    }
  }

  _selectMapMode(mapMode) {
    if (mapMode.selected) return;
    this.mapModeList = this.mapModeList.map(mode => ({
      ...mode,
      selected: mode.type == mapMode.type,
    }));
    switch (mapMode.type) {
      case 'all':
        this.$.querySelector('#report-body-map').removeLayers(['stepMarker', 'heatLayer']);
        this._renderAllMode(this.reportDataset);
        break;
      case 'heat':
        this.$.querySelector('#report-body-map').removeLayers(['all', 'stepMarker']);
        this._renderHeatMode(this.reportDataset);
        break;
      case 'step':
        this.$.querySelector('#report-body-map').removeLayers(['all', 'heatLayer']);
        this.changeRow(this.stateConfig.gridConfig.page, mapMode.step);
        break;
      default:
        break;
    }
  }

  async _renderAllMode(dataset) {
    const mapComponent = this.$.querySelector('#report-body-map');

    await mapComponent.renderDataset({
      dataset: dataset
        .map(data =>
          Object.assign(data, {
            color: '#2196f3',
            icon: data.icon || 'directions_car',
            markerIcon: 'MarkerIcon',
          }),
        )
        .filter(data => data.latitude && data.longitude),
      layerName: 'all',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#2196f3',
    });

    mapComponent.resizeMap();
    mapComponent.zoomTo({ latitude: -20.933402286553182, longitude: -44.95093750000001 }, 4);
  }

  async _renderHeatMode(dataset) {
    const mapComponent = this.$.querySelector('#report-body-map');
    await mapComponent.renderDataset({ dataset, layerName: 'heatLayer', type: 'HeatLayer' });
    mapComponent.resizeMap();
    mapComponent.fitLayers([], 'heatLayer');
  }

  async _renderStepMode(index) {
    const mapComponent = this.$.querySelector('#report-body-map');
    this.mapModeList = this.mapModeList.map(mode =>
      mode.type == 'step' ? { ...mode, step: index } : mode,
    );
    const dataPosition = this.reportDataset[index];

    if (!dataPosition.latitude || !dataPosition.longitude) {
      Object.assign(this, {
        toastText: 'Infrações sem latitude/longitude',
      });

      mapComponent.resizeMap();
      mapComponent.removeLayers(['stepMarker']);
      mapComponent.zoomTo({ latitude: -15.34, longitude: -53.74 }, 4);
      this.$.querySelector('power-toast#report-toast').toggle(true);
      return;
    }

    Object.assign(dataPosition, {
      color: '#007bff',
      icon: dataPosition.icon || 'directions_car',
      markerIcon: 'MarkerIcon',
    });

    await mapComponent.renderDataset({
      dataset: [dataPosition],
      layerName: 'stepMarker',
      type: 'MarkerFeatureGroup',
    });

    mapComponent.fitLayers(['stepMarker']);
    mapComponent.openPopup(0, 'stepMarker');
  }

  _getSelectedMapMode() {
    return this.mapModeList.filter(mode => mode.selected)[0];
  }

  _getStepPosition() {
    return this.mapModeList.filter(mode => mode.type == 'step')[0].step;
  }

  _formatLatLng(str) {
    return str.replace(',', '.');
  }

  _adjustMap() {
    const mapComponent = this.$.querySelector('#report-body-map');
    mapComponent.resizeMap();
    switch (this._getSelectedMapMode().type) {
      case 'all':
        mapComponent.zoomTo({ latitude: -20.933402286553182, longitude: -44.95093750000001 }, 4);
        break;
      case 'heat':
        mapComponent.fitLayers([], 'heatLayer');
        break;
      case 'step':
        mapComponent.fitLayers(['stepMarker']);
        break;
      default:
        break;
    }
  }

  _onActionRequest(evt, data) {
    this.reportServices.callApiMethod(data.method, data.json).then(
      () => {
        this.$.querySelector('power-toast#report-toast').toggle(true);
        Object.assign(this, { toastText: data.text });
        this.$timeout(() => this.$scope.$broadcast('getDataset'));
      },
      () => {},
    );
  }

  _popupViolationModify(action) {
    switch (action) {
      case 'confirm': {
        if (!this.justificationInputValue) {
          this.toastSpeedLimit.text = 'O campo de justificativa precisa ser preenchido';
          this.$.querySelector('#toast-speedlimit-ranking').toggle(true);
          return;
        }
        if (this.justificationInputValue.length > 500) {
          this.toastSpeedLimit.text = 'A justificativa não pode exceder 500 caractéres';
          this.$.querySelector('#toast-speedlimit-ranking').toggle(true);
          return;
        }
        if (this.justificationInputValue.length < 10) {
          this.toastSpeedLimit.text = 'A justificativa precisa ter pelo menos 10 caractéres';
          this.$.querySelector('#toast-speedlimit-ranking').toggle(true);
          return;
        }

        const occurrenceIds = this.selectedRows.map(occurrence => occurrence.occurrenceId);

        if (!this.canSubmit) {
          return;
        }

        this.canSubmit = false;

        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );

        this.$http({
          url: `${this.urlApi}/SpeedLimitModule/UpdateViolationStatus`,
          method: 'POST',
          data: {
            request: {
              occurrenceId: occurrenceIds.toString(),
              isConsiderate: !this.checkViolationDesconsider,
              reason: this.justificationInputValue,
            },
          },
        }).then(success => {
          const response = success.data.data;

          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          );

          this.canSubmit = true;

          if ((success.status && success.status !== 200) || !response) {
            this.toastSpeedLimit.text =
              'Ocorreu um erro ao processar sua solicitação, por favor tente novamente';
            this.$.querySelector('#toast-speedlimit-ranking').toggle(true);

            return;
          }

          this.pluralTexts = this.selectedRows.length > 1;

          const grid = this.$.querySelector('power-grid-speed-limit');
          grid.updateGridSelectedRows(occurrenceIds, this.checkViolationDesconsider);
          this.selectedRows = [];

          setTimeout(() => {
            this.checkViolationDesconsider = false;
            this.justificationInputValue = null;
          }, 300);

          this._switchBlockContent();
        });
        break;
      }

      case 'cancel':
        setTimeout(() => {
          this.justificationInputValue = null;
          this.checkViolationDesconsider = false;
          this.isBlockSuccessContent = false;
          this.pluralTexts = false;
        }, 300);

        this._evtClickPopupViolationModify();
        break;

      default:
        break;
    }
  }

  _switchBlockContent() {
    this.isBlockSuccessContent = !this.isBlockSuccessContent;
  }

  _onRequestShowToast(evt, evtParams) {
    this.$.querySelector('power-toast#report-toast').toggle(true);
    Object.assign(this, { toastText: evtParams.text });
  }

  _onActionFullscreen() {
    this.$timeout(() => this.$scope.$broadcast('getDataset'));
  }

  _clickCheckboxViolation() {
    this.checkViolationDesconsider = !this.checkViolationDesconsider;
  }

  _evtClickPopupViolationModify() {
    this.$.querySelector('#popup-violation-modify').toggle();
  }
  /* */

  /* Observers */
  __reportDatasetChanged(newValue, oldValue) {
    let shouldRender = !oldValue;
    if (oldValue) {
      if (newValue.length != oldValue.length) shouldRender = true;
      else {
        const oldViolation = oldValue.reduce((acc, row, index) => {
          acc[row.occurrenceId] = index;
          return acc;
        }, {});
        for (let index = 0; index < newValue.length; index += 1) {
          if (!(newValue[index].occurrenceId in oldViolation)) {
            shouldRender = true;
            break;
          }
        }
      }
    }

    if (newValue && shouldRender)
      this._selectMapMode(Object.assign(this._getSelectedMapMode(), { selected: false }));
  }

  __onRequestSyncVisualization(evt) {
    super.__onRequestSyncVisualization(evt);

    const gridElement = this.$.querySelector('#report-body-grid');
    const activeRow = gridElement.getActiveRow();

    this._renderStepMode(activeRow._index);
  }
  /* */
}

export { PowerReportRankingDetailsSpeedLimitController };
