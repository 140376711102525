import angular from 'angular';

import template from './power-popup-defaulter-warning.html';
import './power-popup-defaulter-warning.scss';
import '../../directives/ng-tippy/ng-tippy';

import { ImportRouteConfig } from '../../utils/get-route-config';

class PowerPopupDefaulterWarningController {
  static get $inject() {
    return ['$element', '$http', 'urlApi', '$state', '$ngRedux'];
  }

  constructor($element, $http, urlApi, $state, $ngRedux) {
    Object.assign(this, { $: $element[0], $http, urlApi, $state, $ngRedux });
    this.defaulterVehicleListLength = 0;
    this.firstDefaulterExpirationDate = '';
    this.defaulterVehicleList = null;

    this.restrictionVehicleListLength = 0;
    this.firstRestrictionExpirationDate = '';
    this.restrictionVehicleList = null;

    this.defaulterWarningIgnore = false;
    this.queryMobile = window.matchMedia('(max-width: 425px)');

    this.buttonTootipMessage = 'Baixar lista em XLSX';

    this.isAdm = false;
    this.endReportWarningIgnore = false;
  }

  $onInit() {
    Object.assign(this.$, {
      setup: this.setup.bind(this),
      closepopup: this.closepopup.bind(this),
      showShutDownNotification: this.showShutDownNotification.bind(this),
      terminate: this.terminate.bind(this),
    });
  }

  setup(isAdm) {
    this.isAdm = isAdm;
    this.$http({
      url: `${this.urlApi}/Defaulter/GetDefaultNotices`,
      method: 'POST',
      data: {
        request: {
          isAdm,
        },
      },
    }).then(success => {
      if (success.status && success.status !== 200) {
        return;
      }
      if (success.data.data) {
        this.defaulterVehicleListLength = success.data.data.defaultersVehicleQuantity;
        this.firstDefaulterExpirationDate = success.data.data.firstDefaulterExpirationDate;
        this.defaulterVehicleList = success.data.data.defaultersList;

        this.restrictionVehicleListLength = success.data.data.restrictionsVehicleQuantity;
        this.firstRestrictionExpirationDate = success.data.data.firstRestrictionExpirationDate;
        this.restrictionVehicleList = success.data.data.restrictionsList;

        const defaulterWarningIgnoreDate = window.localStorage.getItem('defaulter-warning-ignore');

        if (this.queryMobile.matches) {
          if (
            this.defaulterVehicleListLength > 0 &&
            (!defaulterWarningIgnoreDate ||
              (defaulterWarningIgnoreDate &&
                new Date().getDate() > new Date(defaulterWarningIgnoreDate).getDate()))
          ) {
            this._addNotification(
              this.$.querySelector('#defaulter-notification'),
              this.restrictionVehicleListLength > 0
                ? () => this._addNotification(this.$.querySelector('#restriction-notification'))
                : () => this._removeNotification('#restriction-notification'),
            );
          } else if (this.restrictionVehicleListLength > 0) {
            this._addNotification(this.$.querySelector('#restriction-notification'));
          }
        } else {
          if (
            this.defaulterVehicleListLength > 0 &&
            (!defaulterWarningIgnoreDate ||
              (defaulterWarningIgnoreDate &&
                new Date().getDate() > new Date(defaulterWarningIgnoreDate).getDate()))
          ) {
            this._addNotification(this.$.querySelector('#defaulter-notification'));
          }

          if (this.restrictionVehicleListLength > 0) {
            this._addNotification(this.$.querySelector('#restriction-notification'));
          }
        }
      }
    });
  }

  closepopup(id) {
    this._removeNotification(id);
  }

  async showShutDownNotification() {
    this.__powerStore = this.$ngRedux.connect(store =>
      Object({
        routeLink: store.state.routeList[store.state.routeList.length - 1]?.routeLink,
      }),
    )(this);

    const routeConfig = await ImportRouteConfig(this.routeLink);
    if (routeConfig) {
      const metadata = routeConfig ? routeConfig.__metadata__ : {};
      this.endReportDate = metadata.endReportDate;
      this.newReportModule = metadata.newReportModuleName;
      const newRoute = await ImportRouteConfig(metadata.newReportRouteName);
      this.newReportName = newRoute.data.routeName;
      this.newReportLink = newRoute.data.routeLink;
      this.routeReportLink = routeConfig.data.routeLink;
    }
    if (this.newReportName) {
      const endReportWarningIgnoreDate = window.localStorage.getItem(
        `endReportWarningIgnore_${this.routeReportLink}`,
      );
      if (
        !endReportWarningIgnoreDate ||
        (endReportWarningIgnoreDate &&
          new Date().getDate() > new Date(endReportWarningIgnoreDate).getDate())
      ) {
        this.endReportWarningIgnore = false;
        this.$.querySelector('#report-shutdown').toggle();
      }
    }
  }

  terminate() {
    this.$.querySelectorAll('#notification-stack > .notification-container').forEach(element => {
      this.$.appendChild(element);
      element.classList.remove('fade-in');
      element.classList.remove('fade-out');
    });
  }

  _closeVehicleListPopup() {
    this.$.querySelector('#popup-vehicle-list').toggle();
  }

  _closeShutdownPopup() {
    if (this.endReportWarningIgnore) {
      window.localStorage.setItem(
        `endReportWarningIgnore_${this.routeReportLink}`,
        new Date().toJSON(),
      );
    }
    this.$.querySelector('#report-shutdown').toggle();
  }

  _openVehicleListPopup(listQty, popuplist, type) {
    this.searchVehicleList = '';
    this.vehicleListPopup = {
      open: true,
      title: `${listQty} veículos`,
      icon: 'gavel',
      list: popuplist,
      pouptype: type,
    };
    this.$.querySelector('#popup-vehicle-list').toggle();
  }

  _downloadFile(reporttype) {
    let isRestrictionReport = 'TODOS';
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    if (reporttype == 'warning') {
      isRestrictionReport = 'AVISO';
    } else {
      isRestrictionReport = 'RESTRICAO';
    }

    this.$http({
      url: `${this.urlApi}/Defaulter/Export`,
      method: 'POST',
      data: {
        request: {
          isAdm: this.isAdm,
          defaulterReportType: isRestrictionReport,
        },
      },
    })
      .then(
        async success => {
          const blob = await (
            await fetch(`data:'application/octet-stream';base64,${success.data.data.contentBase64}`)
          ).blob();
          const fileUrl = window.URL.createObjectURL(blob, { type: success.data.data.contentType });
          const downloadLink = document.createElement('a');

          downloadLink.download = `${success.data.data.fileName}${success.data.data.extension}`;
          downloadLink.href = fileUrl;
          downloadLink.click();
        },
        error => error,
      )
      .finally(() => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
      });
  }

  _addNotification(element, ondismiss) {
    const notificationStack = this.$.querySelector('#notification-stack');

    element.classList.add('fade-in');
    element.setAttribute('role', 'status');

    if (ondismiss) {
      element.ondismiss = () => ondismiss();
    }

    if (notificationStack.children.length) {
      this._flipNotification(element);
    } else {
      notificationStack.appendChild(element);
    }
  }

  _flipNotification(element) {
    const notificationStack = this.$.querySelector('#notification-stack');

    const first = notificationStack.offsetHeight;

    notificationStack.appendChild(element);

    const last = notificationStack.offsetHeight;

    const invert = last - first;

    const animation = notificationStack.animate(
      [{ transform: `translateY(${invert}px)` }, { transform: 'translateY(0)' }],
      {
        duration: 150,
        easing: 'ease-out',
      },
    );

    animation.startTime = document.timeline.currentTime;
  }

  async _removeNotification(id) {
    const element = this.$.querySelector(id);

    if (id == '#defaulter-notification' && this.defaulterWarningIgnore) {
      window.localStorage.setItem('defaulter-warning-ignore', new Date().toJSON());
    }

    element.classList.add('fade-out');

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async resolve => {
      await Promise.all(element.getAnimations().map(animation => animation.finished))
        .then(() => {
          this.$.appendChild(element);

          element.classList.remove('fade-in');
          element.classList.remove('fade-out');

          if (element.ondismiss) {
            element.ondismiss();
          }

          resolve();
          this.endReportWarningIgnore = false;
        })
        .catch(() => {});
    });
  }

  _newReportlinkClick() {
    this._closeShutdownPopup();
    this.$state.go(this.newReportLink);
  }
}

class PowerPopupDefaulterWarning {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = PowerPopupDefaulterWarningController;
  }
}
angular
  .module('power-popup-defaulter-warning', ['ng-tippy'])
  .component('powerPopupDefaulterWarning', new PowerPopupDefaulterWarning());
