import angular from 'angular';
import moment from 'moment/src/moment';
import 'ng-redux';

import '../power-fab/power-fab';
import '../power-dropdown/power-dropdown';
import '../power-toolbar/power-toolbar';
import '../power-grid-unitary-violation/power-grid-unitary-violation';
import '../power-map-violation/power-map-violation';
import '../power-photo-indexation/power-photo-indexation';
import '../power-footer/power-footer';
import '../power-pagination/power-pagination';
import '../power-toast/power-toast';
import '../../directives/ng-resize/ng-resize';
import { PowerReportController } from '../power-report/power-report';

import template from './power-report-unitary-violation.html';
import './power-report-unitary-violation.scss';

class PowerReportUnitaryViolationController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
      'identificationTypeServices',
    ];
  }

  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
    identificationTypeServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    Object.assign(this, { identificationTypeServices });

    this.toastText = 'Infrações sem latitude/longitude não serão exibidas no mapa.';
    this.legendList = [];
    this.mapModeList = [
      { type: 'all', description: 'Todos os Pontos', selected: true },
      { type: 'heat', description: 'Mapa de Calor', selected: false },
      { type: 'step', description: 'Ponto a ponto', selected: false, step: 0 },
    ];
    this.legacyEquipament = {
      open: false,
      message: ' equipamentos incompatíveis não constam nesta listagem',
      equipaments: { status: 0, list: [], count: 0, highlight: true },
    };
    this.updateMapDataset = false;
    this.descriptionDateFilter = '';
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();

    this.$scope.$watch(() => this.reportDataset, this.__reportDatasetChanged.bind(this));
    this.$scope.$watch(() => this.stateConfig?.filterConfig, this.__reportFilterConfig.bind(this));
    setTimeout(() => this.$.querySelector('power-toast#report-toast').toggle(true));
  }
  /* */

  /* Public */
  changeView(viewMode) {
    this.stateConfig.viewMode = viewMode;
    this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });
    switch (viewMode) {
      case 'map':
        this.$.setAttribute('map-view', '');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('split-view');
        this.$.removeAttribute('indexation-view');
        if (this.$.querySelector('#report-body-map').getMap) {
          if (this.updateMapDataset) {
            this.updateMapDataset = false;
            this._selectMapMode(Object.assign(this._getSelectedMapMode(), { selected: false }));
          }
          this._adjustMap();
        }
        break;
      case 'split':
        this.$.setAttribute('split-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('indexation-view');
        if (this.$.querySelector('#report-body-map').getMap) {
          if (this.updateMapDataset) {
            this.updateMapDataset = false;
            this._selectMapMode(Object.assign(this._getSelectedMapMode(), { selected: false }));
          }
          this._adjustMap();
        }
        break;
      case 'indexation':
        this.$.setAttribute('indexation-view', '');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('split-view');
        break;
      default:
        this.$.setAttribute('grid-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('split-view');
        this.$.removeAttribute('indexation-view');
        break;
    }
  }

  changePage(page) {
    super.changePage(page);

    const gridElement = this.$.querySelector('#report-body-grid');
    const step = this.stateConfig.gridConfig.pageSize * (page - 1);

    gridElement.setActiveRow({ index: step });

    if (this._getSelectedMapMode().type == 'step') {
      this._renderStepMode(step);
    } else {
      this.mapModeList = this.mapModeList.map(mode =>
        mode.type == 'step' ? { ...mode, step } : mode,
      );
    }
  }

  changeRow(page, row) {
    if (page != this.stateConfig.gridConfig.page) {
      this.changePage(page);
    }

    if (row >= 0) {
      this._renderStepMode(row);
    }
  }

  changePageSize(pageSize) {
    super.changePageSize(pageSize);

    const step = 0;

    if (this._getSelectedMapMode().type == 'step') {
      this._renderStepMode(step);
    } else {
      this.mapModeList = this.mapModeList.map(mode =>
        mode.type == 'step' ? { ...mode, step } : mode,
      );
    }
  }

  evtClickControlPopupLegacyEquipament(method) {
    this.legacyEquipament.open = method != 'close';
    if (this.legacyEquipament.open)
      this.commonServices.clickCountLegacyEquipaments().then(success => {
        this.legacyEquipament.equipaments.highlight =
          success.data && !success.data.hasError ? success.data.data : false;
      });
  }
  /* */

  /* Private */
  _getDateWithoutUTF(date, modifier = 1) {
    return moment(date).utcOffset((modifier * moment(date).utcOffset()) / 60);
  }

  _callAction(action) {
    switch (action.actionType) {
      case 'selectAllOrNone':
        this.$.querySelector('#report-body-indexation').selectAllOrNone();
        break;
      case 'toggleSelection':
        this.$.querySelector('#report-body-indexation').toggleSelectedItems();
        break;
      case 'multiIndexation':
        this.$.querySelector('#report-body-indexation').showIndexationOptions();
        break;
      default:
        super._callAction(action);
        break;
    }
  }

  _goToLink(evt, evtParams) {
    if (evtParams.routeLink == 'map') {
      if (this._getSelectedMapMode().type != 'step') {
        this._selectMapMode(this.mapModeList.filter(mode => mode.type == 'step')[0]);
      }

      if (this.stateConfig.viewMode != 'map' && this.stateConfig.viewMode != 'split') {
        if (window.innerWidth > 878) this.changeView('split');
        else this.changeView('map');
      } else {
        const mapComponent = this.$.querySelector('#report-body-map');

        if (!evtParams.tableRowData.latitude || !evtParams.tableRowData.longitude) {
          Object.assign(this, {
            toastText: 'Infrações sem latitude/longitude',
          });

          mapComponent.resizeMap();
          mapComponent.removeLayers(['stepMarker']);
          mapComponent.zoomTo({ latitude: -15.34, longitude: -53.74 }, 4);
          return this.$.querySelector('power-toast#report-toast').toggle(true);
        }

        mapComponent.zoomTo(evtParams.tableRowData);
      }

      const gridElement = this.$.querySelector('#report-body-grid');

      gridElement.setActiveRow({ index: evtParams.index }).then(() => {
        this.selectedVehicle = gridElement.getActiveRow();
      });
    } else {
      super._goToLink(evt, evtParams);
    }

    return null;
  }

  _selectMapMode(mapMode) {
    if (mapMode.selected) return;
    const mapComponent = this.$.querySelector('#report-body-map');
    this.mapModeList = this.mapModeList.map(mode => ({
      ...mode,
      selected: mode.type == mapMode.type,
    }));
    switch (mapMode.type) {
      case 'all':
        mapComponent.removeLayers(['heatLayer', 'stepMarker']);
        this._renderAllMode(this.reportDataset.filter(data => data.latitude && data.longitude));
        break;
      case 'heat':
        mapComponent.removeLayers(['all', 'stepMarker']);
        this._renderHeatMode(this.reportDataset.filter(data => data.latitude && data.longitude));
        break;
      case 'step':
        mapComponent.removeLayers(['all', 'heatLayer']);
        this.changeRow(this.stateConfig.gridConfig.page, mapMode.step);
        break;
      default:
        break;
    }
  }

  async _renderAllMode(dataset) {
    this._showGlobalLoader(true);

    const mapComponent = this.$.querySelector('#report-body-map');

    await mapComponent.renderDataset({
      dataset: dataset.filter(data => data.latitude && data.longitude),
      layerName: 'all',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#2196f3',
      // icon: 'directions_car',
    });

    mapComponent.resizeMap();
    mapComponent.zoomTo({ latitude: -20.933402286553182, longitude: -44.95093750000001 }, 4);

    this._showGlobalLoader(false);
  }

  async _renderHeatMode(dataset) {
    const mapComponent = this.$.querySelector('#report-body-map');
    await mapComponent.renderDataset({ dataset, layerName: 'heatLayer', type: 'HeatLayer' });
    mapComponent.resizeMap();
    mapComponent.fitLayers([], 'heatLayer');
  }

  async _renderStepMode(index) {
    const mapComponent = this.$.querySelector('#report-body-map');
    this.mapModeList = this.mapModeList.map(mode =>
      mode.type == 'step' ? { ...mode, step: index } : mode,
    );
    const dataPosition = this.reportDataset[index];

    if (!dataPosition.latitude || !dataPosition.longitude) {
      Object.assign(this, {
        toastText: 'Infrações sem latitude/longitude',
      });

      mapComponent.resizeMap();
      mapComponent.removeLayers(['stepMarker']);
      mapComponent.zoomTo({ latitude: -15.34, longitude: -53.74 }, 4);
      this.$.querySelector('power-toast#report-toast').toggle(true);
      return;
    }

    await mapComponent.renderDataset({
      dataset: [dataPosition],
      layerName: 'stepMarker',
      type: 'MarkerFeatureGroup',
    });

    mapComponent.fitLayers(['stepMarker']);
    mapComponent.openPopup(0, 'stepMarker');
  }

  _getSelectedMapMode() {
    return this.mapModeList.filter(mode => mode.selected)[0];
  }

  _getStepPosition() {
    return this.mapModeList.filter(mode => mode.type == 'step')[0].step;
  }

  _formatLatLng(str) {
    return str.replace(',', '.');
  }

  _adjustMap() {
    const mapComponent = this.$.querySelector('#report-body-map');
    mapComponent.resizeMap();
    switch (this._getSelectedMapMode().type) {
      case 'all':
        mapComponent.zoomTo({ latitude: -20.933402286553182, longitude: -44.95093750000001 }, 4);
        break;
      case 'heat':
        mapComponent.fitLayers([], 'heatLayer');
        break;
      case 'step':
        mapComponent.fitLayers(['stepMarker']);
        mapComponent.openPopup(0, 'stepMarker');
        break;
      default:
        break;
    }
  }

  _getIdentificationType() {
    this.identificationTypeServices.getIdentificationType().then(success => {
      if (success.data && !success.data.hasError && success.data.data.length > 0)
        this.legendList.push({
          title: 'Ident.',
          icons: success.data.data,
        });
    });
  }

  _showGlobalLoader(state = false) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: state },
        bubbles: true,
        composed: true,
      }),
    );
  }
  /* */

  /* Observers */
  __reportDatasetChanged(newValue) {
    if (newValue) {
      this.legendList = this.stateConfig.gridConfig.gridHeaders.reduce(
        (acc, ele) => (ele.icons ? acc.concat(ele) : acc),
        [],
      );

      this._getIdentificationType();

      if (newValue.length > 0) {
        if (this.stateConfig.viewMode === 'grid') {
          this.updateMapDataset = true;
        } else {
          this._selectMapMode(Object.assign(this._getSelectedMapMode(), { selected: false }));
        }
      } else {
        this.$.querySelector('#report-body-map').removeLayers(['all', 'heatLayer', 'stepMarker']);
      }
      if (this.isTrip) {
        this.$.querySelector('#report-body-indexation').getDataset(newValue);
      }
    }
  }

  __reportFilterConfig(newValue) {
    const dateValues = newValue.find(x => x.type === 'calendar')?.condition;
    if (dateValues) {
      const endDate = this._getDateWithoutUTF(dateValues.value[1], 0).format('DD-MM-YYYY HH:mm');
      const startDate = this._getDateWithoutUTF(dateValues.value[0], 0).format('DD-MM-YYYY HH:mm');
      this.descriptionDateFilter = `De ${startDate} até ${endDate}`;
    }
  }

  __onRequestSyncVisualization(evt) {
    super.__onRequestSyncVisualization(evt);

    const gridElement = this.$.querySelector('#report-body-grid');
    const activeRow = gridElement.getActiveRow();

    this._renderStepMode(activeRow._index);
  }
  /* */
}

class PowerReportUnitaryViolation {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = PowerReportUnitaryViolationController;
  }
}

angular
  .module('power-report-unitary-violation', [
    'ngRedux',
    'power-fab',
    'power-dropdown',
    'power-toolbar',
    'power-grid-unitary-violation',
    'power-map-violation',
    'power-photo-indexation',
    'power-footer',
    'power-pagination',
    'power-toast',
    'ng-resize',
  ])
  .component('powerReportUnitaryViolation', new PowerReportUnitaryViolation());

export { PowerReportUnitaryViolationController };
