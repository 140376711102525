import angular from 'angular';
import 'ng-redux';

import '../power-fab/power-fab';
import '../power-dropdown/power-dropdown';
import '../power-footer/power-footer';
import '../power-map-points-of-interest/power-map-points-of-interest';
import '../power-pagination/power-pagination';
import '../power-crud/power-crud';
import '../power-grid/power-grid';
import '../power-popup/power-popup';
import '../power-popup-delete/power-popup-delete';
import '../power-popup-share/power-popup-share';
import '../power-toast/power-toast';
import '../power-toolbar-report/power-toolbar-report';
import { PowerReportController } from '../power-report/power-report';

import template from './power-report-points-of-interest.html';
import './power-report-points-of-interest.scss';

class PowerReportPointsOfInterestController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$rootScope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  constructor(
    $element,
    $scope,
    $rootScope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    Object.assign(this, { $rootScope });

    this.legendList = [];
    this.mapModeList = [
      { type: 'all', description: 'Todos os Pontos', selected: true },
      { type: 'step', description: 'Ponto a ponto', selected: false, step: 0 },
    ];
    this.mapLayerList = [
      {
        id: 0,
        description: 'Pontos de Interesse',
        layer: 'points',
        selected: true,
      },
    ];
    this.cantDeletePoints = [];
    this.deletePoints = [];
    this.address = null;
    this.onDeleteEvent = this._deleteSelectedItens.bind(this);
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();

    this.$.addEventListener('mapLoaded', () =>
      this.$.querySelector('#report-body-map')
        .getMap()
        .onMoveEnd(() =>
          this.mapLayerList.map(mapLayer => {
            if (mapLayer.selected) this._toggleMapLayer({ ...mapLayer, selected: false });

            return mapLayer;
          }),
        ),
    );

    this.$scope.$watch(() => this.reportDataset, this.__reportDatasetChanged.bind(this));
  }

  /* Public */
  changeView(viewMode, fitLayer = true) {
    const mapComponent = this.$.querySelector('#report-body-map');
    this.stateConfig.viewMode = viewMode;
    this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });

    switch (viewMode) {
      case 'map':
        this.$.setAttribute('map-view', '');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('split-view');
        if (mapComponent.getMap) {
          this._adjustMap(mapComponent.getMap(), fitLayer);

          if (this._getSelectedMapMode().type === 'step') {
            mapComponent.openPopup(0, 'stepPoints');
          }
        }

        break;
      case 'split':
        this.$.setAttribute('split-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('grid-view');
        if (mapComponent.getMap) {
          this._adjustMap(mapComponent.getMap(), fitLayer);
        }

        break;
      default:
        if (mapComponent.resizeMap) mapComponent.cancelDraw();
        this.$.setAttribute('grid-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('split-view');

        break;
    }
  }

  changePage(page) {
    super.changePage(page);

    const gridElement = this.$.querySelector('#report-body-grid');
    const step = this.stateConfig.gridConfig.pageSize * (page - 1);

    gridElement.setActiveRow({ index: step });

    if (this._getSelectedMapMode().type == 'step') {
      this._renderStepMode(step);
    } else {
      this.mapModeList = this.mapModeList.map(mode =>
        mode.type == 'step' ? { ...mode, step } : mode,
      );
    }
  }

  changeRow(page, row) {
    if (page != this.stateConfig.gridConfig.page) {
      this.changePage(page);
    }

    if (row >= 0) {
      this._renderStepMode(row);
    }

    this.$.querySelector('#report-body-map').toggleNearBySearch(false);
  }

  changePageSize(pageSize) {
    super.changePageSize(pageSize);

    const step = 0;

    if (this._getSelectedMapMode().type == 'step') {
      this._renderStepMode(step);
    } else {
      this.mapModeList = this.mapModeList.map(mode =>
        mode.type == 'step' ? { ...mode, step } : mode,
      );
    }
  }
  /* */

  /* Private */
  _validateAction(action) {
    if (action.actionType == 'edit') {
      if (this.selectedRows.length != 1) return true;
      return false;
    }
    return false;
  }

  _toggleMapLayer(mapLayer) {
    const mapComponent = this.$.querySelector('#report-body-map');
    mapLayer.selected = !mapLayer.selected;
    if (mapLayer.selected) this._renderAllMode(this.reportDataset);
    else {
      mapComponent.removeLayers([mapLayer.layer]);
      if (!mapComponent.hasAttribute('draw')) mapComponent.removeLayers(['stepPoints']);
    }
  }

  _renderAllMode(dataset) {
    const mapComponent = this.$.querySelector('#report-body-map');
    const parsedDataset = mapComponent.sortDatasetByBounds(dataset);

    return mapComponent.renderDataset({
      dataset: parsedDataset
        .reverse()
        .map(data => ({ ...data, color: data.color || data.categoryColor })),
      layerName: 'points',
    });
  }

  _goToLink(evt, evtParams) {
    if (evtParams.routeLink == 'map') {
      if (this.stateConfig.viewMode != 'map' && this.stateConfig.viewMode != 'split') {
        if (window.innerWidth > 878) this.changeView('split');
        else this.changeView('map');
      }

      if (this._getSelectedMapMode().type != 'step') {
        this._selectMapMode(this.mapModeList.filter(mode => mode.type == 'step')[0]);
      }

      const gridElement = this.$.querySelector('#report-body-grid');

      gridElement.setActiveRow({ index: evtParams.index }).then(() => {
        this.selectedVehicle = gridElement.getActiveRow();
      });

      this.$.querySelector('#report-body-map').toggleNearBySearch(false);
    } else {
      super._goToLink(evt, evtParams);
    }
  }

  _getStepPosition() {
    return this.mapModeList.filter(mode => mode.type == 'step')[0].step;
  }

  _adjustMap(map, fitLayer) {
    this.$.querySelector('#report-body-map').resizeMap();
    if (!fitLayer) return;
    switch (this._getSelectedMapMode().type) {
      case 'all':
        this.$.querySelector('#report-body-map').fitLayers(['points']);
        break;
      case 'step':
        this.$.querySelector('#report-body-map').fitLayers(['stepPoints']);
        break;
      default:
        break;
    }
  }

  _getSelectedMapMode() {
    return this.mapModeList.filter(mode => mode.selected)[0];
  }

  _selectMapMode(mapMode, clearActions = true) {
    const mapComponent = this.$.querySelector('#report-body-map');

    if (clearActions) {
      if (mapComponent.hasAttribute('draw')) mapComponent.cancelDraw();
    }

    if (mapMode.selected) return;
    this.mapModeList = this.mapModeList.map(mode => ({
      ...mode,
      selected: mode.type == mapMode.type,
    }));
    switch (mapMode.type) {
      case 'all':
        mapComponent.removeLayers(['stepPoints']);
        this._renderAllMode(this.reportDataset).then(() => {
          mapComponent.resizeMap();
          if (!mapComponent.hasAttribute('draw')) {
            mapComponent.fitLayers(['points']);
          }
        });
        break;
      case 'step':
        mapComponent.removeLayers(['points']);
        if (mapMode.step === 0) {
          this._renderStepMode(
            this.stateConfig.gridConfig.pageSize * (this.stateConfig.gridConfig.page - 1),
          );
        }
        break;
      default:
        break;
    }
  }

  _renderStepMode(index) {
    const mapComponent = this.$.querySelector('#report-body-map');
    this.mapModeList = this.mapModeList.map(mode =>
      mode.type == 'step' ? { ...mode, step: index } : mode,
    );
    const data = this.reportDataset[index];
    mapComponent.setCurrentPoint(data);

    mapComponent
      .renderDataset({
        dataset: [{ ...data, color: data.color || data.categoryColor }],
        layerName: 'stepPoints',
      })
      .then(() => {
        mapComponent.fitLayers(['stepPoints']);
        if (!this.$.hasAttribute('grid-view')) {
          mapComponent.openPopup(0, 'stepPoints');
        }
      });
  }

  _callAction(action) {
    const mapComponent = this.$.querySelector('#report-body-map');
    this.$.removeEventListener('drawEnded', this._evtDrawEnded);
    switch (action.actionType) {
      case 'add':
        this.mapModeList = this.mapModeList.map(mode => ({
          ...mode,
          selected: mode.type == 'all',
        }));
        this._renderAllMode(this.reportDataset);
        this._validateRenderInToggle();
        mapComponent.startDraw({ callback: this._addItemData.bind(this) });
        this.changeView('map', this.stateConfig.viewMode == 'grid');
        break;
      case 'edit':
        this.mapModeList = this.mapModeList.map(mode => ({
          ...mode,
          selected: mode.type == 'all',
        }));
        this._renderAllMode(this.reportDataset);
        this._validateRenderInToggle();
        this.changeView('map', false);
        mapComponent.startDraw({
          callback: this._editItemData.bind(this),
          dataset: this.selectedRows[0],
        });
        break;
      case 'delete':
        this.cantDeletePoints = [];
        this.deletePoints = [];

        Object.keys(this.selectedRows).reduce((acc, item) => {
          this.deletePoints.push(this.selectedRows[item]);
          return acc;
        }, []);

        if (this.deletePoints.length > 0) {
          this.$.querySelector('power-popup-delete').setData({
            deleteMethod: action.actionMethod,
            objects: this.deletePoints.map(row => ({
              id: row.id,
              description: row[this.stateConfig.gridConfig.descriptionField],
              value: row.id,
            })),
          });
          this.$.querySelector('power-popup-delete').toggle();
        }
        break;
      default:
        break;
    }
  }

  async _addItemData(geoJson) {
    if (!geoJson.geometry.coordinates[0] && !geoJson.geometry.coordinates[1]) {
      const mapComponent = this.$.querySelector('#report-body-map');
      const marker = mapComponent.getMap().getObjects();
      for (let i = 0; i < marker.length; i++) {
        // eslint-disable-next-line no-undef
        if (marker[i] instanceof H.map.Marker) {
          geoJson.geometry.coordinates[0] = marker[i].b.lng;
          geoJson.geometry.coordinates[1] = marker[i].b.lat;
        }
      }
    }

    if (
      geoJson.geometry.coordinates &&
      ((geoJson.geometry.type === 'Point' && geoJson.geometry.coordinates[0] === null) ||
        geoJson.geometry.coordinates[0].length === 0)
    ) {
      this.toastText = 'Selecione um Ponto de interesse no mapa';
      this.$.querySelector('power-toast#report-toast').toggle(true);
    } else {
      this.$.querySelector('#popup-add').toggle();

      const latitude = geoJson.geometry.coordinates[1];
      const longitude = geoJson.geometry.coordinates[0];
      const address = await this.requesReverseGeocode(latitude, longitude);

      this.stateConfig.toolbarConfig.addConfig.dataset = { geoJson, address, latitude, longitude };

      if (!this.$scope.$$phase && !this.$rootScope.$$phase) {
        this.$scope.$apply();
      }
    }
  }

  _addItem(method, data) {
    const payload = {
      ...data,
    };

    this.$.querySelector('#popup-add').toggle();
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'POST',
      data: {
        request: Object.assign(payload, {
          geoJson: this.stateConfig.toolbarConfig.addConfig.dataset.geoJson.geometry,
          latitude: this.stateConfig.toolbarConfig.addConfig.dataset.latitude,
          longitude: this.stateConfig.toolbarConfig.addConfig.dataset.longitude,
        }),
      },
    }).then(
      () => {
        this.toastText = `${this.stateConfig.toolbarConfig.addConfig.title} adicionado.`;
        this.$.querySelector('power-toast#report-toast').toggle(true);
        this.requestDataset();
      },
      error => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
        this.toastText = 'Erro ao adicionar o item.';

        if (error.status != 500) {
          this.toastText = error.data.mensagem;
        }

        this.$.querySelector('power-toast#report-toast').toggle(true);
      },
    );
  }

  _validateRenderInToggle() {
    const mapLayerPoint = this.mapLayerList.find(map => map.layer == 'points');
    if (mapLayerPoint.selected) this._renderAllMode(this.reportDataset);
    else this._toggleMapLayer({ ...mapLayerPoint, selected: true });
  }

  async requesReverseGeocode(lat, lng) {
    const success = await this.$http({
      url: `${this.urlApi}/GeoCoding/GetReverseGeocode`,
      method: 'POST',
      data: {
        latitude: lat,
        longitude: lng,
      },
    });
    if (success.status && success.status !== 200) return null;
    if (success.data.data) {
      return success.data.data.label;
    }
    return null;
  }

  async _editItemData(geoJson) {
    this.$.querySelector('#popup-edit').toggle();

    let addressGeo = '';
    if (this.selectedRows[0].address.trim().length == 0) {
      addressGeo = await this.requesReverseGeocode(
        this.selectedRows[0].latitude,
        this.selectedRows[0].longitude,
      );
    } else {
      addressGeo = this.selectedRows[0].address;
    }

    this.stateConfig.toolbarConfig.editConfig.dataset = {
      geoJson,
      id: this.selectedRows[0].id,
      name: this.selectedRows[0].name,
      categoryId: this.selectedRows[0].categoryId,
      privacy: this.selectedRows[0].privacyId,
      description: this.selectedRows[0].description,
      address: addressGeo,
      latitude: this.selectedRows[0].latitude,
      longitude: this.selectedRows[0].longitude,
    };

    if (!this.$scope.$$phase && !this.$rootScope.$$phase) {
      this.$scope.$apply();
    }
  }

  _editItem(method, data) {
    const payload = {
      ...data,
    };

    this.$.querySelector('#popup-edit').toggle();
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'POST',
      data: {
        request: Object.assign(payload, {
          geoJson: this.stateConfig.toolbarConfig.editConfig.dataset.geoJson.geometry,
          latitude: this.stateConfig.toolbarConfig.editConfig.dataset.latitude,
          longitude: this.stateConfig.toolbarConfig.editConfig.dataset.longitude,
        }),
      },
    }).then(
      () => {
        this.toastText = 'Ponto de Interesse editado.';
        this.$.querySelector('power-toast#report-toast').toggle(true);
        this.requestDataset();
      },
      error => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
        this.toastText = 'Erro ao editar o item.';

        if (error.status != 500) {
          this.toastText = error.data.mensagem;
        }

        this.$.querySelector('power-toast#report-toast').toggle(true);
      },
    );
  }

  _cancelEditItem() {
    this.$.querySelector('#popup-edit').toggle();
    this.stateConfig.toolbarConfig.editConfig.dataset = null;
  }

  _errorAddItem() {
    this.toastText = 'Por favor verifique os campos';
    this.$.querySelector('power-toast#report-toast').toggle(true);
  }
  /* */

  /* Observers */
  async __reportDatasetChanged(newValue) {
    if (newValue && newValue.length > 0) {
      await this.$.querySelector('#report-body-map').awaitRender();
      this._selectMapMode(Object.assign(this._getSelectedMapMode(), { selected: false }), false);
    }
  }

  __onRequestSyncVisualization(evt) {
    super.__onRequestSyncVisualization(evt);

    const gridElement = this.$.querySelector('#report-body-grid');
    const activeRow = gridElement.getActiveRow();

    this._renderStepMode(activeRow._index);
  }
  /* */
}

class PowerReportPointsOfInterest {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = PowerReportPointsOfInterestController;
  }
}

angular
  .module('power-report-points-of-interest', [
    'ngRedux',
    'power-fab',
    'power-crud',
    'power-dropdown',
    'power-footer',
    'power-grid',
    'power-map-points-of-interest',
    'power-pagination',
    'power-popup',
    'power-popup-delete',
    'power-popup-share',
    'power-toast',
    'power-toolbar-report',
  ])
  .component('powerReportPointsOfInterest', new PowerReportPointsOfInterest());
