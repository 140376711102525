import angular from 'angular';
import * as Comlink from 'comlink';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-toolbar-report/power-toolbar-report';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-grid-vehicles/power-grid-vehicles';
import '@power/power-components/components/power-map-vehicles/power-map-vehicles';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-popup-share/power-popup-share';
import '@power/power-components/components/power-popup-move-vehicles/power-popup-move-vehicles';
import '@power/power-components/components/power-toast/power-toast';
import '@power/power-components/components/power-header-drag-n-drop/power-header-drag-n-drop';
import '@power/power-components/directives/ng-resize/ng-resize';
import { PowerReportController } from '@power/power-components/components/power-report/power-report';

import template from './golfleet-report-blockages.html';
import './golfleet-report-blockages.scss';

class GolfleetReportBlockagesController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$rootScope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  constructor(
    $element,
    $scope,
    $rootScope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    Object.assign(this, { $rootScope });

    const checkIsAdm = behavior =>
      behavior.state.routeList.length > 0
        ? behavior.state.routeList[behavior.state.routeList.length - 1].stateConfig?.isAdm
        : false;

    this.__appInheritBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Storage */
        isAdm: checkIsAdm(behavior),
        modules: behavior.session.modules,
      }),
    )(this);

    this.tabs = [
      { name: 'hours', label: 'Horário', selected: true },
      { name: 'rules', label: 'Regras' },
    ];

    this.fleetPolicyData = {};

    this.legendList = [];
    this.mapModeList = [
      {
        type: 'all',
        description: 'Todos os Pontos',
        selected: true,
      },
      {
        type: 'heat',
        description: 'Mapa de Calor',
        selected: false,
      },
      {
        type: 'step',
        description: 'Ponto a ponto',
        selected: false,
        step: 0,
      },
    ];
    this.mapLayerList = [
      {
        id: 0,
        description: 'Áreas de Controle',
        method: 'GeoFence/Post',
        lastMapBounds: null,
        selected: true,
        type: 'FeatureGroup',
      },
      {
        id: 1,
        description: 'Pontos de Interesse',
        method: 'PointsOfInterest/Post',
        lastMapBounds: null,
        selected: true,
        type: 'MarkerFeatureGroup',
      },
    ];
    this.selectedVehicle = null;
    this.updateMapDataset = false;

    this.coordinatesMap = null;
    this.zoomMap = null;
    this.firstRendering = true;

    this.isMapAutoRefreshStopped = true;
    this.msgIntervalMapView = null;
    this.intervalMapView = null;

    this.worker = new Worker('./golfleet-report-blockages.worker.js');
    this.workerService = Comlink.wrap(this.worker);
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();

    this.$.addEventListener('mapLoaded', () =>
      this.$.querySelector('#report-body-map')
        .getMap()
        .onMoveEnd(() =>
          this.mapLayerList.map(mapLayer => {
            if (mapLayer.selected) this._toggleMapLayer({ ...mapLayer, selected: false });

            return mapLayer;
          }),
        ),
    );

    this.$scope.$on('actionRequest', this._onActionRequest.bind(this));
    this.$scope.$on('actionFullscreen', this._onActionFullscreen.bind(this));
    this.$scope.$on('requestShowToast', this._onRequestShowToast.bind(this));

    this.$scope.$watch(() => this.reportDataset, this.__reportDatasetChanged.bind(this));
  }

  $onDestroy() {
    this.worker.terminate();
    clearInterval(this.intervalMapView);
  }
  /* */

  /* Public */
  changeView(viewMode) {
    this.stateConfig.viewMode = viewMode;
    this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });

    switch (viewMode) {
      case 'map':
        this.coordinatesMap = null;
        this.zoomMap = 4;
        if (this.$.querySelector('#report-body-map').getMap) {
          if (this.updateMapDataset) {
            this.updateMapDataset = false;
            this._selectMapMode(Object.assign(this._getSelectedMapMode(), { selected: false }));
          }
          this._adjustMap();
        }

        this.$.setAttribute('map-view', '');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('split-view');

        break;
      case 'split':
        this.coordinatesMap = null;
        this.zoomMap = 4;
        if (this.$.querySelector('#report-body-map').getMap) {
          if (this.updateMapDataset) {
            this.updateMapDataset = false;
            this._selectMapMode(Object.assign(this._getSelectedMapMode(), { selected: false }));
          }
          this._adjustMap();
        }
        this.$.setAttribute('split-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('grid-view');

        break;
      default:
        this.$.setAttribute('grid-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('split-view');

        break;
    }
  }

  changePage(page) {
    super.changePage(page);

    const gridElement = this.$.querySelector('#report-body-grid');
    const step = this.stateConfig.gridConfig.pageSize * (page - 1);

    gridElement.setActiveRow({ index: step });

    if (this._getSelectedMapMode().type == 'step') {
      this._renderStepMode(step);
    } else {
      this.mapModeList = this.mapModeList.map(mode =>
        mode.type == 'step' ? { ...mode, step } : mode,
      );
    }
  }

  changeRow(page, row) {
    if (page != this.stateConfig.gridConfig.page) {
      this.changePage(page);
    }

    if (row >= 0) {
      this._renderStepMode(row);
    }
  }

  changePageSize(pageSize) {
    super.changePageSize(pageSize);

    const step = 0;

    if (this._getSelectedMapMode().type == 'step') {
      this._renderStepMode(step);
    } else {
      this.mapModeList = this.mapModeList.map(mode =>
        mode.type == 'step' ? { ...mode, step } : mode,
      );
    }
  }
  /* */

  /* Private */
  _goToLink(evt, evtParams) {
    if (evtParams.routeLink == 'map') {
      if (this._getSelectedMapMode().type != 'step') {
        this._selectMapMode(this.mapModeList.filter(mode => mode.type == 'step')[0]);
      }

      if (this.stateConfig.viewMode != 'map' && this.stateConfig.viewMode != 'split') {
        if (window.innerWidth > 878) this.changeView('split');
        else this.changeView('map');
      } else {
        this.$.querySelector('#report-body-map').zoomTo(evtParams.tableRowData);
      }

      const gridElement = this.$.querySelector('#report-body-grid');

      gridElement.setActiveRow({ index: evtParams.index }).then(() => {
        this.selectedVehicle = gridElement.getActiveRow();
      });
    } else {
      super._goToLink(evt, evtParams);
    }
  }

  async _selectMapMode(mapMode) {
    if (mapMode.selected) return;
    this.mapModeList = this.mapModeList.map(mode => ({
      ...mode,
      selected: mode.type === mapMode.type,
    }));
    switch (mapMode.type) {
      case 'all':
        this.$.querySelector('#report-body-map').removeLayers(['heatLayer', 'stepMarker']);
        await this._renderAllMode(this.reportDataset);
        break;
      case 'heat':
        this.$.querySelector('#report-body-map').removeLayers([
          'allOn',
          'allOff',
          'allIddle',
          'allInvalid',
          'allBlock',
          'stepMarker',
        ]);
        await this._renderHeatMode(this.reportDataset);
        break;
      case 'step':
        this.$.querySelector('#report-body-map').removeLayers([
          'allOn',
          'allOff',
          'allIddle',
          'allInvalid',
          'allBlock',
          'heatLayer',
        ]);
        this.changeRow(this.stateConfig.gridConfig.page, mapMode.step);
        break;
      default:
        break;
    }
  }

  async _renderAllMode(dataset) {
    this._showGlobalLoader(true);

    const mapComponent = this.$.querySelector('#report-body-map');

    await this.workerService.parseAllModeDataset({ dataset });
    const allModeDataset = await this.workerService.allModeDataset;

    await mapComponent.renderDataset({
      dataset: allModeDataset.on,
      layerName: 'allOn',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#4AAE4E',
    });

    await mapComponent.renderDataset({
      dataset: allModeDataset.iddle,
      layerName: 'allIddle',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#DECE00',
    });

    await mapComponent.renderDataset({
      dataset: allModeDataset.off,
      layerName: 'allOff',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#980A1A',
    });

    await mapComponent.renderDataset({
      dataset: allModeDataset.block,
      layerName: 'allBlock',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#000',
    });

    await mapComponent.renderDataset({
      dataset: allModeDataset.invalid,
      layerName: 'allInvalid',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#949494',
    });

    mapComponent.resizeMap();

    mapComponent.fitLayers(['allOn', 'allIddle', 'allOff', 'allBlock', 'allInvalid']);

    this._showGlobalLoader(false);
  }

  async _renderHeatMode(dataset) {
    const mapComponent = this.$.querySelector('#report-body-map');

    await mapComponent.renderDataset({ dataset, layerName: 'heatLayer', type: 'HeatLayer' });
    mapComponent.resizeMap();
    mapComponent.fitLayers([], 'heatLayer');
  }

  async _renderStepMode(index) {
    const mapComponent = this.$.querySelector('#report-body-map');
    this.mapModeList = this.mapModeList.map(mode =>
      mode.type == 'step' ? { ...mode, step: index } : mode,
    );
    const dataPosition = this.reportDataset[index];
    this.selectedVehicle = dataPosition;

    switch (dataPosition?.status) {
      case 1:
        Object.assign(dataPosition, {
          color: '#4AAE4E',
          icon: dataPosition.icon || 'directions_car',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 2:
        Object.assign(dataPosition, {
          color: '#DECE00',
          icon: dataPosition.icon || 'directions_car',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 3:
        Object.assign(dataPosition, {
          color: '#980A1A',
          icon: dataPosition.icon || 'directions_car',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 6:
        Object.assign(dataPosition, {
          color: '#4AAE4E',
          icon: 'lock_open',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 7:
        Object.assign(dataPosition, {
          color: '#DECE00',
          icon: 'lock_open',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 8:
        Object.assign(dataPosition, {
          color: '#980A1A',
          icon: 'lock_open',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 9:
        Object.assign(dataPosition, {
          color: '#4AAE4E',
          icon: 'lock',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 10:
        Object.assign(dataPosition, {
          color: '#DECE00',
          icon: 'lock',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 11:
        Object.assign(dataPosition, {
          color: '#980A1A',
          icon: 'lock',
          markerIcon: 'MarkerIcon',
        });
        break;
      default:
        Object.assign(dataPosition, {
          color: '#949494',
          icon: dataPosition.icon || 'directions_car',
          markerIcon: 'MarkerIcon',
        });
        break;
    }

    await mapComponent.renderDataset({
      dataset: [dataPosition],
      layerName: 'stepMarker',
      type: 'MarkerFeatureGroup',
    });

    mapComponent.fitLayers(['stepMarker']);
    mapComponent.openPopup(0, 'stepMarker');
  }

  _scopeApply() {
    if (this.$scope.$$phase === null && this.$rootScope.$$phase === null) {
      this.$scope.$apply();
    }
  }

  _getSelectedMapMode() {
    return this.mapModeList.filter(mode => mode.selected)[0];
  }

  _getStepPosition() {
    return this.mapModeList.filter(mode => mode.type == 'step')[0].step;
  }

  _formatLatLng(str) {
    return str.replace(',', '.');
  }

  _adjustMap() {
    const mapComponent = this.$.querySelector('#report-body-map');
    mapComponent.resizeMap();
    switch (this._getSelectedMapMode().type) {
      case 'all':
        if (this.coordinatesMap) {
          mapComponent.zoomTo(this.coordinatesMap, this.zoomMap);
        } else if (!this.firstRendering) {
          mapComponent.fitLayers(['allOn', 'allIddle', 'allOff', 'allBlock', 'allInvalid']);
        }
        break;
      case 'heat':
        mapComponent.fitLayers([], 'heatLayer');
        break;
      case 'step':
        mapComponent.fitLayers(['stepMarker']);
        mapComponent.openPopup(0, 'stepMarker');
        break;
      default:
        break;
    }
  }

  _callAction(action) {
    /* Chrome Locked-Scroll Workaround */
    const scrollWorkaround = () => {
      const scrollArea = this.$.querySelector('#popup-add .div-section-crud');
      if (scrollArea) {
        setTimeout(() => {
          scrollArea.style.overflow = 'auto';
        }, 500);
        setTimeout(() => {
          scrollArea.style.overflow = '';
        }, 600);
      }
    };

    switch (action.actionType) {
      case 'share':
        if (this.$.querySelector('power-popup-share')) scrollWorkaround();

        this.$.querySelector('power-popup-share').setData({
          type: this.stateConfig.type,
          getMethod: action.getDataMethod,
          shareMethod: action.actionMethod,
          objects: this.selectedRows.map(row => ({ objectId: row.id })),
        });
        this.$.querySelector('power-popup-share').toggle();
        break;
      default:
        break;
    }
  }

  _showGlobalLoader(state = false) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: state },
        bubbles: true,
        composed: true,
      }),
    );
  }

  _onActionRequest(evt, data) {
    this.reportServices.callApiMethod(data.method, data.json).then(
      () => {
        this.$.querySelector('power-toast#report-toast').toggle(true);
        Object.assign(this, { toastText: data.text });
        this.$timeout(() => this.$scope.$broadcast('getDataset'));
      },
      () => {},
    );
  }

  _onRequestShowToast(evt, evtParams) {
    this.$.querySelector('power-toast#report-toast').toggle(true);
    Object.assign(this, { toastText: evtParams.text });
  }

  _onActionFullscreen() {
    this.$timeout(() => this.$scope.$broadcast('getDataset'));
  }

  _getSelectedTab() {
    return this.tabs.filter(item => item.selected)[0].name;
  }

  _selectTab(tab) {
    this.tabs.forEach(item => {
      item.selected = tab.name === item.name;
    });
  }

  _toggleMapLayer(mapLayer) {
    mapLayer.selected = !mapLayer.selected;
    this.mapLayerList = this.mapLayerList.map(layer =>
      layer.id == mapLayer.id ? mapLayer : layer,
    );
    if (mapLayer.selected) this._renderMapLayer(mapLayer.id);
    else {
      this.mapLayerList = this.mapLayerList.map(layer =>
        layer.id == mapLayer.id ? { ...layer, lastMapBounds: null } : layer,
      );
      this.$.querySelector('#report-body-map').removeLayers([mapLayer.description]);
    }
  }

  _renderMapLayer(mapLayerId) {
    const mapLayer = this.mapLayerList.filter(layer => layer.id == mapLayerId)[0];

    const mapComponent = this.$.querySelector('#report-body-map');
    const mapBounds = mapComponent.getBounds();

    if (!mapLayer.lastMapBounds || !mapLayer.lastMapBounds.containsRect(mapBounds)) {
      this.mapLayerList = this.mapLayerList.map(layer =>
        layer.id == mapLayerId ? { ...layer, lastMapBounds: mapBounds } : layer,
      );

      return this.$http({
        url: `${this.urlApi}/${mapLayer.method}`,
        method: 'POST',
        data: {
          request: {
            filter: {
              conditions: [
                {
                  id: -1,
                  field: 'bounds',
                  value: [mapBounds.asArray().join()],
                },
              ],
            },
            page: 0,
            length: 10,
            isPaginated: false,
            sort: { direction: 'asc', name: 'description' },
          },
        },
      }).then(success => {
        let dataset = success.data.data.data.map(data => ({
          ...data,
          color: data.color || data.categoryColor,
        }));
        dataset = mapComponent.sortDatasetByBounds(dataset);
        mapComponent.renderDataset({
          dataset: dataset.reverse(),
          layerName: mapLayer.description,
        });
      });
    }

    return null;
  }

  _emptyActions() {
    const { toolbarConfig } = this.stateConfig;
    if (
      !toolbarConfig ||
      !toolbarConfig.actionConfig ||
      toolbarConfig.length == 0 ||
      toolbarConfig.actionConfig.actions.length ===
        toolbarConfig.actionConfig.actions.filter(
          action => action.module && !this.modules.includes(action.module),
        )?.length
    ) {
      return true;
    }
    return false;
  }
  /* */

  /* Observers */
  async __reportDatasetChanged(newValue) {
    if (newValue) {
      this.legendList = this.stateConfig.gridConfig.gridHeaders.reduce(
        (acc, ele) => (ele.icons ? acc.concat(ele) : acc),
        [],
      );
      if (newValue.length > 0) {
        if (this.stateConfig.viewMode === 'grid') {
          this.updateMapDataset = true;
        } else {
          const mapMode = this.mapModeList.filter(mode => mode.type == 'all' && mode.selected);
          const mapComponent = this.$.querySelector('#report-body-map');
          if (mapComponent.hasAttribute('fullscreen') || this.stateConfig.viewMode == 'map') {
            this.zoomMap = mapComponent.getMap().getZoom();
            const { lat: latitude, lng: longitude } = mapComponent.getMap().getCenter();
            if (!this.firstRendering) {
              this.coordinatesMap = { latitude, longitude };
            } else {
              this.firstRendering = false;
            }

            if (mapMode.length > 0) {
              this._renderAllMode(this.reportDataset);
            } else {
              await this.workerService.findIndex({
                dataset: newValue,
                vehicleId: this.selectedVehicle.id,
                pageSize: this.stateConfig.gridConfig.pageSize,
              });
              this.changeRow(await this.workerService.page, await this.workerService.row);
            }
          } else {
            this._selectMapMode(Object.assign(this._getSelectedMapMode(), { selected: false }));
          }
        }
      } else {
        this.$.querySelector('#report-body-map').removeLayers([
          'allOn',
          'allOff',
          'allIddle',
          'allInvalid',
          'allBlock',
          'heatLayer',
          'stepMarker',
        ]);
      }
    }
  }

  __onMapExitFullScreen() {
    this.$.removeChild(this.msgInterval);
    clearInterval(this.intervalFullscreen);
    this.$.removeAttribute('fullscreen');
  }

  __onRequestSyncVisualization(evt) {
    super.__onRequestSyncVisualization(evt);

    const gridElement = this.$.querySelector('#report-body-grid');
    const activeRow = gridElement.getActiveRow();

    this._renderStepMode(activeRow._index);
  }
  /* */
}

class GolfleetReportBlockages {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetReportBlockagesController;
  }
}

angular
  .module('golfleet-report-blockages', [
    'power-fab',
    'power-report',
    'power-toolbar-report',
    'power-dropdown',
    'power-grid-vehicles',
    'power-map-vehicles',
    'power-footer',
    'power-pagination',
    'power-popup',
    'power-popup-share',
    'power-toast',
    'power-header-drag-n-drop',
    'ng-resize',
  ])
  .component('golfleetReportBlockages', new GolfleetReportBlockages());
