import angular from 'angular';
import { toDateOffset } from '@power/power-components/utils/date-util.js';
import 'ng-redux';

import '@power/power-components/directives/ng-tippy/ng-tippy';
import '@power/power-components/components/power-toast/power-toast';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-switch/power-switch';

import template from './golfleet-form-revision-plan-crud.html';
import './golfleet-form-revision-plan-crud.scss';

/**
 * Items Type (Enum)
 */
export const ItemsTypeEnum = {
  INSPECT: 1,
  REPLACE: 2,
};

class GolfleetFormRevisionPlanCrudController {
  static get $inject() {
    return [
      '$element',
      '$sce',
      '$ngRedux',
      '$rootScope',
      '$state',
      '$http',
      '$scope',
      'commonServices',
      'maintenanceServices',
      'urlApi',
      'crudServices',
    ];
  }

  /**
   * Creates an instance of GolfleetFormRevisionPlanCrudController.
   * @memberof GolfleetFormRevisionPlanCrudController
   */
  constructor(
    $element,
    $sce,
    $ngRedux,
    $rootScope,
    $state,
    $http,
    $scope,
    commonServices,
    maintenanceServices,
    urlApi,
    crudServices,
  ) {
    Object.assign(this, {
      $: $element[0],
      $sce,
      $ngRedux,
      $rootScope,
      $state,
      $http,
      $scope,
      commonServices,
      maintenanceServices,
      urlApi,
      crudServices,
    });

    this.__appInheritBehavior = $ngRedux.connect(behavior => {
      const routeListSize = behavior.state.routeList.length;
      return Object({
        /* Session Storage */
        session: behavior.session,
        /* State Storage */
        state: behavior.state,
        currentState: behavior.state.routeList[routeListSize - 1],
      });
    })(this);

    this.canSubmit = true;
    this.allItems = [];
    this.itemsByType = [];
    this.copiedItems = { items: [] };
    this.countCopyItemsSelected = null;
    this.tabRevisionSelected = 0;
    this.currrentItemType = null;
    this.popupRevisionTitle = null;
    this.revisionPlanId = this._getRevisionPlanIdFromState();
    this.revisionPlanIdToCopy = this._getRevisionPlanIdFromState('copy');
    this.pasteInfoText = null;
    this.readOnly = false;

    this.pasteItemsOptions = [
      { value: 'REPLACE', description: 'SUBSTITUIR OS ITENS DESSA REVISÃO', selected: true },
      { value: 'MIX', description: 'MESCLAR COM OS ITENS DESSA REVISÃO', selected: false },
    ];

    this.copyItemsOptions = [
      { value: 'INSPECT', description: 'ITENS PARA INSPEÇÃO', selected: false, tabRevision: null },
      { value: 'REPLACE', description: 'ITENS PARA TROCA', selected: false, tabRevision: null },
    ];

    this.fields = [
      {
        field: 'revisionPlanName',
        name: 'Nome',
        type: 'TextBox',
        maxLength: 50,
        required: true,
        inputType: 'Text',
        size: 'large',
      },
      {
        field: 'revisionPlanActive',
        key: 'revisionPlanActive',
        name: 'Status do Plano',
        type: 'switch',
        size: 'small',
      },
      {
        field: 'author',
        name: 'Autor',
        type: 'TextBox',
        maxLength: 50,
        required: true,
        inputType: 'Text',
        size: 'small',
        disabled: true,
      },
      {
        field: 'createDateFormatted',
        name: 'Data de criação',
        type: 'TextBox',
        maxLength: 10,
        required: true,
        inputType: 'Text',
        size: 'small',
        disabled: true,
      },
      {
        field: 'updateDateFormatted',
        name: 'Data da última alteração',
        type: 'TextBox',
        maxLength: 10,
        inputType: 'Text',
        size: 'small',
        disabled: true,
      },
      {
        field: 'linkRevision',
        name: 'Editar revisões',
        type: 'link',
        size: 'full',
      },
    ];

    this.revisionObj = {};
    this.revisionsList = [];
    this.maintenancePlanObj = {};
  }

  /* Lifecycle */
  $onInit() {
    this._checkReady();
  }

  _checkReady() {
    setTimeout(() => {
      this._getFormData();
      this._formViewDataParser();
      this._getRevisionItems();
    }, 300);
  }

  _formViewDataParser() {
    this._syncScope();
  }

  _syncScope() {
    if (this.$scope.$$phase === null && this.$rootScope.$$phase === null) this.$scope.$apply();
  }

  _getFormData() {
    let id = null;
    if (this.revisionPlanId || this.revisionPlanIdToCopy) {
      id = this.revisionPlanId || this.revisionPlanIdToCopy;
    }

    if (id) {
      const payload = {
        id,
      };

      this.maintenanceServices
        .callApiMethod('Maintenance/GetFormDataRevisionPlan', payload)
        .then(success => {
          const { data } = success.data;

          if (success.status && success.status !== 200) {
            this._showToast('Ocorreu um erro ao tentar recuperar as informações');
            return;
          }

          this.readOnly = !data.canEdit;

          this.maintenancePlanObj = data;
          this.maintenancePlanObj.createDateFormatted = toDateOffset({ date: data.createDate });
          this.maintenancePlanObj.updateDateFormatted = toDateOffset({ date: data.updateDate });

          if (this.revisionPlanIdToCopy) {
            const { revisionPlanName } = this.maintenancePlanObj;
            this.maintenancePlanObj = Object.clone(
              {},
              {
                ...this.maintenancePlanObj,
                id: null,
                author: this.session.userName,
                revisionPlanName: `(Cópia) - ${revisionPlanName}`,
                createDateFormatted: toDateOffset({ date: new Date() }),
                updateDateFormatted: null,
              },
            );

            this.readOnly = false;
          }

          this._loadFormData();
          this.formReady = true;
        })
        .finally(() => this._showLoader(false));
    } else {
      this.maintenancePlanObj = {
        revisionPlanActive: true,
        author: this.session.userName,
        createDateFormatted: toDateOffset({ date: new Date() }),
      };

      this._loadFormData();

      this._showLoader(false);
    }
    this.formReady = true;
  }

  _loadFormData() {
    this.fields.map(field => {
      field.value = this.maintenancePlanObj[`${field.field}`];
      if (this.readOnly && field.type !== 'switch') {
        field.disabled = true;
      }
      return field;
    });
  }

  _getRevisionItems() {
    this.maintenanceServices.callApiMethod('Maintenance/GetAllItems', {}).then(result => {
      const { data } = result.data;
      this.allItems = data;
    });
  }

  _loadFormConfig(data) {
    this.formConfig.map(config => {
      if (config.sections) {
        config.sections.forEach(section => {
          if (section.items && section.items.length > 0) {
            section.items.map(item => {
              const field = data[`${item.field}`];
              item.value = field;
              return item;
            });
          }
        });
      }
      return config;
    });
  }

  _deleteCrudForm() {
    if (this.maintenancePlanObj.vehicleCount > 0) {
      this._showToast('O plano de revisão não pode ser excluído, verifique se há veículos nele.');
    } else {
      if (!this.canSubmit) return;

      this.canSubmit = false;

      let toggleLoader = true;

      this._showLoader(true);

      const payload = { id: this.revisionPlanId };

      this.maintenanceServices
        .callDeleteMethod('Maintenance/DeleteRevisionPlan', payload)
        .then(success => {
          const { data } = success.data;
          this.canSubmit = true;

          if ((success.status && success.status !== 200) || !success.data) {
            this._showToast('Ocorreu um erro ao excuir o plano de revisão');
            return;
          }

          if (data) {
            toggleLoader = false;
            this.canSubmit = true;
            this._showToast('Plano de revisão excluído');
            setTimeout(() => {
              this._cancelCrudForm();
            }, 1500);
          }
        })
        .finally(() => {
          if (toggleLoader) {
            this._showLoader(false);
          }
        });
    }
  }

  /**
   * Filter item filtered by revision index and type item
   * @param {Number} revisionIndex
   * @param {Number} type
   */
  _filterRevisionItems(revisionIndex, type) {
    const revision = this.maintenancePlanObj.revisions[revisionIndex];
    if (revision && revision.items) {
      return revision.items.filter(item => item.type === ItemsTypeEnum[type]);
    }
    return [];
  }

  /**
   * Get name items concatenate filtered by revision index and type item
   * @param {Number} revisionIndex
   * @param {Number} type
   */
  _getItemsStr(revisionIndex, type) {
    const revisionItems = this._filterRevisionItems(revisionIndex, type);
    if (revisionItems.length > 0) {
      return revisionItems.reduce(
        (acc, item) => `${acc === '' ? acc : `${acc}, `}${item.description}`,
        '',
      );
    }
    return 'Selecione itens para a revisão';
  }

  /**
   * Get items count filtered by revision index and type item
   * @param {Number} revisionIndex
   * @param {Number} type
   */
  _getItemsCounter(revisionIndex, type) {
    const count = this._filterRevisionItems(revisionIndex, type).length;
    return `${count} ${count > 1 ? 'itens' : 'item'}`;
  }

  _hasRevision() {
    return this.maintenancePlanObj.revisions ? this.maintenancePlanObj.revisions.length > 0 : false;
  }

  _loadRevisionItems(type) {
    const currentRevisionItems = this.maintenancePlanObj.revisions[this.tabRevisionSelected].items;
    this.itemsByType = Object.clone(
      [],
      this.allItems.filter(item => item.type === ItemsTypeEnum[type]),
    );

    if (currentRevisionItems) {
      const idsItemsSelected = currentRevisionItems.map(item => item.id);

      this.itemsByType.forEach(opt => {
        opt.selected = idsItemsSelected.includes(opt.id);
      });
    }
  }

  _selectPasteItemOption(option) {
    this.pasteItemsOptions.forEach(opt => {
      opt.selected = opt.value == option.value;
    });
  }

  _pasteRevisionItems() {
    const option = this.pasteItemsOptions.find(opt => opt.selected);
    if (
      !this.maintenancePlanObj.revisions[this.tabRevisionSelected].items ||
      option.value === 'REPLACE'
    ) {
      this.maintenancePlanObj.revisions[this.tabRevisionSelected].items = this.copiedItems.items;
    } else {
      const copyItemsIds = this.copiedItems.items.map(copyItem => copyItem.id);
      this.maintenancePlanObj.revisions[this.tabRevisionSelected].items = [
        ...this.copiedItems.items,
        ...this.maintenancePlanObj.revisions[this.tabRevisionSelected].items.filter(
          selectedItem => !copyItemsIds.includes(selectedItem.id),
        ),
      ];
    }
  }

  _toggleCopyItemsOption(option) {
    option.selected = !option.selected;
    option.tabRevision = option ? this.tabRevisionSelected : null;
    this._getCountCopyItemsSelected();
  }

  _getCountCopyItemsSelected() {
    const itemsToCopy = this._getSelectedRevisionItems(
      this.maintenancePlanObj.revisions[this.tabRevisionSelected],
    );

    this.countCopyItemsSelected = itemsToCopy.length;
  }

  _copyRevisionItems(increment = false) {
    if (increment && this.copiedItems.index !== this.tabRevisionSelected) {
      return;
    }
    const revision = this.maintenancePlanObj.revisions[this.tabRevisionSelected];
    const itemsToCopy = this._getSelectedRevisionItems(revision);

    this.copiedItems = {
      revisionName: `${revision.limitDistance} km ou ${revision.limitTime} meses`,
      index: this.tabRevisionSelected,
      items: itemsToCopy,
    };

    this.pasteInfoText = `Colar ${this.copiedItems.items.length} ${
      this.copiedItems.items.length > 1 ? 'itens' : 'item'
    } ${this.copiedItems.items.length > 1 ? 'copiados' : 'copiado'} de ${
      this.copiedItems.revisionName
    }`;
  }

  _getSelectedRevisionItems(revision) {
    const itemsType = this.copyItemsOptions
      .filter(type => type.selected)
      .map(type => ItemsTypeEnum[type.value]);

    if (!revision.items || !itemsType) {
      return [];
    }

    return revision.items.filter(item => itemsType.includes(item.type)) || [];
  }

  _getPasteTooltipText() {
    return `Colar  ${this.copiedItems.items.length} ${
      this.copiedItems.items.length > 1 ? 'itens' : 'item'
    } ${this.copiedItems.items.length > 1 ? 'copiados' : 'copiado'} de  ${
      this.copiedItems.revisionName
    }`;
  }

  _getRevisionPlanIdFromState(copy) {
    if (this.currentState) {
      if (copy) {
        return this.currentState.stateConfig.revisionPlanIdToCopy
          ? this.currentState.stateConfig.revisionPlanIdToCopy
          : null;
      }
      return this.currentState.routeTail ? this.currentState.routeTail : null;
    }
    return null;
  }

  _sortRevision(a, b) {
    if (a.limitDistance > b.limitDistance) {
      return 1;
    }
    if (a.limitDistance < b.limitDistance) {
      return -1;
    }
    return 0;
  }

  _disableInput(input) {
    if (this.readOnly && input !== 'duplicate-plan-btn') {
      return true;
    }

    switch (input) {
      case 'copy-items-btn':
        return !this.countCopyItemsSelected;
      case 'copy-items-option':
        return !this.maintenancePlanObj.revisions[this.tabRevisionSelected].items;
      case 'paste-items-btn':
      case 'paste-items-option':
        return (
          !this.copiedItems.items.length || this.copiedItems.index === this.tabRevisionSelected
        );
      case 'duplicate-plan-btn':
        return !this.revisionPlanId || this.revisionPlanIdToCopy;
      case 'delete-btn':
        return !this.revisionPlanId || this.revisionPlanIdToCopy;
      default:
        return false;
    }
  }

  /* Events */
  _evtClickBtnPowerPopup(type, popupId, itemType) {
    if (type == 'close') {
      switch (popupId) {
        case 'popup-revisions':
          this.revisionObj = Object.clone({}, this.revisionObjOriginal);
          this.$.querySelector('#popup-revisions').toggle();
          break;
        case 'popup-items':
          this.$.querySelector('#popup-items').toggle();
          break;
        default:
          break;
      }
    } else if (type == 'open') {
      switch (popupId) {
        case 'popup-revisions':
          this.revisionObj = {};
          this.revisionsList = [...(this.maintenancePlanObj.revisions || [])];
          this.$.querySelector('#popup-revisions').toggle();
          break;
        case 'popup-items':
          this.currrentItemType = itemType;
          this.checkAllItems = false;
          this.popupRevisionTitle =
            itemType === 'REPLACE' ? 'Itens para troca' : 'Itens para inspeção';
          this._loadRevisionItems(itemType);
          this.$.querySelector('#popup-items').toggle();
          break;
        default:
          break;
      }
    } else if (type == 'primaryBtn') {
      switch (popupId) {
        case 'popup-revisions': {
          this.maintenancePlanObj.revisions = [...this.revisionsList];
          this.revisionsList = [];
          this.$.querySelector('#popup-revisions').toggle();
          break;
        }
        case 'popup-items': {
          const revisionItemsSelected = this.itemsByType.filter(item => item.selected);

          const revision = this.maintenancePlanObj.revisions[this.tabRevisionSelected];

          if (revision.items) {
            const revisionItemsOtherType = revision.items.filter(
              item => item.type !== ItemsTypeEnum[this.currrentItemType],
            );

            this.maintenancePlanObj.revisions[this.tabRevisionSelected].items = [
              ...revisionItemsOtherType,
              ...revisionItemsSelected,
            ];
          } else {
            this.maintenancePlanObj.revisions[this.tabRevisionSelected].items = [
              ...revisionItemsSelected,
            ];
          }

          this._copyRevisionItems(true);
          this._getCountCopyItemsSelected();

          this.$.querySelector('#popup-items').toggle();
          break;
        }
        default:
          break;
      }
    }
  }

  _evtClickRevisionTab(action, tabNumber) {
    switch (action) {
      case 'next':
        if (this.tabRevisionSelected < this.maintenancePlanObj.revisions.length - 1) {
          this.tabRevisionSelected++;
        }
        break;
      case 'previous':
        if (this.tabRevisionSelected > 0) {
          this.tabRevisionSelected--;
        }
        break;
      case 'click':
        this.tabRevisionSelected = tabNumber;
        break;
      default:
        break;
    }

    this.copyItemsOptions.forEach(opt => {
      opt.selected = opt.tabRevision === this.tabRevisionSelected;
    });

    this._getCountCopyItemsSelected();
    const revision = this.$.querySelectorAll('.revision-tab-items')[this.tabRevisionSelected];
    revision.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
  }

  _evtClickDuplicate() {
    this._showLoader(true);
    this.currentState.routeTail = null;
    this.currentState.stateConfig.revisionPlanIdToCopy = this.revisionPlanId;
    this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });
    this.$state.reload();
  }

  _evtClickAddRevision() {
    // eslint-disable-next-line no-prototype-builtins
    if (this.revisionObj.hasOwnProperty('index')) {
      this.revisionsList[this.revisionObj.index] = { ...this.revisionObj };
    } else {
      this.revisionsList.push({ ...this.revisionObj });
    }
    this.revisionObj = {};
  }

  _evtClickDeleteRevision(evt, item, itemIndex) {
    evt.stopPropagation();
    const itemDeleted = { ...item };
    this.deleteItemInfo = { item: itemDeleted, index: itemIndex };
    this.$.querySelector('#popup-confirm-delete-revision').toggle();
  }

  _evtClickRevision(revisionIndex) {
    const { limitDistance, limitTime } = this.revisionsList[revisionIndex];
    this.revisionObj = Object.clone(
      {},
      { limitDistance, limitTime, index: revisionIndex, isEditing: true },
    );
  }

  _evtClickConfirmDeleteRevision(action) {
    if (action) {
      this.revisionsList.splice(this.deleteItemInfo.index, 1);
    }
    this.$.querySelector('#popup-confirm-delete-revision').toggle();
    this.deleteItemInfo = {};
  }

  /* Cancel */
  _cancelCrudForm() {
    this._backTo(1);
  }

  _confirmCrudForm() {
    this._showLoader(true);

    this.maintenancePlanObj.id = this.revisionPlanId;

    // fields validation
    if (this._validateFields()) {
      // recover fields values
      this.fields
        .filter(x => x.value || x.type === 'switch')
        .map(item => {
          this.maintenancePlanObj[item.field] = item.value;
          return this.maintenancePlanObj;
        });

      this._upsertMaintenancePlan({ request: this.maintenancePlanObj });
    } else {
      this._showToast('Algumas informações são obrigatórias e precisam ser preenchidas.');
      this._showLoader(false);
    }
  }

  _upsertMaintenancePlan(request) {
    if (!this.canSubmit) return;

    let toggleLoader = true;
    this.canSubmit = false;

    this._showLoader(true);

    const payload = JSON.parse(
      JSON.stringify(request, (key, value) =>
        ['$id', '$$hashKey'].includes(key) ? undefined : value,
      ),
    );

    this.maintenanceServices
      .callApiMethod('Maintenance/UpsertRevisionPlan', payload)
      .then(success => {
        const { data } = success.data;
        this.canSubmit = true;

        if (success.status && success.status !== 200) {
          this._showToast('Ocorreu um erro ao salvar o plano de revisão');
          return;
        }

        if (data) {
          this._showToast(`Plano de Revisão ${this.revisionPlanId ? 'atualizado' : 'cadastrado'}`);
          toggleLoader = false;
          setTimeout(() => {
            this._cancelCrudForm();
          }, 1500);
        }
      })
      .finally(() => {
        if (toggleLoader) {
          this._showLoader(false);
        }
      });
  }

  _validateFields() {
    const emptyValues = [];

    this.fields.map(field => {
      field.warning = '';
      if (field.type === 'link' && !this.maintenancePlanObj.revisions) {
        field.warning = 'É necessário adicionar pelo uma revisão';
        emptyValues.push(field);
      } else if (field.required && !field.value) {
        field.warning = 'Campo obrigatório';
        emptyValues.push(field);
      }
      return field;
    });

    return emptyValues.length === 0;
  }

  _showLoader(show = false) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: show },
        bubbles: true,
        composed: true,
      }),
    );
  }

  _showToast(message) {
    Object.assign(this, { toast: { text: message } });
    this.$.querySelector('power-toast#form-revision-plan-toast').toggle(true);
  }

  _backTo(index) {
    const backToState = this._reverseRouteList()[index];
    this.$ngRedux.dispatch({
      type: 'PREVIOUS_ROUTE',
      data: { index: this.state.routeList.length - 1 - index },
    });
    this.$state.go(backToState.routeLink, { tail: backToState.routeTail });
  }

  _reverseRouteList() {
    return Object.assign([], this.state.routeList).reverse();
  }
}

class GolfleetFormRevisionPlanCrud {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetFormRevisionPlanCrudController;
  }
}

angular
  .module('golfleet-form-revision-plan-crud', [
    'ngRedux',
    'power-toast',
    'power-popup',
    'power-switch',
    'ng-tippy',
  ])
  .component('golfleetFormRevisionPlanCrud', new GolfleetFormRevisionPlanCrud());
