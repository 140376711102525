import angular from 'angular';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-toolbar-report/power-toolbar-report';
import '../golfleet-grid-fuel-module/golfleet-grid-fuel-module';
import '../golfleet-map-fuel-supply-management/golfleet-map-fuel-supply-management';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/directives/ng-resize/ng-resize';
import { PowerReportController } from '@power/power-components/components/power-report/power-report';

import template from './golfleet-report-fuel-supply-management.html';
import './golfleet-report-fuel-supply-management.scss';

class GolfleetReportFuelSupplyManagementController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    this.hideSomeViewButtons = false;
    this.legendList = [];
    this.mapModeList = [
      {
        type: 'distance',
        icon: 'gs_view_map',
        description: 'Divergência de Posição',
        selected: true,
      },
    ];
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();

    this.$scope.$watch(() => this.reportDataset, this.__reportDatasetChanged.bind(this));
  }
  /* */

  /* Public */
  changeView(viewMode) {
    this.stateConfig.viewMode = viewMode;
    this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });
    switch (viewMode) {
      case 'map':
        this.$.setAttribute('map-view', '');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('split-view');
        if (this.$.querySelector('#report-body-map').getMap) this._adjustMap();
        break;
      case 'split':
        this.$.setAttribute('split-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('grid-view');
        if (this.$.querySelector('#report-body-map').getMap) this._adjustMap();
        break;
      default:
        this.$.setAttribute('grid-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('split-view');
        break;
    }
  }

  changeRow(page) {
    if (page != this.stateConfig.gridConfig.page) {
      this.changePage(page);
    }
  }
  /* */

  /* Private */
  _goToLink(evt, evtParams) {
    if (evtParams.routeLink == 'map') {
      if (this.stateConfig.viewMode != 'map' && this.stateConfig.viewMode != 'split') {
        if (window.innerWidth > 878) this.changeView('split');
        else this.changeView('map');
      }
      const markers = [
        {
          icon: evtParams.tableRowData.icon || 'directions_car',
          color: '#2196F3',
          latitude: evtParams.tableRowData.latitude,
          longitude: evtParams.tableRowData.longitude,
          date: evtParams.tableRowData.dataPosicionamento,
          city: evtParams.tableRowData.municipio,
          title: evtParams.tableRowData.descriptionWithAlias,
          state: evtParams.tableRowData.estado,
          address: evtParams.tableRowData.rua,
        },
        {
          icon: 'local_gas_station',
          color: '#FFA000',
          latitude: `${evtParams.tableRowData.pcvLatitude}`,
          longitude: `${evtParams.tableRowData.pcvLongitude}`,
          date: evtParams.tableRowData.dataAbastecimento,
          city: evtParams.tableRowData.cidadePosto,
          title: evtParams.tableRowData.estabelecimento,
          state: evtParams.tableRowData.ufPosto,
          address: evtParams.tableRowData.enderecoPosto,
          streetView: evtParams.tableRowData.pcvParametroStreetView.split('|'),
        },
      ];
      const linestrings = [
        {
          geoJson: {
            type: 'LineString',
            coordinates: [
              [
                this._formatLatLng(evtParams.tableRowData.longitude),
                this._formatLatLng(evtParams.tableRowData.latitude),
              ],
              [evtParams.tableRowData.pcvLongitude, evtParams.tableRowData.pcvLatitude],
            ],
            properties: {
              color: '#F44336',
              distance: this._getDistanceBetweenMarkers(markers[0], markers[1]).toFixed(2),
              weight: 6,
            },
          },
        },
      ];
      this._renderDistanceMode(markers, linestrings);
    } else {
      super._goToLink(evt, evtParams);
    }
  }

  _renderDistanceMode(markers, linestrings) {
    const mapComponent = this.$.querySelector('#report-body-map');
    mapComponent.renderDataset({ dataset: linestrings, layerName: 'distanceLinestrings' });
    mapComponent
      .renderDataset({ dataset: markers, layerName: 'distanceMarkers', type: 'MarkerFeatureGroup' })
      .then(() => {
        mapComponent.resizeMap();
        mapComponent.fitLayers(['distanceMarkers']);
      });
  }

  _getSelectedMapMode() {
    return this.mapModeList.filter(mode => mode.selected)[0];
  }

  _formatLatLng(str) {
    return str.replace(',', '.');
  }

  _adjustMap() {
    const mapComponent = this.$.querySelector('#report-body-map');
    mapComponent.resizeMap();
    switch (this._getSelectedMapMode().type) {
      case 'distance':
        if (mapComponent.getLayer('distanceMarkers')) mapComponent.fitLayers(['distanceMarkers']);
        break;
      default:
        break;
    }
  }

  _getDistanceBetweenMarkers(marker1, marker2) {
    const sLat = (marker1.latitude * Math.PI) / 180;
    const sLon = (marker1.longitude * Math.PI) / 180;
    const fLat = (marker2.latitude * Math.PI) / 180;
    const fLon = (marker2.longitude * Math.PI) / 180;
    return (
      6371.005076123 *
      Math.acos(
        Math.sin(fLat) * Math.sin(sLat) + Math.cos(fLat) * Math.cos(sLat) * Math.cos(fLon - sLon),
      )
    );
  }
  /* */

  /* Observers */
  __reportDatasetChanged(newValue) {
    if (this.stateConfig.filterConfig.filter(filter => filter.field == 'exception')[0]) {
      const hasFilterDivergent = !!this.stateConfig.filterConfig
        .filter(filter => filter.field == 'exception')[0]
        .options.filter(option => option.id == 7)[0].selected;
      if (!hasFilterDivergent) {
        this.hideSomeViewButtons = true;
        this.changeView('grid');
      } else this.hideSomeViewButtons = false;
    }
    if (newValue)
      this.legendList = this.stateConfig.gridConfig.gridHeaders.reduce(
        (acc, ele) => (ele.icons ? acc.concat(ele) : acc),
        [],
      );
  }
  /* */
}

class GolfleetReportFuelSupplyManagement {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetReportFuelSupplyManagementController;
  }
}

angular
  .module('golfleet-report-fuel-supply-management', [
    'ngRedux',
    'power-fab',
    'power-dropdown',
    'power-toolbar-report',
    'golfleet-grid-fuel-module',
    'golfleet-map-fuel-supply-management',
    'power-footer',
    'power-pagination',
    'ng-resize',
  ])
  .component('golfleetReportFuelSupplyManagement', new GolfleetReportFuelSupplyManagement());
