const config = {
  limitClicksDepreciatedEquipaments: 3,
  hasTripConfiguration: [
    'TempUtilization',
    'Utilization',
    'KmControl',
    'Activities',
    'PeriodActivities',
    'Home',
    'Dashboard',
    'Vehicle',
    'VehicleAdm',
    'PeriodActivitiesDetail',
    'Filter',
    'Positions',
    'PositionsReport',
    'GroupAdm',
    'Group',
    'SpeedLimitModule',
    // 'RankingSpeedLimitReport',
    'RankingSpeedLimitDetail',
    'RankingSpeedLimitReportByDriver',
    'RankingSpeedLimitReportByRoute',
    'RankingSpeedLimitReportByVehicle',
    'TempViolation',
    'TempViolationVehicle',
    'FuelModule',
    'FuelSupplyManagementReport',
    'ConsuptionBySegmentReport',
    'ViolationsSpeedLimitReport',
    'MotionLog',
    'MyAlerts',
    'MyAlertsAdm',
    'FleetPolicyStatus',
    'FleetPolicyStatusAdm',
    'MenuConfiguration',
    'FleetPolicy',
    'FleetPolicyAdm',
    'TempDriver',
    'Driver',
    'Reports',
    'TravelsByDriver',
    'TravelsByGroup',
    'TravelsByUtilization',
    'TravelsByVehicle',
    'TravelsByVehicleDriver',
    'User',
    'WifiAdm',
    'RealTimeVehicle',
    'RealTimeVehicleHistory',
    'RealTimeVehicleHistoryAdm',
    'RealTimePermissionAdm',
    'RealTimeGroupByVehiclesAdm',
    'RealTimeGroupByClientDealerAdm',
    'ViolationRankingReportSPAL',
    'VisitReport',
    'RevisionHistory',
    'RevisionPlan',
    'RevisionStatus',
    'Workshop',
    'PointsOfInterest',
    'SuspectedAccident',
    'UnitaryViolation',
    'ViolationByVehicle',
    'ViolationByDriver',
    'ViolationByVehicleDriver',
    'ViolationByGroup',
    'Violation',
    'DetailedViolation',
    'DriversAppStatusAdm',
    'GolfleetIdAppStatusAdm',
    'GolfleetIdPermissionHistoricAdm',
    'UtilizationSimplified',
  ],
  hasApplicationConfiguration: [
    // Dashboard
    {
      application: 'TRACKING',
      screen: 'Home',
      type: 'Dashboard',
    },
    {
      application: 'TRACKING',
      screen: 'Violation',
      type: 'Dashboard',
    },
    // Filter
    {
      application: 'TRACKING',
      screen: 'PositionsReport',
      type: 'Filter',
    },
    {
      application: 'TRACKING',
      screen: 'TempUtilization',
      type: 'Filter',
    },
    {
      application: 'TRACKING',
      screen: 'TempViolation',
      type: 'Filter',
    },
    {
      application: 'TRACKING',
      screen: 'ViolationByVehicle',
      type: 'Filter',
    },
    {
      application: 'TRACKING',
      screen: 'ViolationByDriver',
      type: 'Filter',
    },
    {
      application: 'TRACKING',
      screen: 'ViolationByVehicleDriver',
      type: 'Filter',
    },
    {
      application: 'TRACKING',
      screen: 'ViolationByGroup',
      type: 'Filter',
    },

    // Form
    {
      application: 'TRACKING',
      screen: 'FleetPolicyAdm',
      type: 'Form',
    },
    {
      application: 'TRACKING',
      screen: 'Driver',
      type: 'Form',
    },

    // Grid
    {
      application: 'TRACKING',
      screen: 'Vehicle',
      type: 'Grid',
    },
    {
      application: 'TRACKING',
      screen: 'VehicleAdm',
      type: 'Grid',
    },
    {
      application: 'TRACKING',
      screen: 'TempUtilization',
      type: 'Grid',
    },
    {
      application: 'TRACKING',
      screen: 'TempUtilizationVehicle',
      type: 'Grid',
    },
    {
      application: 'TRACKING',
      screen: 'FleetPolicyStatus',
      type: 'Grid',
    },
    {
      application: 'TRACKING',
      screen: 'FleetPolicyStatusAdm',
      type: 'Grid',
    },
    {
      application: 'TRACKING',
      screen: 'TempUtilizationDriver',
      type: 'Grid',
    },
    {
      application: 'TRACKING',
      screen: 'PositionsReport',
      type: 'Grid',
    },
    {
      application: 'TRACKING',
      screen: 'GeneratedAlerts',
      type: 'Grid',
    },
    {
      application: 'TRACKING',
      screen: 'TempDriver',
      type: 'Grid',
    },
    {
      application: 'TRACKING',
      screen: 'Driver',
      type: 'Grid',
    },
    {
      application: 'TRACKING',
      screen: 'TempViolation',
      type: 'Grid',
    },
    {
      application: 'TRACKING',
      screen: 'TempViolationVehicle',
      type: 'Grid',
    },
    {
      application: 'TRACKING',
      screen: 'TempViolationDriver',
      type: 'Grid',
    },
    {
      application: 'TRACKING',
      screen: 'UnitaryViolation',
      type: 'Grid',
    },
    {
      application: 'TRACKING',
      screen: 'ViolationByVehicle',
      type: 'Grid',
    },
    {
      application: 'TRACKING',
      screen: 'ViolationByDriver',
      type: 'Grid',
    },
    {
      application: 'TRACKING',
      screen: 'ViolationByVehicleDriver',
      type: 'Grid',
    },
    {
      application: 'TRACKING',
      screen: 'ViolationByGroup',
      type: 'Grid',
    },

    // Record
    {
      application: 'TRACKING',
      screen: 'TempDriver',
      type: 'Record',
    },
    {
      application: 'TRACKING',
      screen: 'Driver',
      type: 'Record',
    },

    // Toolbar
    {
      application: 'TRACKING',
      screen: 'TempDriver',
      type: 'Toolbar',
    },
    {
      application: 'TRACKING',
      screen: 'Driver',
      type: 'Toolbar',
    },
    {
      application: 'TRACKING',
      screen: 'User',
      type: 'Toolbar',
    },
    {
      application: 'TRACKING',
      screen: 'Utilization',
      type: 'Filter',
    },
    {
      application: 'TRACKING',
      screen: 'Utilization',
      type: 'Grid',
    },
  ],
};

export function hasTripConfiguration(name) {
  return config.hasTripConfiguration.includes(name);
}

export function hasApplicationConfiguration(application, screen, type) {
  return (
    config.hasApplicationConfiguration.findIndex(
      item => item.application === application && item.screen === screen && item.type === type,
    ) > -1
  );
}
