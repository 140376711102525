import angular from 'angular';

import template from './golfleet-programmed-block.html';
import './golfleet-programmed-block.scss';

class GolfleetProgrammedBlockController {
  static get $inject() {
    return ['$element', '$scope', '$rootScope', '$http', 'urlApi'];
  }

  constructor($element, $scope, $rootScope, $http, urlApi) {
    Object.assign(this, {
      $: $element[0],
      $scope,
      $rootScope,
      $http,
      urlApi,
    });

    this.resultLimit = '';
    this.lblInfoFirst = '';
    this.lblInfoSecond = '';
    this.defaultHours = [];
    this.arrayComboHoraIni = [];
    this.arrayComboHoraFim = [];

    this.dataIniId = 1;
    this.dataFimId = 1;

    this.arrayComboOptions = [];

    this.weeklyOptions = [];

    this.arrayValue = [];

    this.error = false;
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      getValue: this.getValue.bind(this),
      validateHasEmptyParams: this.validateHasEmptyParams.bind(this),
    });

    this.arrayValue = this.value.split(';').map(item => item);

    if (typeof this.arrayValue !== 'undefined' && this.arrayValue.length === 7) {
      this.resultLimit = this.arrayValue;
      if (typeof this.resultLimit[0] !== 'undefined' && this.arrayComboOptions.length === 0) {
        this.arrayComboOptions = [
          {
            objectId: '1',
            value: 'Desabilitado',
            selected: this.resultLimit[0] == '0',
          },
          {
            objectId: '2',
            value: 'Habilitado',
            selected: this.resultLimit[0] == '1',
          },
        ];
      }

      this._loadAuxiliarTimeOptionsArray();

      if (this.resultLimit[0] === '0') {
        this._resetHoursWeeklyOptions();
      } else {
        let horaIni = '';
        let horaFim = '';

        if (this.arrayComboHoraIni.length === 0) {
          if (typeof this.resultLimit[2] !== 'undefined') {
            let mod = 0;
            if (this.resultLimit[0] === '1') {
              const now = new Date();
              now.setHours(parseInt(this.resultLimit[2].substr(0, 2)) - 3);

              mod = now.getHours();
            } else {
              mod = parseInt(this.resultLimit[2].substr(0, 2));
            }
            horaIni = `${String(mod).padStart(2, '0')}:${this.resultLimit[2].substr(2, 2)}`;
          }
        }

        if (this.arrayComboHoraFim.length === 0) {
          if (typeof this.resultLimit[3] !== 'undefined') {
            let mod = 0;
            if (this.resultLimit[0] === '1') {
              const now = new Date();
              now.setHours(parseInt(this.resultLimit[3].substr(0, 2)) - 3);
              mod = now.getHours();
            } else {
              mod = parseInt(this.resultLimit[3].substr(0, 2));
            }

            horaFim = `${String(mod).padStart(2, '0')}:${this.resultLimit[3].substr(2, 2)}`;
          }
        }

        this._setDefinedComboHourId(horaIni, horaFim);

        this._loadDefaultComboHoraIni(horaIni);
        this._loadDefaultComboHoraFim(horaFim);

        if (this.arrayComboHoraFim.length === 0) {
          if (typeof this.resultLimit[3] !== 'undefined') {
            let mod = 0;
            if (this.resultLimit[0] === '1') {
              const now = new Date();
              now.setHours(parseInt(this.resultLimit[3].substr(0, 2)) - 3);
              mod = now.getHours();
            } else {
              mod = parseInt(this.resultLimit[3].substr(0, 2));
            }

            horaFim = `${String(mod).padStart(2, '0')}:${this.resultLimit[3].substr(2, 2)}`;
          }
        }

        if (this.weeklyOptions.length === 0) {
          if (typeof this.resultLimit[6] !== 'undefined') {
            this._loadDefaultWeeklyOptions(this.resultLimit[6]);
            this._setLabelInfo(horaIni, horaFim);
          }
        }
      }
    }
  }

  getValue() {
    const dataDefault = 'Desabilitado';

    let strReturn = '';

    if (this.arrayComboOptions.length > 0) {
      this.arrayComboOptions.forEach(item => {
        if (item.selected && item.value === 'Desabilitado') {
          strReturn = dataDefault;
        }
      });
    }

    if (strReturn === '') {
      const auxStr = '1;America/Sao_Paulo;';

      strReturn += auxStr;

      if (this.arrayComboHoraIni.length > 0) {
        let horaIni = '';
        let mod = 0;
        this.arrayComboHoraIni.forEach(item => {
          if (item.selected) {
            if (item.value !== '') {
              const now = new Date();
              now.setHours(parseInt(item.value.substr(0, 2)) + 3);

              mod = now.getHours();

              horaIni = `${String(mod).padStart(2, '0') + item.value.substr(3, 2)};`;
            } else {
              horaIni = '0000;';
            }
            strReturn += horaIni;
          }
        });
      }

      if (this.arrayComboHoraFim.length > 0) {
        let horaFim = '';
        let mod = 0;
        this.arrayComboHoraFim.forEach(item => {
          if (item.selected) {
            if (item.value !== '') {
              const now = new Date();
              now.setHours(parseInt(item.value.substr(0, 2)) + 3);

              mod = now.getHours();

              horaFim = `${String(mod).padStart(2, '0') + item.value.substr(3, 2)};`;
            } else {
              horaFim = '0000;';
            }

            strReturn += horaFim;
          }
        });
      }

      const fixedValue = '0000;0000;';
      strReturn += fixedValue;

      if (this.weeklyOptions.length > 0) {
        let strWeeklyOptions = '';
        this.weeklyOptions.forEach(item => {
          if (item.selected) {
            strWeeklyOptions += '1';
          } else {
            strWeeklyOptions += '0';
          }
        });
        strReturn += strWeeklyOptions;
      }
    }

    return strReturn;
  }

  validateHasEmptyParams() {
    this.error = true;
  }

  _comboOptionsClick() {
    if (typeof this.arrayComboOptions !== 'undefined' && this.arrayComboOptions.length > 0) {
      this.arrayComboOptions.forEach(item => {
        if (item.selected && item.value === 'Desabilitado') {
          this._resetHoursWeeklyOptions();
        } else if (item.selected && this.arrayComboHoraIni.length <= 1) {
          const horaIni = '07:00';
          const horaFim = '18:00';
          this._setDefinedComboHourId(horaIni, horaFim);
          this._loadDefaultComboHoraIni(horaIni);
          this._loadDefaultComboHoraFim(horaFim);
          this._loadDefaultWeeklyOptions('0111110');
          this._setLabelInfo(horaIni, horaFim);
        }
      });
    }
  }

  _toggleDayOfWeekOption(option) {
    option.selected = !option.selected;

    let horaIni = '';
    let horaFim = '';

    this.arrayComboHoraIni.forEach(item => {
      if (item.selected) {
        if (item.value !== '') {
          horaIni = item.value;
        }
      }
    });

    this.arrayComboHoraFim.forEach(item => {
      if (item.selected) {
        if (item.value !== '') {
          horaFim = item.value;
        }
      }
    });

    if (horaIni !== '' && horaFim !== '') {
      this._setLabelInfo(horaIni, horaFim);
    }

    if (option.selected && this.error) {
      this.error = false;
    }
  }

  _setIntevalEndsIndex() {
    this.arrayComboHoraIni.forEach(item => {
      if (item.selected && item.objectId != this.dataIniId) {
        if (item.value !== '') {
          const horaIni = item.value;
          let horaFim = '';
          this.dataIniId = item.objectId;
          this.arrayComboHoraFim = [];
          let otherComboIdSelected = this.dataFimId;
          if (otherComboIdSelected < item.objectId) {
            otherComboIdSelected = item.objectId + 1;
            this.dataFimId = item.objectId + 1;
          }
          this.defaultHours.forEach(option => {
            if (option.id > item.objectId) {
              this.arrayComboHoraFim.push({
                objectId: option.id,
                value: option.value,
                selected: option.id == otherComboIdSelected,
              });
            }
            if (option.id === otherComboIdSelected) {
              horaFim = option.value;
            }
          });
          this._setLabelInfo(horaIni, horaFim);
        }
      }
    });
  }

  _setIntevalStartsIndex() {
    this.arrayComboHoraFim.forEach(item => {
      if (item.selected && item.objectId != this.dataFimId) {
        if (item.value !== '') {
          const horaFim = item.value;
          let horaIni = '';
          this.dataFimId = item.objectId;
          this.arrayComboHoraIni = [];
          let otherComboIdSelected = this.dataIniId;
          if (otherComboIdSelected === 1) {
            otherComboIdSelected += 1;
            this.dataIniId = otherComboIdSelected;
          }

          this.defaultHours.forEach(option => {
            if (option.id < item.objectId && option.id !== 1) {
              this.arrayComboHoraIni.push({
                objectId: option.id,
                value: option.value,
                selected: option.id == otherComboIdSelected,
              });
            }
            if (option.id === otherComboIdSelected) {
              horaIni = option.value;
            }
          });
          this._setLabelInfo(horaIni, horaFim);
        }
      }
    });
  }

  _setDefinedComboHourId(horaIni, horaFim) {
    if (horaIni !== '00:00' || horaFim !== '00:00') {
      this.defaultHours.forEach(option => {
        if (option.value === horaIni) {
          this.dataIniId = option.id;
        }
        if (option.value === horaFim) {
          this.dataFimId = option.id;
        }
      });
    }
  }

  _setLabelInfo(horaIni, horaFim) {
    if (this.weeklyOptions.length > 0) {
      let strWeeklyOptions = '';
      let strWeeklyOptionsBlocked = '';

      if (this.weeklyOptions.length > 0) {
        this.weeklyOptions.forEach(item => {
          if (item.selected) {
            strWeeklyOptions += ` ${item.description},`;
          } else {
            strWeeklyOptionsBlocked += ` ${item.description},`;
          }
        });
        if (strWeeklyOptions !== '') {
          strWeeklyOptions = `${strWeeklyOptions.slice(0, -1)}.`;
          this.lblInfoFirst = `Os Veículos estarão desbloqueados das ${horaIni} até às ${horaFim} nos seguintes dias: ${strWeeklyOptions}`;
        } else {
          this.lblInfoFirst = '';
        }

        if (strWeeklyOptions !== '' && strWeeklyOptionsBlocked !== '') {
          strWeeklyOptionsBlocked = `${strWeeklyOptionsBlocked.slice(0, -1)}.`;
          this.lblInfoSecond = `Os Veículos estarão bloqueados o dia inteiro nos seguintes dias: ${strWeeklyOptionsBlocked}`;
        } else {
          this.lblInfoSecond = '';
        }
      }
    }
  }

  _loadDefaultComboHoraIni(horaIni) {
    this.arrayComboHoraIni = [];
    this.defaultHours.forEach(option => {
      if (option.value === horaIni) {
        this.dataIniId = option.id;
      }
      if (option.id !== this.defaultHours.length && option.id !== 1) {
        if (option.id < this.dataFimId) {
          this.arrayComboHoraIni.push({
            objectId: option.id,
            value: option.value,
            selected: option.value == horaIni,
          });
        }
      }
    });
  }

  _loadDefaultComboHoraFim(horaFim) {
    this.arrayComboHoraFim = [];

    this.defaultHours.forEach(option => {
      if (option.value === horaFim) {
        this.dataFimId = option.id;
      }
      if (option.id > this.dataIniId && option.id !== 2 && option.id !== 1) {
        this.arrayComboHoraFim.push({
          objectId: option.id,
          value: option.value,
          selected: option.value == horaFim,
        });
      }
    });
  }

  _loadDefaultWeeklyOptions(stringValue) {
    this.weeklyOptions = [
      {
        Id: 'chkdom',
        description: 'DOM',
        value: '1',
        selected: stringValue.substr(0, 1) == '1',
      },
      {
        Id: 'chkseg',
        description: 'SEG',
        value: '2',
        selected: stringValue.substr(1, 1) == '1',
      },
      {
        Id: 'chkter',
        description: 'TER',
        value: '3',
        selected: stringValue.substr(2, 1) == '1',
      },
      {
        Id: 'chkqua',
        description: 'QUA',
        value: '4',
        selected: stringValue.substr(3, 1) == '1',
      },
      {
        Id: 'chkqui',
        description: 'QUI',
        value: '5',
        selected: stringValue.substr(4, 1) == '1',
      },
      {
        Id: 'chksex',
        description: 'SEX',
        value: '6',
        selected: stringValue.substr(5, 1) == '1',
      },
      {
        Id: 'chksab',
        description: 'SAB',
        value: '7',
        selected: stringValue.substr(6, 1) == '1',
      },
    ];
  }

  _resetHoursWeeklyOptions() {
    this.error = false;
    this.lblInfoFirst = '';
    this.lblInfoSecond = '';
    this.arrayComboHoraIni = [];
    this.arrayComboHoraFim = [];

    this.arrayComboHoraIni.push({
      objectId: 1,
      value: '',
      selected: true,
    });
    this.arrayComboHoraFim.push({
      objectId: 1,
      value: '',
      selected: true,
    });

    this.dataIniId = 1;
    this.dataFimId = 1;

    this.weeklyOptions = [];
  }

  _loadAuxiliarTimeOptionsArray() {
    const getDates = (start, end, interval) => {
      const dates = [];
      let date = start;
      let contador = 1;

      dates.push({
        value: '',
        id: contador,
      });

      while (date <= end) {
        contador++;
        dates.push({
          value: `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(
            2,
            '0',
          )}`,
          id: contador,
        });

        date = new Date(date.setMinutes(date.getMinutes() + interval));
      }
      return dates;
    };

    const start = new Date(2022, 1, 1);
    start.setHours(0, 0, 0, 0);
    const end = new Date(2022, 1, 1);
    end.setHours(21, 0, 0, 0);
    const interval = 30;
    this.defaultHours = getDates(start, end, interval);
  }
}

class GolfleetProgrammedBlock {
  constructor() {
    this.bindings = {
      value: '=?',
    };
    this.template = template;
    this.controller = GolfleetProgrammedBlockController;
  }
}

angular
  .module('golfleet-programmed-block', [])
  .component('golfleetProgrammedBlock', new GolfleetProgrammedBlock());
