import angular from 'angular';
import moment from 'moment/src/moment';
import * as Comlink from 'comlink';
import 'moment/src/locale/pt-br';
import '../power-accordion/power-accordion';

import template from './power-navigation-date.html';
import './power-navigation-date.scss';

class PowerNavigationDateController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$state',
      '$http',
      '$timeout',
      '$filter',
      'commonServices',
      'urlApi',
    ];
  }

  constructor(
    $element,
    $scope,
    $ngRedux,
    $state,
    $http,
    $timeout,
    $filter,
    commonServices,
    urlApi,
  ) {
    Object.assign(this, {
      $: $element[0],
      $scope,
      $ngRedux,
      $state,
      $http,
      $timeout,
      $filter,
      commonServices,
      urlApi,
    });

    this.path = [];
    this.minPath = 0;
    this.hidden = true;
    this.dateTree = [];
    this.description = '';
    this.parsedDatePath = [];
    this.maxGranularity = 'hora';

    moment.locale('pt-BR');

    this.worker = new Worker('./power-navigation-date.worker.js');
    this.workerService = Comlink.wrap(this.worker);
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      setup: this.setup.bind(this),
      getPath: this.getPath.bind(this),
      getConfig: this.getConfig.bind(this),
      setDrillUp: this.setDrillUp.bind(this),
      setDrillDown: this.setDrillDown.bind(this),
      setDrillThrough: this.setDrillThrough.bind(this),
      setPathGranularity: this.setPathGranularity.bind(this),
    });

    this.$.setAttribute('hidden', '');
  }

  $onDestroy() {
    this.worker.terminate();
  }
  /* */

  /* Public */
  async setup(params = {}) {
    const { path = [], minPath = 0 } = params;

    if (path.length > 0) {
      await this.workerService.setup({ path, minPath, maxGranularity: this.maxGranularity });

      await this._syncState();

      this.hidden = false;

      this.$.removeAttribute('hidden', '');
    }
  }

  async setDrillUp(params = {}) {
    const { level = 0 } = params;

    await this.workerService.setDrillUp({ level });

    await this._syncState();

    if (this.$.querySelector(`power-dropdown[open]`)) {
      this.$.querySelector('power-dropdown[open]').toggle();
    }

    if (this.$.querySelector(`power-accordion[open]`)) {
      this.$.querySelector(`power-accordion[open]`).toggle();
    }

    this.$.dispatchEvent(new CustomEvent('setDrillUp'));
  }

  async setDrillDown(params = {}) {
    const { date = new Date() } = params;

    await this.workerService.setDrillDown({ date });

    await this._syncState();

    if (this.$.querySelector(`power-dropdown[open]`)) {
      this.$.querySelector('power-dropdown[open]').toggle();
    }

    if (this.$.querySelector(`power-accordion[open]`)) {
      this.$.querySelector(`power-accordion[open]`).toggle();
    }

    this.$.dispatchEvent(new CustomEvent('setDrillDown'));
  }

  async setDrillThrough(params = {}) {
    const { level, dateItem } = params;

    await this.workerService.setDrillThrough({ level, dateItem });

    await this._syncState();

    if (this.$.querySelector(`power-dropdown[open]`)) {
      this.$.querySelector('power-dropdown[open]').toggle();
    }

    if (this.$.querySelector(`power-accordion[open]`)) {
      this.$.querySelector(`power-accordion[open]`).toggle();
    }

    this.$.dispatchEvent(new CustomEvent('setDrillThrough'));
  }

  async setPathGranularity(params = {}) {
    const { granularity = '' } = params;

    await this.workerService.setPathGranularity({ granularity });

    await this._syncState();

    if (this.$.querySelector(`power-dropdown[open]`)) {
      this.$.querySelector('power-dropdown[open]').toggle();
    }

    if (this.$.querySelector(`power-accordion[open]`)) {
      this.$.querySelector(`power-accordion[open]`).toggle();
    }

    this.$.dispatchEvent(new CustomEvent('setPathGranularity'));
  }

  getPath() {
    return this.path;
  }

  getConfig() {
    return {
      minPath: this.minPath,
      maxGranularity: this.maxGranularity,
    };
  }
  /* */

  /* Private */
  async _syncState() {
    this.path = await this.workerService.path;
    this.minPath = await this.workerService.minPath;
    this.dateTree = await this.workerService.dateTree;
    this.description = await this.workerService.description;

    this.parsedDatePath = this._parseDatePath();

    if (!this.$scope.$$phase) {
      this.$scope.$apply();
    }

    this.$.dispatchEvent(new CustomEvent('syncState'));
  }

  _toDate(date) {
    return moment(date).utcOffset(-3 - moment(date).utcOffset() / 60);
  }

  _parseDatePath() {
    return this.path.map((pathItem, index, arr) => {
      let icon = '';
      let endDate = this._toDate(pathItem.endDate);
      let startDate = this._toDate(pathItem.startDate);
      let description = '';

      switch (index) {
        case 0:
          icon =
            arr.length === 1 ? './assets/images/active-node.svg' : './assets/images/node-top.svg';
          endDate = moment(endDate).format('DD-MM-YYYY');
          startDate = moment(startDate).format('DD-MM-YYYY');
          description = `${startDate} até ${endDate}`;
          break;
        case 1:
          icon =
            arr.length === 2
              ? './assets/images/active-node-bottom.svg'
              : './assets/images/node-mid.svg';
          endDate = moment(endDate).format('MMMM [de] YYYY');
          startDate = moment(startDate).format('MMMM [de] YYYY');
          description =
            this.minPath >= 1 && endDate !== startDate
              ? `${startDate} até ${endDate}`
              : `${startDate}`;
          break;
        case 2:
          icon =
            arr.length === 3
              ? './assets/images/active-node-bottom.svg'
              : './assets/images/node-mid.svg';
          endDate = moment(endDate).format('DD [de] MMMM');
          startDate = moment(startDate).format('DD [de] MMMM');
          description =
            this.minPath >= 2 && endDate !== startDate
              ? `${startDate} até ${endDate}`
              : `${startDate}`;
          break;
        case 3:
          icon = './assets/images/active-node-bottom.svg';
          endDate = moment(endDate).format('DD-MM-YYYY - HH:mm');
          startDate = moment(startDate).format('DD-MM-YYYY - HH:mm');
          description =
            this.minPath >= 3 && endDate !== startDate
              ? `${startDate} até ${endDate}`
              : `${startDate}`;
          break;
        default:
          break;
      }

      return { icon, description };
    });
  }

  _getDayList(disabled = false) {
    const dayList = [];

    if (disabled) {
      const [
        { endDate: maxDate, startDate: minDate },
        { endDate: endMonthDate, startDate: startMonthDate },
      ] = this.path;

      const lastDay = moment(this._toDate(endMonthDate));
      const firstDay = moment(this._toDate(startMonthDate));
      const dayIndexLimit = lastDay.diff(firstDay, 'days');

      let dayDate = null;
      let actualDate = null;

      if (this.path.length > 2) {
        [, , { startDate: dayDate }] = this.path;

        actualDate = moment(this._toDate(dayDate));
      }

      for (let index = 0; index <= dayIndexLimit; index += 1) {
        const value = moment(firstDay).add(index, 'days');

        if (value.isSameOrAfter(minDate, 'date') && value.isSameOrBefore(maxDate, 'date')) {
          dayList.push({
            active: value.isSame(actualDate),
            endDate: moment(value).endOf('date')._d,
            startDate: moment(value).startOf('date')._d,
            description: value.format('DD [de] MMMM'),
          });
        }
      }
    }

    return dayList;
  }

  _getHourList(disabled = false) {
    const dayList = [];

    if (disabled) {
      const [
        { endDate: maxDate, startDate: minDate },
        ,
        { endDate: endDayDate, startDate: startDayDate },
      ] = this.path;

      const lastHour = moment(this._toDate(endDayDate));
      const firstHour = moment(this._toDate(startDayDate));
      const hourIndexLimit = lastHour.diff(firstHour, 'hours');

      let dayDate = null;
      let actualDate = null;

      if (this.path.length > 3) {
        [, , , { startDate: dayDate }] = this.path;

        actualDate = moment(this._toDate(dayDate));
      }

      for (let index = 0; index <= hourIndexLimit; index += 1) {
        const value = moment(firstHour).add(index, 'hours');

        if (value.isSameOrAfter(minDate, 'minute') && value.isSameOrBefore(maxDate, 'minute')) {
          dayList.push({
            active: value.isSame(actualDate),
            endDate: moment(value).endOf('hour')._d,
            startDate: moment(value).startOf('hour')._d,
            description: value.format('DD-MM-YYYY - HH:mm'),
          });
        }
      }
    }

    return dayList;
  }

  _getMonthList(disabled = false) {
    const monthList = [];

    if (disabled) {
      const [{ startDate, endDate }] = this.path;

      const offset = -moment(endDate).utcOffset() / 60;
      const lastMonth = moment(endDate).utcOffset(offset);
      const firstMonth = moment(startDate).utcOffset(offset);
      const monthIndexLimit = this._monthDiff(firstMonth._d, lastMonth._d);

      let monthDate = null;
      let actualMonth = null;

      if (this.path.length > 1) {
        [, { startDate: monthDate }] = this.path;

        actualMonth = moment(monthDate).utcOffset(0);
      }

      for (let index = 0; index <= monthIndexLimit; index += 1) {
        const value = moment(startDate).utcOffset(0).add(index, 'months');

        monthList[index] = {
          active: value.isSame(actualMonth),
          endDate: moment(value).endOf('month')._d,
          startDate: moment(value).startOf('month')._d,
          description: value.format('MMMM [de] YYYY'),
        };
      }
    }

    return monthList;
  }

  _monthDiff(startDate, endDate) {
    let months = 0;

    months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
    months -= startDate.getMonth();
    months += endDate.getMonth();

    return months <= 0 ? 0 : months;
  }
  /* */

  /* Observers */
  __onCloseDropdown() {
    this.$.querySelectorAll(`power-accordion[open]`).forEach(nodeItem => {
      nodeItem.toggle();
    });
  }

  __onToggleMenuItem(evt) {
    if (evt.target.hasAttribute('open')) {
      this.$.querySelectorAll(`power-accordion[open]:not([id="${evt.target.id}"])`).forEach(
        nodeItem => {
          nodeItem.toggle();
        },
      );
    }
  }
  /* */
}

class PowerNavigationDate {
  constructor() {
    this.template = template;
    this.bindings = {
      apiMethod: '=?',
      apiParams: '=?',
      maxGranularity: '@?',
    };
    this.controller = PowerNavigationDateController;
  }
}

angular
  .module('power-navigation-date', ['ngSanitize', 'power-accordion'])
  .component('powerNavigationDate', new PowerNavigationDate());

export { PowerNavigationDateController, PowerNavigationDate };
