(() => {
  if (typeof Object.clone === 'function') return false;

  Object.defineProperty(Object, 'clone', {
    enumerable: false,
    configurable: true,
    writable: true,
    value(type, obj) {
      return JSON.parse(JSON.stringify(Object.assign(type || {}, obj)));
    },
  });

  return true;
})();
