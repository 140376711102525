import moment from 'moment/src/moment';

function getParams(params = {}) {
  const {
    date,
    language = navigator.language,
    // timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  } = params;

  const timezone = 'America/Sao_Paulo';

  return { date, language, timezone };
}

function toDateTimeOffset(params = {}) {
  const { date, language, timezone } = getParams(params);
  if (date) {
    const dateTimeFormat = new Intl.DateTimeFormat(language, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: timezone,
    });
    return dateTimeFormat.format(new Date(date));
  }
  return null;
}

function toDateOffset(params = {}) {
  const { date, language, timezone } = getParams(params);
  if (date) {
    const dateTimeFormat = new Intl.DateTimeFormat(language, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      timeZone: timezone,
    });
    return dateTimeFormat.format(new Date(date));
  }
  return null;
}

function toTimeOffset(params = {}) {
  const { date, language, timezone } = getParams(params);

  if (date) {
    const dateTimeFormat = new Intl.DateTimeFormat(language, {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: timezone,
    });
    return dateTimeFormat.format(new Date(date));
  }
  return null;
}
function toDateTimeInput(params = {}) {
  const { date, language, timezone } = getParams(params);

  if (date) {
    const optionYear = new Intl.DateTimeFormat(language, {
      year: 'numeric',
      timeZone: timezone,
    });

    const optionMonth = new Intl.DateTimeFormat(language, {
      month: '2-digit',
      timeZone: timezone,
    });

    const optionDay = new Intl.DateTimeFormat(language, {
      day: '2-digit',
      timeZone: timezone,
    });

    const optionTime = new Intl.DateTimeFormat(language, {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: timezone,
    });

    const datestr = `${optionYear.format(date)}-${optionMonth.format(date)}-${optionDay.format(
      date,
    )}T${optionTime.format(date)}`;
    return new Date(Date.parse(datestr));
  }
  return null;
}

function toUTCDate(params = {}) {
  const { date } = params;

  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();
  const day = date.getUTCDate();
  const hours = date.getUTCHours() - date.getTimezoneOffset() / 60;
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();
  const ms = date.getUTCMilliseconds();

  const result = new Date();
  result.setUTCFullYear(year);
  result.setUTCMonth(month);
  result.setUTCDate(day);
  result.setUTCHours(hours + 3, minutes, seconds, ms);

  return result;
}

function toDate(date) {
  return moment(date).utcOffset(-3 - moment(date).utcOffset() / 60)._d;
}

/**
 * Validation: Value contains filter?
 * @param {String} date
 * @param {String} time
 */
function getDateTime(date, time) {
  if (!date || !time) {
    return null;
  }
  const [hour, minute] = time.split(':');
  const dateTime = new Date(date);
  dateTime.setHours(hour);
  dateTime.setMinutes(minute);
  return dateTime;
}

function getUTCDateTime(date, time) {
  if (!date || !time) {
    return null;
  }
  const [hour, minute] = time.split(':');
  const dateTime = new Date(date);
  dateTime.setUTCHours(parseInt(hour) + 3);
  dateTime.setUTCMinutes(minute);
  return dateTime;
}

export {
  toDateTimeOffset,
  toDateOffset,
  toTimeOffset,
  getDateTime,
  toDateTimeInput,
  toDate,
  toUTCDate,
  getUTCDateTime,
};
