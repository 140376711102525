/* global L: true isIframe: true */
import { PowerMapLeafletProvider } from '../../../power-map/providers/leaflet/power-map.leaflet-provider';
import '../../../../helpers/is-iframe/is-iframe';
import './power-map-ranking-details-speed-limit.leaflet-provider.scss';

class PowerMapRankingDetailsSpeedLimitLeafletProvider extends PowerMapLeafletProvider {
  constructor(context, $element, $ngRedux, $scope, $http, urlApi) {
    super(context, $element, $ngRedux, $scope, $http, urlApi);

    this.intervalFullscreen = null;
    this.msgInterval = null;
  }

  /* Private */
  _geoJsonToMarker({ geoJson }) {
    return L.geoJSON(geoJson, {
      pointToLayer: (feature, latlng) =>
        L.marker(latlng, {
          ...feature.properties,
          icon: this._createMarkerIcon({
            ...feature.properties,
            icon: feature.properties.icon || 'directions_car',
          }),
        }).bindPopup(`
          <div id="mapPopupHeader">
            <span>${feature.properties.veiculoComAlias}</span>
          </div>
          <div id="mapPopupBody">
            <div style="display: ${feature.properties.rua ? 'block' : 'none'}">
              <b>Endereço:</b>
              <br>
              <span>${feature.properties.rua}</span>
            </div>
            <div style="display: ${feature.properties.rua ? 'block' : 'none'}">
              <b>Cidade - Estado:</b>
              <br>
              <span>${feature.properties.municipio} - ${feature.properties.estado}</span>
            </div>
            <div>
              <b>Data Hora:</b>
              <br>
              <span>${new Date(feature.properties.dataHora).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })}</span>
            </div>
            <div>
              <b>Velocidade:</b>
              <br>
              <span>
                ${feature.properties.velocidadeVeiculo}
              </span>
            </div>
            <div>
              <b>Tipo de Infração:</b>
              <br>
              <span>${feature.properties.descInfracao}</span>
            </div>
            <div>
              <b>Condutor:</b>
              <br>
              <span>${feature.properties.tmtNome || 'Não identificado'}</span>
            </div>
            ${
              isIframe()
                ? ''
                : `
              <div style="margin-top:8px;text-align:center">
                <a class="gs-link"
                  target="_blank"
                  href="${`https://maps.google.com/maps?layer=c&q=${latlng.lat},${latlng.lng}&cbll=${latlng.lat},${latlng.lng}&cbp=11,0,0,0,0&z=17&ll=${latlng.lat},${latlng.lng}`}">
                  Ver no StreetView
                </a>
              </div>
            `
            }
          </div>
          <div id="mapPopupFooter">
          <span> Lat: ${parseFloat(latlng.lat).toFixed(4)} </span>
          <span> Lon: ${parseFloat(latlng.lng).toFixed(4)} </span>
          </div>
        `),
    }).getLayers()[0];
  }
  /**/
}

export { PowerMapRankingDetailsSpeedLimitLeafletProvider };
