import angular from 'angular';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import Treemap from 'highcharts/modules/treemap';
import 'highcharts-regression';

import template from './power-chart-position.html';
import './power-chart-position.scss';

Exporting(Highcharts);
OfflineExporting(Highcharts);
Treemap(Highcharts);

class PowerChartPositionController {
  static get $inject() {
    return ['$element', '$scope'];
  }

  constructor($element, $scope) {
    Object.assign(this, { $: $element[0], $scope });

    const curry =
      fn =>
      (...args) =>
        fn.bind(null, ...args);
    const compose =
      (...fns) =>
      x =>
        fns.reduceRight((v, f) => f(v), x);
    const reduce = curry((fn, acc, arr) => arr.reduce(fn, acc));
    const zoneColor = ignicao => (!ignicao ? '#980A1A' : '#4AAE4E');
    const __baseIgnitionZone = arr =>
      arr.concat({
        color: zoneColor(arr[0].ignicao),
        fillColor: zoneColor(arr[0].ignicao),
      });
    const __reduceIgnitionZone = (acc, ele, index, arr) => {
      if (index == arr.length - 1)
        return acc.concat({
          value: ele.x,
          color: zoneColor(ele.tooltipDataset.ignicao),
          fillColor: zoneColor(ele.tooltipDataset.ignicao),
          ignicao: ele.tooltipDataset.ignicao,
        });
      return acc.concat({
        value: arr[index + 1].x,
        color: zoneColor(arr[index + 1].tooltipDataset.ignicao),
        fillColor: zoneColor(arr[index + 1].tooltipDataset.ignicao),
        ignicao: arr[index + 1].tooltipDataset.ignicao,
      });
    };
    this.__chartIgnitionZones = compose(__baseIgnitionZone, reduce(__reduceIgnitionZone, []));

    this.chartInstance = null;
  }

  // #region Lifecycle
  $onInit() {
    Object.assign(this.$, {
      updateChart: this.updateChart.bind(this),
    });

    this.$scope.$watch(() => this.chartDataset, this.__chartDatasetChanged.bind(this));
  }
  // #endregion Lifecycle

  // #region Public
  updateChart() {
    this.chartInstance = new Highcharts.Chart({
      chart: {
        renderTo: 'report-chart-container',
        type: 'line',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        style: {
          fontFamily: 'Ubuntu',
          fontSize: '12px',
        },
      },
      title: { text: null },
      yAxis: {
        labels: {
          enabled: true,
          formatter() {
            return `${this.value} Km/h`;
          },
        },
        title: { text: null },
      },
      xAxis: {
        labels: {
          y: 24,
          enabled: true,
          formatter() {
            return new Date(this.value).toLocaleTimeString('pt-BR', { timeZone: 'America/Sao_Paulo' });
          },
        },
        title: { text: null },
        alternateGridColor: 'rgba(0, 0, 0, 0.05)',
      },
      tooltip: {
        enabled: true,
        borderRadius: 8,
        padding: 0,
        useHTML: true,
        formatter() {
          return `
				<section style="padding: 8px;">
					<div class="flex-row i-center d-center"
						style="color: #FFF; margin-bottom: 16px;">
						<span> ${this.point.tooltipDataset.placaComApelido} </span>
					</div>
					<div style="color: #FFF; font-size: 13px; margin-bottom: 4px;">
						<b>Velocidade:</b>
						<i class="material-icons"
							style="font-size: 11px; color: ${!this.point.tooltipDataset.ignicao ? '#980A1A' : '#4AAE4E'}">
							lens
						</i>
						<span>${this.point.tooltipDataset.velocidade} Km/h</span>
					</div>
					<div style="color: #FFF; font-size: 13px; margin-bottom: 4px;">
						<b>Data Hora:</b>
						<span>${new Date(this.point.tooltipDataset.dataHora).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })}</span>
					</div>
				</section>
			`;
        },
      },
      plotOptions: {
        // series: {
        // 	zoneAxis: "x",
        // 	zones: this.__chartIgnitionZones(newValue)
        // }
        series: {
          marker: {
            enabled: true,
          },
          turboThreshold: 2056,
        },
      },
      exporting: { enabled: false },
      legend: { enabled: false },
      credits: { enabled: false },
      series: [{ data: this.chartDataset }],
    });
  }
  // #endregion Public

  // #region Protected
  __exportChartToPNG() {
    this.chartInstance.exportChartLocal({
      filename: `Historico de Posicoes ${
        this.chartDataset[0].tooltipDataset.placaComApelido
      } - De ${new Date(
        this.chartDataset[0].tooltipDataset.dataHora,
      ).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })} a ${new Date(
        this.chartDataset[this.chartDataset.length - 1].tooltipDataset.dataHora,
      ).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })}`,
      type: 'application/png',
    });
  }

  __chartDatasetChanged(newValue) {
    if (newValue) {
      this.updateChart(newValue);
    }
  }
  // #endregion Protected
}

class PowerChartPosition {
  constructor() {
    this.template = template;
    this.bindings = {
      chartDataset: '=?',
    };
    this.controller = PowerChartPositionController;
  }
}

angular
  .module('power-chart-position', [])
  .component('powerChartPosition', new PowerChartPosition());
