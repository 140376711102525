/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import angular from 'angular';
import moment from 'moment';

import { FilterCondition } from '@power/power-components/components/power-filter/power-filter';
import {
  ReportStateConfig,
  RecordStateConfig,
} from '@power/power-components/components/power-state-config/power-state-config';

import template from './power-simple-card-list.html';
import './power-simple-card-list.scss';

class PowerSimpleCardListController {
  static get $inject() {
    return [
      '$element',
      '$rootScope',
      '$scope',
      '$state',
      '$http',
      '$timeout',
      '$filter',
      'urlApi',
      'commonServices',
      'recordServices',
    ];
  }

  /**
   * Creates an instance of SimpleCardListController.
   */
  constructor(
    $element,
    $rootScope,
    $scope,
    $state,
    $http,
    $timeout,
    $filter,
    urlApi,
    commonServices,
    recordServices,
  ) {
    Object.assign(this, {
      $: $element[0],
      $rootScope,
      $scope,
      $state,
      $http,
      $timeout,
      $filter,
      urlApi,
      commonServices,
      recordServices,
    });

    this.sortableColumns = null;
    this.sortableColumnSelected = null;
  }

  /* Lifecycle */
  $onInit() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this.$scope.$on('getHeaders', this._getHeaders.bind(this));
    this.$scope.$on('getDataset', this._getDataset.bind(this));
    this.$scope.$on('changePage', this._changePage.bind(this));
    this.$scope.$on('changePageSize', this._changePageSize.bind(this));
    this.$scope.$emit('getDatasetReady');
  }
  /* */

  /**
   *
   *
   * @memberof SimpleCardListController
   */
  evtSelectSortableColumn(itemReturn) {
    this.sortableColumnSelected = itemReturn;

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    this.sortGrid(itemReturn.value);
  }

  /**
   *
   *
   * @memberof SimpleCardListController
   */
  evtClickSortDirection() {
    this.sortDirection = this.sortDirection == 'asc' ? 'desc' : 'asc';

    Object.assign(this, {
      sortHeader: this.sortableColumnSelected.value,
      sortDirection: this.sortDirection,
    });
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    this._getDataset();
  }

  /**
   * Converte um valor de Data String para Objeto Data
   *
   * @param {String} str
   * @returns {Object} new Date
   * @memberof SimpleCardListController
   */
  toDate(date) {
    return moment(date).utcOffset(-3 - moment(date).utcOffset() / 60)._d;
  }

  /**
   */
  sortGrid(sortName) {
    if (this.sortHeader.toLowerCase() != sortName.toLowerCase())
      Object.assign(this, {
        sortHeader: sortName,
        sortDirection: 'asc',
      });
    else
      Object.assign(this, {
        sortDirection: this.sortDirection == 'desc' ? 'asc' : 'desc',
      });
    this._getDataset();
  }

  /**
   */
  goToLink(
    index,
    tableRowData,
    routeName,
    routeLink,
    stateFixedParams,
    linkStateConfig,
    getDataMethod,
    backPagination = true,
    fixedFilters,
  ) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    let getDataFilters = [];
    const getDataFixedParams = {};
    index += (this.page - 1) * this.pageSize;
    if (backPagination === null) backPagination = false;
    for (const attr in stateFixedParams) {
      if (tableRowData[stateFixedParams[attr]])
        getDataFixedParams[attr] = tableRowData[stateFixedParams[attr]];
      else if (this.stateConfig.getDataFixedParams[stateFixedParams[attr]])
        getDataFixedParams[attr] = this.stateConfig.getDataFixedParams[stateFixedParams[attr]];
      else getDataFixedParams[attr] = stateFixedParams[attr];
    }
    if (fixedFilters)
      getDataFilters = fixedFilters.map(filter => {
        const fixedFilterValue = filter.condition.map(
          // eslint-disable-next-line no-shadow
          (condition, index) => {
            const data = tableRowData[condition.field];
            if (filter.type == 'DateTime') {
              let conditionDate = moment(data).utcOffset(-3);

              conditionDate = conditionDate.subtract(condition.value, 'days');

              if (!filter.keepTime) {
                if (index == 0) conditionDate.hour(0).minute(0).second(0).millisecond(0);
                else if (index == 1) conditionDate.hour(23).minute(59).second(59).millisecond(0);
              }
              return conditionDate._d;
            }
            if (condition.value) {
              return data ? data + condition.value : condition.value;
            }

            return data;
          },
        );
        if (filter.type == 'DateTime')
          if (filter.actualDate) {
            const startFieldDate = new Date(
              tableRowData[filter.condition[0].field]
                ? new Date(tableRowData[filter.condition[0].field])
                : new Date(new Date().setHours(0, 0, 0)),
            );
            const endFieldDate = new Date(
              tableRowData[filter.condition[1].field]
                ? new Date(tableRowData[filter.condition[1].field])
                : new Date(new Date().setHours(23, 59, 59)),
            );
            return new FilterCondition(filter.id, filter.field, [
              fixedFilterValue,
              [startFieldDate.getHours(), startFieldDate.getMinutes(), startFieldDate.getSeconds()],
              [endFieldDate.getHours(), endFieldDate.getMinutes(), endFieldDate.getSeconds()],
            ]);
          } else
            return new FilterCondition(filter.id, filter.field, [
              fixedFilterValue,
              [0, 0, 0],
              [23, 59, 59],
            ]);
        return new FilterCondition(filter.id, filter.field, [fixedFilterValue]);
      });
    switch (routeLink) {
      case 'external': {
        if (tableRowData[routeName]) {
          window.open(tableRowData[routeName], '_blank');
        }

        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );

        break;
      }
      case 'record': {
        const stateConfig = new RecordStateConfig({
          getDataMethod,
          getDataFixedParams,
        });

        for (const attr in linkStateConfig) {
          stateConfig[attr] = linkStateConfig[attr];
        }

        this.recordServices
          .getRecordConfig(stateConfig.type)
          .then(result => {
            for (const attr in result) {
              stateConfig[attr] = result[attr];
            }
            this.stateManager.dispatch({
              type: 'NEXT_ROUTE',
              routeName: tableRowData[routeName] || routeName,
              routeLink,
              routeTail: getDataFixedParams.id,
              stateConfig,
            });
            this.$state.go(routeLink, { tail: getDataFixedParams.id });
          })
          .finally(() =>
            this.$.dispatchEvent(
              new CustomEvent('toggleLoader', {
                detail: { showLoader: false },
                bubbles: true,
                composed: true,
              }),
            ),
          );
        break;
      }
      case 'streetView': {
        if (getDataFixedParams.paramsStreetView) {
          const paramsStreetView = getDataFixedParams.paramsStreetView.split('|');
          window.open(
            `https://maps.google.com/maps?layer=c&q=&cbll=${paramsStreetView[0]},${paramsStreetView[1]}&cbp=11,${paramsStreetView[2]},0,${paramsStreetView[4]},${paramsStreetView[3]}&panoid=${paramsStreetView[5]}`,
            '_blank',
          );
        } else
          window.open(
            `https://maps.google.com/maps?layer=c&q=${getDataFixedParams.latitude},${getDataFixedParams.longitude}&cbll=${getDataFixedParams.latitude},${getDataFixedParams.longitude}&cbp=11,0,0,0,0&z=17&ll=${getDataFixedParams.latitude},${getDataFixedParams.longitude}`,
            '_blank',
          );
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
        break;
      }
      default: {
        const stateConfig = new ReportStateConfig({
          viewMode: 'grid',
          getDataMethod,
          backPagination,
          getDataFilters,
          getDataFixedParams,
        });
        for (const attr in linkStateConfig) {
          stateConfig[attr] = linkStateConfig[attr];
        }
        this.stateManager.dispatch({
          type: 'NEXT_ROUTE',
          routeName: tableRowData[routeName] || routeName,
          routeLink,
          routeTail: getDataFixedParams.id,
          stateConfig,
        });
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
        this.$state.go(routeLink, { tail: getDataFixedParams.id });
      }
    }
  }

  /**
   */
  _getHeaders(bind, payload) {
    if (payload) this.headerParams = { ...payload };
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    const { screenName, gridName } = this.headerParams;

    this.commonServices
      .getGridHeaders({ screenName, gridName })
      .then(
        data => {
          Object.assign(this, {
            page: 1,
            pageSize: 10,
            gridHeaders: data.columns,
            mainHeader: data.mainDescription,
            sortHeader: data.sortable,
            sortDirection: data.sortableOrder,
            alertPeriodConfig: {
              options: data.options,
              optionSelected: data.optionSelected,
              halfhour: data.halfhour,
              default: data.default,
            },
          });
          data.columns.forEach((item, index) => {
            if (item.sortable) {
              const sortableItem = {
                id: index,
                description: item.title,
                value: item.field,
                selected: data.sortable === item.field,
                halfhour: data.halfhour,
                default: data.default,
              };

              this.sortableColumns.push(sortableItem);

              if (sortableItem.selected) this.sortableColumnSelected = sortableItem;
            }
          });

          return data;
        },
        () => Object.assign(this, { gridHeaders: [] }),
      )
      .finally(() => {
        setTimeout(() => this.$scope.$emit('UPDATE_ROUTE'), 0);
      });
  }

  /**
   * Método para recuperar os dados da tela
   *
   * @param {any} bind
   * @param {any} payload
   * @memberof SimpleCardListController
   */
  _getDataset(bind, payload) {
    if (payload) this.datasetParams = { ...payload };
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    this.selectedRows = [];
    this.$http({
      url: `${this.urlApi}/${this.datasetMethod}`,
      method: 'POST',
      data: {
        request: {
          ...this.datasetParams,
          page: this.page - 1,
          length: this.pageSize,
          sort: {
            name: this.sortHeader,
            direction: this.sortDirection,
          },
        },
      },
    })
      .then(
        success => {
          if (success.status && success.status !== 200)
            return Object.assign(this, { gridDataset: [] });

          const response = success.data.data;
          response.data.map(row => Object.assign(row, { selected: false }));
          Object.assign(this, {
            page: this.page == 0 ? 1 : this.page,
            lastPage: this._calcListMaxPage(response.total, this.pageSize),
            gridDataset: response.data,
          });
          if (response.config && this.mongoGridId != response.config._id)
            Object.assign(this, {
              mainHeader: response.config.mainDescription,
              gridConfig: response.config.columns,
              mongoGridId: response.config._id,
              sortHeader: response.config.sortable,
              sortDirection: response.config.sortableOrder,
            });

          return success;
        },
        () => Object.assign(this, { gridDataset: [] }),
      )
      .finally(() => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
        setTimeout(() => this.$scope.$emit('UPDATE_ROUTE'));
      });
  }

  /**
   */
  _changePage(bind, { page, payload }) {
    if (page >= 0) this.page = page;
    if (this.datasetParams.isPaginated) this._getDataset(this, payload);
  }

  /**
   */
  _changePageSize(bind, { pageSize, payload }) {
    Object.assign(this, {
      page: 1,
      pageSize,
    });
    if (this.datasetParams.isPaginated) this._getDataset(this, payload);
  }

  /**
   */
  _calcListMaxPage(gridTotal, gridPageSize) {
    const pagesBySize = gridTotal / gridPageSize;

    const intPagesBySize = parseInt(pagesBySize, 10);
    return pagesBySize > intPagesBySize ? intPagesBySize + 1 : intPagesBySize;
  }
}

class PowerSimpleCardList {
  /**
   * Creates an instance of SimpleCardList.
   */
  constructor() {
    this.template = template;
    this.transclude = {
      optionCard: '?optionCard',
    };
    this.bindings = {
      /* common */
      datasetMethod: '=?',
      page: '=?',
      pageSize: '=?',
      pageRows: '=?',
      lastPage: '=?',
      datasetLength: '=?',
      hasRowSelection: '=?',
      /* underscore */
      gridHeaders: '=?',
      gridDataset: '=?',
      hasSortable: '=?',
      sortHeader: '=?',
      sortDirection: '=?',
      selectedRows: '=?',
      /* double underscore */
      mainHeader: '=?',
      mongoGridId: '=?',
      headerParams: '=?',
      datasetParams: '=?',

      alertPeriodConfig: '=?',
    };
    this.controller = PowerSimpleCardListController;
  }
}

angular
  .module('power-simple-card-list', [])
  .component('powerSimpleCardList', new PowerSimpleCardList());
