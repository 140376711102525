import angular from 'angular';
import { eventOnce } from '@power/power-components/utils/event-once';

import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-popup-addon-request/power-popup-addon-request';
import '@power/power-components/components/power-toast/power-toast';

import template from './golfleet-popup-golfleet-id-app-invite.html';
import './golfleet-popup-golfleet-id-app-invite.scss';

class GolfleetPopupGolfleetIdAppInviteController {
  static get $inject() {
    return ['$element', '$scope', '$rootScope', '$ngRedux', 'addonServices', '$http', 'urlApi'];
  }

  constructor($element, $scope, $rootScope, $ngRedux, addonServices, $http, urlApi) {
    Object.assign(this, { $: $element[0], $scope, $rootScope, addonServices, $http, urlApi });

    this.__appInheritBehavior = $ngRedux.connect(behavior => {
      const { modules } = behavior.session;
      const routeListSize = behavior.state.routeList.length;
      const currentState = behavior.state.routeList[routeListSize - 1];

      return Object({
        /* Session Storage */
        modules,
        stateConfig: currentState ? currentState.stateConfig : {},
      });
    })(this);

    this.powerAddonRequestController = {
      fields: [
        {
          field: 'addon-request-email',
          label: 'Endereço de email',
          type: 'input',
          value: null,
          size: 100,
          error: null,
          inputMaskFormat: null,
        },
        {
          field: 'addon-request-phone',
          label: 'Telefone',
          type: 'input',
          value: null,
          size: 100,
          error: null,
          inputMaskFormat: '(99)09999-9999',
        },
      ],
    };

    this.dayOfWeekOptions = [
      { value: 1, description: 'DOM', selected: false },
      { value: 2, description: 'SEG', selected: false },
      { value: 3, description: 'TER', selected: false },
      { value: 4, description: 'QUA', selected: false },
      { value: 5, description: 'QUI', selected: false },
      { value: 6, description: 'SEX', selected: false },
      { value: 7, description: 'SAB', selected: false },
    ];

    this.periodsOptions = [
      { value: 1, description: 'Período da manhã', selected: false },
      { value: 2, description: 'Período da tarde', selected: false },
      { value: 3, description: 'Período da noite', selected: false },
    ];

    this.__appInheritBehavior = $ngRedux.connect(behavior =>
      Object({ session: behavior.session, state: behavior.state }),
    )(this);

    this.tabMenuVisible = true;
    this.driverEmail = '';
    this.fetchingData = false;

    this.bluetoothBuzzerDetail = {
      identifier: 'BUZZER_BT_IDENTIFICATION',
      compatibleList: null,
      compatibleQuantity: 0,
      incompatibleQuantity: 0,
      incompatibleList: null,
      notHiredQuantity: 0,
      notHiredList: null,
      typeDescription: {
        compatible: {
          singular: 'veículo contratado',
          plural: 'veículos contratados',
        },
        incompatible: {
          singular: 'veículo incompatível',
          plural: 'veículos incompatíveis',
        },
        notHired: {
          singular: 'veículo compatível com o serviço não contratado',
          plural: 'veículos compatíveis com o serviço não contratado',
        },
      },
    };

    this.bluetoothUnblockDetail = {
      identifier: 'UNBLOCK_BT_IDENTIFICATION',
      compatibleList: null,
      compatibleQuantity: 0,
      incompatibleQuantity: 0,
      incompatibleList: null,
      notHiredQuantity: 0,
      notHiredList: null,
      typeDescription: {
        compatible: {
          singular: 'veículo contratado',
          plural: 'veículos contratados',
        },
        incompatible: {
          singular: 'veículo incompatível',
          plural: 'veículos incompatíveis',
        },
        notHired: {
          singular: 'veículo compatível com o serviço não contratado',
          plural: 'veículos compatíveis com o serviço não contratado',
        },
      },
    };

    this.iaIdentificationDetail = {
      identifier: 'AI_IDENTIFICATION',
      compatibleList: null,
      compatibleQuantity: 0,
      incompatibleQuantity: 0,
      incompatibleList: null,
      notHiredQuantity: 0,
      notHiredList: null,
      activeDriverList: [],
      typeDescription: {
        compatible: {
          singular: 'veículo contratado',
          plural: 'veículos contratados',
        },
        incompatible: {
          singular: 'veículo incompatível',
          plural: 'veículos incompatíveis',
        },
        notHired: {
          singular: 'veículo compatível com o serviço não contratado',
          plural: 'veículos compatíveis com o serviço não contratado',
        },
        activeDrivers: {
          singular: 'condutor ativo',
          plural: 'condutores ativos',
        },
      },
    };

    this.toastPopup = {
      text: '',
    };

    this.requestAddonServiceList = [];
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      setup: this.setup.bind(this),
      toggle: this.toggle.bind(this),
      showToast: this.showToast.bind(this),
    });
  }

  $onDestroy() {}

  /**/

  fillUserData() {
    this.powerAddonRequestController.fields[0].value = this.driverEmail;
  }

  async setup(driverEmail) {
    this.driverEmail = driverEmail;

    const identifierList = [];

    if (this.modules.includes('IDENT_BLUETOOTH_BUZZER')) {
      identifierList.push('BUZZER_BT_IDENTIFICATION');
    }

    if (this.modules.includes('IDENT_BLUETOOTH_DESBLOQUEIO')) {
      identifierList.push('UNBLOCK_BT_IDENTIFICATION');
    }

    if (this.modules.includes('IDENTIFICACAO_IA')) {
      identifierList.push('AI_IDENTIFICATION');
    }

    this.fetchingData = true;

    await this.$http({
      url: `${this.urlApi}/Driver/GetGolfleetIdIdentificationVehicles`,
      method: 'POST',
      data: {
        identifierList,
        showActiveDriversIa: true,
      },
    }).then(async success => {
      if (success.status && success.status !== 200) {
        await success.status;
        this.fetchingData = false;
        return;
      }
      if (success.data.data) {
        const response = await success.data.data;

        const [bluetoothBuzzerResponse] = response.data.filter(
          item => item.identifier == this.bluetoothBuzzerDetail.identifier,
        );
        const [bluetoothUnblockResponse] = response.data.filter(
          item => item.identifier == this.bluetoothUnblockDetail.identifier,
        );
        const [iaIdentificationResponse] = response.data.filter(
          item => item.identifier == this.iaIdentificationDetail.identifier,
        );

        this.bluetoothBuzzerDetail = {
          ...this.bluetoothBuzzerDetail,
          ...bluetoothBuzzerResponse,
        };

        this.bluetoothUnblockDetail = {
          ...this.bluetoothUnblockDetail,
          ...bluetoothUnblockResponse,
        };

        this.iaIdentificationDetail = {
          ...this.iaIdentificationDetail,
          ...iaIdentificationResponse,
        };

        this.fetchingData = false;
        this._requestUpdate();
      }
    });
  }

  async toggle(tab) {
    eventOnce({
      element: this.$,
      event: 'transitionend',
      trigger: () => this.$.querySelector('#golfleet-popup-golfleet-id-app-invite').toggle(),
      // action: () => !this.$.hasAttribute('open') && this._evtClickPopupTab(tab),
    });

    if (tab) {
      this._evtClickPopupTab(tab);
    }

    this.tabMenuVisible = true;
    this.fillUserData();
  }

  _evtClickPopupTab(tabNumber) {
    const tabs = [...this.$.querySelectorAll('.header-tabs > div')];
    tabs.forEach((tabElement, index) => {
      if (index + 1 === tabNumber) {
        tabElement.classList.add('selected');
      } else {
        tabElement.classList.remove('selected');
      }
    });

    this.$.querySelector('.tabclass').setAttribute('tab', tabNumber);

    setTimeout(() => {
      this._requestUpdate();
    }, 50);
  }

  _getInTouch(identificationType) {
    this.tabMenuVisible = false;
    switch (identificationType) {
      case 'bluetooth':
        this.requestAddonServiceList = [
          {
            serId: 28,
            service: 'Identificação via Bluetooth (Buzzer)',
            selected: true,
          },
          {
            serId: 29,
            service: 'Identificação via Bluetooth (Desbloqueio)',
            selected: true,
          },
        ];
        break;
      case 'ia':
        this.requestAddonServiceList = [
          {
            serId: 27,
            service: 'Identificação por IA',
          },
        ];
        break;
      default:
        break;
    }
  }

  _toggleDayOfWeekOption(option) {
    option.selected = !option.selected;
  }

  _togglePeriodsOption(option) {
    option.selected = !option.selected;
  }

  _backButton() {
    this.tabMenuVisible = true;
  }

  _closeVehicleListPopup() {
    this.$.querySelector('#popup-vehicle-list').toggle();
  }

  _openVehicleListPopup(popuplist, type) {
    if (popuplist?.length === 0) return;
    this.searchVehicleList = '';
    let title = '';

    switch (type) {
      case 'compatible':
        title = `Você tem ${popuplist.length} ${
          popuplist.length == 1 ? 'veículo contratado' : 'veículos contratados'
        }`;
        break;
      case 'incompatible':
        title = `Você tem ${popuplist.length} ${
          popuplist.length == 1 ? 'veículo incompatível' : 'veículos incompatíveis'
        }`;
        break;
      case 'notHired':
        title = `Você tem ${popuplist.length} ${
          popuplist.length == 1
            ? 'veículo compatível com o serviço não contratado'
            : 'veículos compatíveis com o serviço não contratado'
        }`;
        break;
      case 'driver':
        title = `Você tem ${popuplist.length} ${
          popuplist.length == 1 ? 'condutor ativo' : 'condutores ativos'
        }`;
        break;
      default:
        break;
    }

    this.vehicleListPopup = {
      open: true,
      title,
      icon: type == 'driver' ? 'gs_driver' : 'gavel',
      list: popuplist,
    };
    this.$.querySelector('#popup-vehicle-list').toggle();
  }

  _removeErrorAttr(item) {
    item.error = false;
  }

  _requestUpdate() {
    if (!this.$scope.$$phase && !this.$rootScope.$$phase) {
      this.$scope.$apply();
    }
  }

  showToast(event) {
    this.toastPopup.text = event.detail.message;
    this.$.querySelector('#golfleet-popup-golfleet-id-app-toast').toggle(true);
  }

  _getActionTippyTitle(type, count) {
    if (count == 0) {
      return `Nenhum ${type.singular}`;
    }

    return `${count} ${count == 1 ? type.singular : type.plural}`;
  }

  _hasModule(moduleList) {
    const result = moduleList.filter(item => this.modules.some(module => module === item));

    return result.length > 0;

    // switch (module) {
    //   case 'bluetooth':
    //     return (
    //       this.modules.includes('IDENT_BLUETOOTH_BUZZER') ||
    //       this.modules.includes('IDENT_BLUETOOTH_DESBLOQUEIO')
    //     );
    //   case 'ia':
    //     return this.modules.includes('IDENTIFICACAO_IA');
    //   default:
    //     return true;
    // }
  }

  _toggleFetchingData(status) {
    this.fetchingData = status;
  }

  /**/

  /**/
}

class GolfleetPopupGolfleetIdAppInvite {
  constructor() {
    this.template = template;
    this.bindings = {
      parameters: '=?',
    };
    this.controller = GolfleetPopupGolfleetIdAppInviteController;
  }
}
angular
  .module('golfleet-popup-golfleet-id-app-invite', [
    'power-popup',
    'power-toast',
    'power-popup-addon-request',
  ])
  .component('golfleetPopupGolfleetIdAppInvite', new GolfleetPopupGolfleetIdAppInvite());
