import angular from 'angular';

import '../power-dropdown/power-dropdown';

import template from './power-crud-combobox.html';
import './power-crud-combobox.scss';

class ComboBoxDropdownItem {
  constructor(id, description, selected, value) {
    this.id = id;
    this.description = description;
    this.selected = selected;
    this.value = value;
  }
}

class PowerCrudComboboxController {
  static get $inject() {
    return ['$element', '$scope', '$attrs', 'crudServices'];
  }

  constructor($element, $scope, $attrs, crudServices) {
    Object.assign(this, { $: $element[0], $scope, $attrs, crudServices });

    this.description = '';
    this.hasSearch = false;
    this.searchItem = '';
  }

  /* Lifecycle */
  $onInit() {
    this.disabled = this.triggerBlock ? true : !!this.disabled;

    this.options = this.options || [];

    this.hasSearch = this.hasSearch || false;

    if (this.value !== null && this.options)
      this.options = this.options.map(option => ({
        ...option,
        selected: option.value == this.value,
      }));

    if (this.getDataMethod && !this.triggerBlock) this._getComboBoxOptions();

    if (this.triggerOf) this.$scope.$watch(() => this.value, this._valueChanged.bind(this));

    this.$scope.$watch(() => this.ngValue, this._ngValueChanged.bind(this));

    this.$scope.$watch(() => this.options, this._optionsChanged.bind(this));

    this.$scope.$watch(() => this.$attrs.disabled, this._disabledChanged.bind(this));

    Object.assign(this.$, {
      setWarning: this.setWarning.bind(this),
    });
  }
  /* */

  /* Private */
  _getComboBoxOptions() {
    const payload = { ...this.getDataParams };
    if (this.dataset)
      // eslint-disable-next-line guard-for-in, no-restricted-syntax
      for (const attr in payload) {
        if (typeof payload[attr] != 'number') {
          payload[attr] = this.dataset[payload[attr]];
        }
      }
    this.crudServices.getData(this.getDataMethod, payload).then(result => {
      let data = result;

      if (this.filterOptions) {
        data = result.filter(item => {
          if (this.filterOptions.value) {
            return item[this.filterOptions.name] == this.filterOptions.value;
          }
          return !!item[this.filterOptions.name] == this.filterOptions.hasValue;
        });
      }

      this.options = data.map(
        item =>
          new ComboBoxDropdownItem(item.id, item.description, item.id == this.value, item.value),
      );

      if (!this.options.filter(option => option.selected)[0]) this.value = null;
    });
  }

  _evtClickOptionSelected(selectedOption) {
    selectedOption.selected = true;
    this.value = selectedOption.value;
    this.options = this.options.map(option => ({
      ...option,
      selected: option.value == this.value,
    }));
  }

  _getDescription() {
    const selectedOption = this.options.filter(option => option.selected)[0];
    return selectedOption ? selectedOption.description : '';
  }
  /* */

  /* Observers */
  _valueChanged() {
    const selectedOption = this.options.filter(option => option.selected)[0];
    if (selectedOption && (selectedOption.id || selectedOption.id === 0)) {
      this.dataset[this.key] = selectedOption.id;
      this.$scope.$emit('triggerRequest', {
        triggerKey: this.key,
        triggerOf: this.triggerOf,
        triggerValue: selectedOption.id,
        triggerCondition: this.triggerCondition,
      });
    }
  }

  _ngValueChanged(newValue) {
    const [selectedOption] = this.options.filter(option => option.value === newValue);

    if (selectedOption) {
      this.value = newValue;
      this.options = this.options.map(option => ({
        ...option,
        selected: option.value == newValue,
      }));
    }
  }

  _optionsChanged(newValue) {
    if (!this.triggerBlock) {
      if (newValue && newValue.length > 0) this.disabled = false;
      else this.disabled = true;
    }
  }

  _disabledChanged(newValue) {
    if (typeof newValue == 'boolean') {
      this.disabled = newValue;
    }
  }
  /* */

  // #region Public
  setWarning(text) {
    this.warning = text;
  }
  // #endregion Public
}

class PowerCrudCombobox {
  constructor() {
    this.template = template;
    this.bindings = {
      key: '=',
      value: '=?',
      name: '=',
      hint: '=?',
      warning: '=?',
      options: '=?',
      required: '=?',
      disabled: '=?',
      forceDisabled: '=?',
      getDataMethod: '=?',
      getDataParams: '=?',
      triggerOf: '=?',
      triggerBlock: '=?',
      triggerCondition: '=?',
      dataset: '=?',
      hasSearch: '=?',
      filterOptions: '=?',
      ngValue: '=?',
    };
    this.controller = PowerCrudComboboxController;
  }
}

angular
  .module('power-crud-combobox', ['power-dropdown'])
  .component('powerCrudCombobox', new PowerCrudCombobox());
