import angular from 'angular';
import moment from 'moment/src/moment';
import * as Comlink from 'comlink';
import 'angular-sanitize';

import '../../directives/ng-resize/ng-resize';
import { PowerGridController } from '../power-grid/power-grid';

import template from './power-grid-utilization.html';
import './power-grid-utilization.scss';

export const IdentificationTypeEnum = {
  FACEID_IDENTIFICATION: 3,
  IDENTIFICATION_IMAGE_PENDING: 4,
  IA_IDENTIFICATION: 6,
  IA_IDENTIFICATION_PENDING: 7,
};

export const IdentificationTypeIconEnum = {
  1: 'insert_link',
  2: 'gs_rfid',
  5: 'bluetooth',
  6: 'gs_ia_rec',
  7: 'gs_ia_rec',
};

class PowerGridUtilizationController extends PowerGridController {
  static get $inject() {
    return [
      '$element',
      '$rootScope',
      '$scope',
      '$state',
      '$http',
      '$timeout',
      '$filter',
      'commonServices',
      'urlApi',
      '$ngRedux',
    ];
  }

  constructor(
    $element,
    $rootScope,
    $scope,
    $state,
    $http,
    $timeout,
    $filter,
    commonServices,
    urlApi,
    $ngRedux,
  ) {
    super($element, $scope, $state, $http, $timeout, $filter, commonServices, urlApi, $ngRedux);

    Object.assign(this, {
      $: $element[0],
      $rootScope,
      $scope,
      $state,
      $http,
      $timeout,
      $filter,
      commonServices,
      urlApi,
      $ngRedux,
    });

    this.__appBehavior = $ngRedux.connect(behavior => {
      const { userId, userEmail, userName, isTrip, isAdm } = behavior.session;
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];

      return Object({
        sessionState: { userId, userEmail, userName, isTrip, isAdm },
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
      });
    })(this);

    if (this.worker) this.worker.terminate();

    this.worker = new Worker('./power-grid-utilization.worker.js');
    this.workerService = Comlink.wrap(this.worker);

    this.activeRow = {};

    this.customActionsController = {
      showPositions: async ({ row, splitPage }) => {
        this.$.dispatchEvent(new CustomEvent('getPositionsStart', { detail: { splitPage } }));

        if (!row || row._active) {
          if (!row) {
            this.$.dispatchEvent(new CustomEvent('getMapDataset', { detail: { dataset: [] } }));
          }

          this.$.dispatchEvent(new CustomEvent('getPositionsEnd'));
          return;
        }

        await this.workerService.setActiveRow({ index: row._index });

        this.pageRows = await this.workerService.pageRows;
        this.activeRow = await this.workerService.activeRow;

        let dataset = [];

        const dataInitialDate = new Date(row.dataHoraIgnicaoLigada);
        const dataEndDate = new Date(row.dataHoraIgnicaoDesligada || row.dataHoraUltimaPosicao);

        if (row.veiculoId && dataInitialDate && dataEndDate) {
          this.$http({
            url: `${this.urlApi}/Utilizations/GetPositionsByUtilization`,
            method: 'POST',
            data: {
              request: {
                veiculoId: row.veiculoId,
                dataHoraInicial: dataInitialDate,
                dataHoraFinal: dataEndDate,
              },
            },
          })
            .then(success => {
              if (success.status && success.status === 200) {
                const response = success.data.data;
                dataset = response.data;
              }
            })
            .finally(() => {
              this.$.dispatchEvent(new CustomEvent('getMapDataset', { detail: { dataset } }));
              this.$.dispatchEvent(new CustomEvent('getPositionsEnd'));

              if (this.queryMobile.matches) {
                requestAnimationFrame(() => this._virtualScrollRender());
              }
            });
        }

        this.$.dispatchEvent(
          new CustomEvent('requestSyncVisualization', { detail: { index: row._index } }),
        );
      },
      showPopupDriver: async ({ row }) => {
        let _row = row;

        if (!row._driverFaces?.ready) {
          _row = await this._requestRowDriverFace(row);
          await this.updateDriverFaces([_row]);
        }

        this.$.dispatchEvent(
          new CustomEvent('gridIdentificationType', {
            detail: { data: _row },
            bubbles: true,
            composed: true,
          }),
        );
      },
      showEventMediasPopup: ({ row, mediaType, mediaByEvent, mediaByRequest }) => {
        this.$.dispatchEvent(
          new CustomEvent('gridEventMedias', {
            detail: { data: row, mediaType, mediaByEvent, mediaByRequest },
            bubbles: true,
            composed: true,
          }),
        );
      },
    };

    /* Virtual Pagination */
    this.firstVirtualPagination = true;
    this.virtualPaginationRequests = [];
    /* */

    moment.locale('pt-BR');
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      getRows: this.getRows.bind(this),
      getHeaders: this.getHeaders.bind(this),
      getDataset: this.getDataset.bind(this),
      changePage: this.changePage.bind(this),
      scrollToIndex: this.scrollToIndex.bind(this),
      changePageSize: this.changePageSize.bind(this),
      getActiveRow: this.getActiveRow.bind(this),
      setActiveRow: this.setActiveRow.bind(this),
      getGridState: this.getGridState.bind(this),
      virtualPagination: this.virtualPagination.bind(this),
      toggleRowDriverFace: this.toggleRowDriverFace.bind(this),
      getSelectedRowsDriverFaces: this.getSelectedRowsDriverFaces.bind(this),
      toggleSelectedRowsDriverFaces: this.toggleSelectedRowsDriverFaces.bind(this),
      selectAllRowsDriverFacesOrNone: this.selectAllRowsDriverFacesOrNone.bind(this),
      updateDriverFaces: this.updateDriverFaces.bind(this),
      updateRequestedMidiaCount: this.updateRequestedMidiaCount.bind(this),
    });

    this.$scope.$on('getHeaders', this.getHeaders.bind(this));
    this.$scope.$on('getDataset', async (_, payload) => {
      const { page, pageSize } = payload;

      await this.workerService.setPageSize({ pageSize: pageSize ?? this.pageSize ?? 10 });
      await this.workerService.setPage({ page: page ?? this.page ?? 1 });

      this.page = await this.workerService.page;
      this.pageSize = await this.workerService.pageSize;
      this.virtualPaginationRequests = [];

      this.getDataset(this, { ...payload, keepDataset: false });
    });
    this.$scope.$on('changePage', this.changePage.bind(this));
    this.$scope.$on('changePageSize', this.changePageSize.bind(this));

    this.$scope.$watch(() => this.gridDataset, this.__onGridDatasetChanged.bind(this));
    this.$scope.$watch(() => this.dateGranularity, this.__onDateGranularityChanged.bind(this));
    this.$scope.$watch(
      () => JSON.stringify(this.gridHeaders),
      this.__onGridHeadersChanged.bind(this),
    );

    this.$scope.$emit('getDatasetReady');

    this.$.querySelector('.table-container').addEventListener(
      'scroll',
      this.__onVirtualScroll.bind(this),
      { passive: true },
    );

    this._lastPageSize = this.pageSize;

    this._performMobileModeAdjustments();

    this.queryMobile.addListener(this.__onMobileMediaQuery.bind(this));

    // this._debouncedRequestDriverFaces = this._debounce(() => this._requestVirtualRowDriverFaces(), 500);
  }

  $onDestroy() {
    super.$onDestroy();

    this.__appBehavior();
  }
  /**/

  /* Public */
  async getRows(params = {}) {
    const { to = 0, from = 0 } = params;

    const rows = await this.workerService.getRows({ to, from });

    return rows;
  }

  async changePage(_, { page, payload }) {
    await this.workerService.setPage({ page });

    this.page = await this.workerService.page;
    this.pageSize = await this.workerService.pageSize;

    if (payload.isPaginated) {
      await this.getDataset(this, { ...payload, keepDataset: true });
    } else {
      this.pageRows = await this.workerService.pageRows;
    }

    this._requestDriverFaces();
  }

  async getGridState() {
    const page = await this.workerService.page;
    const pageSize = await this.workerService.pageSize;
    const datasetLength = await this.workerService.rowsLength;

    return { page, pageSize, datasetLength };
  }

  async toggleAllRows() {
    const gridElement = this.$.querySelector('table');

    if (gridElement) {
      gridElement.setAttribute('pending', '');
    }

    await this.workerService.toggleAllRowsSelection();

    this.pageRows = await this.workerService.pageRows;
    this.selectedRows = await this.workerService.selectedRows;
    this.isAllRowsSelected = await this.workerService.isAllRowsSelected;

    this._requestUpdate();

    if (gridElement) {
      gridElement.removeAttribute('pending');
    }
  }

  async changePageSize(_, { pageSize, payload }) {
    await this.workerService.setPageSize({ pageSize });

    this.page = await this.workerService.page;
    this.pageSize = await this.workerService.pageSize;
    this.lastPage = await this.workerService.lastPage;
    this.virtualPaginationRequests = [];

    if (payload.isPaginated) {
      await this.getDataset(this, { ...payload, keepDataset: true });
    } else {
      this.pageRows = await this.workerService.pageRows;
    }

    this._requestDriverFaces();
  }

  async virtualPagination(endNode, hideLoader) {
    const page = parseInt(endNode / this.pageSize);
    const requestList = [];

    if (this.isPaginated && this.page < this.lastPage && page < this.lastPage) {
      // Request Dataset Backward
      for (let backwardPage = page - 1; backwardPage > page - 6; backwardPage -= 1) {
        if (backwardPage > 0 && this.virtualPaginationRequests.includes(backwardPage) === false) {
          requestList.push(
            this.workerService.shouldRequest({ page: backwardPage }).then(shouldPaginate => {
              if (!shouldPaginate) return false;

              this.virtualPaginationRequests.push(backwardPage);

              return this.getDataset(this, {
                page: backwardPage,
                hideLoader: hideLoader ?? false,
                isPaginated: this.isPaginated,
                keepDataset: true,
              });
            }),
          );
        }
      }

      // Request Dataset Forward
      for (let forwardPage = page; forwardPage < page + 5; forwardPage += 1) {
        if (
          forwardPage <= this.lastPage &&
          this.virtualPaginationRequests.includes(forwardPage) === false
        ) {
          requestList.push(
            this.workerService.shouldRequest({ page: forwardPage }).then(shouldPaginate => {
              if (!shouldPaginate) return false;

              this.virtualPaginationRequests.push(forwardPage);

              return this.getDataset(this, {
                page: forwardPage,
                hideLoader: hideLoader ?? false,
                isPaginated: this.isPaginated,
                keepDataset: true,
              });
            }),
          );
        }
      }
    }

    return Promise.all(requestList);
  }

  async toggleRowDriverFace(row) {
    await this.workerService.toggleRowDriverFace({ index: row._index });
    this.pageRows = await this.workerService.pageRows;
  }

  async getSelectedRowsDriverFaces() {
    return this.workerService.getSelectedRowsDriverFaces();
  }

  async toggleSelectedRowsDriverFaces() {
    this.workerService.toggleSelectedRowsDriverFaces();
    this.pageRows = await this.workerService.pageRows;
  }

  async selectAllRowsDriverFacesOrNone() {
    this.workerService.selectAllRowsDriverFacesOrNone();
    this.pageRows = await this.workerService.pageRows;
  }

  getDataset(_, payload) {
    let resetDataset = false;

    if (!payload || !payload?.page || this.page >= payload.page) {
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );
    }

    this.$scope.$emit('finishFilterConditions', {});

    if (payload) {
      if (!payload.keepDataset) {
        resetDataset = true;
        this.$.querySelector('.table-container').scrollTo(0, 0);
      }

      if (payload?.async) {
        Object.assign(this, {
          isPaginated: payload.isPaginated,
          datasetParams: { ...this.datasetParams, ...payload },
        });
      } else {
        Object.assign(this, {
          page: payload.page >= 0 ? payload.page : this.page,
          isPaginated: payload.isPaginated,
          datasetParams: { ...this.datasetParams, ...payload },
        });
      }

      if (payload.sort) {
        const { name, direction } = payload.sort;

        this.sortHeader = name;
        this.sortDirection = direction;

        this.gridHeaders = this.gridHeaders.map(gridHeader => {
          if (
            (gridHeader.columns &&
              gridHeader.columns.some(
                column => column.sortable === name || column.field === name,
              )) ||
            gridHeader.sortable === name ||
            gridHeader.field === name
          ) {
            gridHeader.show = true;

            if (gridHeader.columns && gridHeader.selectors) {
              const column = gridHeader.columns.find(
                item => item.sortable === name || item.field === name,
              );
              gridHeader.selectors.forEach(item => {
                item.selected = item.selector === column.selector;
              });
            }
          }

          return gridHeader;
        });
      }

      if (payload.visibleColumns) {
        const { visibleColumns } = payload;

        this.gridHeaders = this.gridHeaders.map(gridHeader => {
          if (
            (gridHeader.columns &&
              gridHeader.columns.some(column => visibleColumns.includes(column.field))) ||
            visibleColumns.includes(gridHeader.field)
          ) {
            gridHeader.show = true;

            if (gridHeader.columns && gridHeader.selectors) {
              const column = gridHeader.columns.find(item => visibleColumns.includes(item.field));
              if (gridHeader.selectors) {
                gridHeader.selectors.forEach(item => {
                  item.selected = item.selector === column.selector;
                });
              }
            }
          }

          return gridHeader;
        });
      }
    }

    return this.$http({
      url: `${this.urlApi}/${this.datasetMethod}`,
      method: 'POST',
      data: {
        request: {
          ...this.datasetParams,
          page: payload?.page - 1 || this.page - 1,
          length: this.pageSize,
          sort: {
            name: this.sortHeader,
            direction: this.sortDirection,
          },
        },
      },
    })
      .then(
        async success => {
          if (success.status && success.status === 200) {
            const columns = await this.workerService.columns;
            const response = success.data.data;

            if (columns.length === 0) {
              await this.workerService.setColumns({ columns: this.gridHeaders });
            }

            this.datasetLength = response.total;
            this.lastPage = this._calcGridMaxPage(response.total, this.pageSize);
            this.page = (this.page > this.lastPage ? 1 : this.page) || 1;

            await this.workerService.setPageSize({ pageSize: this.pageSize });
            await this.workerService.setPage({ page: this.page });

            if (this.datasetLength === 0) {
              this.virtualRows = [];
            }

            if (this.isPaginated) {
              await this.workerService.addToDataset({
                page: payload?.page - 1 || this.page - 1,
                rows: response.data,
                rowsLength: response.total,
                isPaginated: this.isPaginated,
                resetDataset,
                useVirtualScroll: true,
              });
            } else {
              await this.workerService.setDataset({
                rows: response.data,
                rowsLength: response.total,
                isPaginated: this.isPaginated,
              });
            }

            this.page = await this.workerService.page;
            this.pageSize = await this.workerService.pageSize;
            this.lastPage = await this.workerService.lastPage;
            this.pageRows = await this.workerService.pageRows;
            this.activeRow = await this.workerService.activeRow;
            this.gridDataset = await this.workerService.rows;
            this.selectedRows = await this.workerService.selectedRows;
            this.toggleAllIcon = await this.workerService.toggleAllIcon;
            this.isAllSelected = await this.workerService.isAllSelected;

            if (!this.datasetParams.isPaginated && response.resultLimit === response.total) {
              this.$.dispatchEvent(
                new CustomEvent('dataLimitReached', {
                  detail: {
                    total: response.total,
                    resultLimit: response.resultLimit,
                  },
                }),
              );
            }

            this._requestUpdate();

            this.$scope.$emit('loadDatasetComplete');
          }
        },
        async () => {
          await this.workerService.setPageSize({ pageSize: this.pageSize });
          await this.workerService.setPage({ page: 1 });
          await this.workerService.setDataset({ rows: [], isPaginated: this.isPaginated });

          this.page = await this.workerService.page;
          this.pageSize = await this.workerService.pageSize;
          this.lastPage = await this.workerService.lastPage;
          this.pageRows = await this.workerService.pageRows;
          this.gridDataset = await this.workerService.rows;
          this.selectedRows = await this.workerService.selectedRows;
          this.toggleAllIcon = await this.workerService.toggleAllIcon;
          this.isAllSelected = await this.workerService.isAllSelected;

          this._requestUpdate();
        },
      )
      .finally(() => {
        this.$timeout(() => this.$scope.$emit('UPDATE_ROUTE'));

        this.$.dispatchEvent(
          new CustomEvent('getDataset', {
            detail: {
              page: payload?.page || this.page,
              resetDataset: resetDataset || this.firstVirtualPagination,
              datasetLength: this.datasetLength,
            },
          }),
        );

        this.firstVirtualPagination = false;

        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );

        this.customActionsController.showPositions({
          row: this.pageRows[0],
          splitPage: false,
        });

        this._requestUpdate();
      });
  }

  getActiveRow() {
    return this.activeRow;
  }

  async setActiveRow({ row, index }) {
    if (row) {
      this.customActionsController.showPositions({ row, splitPage: false });
    } else if (index >= 0) {
      const [_row] = await this.workerService.getRows({
        to: parseInt(index) + 1,
        from: parseInt(index),
      });

      this.customActionsController.showPositions({ row: _row, splitPage: false });
    }
  }

  async scrollToIndex(index) {
    this.$.querySelector('.table-container').scrollTo({
      top: index * 56,
      behavior: 'smooth',
    });

    const [row] = await this.workerService.getRows({
      to: parseInt(index) + 1,
      from: parseInt(index),
    });

    this.customActionsController.showPositions({ row, splitPage: false });
  }

  async updateRequestedMidiaCount(data, value, midiaType) {
    await this.workerService.updatePageRowsRequestedMidiaCount({ data: [data], value, midiaType });
    this.pageRows = await this.workerService.pageRows;
  }
  /* */

  /* Private */
  async updateDriverFaces(dataset) {
    await this.workerService.updatePageRowsDriverFaces({ dataset });
    this.pageRows = await this.workerService.pageRows;
  }

  async _requestDriverFaces() {
    const driverFacesPromises = [];

    for (const data of this.pageRows) {
      driverFacesPromises.push(
        new Promise(resolve => {
          if (!data.tipoIdentificacaoCondutor) {
            data._driverFaces.error = true;
            data._driverFaces.ready = true;
            resolve(data);
            return;
          }

          if (
            data.tipoIdentificacaoCondutor &&
            data.condutorNome &&
            ![
              IdentificationTypeEnum.FACEID_IDENTIFICATION,
              IdentificationTypeEnum.IDENTIFICATION_IMAGE_PENDING,
            ].includes(data.tipoIdentificacaoCondutor)
          ) {
            data._driverFaces.error = true;
            data._driverFaces.ready = true;
            data._driverFaces.showName = true;
            data._driverFaces.defaultIcon =
              IdentificationTypeIconEnum[data.tipoIdentificacaoCondutor];

            resolve(data);
          }

          // if (data.tipoIdentificacaoCondutor === 1 && data.condutorNome) {
          //   data._driverFaces.error = false;
          //   data._driverFaces.ready = true;
          //   resolve(data);
          //   return;
          // }

          if (data._driverFaces) {
            if (data._driverFaces.ready) {
              resolve(data);
              return;
            }
          }

          this.$http({
            url: `${this.urlApi}/FleetCam/GetDriversFace`,
            method: 'POST',
            data: {
              request: {
                vehicleId: data.veiculoId,
                identificationType: data.tipoIdentificacaoCondutor,
                beginDate: this._parseDate(new Date(data.dataHoraIgnicaoLigada)),
                endDate: this._parseDate(
                  new Date(
                    data.dataHoraFimViagem ||
                      data.dataHoraIgnicaoDesligada ||
                      data.dataHoraUltimaPosicao,
                  ),
                ),
              },
            },
          })
            .then(result => {
              if (result.data.hasError) {
                data._driverFaces.error = true;
              } else {
                data._driverFaces.data = [...result.data.data];
              }
              data._driverFaces.ready = true;
            })
            .catch(() => {
              data._driverFaces.error = true;
              data._driverFaces.ready = true;
            })
            .finally(() => {
              resolve(data);
            });
        }),
      );
    }

    const dataset = await Promise.all(driverFacesPromises);
    await this.updateDriverFaces(dataset);
    this._requestUpdate();
  }

  async _requestRowDriverFace(data) {
    await this.$http({
      url: `${this.urlApi}/FleetCam/GetDriversFace`,
      method: 'POST',
      data: {
        request: {
          vehicleId: data.veiculoId,
          identificationType: data.tipoIdentificacaoCondutor,
          beginDate: this._parseDate(new Date(data.dataHoraIgnicaoLigada)),
          endDate: this._parseDate(
            new Date(
              data.dataHoraFimViagem || data.dataHoraIgnicaoDesligada || data.dataHoraUltimaPosicao,
            ),
          ),
        },
      },
    })
      .then(result => {
        if (result.data.hasError) {
          data._driverFaces.error = true;
        } else {
          data._driverFaces.data = [...result.data.data];
        }
        data._driverFaces.ready = true;
      })
      .catch(() => {
        data._driverFaces.error = true;
        data._driverFaces.ready = true;
      });

    return data;
  }

  _toDate(date) {
    return moment(date).utcOffset(-3 - moment(date).utcOffset() / 60)._d;
  }

  _selectRow(column, row) {
    const result =
      super._selectRow(column, row) &&
      (!Object.prototype.hasOwnProperty.call(row, column.field) || !!row[column.field]);

    if (column.type === 'DriverIcon') {
      return (
        [
          IdentificationTypeEnum.FACEID_IDENTIFICATION,
          IdentificationTypeEnum.IDENTIFICATION_IMAGE_PENDING,
          IdentificationTypeEnum.IA_IDENTIFICATION,
          IdentificationTypeEnum.IA_IDENTIFICATION_PENDING,
        ].includes(row.tipoIdentificacaoCondutor) && !!row[column.field]
      );
    }

    return result;
  }

  _parseDate(date) {
    return new Date(date.setHours(date.getHours() - 3));
  }

  _customAction(options) {
    const defaults = { action: '', params: {}, data: {} };
    const result = Object.assign(defaults, options);

    const customAction = this.customActionsController[result.action];
    if (customAction) {
      customAction({ row: result.data, ...result.params });
    }
  }

  _requestUpdate() {
    if (!this.$rootScope.$$phase && !this.$scope.$$phase) {
      this.$scope.$apply();
    }
  }
  /* */

  /* Observers */
  async __onGridDatasetChanged(gridDataset) {
    if (!gridDataset || gridDataset.length == 0) {
      this.$.setAttribute('empty', '');
      this._resetScrollPosition();
    } else {
      this.$.removeAttribute('empty');
      this._requestDriverFaces();

      if (this.queryMobile.matches) {
        requestAnimationFrame(() => this._virtualScrollRender());
      }
    }
  }
  /* */
}

class PowerGridUtilization {
  constructor() {
    this.template = template;
    this.bindings = {
      /* common */
      datasetMethod: '=?',
      isPaginated: '=?',
      page: '=?',
      pageSize: '=?',
      pageRows: '=?',
      lastPage: '=?',
      datasetLength: '=?',
      hasRowSelection: '=?',
      dateGranularity: '=?',
      /* underscore */
      gridHeaders: '=?',
      gridDataset: '=?',
      gridHeadersCategories: '=?',
      sortHeader: '=?',
      sortDirection: '=?',
      selectedRows: '=?',
      /* duble underscore */
      mainHeader: '=?',
      mongoGridId: '=?',
      headerParams: '=?',
      datasetParams: '=?',
    };
    this.controller = PowerGridUtilizationController;
  }
}

angular
  .module('power-grid-utilization', ['ngSanitize', 'ng-resize'])
  .component('powerGridUtilization', new PowerGridUtilization());
