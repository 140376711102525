import { PowerMapHereProvider } from '@power/power-components/components/power-map/providers/here/power-map.here-provider';
import '@power/power-components/helpers/is-iframe/is-iframe';
import './golfleet-map-speed-limit.here-provider.scss';

class GolfleetMapSpeedLimitHereProvider extends PowerMapHereProvider {
  // eslint-disable-next-line no-useless-constructor
  constructor(context, $element, $ngRedux, $scope, $http, urlApi) {
    super(context, $element, $ngRedux, $scope, $http, urlApi);
  }

  /* Private */
  _initializeMap() {
    super._initializeMap();
    this.ui.removeControl('distancemeasurement');
  }
  /* */
}

export { GolfleetMapSpeedLimitHereProvider };
