import angular from 'angular';
import * as Comlink from 'comlink';

import '../power-dropdown/power-dropdown';
import '../../directives/infinite-scroll/infinite-scroll';

import template from './power-filter-compound-custom.html';
import './power-filter-compound-custom.scss';

class PowerFilterCompoundCustomController {
  static get $inject() {
    return ['$element', '$rootScope', '$scope', '$http', 'urlApi'];
  }

  constructor($element, $rootScope, $scope, $http, urlApi) {
    Object.assign(this, { $: $element[0], $rootScope, $scope, $http, urlApi });

    this.icon = 'gs_fleet';
    this.isTrip = false;
    this.filterId = 0;
    this.activeView = 'entity';
    this.description = 'Carregando...';
    this.activeEntity = 'vehicle';
    this.requestMethod = '';

    this.group = {
      search: '',
      description: '',
      options: [],
      default: [],
      condition: {},
      isAllSelected: false,
    };
    this.driver = {
      search: '',
      description: '',
      options: [],
      default: [],
      condition: {},
      isAllSelected: false,
      incluedHistoricalData: false,
    };
    this.vehicle = {
      search: '',
      description: '',
      options: [],
      default: [],
      condition: {},
      isAllSelected: false,
      incluedHistoricalData: false,
    };
    this.filters = {};
    this.default = [];
    this.condition = {};
    this.requestParams = {};

    this.groupWorker = new Worker('./power-filter-compound-custom.worker.js');
    this.driverWorker = new Worker('./power-filter-compound-custom.worker.js');
    this.vehicleWorker = new Worker('./power-filter-compound-custom.worker.js');
    this.groupWorkerService = Comlink.wrap(this.groupWorker);
    this.driverWorkerService = Comlink.wrap(this.driverWorker);
    this.vehicleWorkerService = Comlink.wrap(this.vehicleWorker);
  }

  /* Lifecycle */
  $onInit() {
    this.$.loading = true;

    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
      getOptions: this.getOptions.bind(this),
      requestUpdate: this.requestUpdate.bind(this),
      setGroupFilterValue: this.setGroupFilterValue.bind(this),
      setDriverFilterValue: this.setDriverFilterValue.bind(this),
      setVehicleFilterValue: this.setVehicleFilterValue.bind(this),
    });

    this.icon = 'gs_fleet';
    this.description = 'Carregando...';

    this._initializeFilter().then(() => {
      this.$.loading = false;
    });
  }

  $onDestroy() {
    this.groupWorker.terminate();
    this.driverWorker.terminate();
    this.vehicleWorker.terminate();
  }
  /* */

  /* Public */
  toggle() {
    document.querySelector('power-filter-menu').toggleFilter(this.filterId);
  }

  requestUpdate() {
    if (!this.$rootScope.$$phase && !this.$scope.$$phase) {
      this.$scope.$apply();
    }
  }

  getOptions() {
    return {
      group: this.group.options,
      driver: this.driver.options,
      vehicle: this.vehicle.options,
    };
  }

  async setGroupFilterValue(value = []) {
    await this.groupWorkerService.setFilterValue({ value });

    await this._groupSyncState();

    await this._changeActiveView('group');

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('setGroupFilterValue'));
  }

  async setDriverFilterValue(value = []) {
    await this.driverWorkerService.setFilterValue({ value });

    await this._driverSyncState();

    await this._changeActiveView('entity');

    await this._changeActiveEntity('driver');

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('setDriverFilterValue'));
  }

  async setVehicleFilterValue(value = []) {
    await this.vehicleWorkerService.setFilterValue({ value });

    await this._vehicleSyncState();

    await this._changeActiveView('entity');

    await this._changeActiveEntity('vehicle');

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('setVehicleFilterValue'));
  }
  /* */

  /* Private */
  async _initializeFilter() {
    if (this.requestMethod !== '') {
      await this._requestOptions();
    }

    await Promise.all([this._groupSetup(), this._driverSetup(), this._vehicleSetup()]);

    if (
      (this.default && this.default[0] && this.default[0].entity === 'group') ||
      (this.condition && this.condition.field && this.condition.field === this.filters.group.field)
    ) {
      await this._changeActiveView('group');
    } else if (
      (this.default && this.default[0] && this.default[0].entity === 'driver') ||
      (this.condition &&
        this.condition.field &&
        this.filters.driver &&
        this.condition.field === this.filters.driver.field)
    ) {
      await this._changeActiveView('entity');
      await this._changeActiveEntity('driver');
    } else {
      await this._changeActiveView('entity');
      await this._changeActiveEntity('vehicle');
    }

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('setup'));
  }

  async _groupSetup() {
    await this.groupWorkerService.setup({
      id: this.filterId,
      field: this.filters.group.field,
      plural: this.filters.group.plural || 'grupos',
      singular: this.filters.group.singular || 'grupo',
      isTrip: this.isTrip,
      options: this.group.options,
      condition:
        this.condition && this.condition.field && this.condition.field === this.filters.group.field
          ? this.condition
          : {},
      defaultCondition:
        this.default && this.default[0] && this.default[0].entity === 'group'
          ? this.default[0].value
          : [],
    });

    await this.groupWorkerService.filterOptions({ search: this.group.search });

    await this._groupSyncState();

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('groupSetup'));
  }

  async _driverSetup() {
    if (!this.filters.driver) {
      return;
    }

    await this.driverWorkerService.setup({
      id: this.filterId,
      field: this.filters.driver.field,
      plural: this.filters.driver.plural || 'condutores',
      singular: this.filters.driver.singular || 'condutor',
      isTrip: this.isTrip,
      options: this.driver.options,
      condition:
        this.condition &&
        this.condition.field &&
        this.filters.driver &&
        this.condition.field === this.filters.driver.field
          ? this.condition
          : {},
      defaultCondition:
        this.default && this.default[0] && this.default[0].entity === 'driver'
          ? this.default[0].value
          : [],
      incluedHistoricalData:
        this.condition &&
        this.condition.field &&
        this.condition.field === this.filters.driver.field &&
        this.condition.incluedHistoricalData
          ? this.condition.incluedHistoricalData
          : this.driver.incluedHistoricalData,
      hasIncluedHistoricalData: this.filters.driver.hasIncluedHistoricalData,
    });

    await this.driverWorkerService.filterOptions({ search: this.driver.search });

    await this._driverSyncState();

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('driverSetup'));
  }

  async _vehicleSetup() {
    await this.vehicleWorkerService.setup({
      id: this.filterId,
      field: this.filters.vehicle.field,
      plural: this.filters.vehicle.plural || 'veículos',
      singular: this.filters.vehicle.singular || 'veículo',
      isTrip: this.isTrip,
      options: this.vehicle.options,
      condition:
        this.condition &&
        this.condition.field &&
        this.condition.field === this.filters.vehicle.field
          ? this.condition
          : {},
      defaultCondition:
        this.default && this.default[0] && this.default[0].entity === 'vehicle'
          ? this.default[0].value
          : [],
      incluedHistoricalData:
        this.condition &&
        this.condition.field &&
        this.condition.field === this.filters.vehicle.field &&
        this.condition.incluedHistoricalData
          ? this.condition.incluedHistoricalData
          : this.vehicle.incluedHistoricalData,
      hasIncluedHistoricalData: this.filters.vehicle.hasIncluedHistoricalData,
    });

    await this.vehicleWorkerService.filterOptions({ search: this.vehicle.search });

    await this._vehicleSyncState();

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('vehicleSetup'));
  }

  async _groupSyncState() {
    this.group.options = await this.groupWorkerService.filteredOptions;
    this.group.condition = await this.groupWorkerService.condition;
    this.group.description = await this.groupWorkerService.description;
    this.group.isAllSelected = await this.groupWorkerService.filteredIsAllSelected;

    this.$.dispatchEvent(new CustomEvent('syncState'));
    this.$.dispatchEvent(new CustomEvent('groupSyncState'));
    this.$.dispatchEvent(new CustomEvent('groupOptionsChanged'));
    this.$.dispatchEvent(new CustomEvent('groupConditionChanged'));
    this.$.dispatchEvent(new CustomEvent('groupDescriptionChanged'));
  }

  async _driverSyncState() {
    this.driver.options = await this.driverWorkerService.filteredOptions;
    this.driver.condition = await this.driverWorkerService.condition;
    this.driver.description = await this.driverWorkerService.description;
    this.driver.isAllSelected = await this.driverWorkerService.filteredIsAllSelected;
    this.driver.incluedHistoricalData = await this.driverWorkerService.incluedHistoricalData;

    this.$.dispatchEvent(new CustomEvent('syncState'));
    this.$.dispatchEvent(new CustomEvent('driverSyncState'));
    this.$.dispatchEvent(new CustomEvent('driverOptionsChanged'));
    this.$.dispatchEvent(new CustomEvent('driverConditionChanged'));
    this.$.dispatchEvent(new CustomEvent('driverDescriptionChanged'));
  }

  async _vehicleSyncState() {
    this.vehicle.options = await this.vehicleWorkerService.filteredOptions;
    this.vehicle.condition = await this.vehicleWorkerService.condition;
    this.vehicle.description = await this.vehicleWorkerService.description;
    this.vehicle.isAllSelected = await this.vehicleWorkerService.filteredIsAllSelected;
    this.vehicle.incluedHistoricalData = await this.vehicleWorkerService.incluedHistoricalData;

    this.$.dispatchEvent(new CustomEvent('syncState'));
    this.$.dispatchEvent(new CustomEvent('vehicleSyncState'));
    this.$.dispatchEvent(new CustomEvent('vehicleOptionsChanged'));
    this.$.dispatchEvent(new CustomEvent('vehicleConditionChanged'));
    this.$.dispatchEvent(new CustomEvent('vehicleDescriptionChanged'));
  }

  async _changeActiveView(view) {
    this.activeView = view;

    this._formatIcon();
    this._formatCondition();
    this._formatDescription();

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('activeViewChanged'));
  }

  async _changeActiveEntity(entity) {
    this.activeEntity = entity;

    this._formatIcon();
    this._formatCondition();
    this._formatDescription();

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('activeEntityChanged'));
  }

  async _toggleHistoricalData() {
    if (this.activeEntity === 'vehicle') {
      await this.vehicleWorkerService.toggleHistoricalData({
        incluedHistoricalData: !this.vehicle.incluedHistoricalData,
      });

      await this._vehicleSyncState();
    } else if (this.activeEntity === 'driver') {
      await this.driverWorkerService.toggleHistoricalData({
        incluedHistoricalData: !this.driver.incluedHistoricalData,
      });

      await this._driverSyncState();
    }

    this._formatIcon();
    this._formatCondition();
    this._formatDescription();

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('toggleHistoricalData'));
  }

  async _toggleOptionSelection(selectedOption) {
    if (this.activeView === 'group') {
      await this.groupWorkerService.toggleOptionsSelection({ options: [selectedOption] });

      await this._groupSyncState();
    } else if (this.activeEntity === 'driver') {
      await this.driverWorkerService.toggleOptionsSelection({ options: [selectedOption] });

      await this._driverSyncState();
    } else {
      await this.vehicleWorkerService.toggleOptionsSelection({ options: [selectedOption] });

      await this._vehicleSyncState();
    }

    this._formatIcon();
    this._formatCondition();
    this._formatDescription();

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('toggleOptionSelection'));
  }

  async _toggleAllOptionsSelection() {
    if (this.activeView === 'group') {
      await this.groupWorkerService.toggleAllOptionsSelection();

      await this._groupSyncState();
    } else if (this.activeEntity === 'driver') {
      await this.driverWorkerService.toggleAllOptionsSelection();

      await this._driverSyncState();
    } else {
      await this.vehicleWorkerService.toggleAllOptionsSelection();

      await this._vehicleSyncState();
    }

    this._formatIcon();
    this._formatCondition();
    this._formatDescription();

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('toggleAllOptionsSelection'));
  }

  _formatIcon() {
    if (this.activeEntity === 'driver') {
      this.icon = this.filters.driver.icon;
    } else {
      this.icon = this.filters.vehicle.icon;
    }
  }

  _requestOptions() {
    return this.$http({
      url: `${this.urlApi}/${this.requestMethod}`,
      method: 'POST',
      data: { ...this.requestParams },
    }).then(async success => {
      await Promise.all([
        this.groupWorkerService.setOptions({ options: success.data.data.groups }),
        this.driverWorkerService.setOptions({ options: success.data.data.drivers }),
        this.vehicleWorkerService.setOptions({ options: success.data.data.vehicles }),
      ]);

      await Promise.all([
        this._groupSyncState(),
        this._driverSyncState(),
        this._vehicleSyncState(),
      ]);

      this.requestUpdate();
      this.$.dispatchEvent(new CustomEvent('requestOptions'));
    });
  }

  _formatCondition() {
    if (this.activeView === 'group') {
      this.condition = this.group.condition;
    } else if (this.activeEntity === 'driver') {
      this.condition = this.driver.condition;
    } else {
      this.condition = this.vehicle.condition;
    }

    this.$.dispatchEvent(new CustomEvent('conditionChanged'));
  }

  _formatDescription() {
    if (this.activeView === 'group') {
      this.description = this.group.description;
    } else if (this.activeEntity === 'driver') {
      this.description = this.driver.description;
    } else {
      this.description = this.vehicle.description;
    }

    this.$.dispatchEvent(new CustomEvent('descriptionChanged'));
  }
  /* */

  /* Observers */
  async __onGroupSearchChanged() {
    await this.groupWorkerService.filterOptions({ search: this.group.search });

    await this._groupSyncState();

    this.requestUpdate();
  }

  async __onDriverSearchChanged() {
    await this.driverWorkerService.filterOptions({ search: this.driver.search });

    await this._driverSyncState();

    this.requestUpdate();
  }

  async __onVehicleSearchChanged() {
    await this.vehicleWorkerService.filterOptions({ search: this.vehicle.search });

    await this._vehicleSyncState();

    this.requestUpdate();
  }
  /* */
}

class PowerFilterCompoundCustom {
  constructor() {
    this.template = template;
    this.bindings = {
      icon: '=',
      isTrip: '=',
      default: '=',
      filters: '=',
      filterId: '=',
      condition: '=',
      activeView: '=',
      activeEntity: '=',
      description: '=',
      requestMethod: '=',
      requestParams: '=',
    };
    this.controller = PowerFilterCompoundCustomController;
  }
}

angular
  .module('power-filter-compound-custom', ['infinite-scroll', 'power-dropdown'])
  .component('powerFilterCompoundCustom', new PowerFilterCompoundCustom());
