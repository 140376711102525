import angular from 'angular';

import '@power/power-components/components/power-toolbar/power-toolbar';
import template from './golfleet-report-reports-tips.html';
import './golfleet-report-reports-tips.scss';

class GolfleetReportReportsTipsController {
  static get $inject() {
    return ['$element', '$ngRedux', '$scope', '$http', 'dashboardServices'];
  }

  constructor($element, $ngRedux, $scope, $http, dashboardServices) {
    Object.assign(this, { $: $element[0], $ngRedux, $scope, $http, dashboardServices });
    this.configList = [];

    this.__appBehavior = $ngRedux.connect(behavior => {
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];
      return Object({
        /* State Storage */
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
        isSingleSignon: behavior.session.isSingleSignon,
      });
    })(this);
  }

  $onInit() {}

  $onDestroy() {
    this.__appBehavior();
  }

  _getImage(name) {
    const src = '/assets/images/gs-reports-tips/';
    return src.concat(name);
  }

  _getPdfReportTips(fileName) {
    const link = document.createElement('a');
    Object.assign(link, {
      href: `/assets/downloads/${fileName}`,
    });
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

class GolfleetReportReportsTips {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetReportReportsTipsController;
  }
}

angular
  .module('golfleet-report-reports-tips', ['power-toolbar'])
  .component('golfleetReportReportsTips', new GolfleetReportReportsTips());
