import angular from 'angular';
import '@power/power-components/helpers/is-iframe/is-iframe';

import { GolfleetMapFuelSupplyManagementHereProvider } from './providers/here/golfleet-map-fuel-supply-management.here-provider';
import { GolfleetMapFuelSupplyManagementLeafletProvider } from './providers/leaflet/golfleet-map-fuel-supply-management.leaflet-provider';
import { PowerMapController } from '@power/power-components/components/power-map/power-map';

import template from './golfleet-map-fuel-supply-management.html';

class GolfleetMapFuelSupplyManagementController extends PowerMapController {
  static get $inject() {
    return ['$element', '$ngRedux', '$scope', '$http', 'urlApi'];
  }

  constructor($element, $ngRedux, $scope, $http, urlApi) {
    super($element, $ngRedux, $scope, $http, urlApi);

    if (this.providerName === 'HERE') {
      this.provider = new GolfleetMapFuelSupplyManagementHereProvider(
        this,
        $element,
        $ngRedux,
        $scope,
        $http,
        urlApi,
      );
    } else {
      // this.providerName === 'LEAFLET'
      this.provider = new GolfleetMapFuelSupplyManagementLeafletProvider(
        this,
        $element,
        $ngRedux,
        $scope,
        $http,
        urlApi,
      );
    }
  }
}

class GolfleetMapFuelSupplyManagement {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetMapFuelSupplyManagementController;
  }
}

angular
  .module('golfleet-map-fuel-supply-management', [])
  .component('golfleetMapFuelSupplyManagement', new GolfleetMapFuelSupplyManagement());
