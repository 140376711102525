import angular from 'angular';

import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-toast/power-toast';

import template from './golfleet-popup-otp.html';
import './golfleet-popup-otp.scss';

class GolfleetPopupOtpController {
  static get $inject() {
    return ['$element', '$scope', 'reportServices', '$ngRedux'];
  }

  constructor($element, $scope, reportServices, $ngRedux) {
    Object.assign(this, { $: $element[0], $scope, reportServices, $ngRedux });

    this.showView = 'otpOptions';
    this.qrCode = '';
    this.number1 = '';
    this.number2 = '';
    this.number3 = '';
    this.number4 = '';
    this.number5 = '';
    this.number6 = '';
    this.verifyError = false;
    this.copyText = 'Copiar código';
    this.showCloseButton = false;
    this.cancelOptPopup = false;
  }

  /**
   * Lifecycle
   */
  $onInit() {
    Object.assign(this.$, {
      togglePopup: this.togglePopup.bind(this),
    });
  }
  /* Lifecycle */

  /* Public */
  togglePopup(createNewOtp = true, showCloseButton = false, cancelOtp = false) {
    this.showCloseButton = showCloseButton;
    this.cancelOptPopup = cancelOtp;
    if (cancelOtp) {
      this._setView('otpCancelation');
    } else {
      this._setView('otpOptions');
    }
    const popup = this.$.querySelector('power-popup');
    popup.toggle();
    if (createNewOtp) {
      this.CreateOtp();
    }
  }

  _closePopup() {
    this.$.querySelector('power-popup').toggle();
  }

  _setView(view) {
    this.showView = view;
    if (view == 'otpValidation')
      setTimeout(() => {
        this.otpInputFocus(1);
      });
    this.clear();
  }

  _showLoader(showLoader) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader },
        bubbles: true,
        composed: true,
      }),
    );
  }

  _otpInputKeyDown(event) {
    const validKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'];
    const isCtrlV = (event.ctrlKey || event.metaKey) && (event.key === 'v' || event.key === 'V'); // Ctrl+V or Cmd+V
    if (!validKeys.includes(event.key) && !isCtrlV) {
      event.preventDefault();
      return;
    }
    if (event.key === 'Backspace') {
      if (this.number6) {
        this.otpInputFocus(6);
      } else if (this.number5) {
        this.otpInputFocus(5);
      } else if (this.number4) {
        this.otpInputFocus(4);
      } else if (this.number3) {
        this.otpInputFocus(3);
      } else if (this.number2) {
        this.otpInputFocus(2);
      } else {
        this.otpInputFocus(1);
      }
    } else if (!this.number1) {
      this.otpInputFocus(1);
    } else if (!this.number2) {
      this.otpInputFocus(2);
    } else if (!this.number3) {
      this.otpInputFocus(3);
    } else if (!this.number4) {
      this.otpInputFocus(4);
    } else if (!this.number5) {
      this.otpInputFocus(5);
    } else {
      this.otpInputFocus(6);
    }
  }

  _otpInputKeyUp(event, number) {
    const validKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'];
    if (!validKeys.includes(event.key)) {
      event.preventDefault();
    } else if (number === 6 && event.key !== 'Backspace') {
      this._checkCode();
    } else if (event.key !== 'Backspace') {
      this.otpInputFocus(number + 1);
    }
  }

  _otpPaste(event) {
    const value = event.clipboardData.getData('text');

    event.preventDefault();

    if (value.length !== 6) return;

    const [number1, number2, number3, number4, number5, number6] = value.split('');

    this.number1 = parseInt(number1);
    this.number2 = parseInt(number2);
    this.number3 = parseInt(number3);
    this.number4 = parseInt(number4);
    this.number5 = parseInt(number5);
    this.number6 = parseInt(number6);

    this._checkCode();
  }

  otpInputFocus(number) {
    this.$.querySelector(`#number-${number}`).focus();
  }
  /* */

  CreateOtp() {
    this.reportServices.genericRequest(`/User/CreateOtp`, {}).then(
      success => {
        this.qrCode = `data:image/jpeg;base64,${success.data.data.qrCodeBase64}`;
        this.otpCode = success.data.data.secret;
      },
      () => {
        // error
      },
    );
  }

  ValidateOpt(userOtpCode) {
    this.reportServices
      .genericRequest(`/User/ValidateOtp`, {
        otpCode: userOtpCode,
      })
      .then(
        success => {
          if (success.data.data) {
            this._closePopup();
            this.updateReducer(true);
            this.$scope.$emit('opt_register_finished', true);
          } else {
            this.verifyError = true;
          }
        },
        () => {
          // error
        },
      );
  }

  cancelOpt(userOtpCode) {
    this.reportServices
      .genericRequest(`/User/CancelOtp`, {
        otpCode: userOtpCode,
      })
      .then(
        success => {
          if (success.data.data) {
            this._closePopup();
            this.updateReducer(false);
          } else {
            this.verifyError = true;
          }
        },
        () => {
          // error
        },
      );
  }

  _checkCode() {
    const code = `${this.number1}${this.number2}${this.number3}${this.number4}${this.number5}${this.number6}`;
    if (code.length === 6) {
      if (this.cancelOptPopup) {
        this.cancelOpt(code);
      } else {
        this.ValidateOpt(code);
      }
    }
  }

  _copy() {
    const textarea = document.createElement('textarea');
    textarea.value = this.otpCode;
    textarea.style.position = 'fixed';
    textarea.style.left = '-9999px';
    document.body.appendChild(textarea);
    textarea.select();
    const successful = document.execCommand('copy');
    if (successful) {
      this.copyText = 'Copiado';
    }
    document.body.removeChild(textarea);
  }

  clear() {
    this.number1 = '';
    this.number2 = '';
    this.number3 = '';
    this.number4 = '';
    this.number5 = '';
    this.number6 = '';
    this.verifyError = false;
    this.copyText = 'Copiar código';
  }

  updateReducer(active) {
    this.$ngRedux.dispatch({
      type: 'UPDATE_OTP_ACTIVE',
      data: {
        otpActive: active,
      },
    });
  }
}

class GolfleetPopupOtp {
  constructor() {
    this.template = template;
    this.bindings = {
      /* Callbacks */
      openPopupCallback: '&?',
      confirmCallback: '&?',
      requestDataOnInitCallback: '&?',
    };
    this.controller = GolfleetPopupOtpController;
  }
}

angular
  .module('golfleet-popup-otp', ['power-popup', 'power-toast'])
  .component('golfleetPopupOtp', new GolfleetPopupOtp());
