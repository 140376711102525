/* eslint new-cap: ["error", { "newIsCap": false }] */
import angular from 'angular';
import 'ng-redux';

import template from './power-field.html';
import './power-field.scss';

class PowerFieldController {
  static get $inject() {
    return ['$element', '$sce', '$rootScope', '$state', '$http', '$scope', 'urlApi'];
  }

  /**
   * Creates an instance of PowerFieldController.
   * @memberof PowerFieldController
   */
  constructor($element, $sce, $rootScope, $state, $http, $scope, urlApi) {
    Object.assign(this, {
      $: $element[0],
      $sce,
      $rootScope,
      $state,
      $http,
      $scope,
      urlApi,
    });

    this.type = 'text';
    this.field = '';
    this.label = '';
    this.value = null;
    this.error = false;
    this.limitValuesInvalid = false;
    this.complement = '';
    this.minValue = null;
    this.maxValue = null;
    this.disabled = false;
    this.allowZero = false;
    this.placeholder = '';
  }

  /* Lifecycle */
  $onInit() {}

  /**
   * Private
   */
  _removeErrorAttr() {
    this.error = false;
  }

  _removeLimitValuesErrorAttr() {
    // to clear the zeros when click in field uncomment the line below
    // this._validateNumberFields();

    this.limitValuesInvalid = false;
    this.error = false;
  }

  _validateNumberFields() {
    if (this.value == 0 && !this.allowZero) {
      this.value = null;
    }

    if (!!this.value && this.onlyInteger) {
      this.value = parseInt(`${this.value}`.match(/[-]?\d+/g)[0]);
    }
  }

  _validateNumberFieldsKeyDown(event) {
    if (this.onlyInteger) {
      if (event.key === 'e' || event.key === '+' || event.key === '.' || event.key === ',') {
        event.preventDefault();
      }
      if (event.target.value.length == 0 && event.key === '-') {
        event.target.value = '';
      }
      if (event.target.value.length > 0 && event.key === '-') {
        event.preventDefault();
      }
    }
  }

  _validateNumberFieldsPaste(event) {
    if (this.onlyInteger) {
      const clipboard = event.clipboardData.getData('text/plain');
      if (!clipboard.match(/[-]?\d+/y)) {
        event.preventDefault();
      }
    }
  }

  _generateInformationDescription() {
    let description = '';

    if (this.minValue && this.maxValue) {
      description = `Valor Mínimo (${this.minValue}) - Valor Máximo (${this.maxValue})`;
    } else if (this.minValue && !this.maxValue) {
      description = `Valor Mínimo (${this.minValue})`;
    } else if (!this.minValue && this.maxValue) {
      description = `Valor Máximo (${this.maxValue})`;
    }

    return description;
  }
}

class PowerField {
  constructor() {
    this.template = template;
    this.bindings = {
      type: '=?',
      field: '=',
      value: '=?',
      complement: '=?',
      label: '=?',
      error: '=?',
      minValue: '=?',
      maxValue: '=?',
      limitValuesInvalid: '=?',
      disabled: '=?',
      allowZero: '=?',
      placeholder: '=?',
      onlyInteger: '=?',
    };
    this.controller = PowerFieldController;
  }
}

angular.module('power-field', []).component('powerField', new PowerField());
