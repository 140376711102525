import angular from 'angular';

import template from './power-radio-button-group.html';
import './power-radio-button-group.scss';

class PowerRadioButtonGroupController {
  static get $inject() {
    return ['$element', '$scope'];
  }

  constructor($element, $scope) {
    Object.assign(this, { $: $element[0], $scope });

    this.value = false;
    this.model = false;
    this.id = '';
    this.name = '';
  }

  // #region Lifecycle
  $onInit() {
    this.value = !this.value && this.value !== 0 ? false : this.value;
    this.model = !this.model && this.model !== 0 ? false : this.model;
    this.id = this.id || `gs-radiobutton-${Math.random()}`;
    this.name = this.name || 'gs-radiobutton';
  }
  // #endregion Lifecycle
}

class PowerRadioButtonGroup {
  constructor() {
    this.template = template;
    this.bindings = {
      id: '=?',
      name: '=?',
      value: '=',
      model: '=',
      label: '=?',
      color: '=?',
    };
    this.controller = PowerRadioButtonGroupController;
  }
}

angular
  .module('power-radio-button-group', [])
  .component('powerRadioButtonGroup', new PowerRadioButtonGroup());

Object.assign(window, {
  PowerRadioButtonGroup,
  PowerRadioButtonGroupController,
});
