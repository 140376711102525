import angular from 'angular';

import '@power/power-components/components/power-toolbar/power-toolbar';
import '@power/power-components/components/power-popup-addon-request/power-popup-addon-request';

import template from './golfleet-dashboard-info-addon.html';
import './golfleet-dashboard-info-addon.scss';

/**
 * Creates an instance of InfoAddonController.
 * @memberof InfoAddonController
 */
class GolfleetDashboardInfoAddonController {
  static get $inject() {
    return ['$element', '$ngRedux', '$scope', '$http', 'dashboardServices'];
  }

  constructor($element, $ngRedux, $scope, $http, dashboardServices) {
    Object.assign(this, { $: $element[0], $ngRedux, $scope, $http, dashboardServices });
    this.configList = [];

    this.__appBehavior = $ngRedux.connect(behavior => {
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];
      return Object({
        /* State Storage */
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
        isSingleSignon: behavior.session.isSingleSignon,
      });
    })(this);
  }

  $onInit() {
    this._getDashboardData();
  }

  _callAction(action) {
    switch (action.resquestMethod) {
      case 'schedule':
        this.$.querySelector('power-popup-addon-request').tooglePopup();
        break;
      default:
        break;
    }
  }

  _getDashboardData() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this.dashboardServices
      .getDashboardConfig(`${this.currentState.stateConfig.screen}_InfoAddon`)
      .then(response => {
        // Após carregar as configurações do Dashboard, carrega as informações dos Block's
        if (response.status && response.status === 500) {
          this.toastDashboard.text =
            'Ops! Ocorreu um erro ao carregar as configurações do seu Dashboard. Por favor, atualize a página.';
          this.$.querySelector('#toast-obj').toggle(true);
          return;
        }

        this.configList = [...response.configList];
      })
      .finally(() => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
      });
  }

  // _enableLinkPdf(fileName) {
  _enableLinkPdf() {
    // return !!fileName && !this.isSingleSignon;
    return false;
  }

  _getImage(config) {
    const src = '/assets/images/addon/';
    // if (!!config.downloadPdf.fileName && this.isSingleSignon) {
    if (config.downloadPdf.fileName) {
      return `${src}single-signon-${config.image}`;
    }
    return src.concat(config.image);
  }

  _getPdfAddon(fileName) {
    const link = document.createElement('a');
    Object.assign(link, {
      href: `/assets/downloads/${fileName}`,
    });
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

class GolfleetDashboardInfoAddon {
  /**
   * Creates an instance of InfoAddon.
   * @memberof InfoAddon
   */
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetDashboardInfoAddonController;
  }
}

angular
  .module('golfleet-dashboard-info-addon', ['power-toolbar', 'power-popup-addon-request'])
  .component('golfleetDashboardInfoAddon', new GolfleetDashboardInfoAddon());
