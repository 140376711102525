/* global H: true isIframe: true */
import { PowerMapHereProvider } from '../../../power-map/providers/here/power-map.here-provider';
import '../../../../helpers/is-iframe/is-iframe';
import './power-map-ranking-details-speed-limit.here-provider.scss';

class PowerMapRankingDetailsSpeedLimitHereProvider extends PowerMapHereProvider {
  constructor(context, $element, $ngRedux, $scope, $http, urlApi) {
    super(context, $element, $ngRedux, $scope, $http, urlApi);

    this.intervalFullscreen = null;
    this.msgInterval = null;
  }

  /* Private */
  _geoJsonToMarker({ geoJson, markerIcon }) {
    const [[lng, lat]] = geoJson.geometry.coordinates;
    return new H.map.DomMarker(
      { lat, lng },
      {
        icon:
          markerIcon ||
          this._createMarkerIcon({
            ...geoJson.properties,
            icon: geoJson.properties.icon || 'directions_car',
          }),
        data: {
          ...geoJson.properties,
          bubbleContent: `
            <div id="mapPopupHeader">
              <span>${geoJson.properties.veiculoComAlias}</span>
            </div>
            <div id="mapPopupBody">
              <div style="display: ${geoJson.properties.rua ? 'block' : 'none'}">
                <b>Endereço:</b>
                <br>
                <span>${geoJson.properties.rua}</span>
              </div>
              <div style="display: ${geoJson.properties.rua ? 'block' : 'none'}">
                <b>Cidade - Estado:</b>
                <br>
                <span>${geoJson.properties.municipio} - ${geoJson.properties.estado}</span>
              </div>
              <div>
                <b>Data Hora:</b>
                <br>
                <span>${new Date(geoJson.properties.dataHora).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })}</span>
              </div>
              <div>
                <b>Velocidade:</b>
                <br>
                <span>
                  ${geoJson.properties.velocidadeVeiculo}
                </span>
              </div>
              <div>
                <b>Tipo de Infração:</b>
                <br>
                <span>${geoJson.properties.descInfracao}</span>
              </div>
              <div>
                <b>Condutor:</b>
                <br>
                <span>${geoJson.properties.tmtNome || 'Não identificado'}</span>
              </div>
              ${
                isIframe()
                  ? ''
                  : `
                <div style="margin-top:8px;text-align:center">
                  <a class="gs-link"
                    target="_blank"
                    href="${`https://maps.google.com/maps?layer=c&q=${lat},${lng}&cbll=${lat},${lng}&cbp=11,0,0,0,0&z=17&ll=${lat},${lng}`}">
                    Ver no StreetView
                  </a>
                </div>
              `
              }
            </div>
            <div id="mapPopupFooter">
            <span> Lat: ${parseFloat(lat).toFixed(4)} </span>
            <span> Lon: ${parseFloat(lng).toFixed(4)} </span>
            </div>
          `,
        },
      },
    );
  }
  /**/
}

export { PowerMapRankingDetailsSpeedLimitHereProvider };
