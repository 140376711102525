import angular from 'angular';
import 'ng-redux';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-map-ranking-details-speed-limit/power-map-ranking-details-speed-limit';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-toolbar-report/power-toolbar-report';
import '@power/power-components/directives/ng-resize/ng-resize';
import '@power/power-components/directives/ng-tippy/ng-tippy';

import '../golfleet-grid-speed-limit/golfleet-grid-speed-limit';

import { PowerReportRankingDetailsSpeedLimitController } from '@power/power-components/components/power-report-ranking-details-speed-limit/power-report-ranking-details-speed-limit';
import template from './golfleet-report-ranking-details-speed-limit.html';
import './golfleet-report-ranking-details-speed-limit.scss';

class GolfleetReportRankingDetailsSpeedLimitController extends PowerReportRankingDetailsSpeedLimitController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
  }
  /* */

  /* Public */
  /* */

  /* Private */
  _popupViolationModify(action) {
    switch (action) {
      case 'confirm': {
        if (!this.justificationInputValue) {
          this.toastSpeedLimit.text = 'O campo de justificativa precisa ser preenchido';
          this.$.querySelector('#toast-speedlimit-ranking').toggle(true);
          return;
        }
        if (this.justificationInputValue.length > 500) {
          this.toastSpeedLimit.text = 'A justificativa não pode exceder 500 caractéres';
          this.$.querySelector('#toast-speedlimit-ranking').toggle(true);
          return;
        }
        if (this.justificationInputValue.length < 10) {
          this.toastSpeedLimit.text = 'A justificativa precisa ter pelo menos 10 caractéres';
          this.$.querySelector('#toast-speedlimit-ranking').toggle(true);
          return;
        }

        const occurrenceIds = this.selectedRows.map(occurrence => occurrence.occurrenceId);

        if (!this.canSubmit) {
          return;
        }

        this.canSubmit = false;

        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );

        this.$http({
          url: `${this.urlApi}/SpeedLimitModule/UpdateViolationStatus`,
          method: 'POST',
          data: {
            request: {
              occurrenceId: occurrenceIds.toString(),
              isConsiderate: !this.checkViolationDesconsider,
              reason: this.justificationInputValue,
            },
          },
        }).then(success => {
          const response = success.data.data;

          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          );

          this.canSubmit = true;

          if ((success.status && success.status !== 200) || !response) {
            this.toastSpeedLimit.text =
              'Ocorreu um erro ao processar sua solicitação, por favor tente novamente';
            this.$.querySelector('#toast-speedlimit-ranking').toggle(true);

            return;
          }

          this.pluralTexts = this.selectedRows.length > 1;

          const grid = this.$.querySelector('golfleet-grid-speed-limit');
          grid.updateGridSelectedRows(occurrenceIds, this.checkViolationDesconsider);
          this.selectedRows = [];

          setTimeout(() => {
            this.checkViolationDesconsider = false;
            this.justificationInputValue = null;
          }, 300);

          this._switchBlockContent();
        });
        break;
      }

      case 'cancel':
        setTimeout(() => {
          this.justificationInputValue = null;
          this.checkViolationDesconsider = false;
          this.isBlockSuccessContent = false;
          this.pluralTexts = false;
        }, 300);

        this._evtClickPopupViolationModify();
        break;

      default:
        break;
    }
  }
  /* */

  /* Observers */
  /* */
}

class GolfleetReportRankingDetailsSpeedLimit {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetReportRankingDetailsSpeedLimitController;
  }
}

angular
  .module('golfleet-report-ranking-details-speed-limit', [
    'ngRedux',
    'ng-tippy',
    'power-fab',
    'power-toolbar-report',
    'golfleet-grid-speed-limit',
    'power-map-ranking-details-speed-limit',
    'power-footer',
    'power-pagination',
    'power-popup',
    'power-dropdown',
    'ng-resize',
  ])
  .component(
    'golfleetReportRankingDetailsSpeedLimit',
    new GolfleetReportRankingDetailsSpeedLimit(),
  );
