/* eslint-disable camelcase */
import Filter_Activities_Report from './default/Filter_Activities_Report.json';
import Filter_Activities_Trip_Report from './default/Filter_Activities_Trip_Report.json';
import Filter_AttendanceDetail_Report from './default/Filter_AttendanceDetail_Report.json';
import Filter_Attendance_Report from './default/Filter_Attendance_Report.json';
import Filter_Announcements_Report from './default/Filter_Announcements_Report.json';
import Filter_ConsuptionBySegmentReport_List from './default/Filter_ConsuptionBySegmentReport_List.json';
import Filter_ConsuptionBySegmentReport_Trip_List from './default/Filter_ConsuptionBySegmentReport_Trip_List.json';
import Filter_DashboardDriver_Home from './default/Filter_DashboardDriver_Home.json';
import Filter_Dashboard_FuelModule from './default/Filter_Dashboard_FuelModule.json';
import Filter_Dashboard_Home from './default/Filter_Dashboard_Home.json';
import Filter_Dashboard_SpeedLimitModule from './default/Filter_Dashboard_SpeedLimitModule.json';
import Filter_Dashboard_Trip_FuelModule from './default/Filter_Dashboard_Trip_FuelModule.json';
import Filter_Dashboard_Trip_Home from './default/Filter_Dashboard_Trip_Home.json';
import Filter_Dashboard_Trip_SpeedLimitModule from './default/Filter_Dashboard_Trip_SpeedLimitModule.json';
import Filter_Dashboard_Trip_Announcements from './default/Filter_Dashboard_Trip_Announcements.json';
import Filter_TempDriver_List from './default/Filter_TempDriver_List.json';
import Filter_TempDriver_Trip_List from './default/Filter_TempDriver_Trip_List.json';
import Filter_Driver_Trip_List from './default/Filter_Driver_Trip_List.json';
import Filter_FuelSupplyManagementReport_List from './default/Filter_FuelSupplyManagementReport_List.json';
import Filter_FuelSupplyManagementReport_Trip_List from './default/Filter_FuelSupplyManagementReport_Trip_List.json';
import Filter_GeneratedAlerts_Report from './default/Filter_GeneratedAlerts_Report.json';
import Filter_GeoFence_List from './default/Filter_GeoFence_List.json';
import Filter_GolCamReport_List from './default/Filter_GolCamReport_List.json';
import Filter_GroupAdm_List from './default/Filter_GroupAdm_List.json';
import Filter_GroupAdm_Trip_List from './default/Filter_GroupAdm_Trip_List.json';
import Filter_Group_List from './default/Filter_Group_List.json';
import Filter_Group_Trip_List from './default/Filter_Group_Trip_List.json';
import Filter_KmControl_Report from './default/Filter_KmControl_Report.json';
import Filter_KmControl_Trip_Report from './default/Filter_KmControl_Trip_Report.json';
import Filter_MotionLog_Trip_Report from './default/Filter_MotionLog_Trip_Report.json';
import Filter_MyAlertsAdm_Report from './default/Filter_MyAlertsAdm_Report.json';
import Filter_MyAlertsAdm_Trip_Report from './default/Filter_MyAlertsAdm_Trip_Report.json';
import Filter_MyAlerts_Report from './default/Filter_MyAlerts_Report.json';
import Filter_MyAlerts_Trip_Report from './default/Filter_MyAlerts_Trip_Report.json';
import Filter_OrderService_Report from './default/Filter_OrderService_Report.json';
import Filter_PeriodActivitiesDetail_Report from './default/Filter_PeriodActivitiesDetail_Report.json';
import Filter_PeriodActivitiesDetail_Trip_Report from './default/Filter_PeriodActivitiesDetail_Trip_Report.json';
import Filter_PeriodActivities_Report from './default/Filter_PeriodActivities_Report.json';
import Filter_PeriodActivities_Trip_Report from './default/Filter_PeriodActivities_Trip_Report.json';
import Filter_FleetPolicyAdm_Trip_List from './default/Filter_FleetPolicyAdm_Trip_List.json';
import Filter_FleetPolicyStatusAdm_Trip_List from './default/Filter_FleetPolicyStatusAdm_Trip_List.json';
import Filter_FleetPolicyStatus_Trip_List from './default/Filter_FleetPolicyStatus_Trip_List.json';
import Filter_Profiles_List from './default/Filter_Profiles_List.json';
import Filter_RankingSpeedLimitDetail_Report from './default/Filter_RankingSpeedLimitDetail_Report.json';
import Filter_RankingSpeedLimitDetail_Trip_Report from './default/Filter_RankingSpeedLimitDetail_Trip_Report.json';
import Filter_RankingSpeedLimitReportByDriver_List from './default/Filter_RankingSpeedLimitReportByDriver_List.json';
import Filter_RankingSpeedLimitReportByDriver_Trip_List from './default/Filter_RankingSpeedLimitReportByDriver_Trip_List.json';
import Filter_RankingSpeedLimitReportByRoute_List from './default/Filter_RankingSpeedLimitReportByRoute_List.json';
import Filter_RankingSpeedLimitReportByRoute_Trip_List from './default/Filter_RankingSpeedLimitReportByRoute_Trip_List.json';
import Filter_RankingSpeedLimitReportByVehicle_List from './default/Filter_RankingSpeedLimitReportByVehicle_List.json';
import Filter_RankingSpeedLimitReportByVehicle_Trip_List from './default/Filter_RankingSpeedLimitReportByVehicle_Trip_List.json';
import Filter_Reports_Trip_List from './default/Filter_Reports_Trip_List.json';
import Filter_SuspectedAccident_Trip_Report from './default/Filter_SuspectedAccident_Trip_Report.json';
import Filter_TcoAccidentReport_List from './default/Filter_TcoAccidentReport_List.json';
import Filter_TcoCostReport_List from './default/Filter_TcoCostReport_List.json';
import Filter_TcoEntryReport_List from './default/Filter_TcoEntryReport_List.json';
import Filter_TcoRentReport_List from './default/Filter_TcoRentReport_List.json';
import Filter_TcoTicketReport_List from './default/Filter_TcoTicketReport_List.json';
import Filter_TcoParkingReport_List from './default/Filter_TcoParkingReport_List.json';
import Filter_TcoTollReport_List from './default/Filter_TcoTollReport_List.json';
import Filter_PositionsReport_List from './default/Filter_PositionsReport_List.json';
import Filter_PositionsReport_Trip_List from './default/Filter_PositionsReport_Trip_List.json';
import Filter_TripsDetail_Report from './default/Filter_TripsDetail_Report.json';
import Filter_TravelsByDriver_Trip_Report from './default/Filter_TravelsByDriver_Trip_Report.json';
import Filter_TravelsByGroup_Trip_Report from './default/Filter_TravelsByGroup_Trip_Report.json';
import Filter_TravelsByUtilization_Trip_Report from './default/Filter_TravelsByUtilization_Trip_Report.json';
import Filter_TravelsByVehicle_Trip_Report from './default/Filter_TravelsByVehicle_Trip_Report.json';
import Filter_TravelsByVehicleDriver_Trip_Report from './default/Filter_TravelsByVehicleDriver_Trip_Report.json';
import Filter_TripsDriver_Report from './default/Filter_TripsDriver_Report.json';
import Filter_Trips_Report from './default/Filter_Trips_Report.json';
import Filter_User_List from './default/Filter_User_List.json';
import Filter_User_Trip_List from './default/Filter_User_Trip_List.json';
import Filter_TempUtilizationDetail_Report from './default/Filter_TempUtilizationDetail_Report.json';
import Filter_TempUtilization_Report from './default/Filter_TempUtilization_Report.json';
import Filter_TempUtilization_Trip_Report from './default/Filter_TempUtilization_Trip_Report.json';
import Filter_Utilization_Trip_Report from './default/Filter_Utilization_Trip_Report.json';
import Filter_VehicleAdm_List from './default/Filter_VehicleAdm_List.json';
import Filter_VehicleAdm_Trip_List from './default/Filter_VehicleAdm_Trip_List.json';
import Filter_Vehicle_List from './default/Filter_Vehicle_List.json';
import Filter_Vehicle_Trip_List from './default/Filter_Vehicle_Trip_List.json';
import Filter_TempViolation_Trip_Report from './default/Filter_TempViolation_Trip_Report.json';
import Filter_ViolationsSpeedLimitReport_List from './default/Filter_ViolationsSpeedLimitReport_List.json';
import Filter_ViolationsSpeedLimitReport_Trip_List from './default/Filter_ViolationsSpeedLimitReport_Trip_List.json';
import Filter_WifiAdm_Trip_List from './default/Filter_WifiAdm_Trip_List.json';
import Filter_RealTimeVehicle_Trip_Report from './default/Filter_RealTimeVehicle_Trip_Report.json';
import Filter_RealTimeVehicleHistory_Trip_Report from './default/Filter_RealTimeVehicleHistory_Trip_Report.json';
import Filter_RealTimeVehicleHistoryAdm_Trip_Report from './default/Filter_RealTimeVehicleHistoryAdm_Trip_Report.json';
import Filter_RealTimePermissionAdm_Trip_Report from './default/Filter_RealTimePermissionAdm_Trip_Report.json';
import Filter_RealTimeGroupByVehiclesAdm_Trip_Report from './default/Filter_RealTimeGroupByVehiclesAdm_Trip_Report.json';
import Filter_RealTimeGroupByClientDealerAdm_Trip_Report from './default/Filter_RealTimeGroupByClientDealerAdm_Trip_Report.json';
import Filter_ViolationRankingReportSPAL_Trip_Report from './default/Filter_ViolationRankingReportSPAL_Trip_Report.json';
import Filter_VisitReport_Trip_Report from './default/Filter_VisitReport_Trip_Report.json';
import Filter_RevisionPlan_Trip_Report from './default/Filter_RevisionPlan_Trip_Report.json';
import Filter_RevisionStatus_Trip_Report from './default/Filter_RevisionStatus_Trip_Report.json';
import Filter_RevisionHistory_Trip_Report from './default/Filter_RevisionHistory_Trip_Report.json';
import Filter_Workshop_Trip_List from './default/Filter_Workshop_Trip_List.json';
import Filter_PointsOfInterest_Trip_List from './default/Filter_PointsOfInterest_Trip_List';
import Filter_UnitaryViolation_Trip_Report from './default/Filter_UnitaryViolation_Trip_Report.json';
import Filter_ViolationByVehicle_Trip_Report from './default/Filter_ViolationByVehicle_Trip_Report.json';
import Filter_ViolationByDriver_Trip_Report from './default/Filter_ViolationByDriver_Trip_Report.json';
import Filter_ViolationByVehicleDriver_Trip_Report from './default/Filter_ViolationByVehicleDriver_Trip_Report.json';
import Filter_ViolationByGroup_Trip_Report from './default/Filter_ViolationByGroup_Trip_Report.json';
import Filter_DetailedViolation_Trip_Report from './default/Filter_DetailedViolation_Trip_Report.json';
import Filter_Requester_List from './default/Filter_Requester_List.json';
import Filter_DriversAppStatusAdm_Trip_List from './default/Filter_DriversAppStatusAdm_Trip_List.json';
import Filter_GolfleetIdAppStatusAdm_Trip_List from './default/Filter_GolfleetIdAppStatusAdm_Trip_List.json';
import Filter_Blockage_Report from './default/Filter_Blockage_Report.json';
import Filter_BlockageAdm_Report from './default/Filter_BlockageAdm_Report.json';
import Filter_Checklist_Report from './default/Filter_Checklist_Report.json';
import Filter_GolfleetIdPermissionHistoricAdm_Trip_List from './default/Filter_GolfleetIdPermissionHistoricAdm_Trip_List.json';
import Filter_UtilizationSimplified_Trip_List from './default/Filter_UtilizationSimplified_Trip_List.json';

// Tracking
import Filter_PositionsReport_Tracking_List from './tracking/Filter_PositionsReport_Tracking_List.json';
import Filter_TempUtilization_Tracking_Report from './tracking/Filter_TempUtilization_Tracking_Report.json';
import Filter_TempViolation_Tracking_Report from './tracking/Filter_TempViolation_Tracking_Report.json';
import Filter_ViolationByVehicle_Tracking_Report from './tracking/Filter_ViolationByVehicle_Tracking_Report.json';
import Filter_ViolationByDriver_Tracking_Report from './tracking/Filter_ViolationByDriver_Tracking_Report.json';
import Filter_ViolationByVehicleDriver_Tracking_Report from './tracking/Filter_ViolationByVehicleDriver_Tracking_Report.json';
import Filter_ViolationByGroup_Tracking_Report from './tracking/Filter_ViolationByGroup_Tracking_Report.json';
import Filter_Utilization_Tracking_Report from './tracking/Filter_Utilization_Tracking_Report.json';

// New Dashboard
import Filter_Dashboard_Tco from './default/Filter_Dashboard_Tco.json';
import Filter_Dashboard_Travels from './default/Filter_Dashboard_Travels.json';
import Filter_Dashboard_Trip_Tco from './default/Filter_Dashboard_Trip_Tco.json';
import Filter_Dashboard_Trip_Travels from './default/Filter_Dashboard_Trip_Travels.json';
import Filter_Dashboard_Trip_Violation from './default/Filter_Dashboard_Trip_Violation.json';
import Filter_Dashboard_Trip_Utilization from './default/Filter_Dashboard_Trip_Utilization.json';

export default {
  Filter_Activities_Report,
  Filter_Activities_Trip_Report,
  Filter_AttendanceDetail_Report,
  Filter_Attendance_Report,
  Filter_Announcements_Report,
  Filter_ConsuptionBySegmentReport_List,
  Filter_ConsuptionBySegmentReport_Trip_List,
  Filter_DashboardDriver_Home,
  Filter_Dashboard_FuelModule,
  Filter_Dashboard_Home,
  Filter_Dashboard_SpeedLimitModule,
  Filter_Dashboard_Trip_FuelModule,
  Filter_Dashboard_Trip_Home,
  Filter_Dashboard_Trip_SpeedLimitModule,
  Filter_Dashboard_Trip_Announcements,
  Filter_TempDriver_List,
  Filter_TempDriver_Trip_List,
  Filter_Driver_Trip_List,
  Filter_FuelSupplyManagementReport_List,
  Filter_FuelSupplyManagementReport_Trip_List,
  Filter_GeneratedAlerts_Report,
  Filter_GeoFence_List,
  Filter_GolCamReport_List,
  Filter_GroupAdm_List,
  Filter_GroupAdm_Trip_List,
  Filter_Group_List,
  Filter_Group_Trip_List,
  Filter_KmControl_Report,
  Filter_KmControl_Trip_Report,
  Filter_MotionLog_Trip_Report,
  Filter_MyAlertsAdm_Report,
  Filter_MyAlertsAdm_Trip_Report,
  Filter_MyAlerts_Report,
  Filter_MyAlerts_Trip_Report,
  Filter_OrderService_Report,
  Filter_PeriodActivitiesDetail_Report,
  Filter_PeriodActivitiesDetail_Trip_Report,
  Filter_PeriodActivities_Report,
  Filter_PeriodActivities_Trip_Report,
  Filter_FleetPolicyAdm_Trip_List,
  Filter_FleetPolicyStatusAdm_Trip_List,
  Filter_FleetPolicyStatus_Trip_List,
  Filter_Profiles_List,
  Filter_RankingSpeedLimitDetail_Report,
  Filter_RankingSpeedLimitDetail_Trip_Report,
  Filter_RankingSpeedLimitReportByDriver_List,
  Filter_RankingSpeedLimitReportByDriver_Trip_List,
  Filter_RankingSpeedLimitReportByRoute_List,
  Filter_RankingSpeedLimitReportByRoute_Trip_List,
  Filter_RankingSpeedLimitReportByVehicle_List,
  Filter_RankingSpeedLimitReportByVehicle_Trip_List,
  Filter_Reports_Trip_List,
  Filter_SuspectedAccident_Trip_Report,
  Filter_TcoAccidentReport_List,
  Filter_TcoCostReport_List,
  Filter_TcoEntryReport_List,
  Filter_TcoRentReport_List,
  Filter_TcoTicketReport_List,
  Filter_TcoParkingReport_List,
  Filter_TcoTollReport_List,
  Filter_PositionsReport_List,
  Filter_PositionsReport_Trip_List,
  Filter_TravelsByDriver_Trip_Report,
  Filter_TravelsByGroup_Trip_Report,
  Filter_TravelsByUtilization_Trip_Report,
  Filter_TravelsByVehicle_Trip_Report,
  Filter_TravelsByVehicleDriver_Trip_Report,
  Filter_TripsDetail_Report,
  Filter_TripsDriver_Report,
  Filter_Trips_Report,
  Filter_User_List,
  Filter_User_Trip_List,
  Filter_TempUtilizationDetail_Report,
  Filter_TempUtilization_Report,
  Filter_TempUtilization_Trip_Report,
  Filter_Utilization_Trip_Report,
  Filter_VehicleAdm_List,
  Filter_VehicleAdm_Trip_List,
  Filter_Vehicle_List,
  Filter_Vehicle_Trip_List,
  Filter_TempViolation_Trip_Report,
  Filter_ViolationsSpeedLimitReport_List,
  Filter_ViolationsSpeedLimitReport_Trip_List,
  Filter_WifiAdm_Trip_List,
  Filter_RealTimeVehicle_Trip_Report,
  Filter_RealTimeVehicleHistory_Trip_Report,
  Filter_RealTimeVehicleHistoryAdm_Trip_Report,
  Filter_RealTimePermissionAdm_Trip_Report,
  Filter_RealTimeGroupByVehiclesAdm_Trip_Report,
  Filter_RealTimeGroupByClientDealerAdm_Trip_Report,
  Filter_ViolationRankingReportSPAL_Trip_Report,
  Filter_VisitReport_Trip_Report,
  Filter_RevisionPlan_Trip_Report,
  Filter_RevisionStatus_Trip_Report,
  Filter_RevisionHistory_Trip_Report,
  Filter_Workshop_Trip_List,
  Filter_PointsOfInterest_Trip_List,
  Filter_UnitaryViolation_Trip_Report,
  Filter_ViolationByVehicle_Trip_Report,
  Filter_ViolationByDriver_Trip_Report,
  Filter_ViolationByVehicleDriver_Trip_Report,
  Filter_ViolationByGroup_Trip_Report,
  Filter_DetailedViolation_Trip_Report,
  Filter_Requester_List,
  Filter_DriversAppStatusAdm_Trip_List,
  Filter_GolfleetIdAppStatusAdm_Trip_List,
  Filter_Blockage_Report,
  Filter_BlockageAdm_Report,
  Filter_Checklist_Report,
  Filter_GolfleetIdPermissionHistoricAdm_Trip_List,
  Filter_UtilizationSimplified_Trip_List,

  // Tracking
  Filter_PositionsReport_Tracking_List,
  Filter_TempUtilization_Tracking_Report,
  Filter_TempViolation_Tracking_Report,
  Filter_ViolationByVehicle_Tracking_Report,
  Filter_ViolationByDriver_Tracking_Report,
  Filter_ViolationByVehicleDriver_Tracking_Report,
  Filter_ViolationByGroup_Tracking_Report,
  Filter_Utilization_Tracking_Report,

  // New Dashboard
  Filter_Dashboard_Tco,
  Filter_Dashboard_Travels,
  Filter_Dashboard_Trip_Tco,
  Filter_Dashboard_Trip_Travels,
  Filter_Dashboard_Trip_Violation,
  Filter_Dashboard_Trip_Utilization,
};
