import angular from 'angular';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import Treemap from 'highcharts/modules/treemap';
import {
  Engine,
  Scene,
  FollowCamera,
  Vector3,
  HemisphericLight,
  MeshBuilder,
  CannonJSPlugin,
  PhysicsImpostor,
  Path3D,
  Quaternion,
  Color3,
  SceneLoader,
  StandardMaterial,
} from '@babylonjs/core';
import { GridMaterial } from '@babylonjs/materials';
import '@babylonjs/loaders/OBJ';
import 'cannon';
import 'highcharts-regression';

import template from './golfleet-motion-log.html';
import './golfleet-motion-log.scss';

Exporting(Highcharts);
OfflineExporting(Highcharts);
Treemap(Highcharts);

class GolfleetMotionLogController {
  static get $inject() {
    return ['$element', '$scope', '$rootScope'];
  }

  constructor($element, $scope, $rootScope) {
    Object.assign(this, { $: $element[0], $scope, $rootScope });

    this.dataset = [];
    this.simulationStarted = false;
    this.simulationProgress = 0;
  }

  /** Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      resize: this.resize.bind(this),
      updateSimulation: this.updateSimulation.bind(this),
    });

    this.$scope.$watch(() => this.dataset, this.__datasetChanged.bind(this));

    this.canvas = this.$.querySelector('#renderCanvas');

    this.engine = new Engine(this.canvas, true);

    this.createScene();

    this.engine.runRenderLoop(() => {
      this.scene.render();
    });

    window.addEventListener('resize', () => {
      this.resize();
    });

    window.lala = this.engine;
    window.createScene = this.createScene.bind(this);
    window.startSimulation = this.startSimulation.bind(this);
  }
  /** */

  /** Public */
  async createScene() {
    // This creates a basic Babylon Scene object (non-mesh)
    this.scene = new Scene(this.engine);

    // This creates and positions a free camera (non-mesh)
    this.camera = new FollowCamera('camera1', new Vector3(0, 5, -10), this.scene);

    // This targets the camera to scene origin
    this.camera.setTarget(Vector3.Zero());

    // This attaches the camera to the canvas
    this.camera.attachControl(this.canvas, true);

    // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
    const light = new HemisphericLight('light', new Vector3(0, 1, 0), this.scene);

    // Default intensity is 1. Let's dim the light a small amount
    light.intensity = 0.7;

    // 'assets/3D/', 'BasicCar.obj'
    // 'https://raw.githubusercontent.com/Hriz256/car/master/src/assets/', 'car.obj'
    await SceneLoader.ImportMesh(
      '',
      'assets/3D/',
      'golfleet_car_3d_colors.obj',
      this.scene,
      newMeshes => {
        this.car = MeshBuilder.CreateBox('car', { width: 2, height: 1.6, depth: 4 }, this.scene);
        this.car.position.y = 0.9;
        this.car.scaling = new Vector3(1, 1, 1).scale(1);
        this.car.showBoundingBox = false;

        const material = new StandardMaterial('car', this.scene);
        material.alpha = 0;

        this.car.material = material;

        newMeshes.forEach(i => {
          i.parent = this.car;
        });

        // Our built-in 'ground' shape.
        const groundMaterial = new GridMaterial('groundMaterial', this.scene);
        groundMaterial.majorUnitFrequency = 1;
        groundMaterial.minorUnitVisibility = 1;
        groundMaterial.gridRatio = 2;
        groundMaterial.backFaceCulling = false;
        groundMaterial.mainColor = new Color3(1, 1, 1);
        groundMaterial.lineColor = new Color3(0, 0, 0);
        groundMaterial.opacity = 1;

        const ground = MeshBuilder.CreateGround('ground', { width: 9999, height: 999 }, this.scene);
        ground.material = groundMaterial;

        // Camera follow
        this.camera.lockedTarget = this.car;

        // Physics
        const gravityVector = new Vector3(0, -9.81, 0);
        const physicsPlugin = new CannonJSPlugin();
        this.scene.enablePhysics(gravityVector, physicsPlugin);

        this.car.rotation.y = (0 * Math.PI) / 180;

        ground.physicsImpostor = new PhysicsImpostor(
          ground,
          PhysicsImpostor.BoxImpostor,
          { mass: 0, restitution: 0.9, friction: 0.00012 },
          this.scene,
        );
        this.car.physicsImpostor = new PhysicsImpostor(
          this.car,
          PhysicsImpostor.BoxImpostor,
          { mass: 1003.2, restitution: 0.1 },
          this.scene,
        ); // 0.00012
      },
    );

    return this.scene;
  }

  resize() {
    this.engine.resize();
  }

  requestUpdate() {
    if (!this.$rootScope.$$phase && !this.$scope.$$phase) {
      this.$scope.$apply();
    }
  }

  async startSimulation() {
    await this.createScene();

    this.pathPoints = [];
    this.simulationStarted = true;
    this.simulationProgress = 0;

    this.requestUpdate();

    this.$.dispatchEvent(new CustomEvent('update-progress', { detail: this.simulationProgress }));

    const timestamp = new Date().getTime();

    let heading = 0;

    const animationLoop = () => {
      const datasetPosition = Math.abs(Math.round((new Date().getTime() - timestamp) / (1000 / 4)));

      if (datasetPosition >= this.dataset.length) {
        const path3D = new Path3D(this.pathPoints);
        const path = MeshBuilder.CreateLines(
          'path',
          { points: path3D.getCurve(), updatable: true },
          this.scene,
        );
        path.color = Color3.Red();

        this.car.physicsImpostor.setLinearVelocity(new Vector3(0, 0, 0));

        this.simulationStarted = false;
        this.simulationProgress = 100;

        this.requestUpdate();

        this.$.dispatchEvent(
          new CustomEvent('update-progress', { detail: this.simulationProgress }),
        );
      } else {
        this.simulationProgress = (100 * datasetPosition) / (this.dataset.length - 1);

        this.$.dispatchEvent(
          new CustomEvent('update-progress', { detail: this.simulationProgress }),
        );
        // console.log('Time: ', this.dataset[datasetPosition].time);
        // console.log(
        //   'Velocity: ',
        //   Math.sqrt(
        //     this.car.physicsImpostor.getLinearVelocity().x ** 2 +
        //       this.car.physicsImpostor.getLinearVelocity().z ** 2,
        //   ) * 3.6,
        // );
        // console.log('Expected Velocity: ', this.dataset[datasetPosition].velocity);
        let Fx = 0;
        let Fz = 0;

        const headingX = heading;

        // Decompose force registred as coordinated X relative to the car into global Fx and Fz
        if (heading >= 0 && heading <= 90) {
          Fx += parseInt(this.dataset[datasetPosition].x) * Math.cos((headingX * Math.PI) / 180);
          Fz += parseInt(this.dataset[datasetPosition].x) * Math.sin((headingX * Math.PI) / 180);
        } else {
          Fx +=
            -1 * parseInt(this.dataset[datasetPosition].x) * Math.cos((headingX * Math.PI) / 180);
          Fz += parseInt(this.dataset[datasetPosition].x) * Math.sin((headingX * Math.PI) / 180);
        }

        // Rotate force registred as coordinated Z relative to the car
        const headingZ = heading + 90;

        // Decompose force registred as coordinated Z relative to the car into global Fx and Fz
        if (headingZ >= 0 && headingZ <= 90) {
          Fx += parseInt(this.dataset[datasetPosition].z) * Math.cos((headingZ * Math.PI) / 180);
          Fz += parseInt(this.dataset[datasetPosition].z) * Math.sin((headingZ * Math.PI) / 180);
        } else {
          Fx +=
            -1 * parseInt(this.dataset[datasetPosition].z) * Math.cos((headingZ * Math.PI) / 180);
          Fz += parseInt(this.dataset[datasetPosition].z) * Math.sin((headingZ * Math.PI) / 180);
        }

        this.car.physicsImpostor.applyImpulse(
          new Vector3(
            15 * 1003.2 * Fx * 9.81 * 10 ** -6,
            15 * 1003.2 * parseInt(this.dataset[datasetPosition].y) * 9.81 * 10 ** -6,
            15 * 1003.2 * Fz * 9.81 * 10 ** -6,
          ),
          this.car.getAbsolutePosition(),
        );

        this.pathPoints.push(this.car.getAbsolutePosition().clone());

        const path3D = new Path3D(this.pathPoints);
        const path = MeshBuilder.CreateLines(
          'path',
          { points: path3D.getCurve(), updatable: true },
          this.scene,
        );
        path.color = Color3.Red();

        const linVel = this.car.physicsImpostor.getLinearVelocity();
        linVel.normalize();

        this.car.rotationQuaternion = Quaternion.FromEulerAngles(
          0,
          Math.atan2(linVel.x, linVel.z) + Math.PI * 1,
          0,
        );

        heading = (Math.atan2(linVel.z, linVel.x) * 180) / Math.PI;

        requestAnimationFrame(animationLoop);
      }
    };

    setTimeout(() => {
      this.car.physicsImpostor.setLinearVelocity(new Vector3(this.dataset[0].velocity / 3.6, 0, 0));

      const linVel = this.car.physicsImpostor.getLinearVelocity();
      linVel.normalize();

      this.car.rotationQuaternion = Quaternion.FromEulerAngles(
        0,
        Math.atan2(linVel.x, linVel.z) + Math.PI * 1,
        0,
      );

      heading = (Math.atan2(linVel.z, linVel.x) * 180) / Math.PI;

      requestAnimationFrame(animationLoop);
    }, 1000);
  }

  updateSimulation() {
    this.resize();
    this.createScene();
  }
  /** */

  /** Protected */
  __datasetChanged() {}
  /** */
}

class GolfleetMotionLog {
  constructor() {
    this.template = template;
    this.bindings = {
      dataset: '=?',
    };
    this.controller = GolfleetMotionLogController;
  }
}

angular.module('golfleet-motion-log', []).component('golfleetMotionLog', new GolfleetMotionLog());
