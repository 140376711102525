import angular from 'angular';
import 'ng-redux';
import 'angular-sanitize';
import 'moment/src/locale/pt-br';

import { PowerGridDriverController } from '@power/power-components/components/power-grid-driver/power-grid-driver';
import { isValidEmail } from '@power/power-components/utils/email-util.js';

import template from './golfleet-grid-driver.html';
import '../golfleet-popup-driver-privacy-policy/golfleet-popup-driver-privacy-policy';
import '../golfleet-popup-golfleet-id-privacy-policy/golfleet-popup-golfleet-id-privacy-policy';
import './golfleet-grid-driver.scss';

class GolfleetGridDriverController extends PowerGridDriverController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$state',
      '$http',
      '$timeout',
      '$filter',
      'commonServices',
      'urlApi',
      'driverServices',
      '$ngRedux',
    ];
  }

// eslint-disable-next-line no-useless-constructor
  constructor(
    $element,
    $scope,
    $state,
    $http,
    $timeout,
    $filter,
    commonServices,
    urlApi,
    driverServices,
    $ngRedux,
  ) {
    super(
      $element,
      $scope,
      $state,
      $http,
      $timeout,
      $filter,
      commonServices,
      urlApi,
      driverServices,
      $ngRedux,
    );
  }

  /* Lifecycle */
  /* */

  /* Public */
  /* */

  /* Private */
  _evtClickAddEmail(action, data, type) {
    switch (action) {
      case 'open':
        this.driverId = data.id;
        this.popupContent = 0;
        this.driverEmail = '';
        this.driverEmailWarning = '';

        if (type === 'Grid_DriversAppStatusAdm_Trip_List') {
          this.powerIdInvite = false;
          this._privacyPolicyOpen();
        } else {
          this.powerIdInvite = true;
          this._privacyPolicyOpen();
        }

        break;
      case 'close':
        this.$.querySelector('#popup-add-email').toggle();
        this.getDataset();
        break;
      case 'primaryBtn':
        if (!isValidEmail(this.driverEmail)) {
          this.driverEmailWarning = 'E-mail inválido';
        } else if (this.powerIdInvite == false) {
          this._saveEmail();
        } else {
          this._saveEmailGolfleetId();
        }
        break;
      default:
        break;
    }
  }


  _privacyPolicyOpen() {
    this.popupConfig = [
      {
        title: 'Condutor sem email',
        subTitle: 'Por favor informe o email deste condutor',
        action: 'primaryBtn',
      },
      {
        title: 'Convite enviado',
        subTitle: this.powerIdInvite
          ? 'O convite para a instalação do App Golfleet ID foi enviado para o seguinte email'
          : 'O convite para a instalação do App Golfleet Driver foi enviado para o seguinte email',
        action: 'close',
      },
    ];

    let privacyPolicyPopup = null;
    if (this.powerIdInvite) {
      privacyPolicyPopup = this.$.querySelector('#golfleet-popup-golfleet-id-privacy-policy');
    } else {
      privacyPolicyPopup = this.$.querySelector('#golfleet-popup-driver-privacy-policy');
    }

    if (privacyPolicyPopup) {
      privacyPolicyPopup.show();
    }
  }

  _saveEmail() {
    const payload = {
      driverId: this.driverId,
      userLoginApp: this.driverEmail,
    };

    this._toggleLoader(true);
    this.driverServices
      .createUserAppDriver(payload)
      .then(
        success => {
          if (success.status && success.status !== 200) {
            this.toastObj = {
              text: success.data?.data?.toString() || 'Erro ao salvar o e-mail. Tente novamente!',
            };
            this.$.querySelector('#toast-obj').toggle(true);
          }
          if (success.data && !success.data.hasError) {
            this.popupContent = 1;
          }
        },
        error => {
          this.toastObj = {
            text: error.data.data[0],
          };
          this.$.querySelector('#toast-obj').toggle(true);
        },
      )
      .finally(() => {
        this._toggleLoader(false);
      });
  }

  _saveEmailGolfleetId() {
    const payload = {
      driverId: this.driverId,
      userLoginApp: this.driverEmail,
    };

    this._toggleLoader(true);
    this.driverServices
      .createUserDriverId(payload)
      .then(
        success => {
          if (success.status && success.status !== 200) {
            this.toastObj = {
              text: success.data?.data?.toString() || 'Erro ao salvar o e-mail. Tente novamente!',
            };
            this.$.querySelector('#toast-obj').toggle(true);
          }
          if (success.data && !success.data.hasError) {
            this.popupContent = 1;
          }
        },
        error => {
          this.toastObj = {
            text: error.data.data[0],
          };
          this.$.querySelector('#toast-obj').toggle(true);
        },
      )
      .finally(() => {
        this._toggleLoader(false);
      });
  }
  /* */

  /* Observers */
  /* */
}

class GolfleetGridDriver {
  constructor() {
    this.template = template;
    this.bindings = {
      /* common */
      datasetMethod: '=?',
      isPaginated: '=?',
      page: '=?',
      pageSize: '=?',
      pageRows: '=?',
      lastPage: '=?',
      datasetLength: '=?',
      hasRowSelection: '=?',
      dateGranularity: '=?',
      /* underscore */
      gridHeaders: '=?',
      gridDataset: '=?',
      gridHeadersCategories: '=?',
      sortHeader: '=?',
      sortDirection: '=?',
      selectedRows: '=?',
      /* duble underscore */
      mainHeader: '=?',
      mongoGridId: '=?',
      headerParams: '=?',
      datasetParams: '=?',
    };
    this.controller = GolfleetGridDriverController;
  }
}

angular
  .module('golfleet-grid-driver', [
    'ngSanitize',
    'ng-resize',
    'golfleet-popup-driver-privacy-policy',
    'golfleet-popup-golfleet-id-privacy-policy',
  ])
  .component('golfleetGridDriver', new GolfleetGridDriver());
