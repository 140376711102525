import angular from 'angular';

import '../power-popup/power-popup';

import template from './power-popup-custom-actions.html';
import './power-popup-custom-actions.scss';

export class CustomActionOption {
  /**
   *
   * @param {string} value
   * @param {string} description
   * @param {boolean} selected
   */
  constructor(value, description, selected = false) {
    this.value = value;
    this.description = description;
    this.selected = selected;
  }
}

/**
 * - Component Controller
 */
export class PowerPopupCustomActionsController {
  static get $inject() {
    return ['$element', '$scope'];
  }

  /**
   * - Constructor
   */
  constructor($element, $scope) {
    Object.assign(this, { $: $element[0], $scope });
  }

  // #region /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
    });
  }

  $onDestroy() {}
  // #endregion

  // #region /* Public */
  /**
   * Open / Close pop-up
   */
  toggle() {
    this.$.querySelector('#power-popup-custom-actions').toggle();
  }

  selectOption(value) {
    this.options.forEach(option => {
      option.selected = option.value == value;
    });
  }

  confirmAction() {
    this.confirmationCallback({
      value: this.options.find(opt => opt.selected).value,
    });
  }
  // #endregion
}

/**
 * Component Class
 */
export class PowerPopupCustomActions {
  constructor() {
    this.template = template;
    this.bindings = {
      header: '=',
      description: '=',
      options: '=',

      /* Callbacks */
      confirmationCallback: '&?',
    };
    this.controller = PowerPopupCustomActionsController;
  }
}

angular
  .module('power-popup-custom-actions', ['power-popup'])
  .component('powerPopupCustomActions', new PowerPopupCustomActions());
