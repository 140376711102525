/* global L: true */
import { PowerMapLeafletProvider } from '@power/power-components/components/power-map/providers/leaflet/power-map.leaflet-provider';
import './power-map-geofences.leaflet-provider.scss';

class PowerMapGeofencesLeafletProvider extends PowerMapLeafletProvider {
  constructor(context, $element, $ngRedux, $scope, $http, urlApi) {
    super(context, $element, $ngRedux, $scope, $http, urlApi);

    this.drawController = null;
    this.drawEndedCallback = null;
  }

  // #region Lifecycle
  $onInit() {
    Object.assign(this.$, {
      startDraw: this.startDraw.bind(this),
      endDraw: this.endDraw.bind(this),
      cancelDraw: this.cancelDraw.bind(this),
    });

    super.$onInit();
  }
  // #endregion Lifecycle

  // #region Public
  renderDataset({
    dataset,
    layerName = 'default',
    type = 'FeatureGroup',
    useCluster,
    clusterColor = '#D60F2C',
  }) {
    return this._createDatasetLayer({ type, dataset }).then(featureGroup => {
      if (this.mapFeatureLayers[layerName])
        this.mapLayer.removeLayer(this.mapFeatureLayers[layerName]);
      if (useCluster)
        this.mapFeatureLayers[layerName] = this._createCluster({ clusterColor }).addLayer(
          featureGroup,
        );
      else if (layerName == 'draw')
        this.mapFeatureLayers[layerName].addLayer(featureGroup.getLayers()[0]);
      else this.mapFeatureLayers[layerName] = featureGroup;
      this.mapLayer.addLayer(this.mapFeatureLayers[layerName]);
    });
  }

  startDraw({ callback, dataset }) {
    this.$.setAttribute('draw', '');
    this.drawEndedCallback = callback;
    this.$.querySelector('#draw-actions').style.visibility = 'visible';
    if (!this.drawController) this._initializeMapDrawTool({ dataset });
    else this.mapLayer.addControl(this.drawController);

    if (dataset && dataset.geoJson) {
      let bounds = null;

      if (dataset.radius) {
        const { latitude, longitude, radius } = dataset;
        const circle = L.circle([latitude, longitude], { radius });
        this.mapFeatureLayers.draw.addLayer(circle);
        bounds = circle.getBounds();
      } else {
        const [coordinates] = [...dataset.geoJson.coordinates];
        const polygon = L.polygon([coordinates.map(item => [...item].reverse())]);
        this.mapFeatureLayers.draw.addLayer(polygon);
        bounds = polygon.getBounds();
      }

      this.mapLayer.fitBounds(bounds);
    }
  }

  endDraw() {
    const geoJson = this.mapFeatureLayers.draw.getLayers().map(layer =>
      layer._mRadius
        ? Object.assign(layer.toGeoJSON(), {
            properties: {
              radius: layer._mRadius,
            },
          })
        : layer.toGeoJSON(),
    )[0];
    this.drawEndedCallback(geoJson);
    this.cancelDraw();
  }

  cancelDraw() {
    this.$.removeAttribute('draw');
    this.$.querySelector('#draw-actions').style.visibility = 'hidden';
    if (this.drawController && this.drawController._map)
      this.mapLayer.removeControl(this.drawController);
    if (this.mapFeatureLayers.draw) this.mapFeatureLayers.draw.clearLayers();
    this.$.dispatchEvent(new CustomEvent('drawLayerRemoved', { bubbles: true, composed: true }));
  }
  // #endregion Public

  // #region Private
  _initializeMapDrawTool() {
    Object.assign(L.drawLocal, {
      draw: {
        toolbar: {
          actions: {
            text: 'Cancelar',
            title: 'Cancelar',
          },
          buttons: {
            marker: 'Inserir um Ponto',
            circlemarker: 'Inserir um Círculo',
            circle: 'Desenhar uma Área Circular',
            polygon: 'Desenhar uma Área Poligonal',
            polyline: 'Desenhar um Caminho',
            rectangle: 'Desenhar um Retângulo',
          },
          finish: {
            enable: false,
            text: 'Concluir',
            title: 'Concluir',
          },
          undo: {
            text: 'Desfazer ponto',
            title: 'Desfazer ponto',
          },
        },
        handlers: {
          marker: {
            tooltip: {
              start: 'Click no mapa para criar o Ponto',
            },
          },
          circlemarker: {
            tooltip: {
              start: 'Click no mapa para criar o Círculo',
            },
          },
          circle: {
            radius: 'Raio',
            tooltip: {
              start: 'Click e arraste para desenhar a Área Circular',
            },
          },
          polygon: {
            tooltip: {
              cont: 'Click para continua a desenhar',
              start: 'Click começar a desenhar',
              end: 'Click no primeiro ponto para concluir',
            },
          },
          polyline: {
            error: 'Proibido criar lados cruzados',
            tooltip: {
              cont: 'Click para continuar a desenhar',
              start: 'Click para começar a desenhar',
              end: 'Click no último ponto para concluir',
            },
          },
          rectangle: {
            tooltip: {
              start: 'Click e arraste para desenhar o Retângulo',
            },
          },
          simpleshape: {
            tooltip: {
              end: 'Solte para concluir',
            },
          },
        },
      },
      edit: {
        toolbar: {
          actions: {
            cancel: {
              text: 'Cancelar',
              title: 'Cancelar',
            },
            clearAll: {
              text: 'Limpar tudo',
              title: 'Limpar tudo',
            },
            save: {
              text: 'Concluir',
              title: 'Concluir',
            },
          },
          buttons: {
            edit: 'Editar',
            editDisabled: 'Nada para editar',
            remove: 'Remover',
            removeDisabled: 'Nada para remover',
          },
        },
        handlers: {
          edit: {
            tooltip: {
              text: 'Click em Cancelar para desfazer as alterações',
              subText: 'Para editar, arraste os pontos',
            },
          },
          remove: {
            tooltip: {
              text: 'Click em um objeto para remover',
            },
          },
        },
      },
    });

    this.mapFeatureLayers.draw = L.featureGroup();
    this.drawController = new L.Control.Draw({
      draw: {
        marker: false,
        circle: true,
        polygon: {
          allowIntersection: false,
        },
        polyline: false,
        rectangle: false,
        circlemarker: false,
      },
      edit: {
        remove: false,
        featureGroup: this.mapFeatureLayers.draw,
        poly: {
          allowIntersection: false,
        },
      },
    });

    this.mapLayer.on(L.Draw.Event.DRAWSTART, () => {
      this.$.querySelector('#draw-actions').setAttribute('hide', '');
      this.mapFeatureLayers.draw.clearLayers();
      this.$scope.$apply();
    });

    this.mapLayer.on(L.Draw.Event.CREATED, evt => {
      this.$.querySelector('#draw-actions').removeAttribute('hide');
      if (!evt.layer.options.editing)
        Object.assign(evt.layer.options, {
          editing: evt.layer.editing,
        });
      this.mapFeatureLayers.draw.addLayer(evt.layer);
    });

    this.mapLayer.on(L.Draw.Event.EDITSTART, () => {
      this.$.querySelector('#draw-actions').setAttribute('hide', '');
      this.mapFeatureLayers.draw.getLayers().map(layer => {
        if (!layer.options.editing)
          Object.assign(layer.options, {
            editing: layer.editing,
          });
        return layer;
      });
    });

    this.mapLayer.on(L.Draw.Event.EDITSTOP, () => {
      this.$.querySelector('#draw-actions').removeAttribute('hide');
    });

    this.mapLayer.addLayer(this.mapFeatureLayers.draw);
    this.mapLayer.addControl(this.drawController);
  }

  _geoJsonToLGeoJson({ geoJson }) {
    return L.geoJSON(geoJson, {
      style: { color: geoJson.properties.color },
      onEachFeature: (feature, layer) => {
        layer.bindPopup(`
				  <div id="mapPopupHeader">
            <span>${feature.properties.description}</span>
          </div>
          <div id="mapPopupBody">
            <div>
              <b>Descrição:</b>
              <br>
              <span>${feature.properties.extendedDescription || '---'}</span>
            </div>
            <div>
              <b>Categoria:</b>
              <br>
              <span>${feature.properties.categoryName || '---'}</span>
            </div>
            <div>
              <b>Privacidade:</b>
              <br>
              <span>${feature.properties.privacy || '---'}</span>
            </div>
            <div>
              <b>Status:</b>
              <br>
              <span>${feature.properties.providerStatusDesc || '---'}</span>
            </div>
          </div>
          <div id="mapPopupFooter">
            <span> Lat: ${parseFloat(feature.properties.latitude).toFixed(4)} </span>
            <span> Lon: ${parseFloat(feature.properties.longitude).toFixed(4)} </span>
          </div>
			  `);
      },
      pointToLayer: (feature, latlng) => {
        switch (feature.type) {
          case 'Polygon':
            return L.polygon(latlng, feature.properties);
          case 'Polyline':
            return L.polyline(latlng, feature.properties);
          case 'Rectangle':
            return L.rectangle(latlng, feature.properties);
          default:
            return feature.properties.radius
              ? L.circle(latlng, feature.properties)
              : L.marker(latlng, {
                  ...feature.properties,
                  icon: this._createMarkerIcon(feature.properties.color, feature.properties.icon),
                });
        }
      },
    }).getLayers()[0];
  }
  // #endregion Private

  // #region Observers
  // #endregion Observers
}

export { PowerMapGeofencesLeafletProvider };
