import angular from 'angular';

import '../power-crud-textbox/power-crud-textbox';
import '../power-crud-combobox/power-crud-combobox';
import '../power-crud-combobox-multi-select/power-crud-combobox-multi-select';

import template from './power-crud-section.html';
import './power-crud-section.scss';

class PowerCrudSectionController {
  static get $inject() {
    return ['$element'];
  }

  constructor($element) {
    Object.assign(this, { $: $element[0] });
  }
}

class PowerCrudSection {
  constructor() {
    this.template = template;
    this.bindings = {
      section: '=',
      dataset: '=?',
    };
    this.controller = PowerCrudSectionController;
  }
}

angular
  .module('power-crud-section', [
    'power-crud-textbox',
    'power-crud-combobox',
    'power-crud-combobox-multi-select',
  ])
  .component('powerCrudSection', new PowerCrudSection());
