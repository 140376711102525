/** @typedef {import('@power/power-components/components/power-popup-users/power-popup-users').User} User */

import angular from 'angular';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-toolbar-report/power-toolbar-report';
import '../golfleet-grid-maintenance/golfleet-grid-maintenance';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-toast/power-toast';
import '@power/power-components/directives/ng-tippy/ng-tippy';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-popup-users/power-popup-users';
import '@power/power-components/components/power-header-selector/power-header-selector';
import { PowerReportController } from '@power/power-components/components/power-report/power-report';

import template from './golfleet-report-revision-plan.html';
import './golfleet-report-revision-plan.scss';

/**
 * Items Type (Enum)
 */
export const SendAlertEnum = {
  WITHOUT_REVISION: 'sendAlertVehicleWithoutPlan',
  NEXT: 'sendAlertNextRevision',
  DELAYED: 'sendAlertDelayedRevision',
};

export class SharedUser {
  /**
   * - Constructor
   * @param {String} icon
   * @param {Number} userId
   * @param {String} userName
   * @param {String} userEmail
   */
  constructor(icon, userId, userName, userEmail) {
    this.icon = icon;
    this.id = userId;
    this.name = userName;
    this.email = userEmail;
  }
}

class GolfleetReportRevisionPlanController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
      'maintenanceServices',
      '$sce',
      '$rootScope',
    ];
  }

  /**
   * Creates an instance of GolfleetReportRevisionPlanController.
   * @memberof GolfleetReportRevisionPlanController
   */
  // eslint-disable-next-line no-useless-constructor
  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
    maintenanceServices,
    $sce,
    $rootScope,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    Object.assign(this, { maintenanceServices, $sce, $rootScope });

    this.popupConfigTabSelected = 1; // 1. Opções, 2. Frequência, 3. Recipientes

    this.maintenanceConfigOptions = [
      {
        value: 'WITHOUT_REVISION',
        description: 'Enviar alertas sobre veículos sem plano de revisão',
      },
      { value: 'NEXT', description: 'Enviar alertas sobre próximas revisões' },
      { value: 'DELAYED', description: 'Enviar alertas sobre revisões atrasadas' },
    ];

    this.model = {};
    this.sharedUsers = [];
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
  }

  _addRevisionPlan() {
    this.$ngRedux.dispatch({
      type: 'NEXT_ROUTE',
      data: {
        routeName: 'Plano de revisão',
        routeLink: 'revisionPlanForm',
        routeSubName: 'Formulário de Plano de Revisão',
        routeTail: null,
        stateConfig: { ...this.stateConfig, gridName: 'Formulário', filterConfig: [] },
      },
    });

    this.$state.go('revisionPlanForm');
  }

  _toggleMaintenanceConfigOption(option) {
    option.selected = !option.selected;
  }

  _evtClickPopupAlertTab(tabNumber) {
    // 1. Opções, 2. Frequência, 3. Recipientes
    this.popupConfigTabSelected = tabNumber;
    this.$.querySelector('.body-content').setAttribute('tab', tabNumber);
  }

  _evtClickBtnPowerPopup(type, popupId) {
    if (type == 'close') {
      switch (popupId) {
        case 'popup-maintenance-config':
          this._controlBlurInputMaintenanceConfig(false);
          this.$.querySelector('#popup-maintenance-config').toggle();
          break;
        case 'popup-validation-messages':
          this.$.querySelector('#popup-validation-messages').toggle();
          break;
        default:
          break;
      }
    } else if (type == 'open') {
      switch (popupId) {
        case 'popup-maintenance-config':
          this._controlBlurInputMaintenanceConfig(true);
          this._evtClickPopupAlertTab(1);
          this._getAlertConfig();
          break;
        case 'golfleet-popup-config-users':
          this.$.querySelector('#golfleet-popup-config-users').toggle();
          break;
        default:
          break;
      }
    } else if (type == 'primaryBtn') {
      switch (popupId) {
        case 'popup-maintenance-config':
          if (this._validateAlertConfig()) this._upsertAlertConfig();
          break;
        default:
          break;
      }
    }
  }

  _controlBlurInputMaintenanceConfig(addEventListener) {
    const inputs = this.$.querySelectorAll('#popup-maintenance-config power-crud-textbox input');
    if (addEventListener) {
      inputs.forEach(input => input.addEventListener('blur', this._checkValuesMaintenanceConfig));
    } else {
      inputs.forEach(input =>
        input.removeEventListener('blur', this._checkValuesMaintenanceConfig),
      );
    }
  }

  _checkValuesMaintenanceConfig(event) {
    const input = event.target;
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(input.value) || parseInt(input.value) < 1) {
      input.value = null;
      input.dispatchEvent(new Event('change'));
    }
  }

  /**
   * - Add selected items - users pop-up callback
   * @param {Array<User>} selectedItems
   */
  _addSelectedUsersPopupCallback(selectedItems) {
    selectedItems.forEach(item => {
      if (!this.sharedUsers.find(user => user.id == item.id)) {
        this.sharedUsers.push(new SharedUser(item.icon, item.id, item.name, item.email));
      }
    });
  }

  _getAlertConfig() {
    this.$.querySelector('#popup-maintenance-config').toggle();
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this.maintenanceServices
      .callApiMethod('Maintenance/GetAlertConfig')
      .then(success => {
        this.$.querySelector('#golfleet-popup-config-users').requestData();

        if (success.status && success.status !== 200) {
          this.$.querySelector('power-toast#report-toast').toggle(true);
          Object.assign(this, {
            toast: { text: 'Ocorreu um erro ao obter os dados de configuração de alertas' },
          });
          return;
        }
        if (success.data.data) {
          this.model = success.data.data;
          this.maintenanceConfigOptions.forEach(opt => {
            opt.selected = this.model[SendAlertEnum[opt.value]];
          });
        }
      })
      .finally(() => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
      });
  }

  _validateAlertConfig() {
    this.validationMessages = [];
    if (this._isInvalidNumber(this.model.configSoonStatus)) {
      this.validationMessages.push(
        this.$sce.trustAsHtml(
          '<div class="tab-info">Aba: <b>OPÇÕES</b>. </div>Campo: <b>"Atribuir status EM BREVE para revisões previstas ou agendadas para"</b> não pode ser vazio.',
        ),
      );
    }

    if (
      this._isInvalidNumber(this.model.startNextRevision) ||
      this._isInvalidNumber(this.model.repeatNextRevision)
    ) {
      this.validationMessages.push(
        this.$sce.trustAsHtml(
          '<div class="tab-info">Aba: <b>FREQUÊNCIA</b>. </div>Os campos de: <b>"Avisos de próxima revisão"</b> não podem ser vazios.',
        ),
      );
    }

    if (
      this._isInvalidNumber(this.model.startRevisionDelayed) ||
      this._isInvalidNumber(this.model.repeatRevisionDelayed)
    ) {
      this.validationMessages.push(
        this.$sce.trustAsHtml(
          '<div class="tab-info">Aba: <b>FREQUÊNCIA</b>. </div>Os campos de: <b>"Avisos de revisão atrasada"</b> não podem ser vazios.',
        ),
      );
    }

    if (this.sharedUsers.length === 0) {
      this.validationMessages.push(
        this.$sce.trustAsHtml(
          '<div class="tab-info">Aba: <b>DESTINATÁRIOS</b>. </div>Obrigatório pelo menos um destinatário.',
        ),
      );
    }

    if (this.validationMessages.length > 0) {
      this.$.querySelector('#popup-validation-messages').toggle();
      return false;
    }

    return true;
  }

  _upsertAlertConfig() {
    const objRequest = {
      id: this.model.id,
      configSoonStatus: this.model.configSoonStatus,
      startNextRevision: this.model.startNextRevision,
      repeatNextRevision: this.model.repeatNextRevision,
      startRevisionDelayed: this.model.startRevisionDelayed,
      repeatRevisionDelayed: this.model.repeatRevisionDelayed,
      recipients: this.sharedUsers.map(user => user.id),
      sendAlertVehicleWithoutPlan: this.maintenanceConfigOptions.find(
        opt => opt.value === 'WITHOUT_REVISION',
      ).selected,
      sendAlertNextRevision: this.maintenanceConfigOptions.find(opt => opt.value === 'NEXT')
        .selected,
      sendAlertDelayedRevision: this.maintenanceConfigOptions.find(opt => opt.value === 'DELAYED')
        .selected,
    };

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this.maintenanceServices
      .callApiMethod('Maintenance/UpsertAlertConfig', { request: objRequest })
      .then(success => {
        const { data } = success.data;

        if (success.status && success.status !== 200) {
          this.$.querySelector('power-toast#report-toast').toggle(true);
          Object.assign(this, {
            toast: { text: 'Ocorreu um erro ao salvar a configuração de alertas' },
          });
          return;
        }

        if (data) {
          this.$.querySelector('power-toast#report-toast').toggle(true);
          Object.assign(this, { toast: { text: 'Salvo com sucesso!' } });
          this._controlBlurInputMaintenanceConfig(false);
          this.$.querySelector('#popup-maintenance-config').toggle();
        }
      })
      .finally(() => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
      });
  }

  /**
   * - Request data - users pop-up callback
   * @param {Array<User>} userItems
   */
  _requestUsersPopupCallback(userItems) {
    this.sharedUsers = [];
    if (this.model.recipients) {
      userItems.forEach(item => {
        if (this.model.recipients.find(user => user == item.id)) {
          this.sharedUsers.push(new SharedUser(item.icon, item.id, item.name, item.email));
        }
      });
    }

    if (!this.$scope.$$phase && !this.$rootScope.$$phase) this.$scope.$apply();
  }

  /**
   * Remove user by id
   * @param {Number} id
   */
  _removeUser(id) {
    this.sharedUsers = this.sharedUsers.filter(user => user.id != id);
  }

  _isInvalidNumber(value) {
    return Number.isNaN(parseInt(value));
  }
}

class GolfleetReportRevisionPlan {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetReportRevisionPlanController;
  }
}

angular
  .module('golfleet-report-revision-plan', [
    'ngRedux',
    'power-fab',
    'power-toolbar-report',
    'golfleet-grid-maintenance',
    'power-footer',
    'power-pagination',
    'power-toast',
    'ng-tippy',
    'power-popup',
    'power-header-selector',
    'power-popup-users',
  ])
  .component('golfleetReportRevisionPlan', new GolfleetReportRevisionPlan());
