/* eslint-disable camelcase */
import Record_TempDriver from './default/Record_TempDriver.json';
import Record_TempDriver_Trip from './default/Record_TempDriver_Trip.json';
import Record_Driver_Trip from './default/Record_Driver_Trip.json';
import Record_Group from './default/Record_Group.json';
import Record_GroupAdm from './default/Record_GroupAdm.json';
import Record_GroupAdm_Trip from './default/Record_GroupAdm_Trip.json';
import Record_Group_Trip from './default/Record_Group_Trip.json';
import Record_RequesterReport from './default/Record_RequesterReport.json';
import Record_TcoEntryReport from './default/Record_TcoEntryReport.json';
import Record_TcoTicketReport from './default/Record_TcoTicketReport.json';
import Record_TcoRentReport from './default/Record_TcoRentReport.json';
import Record_TcoParkingReport from './default/Record_TcoParkingReport.json';
import Record_TcoTollReport from './default/Record_TcoTollReport.json';
import Record_User from './default/Record_User.json';
import Record_UserAdm from './default/Record_UserAdm.json';
import Record_UserAdm_Trip from './default/Record_UserAdm_Trip.json';
import Record_User_Trip from './default/Record_User_Trip.json';
import Record_Vehicle from './default/Record_Vehicle.json';
import Record_VehicleAdm from './default/Record_VehicleAdm.json';
import Record_VehicleAdm_Trip from './default/Record_VehicleAdm_Trip.json';
import Record_Vehicle_Trip from './default/Record_Vehicle_Trip.json';

// Tracking
import Record_TempDriver_Tracking from './tracking/Record_TempDriver_Tracking.json';
import Record_Driver_Tracking from './tracking/Record_Driver_Tracking.json';
import Record_User_Tracking from './tracking/Record_User_Tracking.json';

export default {
  Record_TempDriver,
  Record_TempDriver_Trip,
  Record_Driver_Trip,
  Record_Group,
  Record_GroupAdm,
  Record_GroupAdm_Trip,
  Record_Group_Trip,
  Record_RequesterReport,
  Record_TcoEntryReport,
  Record_TcoTicketReport,
  Record_TcoRentReport,
  Record_TcoParkingReport,
  Record_TcoTollReport,
  Record_User,
  Record_UserAdm,
  Record_UserAdm_Trip,
  Record_User_Trip,
  Record_Vehicle,
  Record_VehicleAdm,
  Record_VehicleAdm_Trip,
  Record_Vehicle_Trip,
  // Tracking
  Record_TempDriver_Tracking,
  Record_Driver_Tracking,
  Record_User_Tracking,
};
