import angular from 'angular';

class NgTouchstartLink {
  constructor($scope, element, attr) {
    Object.assign(this, { $: element[0], $scope, attr });

    this.$.addEventListener('touchstart', evt => {
      $scope.$parent.$apply(() => {
        $scope.$parent.$eval(attr.ngTouchstart, { $event: evt });
      });
    });
  }
}

class NgTouchstart {
  static get $$ngIsClass() {
    return true;
  }

  constructor() {
    this.restrict = 'A';
    this.scope = {};
  }

  link($scope, element, attr) {
    return new NgTouchstartLink($scope, element, attr);
  }
}

angular.module('ng-touchstart', []).directive('ngTouchstart', NgTouchstart);
