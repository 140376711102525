import angular from 'angular';
import moment from 'moment/src/moment';

import { eventOnce } from '../../utils/event-once';
import template from './power-popup-event-medias.html';
import '../../directives/trust-src/trust-src';
import '../power-popup/power-popup';
import './power-popup-event-medias.scss';

class PowerPopupEventMediasController {
  static get $inject() {
    return ['$ngRedux', '$element', '$scope', '$rootScope'];
  }

  constructor($ngRedux, $element, $scope, $rootScope) {
    Object.assign(this, { $: $element[0], $ngRedux, $scope, $rootScope });

    this._appBehavior = $ngRedux.connect(behavior =>
      Object({
        isTrainingMode: behavior.session.trainingMode,
      }),
    )(this);

    this.mediaList = [];
    this.selectedMedia = {};
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
      viewMediaList: this.viewMediaList.bind(this),
    });
  }

  $onDestroy() {}

  /**/

  /** Public */
  async toggle() {
    const popupElement = this.$.querySelector('#power-popup-event-medias');
    await eventOnce({
      element: this.$,
      event: 'transitionend',
      trigger: () => popupElement.toggle(),
    });

    if (popupElement.hasAttribute('open')) {
      this.$.dispatchEvent(
        new CustomEvent('opened', {
          bubbles: true,
          composed: true,
        }),
      );
    } else {
      this.$.dispatchEvent(
        new CustomEvent('closed', {
          bubbles: true,
          composed: true,
        }),
      );
    }
  }

  viewMediaList(mediaList, event) {
    this.mediaList = mediaList.map((media, index) => ({
      ...media,
      _index: index,
      selected: index === 0,
      eventoGerador: event || media.eventoGerador,
      tipoMidia: media.tipoMidiaDescricao || media.tipoMidia,
    }));
    [this.selectedMedia] = this.mediaList;

    if (this.selectedMedia.image.hasError === false && !this.selectedMedia.midiaExpirada) {
      this.$.querySelector('#event-medias-popup-loader').toggle(true);

      if (this.selectedMedia.tipoMidia !== 'VIDEO') {
        const drawImageInterval = setInterval(() => {
          if (document.querySelector('#image-container').clientWidth) {
            this._drawImage(
              this.selectedMedia.image.isUrl
                ? this.selectedMedia.image.result
                : `data:image/jpeg;base64,${this.selectedMedia.image.result}`,
            );

            if (this.isTrainingMode) {
              const canvas = this.$.querySelector('#image-container > canvas');
              canvas.classList.add('blurred');
            }

            clearInterval(drawImageInterval);
          }
        }, 250);
      } else {
        this.$.querySelector('#media-player').addEventListener(
          'loadeddata',
          this.$.querySelector('#event-medias-popup-loader').toggle(false),
          { once: true },
        );
      }
    }

    const mediaDetailList = this.$.querySelector('#event-medias-popup-detail-list');
    if (mediaDetailList) mediaDetailList.scrollTop = 0;

    this.toggle();
  }
  /**/

  /** Private */
  _toDate(date) {
    return moment(date).utcOffset(-3 - moment(date).utcOffset() / 60)._d;
  }

  _changeMedia(index) {
    this.mediaList = this.mediaList.map(media => ({
      ...media,
      selected: media._index === index,
    }));
    this.selectedMedia = this.mediaList[index];

    if (this.selectedMedia.image.hasError === false) {
      this.$.querySelector('#event-medias-popup-loader').toggle(true);

      if (this.selectedMedia.tipoMidia !== 'VIDEO') {
        const drawImageInterval = setInterval(() => {
          if (document.querySelector('#image-container').clientWidth) {
            this._drawImage(
              this.selectedMedia.image.isUrl
                ? this.selectedMedia.image.result
                : `data:image/jpeg;base64,${this.selectedMedia.image.result}`,
            );

            if (this.isTrainingMode) {
              const canvas = this.$.querySelector('#image-container > canvas');
              canvas.classList.add('blurred');
            }

            clearInterval(drawImageInterval);
          }
        }, 250);
      } else {
        this.$.querySelector('#media-player').addEventListener(
          'loadeddata',
          this.$.querySelector('#event-medias-popup-loader').toggle(false),
          { once: true },
        );
      }
    }
  }

  _drawImage(url) {
    const canvas = document.createElement('canvas');
    const canvasContainer = this.$.querySelector('#image-container');

    if (canvasContainer.querySelector('canvas')) {
      canvasContainer.replaceChild(canvas, canvasContainer.querySelector('canvas'));
    } else {
      canvasContainer.appendChild(canvas);
    }

    Object.assign(canvas, {
      width: canvasContainer.clientWidth,
      height: canvasContainer.clientHeight,
    });

    const image = new Image();
    const ctx = canvas.getContext('2d');
    let lastX = canvas.width / 2;
    let lastY = canvas.height / 2;
    let dragStart;
    let dragged;

    const redraw = function redraw() {
      const p1 = ctx.transformedPoint(0, 0);
      const p2 = ctx.transformedPoint(canvas.width, canvas.height);
      ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);

      if (canvas.width > image.width) {
        ctx.drawImage(image, 0, 0);
      } else {
        ctx.drawImage(image, 0, 0, canvas.width, (canvas.width * image.height) / image.width);
      }

      ctx.save();
    };
    const zoom = function zoom(clicks) {
      const pt = ctx.transformedPoint(lastX, lastY);
      ctx.translate(pt.x, pt.y);
      // eslint-disable-next-line no-restricted-properties
      const factor = Math.pow(1.1, clicks);
      ctx.scale(factor, factor);
      ctx.translate(-pt.x, -pt.y);
      redraw();
    };
    const handleScroll = function handleScroll(evt) {
      const delta = evt.wheelDelta ? evt.wheelDelta / 40 : evt.detail ? -evt.detail : 0;
      if (delta) zoom(delta);
      return evt.preventDefault() && false;
    };
    const handleMouseUp = function handleMouseUp(evt) {
      dragStart = null;
      if (!dragged) zoom(evt.shiftKey ? -1 : 1);
    };
    const handleMouseDown = function handleMouseDown(evt) {
      // eslint-disable-next-line no-multi-assign
      document.body.style.userSelect = 'none';
      lastX = evt.offsetX || evt.pageX - canvas.offsetLeft;
      lastY = evt.offsetY || evt.pageY - canvas.offsetTop;
      dragStart = ctx.transformedPoint(lastX, lastY);
      dragged = false;
    };
    const handleMouseMove = function handleMouseMove(evt) {
      lastX = evt.offsetX || evt.pageX - canvas.offsetLeft;
      lastY = evt.offsetY || evt.pageY - canvas.offsetTop;
      dragged = true;
      if (dragStart) {
        const pt = ctx.transformedPoint(lastX, lastY);
        ctx.translate(pt.x - dragStart.x, pt.y - dragStart.y);
        redraw();
      }
    };
    // eslint-disable-next-line no-shadow
    const trackTransforms = function trackTransforms(ctx) {
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      const savedTransforms = [];
      let xform = svg.createSVGMatrix();
      ctx.getTransform = function () {
        return xform;
      };

      const { save } = ctx;
      ctx.save = function () {
        savedTransforms.push(xform.translate(0, 0));
        return save.call(ctx);
      };

      const { restore } = ctx;
      ctx.restore = function () {
        xform = savedTransforms.pop();
        return restore.call(ctx);
      };

      const { scale } = ctx;
      ctx.scale = function (sx, sy) {
        xform = xform.scaleNonUniform(sx, sy);
        return scale.call(ctx, sx, sy);
      };

      const { rotate } = ctx;
      ctx.rotate = function (radians) {
        xform = xform.rotate((radians * 180) / Math.PI);
        return rotate.call(ctx, radians);
      };

      const { translate } = ctx;
      ctx.translate = function (dx, dy) {
        xform = xform.translate(dx, dy);
        return translate.call(ctx, dx, dy);
      };

      const { transform } = ctx;
      ctx.transform = function (a, b, c, d, e, f) {
        const m2 = svg.createSVGMatrix();
        Object.assign(m2, { a, b, c, d, e, f });
        xform = xform.multiply(m2);
        return transform.call(ctx, a, b, c, d, e, f);
      };

      const { setTransform } = ctx;
      ctx.setTransform = function (a, b, c, d, e, f) {
        Object.assign(xform, { a, b, c, d, e, f });
        return setTransform.call(ctx, a, b, c, d, e, f);
      };

      const pt = svg.createSVGPoint();
      ctx.transformedPoint = function (x, y) {
        Object.assign(pt, { x, y });
        return pt.matrixTransform(xform.inverse());
      };
    };

    image.onload = () => {
      trackTransforms(ctx);
      redraw();
      setTimeout(() => zoom(0));
      this.$.querySelector('#event-medias-popup-loader').toggle(false);
    };

    image.src = url;

    canvas.addEventListener('mousedown', handleMouseDown, false);
    canvas.addEventListener('mousemove', handleMouseMove, false);
    canvas.addEventListener('mouseup', handleMouseUp, false);

    canvas.addEventListener('DOMMouseScroll', handleScroll, false);
    canvas.addEventListener('mousewheel', handleScroll, false);
  }

  _requestUpdate() {
    if (!this.$rootScope.$$phase && !this.$scope.$$phase) {
      this.$scope.$apply();
    }
  }
  /**/
}

class PowerPopupEventMedias {
  constructor() {
    this.template = template;
    this.bindings = {
      parameters: '=?',
    };
    this.controller = PowerPopupEventMediasController;
  }
}
angular
  .module('power-popup-event-medias', ['power-popup', 'trust-src'])
  .component('powerPopupEventMedias', new PowerPopupEventMedias());
