/* eslint new-cap: ["error", { "newIsCap": false }] */
import angular from 'angular';
import moment from 'moment/src/moment';
import 'ng-redux';

import '../power-fab/power-fab';
import '../power-toast/power-toast';
import { PowerReportController } from '../power-report/power-report';
import { ReportStateConfig } from '../power-state-config/power-state-config';
import '../../directives/infinite-scroll/infinite-scroll';
import '../../directives/ng-tippy/ng-tippy';

import template from './power-report-fleet-policy.html';
import './power-report-fleet-policy.scss';

class PowerReportFleetPolicyController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
      '$sce',
      '$rootScope',
    ];
  }

  /**
   * Creates an instance of PowerReportFleetPolicyController.
   * @memberof PowerReportFleetPolicyController
   */
  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
    $sce,
    $rootScope,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    Object.assign(this, { $sce, $rootScope, $scope });

    this.__appInheritBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Storage */
        state: behavior.state,
      }),
    )(this);

    this.dataset = [];
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();

    Object.assign(this.$, {
      requestDataset: this.requestDataset.bind(this),
    });

    this.stateConfig.gridConfig.page = 1;
    this.stateConfig.gridConfig.pageSize = 10;

    this.$ngRedux.dispatch({ type: 'UPDATE_STATE' });

    this._getConfigurations();
  }

  requestDataset() {
    this.stateConfig.gridConfig.page = 1;
    this._requestDataset();
  }

  _requestDataset() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this.$http({
      url: `${this.urlApi}/FleetPolicyAdm/GetFleetPolicies`,
      method: 'POST',
      data: {
        request: {
          filter: {
            conditions: this.stateConfig.filterConditions,
          },
          isPaginated: this.stateConfig.gridConfig.backPagination,
          page: this.stateConfig.gridConfig.page - 1,
          length: this.stateConfig.gridConfig.pageSize,
        },
      },
    }).then(success => {
      const { data } = success.data;

      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: false },
          bubbles: true,
          composed: true,
        }),
      );

      if (success.status && success.status !== 200) {
        this.$.querySelector('#report-fleet-policy-toast').toggle(true);
        Object.assign(this, { toast: { text: data.msg } });
        return;
      }

      Object.assign(this.stateConfig.gridConfig, {
        gridTotal: data.total,
        lastPage: this._getGridMaxPage(data.total, this.stateConfig.gridConfig.pageSize),
        gridDataset: data,
      });

      this.dataset = data.data;
    });
  }

  _changePage(page) {
    if (page >= 0) this.stateConfig.gridConfig.page = page;
    if (this.stateConfig.gridConfig.backPagination) this._requestDataset();
  }

  _changePageSize(pageSize) {
    Object.assign(this.stateConfig.gridConfig, {
      page: 1,
      pageSize,
    });

    if (this.stateConfig.gridConfig.backPagination) this._requestDataset();
  }

  _getGridMaxPage(gridTotal, pageSize) {
    const pagesBySize = gridTotal / pageSize;
    const intPagesBySize = parseInt(pagesBySize, 10);
    return pagesBySize > intPagesBySize ? intPagesBySize + 1 : intPagesBySize;
  }

  _getHistory(policy) {
    const { id } = policy;

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: false },
        bubbles: true,
        composed: true,
      }),
    );

    this.$http({
      url: `${this.urlApi}/FleetPolicyAdm/GetFleetPolicyHistoric`,
      method: 'POST',
      data: {
        request: {
          id,
        },
      },
    }).then(success => {
      const { data } = success.data;

      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: false },
          bubbles: true,
          composed: true,
        }),
      );

      if (success.status && success.status !== 200) {
        this.$.querySelector('#report-fleet-policy-toast').toggle(true);
        Object.assign(this, { toast: { text: data.msg } });
        return;
      }

      this.historyPopup = {
        open: true,
        title: policy.name,
        icon: 'gavel',
        list: data.data,
      };

      this._toogleHistoryModal();
    });
  }

  _deleteFleetPolicy(policy) {
    if (policy.isDefault || policy.totalVehicles > 0) {
      this.$.querySelector('#report-fleet-policy-toast').toggle(true);
      Object.assign(this, {
        toast: {
          text: 'A política de frota não pode ser excluída, verifique se há veículos nela ou se é a política de frota padrão.',
        },
      });
    } else {
      this.$http({
        url: `${this.urlApi}/FleetPolicyAdm/DeleteFleetPolicy`,
        method: 'POST',
        data: {
          request: {
            id: policy.id,
          },
        },
      })
        .then(success => {
          const { data } = success.data;

          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          );

          if (!success.data) {
            this.$.querySelector('#report-fleet-policy-toast').toggle(true);
            Object.assign(this, { toast: { text: data } });
            return;
          }

          this.$.querySelector('#report-fleet-policy-toast').toggle(true);
          Object.assign(this, { toast: { text: 'Política de frota excluída' } });

          this._requestDataset();
        })
        .catch(error => {
          this.$.querySelector('#report-fleet-policy-toast').toggle(true);
          Object.assign(this, { toast: { text: error.data.data[0] } });
        });
    }
  }

  _setAsDefault(policy) {
    this.$http({
      url: `${this.urlApi}/FleetPolicyAdm/UpdateFleetPolicyDefault`,
      method: 'POST',
      data: {
        request: {
          id: policy.id,
        },
      },
    }).then(success => {
      const { data } = success.data;

      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: false },
          bubbles: true,
          composed: true,
        }),
      );

      if ((success.status && success.status !== 200) || !success.data) {
        this.$.querySelector('#report-fleet-policy-toast').toggle(true);
        Object.assign(this, { toast: { text: data.msg } });
        return;
      }

      this.$.querySelector('#report-fleet-policy-toast').toggle(true);
      Object.assign(this, {
        toast: { text: 'Política de frota definida como padrão' },
      });

      this._requestDataset();
    });
  }

  _historyDescription(item) {
    let { description } = item;

    if (item.objectId == 'period') {
      const parsedItem = { periodValue: item.value };
      description = this._generateHoursDescription(parsedItem);
    }

    return description;
  }

  _getConfigurations() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this._getStateConfig();

    this.$scope.$on('getDatasetReady', () => {
      this._requestDataset();
    });
  }

  _computeBitArray(weekdayIndex, value, policy) {
    const bitArray = [];
    for (let bitIndex = 0; bitIndex <= policy.frontPeriod.numberOfPeriods; bitIndex += 1) {
      // eslint-disable-next-line no-restricted-properties
      const indexValue = Math.pow(2, policy.frontPeriod.numberOfPeriods - bitIndex);
      if (value >= indexValue) {
        value -= indexValue;
        bitArray[bitIndex] = 1;
        bitArray[bitIndex - 1] = 1;
      } else bitArray[bitIndex] = 0;
    }
    policy.frontPeriod.periodMap[weekdayIndex].bitArray = Object.assign([], bitArray);
  }

  _toogleHistoryModal() {
    this.$.querySelector('#popup-history').toggle();
  }

  _computePeriod(weekdayIndex, policy) {
    let onPeriod = false;
    let openPeriodIndex = 0;
    const periods = policy.frontPeriod.periodMap[weekdayIndex].bitArray.reduce(
      (acc, periodBit, periodIndex, arr) => {
        if (!onPeriod && periodBit) {
          onPeriod = true;
          openPeriodIndex = periodIndex;
        } else if (onPeriod && !periodBit) {
          onPeriod = false;
          acc.push([openPeriodIndex, periodIndex - 1]);
        } else if (onPeriod && periodIndex == arr.length - 1) {
          onPeriod = false;
          acc.push([openPeriodIndex, periodIndex]);
        }
        return acc;
      },
      [],
    );
    policy.frontPeriod.periodMap[weekdayIndex].periods = !periods.length ? [[0, 0]] : periods;
  }

  _addFleetPolicy() {
    this.stateConfig.policyId = null;
    this.stateConfig.policyIdToCopy = null;

    this.$ngRedux.dispatch({
      type: 'NEXT_ROUTE',
      data: {
        routeName: 'Política',
        routeLink: 'fleetPolicyFormAdm',
        routeSubName: 'Formulário de Política',
        routeTail: null,
        stateConfig: {
          ...this.stateConfig,
          gridName: 'Formulário',
          filterConfig: [],
        },
      },
    });

    this.$state.go('fleetPolicyFormAdm');
  }

  _editFleetPolicy(policy) {
    this.stateConfig.policyId = policy.id;
    this.stateConfig.policyIdToCopy = null;

    this.$ngRedux.dispatch({
      type: 'NEXT_ROUTE',
      data: {
        routeName: 'Política',
        routeLink: 'fleetPolicyFormAdm',
        routeSubName: 'Formulário de Política',
        routeTail: null,
        stateConfig: {
          ...this.stateConfig,
          gridName: 'Formulário',
          filterConfig: [],
        },
      },
    });

    this.$state.go('fleetPolicyFormAdm');
  }

  _copyFleetPolicy(policy) {
    this.stateConfig.policyId = null;
    this.stateConfig.policyIdToCopy = policy.id;

    this.$ngRedux.dispatch({
      type: 'NEXT_ROUTE',
      data: {
        routeName: 'Política',
        routeLink: 'fleetPolicyFormAdm',
        routeSubName: 'Formulário de Política',
        routeTail: null,
        stateConfig: { ...this.stateConfig, gridName: 'Formulário' },
      },
    });

    this.$state.go('fleetPolicyFormAdm');
  }

  _drilldownIcons(id, type = []) {
    this.$ngRedux.dispatch({
      type: 'NEXT_ROUTE',
      data: {
        routeName: 'Status de Regra por Veículo',
        routeLink: 'fleetPolicyStatusAdm',
        routeTail: null,
        stateConfig: new ReportStateConfig({
          type: 'FleetPolicyStatus',
          isAdm: true,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'FleetPolicyStatusAdm',
          toolbarName: 'List',
          getDataMethod: 'FleetPolicyAdm/GetFleetPolicyVehicles',
          backPagination: false,
          getDataFilters: [
            { id: 1, default: [id] },
            { id: 2, default: [] },
            {
              id: 3,
              field: { primary: 'fleetPolicyItemStatus', secondary: 'fleetPolicyItemConfigs' },
              default: [type, []],
            },
          ],
        }),
      },
    });

    this.$state.go('fleetPolicyStatusAdm', {}, { reload: true });
  }

  _generateFleetPolicyHistoricDescription(policy) {
    const { updateUserName } = policy;
    const updateDate = new Date(policy.updateDate).toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo' });

    let description;

    if (updateUserName && updateDate) {
      description = `Política de frota modificada por ${updateUserName} em ${updateDate}`;
    } else if (updateUserName && !updateDate) {
      description = `Política de frota modificada por ${updateUserName}`;
    } else if (!updateUserName && updateDate) {
      description = `Política de frota modificada em ${updateDate}`;
    }

    return description;
  }

  _generateHoursDescription(policy) {
    policy.frontPeriod = {};
    policy.frontPeriod.period = policy.periodValue.split(',');
    policy.frontPeriod.numberOfPeriods = 48;
    policy.frontPeriod.periodMap = [
      {
        description: 'DOM',
        bitArray: [],
        periods: [[0, 0]],
        extensiveDescription: '',
      },
      {
        description: 'SEG',
        bitArray: [],
        periods: [[0, 0]],
        extensiveDescription: '',
      },
      {
        description: 'TER',
        bitArray: [],
        periods: [[0, 0]],
        extensiveDescription: '',
      },
      {
        description: 'QUA',
        bitArray: [],
        periods: [[0, 0]],
        extensiveDescription: '',
      },
      {
        description: 'QUI',
        bitArray: [],
        periods: [[0, 0]],
        extensiveDescription: '',
      },
      {
        description: 'SEX',
        bitArray: [],
        periods: [[0, 0]],
        extensiveDescription: '',
      },
      {
        description: 'SAB',
        bitArray: [],
        periods: [[0, 0]],
        extensiveDescription: '',
      },
    ];

    policy.frontPeriod.period.forEach((value, index) => {
      this._computeBitArray(index, value, policy);
      this._computePeriod(index, policy);
    });

    let hours = '';
    let count = 0;
    let periodCount = 0;

    policy.frontPeriod.periodMap.forEach(item => {
      item.bitArray.forEach((bitItem, index, bitArray) => {
        if (bitItem > 0) {
          count += 1;

          if (bitArray[index + 1] == 0 || bitArray[index + 1] == undefined) {
            periodCount += 1;
          }
        }
      });
    });

    const countDiff = count - periodCount;

    if (policy.frontPeriod.numberOfPeriods > 24 && count > 0) {
      if ((countDiff / 2) % 1 == 0) {
        hours = `${parseInt(countDiff / 2, 10)} horas semanais`;
      } else {
        hours = `${parseInt(countDiff / 2, 10)} horas e 30 minutos semanais`;
      }
    } else {
      hours = `${count - periodCount} horas semanais`;
    }

    return hours;
  }

  _toDate(date) {
    return moment(date).utcOffset(-3 - moment(date).utcOffset() / 60)._d;
  }
}

class PowerReportFleetPolicy {
  /**
   * Creates an instance of ReportAlerts.
   * @memberof ReportAlerts
   */
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = PowerReportFleetPolicyController;
  }
}

angular
  .module('power-report-fleet-policy', [
    'ngRedux',
    'infinite-scroll',
    'ng-tippy',
    'power-fab',
    'power-toast',
  ])
  .component('powerReportFleetPolicy', new PowerReportFleetPolicy());

export { PowerReportFleetPolicyController };
