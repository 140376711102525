import angular from 'angular';

import '../power-addon-request/power-addon-request';
import '../power-popup/power-popup';
import '../power-toast/power-toast';

import template from './power-popup-addon-request.html';
import './power-popup-addon-request.scss';

class PowerPopupAddonRequestController {
  static get $inject() {
    return ['$element', '$scope', '$ngRedux', '$http', 'urlApi', 'addonServices'];
  }

  constructor($element, $scope, $ngRedux, $http, urlApi, addonServices) {
    Object.assign(this, { $: $element[0], $scope, $http, urlApi, addonServices });

    this.__appInheritBehavior = $ngRedux.connect(behavior =>
      Object({ session: behavior.session, state: behavior.state }),
    )(this);

    this.headerTitle = `Experimente o módulo ${this.state.routeList[0].stateConfig.serviceName}`;
    this.headerIcon = this.state.routeList[0].stateConfig.serviceIcon;
  }

  /**
   * Lifecycle
   */
  $onInit() {
    Object.assign(this.$, {
      tooglePopup: this.tooglePopup.bind(this),
      actionEventHandler: this.actionEventHandler.bind(this),
      showToast: this.showToast.bind(this),
    });
  }

  $onDestroy() {
    this.__appInheritBehavior();
  }
  /* Lifecycle */

  tooglePopup() {
    setTimeout(() => {
      this.$.querySelector('#power-addon-request-popup').toggle();
    });
  }

  actionEventHandler(action) {
    if (action == 'cancel') {
      this.tooglePopup();
    }
  }

  showToast(event) {
    Object.assign(this, {
      toast: {
        text: event.detail.message,
      },
    });
    this.$.querySelector('#power-popup-addon-request-toast').toggle(true);
  }
}

class PowerPopupAddonRequest {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = PowerPopupAddonRequestController;
  }
}

angular
  .module('power-popup-addon-request', [
    'power-popup',
    'wt.easy',
    'power-addon-request',
    'power-toast',
  ])
  .component('powerPopupAddonRequest', new PowerPopupAddonRequest());

export { PowerPopupAddonRequestController };
