import angular from 'angular';
import 'ng-redux';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-toolbar/power-toolbar';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-grid/power-grid';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-popup-share/power-popup-share';
import '@power/power-components/components/power-popup-delete/power-popup-delete';
import '@power/power-components/components/power-toast/power-toast';
import { PowerReportController } from '@power/power-components/components/power-report/power-report';

import template from './golfleet-report-order-service.html';
import './golfleet-report-order-service.scss';

class GolfleetReportOrderServiceController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
      '$sce',
      '$rootScope',
    ];
  }

  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
    $sce,
    $rootScope,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    Object.assign(this, { $sce, $rootScope });

    this.__appInheritBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Storage */
        isSingleSignon: behavior.session.isSingleSignon,
        session: behavior.session,
      }),
    )(this);

    this.toastObj = {
      text: '',
    };
  }

  $onInit() {
    super.$onInit();

    this.stateConfig.gridConfig.page = 0;

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this.$scope.$on('showToast', (evt, data) => {
      this.$.querySelector('power-toast#report-toast').toggle(true);
      this.toastObj = { text: data.text };
    });
  }

  $onDestroy() {
    this.__appInheritBehavior();
  }

  _callAction(action) {
    if (action.actionType === 'add' && action.type === 'OrderService') {
      this._addOrderService();
    } else {
      super._callAction(action);
    }
  }

  _addOrderService() {
    this.$ngRedux.dispatch({
      type: 'NEXT_ROUTE',
      data: {
        routeName: 'Ordem de Serviço',
        routeLink: 'orderServiceForm',
        routeTail: null,
        stateConfig: { ...this.stateConfig, gridName: 'Formulário', filterConfig: [] },
      },
    });

    this.$state.go('orderServiceForm');
  }
}

class GolfleetReportOrderService {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetReportOrderServiceController;
  }
}

angular
  .module('golfleet-report-order-service', [
    'ngRedux',
    'power-fab',
    'power-toolbar',
    'power-dropdown',
    'power-grid',
    'power-footer',
    'power-pagination',
    'power-popup',
    'power-popup-share',
    'power-popup-delete',
    'power-toast',
  ])
  .component('golfleetReportOrderService', new GolfleetReportOrderService());
