import angular from 'angular';
import '@power/power-components/helpers/is-iframe/is-iframe';

import { GolfleetMapRevisionHistoryHereProvider } from './providers/here/golfleet-map-revision-history.here-provider';
import { GolfleetMapRevisionHistoryLeafletProvider } from './providers/leaflet/golfleet-map-revision-history.leaflet-provider';
import { PowerMapController } from '@power/power-components/components/power-map/power-map';

import template from './golfleet-map-revision-history.html';

class GolfleetMapRevisionHistoryController extends PowerMapController {
  static get $inject() {
    return ['$element', '$ngRedux', '$scope', '$http', 'urlApi'];
  }

  constructor($element, $ngRedux, $scope, $http, urlApi) {
    super($element, $ngRedux, $scope, $http, urlApi);

    if (this.providerName === 'HERE') {
      this.provider = new GolfleetMapRevisionHistoryHereProvider(
        this,
        $element,
        $ngRedux,
        $scope,
        $http,
        urlApi,
      );
    } else {
      // this.providerName === 'LEAFLET'
      this.provider = new GolfleetMapRevisionHistoryLeafletProvider(
        this,
        $element,
        $ngRedux,
        $scope,
        $http,
        urlApi,
      );
    }
  }

  /* Lifecycle */
  /**/

  /* Public */
  async renderDataset({
    dataset,
    layerName = 'default',
    type = 'FeatureGroup',
    useCluster,
    clusterColor = '#D60F2C',
    icon,
  }) {
    return this.provider.renderDataset({
      dataset,
      layerName,
      type,
      useCluster,
      clusterColor,
      icon,
    });
  }
  /**/

  /* Private */
  /**/

  /* Observers */
  /**/
}

class GolfleetMapRevisionHistory {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetMapRevisionHistoryController;
  }
}

angular
  .module('golfleet-map-revision-history', ['power-map'])
  .component('golfleetMapRevisionHistory', new GolfleetMapRevisionHistory());
