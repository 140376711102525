import angular from 'angular';

import template from './power-toast.html';
import './power-toast.scss';

class PowerToastController {
  static get $inject() {
    return ['$element', '$rootScope', '$scope', '$timeout'];
  }

  constructor($element, $rootScope, $scope, $timeout) {
    Object.assign(this, { $: $element[0], $rootScope, $scope, $timeout });
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, { toggle: this.toggle.bind(this) });

    if (!this.open) this.open = false;

    if (!this.positionX) this.positionX = 'right';
    this.$.classList.add(`toast-${this.positionX}`);

    if (!this.positionY) this.positionY = 'bottom';
    this.$.classList.add(`toast-${this.positionY}`);

    if (this.open) this._openAnimation();
  }

  $onDestroy() {
    this.$.removeAttribute('open');
  }
  /* */

  /* Public */
  toggle(show) {
    if (show) {
      this.$.setAttribute('open', '');
      this._openAnimation();
    } else {
      this.$.removeAttribute('open');
      this._closeAnimation();
    }
    this.open = show;
  }
  /* */

  /* Private */
  _openAnimation() {
    if (!this.$.style.transition)
      Object.assign(this.$.style, {
        transition: 'transform 0.5s cubic-bezier(0.49, -0.89, 0.46, 2)',
      });
    if (this.positionY === 'bottom')
      Object.assign(this.$.style, {
        transform: `translateY(-${this.translateY || 66}px)`,
      });
    else
      Object.assign(this.$.style, {
        transform: `translateY(${this.translateY || 66}px)`,
      });
    if (this.timeout)
      this.toastTimeout = this.$timeout(() => {
        this.toggle(false);
        if (this.onTimeout) this.onTimeout({});
        if (!this.$scope.$$phase && !this.$rootScope.$$phase) this.$scope.$apply();
      }, this.timeout);
  }

  _closeAnimation() {
    if (this.positionY === 'bottom')
      Object.assign(this.$.style, {
        transform: `translateY(${this.translateY || 66}px)`,
      });
    else
      Object.assign(this.$.style, {
        transform: `translateY(-${this.translateY || 66}px)`,
      });
  }
  /* */

  /* Protected */
  __closeToast() {
    this.$timeout.cancel(this.toastTimeout);
    if (this.reaction) this.reaction({});
    this.toggle(false);
  }
  /* */
}

class PowerToast {
  constructor() {
    this.template = template;
    this.bindings = {
      open: '=?',
      positionX: '=?',
      positionY: '=?',
      translateY: '=?',
      timeout: '=?',
      onTimeout: '&?',
      reaction: '&?',
    };
    this.transclude = {
      text: '?textSlot',
      action: '?actionSlot',
    };
    this.controller = PowerToastController;
  }
}

angular.module('power-toast', []).component('powerToast', new PowerToast());
