import angular from 'angular';

import template from './power-popup-share.html';
import './power-popup-share.scss';

class PowerPopupShareController {
  static get $inject() {
    return ['$element', '$http', 'urlApi'];
  }

  constructor($element, $http, urlApi) {
    Object.assign(this, { $: $element[0], $http, urlApi });

    this.toId = null;
    this.method = '';
    this.objects = [];
    this.shareList = [];
    this.searchText = '';
  }

  // #region Lifecycle
  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
      setData: this.setData.bind(this),
    });
  }

  $onDestroy() {}
  // #endregion Lifecycle

  // #region Public
  toggle() {
    if (!this.$.hasAttribute('open')) {
      this._getShareList();
      this.$.setAttribute('open', '');
    } else this.$.removeAttribute('open');
  }

  setData(data) {
    Object.assign(this, data);
  }
  // #endregion Public

  // #region Private
  _getShareList() {
    return this.$http({
      url: `${this.urlApi}/${this.getMethod}`,
      method: 'POST',
    }).then(result =>
      Object.assign(this, {
        shareList: result.data.data,
      }),
    );
  }

  _selectItem(item) {
    this.shareList = this.shareList.map(ele => Object.assign(ele, { selected: ele.id == item.id }));
  }

  _getSelectedItem() {
    return this.shareList.filter(ele => ele.selected)[0];
  }

  _share() {
    this.searchText = '';
    this.$.dispatchEvent(
      new CustomEvent('share', {
        detail: {
          toId: this._getSelectedItem().value,
          type: this.type,
          method: this.shareMethod,
          objects: this.objects,
        },
        bubbles: true,
        composed: true,
      }),
    );
  }
  // #endregion Private
}

class PowerPopupShare {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = PowerPopupShareController;
  }
}

angular
  .module('power-popup-share', [])
  .component('powerPopupShare', new PowerPopupShare());
