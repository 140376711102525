import * as Comlink from 'comlink';

import '../../directives/ng-resize/ng-resize';
import { PowerReportController } from '../power-report/power-report';
import { ReportStateConfig } from '../power-state-config/power-state-config';

import './power-report-vehicles.scss';

class PowerReportVehiclesController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$rootScope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  constructor(
    $element,
    $scope,
    $rootScope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    Object.assign(this, { $rootScope });

    const checkIsAdm = behavior =>
      behavior.state.routeList.length > 0
        ? behavior.state.routeList[behavior.state.routeList.length - 1].stateConfig?.isAdm
        : false;

    this.__appInheritBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Storage */
        isAdm: checkIsAdm(behavior),
        modules: behavior.session.modules,
      }),
    )(this);

    this.tabs = [
      { name: 'hours', label: 'Horário', selected: true },
      { name: 'rules', label: 'Regras' },
    ];

    this.fleetPolicyData = {};

    this.legendList = [];
    this.mapModeList = [
      {
        type: 'all',
        description: 'Todos os Pontos',
        selected: true,
      },
      {
        type: 'heat',
        description: 'Mapa de Calor',
        selected: false,
      },
      {
        type: 'step',
        description: 'Ponto a ponto',
        selected: false,
        step: 0,
      },
    ];
    this.mapLayerList = [
      {
        id: 0,
        description: 'Áreas de Controle',
        method: 'GeoFence/Post',
        lastMapBounds: null,
        selected: true,
        type: 'FeatureGroup',
      },
      {
        id: 1,
        description: 'Pontos de Interesse',
        method: 'PointsOfInterest/Post',
        lastMapBounds: null,
        selected: true,
        type: 'MarkerFeatureGroup',
      },
    ];
    this.legacyEquipaments = {
      status: 0, // 0. loading, 1. ok, 2. no data, 3. error
      list: [],
      count: 0,
      highlight: true,
    };
    this.msgLegacyEquipament = ' equipamentos incompatíveis não constam nesta listagem';
    this.selectedVehicle = null;
    this.updateMapDataset = false;

    this.cantMoveObjectList = [];
    this.moveObjectList = [];

    this.coordinatesMap = null;
    this.zoomMap = null;
    this.firstRendering = true;

    this.isMapAutoRefreshStopped = true;
    this.msgIntervalMapView = null;
    this.intervalMapView = null;

    this.worker = new Worker('./power-report-vehicles.worker.js');
    this.workerService = Comlink.wrap(this.worker);
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();

    this.$.addEventListener('mapLoaded', () =>
      this.$.querySelector('#report-body-map')
        .getMap()
        .onMoveEnd(() =>
          this.mapLayerList.map(mapLayer => {
            if (mapLayer.selected) this._toggleMapLayer({ ...mapLayer, selected: false });

            return mapLayer;
          }),
        ),
    );

    this.$scope.$on('actionRequest', this._onActionRequest.bind(this));
    this.$scope.$on('actionFullscreen', this._onActionFullscreen.bind(this));
    this.$scope.$on('onEnterFullscreen', this._onEnterFullscreen.bind(this));
    this.$scope.$on('onExitFullscreen', this._onExitFullscreen.bind(this));
    this.$scope.$on('requestShowToast', this._onRequestShowToast.bind(this));
    this.$scope.$on('loadDatasetComplete', this._getLegacyEquipamentsData.bind(this));

    this.$scope.$watch(() => this.reportDataset, this.__reportDatasetChanged.bind(this));
  }

  $onDestroy() {
    this.worker.terminate();
    clearInterval(this.intervalMapView);
  }
  /* */

  /* Public */
  changeView(viewMode) {
    this.stateConfig.viewMode = viewMode;
    this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });

    switch (viewMode) {
      case 'map':
        this.coordinatesMap = null;
        this.zoomMap = 4;
        if (this.$.querySelector('#report-body-map').getMap) {
          if (this.updateMapDataset) {
            this.updateMapDataset = false;
            this._selectMapMode(Object.assign(this._getSelectedMapMode(), { selected: false }));
          }
          this._adjustMap();
        }

        this.$.setAttribute('map-view', '');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('split-view');

        break;
      case 'split':
        this.coordinatesMap = null;
        this.zoomMap = 4;
        if (this.$.querySelector('#report-body-map').getMap) {
          if (this.updateMapDataset) {
            this.updateMapDataset = false;
            this._selectMapMode(Object.assign(this._getSelectedMapMode(), { selected: false }));
          }
          this._adjustMap();
        }
        this.$.setAttribute('split-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('grid-view');

        break;
      default:
        this.$.setAttribute('grid-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('split-view');

        break;
    }

    const selectedMapMode = this.mapModeList.find(mode => mode.selected);
    this._applyAutoRefreshIfNecessary(viewMode, selectedMapMode.type);
  }

  changePage(page) {
    super.changePage(page);

    const gridElement = this.$.querySelector('#report-body-grid');
    const step = this.stateConfig.gridConfig.pageSize * (page - 1);

    gridElement.setActiveRow({ index: step });

    if (this._getSelectedMapMode().type == 'step') {
      this._renderStepMode(step);
    } else {
      this.mapModeList = this.mapModeList.map(mode =>
        mode.type == 'step' ? { ...mode, step } : mode,
      );
    }
  }

  changeRow(page, row) {
    if (page != this.stateConfig.gridConfig.page) {
      this.changePage(page);
    }

    if (row >= 0) {
      this._renderStepMode(row);
    }
  }

  changePageSize(pageSize) {
    super.changePageSize(pageSize);

    const step = 0;

    if (this._getSelectedMapMode().type == 'step') {
      this._renderStepMode(step);
    } else {
      this.mapModeList = this.mapModeList.map(mode =>
        mode.type == 'step' ? { ...mode, step } : mode,
      );
    }
  }

  evtClickControlPopupLegacyEquipament(method) {
    this.legacyEquipament.open = method != 'close';
    if (this.legacyEquipament.open)
      this.commonServices.clickCountLegacyEquipaments().then(success => {
        this.legacyEquipament.equipaments.highlight =
          success.data && !success.data.hasError ? success.data.data : false;
      });
  }
  /* */

  /* Private */
  _evtClickPopupLegacyEquipament() {
    this.$.querySelector('#popup-legacy-equipament').toggle();
  }

  _goToLink(evt, evtParams) {
    if (evtParams.routeLink == 'map') {
      if (this._getSelectedMapMode().type != 'step') {
        this._selectMapMode(this.mapModeList.filter(mode => mode.type == 'step')[0]);
      }

      if (this.stateConfig.viewMode != 'map' && this.stateConfig.viewMode != 'split') {
        if (window.innerWidth > 878) this.changeView('split');
        else this.changeView('map');
      } else {
        this.$.querySelector('#report-body-map').zoomTo(evtParams.tableRowData);
      }

      const gridElement = this.$.querySelector('#report-body-grid');

      gridElement.setActiveRow({ index: evtParams.index }).then(() => {
        this.selectedVehicle = gridElement.getActiveRow();
      });
    } else {
      super._goToLink(evt, evtParams);
    }
  }

  async _selectMapMode(mapMode) {
    if (mapMode.selected) return;
    this.mapModeList = this.mapModeList.map(mode => ({
      ...mode,
      selected: mode.type === mapMode.type,
    }));
    switch (mapMode.type) {
      case 'all':
        this.$.querySelector('#report-body-map').removeLayers(['heatLayer', 'stepMarker']);
        await this._renderAllMode(this.reportDataset);
        break;
      case 'heat':
        this.$.querySelector('#report-body-map').removeLayers([
          'allOn',
          'allOff',
          'allIddle',
          'allInvalid',
          'allBlock',
          'stepMarker',
        ]);
        await this._renderHeatMode(this.reportDataset);
        break;
      case 'step':
        this.$.querySelector('#report-body-map').removeLayers([
          'allOn',
          'allOff',
          'allIddle',
          'allInvalid',
          'allBlock',
          'heatLayer',
        ]);
        this.changeRow(this.stateConfig.gridConfig.page, mapMode.step);
        break;
      default:
        break;
    }

    this._applyAutoRefreshIfNecessary(this.stateConfig.viewMode, mapMode.type);
  }

  async _renderAllMode(dataset) {
    this._showGlobalLoader(true);

    const mapComponent = this.$.querySelector('#report-body-map');

    await this.workerService.parseAllModeDataset({ dataset });
    const allModeDataset = await this.workerService.allModeDataset;

    await mapComponent.renderDataset({
      dataset: allModeDataset.on,
      layerName: 'allOn',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#4AAE4E',
    });

    await mapComponent.renderDataset({
      dataset: allModeDataset.iddle,
      layerName: 'allIddle',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#DECE00',
    });

    await mapComponent.renderDataset({
      dataset: allModeDataset.off,
      layerName: 'allOff',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#980A1A',
    });

    await mapComponent.renderDataset({
      dataset: allModeDataset.block,
      layerName: 'allBlock',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#000',
    });

    await mapComponent.renderDataset({
      dataset: allModeDataset.invalid,
      layerName: 'allInvalid',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#949494',
    });

    mapComponent.resizeMap();

    mapComponent.fitLayers(['allOn', 'allIddle', 'allOff', 'allBlock', 'allInvalid']);

    this._showGlobalLoader(false);
  }

  async _renderHeatMode(dataset) {
    const mapComponent = this.$.querySelector('#report-body-map');

    await mapComponent.renderDataset({ dataset, layerName: 'heatLayer', type: 'HeatLayer' });
    mapComponent.resizeMap();
    mapComponent.fitLayers([], 'heatLayer');
  }

  _drilldownIcons(id, type = [], config = []) {
    const stateConfig = new ReportStateConfig({
      type: 'FleetPolicyStatus',
      isAdm: this.isAdm,
      viewMode: 'grid',
      gridName: 'List',
      screenName: `FleetPolicyStatusAdm`,
      toolbarName: 'List',
      getDataMethod: `FleetPolicyAdm/GetFleetPolicyVehicles`,
      backPagination: false,
      getDataFilters: [
        { id: 1, default: [id] },
        { id: 2, default: [] },
        {
          id: 3,
          field: { primary: 'fleetPolicyItemStatus', secondary: 'fleetPolicyItemConfigs' },
          default: [type, config],
        },
      ],
    });

    this.$ngRedux.dispatch({
      type: 'NEXT_ROUTE',
      data: {
        routeName: 'Status de Regra por Veículo',
        routeLink: `fleetPolicyStatusAdm`,
        routeTail: null,
        stateConfig,
      },
    });

    this.$state.go(`fleetPolicyStatusAdm`, {}, { reload: true });
  }

  async _renderStepMode(index) {
    const mapComponent = this.$.querySelector('#report-body-map');
    this.mapModeList = this.mapModeList.map(mode =>
      mode.type == 'step' ? { ...mode, step: index } : mode,
    );
    const dataPosition = this.reportDataset[index];
    this.selectedVehicle = dataPosition;

    switch (dataPosition?.status) {
      case 1:
        Object.assign(dataPosition, {
          color: '#4AAE4E',
          icon: dataPosition.icon || 'directions_car',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 2:
        Object.assign(dataPosition, {
          color: '#DECE00',
          icon: dataPosition.icon || 'directions_car',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 3:
        Object.assign(dataPosition, {
          color: '#980A1A',
          icon: dataPosition.icon || 'directions_car',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 6:
        Object.assign(dataPosition, {
          color: '#4AAE4E',
          icon: 'lock_open',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 7:
        Object.assign(dataPosition, {
          color: '#DECE00',
          icon: 'lock_open',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 8:
        Object.assign(dataPosition, {
          color: '#980A1A',
          icon: 'lock_open',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 9:
        Object.assign(dataPosition, {
          color: '#4AAE4E',
          icon: 'lock',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 10:
        Object.assign(dataPosition, {
          color: '#DECE00',
          icon: 'lock',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 11:
        Object.assign(dataPosition, {
          color: '#980A1A',
          icon: 'lock',
          markerIcon: 'MarkerIcon',
        });
        break;
      default:
        Object.assign(dataPosition, {
          color: '#949494',
          icon: dataPosition.icon || 'directions_car',
          markerIcon: 'MarkerIcon',
        });
        break;
    }

    await mapComponent.renderDataset({
      dataset: [dataPosition],
      layerName: 'stepMarker',
      type: 'MarkerFeatureGroup',
    });

    mapComponent.fitLayers(['stepMarker']);
    mapComponent.openPopup(0, 'stepMarker');
  }

  _getLegacyEquipamentsData() {
    if (this.watchFiltersInterval) {
      clearInterval(this.watchFiltersInterval);
    }

    this.watchFiltersInterval = setInterval(() => {
      const filterList = this.currentState.stateConfig.filterConfig.map(filter => ({ ...filter }));

      const readyFilterList = filterList.filter(filter => {
        const filterElement = this.$.querySelector(`[data-id="${filter.id}"]`);

        return !!filterElement && filterElement.loading !== undefined
          ? filterElement.loading === false && !!filter.condition
          : !!filter.condition;
      });

      if (readyFilterList.length == filterList.length) {
        const filterEquipaments = this.stateConfig.filterConditions.filter(
          condition => condition.field === 'vehicles' || condition.field === 'driver',
        )[0];
        if (
          filterEquipaments &&
          (filterEquipaments.value.length === 0 ||
            filterEquipaments.value.length === filterEquipaments.total)
        ) {
          this.commonServices
            .getLegacyEquipaments(
              `LegacyEquipaments${this.isAdm ? 'Adm' : ''}/GetLegacyEquipaments`,
            )
            .then(
              success => {
                if (success.data?.hasError) this.legacyEquipaments.status = 3;
                else if (success.data.data && success.data.data.total > 0) {
                  Object.assign(this.legacyEquipaments, {
                    list: success.data.data.equipaments,
                    count: success.data.data.total,
                    highlight: success.data.data.highlight,
                    status: 1,
                  });
                } else this.legacyEquipaments.status = 2;
                this._scopeApply();
              },
              () => {
                this.legacyEquipaments.status = 3;
                this._scopeApply();
              },
            );
        } else {
          this.legacyEquipaments.status = 0;
          this._scopeApply();
        }

        clearInterval(this.watchFiltersInterval);
      }
    }, 250);
  }

  _scopeApply() {
    if (this.$scope.$$phase === null && this.$rootScope.$$phase === null) {
      this.$scope.$apply();
    }
  }

  _getSelectedMapMode() {
    return this.mapModeList.filter(mode => mode.selected)[0];
  }

  _getStepPosition() {
    return this.mapModeList.filter(mode => mode.type == 'step')[0].step;
  }

  _formatLatLng(str) {
    return str.replace(',', '.');
  }

  _adjustMap() {
    const mapComponent = this.$.querySelector('#report-body-map');
    mapComponent.resizeMap();
    switch (this._getSelectedMapMode().type) {
      case 'all':
        if (this.coordinatesMap) {
          mapComponent.zoomTo(this.coordinatesMap, this.zoomMap);
        } else if (!this.firstRendering) {
          mapComponent.fitLayers(['allOn', 'allIddle', 'allOff', 'allBlock', 'allInvalid']);
        }
        break;
      case 'heat':
        mapComponent.fitLayers([], 'heatLayer');
        break;
      case 'step':
        mapComponent.fitLayers(['stepMarker']);
        mapComponent.openPopup(0, 'stepMarker');
        break;
      default:
        break;
    }
  }

  _actionFunction(option) {
    const json = {};
    const list = this.selectedRows.map(row => Object({ objectId: row.id }));
    const title = `${option.actionTitle.split('<item>').join(this.selectedRows[0].placa)} ${
      this.selectedRows.length === 1 ? `e outros ${this.selectedRows.length - 1}` : ''
    }`;
    const description =
      this.selectedRows.length === 1
        ? `O item foi ${option.actionText}`
        : `Os itens foram ${option.actionText}s`;

    if (option.actionType === 'share')
      return this.reportServices.callApiMethod(option.getDataMethod, json).then(
        success => {
          this.sharePopup = {
            open: true,
            title,
            icon: option.actionIcon,
            list: success.data.data,
            method: option.actionMethod,
            json: {
              type: this.stateConfig.type,
              objects: list,
            },
            text: description,
          };
        },
        () => {},
      );

    return false;
  }

  _callAction(action) {
    /* Chrome Locked-Scroll Workaround */
    const scrollWorkaround = () => {
      const scrollArea = this.$.querySelector('#popup-add .div-section-crud');
      if (scrollArea) {
        setTimeout(() => {
          scrollArea.style.overflow = 'auto';
        }, 500);
        setTimeout(() => {
          scrollArea.style.overflow = '';
        }, 600);
      }
    };

    switch (action.actionType) {
      case 'add':
        this.stateConfig.toolbarConfig.addConfig.dataset = null;
        this.$.querySelector('#popup-add').toggle();

        if (this.$.querySelector('#popup-add')) scrollWorkaround();

        break;
      case 'share':
        if (this.$.querySelector('power-popup-share')) scrollWorkaround();

        this.$.querySelector('power-popup-share').setData({
          type: this.stateConfig.type,
          getMethod: action.getDataMethod,
          shareMethod: action.actionMethod,
          objects: this.selectedRows.map(row => ({ objectId: row.id })),
        });
        this.$.querySelector('power-popup-share').toggle();
        break;
      case 'move':
        this.cantMoveObjectList = [];
        this.moveObjectList = [];

        Object.keys(this.selectedRows).reduce((acc, item) => {
          if (!this.selectedRows[item][action.validationField]) {
            this.cantMoveObjectList.push(this.selectedRows[item]);
          } else {
            this.moveObjectList.push(this.selectedRows[item]);
          }
          return acc;
        }, []);

        this.$.querySelector('power-popup-move-vehicles').setData({
          type: this.stateConfig.type,
          title: action.actionTitle,
          showPreviewItem: action.showPreviewItem,
          actionIcon: action.actionIcon,
          getMethod: action.getDataMethod,
          moveMethod: action.actionMethod,
          cantMoveTitle: action.cantMoveTitle.split('<item>').join(this.cantMoveObjectList.length),
          cantMoveList: this.cantMoveObjectList.map(row => ({
            description: row.placaComApelido,
            icon: row.icon || 'directions_car',
          })),
          objects: this.moveObjectList.map(row => ({ objectId: row.id })),
        });

        this.$.querySelector('power-popup-move-vehicles').toggle();
        break;
      case 'edit':
        break;
      case 'delete':
        this.$.querySelector('power-popup-delete').setData({
          deleteMethod: action.actionMethod,
          objects: this.selectedRows.map(row => ({
            id: row.id,
            description: row[this.stateConfig.gridConfig.descriptionField],
            value: row.id,
          })),
        });
        this.$.querySelector('power-popup-delete').toggle();
        break;
      default:
        break;
    }
  }

  _showGlobalLoader(state = false) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: state },
        bubbles: true,
        composed: true,
      }),
    );
  }

  _onActionRequest(evt, data) {
    this.reportServices.callApiMethod(data.method, data.json).then(
      () => {
        this.$.querySelector('power-toast#report-toast').toggle(true);
        Object.assign(this, { toastText: data.text });
        this.$timeout(() => this.$scope.$broadcast('getDataset'));
      },
      () => {},
    );
  }

  _getFleetPolicyIconTitle(status, amount) {
    let title = '';

    switch (status) {
      case 'broadcasting':
        title = `${amount} itens em processo de atualização`;
        break;

      case 'notHired':
        title = `${amount} itens de serviço não contratado`;
        break;

      case 'incompatible':
        title = `${amount} itens incompatíveis com uma ou mais regras`;
        break;

      default:
        break;
    }

    return title;
  }

  _onRequestShowToast(evt, evtParams) {
    this.$.querySelector('power-toast#report-toast').toggle(true);
    Object.assign(this, { toastText: evtParams.text });
  }

  _onActionFullscreen() {
    this.$timeout(() => this.$scope.$broadcast('getDataset'));
  }

  _onEnterFullscreen() {
    const selectedMapMode = this.mapModeList.find(mode => mode.selected);
    this._applyAutoRefreshIfNecessary(this.stateConfig.viewMode, selectedMapMode.type);
  }

  _onExitFullscreen() {
    const selectedMapMode = this.mapModeList.find(mode => mode.selected);
    this._applyAutoRefreshIfNecessary(this.stateConfig.viewMode, selectedMapMode.type);
  }

  _eventModalReadOnlyFleetPoliciesSwitch() {
    this.$.querySelector('#popup-readonly-fleet-policies').toggle();
  }

  _getSelectedTab() {
    return this.tabs.filter(item => item.selected)[0].name;
  }

  _selectTab(tab) {
    this.tabs.forEach(item => {
      item.selected = tab.name === item.name;
    });
  }

  _generateFleetPolicyHistoricDescription() {
    const { updateUserName } = this.fleetPolicyData;
    const updateDate = new Date(this.fleetPolicyData.updateDate).toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo' });

    let description;

    if (updateUserName && updateDate) {
      description = `Política de frota modificada por ${updateUserName} em ${updateDate}`;
    } else if (updateUserName && !updateDate) {
      description = `Política de frota modificada por ${updateUserName}`;
    } else if (!updateUserName && updateDate) {
      description = `Política de frota modificada em ${updateDate}`;
    }

    return description;
  }

  _getFleetPolicyData(fleetPolicy) {
    const { id } = fleetPolicy;

    this.fleetPolicyData = {};

    if (id) {
      this._showGlobalLoader(true);

      this.$http({
        url: `${this.urlApi}/FleetPolicy${this.isAdm ? 'Adm' : ''}/GetFleetPolicyDetail`,
        method: 'POST',
        data: {
          request: {
            id,
          },
        },
      }).then(success => {
        const { data } = success.data;
        let period = {};

        const items = data.items.reduce((acc, item) => {
          if (item.objectId == 'period') {
            item.items = item.value.split(',');
            period = item;
          } else {
            acc.push(item);
          }

          return acc;
        }, []);

        this.fleetPolicyData = {
          id,
          name: data.name,
          broadcastingItemsCount: data.broadcastingItemsCount,
          incompatibleItemsCount: data.incompatibleItemsCount,
          notHiredItemsCount: data.notHiredItemsCount,
          totalVehicles: data.totalVehicles,
          period,
          items,
          updateDate: data.updateDate,
          updateUserName: data.updateUserName,
        };

        if (success.status && success.status !== 200) {
          this.$.querySelector('power-toast#report-toast').toggle(true);
          Object.assign(this, { toastText: data.text });
          return;
        }

        if (this.fleetPolicyData.name) {
          this._showGlobalLoader(false);
          this._eventModalReadOnlyFleetPoliciesSwitch();
        }
      });
    }
  }

  _toggleMapLayer(mapLayer) {
    mapLayer.selected = !mapLayer.selected;
    this.mapLayerList = this.mapLayerList.map(layer =>
      layer.id == mapLayer.id ? mapLayer : layer,
    );
    if (mapLayer.selected) this._renderMapLayer(mapLayer.id);
    else {
      this.mapLayerList = this.mapLayerList.map(layer =>
        layer.id == mapLayer.id ? { ...layer, lastMapBounds: null } : layer,
      );
      this.$.querySelector('#report-body-map').removeLayers([mapLayer.description]);
    }
  }

  _renderMapLayer(mapLayerId) {
    const mapLayer = this.mapLayerList.filter(layer => layer.id == mapLayerId)[0];

    const mapComponent = this.$.querySelector('#report-body-map');
    const mapBounds = mapComponent.getBounds();

    if (!mapLayer.lastMapBounds || !mapLayer.lastMapBounds.containsRect(mapBounds)) {
      this.mapLayerList = this.mapLayerList.map(layer =>
        layer.id == mapLayerId ? { ...layer, lastMapBounds: mapBounds } : layer,
      );

      return this.$http({
        url: `${this.urlApi}/${mapLayer.method}`,
        method: 'POST',
        data: {
          request: {
            filter: {
              conditions: [
                {
                  id: -1,
                  field: 'bounds',
                  value: [mapBounds.asArray().join()],
                },
              ],
            },
            page: 0,
            length: 10,
            isPaginated: false,
            sort: { direction: 'asc', name: 'description' },
          },
        },
      }).then(success => {
        let dataset = success.data.data.data.map(data => ({
          ...data,
          color: data.color || data.categoryColor,
        }));
        dataset = mapComponent.sortDatasetByBounds(dataset);
        mapComponent.renderDataset({
          dataset: dataset.reverse(),
          layerName: mapLayer.description,
        });
      });
    }

    return null;
  }

  _emptyActions() {
    const { toolbarConfig } = this.stateConfig;
    if (
      !toolbarConfig ||
      !toolbarConfig.actionConfig ||
      toolbarConfig.length == 0 ||
      toolbarConfig.actionConfig.actions.length ===
        toolbarConfig.actionConfig.actions.filter(
          action => action.module && !this.modules.includes(action.module),
        )?.length
    ) {
      return true;
    }
    return false;
  }

  async _applyAutoRefreshIfNecessary(pageView, mapModeType) {
    const mapComponent = await this.$.querySelector('#report-body-map');

    if (pageView === 'map' && mapModeType === 'all' && !mapComponent.hasAttribute('fullscreen')) {
      if (this.isMapAutoRefreshStopped) {
        this.msgIntervalMapView = mapComponent.addMessageInfo('Carregamento automático...');

        this.intervalMapView = setInterval(
          () => {
            this.$timeout(() => this.$scope.$broadcast('getDataset'));
          },
          60 * 1000, // 60000 = 1 min (secs * miliseconds)
        );

        this.isMapAutoRefreshStopped = false;
      }
    } else if (this.intervalMapView) {
      if (this.msgIntervalMapView) {
        mapComponent.removeMessageInfo(this.msgIntervalMapView);
        this.msgIntervalMapView = null;
      }
      clearInterval(this.intervalMapView);
      this.intervalMapView = null;

      this.isMapAutoRefreshStopped = true;
    }
  }
  /* */

  /* Observers */
  async __reportDatasetChanged(newValue) {
    if (newValue) {
      this.legendList = this.stateConfig.gridConfig.gridHeaders.reduce(
        (acc, ele) => (ele.icons ? acc.concat(ele) : acc),
        [],
      );
      if (newValue.length > 0) {
        if (this.stateConfig.viewMode === 'grid') {
          this.updateMapDataset = true;
        } else {
          const mapMode = this.mapModeList.filter(mode => mode.type == 'all' && mode.selected);
          const mapComponent = this.$.querySelector('#report-body-map');
          if (mapComponent.hasAttribute('fullscreen') || this.stateConfig.viewMode == 'map') {
            this.zoomMap = mapComponent.getMap().getZoom();
            const { lat: latitude, lng: longitude } = mapComponent.getMap().getCenter();
            if (!this.firstRendering) {
              this.coordinatesMap = { latitude, longitude };
            } else {
              this.firstRendering = false;
            }

            if (mapMode.length > 0) {
              this._renderAllMode(this.reportDataset);
            } else {
              await this.workerService.findIndex({
                dataset: newValue,
                vehicleId: this.selectedVehicle.id,
                pageSize: this.stateConfig.gridConfig.pageSize,
              });
              this.changeRow(await this.workerService.page, await this.workerService.row);
            }
          } else {
            this._selectMapMode(Object.assign(this._getSelectedMapMode(), { selected: false }));
          }
        }
      } else {
        this.$.querySelector('#report-body-map').removeLayers([
          'allOn',
          'allOff',
          'allIddle',
          'allInvalid',
          'allBlock',
          'heatLayer',
          'stepMarker',
        ]);
      }
    }
  }

  __onMapExitFullScreen() {
    this.$.removeChild(this.msgInterval);
    clearInterval(this.intervalFullscreen);
    this.$.removeAttribute('fullscreen');
  }

  __onRequestSyncVisualization(evt) {
    super.__onRequestSyncVisualization(evt);

    const gridElement = this.$.querySelector('#report-body-grid');
    const activeRow = gridElement.getActiveRow();

    this._renderStepMode(activeRow._index);
  }
  /* */
}

export { PowerReportVehiclesController };
