import angular from 'angular';
import * as Comlink from 'comlink';
import 'angular-sanitize';
import 'ng-redux';

import '../power-period-preview/power-period-preview';
import '../power-popup/power-popup';
import '../../directives/ng-resize/ng-resize';
import { ReportStateConfig } from '../power-state-config/power-state-config';
import { PowerGridController } from '../power-grid/power-grid';

import template from './power-grid-fleet-policy-status.html';
import './power-grid-fleet-policy-status.scss';

class PowerGridFleetPolicyStatusController extends PowerGridController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$state',
      '$http',
      '$timeout',
      '$filter',
      'commonServices',
      'urlApi',
      '$ngRedux',
    ];
  }

  constructor(
    $element,
    $scope,
    $state,
    $http,
    $timeout,
    $filter,
    commonServices,
    urlApi,
    $ngRedux,
  ) {
    super($element, $scope, $state, $http, $timeout, $filter, commonServices, urlApi, $ngRedux);

    Object.assign(this, {
      $ngRedux,
    });

    const checkIsAdm = behavior =>
      behavior.state.routeList.length > 0
        ? behavior.state.routeList[behavior.state.routeList.length - 1].stateConfig?.isAdm
        : false;

    this.__appInheritBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Storage */
        isAdm: checkIsAdm(behavior),
      }),
    )(this);

    if (this.worker) this.worker.terminate();

    this.worker = new Worker('./power-grid-fleet-policy-status.worker.js');
    this.workerService = Comlink.wrap(this.worker);

    this.tabs = [
      { name: 'hours', label: 'Horário', selected: true, visible: true },
      { name: 'rules', label: 'Regras', visible: true },
    ];

    this.fleetPolicyData = {};
  }

  _selectRow(column, row) {
    return (
      (super._selectRow(column, row) || column.type === 'FleetPolicyModal') && !!row[column.field]
    );
  }

  _drilldownIcons(id, type = [], config = []) {
    const stateConfig = new ReportStateConfig({
      type: 'FleetPolicyStatus',
      isAdm: this.isAdm,
      viewMode: 'grid',
      gridName: 'List',
      screenName: `FleetPolicyStatus${this.isAdm ? 'Adm' : ''}`,
      toolbarName: 'List',
      getDataMethod: `FleetPolicy${this.isAdm ? 'Adm' : ''}/GetFleetPolicyVehicles`,
      backPagination: false,
      getDataFilters: this.isAdm
        ? [
            { id: 1, default: [id] },
            { id: 2, default: [] },
            {
              id: this.isAdm ? 3 : 2,
              activeView: 'secondary',
              field: { primary: 'fleetPolicyItemStatus', secondary: 'fleetPolicyItemConfigs' },
              default: [type, config],
            },
          ]
        : [
            { id: 1, default: [id] },
            {
              id: this.isAdm ? 3 : 2,
              activeView: 'secondary',
              field: { primary: 'fleetPolicyItemStatus', secondary: 'fleetPolicyItemConfigs' },
              default: [type, config],
            },
          ],
    });

    const routeLink = `fleetPolicyStatus${this.isAdm ? 'Adm' : ''}`;

    this.$ngRedux.dispatch({ type: 'CLEAR_ROUTE_LIST' });
    this.$ngRedux.dispatch({
      type: 'NEXT_ROUTE_SINGLE',
      data: {
        routeName: 'Status de Regra por Veículo',
        routeLink,
        routeTail: null,
        stateConfig,
      },
    });

    this.$state.go(`fleetPolicyStatus${this.isAdm ? 'Adm' : ''}`, {}, { reload: true });
  }

  _eventModalReadOnlyFleetPoliciesSwitch() {
    this.$.querySelector('#popup-readonly-fleet-policies').toggle();
  }

  _getSelectedTab() {
    return this.tabs.filter(item => item.selected)[0].name;
  }

  _selectTab(tab) {
    this.tabs.forEach(item => {
      item.selected = tab.name === item.name;
    });
  }

  _generateFleetPolicyHistoricDescription() {
    const { updateUserName } = this.fleetPolicyData;
    const updateDate = new Date(this.fleetPolicyData.updateDate).toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo' });

    let description;

    if (updateUserName && updateDate) {
      description = `Política de frota modificada por ${updateUserName} em ${updateDate}`;
    } else if (updateUserName && !updateDate) {
      description = `Política de frota modificada por ${updateUserName}`;
    } else if (!updateUserName && updateDate) {
      description = `Política de frota modificada em ${updateDate}`;
    }

    return description;
  }

  _getFleetPolicyIconTitle(status, amount) {
    let title = '';

    switch (status) {
      case 'broadcasting':
        title = `${amount} itens em processo de atualização`;
        break;

      case 'notHired':
        title = `${amount} itens de serviço não contratado`;
        break;

      case 'incompatible':
        title = `${amount} itens incompatíveis com uma ou mais regras`;
        break;

      default:
        break;
    }

    return title;
  }

  _getFleetPolicyData(row) {
    const id = row.fleetPolicyId;

    this.fleetPolicyData = {};

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    if (id) {
      this.$http({
        url: `${this.urlApi}/FleetPolicy${this.isAdm ? 'Adm' : ''}/GetFleetPolicyDetail`,
        method: 'POST',
        data: {
          request: {
            id,
          },
        },
      }).then(success => {
        const { data } = success.data;
        let period = {};

        const items = data.items.reduce((acc, item) => {
          if (item.objectId == 'period') {
            item.items = item.value.split(',');
            period = item;
          } else {
            acc.push(item);
          }

          return acc;
        }, []);

        this.fleetPolicyData = {
          id,
          name: data.name,
          broadcastingItemsCount: data.broadcastingItemsCount,
          incompatibleItemsCount: data.incompatibleItemsCount,
          notHiredItemsCount: data.notHiredItemsCount,
          totalVehicles: data.totalVehicles,
          period,
          items,
          updateDate: data.updateDate,
          updateUserName: data.updateUserName,
        };

        if (this._objectIsEmpty(period)) {
          this.tabs.find(tab => tab.name === 'hours').visible = false;
          this.tabs.find(tab => tab.name === 'hours').selected = false;
          this.tabs.find(tab => tab.name === 'rules').selected = true;
        }

        if (success.status && success.status !== 200) {
          this.$.querySelector('power-toast#report-toast').toggle(true);
          Object.assign(this, { toastText: data.text });
          return;
        }

        if (this.fleetPolicyData.name) {
          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          );
          this._eventModalReadOnlyFleetPoliciesSwitch();
        }
      });
    }
  }

  _objectIsEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
}

class PowerGridFleetPolicyStatus {
  constructor() {
    this.template = template;
    this.bindings = {
      /* common */
      datasetMethod: '=?',
      isPaginated: '=?',
      page: '=?',
      pageSize: '=?',
      pageRows: '=?',
      lastPage: '=?',
      datasetLength: '=?',
      hasRowSelection: '=?',
      dateGranularity: '=?',
      /* underscore */
      gridHeaders: '=?',
      gridDataset: '=?',
      gridHeadersCategories: '=?',
      sortHeader: '=?',
      sortDirection: '=?',
      selectedRows: '=?',
      /* duble underscore */
      mainHeader: '=?',
      mongoGridId: '=?',
      headerParams: '=?',
      datasetParams: '=?',
    };
    this.controller = PowerGridFleetPolicyStatusController;
  }
}

angular
  .module('power-grid-fleet-policy-status', [
    'ngSanitize',
    'ngRedux',
    'power-period-preview',
    'power-popup',
    'ng-resize',
  ])
  .component('powerGridFleetPolicyStatus', new PowerGridFleetPolicyStatus());

export { PowerGridFleetPolicyStatusController, PowerGridFleetPolicyStatus };
