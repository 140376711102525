import angular from 'angular';
import * as Comlink from 'comlink';
import '@power/power-components/directives/ng-resize/ng-resize';

import { PowerGridController } from '@power/power-components/components/power-grid/power-grid';
import template from './golfleet-grid-violation.html';
import './golfleet-grid-violation.scss';

class GolfleetGridViolationController extends PowerGridController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$state',
      '$http',
      '$timeout',
      '$filter',
      'commonServices',
      'urlApi',
      '$ngRedux',
    ];
  }

  constructor(
    $element,
    $scope,
    $state,
    $http,
    $timeout,
    $filter,
    commonServices,
    urlApi,
    $ngRedux,
  ) {
    super($element, $scope, $state, $http, $timeout, $filter, commonServices, urlApi, $ngRedux);
    Object.assign(this, {
      $: $element[0],
      $scope,
      $state,
      $http,
      $timeout,
      $filter,
      commonServices,
      urlApi,
    });

    if (this.worker) this.worker.terminate();
    this.worker = new Worker('./golfleet-grid-violation.worker.js');
    this.workerService = Comlink.wrap(this.worker);
  }
}

class GolfleetGridViolation {
  constructor() {
    this.template = template;
    this.bindings = {
      /* common */
      datasetMethod: '=?',
      isPaginated: '=?',
      page: '=?',
      pageSize: '=?',
      pageRows: '=?',
      lastPage: '=?',
      datasetLength: '=?',
      hasRowSelection: '=?',
      dateGranularity: '=?',
      /* underscore */
      gridHeaders: '=?',
      gridDataset: '=?',
      gridHeadersCategories: '=?',
      sortHeader: '=?',
      sortDirection: '=?',
      selectedRows: '=?',
      /* duble underscore */
      mainHeader: '=?',
      mongoGridId: '=?',
      headerParams: '=?',
      datasetParams: '=?',
      /* complement header title */
      replaceHeaderText: '=?',
      complementHeaderField: '=?',
      complementHeaderText: '=?',
    };
    this.controller = GolfleetGridViolationController;
  }
}

angular
  .module('golfleet-grid-violation', [])
  .component('golfleetGridViolation', new GolfleetGridViolation());

export { GolfleetGridViolationController, GolfleetGridViolation };
