/* eslint new-cap: ["error", { "newIsCap": false }] */
import 'ua-device-detector';
import 'ng-device-detector';

import { ImportRouteConfig } from '../../utils/get-route-config';

class PowerSwitchAccountController {
  static get $inject() {
    return [
      '$element',
      '$sce',
      '$ngRedux',
      '$rootScope',
      '$state',
      '$http',
      '$scope',
      'urlApi',
      '_applications',
      'switchAccountServices',
      'commonServices',
      'tipoApp',
    ];
  }

  /**
   * Creates an instance of PowerSwitchAccountController.
   * @memberof PowerSwitchAccountController
   */
  constructor(
    $element,
    $sce,
    $ngRedux,
    $rootScope,
    $state,
    $http,
    $scope,
    urlApi,
    _applications,
    switchAccountServices,
    commonServices,
    tipoApp,
  ) {
    Object.assign(this, {
      $: $element[0],
      $sce,
      $ngRedux,
      $rootScope,
      $state,
      $http,
      $scope,
      urlApi,
      _applications,
      switchAccountServices,
      commonServices,
      tipoApp,
    });

    this.__appInheritBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Storage */
        state: behavior.state,
        session: behavior.session,
      }),
    )(this);

    this.isInLogin = false;
    this.accounts = [];
    this.directLoginChecked = false;
    this.directLoginChanged = false;
    this.hasUserDefault = false;
    this.hasNewAnnouncement = false;
  }

  /* Lifecycle */
  $onInit() {
    this.$.addEventListener('getSwitchAccounts', this._getSwitchAccounts.bind(this));
  }

  _getSwitchAccounts(e) {
    this._toggleLoader(true);
    const applications = this._applications.map(app => app.id);

    this.switchAccountServices.getAccounts({ applications }).then(response => {
      if (response.status && response.status !== 200) {
        this._toggleLoader(false);
        if (this.isInLogin) {
          this._continueSameAccount();
        }
        this._showToast('Ocorreu um erro ao processar sua solicitação, por favor tente novamente');
        return;
      }

      const defaultAccount = {};
      this.accounts = response.data.data.items;
      this._getItemRelationClass();
      this._getSelectedAndDefault();

      setTimeout(() => {
        this._scrollToSelected();
      }, 100);

      this.directLoginChanged = false;
      this.hasUserDefault = this.accounts.some(account => account.default);

      if (defaultAccount.default && defaultAccount.selected) {
        this.directLoginChecked = true;
      }
      if (e && e.detail && e.detail.callback) e.detail.callback();
      this._toggleLoader(false);
    });
  }

  _showToast(message) {
    this.$.dispatchEvent(
      new CustomEvent('showToastAccountPopup', {
        detail: { message },
        bubbles: true,
        composed: true,
      }),
    );
  }

  _toggleLoader(show) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: show },
        bubbles: true,
        composed: true,
      }),
    );
  }

  _switchAccountPopup() {
    this.directLoginChecked = false;
    this.$.dispatchEvent(new CustomEvent('switchAccountPopup', { bubbles: true, composed: true }));
  }

  _switchAccount() {
    const [selectedAccount] = this.accounts.filter(item => item.selected);
    const { directLoginChecked } = this;

    if (
      selectedAccount &&
      (selectedAccount.cliId !== this.session.clientId ||
        selectedAccount.application !== this.session.application)
    ) {
      const popupElement = document.querySelector('power-popup-defaulter-warning');
      popupElement.closepopup('#defaulter-notification');
      popupElement.closepopup('#restriction-notification');

      const refreshToken = window.localStorage.getItem(`refresh_token-${this.session.loginId}`);

      const payload =
        `clientId=${selectedAccount.cliId}&` +
        `refresh_token=${refreshToken}&` +
        `grant_type=refresh_token&` +
        `application=${selectedAccount.application}&` +
        `applicationChanged=${selectedAccount.application !== this.session.application}&` +
        `directLoginChecked=${directLoginChecked}`;

      this.switchAccountServices.switchAccount(payload).then(success => {
        if (success.status && success.status !== 200) {
          if (this.isInLogin) {
            this.$ngRedux.dispatch({ type: 'SIGNOUT' });
            this.$ngRedux.dispatch({ type: 'CLEAR_ROUTE_LIST' });
            this.$state.go('login');
          }
          this._showToast(
            'Ocorreu um erro ao processar sua solicitação, por favor tente novamente',
          );
          return;
        }

        window.localStorage.setItem(
          `refresh_token-${this.session.loginId}`,
          success.data.refresh_token,
        );

        const fetchSessionData = () =>
          this.$http({
            url: `${this.urlApi}/MenuConfiguration/GetMenuConfiguration`,
            method: 'POST',
          }).then(response => response.data.data);

        const insertLog = () => this._insertLog();
        window.sessionStorage.setItem('logged', true);

        const dispatchAuthorization = authorizationData => {
          const params = {
            extra: {
              isSingleSignon: false,
              tipoApp: this.tipoApp,
              isAdm: JSON.parse(authorizationData.isAdm),
              token: authorizationData.access_token,
              isTrip: JSON.parse(authorizationData.isTrip),
              keepSession: !!this.session.keepSession,
            },
            user: {
              email: authorizationData.userEmail,
            },
          };

          if (process.env.NODE_ENV === 'production') {
            window.dispatchEvent(new CustomEvent('updateSentryScope', { detail: params }));
          }

          this.dispatch({
            type: 'SIGNIN',
            data: {
              clientId: JSON.parse(authorizationData.clientId),
              application: authorizationData.application,
              mapProvider: authorizationData.mapProvider,
              token: authorizationData.access_token,
              isAdm: JSON.parse(authorizationData.isAdm),
              isTrip: JSON.parse(authorizationData.isTrip),
              userId: JSON.parse(authorizationData.userId),
              userEmail: authorizationData.userEmail,
              userName: authorizationData.userName,
              companyName: authorizationData.clientName,
              permissions: authorizationData.permissions
                ? authorizationData.permissions.split(',')
                : [],
              modules: authorizationData.modules ? authorizationData.modules.split(',') : [],
              themeColors: {
                primaryColor: authorizationData.primaryColor,
                primaryTextColor: authorizationData.primaryTextColor,
                headerColor: authorizationData.headerColor,
                headerLogoColor: authorizationData.headerLogoColor,
                headerTextColor: authorizationData.headerTextColor,
                headerBadgeColor: authorizationData.headerBadgeColor,
                headerButtonColor: authorizationData.headerButtonColor,
                navigationColor: authorizationData.navigationColor,
                navigationDarkColor: authorizationData.navigationDarkColor,
                navigationLightColor: authorizationData.navigationLightColor,
                navigationSelectedColor: authorizationData.navigationSelectedColor,
                navigationTextColor: authorizationData.navigationTextColor,
                navigationTextDarkColor: authorizationData.navigationTextDarkColor,
                navigationTextLightColor: authorizationData.navigationTextLightColor,
                navigationTextSelectedColor: authorizationData.navigationTextSelectedColor,
                navigationAdminColor: authorizationData.navigationAdminColor,
                navigationAdminDarkColor: authorizationData.navigationAdminDarkColor,
                navigationAdminLightColor: authorizationData.navigationAdminLightColor,
                navigationAdminTextColor: authorizationData.navigationAdminTextColor,
                navigationAdminTextLightColor: authorizationData.navigationAdminTextLightColor,
              },
              trainingMode:
                typeof authorizationData.cliHashType != 'undefined' &&
                authorizationData.cliHashType != '' &&
                authorizationData.cliHashType != '0',
            },
          });

          const { mapProvider } = authorizationData;
          this.dispatch({ type: 'UPDATE_MAP_PROVIDER', data: { mapProvider } });
          // sessionStorage.setItem('sessionMapProvider', authorizationData.mapProvider);
        };
        const dispatchSession = async sessionData => {
          const checkForNewAnnouncements = this.commonServices.needCheckAnnouncements(
            this.session.token,
            false,
            this.session.loginId,
          );
          if (checkForNewAnnouncements) {
            this.hasNewAnnouncement = await this.commonServices.checkNewAnnouncementsLogin(
              this.session.loginId,
            );
          }

          this.dispatch({
            type: 'SIGNIN',
            data: {
              refreshFilter: true,
              needSelectAccount: false,
              updateLocalStorage: this.directLoginChecked || !this.hasUserDefault,
              keepSession: this.session.keepSession,
              userPhoto: sessionData ? sessionData.userPhoto : '',
              headerIcon: sessionData ? sessionData.headerIcon : '',
              headerLogo: sessionData ? sessionData.headerLogo : '',
              tradeMarkIcon: sessionData ? sessionData.tradeMarkIcon : '',
              applicationName: sessionData ? sessionData.applicationName : '',
              applicationNameVisibility: sessionData ? sessionData.applicationNameVisibility : '',
              navigationList: sessionData ? sessionData.navigationList : [],
              adminNavigationList: sessionData ? sessionData.adminNavigationList : [],
            },
          });

          const {
            userReportQuantity,
            userReportSharedQuantity,
            userAutoSendQuantity,
            isVideoTelemetry,
            headerIconAllowColorChange,
            userDefaultScreen,
            userDefaultActiveView,
          } = sessionData;
          this.$ngRedux.dispatch({
            type: 'UPDATE_USER_REPORT_QUANTITY',
            data: { userReportQuantity },
          });

          this.$ngRedux.dispatch({
            type: 'UPDATE_USER_REPORT_SHARED_QUANTITY',
            data: { userReportSharedQuantity },
          });

          this.$ngRedux.dispatch({
            type: 'UPDATE_USER_AUTO_SEND_QUANTITY',
            data: { userAutoSendQuantity },
          });

          this.$ngRedux.dispatch({
            type: 'UPDATE_IS_VIDEOTELEMETRY',
            data: { isVideoTelemetry },
          });

          this.$ngRedux.dispatch({
            type: 'UPDATE_HEADER_ICON_ALLOW_COLOR_CHANGE',
            data: { headerIconAllowColorChange },
          });

          this.$ngRedux.dispatch({
            type: 'UPDATE_USER_DEFAULT_SCREEN',
            data: { userDefaultScreen, userDefaultActiveView },
          });

          this._updateImages(sessionData);

          /* HOTFIX */
          // this.$.dispatchEvent(new CustomEvent('authorizedSignin', { bubbles: true, composed: true }));
          document
            .querySelector('#app-component')
            .dispatchEvent(new CustomEvent('authorizedSignin', { bubbles: true, composed: true }));
        };

        dispatchAuthorization(success.data);
        fetchSessionData()
          .then(sessionResponse => dispatchSession(sessionResponse))
          .then(() => this._switchAccountPopup())
          .then(() => this._nextRoute())
          .then(() => insertLog());
      });
    } else if (this.isInLogin) {
      if (this.directLoginChanged) {
        this._updateDefaultAccount(selectedAccount, directLoginChecked);
      }
      this._continueSameAccount();
    } else {
      this._switchAccountPopup();
      if (this.directLoginChanged) {
        this._updateDefaultAccount(selectedAccount, directLoginChecked);
        if (directLoginChecked) {
          this.dispatch({
            type: 'SIGNIN',
            data: Object.assign(this.session, { updateLocalStorage: true }),
          });
        }
      }
    }
  }

  _updateDefaultAccount(selectedAccount, directLoginChecked) {
    this.switchAccountServices
      .updateAccountDefault({
        application: selectedAccount.application,
        mapProvider: selectedAccount.mapProvider,
        userId: selectedAccount.usuId,
        directLoginChecked,
      })
      .then(success => {
        const { mapProvider } = selectedAccount;
        this.dispatch({ type: 'UPDATE_MAP_PROVIDER', data: { mapProvider } });
        // sessionStorage.setItem('sessionMapProvider', selectedAccount.mapProvider);

        if (success.status != 200) {
          throw new Error(`Server Status ${success.status}`);
        }
      });
  }

  async _continueSameAccount() {
    const checkForNewAnnouncements = this.commonServices.needCheckAnnouncements(
      this.session.token,
      false,
      this.session.loginId,
    );

    if (checkForNewAnnouncements) {
      this._toggleLoader(true);
      this.commonServices
        .checkNewAnnouncementsLogin(this.session.loginId)
        .then(response => {
          this.hasNewAnnouncement = response;
        })
        .finally(() => {
          this.dispatch({
            type: 'SIGNIN',
            data: {
              needSelectAccount: false,
            },
          });
          document
            .querySelector('#app-component')
            .dispatchEvent(new CustomEvent('authorizedSignin', { bubbles: true, composed: true }));
          this._nextRoute();
          this._toggleLoader(false);
        });
    } else {
      this.dispatch({
        type: 'SIGNIN',
        data: {
          needSelectAccount: false,
        },
      });
      document
        .querySelector('#app-component')
        .dispatchEvent(new CustomEvent('authorizedSignin', { bubbles: true, composed: true }));
      this._nextRoute();
    }
  }

  _nextRoute() {
    const { nextRouteName } = this.state;
    this.state.nextRouteName = null;

    if (nextRouteName) {
      const route = this._getNextRoute(nextRouteName);
      if (route.showInfo) {
        this.$state.go(route.routeInfoAddon, {
          routeId: route.id,
          serviceId: route.serviceId,
          serviceName: route.description,
          serviceIcon: route.icon,
        });
      } else {
        this.$state.go(nextRouteName);
      }
    } else if (this.hasNewAnnouncement) {
      this.$state.go('dashboardAnnouncements');
    } else if (this.session.userDefaultScreen) {
      this._directToDefaultRoute(
        this.session.userDefaultScreen,
        this.session.userDefaultActiveView,
      );
    } else {
      this.$state.go('dashboard', {}, { reload: true });
    }
  }

  _getSelectedAndDefault() {
    this.accounts = this.accounts.map(item => {
      if (item.cliId === this.session.clientId && item.application == this.session.application) {
        item.selected = true;
        if (item.default && item.defaultApplication) {
          this.directLoginChecked = true;
        }
      }
      return item;
    });
  }

  _getItemRelationClass() {
    const groups = this.accounts.reduce((acc, item) => {
      if (acc.indexOf(item.group) > 0) {
        return acc;
      }
      return acc.concat(item.group);
    }, []);

    groups.forEach(group => {
      const accounts = this.accounts.filter(item => item.group == group);

      accounts.forEach((item, index) => {
        if (accounts.length === 1) {
          item.accountClass = 'single';
        } else if (accounts.length - 1 === index) {
          item.accountClass = 'last';
        } else if (index === 0) {
          item.accountClass = 'first';
        } else {
          item.accountClass = 'middle';
        }
      });
    });
  }

  _selectAccount(account) {
    this.directLoginChecked = this.accounts.reduce((acc, item) => {
      if (item.cliId == account.cliId && item.application == account.application) {
        acc = item;
        item.directLoginChecked = item.default && item.defaultApplication;
        item.selected = true;
      } else {
        item.selected = false;
      }
      return acc;
    }, {}).directLoginChecked;
  }

  _directLoginSwitch() {
    this.directLoginChecked = !this.directLoginChecked;
    this.directLoginChanged = !this.directLoginChanged;
  }

  _scrollToSelected() {
    const selected = this.$.querySelector('.item-selected');
    const list = this.$.querySelector('.body');
    list.scrollTop = selected.offsetTop - list.offsetTop;
  }

  _getNextRoute(routeId) {
    const { navigationList, adminNavigationList } = this.session;

    const routes = navigationList
      .map(item => [item].concat(item.itens))
      .concat(adminNavigationList.map(item => [item].concat(item.itens)))
      .flat();

    return routes.find(item => item.route === routeId);
  }

  _getAccountItemStyle(element, account) {
    const style = {
      '--account-primary-color': account.primaryColor,
    };

    return style;
  }

  async _directToDefaultRoute(route, activeView) {
    if (activeView) {
      // eslint-disable-next-line new-cap
      const routeConfig = await ImportRouteConfig(route);
      if (routeConfig) {
        const routeData = {
          ...routeConfig.data,
          stateConfig: routeConfig.data.stateConfig
            ? routeConfig.data.stateConfig({
                isCustomReport: false,
                customReportId: '',
              })
            : null,
        };
        if (routeData.stateConfig) {
          routeData.stateConfig.viewMode = activeView;
        }

        this.$ngRedux.dispatch({ type: 'CLEAR_ROUTE_LIST' });
        this.$ngRedux.dispatch({
          type: 'NEXT_ROUTE',
          data: routeData,
        });
      }
    }
    this.$state.go(route, {}, { reload: true });
  }
  // end controller
}

export { PowerSwitchAccountController };
