import angular from 'angular';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

class NgTippyLink {
  constructor($scope, element) {
    Object.assign(this, { $: element[0], $scope });

    this.overflowConfig = {
      modifiers: {
        hide: { enabled: false },
        preventOverflow: { enabled: false },
      },
    };

    this.$.setAttribute('data-tippy-content', this.$scope.tippyTitle);

    this.instance = tippy(this.$, {
      arrow: this.$scope.tippyArrow || false,
      placement: this.$scope.tippyPosition || 'top',
      popperOptions: this.$scope.tippyForcePosition ? this.overflowConfig : {},
    });

    this.$scope.$watch(() => this.$scope.tippyTitle, this.__onTippyTitleChanged.bind(this));
  }

  __onTippyTitleChanged(newValue, oldValue) {
    if (!newValue) {
      this.instance.disable();
    } else if (newValue != oldValue) {
      this.instance.enable();
      this.instance.setContent(this.$scope.tippyTitle);
    }
  }
}

class NgTippy {
  static get $$ngIsClass() {
    return true;
  }

  constructor() {
    this.restrict = 'A';
    this.scope = {
      tippyTitle: '=?',
      tippyArrow: '=?',
      tippyPosition: '=?',
      tippyForcePosition: '=?',
    };
  }

  link($scope, element) {
    return new NgTippyLink($scope, element);
  }
}

angular.module('ng-tippy', []).directive('ngTippy', NgTippy).directive('gsTippy', NgTippy);
