/* global marked: true */
import moment from 'moment';

import { PowerRecordController } from '../power-record/power-record';

import '../../directives/infinite-scroll/infinite-scroll';
import '../../directives/ng-tippy/ng-tippy';

class MessageObj {
  constructor(userName, companyName, realTimeId, message) {
    this.userName = userName;
    this.companyName = companyName;
    this.realTimeId = realTimeId;
    this.message = message;
  }
}

class PowerRecordVehicleController extends PowerRecordController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$state',
      '$scope',
      '$http',
      'recordServices',
      'urlApi',
      'urlWebSocket',
      'trackingRealTimeServices',
      'deviceDetector',
    ];
  }

  constructor(
    $element,
    $ngRedux,
    $state,
    $scope,
    $http,
    recordServices,
    urlApi,
    urlWebSocket,
    trackingRealTimeServices,
    deviceDetector,
  ) {
    super($element, $ngRedux, $state, $scope, $http, recordServices, urlApi);
    Object.assign(this, { urlWebSocket, trackingRealTimeServices, deviceDetector });

    this.__appInheritBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Storage */
        userName: behavior.session.userName,
        companyName: behavior.session.companyName,
        application: behavior.session.application,
        isVideoTelemetry: behavior.session.isVideoTelemetry,
        modules: behavior.session.modules,
      }),
    )(this);

    this.viewMode = 'card';
    this.popupComandsTabSelected = 1; // 1. Comandos, 2. Enviados
    this.chatTabSelected = 1; // 1. Mensagens, 2. Dados do Veículo
    this.bodyBlocked = false;
    this.commandHistory = [];

    // Atualizar o histórico de comandos a cada 10 segundos
    this.intervalComandHistoryTimeDefault = 10000;
    this.fnRefreshComandHistory = null;
    // Timer do tempo do acompanhamento em tempo real
    this.intervalTimerRealTimeDefault = 1000;
    this.fnTimerRealTime = null;
    this.fnRefreshVehicleData = null;
    this.copiedLink = false;
    this.timerRealTime = null;
    this.tempEndDateRealTime = null;
    this.chatMessages = [];
    this.reasonExcepcionalConfigList = [];
    this.wsChat = null;
    this.dataVehicleChat = [
      {
        text: 'Marca',
        value: 'marca',
      },
      {
        text: 'Modelo',
        value: 'modelo',
      },
      {
        text: 'Cor',
        value: 'cor',
      },
      {
        text: 'Motor',
        value: 'litragemMotor',
      },
      {
        text: 'Combustível',
        value: 'combustivel',
      },
      {
        text: 'Tanque',
        value: 'volumeTanque',
      },
      {
        text: 'Ano Fabricação',
        value: 'ano',
      },
      {
        text: 'Ano Modelo',
        value: 'anoModelo',
      },
      {
        text: 'Chassi',
        value: 'chassi',
      },
    ];
    this.zoomMap = 14;
    this.stopRefreshVehicleData = false;
    this.incrementDecrementMinutes = 0;
    this.realTimeConfig = {};
    this.unreadMessageCount = 0;
    this.fleetPolicy = null;

    this.realTimeStatusEnum = {
      INACTIVE: 0,
      INPROGRESS: 1,
      WAITING: 2,
    };

    this.terms = {
      icon: 'gs_loc',
      title: 'Deseja ativar o acompanhamento em tempo real?',
      subtitle:
        'Ao ativar a funcionalidade, você declara estar de acordo com seus termos e tarifa.',
      term: `Fica ciente o Contratante que ao clicar a funcionalidade é acionada efetivando a sua
      contratação e cobrança, a qual ocorrerá na próxima fatura. A cobrança ocorre da seguinte
      forma: a cada 30 minutos é considerada uma utilização pelo usuário no valor de R$ 6,49
      (seis reais e quarenta e nove centavos), sendo que o cliente escolherá o tempo que deseja que a funcionalidade
      esteja ativa, mediante clique em "Configurar duração", sendo que ultrapassado o período
      será necessário a sua renovação. O tempo mínimo de funcionamento por acionamento é de 30
      minutos e o máximo é de 5 horas.`,
      cost: 'R$6,49',
      period: '30 minutos',
    };
    this.acceptTermsSelected = false;
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();

    Object.assign(this.$, {
      _refreshDataset: this._refreshDataset.bind(this),
    });

    this.$.setAttribute('card-view', '');
    this.$.removeAttribute('map-view');

    this.$scope.$watch(() => this.realTimeConfig.endDate, this.__getTimer.bind(this));
  }

  $onDestroy() {
    super.$onDestroy();
    clearInterval(this.fnRefreshVehicleData);
    clearInterval(this.fnTimerRealTime);
    this._disconnectChat();
  }
  /* */

  /* Public */
  changeView(viewMode) {
    this.viewMode = viewMode;
    switch (viewMode) {
      case 'map':
        this.$.scrollTop = 0;
        this.$.setAttribute('map-view', '');
        this.$.removeAttribute('card-view');
        this._renderStepMode();
        break;
      default:
        this.$.setAttribute('card-view', '');
        this.$.removeAttribute('map-view');
        break;
    }
  }
  /* */

  /* Private */
  async _getDataset() {
    this._toggleLoader(true);

    await this._getVehicleDataSet()
      .then(() => {
        if (!this.showVehicleSummary) {
          this._toggleLoader(false);
        }
      })
      .catch(() => {
        this._toggleLoader(false);
      });

    if (this.showVehicleSummary) {
      await Promise.all([this._getConfigRealTimeVechicle(), this._getFleetPolicyDataset()])
        .then(() => {
          this._refreshVehicleData();
          this._toggleLoader(false);
        })
        .catch(() => {
          this._toggleLoader(false);
        });
    }

    this._getReasonExcepcionalConfigList();
  }

  async _getVehicleDataSet() {
    const msgError = 'Ops! Ocorreu um erro ao carregar as informações.';
    await this.$http({
      url: `${this.urlApi}/${this.stateConfig.getDataMethod}`,
      method: 'POST',
      data: { ...this.stateConfig.getDataFixedParams },
    }).then(success => {
      if (success.status && success.status !== 200) {
        this.toastObj = {
          text: msgError,
        };
        this.$.querySelector('#toast-obj').toggle(true);
      } else if (success.data && !success.data.hasError) {
        this.stateData = { ...success.data.data };

        if ('dadosHistoricos' in this.stateData) {
          if (this.stateData.dadosHistoricos) {
            this.showVehicleSummary = false;
          }
        }
      } else {
        this.toastObj = {
          text: msgError,
        };
        this.$.querySelector('#toast-obj').toggle(true);
      }
    });
  }

  async _getFleetPolicyDataset() {
    const msgError = 'Ops! Ocorreu um erro ao carregar as informações.';
    await this.$http({
      url: `${this.urlApi}/FleetPolicy/GetVehicleFleetPolicy`,
      method: 'POST',
      data: { ...this.stateConfig.getDataFixedParams, request: { vehicleId: this.stateData.id } },
    }).then(
      success => {
        if (success.status && success.status !== 200) {
          this.toastObj = {
            text: msgError,
          };
          this.$.querySelector('#toast-obj').toggle(true);
        } else if (success.data && !success.data.hasError) {
          this.fleetPolicy = success.data.data;

          if (this.fleetPolicy && this.fleetPolicy.period) {
            this.fleetPolicy.period.items = this.fleetPolicy.period.value.split(',');
          }
        } else {
          this.toastObj = {
            text: msgError,
          };
          this.$.querySelector('#toast-obj').toggle(true);
        }
      },
      error => {
        console.error(error);
        this.toastObj = {
          text: msgError,
        };
        this.$.querySelector('#toast-obj').toggle(true);
      },
    );
  }

  async _getConfigRealTimeVechicle() {
    const msgError = 'Ops! Ocorreu um erro ao carregar as informações.';
    await this.trackingRealTimeServices
      .getConfigRealTimeVechicle({ id: this.stateData.id })
      .then(success => {
        if (success.status && success.status !== 200) {
          this._toggleToast(msgError);
          return;
        }

        if (success.data && !success.data.hasError) {
          this.realTimeConfig = success.data.data || {};
          this.realTimeConfig.externalLink = this._getUrlExternalLink(
            this.realTimeConfig.guidExternalLink,
          );
          if (this.realTimeConfig.status === this.realTimeStatusEnum.INPROGRESS) {
            this._connectChat();
          } else {
            this._disconnectChat();
          }
        } else {
          this._toggleToast(msgError);
        }
      });
  }

  async _refreshDataset() {
    const realTimeActive = this.realTimeConfig.isActive;
    await Promise.all([this._getConfigRealTimeVechicle(), this._getVehicleDataSet()]).then(() => {
      if (this.realTimeConfig.isActive) {
        this._selectReasonExcepcional(this.realTimeConfig.reasonId);
      }
      if (this.realTimeConfig.isActive !== realTimeActive) {
        this._refreshVehicleData();
      }
    });

    if (this.viewMode === 'map') {
      const mapComponent = this.$.querySelector('#record-map');
      this.zoomMap = mapComponent.getMap().getZoom();
      this._renderStepMode();
    }
  }

  _getReasonExcepcionalConfigList() {
    this.trackingRealTimeServices.getReasonExcepcionalConfigList().then(success => {
      if (success && success.status !== 200) {
        this._toggleToast('Ops! Erro ao carregar as informações');
      }
      this.reasonExcepcionalConfigList = success.data.data;
      this._selectReasonExcepcional(this.realTimeConfig.reasonId);
    });
  }

  _getCommandHistory() {
    this.bodyBlocked = true;
    const json = {};
    json.request = { vehicleId: this.stateConfig.getDataFixedParams.id };
    this.__callApiMethod(
      'Command/GetCommandHistory',
      json,
      {
        success: null,
        error: 'Ops! Erro ao carregar as informações',
      },
      false,
    ).then(result => {
      if (result && !result.hasError) {
        this.bodyBlocked = false;
        this.commandHistory = result.data;
      }
    });
  }

  _getMessagesChat() {
    this.trackingRealTimeServices.getMessagesChat({ id: this.realTimeConfig.id }).then(success => {
      if (success.status && success.status !== 200) {
        this._toggleToast('Ops! Erro ao carregar as informações');
        return;
      }
      this.chatMessages = success.data.data;
    });
  }

  _activeRealTime() {
    this._toggleLoader(true);
    this.trackingRealTimeServices.activeRealTime({ id: this.stateData.id }).then(success => {
      if (success.status && success.status !== 200) {
        this._toggleToast('Erro ao realizar a solicitação');
        this._toggleLoader(false);
        return;
      }

      if (success.data && success.data.data) {
        this._toggleToast('A solicitação de acompanhamento em Tempo Real foi enviada.');
        this.timerRealTime = '00:30';
        this.realTimeConfig.isActive = true;
        this.realTimeConfig.status = this.realTimeStatusEnum.WAITING;
        this._refreshVehicleData();
      }

      this._toggleLoader(false);
    });
  }

  _changeReasonRealTimeVehicle(reason) {
    const payload = {
      realTimeId: this.realTimeConfig.id,
      reasonId: reason.id,
    };

    this.trackingRealTimeServices.changeReasonRealTimeVehicle(payload).then(success => {
      if (success.status && success.status !== 200) {
        this._toggleToast('Erro ao realizar a solicitação');
        return;
      }

      if (success && success.data.data) {
        this._toggleToast('Motivo alterado com sucesso!');
      }
    });
  }

  _evtClickBtnCustomPopup(type, popupId) {
    if (type == 'close') {
      switch (popupId) {
        case 'popup-exec': {
          this.stopRefreshVehicleData = false;
          this.$.querySelector('#popup-exec').toggle();
          clearInterval(this.fnRefreshComandHistory);
          this._getDataset();
          break;
        }
        case 'popup-confirmation-exec': {
          this.$.querySelector('#popup-confirmation-exec').toggle();
          break;
        }
        case 'popup-confirmation-real-time':
          this.confirmationPopup.cancel();
          break;
        case 'popup-link-real-time':
          this.$.querySelector('#popup-link-real-time').toggle();
          break;
        case 'popup-add-remove-time':
          this.stopRefreshVehicleData = false;
          this.realTimeConfig.endDate = this.tempEndDateRealTime;
          this.$.querySelector('#popup-add-remove-time').toggle();
          break;
        default: {
          break;
        }
      }
    } else if (type == 'primaryBtn') {
      switch (popupId) {
        case 'popup-exec': {
          if (this.execPopup.itemSelected.commandTerm) {
            this.$.querySelector('#popup-block-terms').toggle();
            this.$.querySelector('.popup-block-terms-description').innerHTML = marked.parse(
              this.execPopup.itemSelected.commandTerm,
            );
          } else {
            this.$.querySelector('#popup-confirmation').toggle();
          }
          break;
        }
        case 'popup-confirmation': {
          Object.assign(this.execPopup.json.request, {
            commandId: this.execPopup.itemSelected.commandId,
            equipamentId: this.execPopup.itemSelected.equipamentId,
            acceptTerm: this.execPopup.itemSelected.commandTerm,
            browser: this.deviceDetector.browser,
          });
          this.__callApiMethod(
            this.execPopup.method,
            this.execPopup.json,
            Object({
              success: this.execPopup.text,
              error: 'Ops! Ocorreu um erro com a solicitação, tente novamente',
            }),
            true,
          ).then(result => {
            if (!result) {
              if (this.$.querySelector('#popup-block-terms').hasAttribute('open')) {
                this.$.querySelector('#popup-block-terms').toggle(false);
                this.$.querySelector('#popup-exec').toggle(false);
              }
            } else if (!result.hasError) {
              this.execPopup.itemSelected = null;
              this._evtClickPopupTab(2); // 1. Comandos, 2. Enviados

              if (this.$.querySelector('#popup-block-terms').hasAttribute('open')) {
                this.$.querySelector('#popup-block-terms').toggle(false);
              }
            }
          });
          break;
        }
        case 'popup-confirmation-real-time': {
          this.confirmationPopup.confirm();
          break;
        }
        case 'popup-add-remove-time': {
          const messageError = 'Ops! Ocorreu um erro com a solicitação, tente novamente';
          const payload = {
            id: this.realTimeConfig.id,
            incrementDecrementMinutes: this.incrementDecrementMinutes,
          };

          this.stopRefreshVehicleData = false;
          this.$.querySelector('#popup-add-remove-time').toggle();
          this._toggleLoader(true);
          this.trackingRealTimeServices.addRemoveTimeRealTimeVehicle(payload).then(success => {
            if (success.status !== 200 || (success.data && !success.data.data)) {
              this._toggleToast(messageError);
              this._toggleLoader(false);
              return;
            }

            this._toggleLoader(false);
            this._toggleToast('A duração foi configurada com sucesso.');
          });
          break;
        }
        case 'popup-waiting-real-time':
          this.$.querySelector('#popup-waiting-real-time').toggle();
          break;
        default: {
          break;
        }
      }
    }
  }

  _evtClickPopupTab(tabNumber) {
    if (tabNumber !== this.popupComandsTabSelected) {
      // 1. Comandos, 2. Enviados
      this.popupComandsTabSelected = tabNumber;
      this.$.querySelector('.popup-exec-body').setAttribute('tab', tabNumber);

      if (tabNumber == 2) {
        this.stopRefreshVehicleData = true;
        this._getCommandHistory();
        this.fnRefreshComandHistory = setInterval(() => {
          this._getCommandHistory();
        }, this.intervalComandHistoryTimeDefault);
      } else {
        clearInterval(this.fnRefreshComandHistory);
      }
    }
  }

  _evtClickChatTab(tabNumber) {
    // 1. Mensagens, 2. Dados do Veículo
    this.chatTabSelected = tabNumber;
    this.$.querySelector('.chat-real-time-vehicle').setAttribute('tab', tabNumber);
  }

  _evtClickAddRemoveTime(value) {
    this.incrementDecrementMinutes += value * 30;
    this.realTimeConfig.endDate = moment(this.realTimeConfig.endDate).add(value * 30, 'minutes');
  }

  _evtClickCopyToClipBoard(id) {
    const copyText = this.$.querySelector(`#${id}`);
    copyText.select();
    document.execCommand('Copy');

    this.copiedLink = true;
  }

  _evtClickReasonExcepcional(reason) {
    this._selectReasonExcepcional(reason.id);
    this._changeReasonRealTimeVehicle(reason);
  }

  _selectReasonExcepcional(id) {
    this.reasonExcepcionalConfigList.forEach(item => {
      item.selected = item.id === id;
    });
  }

  _showUnsupportedInfo(identifier) {
    this.toastObj = {
      text: this._getNotSendCommandTippyTitle(identifier),
    };
    this.$.querySelector('#toast-obj').toggle(true);
  }

  _dropdownReasonDescription() {
    const selectedOption = this.reasonExcepcionalConfigList.filter(option => option.selected);
    if (selectedOption[0]) {
      return selectedOption[0].description;
    }
    return '';
  }

  _renderStepMode() {
    const mapComponent = this.$.querySelector('#record-map');
    mapComponent.resizeMap();
    const vehicle = Object.clone({}, this.stateData);

    switch (vehicle.status) {
      case 1:
        Object.assign(vehicle, {
          color: '#4AAE4E',
          icon: vehicle.icon || 'directions_car',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 2:
        Object.assign(vehicle, {
          color: '#DECE00',
          icon: vehicle.icon || 'directions_car',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 3:
        Object.assign(vehicle, {
          color: '#980A1A',
          icon: vehicle.icon || 'directions_car',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 6:
        Object.assign(vehicle, {
          color: '#4AAE4E',
          icon: 'lock_open',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 7:
        Object.assign(vehicle, {
          color: '#DECE00',
          icon: 'lock_open',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 8:
        Object.assign(vehicle, {
          color: '#980A1A',
          icon: 'lock_open',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 9:
        Object.assign(vehicle, {
          color: '#4AAE4E',
          icon: 'lock',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 10:
        Object.assign(vehicle, {
          color: '#DECE00',
          icon: 'lock',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 11:
        Object.assign(vehicle, {
          color: '#980A1A',
          icon: 'lock',
          markerIcon: 'MarkerIcon',
        });
        break;
      default:
        Object.assign(vehicle, {
          color: '#949494',
          icon: vehicle.icon || 'directions_car',
          markerIcon: 'MarkerIcon',
        });
        break;
    }

    mapComponent
      .renderDataset({
        dataset: [vehicle],
        layerName: 'stepMarker',
        type: 'MarkerFeatureGroup',
      })
      .then(() => {
        mapComponent.fitLayers(['stepMarker']);
        mapComponent.openPopup(0, 'stepMarker');
      });

    const { latitude, longitude } = vehicle;

    mapComponent.zoomTo({ latitude, longitude }, this.zoomMap);
  }

  _disableAddRemoveTimeBtn(operation) {
    const actualDate = moment();
    const endDate = moment(this.realTimeConfig.endDate);
    let limitMinutes;

    if (operation === 'add') {
      limitMinutes = this.realTimeConfig.limitMinutes;
      const startDate = moment(this.realTimeConfig.startDate);
      return Math.round(moment.duration(endDate.diff(startDate)).asMinutes()) >= limitMinutes;
    }
    if (operation === 'remove') {
      // Cannot remove time when less than 30 minutes remain
      limitMinutes = 30;
      return Math.round(moment.duration(endDate.diff(actualDate)).asMinutes()) <= limitMinutes;
    }
    return false;
  }

  _refreshVehicleData() {
    if (this.fnRefreshVehicleData) {
      clearInterval(this.fnRefreshVehicleData);
    }
    const time = this.realTimeConfig.isActive ? 10000 : 180000;
    this.fnRefreshVehicleData = setInterval(() => {
      const popups = Array.from(document.querySelectorAll('power-popup'));
      if (popups.findIndex(item => item.hasAttribute('open')) < 0 && !this.stopRefreshVehicleData) {
        this._refreshDataset();
      }
    }, time);
  }

  _toggleChat() {
    this.$.querySelector('#chat-real-time-vehicle').toggle();
  }

  _toggleLoader(show) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: show },
        bubbles: true,
        composed: true,
      }),
    );
  }

  _toggleToast(message) {
    this.toastObj = {
      text: message,
    };
    this.$.querySelector('#toast-obj').toggle(true);
  }

  _getUrlExternalLink(guid) {
    const url = document.location.origin;
    const param = {
      guidId: guid,
      application: this._getApplicationType(this.application),
    };
    const value = btoa(JSON.stringify(param));
    return `${url}/acompanhamento-veiculo.html?value=${value}`;
  }

  _evtKeydownChatInputMessage(evt) {
    if (!evt.shiftKey && evt.keyCode == 13) {
      evt.preventDefault();
      this._sendMessageChat();
    }
  }

  _connectChat() {
    if (!this.wsChat || this.wsChat.readyState !== WebSocket.OPEN) {
      const chatUrl = `${this.urlWebSocket}/WebSocketChatRealTimeServer.ashx?room=${this.realTimeConfig.guidExternalLink}`;

      this.wsChat = new WebSocket(chatUrl);

      this.wsChat.onmessage = evt => {
        const chat = this.$.querySelector('#chat-real-time-vehicle');
        if (!chat.hasAttribute('open')) {
          this.unreadMessageCount++;
        }

        const message = JSON.parse(evt.data);
        this.chatMessages.push(message);
        this.$scope.$apply();
        this._scrollChatToBottom();
      };

      this.wsChat.onopen = () => {
        this._getMessagesChat();
      };

      this.wsChat.onclose = evt => {
        if (evt.code !== 1000) {
          setTimeout(() => {
            this._connectChat();
          }, 5000);
        }
      };
    }
  }

  _disconnectChat() {
    if (this.wsChat) {
      this.reconnectChat = false;
      this.wsChat.close();
    }
    this.wsChat = null;
  }

  _scrollChatToBottom() {
    const element = this.$.querySelector('.chat-list-message-container');
    element.scrollTop = element.scrollHeight;
  }

  _sendMessageChat() {
    if (this.textareaMessageChat.trim() === '') {
      return;
    }

    if (this.wsChat.readyState === WebSocket.OPEN) {
      const message = new MessageObj(
        this.userName,
        this.companyName,
        this.realTimeConfig.id,
        this.textareaMessageChat,
      );
      this.wsChat.send(JSON.stringify(message));
      this.textareaMessageChat = '';
    }
  }

  _getNotSendCommandTippyTitle(identifier) {
    let strMessage = '';
    switch (identifier) {
      case 'SERVICO_NAO_CONTRATADO':
        strMessage = 'Serviço não contratado para esse veículo.';
        break;
      default:
        strMessage = 'Comando incompatível com o hardware instalado nesse veículo.';
        break;
    }
    return strMessage;
  }

  /**
   * Input expected: fleetPolicy.name
   * @param {string} value path
   */
  _getDeepViewData(value, defaultText) {
    const parsedValue = JSON.parse(JSON.stringify({ value })).value;

    if (!parsedValue || typeof parsedValue !== 'string') {
      return defaultText;
    }

    return parsedValue.split('.').reduce((acc, item) => {
      if (acc && typeof acc === 'object' && item in acc) {
        return acc[item];
      }

      return defaultText;
    }, this);
  }

  _validateFleetPolicySection(tabContentSummary) {
    const { data } = tabContentSummary;
    if (!data) {
      return true;
    }

    if (this._filterItemByModule([tabContentSummary]).length == 0) {
      return false;
    }

    // if (
    //   tabContentSummary.validateModule &&
    //   !this.modules.includes(tabContentSummary.validateModule)
    // ) {
    //   return false;
    // }

    if (data[0] && data[0].component) {
      if (data[0].component == 'period' && !this.fleetPolicy.period) {
        return false;
      }
      return true;
    }

    const values = [];

    data.forEach(item => {
      if (this._getDeepViewData(item.value)) {
        values.push(item.value);
      }
    });

    return values.length > 0;
  }

  _getFleetPolicyIconColor(status) {
    let classColor;

    switch (status) {
      case 'broadcasting':
        classColor = 'broadcasting';
        break;

      case 'notHired':
        classColor = 'not-hired';
        break;

      case 'incompatible':
        classColor = 'incompatible';
        break;

      default:
        break;
    }

    return classColor;
  }

  _getFleetPolicyIcon(status) {
    let icon;

    switch (status) {
      case 'broadcasting':
        icon = 'schedule';
        break;

      case 'notHired':
        icon = 'new_releases';
        break;

      case 'incompatible':
        icon = 'new_releases';
        break;

      default:
        break;
    }

    return icon;
  }

  _getFleetPolicyIconTitle(status) {
    let title = '';

    switch (status) {
      case 'broadcasting':
        title = 'Em processo de atualização no veículo';
        break;

      case 'notHired':
        title = 'Serviço não contratado para este veículo';
        break;

      case 'incompatible':
        title = 'Equipamento incompatível';
        break;

      default:
        break;
    }

    return title;
  }

  _generateFleetPolicyHistoricDescription() {
    const { updateUserName } = this.fleetPolicy;
    const updateDate = new Date(this.fleetPolicy.updateDate).toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo' });

    let description;

    if (updateUserName && updateDate) {
      description = `Política de frota modificada por ${updateUserName} em ${updateDate}`;
    } else if (updateUserName && !updateDate) {
      description = `Política de frota modificada por ${updateUserName}`;
    } else if (!updateUserName && updateDate) {
      description = `Política de frota modificada em ${updateDate}`;
    }

    return description;
  }

  _toggleBlockTermsPopup() {
    this.$.querySelector('#popup-block-terms').toggle();
  }

  _evtClickCheckBoxAcceptTerms() {
    this.acceptTermsSelected = !this.acceptTermsSelected;
  }
  /* */

  __getTimer() {
    if (this.realTimeConfig.endDate) {
      this.fnTimerRealTime = setInterval(() => {
        const actualDate = moment();
        const endDate = moment(this.realTimeConfig.endDate);
        if (endDate._isValid) {
          if (actualDate >= endDate) {
            this.timerRealTime = '00:00';
            clearInterval(this.fnTimerRealTime);
          } else {
            const minutes = Math.floor(moment.duration(endDate.diff(actualDate)).asMinutes());
            const seconds = moment.utc(endDate.diff(actualDate)).format('ss');
            this.timerRealTime = `${String(minutes).padStart(2, '0')}:${seconds}`;
          }
          this.$scope.$apply();
        }
      }, this.intervalTimerRealTimeDefault);
    }
  }

  __chatOnToggle(evt) {
    if (evt.detail.open) {
      this._scrollChatToBottom();
    }
    this.unreadMessageCount = 0;
  }

  _filterItemByModule(itemList) {
    return itemList.filter(
      item =>
        !item.validateModule || this.modules.some(module => item.validateModule.includes(module)),
    );
  }
}

export { PowerRecordVehicleController };
