import angular from 'angular';
import { eventOnce } from '@power/power-components/utils/event-once';

import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-toast/power-toast';

import template from './golfleet-popup-address.html';
import './golfleet-popup-address.scss';

/**
 * Address
 */
export class Address {
  /**
   * - Constructor
   * @param {Object} address
   */
  constructor(address) {
    this.city = address.city;
    this.district = address.district;
    this.houseNumber = address.houseNumber;
    this.label = address.label;
    this.zipCode = address.zipCode;
    this.state = address.state;
    this.street = address.street;
    this.latitude = address.latitude;
    this.longitude = address.longitude;
  }
}

class GolfleetPopupAddressController {
  static get $inject() {
    return ['$element', '$scope'];
  }

  constructor($element, $scope) {
    Object.assign(this, { $: $element[0], $scope });

    this.model = {};
  }

  /**
   * Lifecycle
   */
  $onInit() {
    Object.assign(this.$, {
      togglePopup: this.togglePopup.bind(this),
      setDataSet: this.setDataSet.bind(this),
    });
  }
  /* Lifecycle */

  /* Public */
  togglePopup() {
    this.$.querySelector('power-popup').toggle();
  }

  setDataSet(data) {
    this.model = data;
    if (this.model.label) {
      this.model.address = new Address(this.model);
      const { latitude: lat, longitude: lng } = this.model;
      if (lat && lng) {
        this.$.querySelector('golfleet-map-reverse-geocode').setMarker({ lat, lng });
      }
    }
  }
  /* */

  /* Private */
  _save() {
    if (this._validateModel()) {
      const addressFormated = `${this.model.address.label} (${this._getLatLon()})`;
      this.$scope.$emit('changeAddress', addressFormated);
    }
    this._closePopup();
  }

  _getLatLon() {
    return this.model.address
      ? `${this.model.address.latitude}, ${this.model.address.longitude}`
      : '';
  }

  _clearWarnings() {
    const fields = this.$.querySelectorAll('power-crud-textbox');
    fields.forEach(field => {
      field.setWarning(null);
    });
  }

  _validateModel() {
    this._clearWarnings();

    let fieldErrorCount = 0;

    if (!this.model.address || !this.model.address.latitude || !this.model.address.longitude) {
      this.toastObj = { text: 'Selecione um endereço válido no mapa' };
      this.$.querySelector('#golfleet-popup-report-address-toast').toggle(true);
      fieldErrorCount++;
    }

    return fieldErrorCount === 0;
  }

  _closePopup() {
    eventOnce({
      element: this.$,
      event: 'transitionend',
      trigger: () => this.togglePopup(),
      action: () => this.$.querySelector('golfleet-map-reverse-geocode').resetMap(),
    });

    this._clearWarnings();
  }

  _showLoader(showLoader) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader },
        bubbles: true,
        composed: true,
      }),
    );
  }
  /* */
}

class GolfleetPopupAddress {
  constructor() {
    this.template = template;
    this.bindings = {
      headerDescription: '=?',
      userFunctionalities: '=?',
      userFunctionalitiesSeparator: '=?',
      selectedItems: '=?',
      userFunctionalitiesIgnoreCase: '<',
      requestDataOnInit: '<',
      showButtonNewEmail: '<',
      multiSelect: '<',

      /* Callbacks */
      openPopupCallback: '&?',
      confirmCallback: '&?',
      requestDataOnInitCallback: '&?',
    };
    this.controller = GolfleetPopupAddressController;
  }
}

angular
  .module('golfleet-popup-address', ['power-popup', 'power-toast'])
  .component('golfleetPopupAddress', new GolfleetPopupAddress());
