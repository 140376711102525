/* global L: true isIframe: true */
import moment from 'moment';

import { PowerMapLeafletProvider } from '../../../power-map/providers/leaflet/power-map.leaflet-provider';
import '../../../../helpers/is-iframe/is-iframe';
import './power-map-utilization.leaflet-provider.scss';

class PowerMapUtilizationLeafletProvider extends PowerMapLeafletProvider {
  // eslint-disable-next-line no-useless-constructor
  constructor(context, $element, $ngRedux, $rootScope, $scope, $http, urlApi) {
    super(context, $element, $ngRedux, $scope, $http, urlApi);

    Object.assign(this, { $rootScope });

    this.routeTimelineInterval = null;
    this.routeTimelinePosition = 0;

    this.routeMarkers = null;
    this.routeLinestrings = null;
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      openPopup: this.openPopup.bind(this),
      setTimelineDataset: this.setTimelineDataset.bind(this),
      toggleTimelineControl: this.toggleTimelineControl.bind(this),
      updateTimelinePosition: this.updateTimelinePosition.bind(this),
    });

    super.$onInit();
  }

  $onDestroy() {
    clearInterval(this.context.routeTimelineEvent);
  }
  /**/

  /* Public */
  removeLayers(layerNameList = ['default']) {
    const removingTimeline =
      layerNameList.filter(layer => layer == 'routeMarkers' || layer == 'routeLinestrings').length <
      layerNameList.length;
    if (this.context.routeTimelineEvent && removingTimeline) {
      clearInterval(this.context.routeTimelineEvent);
      Object.assign(this, {
        routeTimelineEvent: null,
        routeTimelinePosition: this.routeMarkers.length - 1,
      });
    }
    if (removingTimeline) {
      Object.assign(this, {
        routeMarkers: null,
        routeLinestrings: null,
      });
    }
    super.removeLayers(layerNameList);
  }

  toggleTimelineControl(show) {
    this.$.querySelector('#route-timeline-control').style.display = show ? '' : 'none';
  }

  setTimelineDataset({ markers, linestrings }) {
    Object.assign(this, {
      routeMarkers: markers,
      routeLinestrings: linestrings,
      routeTimelinePosition: markers.length - 1,
    });
    this._renderRouteTimeline();
  }

  updateTimelinePosition(evtParams) {
    this.routeTimelinePosition = evtParams.pathPosition;

    if ('routeMarkers' in this.mapFeatureLayers) {
      const objects = this.mapFeatureLayers.routeMarkers.getLayers();

      for (let markerIndex = 0; markerIndex < objects.length; markerIndex++) {
        const marker = objects[markerIndex];
        const markerElement = document.querySelector(`#mrv_${marker.options.mrvId}`);

        if (markerIndex === this.routeTimelinePosition) {
          if (markerIndex != 0 && markerIndex != objects.length - 1) {
            markerElement?.setAttribute('selected', '');
          }

          marker.openPopup();
          marker._bringToFront();

          this.mapLayer.setView(
            marker.getLatLng(),
            this.routeTimelinePosition == 1 ? 16 : this.mapLayer.getZoom(),
            { animate: true },
          );

          marker.setZIndexOffset(300);
        } else {
          markerElement?.removeAttribute('selected');
          marker.setZIndexOffset(1);
        }
      }
    }
  }
  /**/

  /* Private */
  _createMarkerIcon({ color, icon, ignicao: ignition, direcao: direction }) {
    if (!ignition || direction === null) {
      return super._createMarkerIcon({ color, icon });
    }

    const createIcon = iconString => {
      if (!iconString) return '';
      return `
        <div style="color: #fff;cursor: pointer;transform: rotate(-${direction}deg);">
          <i class="material-icons" style="cursor: pointer;">
            ${iconString}
          </i>
        </div>
      `;
    };

    return L.divIcon({
      className: 'leaflet-marker-icon',
      iconSize: [20, 20],
      html: `
        <div style="margin: -10px 0px 0px -10px; z-index: 0; position: absolute;">
          <div style="width:40px; height:40px; transform: rotate(${direction}deg);">
            <svg xmlns="https://www.w3.org/2000/svg" width="40" height="60" style="margin-top:-20px;">
              <foreignObject x="0" y="0" width="40" height="60" style="">
                <div style="display:flex;justify-content:center;align-items: center;width: 100%;height: 100%;flex-direction: column;">
                  <i class="material-icons" style="color:${
                    color || 'var(--primary-color)'
                  };cursor: pointer;font-size: 24px;margin-bottom:1px; margin-top: -4px;">
                    navigation
                  </i>
                  <div style="background:${
                    color || 'var(--primary-color)'
                  };border-radius: 100%;display:flex;justify-content:center;align-items: center;height: 32px;width: 32px;">
                    ${createIcon(icon)}
                  </div>
                </div>
              </foreignObject>
            </svg>
          </div>
        </div>
        `,
    });
  }

  _createRouteIcon(properties) {
    const svgSize = 24;
    const svgSizeWithShadow = 28;

    const containerDefaultStyle = `
      margin: -${svgSizeWithShadow / 4}px 0 0 -${svgSizeWithShadow / 4}px;
      z-index: 0;
      position: absolute;"
    `;

    let markerContent = '';

    if (properties.first) {
      markerContent = `
        <div
          id="mrv_${properties.mrvId}"
          class="power-custom-route-marker"
          has-violation=${properties.infracoes.length > 0}
          style="${containerDefaultStyle}"
        >
          <svg xmlns="https://www.w3.org/2000/svg"
            viewBox="0 0 ${svgSizeWithShadow} ${svgSizeWithShadow}"
            width="${svgSizeWithShadow}"
            height="${svgSizeWithShadow}"
          >
            <circle
              r="${svgSize / 2.5}"
              cx="${svgSizeWithShadow / 2}"
              cy="${svgSizeWithShadow / 2}"
              fill=${properties.color}
            >
            </circle>
          </svg>
        </div>
      `;
    } else if (properties.last) {
      markerContent = `
        <div
          id="mrv_${properties.mrvId}"
          class="power-custom-route-marker"
          has-violation=${properties.infracoes.length > 0}
          style="${containerDefaultStyle}"
        >
          <svg xmlns="https://www.w3.org/2000/svg"
            viewBox="0 0 ${svgSizeWithShadow} ${svgSizeWithShadow}"
            width="${svgSizeWithShadow}"
            height="${svgSizeWithShadow}"
          >
            <rect
              width="${svgSizeWithShadow * 0.64}"
              height="${svgSizeWithShadow * 0.64}"
              x="${(svgSizeWithShadow * 0.36) / 2}"
              y="${(svgSizeWithShadow * 0.36) / 2}"
              rx="2"
              style="fill:${properties.color}"
            />
          </svg>
        </div>
      `;
    } else {
      markerContent = `
        <div
          id="mrv_${properties.mrvId}"
          class="power-custom-route-marker"
          has-violation=${properties.infracoes.length > 0}
          style="${containerDefaultStyle}"
        >
          <svg xmlns="https://www.w3.org/2000/svg"
            viewBox="0 0 ${svgSizeWithShadow} ${svgSizeWithShadow}"
            width="${svgSizeWithShadow}"
            height="${svgSizeWithShadow}"
          >
            <circle
              r="${svgSize / 3.5}"
              cx="${svgSizeWithShadow / 2}"
              cy="${svgSizeWithShadow / 2}"
              fill=${properties.color}
            >
            </circle>
            <circle
              r="${svgSize / 5}"
              cx="${svgSizeWithShadow / 2}"
              cy="${svgSizeWithShadow / 2}"
              fill=${properties.color}
            >
            </circle>
          </svg>
        </div>
      `;
    }

    return L.divIcon({
      className: 'leaflet-route-icon',
      iconSize: [svgSizeWithShadow / 2, svgSizeWithShadow / 2],
      html: markerContent,
    });
  }

  _geoJsonToMarker({ geoJson, markerIcon }) {
    const { eventoGerador } = geoJson.properties;
    const isVmaxEvent = eventoGerador && eventoGerador.toUpperCase() == 'VMAX';

    return L.geoJSON(geoJson, {
      pointToLayer: (feature, latlng) => {
        const marker = L.marker(latlng, {
          ...feature.properties,
          icon:
            markerIcon ||
            (geoJson.properties.markerIcon === 'RouteIcon'
              ? this._createRouteIcon
              : this._createMarkerIcon)(geoJson.properties),
        }).bindPopup(`
          <div id="${!isVmaxEvent ? 'mapPopupHeader' : 'mapPopupHeaderVmax'}">
            <span>${geoJson.properties.placaComApelido}</span>
          </div>
          <div id="mapPopupBody">
            <div class="popup-info-row">
              <div class="left">
                <i class="material-icons md-20"> gs_speed </i>
              </div>
              <div class="right">
                <span><b>${geoJson.properties.velocidade}</b> Kmh</span>
                <span>
                ${
                  !geoJson.properties.ignicao
                    ? `
                      <span style="color:#980A1A;font-weight:bold;">desligada</span>
                      <i class="material-icons" style="color:#980A1A; font-size: 10px;">lens</i>
                    `
                    : `
                      <span style="color:#4AAE4E;font-weight:bold;">ligada</span>
                      <i class="material-icons" style="color:#4AAE4E; font-size: 10px;">lens</i>
                    `
                }
                </span>
              </div>
            </div>
            <div class="popup-info-row" style="display: ${
              geoJson.properties.logradouro ? 'flex' : 'none'
            }">
              <div class="left">
                <i class="material-icons md-20"> gs_pos </i>
              </div>
              <div class="right">
                ${
                  isIframe()
                    ? `
                      <span>
                        ${geoJson.properties.logradouro}
                        <br />
                        ${geoJson.properties.municipio} - ${geoJson.properties.estado}
                      </span>
                    `
                    : `
                    <span>
                      <a class="gs-link"
                        target="_blank"
                        href="https://maps.google.com/maps?layer=c&q=${latlng.lat},${latlng.lng}&cbll=${latlng.lat},${latlng.lng}&cbp=11,0,0,0,0&z=17&ll=${latlng.lat},${latlng.lng}"
                      >
                        ${geoJson.properties.logradouro}
                        <br />
                        ${geoJson.properties.municipio} - ${geoJson.properties.estado}
                      </a>
                    </span>
                  `
                }
              </div>
            </div>
            <div class="popup-info-row">
              <div class="left">
                <i class="material-icons md-20"> gs_date </i>
              </div>
              <div class="right">
                <span>
                  ${
                    this._toDate(geoJson.properties.dataHora)
                      .toLocaleString()
                      .replace(',', '')
                      .split(' ')[0]
                  }
                  <b>
                    ${
                      this._toDate(geoJson.properties.dataHora)
                        .toLocaleString()
                        .replace(',', '')
                        .split(' ')[1]
                    }
                  </b>
                </span>
              </div>
            </div>
            ${geoJson.properties.infracoes.reduce(
              (acc, infracao) => `
                ${acc}
                <div class="popup-info-row">
                  <div class="left">
                    <i class="material-icons md-20" style="color:#9D0000"> gs_violation </i>
                  </div>
                  <div class="right">
                    <span style="color:#9D0000">${infracao.descricao}</span>
                  </div>
                </div>
              `,
              '',
            )}
            <div class="popup-info-row" style="background:#EDEDED">
              <div class="left">
                <i class="material-icons md-20"> gs_driver_b1 </i>
              </div>
              <div class="right">
                <span>${geoJson.properties.condutor || 'Não identificado'}</span>
              </div>
            </div>
          </div>
          <div id="mapPopupFooter">
            <span> Lat: ${Number(latlng.lat).toFixed(4)} </span>
            <span> Lon: ${Number(latlng.lng).toFixed(4)} </span>
          </div>
        `);
        if (geoJson.properties.markerIcon === 'RouteIcon') {
          marker.on('click', e => {
            const pathPosition = this.mapFeatureLayers.routeMarkers.getLayers().indexOf(e.target);

            this.$.dispatchEvent(
              new CustomEvent('routeMarkerTapped', {
                detail: { pathPosition },
              }),
            );
          });
        }

        return marker;
      },
    }).getLayers()[0];
  }

  _geoJsonToLGeoJson({ geoJson, markerIcon }) {
    return L.geoJSON(geoJson, {
      style: { color: geoJson.properties.color },
      pointToLayer: (feature, latlng) => {
        switch (feature.type) {
          case 'Polygon':
            return L.polygon(latlng, feature.properties);
          case 'Polyline':
            return L.polyline(latlng, feature.properties);
          case 'Rectangle':
            return L.rectangle(latlng, feature.properties);
          default:
            return feature.properties.radius
              ? L.circle(latlng, feature.properties)
              : L.marker(latlng, {
                  ...feature.properties,
                  icon: markerIcon || this._createMarkerIcon(feature.properties),
                });
        }
      },
    }).getLayers()[0];
  }

  _toggleRouteTimeline() {
    if (!this.routeMarkers || !this.routeLinestrings) {
      // eslint-disable-next-line no-console
      return console.warn('Missing routeMarkers or/and routeLinestrings Layers');
    }

    if (!this.context.routeTimelineEvent) {
      this.context.routeTimelineEvent = setInterval(() => {
        if (this.routeTimelinePosition < this.routeMarkers.length - 1) {
          this.routeTimelinePosition += 1;
          this.$scope.$emit('updateTimelineSlider', {
            value: this.routeTimelinePosition,
          });
        } else {
          this._endRouteTimeline();
        }
      }, 2000);

      if (this.routeTimelinePosition == this.routeMarkers.length - 1)
        this.routeTimelinePosition = 0;
      else this.routeTimelinePosition += 1;
      this.$scope.$emit('updateTimelineSlider', {
        value: this.routeTimelinePosition,
      });
    } else {
      clearInterval(this.context.routeTimelineEvent);
      this.context.routeTimelineEvent = null;
      this._renderRouteTimeline();
    }

    return null;
  }

  _endRouteTimeline() {
    clearInterval(this.context.routeTimelineEvent);
    this.context.routeTimelineEvent = null;

    Object.assign(this, {
      routeTimelinePosition: this.routeMarkers.length - 1,
    });

    if (this.$scope.$$phase === null && this.$rootScope.$$phase === null) {
      this.$scope.$apply();
    }

    this.$scope.$emit('updateTimelineSlider', {
      value: this.routeMarkers.length - 1,
    });

    this._renderRouteTimeline();
  }

  _renderRouteTimeline() {
    return this.renderDataset({
      dataset: this.routeMarkers,
      layerName: 'routeMarkers',
      type: 'MarkerFeatureGroup',
      useCluster: false,
      clusterColor: '#2196f3',
    })
      .then(() =>
        this.renderDataset({
          dataset: this.routeLinestrings,
          layerName: 'routeLinestrings',
          type: 'LinestringFeatureGroup',
        }),
      )
      .then(() => {
        if (!this.context.routeTimelineEvent && this.routeLinestrings.length > 0) {
          this.resizeMap();
          this.fitLayers(['routeLinestrings']);
        } else if ('getLayers' in this.mapFeatureLayers.routeMarkers) {
          const markersLayers = this.mapFeatureLayers.routeMarkers.getLayers();

          if (markersLayers.length < 1) return;

          const lastMarker = markersLayers[markersLayers.length - 1];
          this.mapLayer.setView(lastMarker.getLatLng(), 16, { animate: false });

          lastMarker.openPopup();
          lastMarker._bringToFront();
        }
      });
  }

  _toDate(date) {
    return moment(date).utcOffset(-3 - moment(date).utcOffset() / 60)._d;
  }
  /**/

  /* Observers */
  /**/
}

export { PowerMapUtilizationLeafletProvider };
