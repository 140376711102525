import angular from 'angular';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/directives/to-bytes/to-bytes';
import '@power/power-components/directives/trust-src/trust-src';

import template from './golfleet-file-list.html';
import './golfleet-file-list.scss';

class GolfleetFileListController {
  static get $inject() {
    return ['$element', '$scope', 'crudServices'];
  }

  constructor($element, $scope, crudServices) {
    Object.assign(this, { $: $element[0], $scope, crudServices });

    this.accept = 'video/*, image/*, .txt, .doc, .xls, .xlsx, .pdf';
    this.fileList = [];
    this.actualFileIndex = 0;
    this.warning = null;
  }

  $onInit() {
    this.$.querySelector('#file-input').onchange = this.__onInputFileChanged.bind(this);
    Object.assign(this.$, {
      getFileList: this.getFileList.bind(this),
      addFileList: this.addFileList.bind(this),
    });
    if (!this.maxFileSize) {
      this.maxFileSize = 15728640;
    }
    if (!this.maxFileList) {
      this.maxFileList = 10;
    }
  }

  _addFile() {
    if (this.fileList.length < this.maxFileList) {
      this.$.querySelector('#file-input').click();
    } else {
      this.warning = `Limite máximo de ${this.maxFileList} arquivos`;
    }
  }

  _removeFile(index) {
    if (this.fileList.length <= this.maxFileList) {
      this.warning = null;
    }

    const popup = this.$.querySelector('#file-list-popup');

    if (this.fileList.length === 0 && popup.hasAttribute('open')) {
      popup.toggle();
    }

    if (this.actualFileIndex >= this.fileList.length - 1) {
      this.actualFileIndex -= 1;
    }

    this.fileList.splice(index, 1);
  }

  _updateFileIndex(value = 1) {
    this.actualFileIndex += value;
    this._updateFileContent(this.actualFileIndex);
  }

  async _openDialog(index) {
    this.actualFileIndex = index;
    this._updateFileContent(index);
    this.$.querySelector('#file-list-popup').toggle();
  }

  async _downloadFile(file) {
    const blob = await (await fetch(file.contentBase64)).blob();
    const fileUrl = window.URL.createObjectURL(blob, { type: file.type });
    const downloadLink = document.createElement('a');

    downloadLink.download = `${file.name}${file.extension}`;
    downloadLink.href = fileUrl;
    downloadLink.click();
  }

  _fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  async _updateFileContent(index) {
    if (this.fileList[index].contentBase64 == null) {
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );
      const payload = {
        id: this.fileList[index].id,
      };
      this.crudServices
        .getData(this.getDataMethodFile, payload)
        .then(data => {
          this.fileList[index].contentBase64 = data.conteudoBase64;
        })
        .finally(() =>
          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          ),
        );
    }
  }

  _closePopUp() {
    const video = document.querySelector('video');
    if (video) {
      if (video.currentTime > 0) {
        video.pause();
      }
    }
  }

  /* Public */
  getFileList() {
    return this.fileList;
  }

  addFileList(file) {
    this.fileList.push(file);
  }
  /* */

  async __onInputFileChanged() {
    const [file] = this.$.querySelector('#file-input').files;

    if (file) {
      if (file.size > this.maxFileSize) {
        this.warning = `Tamanho máximo do arquivo: ${this.maxFileSize / 1048576} MB`;
      } else {
        this.warning = null;
        const extension = /\.\w+$/.exec(file.name);

        const contentBase64 = await this._fileToBase64(file);

        this.fileList.push({
          type: file.type,
          name: file.name.substring(0, extension.index),
          size: file.size,
          extension: extension[0],
          contentBase64,
        });
      }

      if (!this.$scope.$$phase) {
        this.$scope.$apply();
      }
    }
  }
}

class GolfleetFileList {
  constructor() {
    this.template = template;
    this.bindings = {
      accept: '=?',
      fileList: '=?',
      maxFileSize: '=?',
      maxFileList: '=?',
      getDataMethodFile: '=',
    };
    this.transclude = {};
    this.controller = GolfleetFileListController;
  }
}

angular
  .module('golfleet-file-list', ['trust-src', 'to-bytes', 'power-popup'])
  .component('golfleetFileList', new GolfleetFileList());
