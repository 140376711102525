/* eslint-disable camelcase */
import Dashboard_DriverModule from './default/Dashboard_DriverModule.json';
import Dashboard_FuelModule from './default/Dashboard_FuelModule.json';
import Dashboard_FuelModule_InfoAddon from './default/Dashboard_FuelModule_InfoAddon.json';
import Dashboard_FuelModule_InfoAddon_Trip from './default/Dashboard_FuelModule_InfoAddon_Trip.json';
import Dashboard_FuelModule_Trip from './default/Dashboard_FuelModule_Trip.json';
import Dashboard_Home from './default/Dashboard_Home.json';
import Dashboard_Home_Trip from './default/Dashboard_Home_Trip.json';
import Dashboard_SpeedLimitModule from './default/Dashboard_SpeedLimitModule.json';
import Dashboard_SpeedLimitModule_InfoAddon from './default/Dashboard_SpeedLimitModule_InfoAddon.json';
import Dashboard_SpeedLimitModule_InfoAddon_Trip from './default/Dashboard_SpeedLimitModule_InfoAddon_Trip.json';
import Dashboard_SpeedLimitModule_Trip from './default/Dashboard_SpeedLimitModule_Trip.json';
import Dashboard_MaintenanceModule_InfoAddon from './default/Dashboard_MaintenanceModule_InfoAddon.json';
import Dashboard_TcoModule_InfoAddon from './default/Dashboard_TcoModule_InfoAddon.json';
import Dashboard_Announcements from './default/Dashboard_Announcements.json';
// Tracking
import Dashboard_Home_Tracking from './tracking/Dashboard_Home_Tracking.json';
// New Dashboard
import Dashboard_Tco from './default/Dashboard_Tco.json';
import Dashboard_Travels from './default/Dashboard_Travels.json';
import Dashboard_Violation_Trip from './default/Dashboard_Violation_Trip.json';
import Dashboard_Violation_Tracking from './tracking/Dashboard_Violation_Tracking.json';
import Dashboard_Utilization_Trip from './default/Dashboard_Utilization_Trip.json';

export default {
  Dashboard_DriverModule,
  Dashboard_FuelModule,
  Dashboard_FuelModule_InfoAddon,
  Dashboard_FuelModule_InfoAddon_Trip,
  Dashboard_FuelModule_Trip,
  Dashboard_Home,
  Dashboard_Home_Trip,
  Dashboard_SpeedLimitModule,
  Dashboard_SpeedLimitModule_InfoAddon,
  Dashboard_SpeedLimitModule_InfoAddon_Trip,
  Dashboard_SpeedLimitModule_Trip,
  Dashboard_MaintenanceModule_InfoAddon,
  Dashboard_TcoModule_InfoAddon,
  Dashboard_Announcements,
  // Tracking
  Dashboard_Home_Tracking,
  // New Dashboard
  Dashboard_Tco,
  Dashboard_Travels,
  Dashboard_Violation_Trip,
  Dashboard_Violation_Tracking,
  Dashboard_Utilization_Trip,
};
