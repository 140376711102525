import angular from 'angular';
import moment from 'moment';
import $ from 'jquery';
import * as Comlink from 'comlink';
import 'air-datepicker';
import 'air-datepicker/dist/css/datepicker.min.css';

import { PowerFilterController, PowerFilter } from '../power-filter/power-filter';
import '../../directives/infinite-scroll/infinite-scroll';
import '../power-dropdown/power-dropdown';

import template from './power-filter-calendar.html';
import './power-filter-calendar.scss';

class PowerFilterCalendarController extends PowerFilterController {
  static get $inject() {
    return ['$element', '$ngRedux', '$rootScope', '$scope', '$http', 'urlApi'];
  }

  constructor($element, $ngRedux, $rootScope, $scope, $http, urlApi) {
    super($element, $scope, $http, urlApi);
    Object.assign(this, { $ngRedux, $rootScope });

    this.__powerStore = $ngRedux.connect(store => Object({ isTrip: store.session.isTrip }))(
      this,
    );

    this.icon = '';
    this.filterId = '';
    this.timepicker = false;
    this.activeView = 'preset';
    this.calendarView = 'days';
    this.activeCalendar = 'startDate';
    this.minInterval = 0;
    this.maxInterval = 3;
    this.minIntervalType = 'day';
    this.maxIntervalType = 'month';

    this.startDate = {
      calendar: null,
      hour: 0,
      minute: 0,
    };
    this.endDate = {
      calendar: null,
      hour: 23,
      minute: 59,
    };
    this.search = '';
    this.options = [];
    this.hourList = [];
    this.minuteList = [];
    this.default = [];
    this.condition = {};
    this.description = 'Carregando...';
    this.previewDescription = this.description;

    this.worker = new Worker('./power-filter-calendar.worker.js');
    this.workerService = Comlink.wrap(this.worker);

    $.fn.datepicker.language['pt-BR'] = {
      daysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      daysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      days: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
      monthsShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
      ],
      months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
      today: 'Hoje',
      clear: 'Limpar',
      dateFormat: 'dd/mm/yyyy',
      timeFormat: 'hh:ii',
      firstDay: 0,
    };
    for (let hour = 0; hour < 24; hour += 1) {
      this.hourList.push({ id: hour, description: `${hour < 10 ? '0' : ''}${hour}`, value: hour });
    }
    for (let minute = 0; minute < 60; minute += 1) {
      this.minuteList.push({
        id: minute,
        description: `${minute < 10 ? '0' : ''}${minute}`,
        value: minute,
      });
    }
  }

  /* Lifecycle */
  $onInit() {
    this.$.loading = true;

    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
      setPresetFilterValue: this.setPresetFilterValue.bind(this),
      setCalendarFilterValue: this.setCalendarFilterValue.bind(this),
      getNumberOfDays: this.getNumberOfDays.bind(this),
    });

    const _calendarConfig = {
      language: 'pt-BR',
      navTitles: { days: 'MM de yyyy' },
      view: this.calendarView,
      minView: this.calendarView,
      minDate: moment().year(2008).month(0).date(1)._d,
      maxDate: moment().hour(23).minute(59).second(59)._d,
      range: false,
      timepicker: false,
      toggleSelected: false,
      onSelect: this.__onSelect.bind(this),
      onChangeView: this.__onChangeView.bind(this),
      onChangeYear: this.__onChangeYear.bind(this),
      onChangeMonth: this.__onChangeMonth.bind(this),
      onChangeDecade: this.__onChangeDecade.bind(this),
    };

    this.startDate.calendar = $(this.$.querySelector('#startdate-calendar'))
      .datepicker({ ..._calendarConfig })
      .data('datepicker');
    this.endDate.calendar = $(this.$.querySelector('#enddate-calendar'))
      .datepicker({ ..._calendarConfig })
      .data('datepicker');

    this._setup().then(() => {
      this.$.loading = false;
    });
  }

  $onDestroy() {
    this.__powerStore();
  }
  /* */

  /* Public */
  toggle() {
    document.querySelector('power-filter-menu').toggleFilter(this.filterId);
  }

  async setPresetFilterValue(preset = {}) {
    await this.workerService.setFilterValue({ preset });

    await this._changeActiveView('preset');

    await this._syncState();

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('setPresetFilterValue'));
  }

  async setCalendarFilterValue(value = []) {
    await this._changeActiveView('calendar');

    const endDate = this._getDateWithoutUTF(value[1], 0);
    const startDate = this._getDateWithoutUTF(value[0], 0);

    this.startDate.hour = startDate.hour();
    this.startDate.minute = startDate.minute();
    this.startDate.calendar.selectDate(this._getDateWithoutUTF(value[0], -1)._d);

    this.endDate.hour = endDate.hour();
    this.endDate.minute = endDate.minute();
    this.endDate.calendar.selectDate(this._getDateWithoutUTF(value[1], -1)._d);

    this.$.dispatchEvent(new CustomEvent('setCalendarFilterValue'));
  }

  getNumberOfDays() {
    if (this.condition) {
      const startDate = this._getDateWithoutUTF(this.condition.value[0], 0);
      const endDate = this._getDateWithoutUTF(this.condition.value[1], 0);
      return Math.round((endDate._d - startDate._d) / 86400000);
    }

    return 0;
  }
  /* */

  /* Private */
  async _setup() {
    await this.workerService.setup({
      id: this.filterId,
      field: this.field,
      options: this.options,
      usePreset: this.activeView === 'preset',
      condition: this.condition,
      timepicker: this.timepicker,
      defaultCondition: this.default,
    });

    await this._syncState();

    const endDate = this._getDateWithoutUTF(this.condition.value[1], 0);
    const startDate = this._getDateWithoutUTF(this.condition.value[0], 0);

    this.startDate.hour = startDate.hour();
    this.startDate.minute = startDate.minute();
    this.startDate.calendar.selectDate(this._getDateWithoutUTF(this.condition.value[0], -1)._d);

    this.endDate.hour = endDate.hour();
    this.endDate.minute = endDate.minute();
    this.endDate.calendar.selectDate(this._getDateWithoutUTF(this.condition.value[1], -1)._d);

    this.activeView = !this.condition.preset ? 'calendar' : 'preset';

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('setup'));
    this.$.dispatchEvent(new CustomEvent('activeViewChanged'));
  }

  async _syncState() {
    this.options = await this.workerService.filteredOptions;
    this.condition = await this.workerService.condition;
    this.description = await this.workerService.description;
    this.previewDescription = await this.workerService.previewDescription;

    this.$.dispatchEvent(new CustomEvent('syncState'));
    this.$.dispatchEvent(new CustomEvent('presetChanged'));
    this.$.dispatchEvent(new CustomEvent('optionsChanged'));
    this.$.dispatchEvent(new CustomEvent('conditionChanged'));
    this.$.dispatchEvent(new CustomEvent('descriptionChanged'));
  }

  async _selectTime(value, calendar, type) {
    this[calendar][type] = value;

    await this.workerService.setFilterValue({
      value: [
        this._getDateWithoutUTF(this.startDate.calendar.selectedDates[0])
          .hour(this.startDate.hour)
          .minute(this.startDate.minute)
          .second(0)._d,
        this._getDateWithoutUTF(this.endDate.calendar.selectedDates[0])
          .hour(this.endDate.hour)
          .minute(this.endDate.minute)
          .second(59)._d,
      ],
    });

    await this._syncState();

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('timeChanged'));
  }

  async _toggleOptionSelection(selectedOption) {
    await this.workerService.toggleOptionsSelection({ options: [selectedOption] });

    await this._syncState();

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('toggleOptionSelection'));
  }

  async _changeActiveView(view) {
    this.activeView = view;

    await this.workerService.setUsePreset({ usePreset: this.activeView === 'preset' });

    await this._syncState();

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('activeViewChanged'));
  }

  requestUpdate() {
    if (!this.$rootScope.$$phase && !this.$scope.$$phase) {
      this.$scope.$apply();
    }
  }

  _getDateWithoutUTF(date, modifier = 1) {
    return moment(date).utcOffset((modifier * moment(date).utcOffset()) / 60);
  }

  _updateOtherSelected() {
    const endSelectedDate = this._getDateWithoutUTF(this.endDate.calendar.selectedDates[0]);
    const startSelectedDate = this._getDateWithoutUTF(this.startDate.calendar.selectedDates[0]);

    const betweenSelected = async (calendar, startSelected, endSelected) => {
      const startDate = startSelected.date();
      const startMonth = startSelected.month();
      const startYear = startSelected.year();
      const endDate = endSelected.date();
      const endMonth = endSelected.month();
      const endYear = endSelected.year();
      calendar.el.querySelectorAll('.datepicker--cell').forEach(node => {
        let currentViewYear = null;
        let isBetweenSelected = false;

        switch (calendar.currentView) {
          case 'days':
            isBetweenSelected =
              /* Between Dates on the same Month and same Year */
              (node.getAttribute('data-date') > startDate &&
                node.getAttribute('data-date') < endDate &&
                node.getAttribute('data-month') === `${startMonth}` &&
                node.getAttribute('data-month') === `${endMonth}` &&
                node.getAttribute('data-year') === `${startYear}` &&
                node.getAttribute('data-year') === `${endYear}`) ||
              /* Between Months on the same Year */
              (node.getAttribute('data-month') > startMonth &&
                node.getAttribute('data-month') < endMonth &&
                node.getAttribute('data-year') === `${startYear}` &&
                node.getAttribute('data-year') === `${endYear}`) ||
              (node.getAttribute('data-date') > startDate &&
                node.getAttribute('data-month') === `${startMonth}` &&
                node.getAttribute('data-month') < endMonth &&
                node.getAttribute('data-year') === `${startYear}` &&
                node.getAttribute('data-year') === `${endYear}`) ||
              (node.getAttribute('data-date') < endDate &&
                node.getAttribute('data-month') > startMonth &&
                node.getAttribute('data-month') === `${endMonth}` &&
                node.getAttribute('data-year') === `${startYear}` &&
                node.getAttribute('data-year') === `${endYear}`) ||
              /* Between Years */
              (node.getAttribute('data-year') > startYear &&
                node.getAttribute('data-year') < endYear) ||
              (node.getAttribute('data-month') > startMonth &&
                node.getAttribute('data-year') === `${startYear}` &&
                node.getAttribute('data-year') < endYear) ||
              (node.getAttribute('data-month') < endMonth &&
                node.getAttribute('data-year') > startYear &&
                node.getAttribute('data-year') === `${endYear}`) ||
              (node.getAttribute('data-date') > startDate &&
                node.getAttribute('data-month') === `${startMonth}` &&
                node.getAttribute('data-year') === `${startYear}` &&
                node.getAttribute('data-year') < endYear) ||
              (node.getAttribute('data-date') < endDate &&
                node.getAttribute('data-month') === `${endMonth}` &&
                node.getAttribute('data-year') > startYear &&
                node.getAttribute('data-year') === `${endYear}`);
            break;
          case 'months':
            currentViewYear = moment(calendar.views.months.d.currentDate).year();
            isBetweenSelected =
              /* Months between same Year */
              (node.getAttribute('data-month') > startMonth &&
                node.getAttribute('data-month') < endMonth &&
                currentViewYear === startYear &&
                currentViewYear === endYear) ||
              /* Months between Years */
              (currentViewYear > startYear && currentViewYear < endYear) ||
              (node.getAttribute('data-month') > startMonth &&
                currentViewYear === startYear &&
                currentViewYear < endYear) ||
              (node.getAttribute('data-month') < endMonth &&
                currentViewYear > startYear &&
                currentViewYear === endYear);
            break;
          case 'years':
            isBetweenSelected =
              node.getAttribute('data-year') > startYear &&
              node.getAttribute('data-year') < endYear;
            break;
          default:
            break;
        }

        if (isBetweenSelected) {
          node.classList.add('-between-selected-');
        } else {
          node.classList.remove('-between-selected-');
        }
      });
    };
    const setOtherSelected = async (calendar, otherSelected) => {
      const otherDate = otherSelected.date();
      const otherMonth = otherSelected.month();
      const otherYear = otherSelected.year();
      let otherDateNode = null;
      let currentViewYear = null;

      switch (calendar.currentView) {
        case 'days':
          otherDateNode = calendar.el.querySelector(
            `.datepicker--cells-days [data-date="${otherDate}"]` +
              `[data-month="${otherMonth}"][data-year="${otherYear}"]`,
          );
          if (otherDateNode) otherDateNode.classList.add('-other-selected-');
          break;
        case 'months':
          currentViewYear = moment(calendar.views.months.d.currentDate).year();
          if (currentViewYear === otherYear) {
            otherDateNode = calendar.el.querySelector(
              `.datepicker--cells-months [data-month="${otherMonth}"]`,
            );
            if (otherDateNode) otherDateNode.classList.add('-other-selected-');
          }
          break;
        case 'years':
          otherDateNode = calendar.el.querySelector(
            `.datepicker--cells-years [data-year="${otherYear}"]`,
          );
          if (otherDateNode) otherDateNode.classList.add('-other-selected-');
          break;
        default:
          break;
      }
    };
    const clearOtherSelected = calendar => {
      const otherSelected = calendar.el.querySelector('.-other-selected-');
      if (otherSelected) otherSelected.classList.remove('-other-selected-');
    };

    clearOtherSelected(this.startDate.calendar);
    setOtherSelected(this.startDate.calendar, endSelectedDate);
    betweenSelected(this.startDate.calendar, startSelectedDate, endSelectedDate);

    clearOtherSelected(this.endDate.calendar);
    setOtherSelected(this.endDate.calendar, startSelectedDate);
    betweenSelected(this.endDate.calendar, startSelectedDate, endSelectedDate);
  }

  _changeActiveCalendar(calendar) {
    this.activeCalendar = calendar;

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('activeCalendarChanged'));
  }
  /* */

  /* Observers */
  async __onSelect() {
    let [endDate] = this.endDate.calendar.selectedDates;
    let [startDate] = this.startDate.calendar.selectedDates;

    endDate = endDate || startDate;
    startDate = startDate || endDate;

    if (this.maxInterval || this.maxInterval === 0) {
      if (this.activeCalendar === 'startDate') {
        const maxDate = moment(startDate).add(this.maxInterval, this.maxIntervalType);
        if (maxDate.isBefore(endDate, this.calendarView)) {
          this.endDate.calendar.selectDate(maxDate._d);
          return;
        }
      } else {
        const maxDate = moment(endDate).subtract(this.maxInterval, this.maxIntervalType);
        if (maxDate.isAfter(startDate, this.calendarView)) {
          this.startDate.calendar.selectDate(maxDate._d);
          return;
        }
      }
    }
    if (this.minInterval || this.minInterval === 0) {
      if (this.activeCalendar === 'startDate') {
        const minDate = moment(startDate).add(this.minInterval, this.minIntervalType);
        if (moment(startDate).isSame(this.endDate.calendar.maxDate, this.calendarView)) {
          if (this.minInterval > 0) {
            this.startDate.calendar.selectDate(
              moment(startDate).subtract(this.minInterval, this.minIntervalType)._d,
            );
            return;
          }
        }
        if (
          minDate.isAfter(endDate, this.calendarView) &&
          !minDate.isAfter(moment(), this.calendarView)
        ) {
          this.endDate.calendar.selectDate(minDate._d);
          return;
        }
      } else {
        const minDate = moment(endDate).subtract(this.minInterval, this.minIntervalType);
        if (moment(endDate).isSame(this.startDate.calendar.minDate, this.calendarView)) {
          if (this.minInterval > 0) {
            this.endDate.calendar.selectDate(
              moment(endDate).add(this.minInterval, this.minIntervalType)._d,
            );
            return;
          }
        }
        if (
          minDate.isBefore(startDate, this.calendarView) &&
          !minDate.isAfter(moment(), this.calendarView)
        ) {
          this.startDate.calendar.selectDate(minDate._d);
          return;
        }
      }
    }

    if (this.activeView === 'calendar') {
      endDate = this._getDateWithoutUTF(endDate)
        .hour(this.endDate.hour)
        .minute(this.endDate.minute)
        .second(59)
        .millisecond(999);
      startDate = this._getDateWithoutUTF(startDate)
        .hour(this.startDate.hour)
        .minute(this.startDate.minute)
        .second(0)
        .millisecond(0);

      if (this.calendarView === 'months') {
        endDate = endDate.date(
          moment().isSame(endDate, 'month') ? moment().date() : endDate.daysInMonth(),
        );
        startDate = startDate.date(1);
      }

      await this.workerService.setFilterValue({ value: [startDate._d, endDate._d] });

      await this._syncState();

      this._changeActiveCalendar(this.activeCalendar === 'startDate' ? 'endDate' : 'startDate');

      this.$.dispatchEvent(new CustomEvent('calendarDateChanged'));
    }

    requestAnimationFrame(() => this._updateOtherSelected());
  }

  async __onSearchChanged() {
    await this.workerService.filterOptions({ search: this.search });

    await this._syncState();

    this.requestUpdate();
  }

  __onChangeView() {
    requestAnimationFrame(() => this._updateOtherSelected());
  }

  __onChangeYear() {
    requestAnimationFrame(() => this._updateOtherSelected());
  }

  __onChangeMonth() {
    requestAnimationFrame(() => this._updateOtherSelected());
  }

  __onChangeDecade() {
    requestAnimationFrame(() => this._updateOtherSelected());
  }

  _searchFilterOption(value) {
    if (this.condition && this.condition.preset && this.condition.preset._search) {
      return new RegExp(
        this.preset._search
          .toLowerCase()
          .replace(/^( *, *)+|( *, *)+$/g, '')
          .replace(/( *, *)+/g, '|'),
      ).test(value.description.toLowerCase());
    }
    return true;
  }
  /* */
}

class PowerFilterCalendar extends PowerFilter {
  constructor() {
    super();
    Object.assign(this.bindings, {
      previewDescription: '=?',
      minInterval: '=?',
      maxInterval: '=?',
      minIntervalType: '=?',
      maxIntervalType: '=?',
      calendarView: '=?',
      timepicker: '=?',
    });
    this.template = template;
    this.controller = PowerFilterCalendarController;
  }
}

angular
  .module('power-filter-calendar', ['infinite-scroll', 'power-dropdown'])
  .component('powerFilterCalendar', new PowerFilterCalendar());
