import angular from 'angular';
import { PowerGridController } from '@power/power-components/components/power-grid/power-grid';

import template from './golfleet-grid-maintenance.html';
import './golfleet-grid-maintenance.scss';

class GolfleetGridMaintenanceController extends PowerGridController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$scope',
      '$state',
      '$http',
      '$timeout',
      '$filter',
      'commonServices',
      'urlApi',
      'crudServices',
    ];
  }

  constructor(
    $element,
    $ngRedux,
    $scope,
    $state,
    $http,
    $timeout,
    $filter,
    commonServices,
    urlApi,
    crudServices,
  ) {
    super($element, $scope, $state, $http, $timeout, $filter, commonServices, urlApi, $ngRedux);
    Object.assign(this, {
      $: $element[0],
      $ngRedux,
      $scope,
      $state,
      $http,
      $timeout,
      $filter,
      commonServices,
      urlApi,
      crudServices,
    });

    this.__appBehavior = $ngRedux.connect(behavior => {
      const { userId, userEmail, userName, isTrip, isAdm } = behavior.session;
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];

      return Object({
        sessionState: { userId, userEmail, userName, isTrip, isAdm },
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
      });
    })(this);
  }

  /* Lifecycle */
  $onDestroy() {
    this.__appBehavior();
  }

  _selectRow(column, row) {
    return (
      super._selectRow(column, row) &&
      (!Object.prototype.hasOwnProperty.call(row, column.field) || !!row[column.field])
    );
  }

  _canClick(row, header) {
    if (header.field === 'details') {
      return row.canEdit;
    }

    return true;
  }
}

class GolfleetGridMaintenance {
  constructor() {
    this.template = template;
    this.bindings = {
      /* common */
      datasetMethod: '=?',
      isPaginated: '=?',
      page: '=?',
      pageSize: '=?',
      pageRows: '=?',
      lastPage: '=?',
      datasetLength: '=?',
      hasRowSelection: '=?',
      dateGranularity: '=?',
      /* underscore */
      gridHeaders: '=?',
      gridDataset: '=?',
      gridHeadersCategories: '=?',
      sortHeader: '=?',
      sortDirection: '=?',
      selectedRows: '=?',
      /* duble underscore */
      mainHeader: '=?',
      mongoGridId: '=?',
      headerParams: '=?',
      datasetParams: '=?',
    };
    this.controller = GolfleetGridMaintenanceController;
  }
}

angular
  .module('golfleet-grid-maintenance', [])
  .component('golfleetGridMaintenance', new GolfleetGridMaintenance());
