import angular from 'angular';
import 'ng-redux';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-toolbar/power-toolbar';
import '../golfleet-grid-tracking-real-time/golfleet-grid-tracking-real-time';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-pagination/power-pagination';
import '../golfleet-popup-terms-of-use/golfleet-popup-terms-of-use';
import { PowerReportController } from '@power/power-components/components/power-report/power-report';

import template from './golfleet-report-tracking-real-time.html';
import './golfleet-report-tracking-real-time.scss';

class GolfleetReportTrackingRealTimeController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
      'trackingRealTimeServices',
    ];
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
    trackingRealTimeServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );
    Object.assign(this, { trackingRealTimeServices });
    this.popupHistoryTabSelected = 1;

    this.terms = {
      icon: 'gs_loc',
      title: 'Deseja ativar o acompanhamento em tempo real?',
      subtitle:
        'Ao ativar a funcionalidade, você declara estar de acordo com seus termos e tarifa.',
      term: `Fica ciente o Contratante que ao clicar a funcionalidade é acionada efetivando a sua
      contratação e cobrança, a qual ocorrerá na próxima fatura. A cobrança ocorre da seguinte
      forma: a cada 30 minutos é considerada uma utilização pelo usuário no valor de R$ 6,49
      (seis reais e quarenta e nove centavos), sendo que o cliente escolherá o tempo que deseja que a funcionalidade
      esteja ativa, mediante clique em "Configurar duração", sendo que ultrapassado o período
      será necessário a sua renovação. O tempo mínimo de funcionamento por acionamento é de 30
      minutos e o máximo é de 5 horas.`,
      cost: 'R$6,49',
      period: '30 minutos',
    };
  }

  _callAction(action) {
    switch (action.actionType) {
      case 'active':
        this.$.querySelector('golfleet-popup-terms-of-use').togglePopup();
        break;
      case 'inactive':
        this._activeInactiveSelectedItens(false);
        break;
      default:
        break;
    }
  }

  _activeInactiveSelectedItens(active) {
    const objects = this.selectedRows
      .filter(row => {
        if (row.dealerId !== this.clientId || row.clientId === this.clientId)
          return row.clientPermission !== active;
        return row.dealerPermission !== active;
      })
      .map(row => ({ clientId: row.clientId, dealerId: row.dealerId }));

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    const payload = {
      objects,
      isActive: active,
    };

    this.trackingRealTimeServices.activeInactiveRealTimePermission(payload).then(
      success => {
        if (success.data && success.data.data) {
          this.toastText = `${active ? 'Ativado' : 'Inativado'} com sucesso.`;
          this.requestDataset();
        } else this.toastText = `Não foi possivel ${active ? 'Ativar' : 'Inativar'}.`;

        this.$.querySelector('power-toast#report-toast').toggle(true);
      },
      () => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
        this.toastText = `Não foi possivel ${active ? 'Ativar' : 'Inativar'}.`;
        this.$.querySelector('power-toast#report-toast').toggle(true);
      },
    );
  }

  _headerTitle(title) {
    if (!Array.isArray(title)) return title;

    return title.join(' ');
  }
}

class GolfleetReportTrackingRealTime {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetReportTrackingRealTimeController;
  }
}

angular
  .module('golfleet-report-tracking-real-time', [
    'ngRedux',
    'power-fab',
    'power-dropdown',
    'power-toolbar',
    'golfleet-grid-tracking-real-time',
    'power-footer',
    'power-pagination',
    'golfleet-popup-terms-of-use',
  ])
  .component('golfleetReportTrackingRealTime', new GolfleetReportTrackingRealTime());
