import angular from 'angular';
import { isMobile } from 'mobile-device-detect';
import '../power-popup/power-popup';

import template from './power-popup-privacy-policy.html';
import './power-popup-privacy-policy.scss';

class PowerPopupPrivacyPolicyController {
  static get $inject() {
    return ['$element', '$ngRedux', '$http', 'urlApi'];
  }

  constructor($element, $ngRedux, $http, urlApi) {
    Object.assign(this, { $: $element[0], $ngRedux, $http, urlApi });

    this.__appStore = $ngRedux.connect(store =>
      Object({
        userEmail: store.session.userEmail,
        isTrip: store.session.isTrip,
        isSingleSignon: store.session.isSingleSignon,
        privacyPolicyStatus: store.session.privacyPolicyStatus,
      }),
    )(this);
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      show: this.show.bind(this),
      close: this.close.bind(this),
    });
  }

  $onDestroy() {
    this.__appStore();
  }
  /**/

  /* Public */
  show() {
    const popopElement = this.$.querySelector('#power-popup-privacy-policy');
    if (this.isTrip && !this.isSingleSignon && !popopElement.hasAttribute('open')) {
      popopElement.toggle();
    }
  }

  close() {
    const popopElement = this.$.querySelector('#power-popup-privacy-policy');
    if (this.isTrip && !this.isSingleSignon && popopElement.hasAttribute('open')) {
      popopElement.toggle();
    }
  }

  /**/

  /* Private */
  _toggleCheckBoxAccept() {
    const checkBoxElement = this.$.querySelector('#power-popup-privacy-policy-accept');
    const buttonElement = this.$.querySelector('#power-popup-privacy-policy-confirm-button');

    if (checkBoxElement.hasAttribute('checked')) {
      checkBoxElement.removeAttribute('checked');
      buttonElement.setAttribute('disabled', '');
    } else {
      checkBoxElement.setAttribute('checked', '');
      buttonElement.removeAttribute('disabled');
    }
  }

  _toggleCheckBoxSendEmailCopy() {
    const checkBoxElement = this.$.querySelector('#power-popup-privacy-policy-send-email-copy');

    if (checkBoxElement.hasAttribute('checked')) {
      checkBoxElement.removeAttribute('checked');
    } else {
      checkBoxElement.setAttribute('checked', '');
    }
  }

  _togglePopupPdf() {
    const popupPdfElement = this.$.querySelector('#power-popup-privacy-policy-pdf');
    popupPdfElement.toggle();
  }

  _showPrivacyPolicyDocument() {
    if (isMobile) {
      const link = document.createElement('a');
      link.target = '_blank';
      link.href = this.privacyPolicyStatus.url;
      link.click();
    } else {
      const objectElement = document.createElement('embed');
      objectElement.type = 'application/pdf';
      objectElement.src = `${this.privacyPolicyStatus.url}#toolbar=0&navpanes=0&scrollbar=0`;

      const containerElement = this.$.querySelector('#power-popup-privacy-policy-pdf-container');
      containerElement.innerHTML = '';
      containerElement.appendChild(objectElement);

      this._togglePopupPdf();
    }
  }

  async _declarationOfAcceptanceButtonClick() {
    const checkBoxAcceptElement = this.$.querySelector('#power-popup-privacy-policy-accept');
    const checkBoxSendEmailCopyElement = this.$.querySelector(
      '#power-popup-privacy-policy-send-email-copy',
    );
    const acceptButtonElement = this.$.querySelector('#power-popup-privacy-policy-confirm-button');

    if (checkBoxAcceptElement.hasAttribute('checked')) {
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );

      const sendEmailWithCopy = checkBoxSendEmailCopyElement.hasAttribute('checked');
      const { id: privacyPolicyId } = this.privacyPolicyStatus;

      const response = await this.$http({
        url: `${this.urlApi}/PrivacyPolicy/Accept`,
        method: 'POST',
        data: {
          email: this.userEmail,
          privacyPolicyId,
          sendEmailWithCopy,
        },
      });

      const { data } = response.data;

      if (data) {
        this.close();
        checkBoxAcceptElement.removeAttribute('checked');
        checkBoxSendEmailCopyElement.removeAttribute('checked');
        acceptButtonElement.setAttribute('disabled', '');
      }

      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: false },
          bubbles: true,
          composed: true,
        }),
      );
    }
  }
  /**/
}

class PowerPopupPrivacyPolicy {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = PowerPopupPrivacyPolicyController;
  }
}
angular
  .module('power-popup-privacy-policy', ['power-popup'])
  .component('powerPopupPrivacyPolicy', new PowerPopupPrivacyPolicy());

export { PowerPopupPrivacyPolicy, PowerPopupPrivacyPolicyController };
