import angular from 'angular';
import { eventOnce } from '@power/power-components/utils/event-once';

import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-toast/power-toast';

import template from './golfleet-popup-workshop.html';
import './golfleet-popup-workshop.scss';

/**
 * Address
 */
export class Address {
  /**
   * - Constructor
   * @param {Object} workshop
   */
  constructor(workshop) {
    this.city = workshop.city;
    this.district = workshop.district;
    this.houseNumber = workshop.houseNumber;
    this.label = workshop.label;
    this.zipCode = workshop.zipCode;
    this.state = workshop.state;
    this.street = workshop.street;
    this.latitude = workshop.latitude;
    this.longitude = workshop.longitude;
  }
}

class GolfleetPopupWorkshopController {
  static get $inject() {
    return ['$element', '$scope', 'workshopServices'];
  }

  constructor($element, $scope, workshopServices) {
    Object.assign(this, { $: $element[0], $scope, workshopServices });

    this.model = {};
  }

  /**
   * Lifecycle
   */
  $onInit() {
    Object.assign(this.$, {
      togglePopup: this.togglePopup.bind(this),
      setDataSet: this.setDataSet.bind(this),
    });
  }
  /* Lifecycle */

  /* Public */
  togglePopup() {
    this.$.querySelector('power-popup').toggle();
  }

  setDataSet(data) {
    this.model = data;
    if (this.model.id) {
      this.model.address = new Address(this.model);
      const { latitude: lat, longitude: lng } = this.model;
      if (lat && lng) {
        this.$.querySelector('golfleet-map-reverse-geocode').setMarker({ lat, lng });
      }
    }
  }
  /* */

  /* Private */
  _save() {
    if (this._validateModel()) {
      const { id, name, cnpj, address } = this.model;
      const payload = {
        id,
        name,
        cnpj: cnpj.replace(/[^0-9]+/g, ''),
        ...address,
      };

      this._showLoader(true);

      this.workshopServices.upsertWorkshop(payload).then(success => {
        this._showLoader(false);
        if (success.status && success.status !== 200) {
          this.toastObj = { text: success.data.data.toString() };
          this.$.querySelector('#golfleet-popup-report-workshop-toast').toggle(true);
          return;
        }

        Object.assign(this.model, { id: success.data.data });
        this.toastObj = { text: `Oficina ${payload.id ? 'editada' : 'cadastrada'}` };
        this.$.querySelector('#golfleet-popup-report-workshop-toast').toggle(true);
        this._closePopup();
        this.$.dispatchEvent(new CustomEvent('saved'));
      });
    }
  }

  _clearWarnings() {
    const fields = this.$.querySelectorAll('power-crud-textbox');
    fields.forEach(field => {
      field.setWarning(null);
    });
  }

  _validateModel() {
    this._clearWarnings();

    let fieldErrorCount = 0;
    if (!this.model.name) {
      this.$.querySelector('#workshop-name').setWarning('Campo obrigatório');
      fieldErrorCount++;
    }
    if (!this.model.cnpj) {
      this.$.querySelector('#workshop-cnpj').setWarning('Cnpj inválido');
      fieldErrorCount++;
    }
    if (!this.model.address || !this.model.address.latitude || !this.model.address.longitude) {
      this.toastObj = { text: 'Selecione um endereço válido no mapa' };
      this.$.querySelector('#golfleet-popup-report-workshop-toast').toggle(true);
      fieldErrorCount++;
    }

    return fieldErrorCount === 0;
  }

  _closePopup() {
    eventOnce({
      element: this.$,
      event: 'transitionend',
      trigger: () => this.togglePopup(),
      action: () => this.$.querySelector('golfleet-map-reverse-geocode').resetMap(),
    });

    this._clearWarnings();
  }

  _showLoader(showLoader) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader },
        bubbles: true,
        composed: true,
      }),
    );
  }
  /* */
}

class GolfleetPopupWorkshop {
  constructor() {
    this.template = template;
    this.bindings = {
      headerDescription: '=?',
      userFunctionalities: '=?',
      userFunctionalitiesSeparator: '=?',
      selectedItems: '=?',
      userFunctionalitiesIgnoreCase: '<',
      requestDataOnInit: '<',
      showButtonNewEmail: '<',
      multiSelect: '<',

      /* Callbacks */
      openPopupCallback: '&?',
      confirmCallback: '&?',
      requestDataOnInitCallback: '&?',
    };
    this.controller = GolfleetPopupWorkshopController;
  }
}

angular
  .module('golfleet-popup-workshop', ['power-popup', 'power-toast'])
  .component('golfleetPopupWorkshop', new GolfleetPopupWorkshop());
