import { app } from './app.module';
import { RoutesConfig } from './configs/routes/routes.config';

class Route {
  constructor($ngRedux, $state, data) {
    this.$ngRedux = $ngRedux;
    this.$state = $state;
    this.data = data;
  }

  dispatch() {
    this.$ngRedux.dispatch({
      type: 'NEW_ROUTE',
      data: this.data,
    });
  }

  toLogin() {
    this.$state.go('login');
  }

  validate() {
    const { state, session } = this.$ngRedux.getState();
    const currentState = state.routeList[state.routeList.length - 1];

    if (!currentState || currentState.routeLink != this.data.routeLink) {
      if (!session.token || session.needSelectAccount) {
        this.toLogin();
        return false;
      }

      this.dispatch();
      return true;
    }

    return true;
  }
}

app
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    ($stateProvider, $urlRouterProvider) => {
      $urlRouterProvider.otherwise(() => '/dashboard');

      Object.keys(RoutesConfig).forEach(key => {
        const { name, url, template, params, data } = RoutesConfig[key];

        $stateProvider.state(name, {
          url,
          template,
          params,
          onEnter: [
            '$ngRedux',
            '$state',
            '$stateParams',
            ($ngRedux, $state, $stateParams) => {
              const route = new Route($ngRedux, $state, {
                ...data,
                stateConfig: data.stateConfig ? data.stateConfig($stateParams) : null,
              });
              const isValid = route.validate();

              if (isValid) {
                const { session } = $ngRedux.getState();
                if (!session.isSingleSignon) {
                  const auxStateConfig = data?.stateConfig ? data?.stateConfig($stateParams) : null;
                  if (auxStateConfig !== null && auxStateConfig.checkDefaulterRules) {
                    const popupElement = document.querySelector('power-popup-defaulter-warning');
                    popupElement.terminate();
                    popupElement.setup(auxStateConfig.isAdm);
                  } else {
                    const popupElement = document.querySelector('power-popup-defaulter-warning');
                    popupElement.terminate();
                  }
                }
              }
            },
          ],
        });
      });
    },
  ])
  .run(($rootScope, $location, $ngRedux, $state) => {
    /**
     * Register listener to watch route changes
     * first param: event
     * second param: current
     * third param: next
     */
    $rootScope.$on('$locationChangeStart', () => {
      const url = $location
        .url()
        .split(/\/|\?/)
        .filter(item => item);
      const [action, nextRouteName, showHelp] = url;
      const routesList = $state.get();

      if (
        action === 'next' &&
        nextRouteName &&
        routesList.filter(item => item.name === nextRouteName).length > 0
      ) {
        const { state } = $ngRedux.getState();
        state.nextRouteName = nextRouteName;
        state.showHelp = !!showHelp;
      }
    });
  });
