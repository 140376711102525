/* global H: true */
import { PowerMapHereProvider } from '@power/power-components/components/power-map/providers/here/power-map.here-provider';
import '@power/power-components/helpers/is-iframe/is-iframe';

import './golfleet-map-reverse-geocode.here-provider.scss';

class GolfleetMapReverseGeocodeHereProvider extends PowerMapHereProvider {
  constructor(context, $element, $ngRedux, $scope, $rootScope, $http, urlApi) {
    super(context, $element, $ngRedux, $scope, $http, urlApi);

    Object.assign(this, { $rootScope });

    this.context.address = {};
    this.context.addressSearchText = null;
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();

    Object.assign(this.$, {
      setMarker: this.setMarker.bind(this),
      resetMap: this.resetMap.bind(this),
    });

    const clickToLatLng = evt => {
      const coord = this.map.screenToGeo(
        evt.currentPointer.viewportX,
        evt.currentPointer.viewportY,
      );
      this._removeMarker();
      // eslint-disable-next-line no-undef
      this._clickToLatLngMarker = new H.map.Marker({ ...coord });
      this.map.addObject(this._clickToLatLngMarker);
      this.requesReverseGeocode({ ...coord });
    };

    this._clickToLatLng = clickToLatLng.bind(this);
    this.map.addEventListener('tap', this._clickToLatLng);
  }
  /**/

  /* Public */
  setMarker({ lat, lng }) {
    // eslint-disable-next-line no-undef
    this._clickToLatLngMarker = new H.map.Marker({ lat, lng });
    this.map.addObject(this._clickToLatLngMarker);
    this.map.setZoom(16, true);
    this.map.setCenter({ lat, lng }, true);
  }

  resetMap() {
    this._removeMarker();
    this.map.setZoom(4, true);
    this.coordinatesMap = { latitude: -20.933402286553182, longitude: -44.95093750000001 };
    this.map.setCenter({ lat: -15.34, lng: -53.74 }, true);
  }

  requestGeocoding() {
    this.$http({
      url: `${this.urlApi}/GeoCoding/Post`,
      method: 'POST',
      data: {
        request: { street: this.context.addressSearchText },
      },
    }).then(
      success => {
        if (success.status && success.status !== 200) return;
        if (success.data.data) {
          const { x: lat, y: lng } = success.data.data;
          this._removeMarker();
          // eslint-disable-next-line no-undef
          this._clickToLatLngMarker = new H.map.Marker({ lat, lng });
          this.requesReverseGeocode({ lat, lng });
          this.map.addObject(this._clickToLatLngMarker);
          this.map.setZoom(16, true);
          this.map.setCenter({ lat, lng }, true);
          this.context.addressSearchText = '';
        }
      },
      () => {},
    );
  }

  requesReverseGeocode({ lat, lng }) {
    this.$http({
      url: `${this.urlApi}/GeoCoding/GetReverseGeocode`,
      method: 'POST',
      data: {
        latitude: lat,
        longitude: lng,
      },
    }).then(
      success => {
        if (success.status && success.status !== 200) return;
        if (success.data.data) {
          this.context.address = success.data.data;
          Object.assign(this.context.address, { latitude: lat, longitude: lng });
        }
      },
      () => {},
    );
  }
  /**/

  /* Private */
  _removeMarker() {
    if (this._clickToLatLngMarker) {
      this.map.removeObject(this._clickToLatLngMarker);
      this._clickToLatLngMarker = null;
    }
  }
  /* */

  /* Observers */
  /**/
}

export { GolfleetMapReverseGeocodeHereProvider };
