/* eslint-disable linebreak-style */
import angular from 'angular';
import 'angular-easy-masks';

import template from './power-crud-signature-photo.html';
import './power-crud-signature-photo.scss';

class PowerCrudSignaturePhotoController {
  static get $inject() {
    return ['$element', '$scope', '$rootScope', '$timeout', 'reportServices'];
  }

  constructor($element, $scope, $rootScope, $timeout, reportServices) {
    Object.assign(this, { $: $element[0], $scope, $rootScope, $timeout, reportServices });

    this.newFile = null;

    this.disabled = true;

    this.isLoading = false;
  }

  // #region Lifecycle
  $onInit() {}
  // #endregion Lifecycle

  // #region Public
  async __onInputFileChanged() {
    const [file] = this.$.querySelector('#file-input').files;

    if (file) {
      const extension = /\.\w+$/.exec(file.name);

      if (file.size > this.maxFileSize) {
        this.warning = `Tamanho máximo do arquivo: ${this.maxFileSize / 1048576} MB`;
      } else if (extension[0] !== '.png' && extension[0] !== '.jpg' && extension[0] !== '.jpeg') {
        this.warning = `Formato de arquivo inválido`;
      } else {
        this.isLoading = true;
        this.warning = null;

        const contentBase64 = await this._fileToBase64(file);

        this.newFile = {
          type: file.type,
          name: file.name.substring(0, extension.index),
          size: file.size,
          extension: extension[0],
          contentBase64,
        };

        this._uploadPhoto();
      }

      if (!this.$scope.$$phase) {
        this.$scope.$apply();
      }
    }
    this.isLoading = false;
  }

  _addFile() {
    this.$.querySelector('#file-input').click();
  }

  _uploadPhoto() {
    if (this.newFile) {
      this.reportServices
        .genericRequest(this.uploadPhotoMethod, {
          photoBase64: this.newFile.contentBase64,
          format: this.newFile.extension,
        })
        .then(
          success => {
            this.photoUrl = success.data.data.url;
            this.value = success.data.data.key;
            this.isLoading = false;
          },
          () => {
            // error
            this.isLoading = false;
          },
        );
    }
  }

  _fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  _removePhoto() {
    this.photoUrl = null;
    this.value = null;
    this.newFile = null;

    this.$.querySelector('#file-input').value = '';
  }

  // #endregion Public
}

class PowerCrudSignaturePhoto {
  constructor() {
    this.template = template;
    this.bindings = {
      value: '=?',
      name: '=',
      uploadPhotoMethod: '=?',
      photoUrl: '=?',
      disabled: '=?',
      warning: '=?',
      maxFileSize: '=?',
    };
    this.controller = PowerCrudSignaturePhotoController;
  }
}

angular
  .module('power-crud-signature-photo', ['wt.easy'])
  .component('powerCrudSignaturePhoto', new PowerCrudSignaturePhoto());
