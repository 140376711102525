import angular from 'angular';
import moment from 'moment/src/moment';
import 'moment/src/locale/pt-br';

import template from './power-card-list.html';
import './power-card-list.scss';

class PowerCardListController {
  static get $inject() {
    return ['$element', '$scope', '$rootScope', '$state'];
  }

  constructor($element, $scope, $rootScope, $state) {
    Object.assign(this, {
      $: $element[0],
      $scope,
      $rootScope,
      $state,
    });

    moment.locale('pt-BR');

    this.cardHeader = {};
    this.cardFooter = {};
    this.cardContent = [];

    this.pageRows = [];
    this.gridHeaders = [];
    this.virtualCards = [];
    this.hasRowSelection = false;

    this.scrollX = 0;
    this.cardIndex = 0;
    this.timeStart = 0;
    this.toucheXStart = 0;
    this.scrollElement = null;
    this.datasetLength = 0;

    this.$scope.$watch(() => this.pageRows, this.__onPageRowsChanged.bind(this));
    this.$scope.$watch(
      () => JSON.stringify(this.gridHeaders),
      this.__onGridHeadersChanged.bind(this),
    );

    this.intersectionObserver = null;

    this.queryMobile = window.matchMedia('(max-width: 425px)');

    this.resetVirtualCardList = false;
    this.firstVirtualCardListRender = true;
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      scrollToIndex: this.scrollToIndex.bind(this),
      _virtualScrollRender: this._virtualScrollRender.bind(this),
    });

    this.intersectionObserver = new IntersectionObserver(this.__onIntersectionObserver.bind(this), {
      root: null,
      rootMargin: '0px',
      threshold: this._buildIntersectionObserverThreshold(),
    });

    document
      .querySelector('#report-body-grid')
      ?.addEventListener('getDataset', this.__onGetDataset.bind(this));

    this.scrollElement = this.$.querySelector('.mobile-card-list-container');

    this.scrollElement?.addEventListener('scroll', this.__onVirtualScroll.bind(this), {
      passive: true,
    });

    this.scrollElement?.addEventListener('touchmove', evt => {
      evt.preventDefault();

      this.scrollElement.scrollTo({
        left: this.scrollX - (evt.touches[0].clientX - this.toucheXStart),
      });
    });
    this.scrollElement?.addEventListener('touchstart', evt => {
      this.scrollX = this.scrollElement.scrollLeft;
      this.timeStart = new Date().getTime();
      this.toucheXStart = evt.touches[0].clientX;
    });
    this.scrollElement?.addEventListener('touchend', evt => {
      const deltaTime = new Date().getTime() - this.timeStart;
      const deltaTouchX = evt.changedTouches[0].clientX - this.toucheXStart;
      const threshold = (Math.abs(deltaTouchX) * 100) / window.innerWidth;

      let nextCardIndex = this.cardIndex;
      let cardIndexModifier = 0;

      if (threshold > 40) {
        cardIndexModifier = deltaTime < 150 ? 10 : 1;

        if (deltaTouchX < 0) {
          nextCardIndex = this.cardIndex + cardIndexModifier;

          if (nextCardIndex >= this.datasetLength) {
            nextCardIndex = this.datasetLength - 1;
          }

          this.scrollToIndex(nextCardIndex);

          this.$.dispatchEvent(
            new CustomEvent('requestSyncVisualization', { detail: { index: nextCardIndex } }),
          );
        } else if (deltaTouchX > 0) {
          nextCardIndex = this.cardIndex - cardIndexModifier;

          if (nextCardIndex <= 0) {
            nextCardIndex = 0;
          }

          this.scrollToIndex(nextCardIndex);

          this.$.dispatchEvent(
            new CustomEvent('requestSyncVisualization', { detail: { index: nextCardIndex } }),
          );
        }
      } else {
        this.scrollToIndex(this.cardIndex);
      }
    });
  }
  /* */

  /* Public */
  scrollToIndex(index) {
    const _index = parseInt(index);

    if (_index <= 0) {
      this.cardIndex = 0;
    } else if (_index >= this.datasetLength) {
      this.cardIndex = this.datasetLength - 1;
    } else {
      this.cardIndex = _index;
    }

    this.$.querySelector('.mobile-card-list-container').scrollTo({
      left: 16 * (1 + this.cardIndex) + (3 * window.innerWidth * this.cardIndex) / 5,
      behavior: 'smooth',
    });
  }
  /* */

  /* Private */
  async _virtualScrollRender() {
    const gridElement = document.querySelector('#report-body-grid');
    const scrollableElement = this.$.querySelector('.mobile-card-list-container');
    const hideVirtualPaginationLoader = this.virtualCards.length !== 0;

    let offsetX = 0;
    let endNode = 0;
    let startNode = 0;

    if (!scrollableElement) return { startNode, endNode };

    const { page, pageSize, datasetLength } = await gridElement.getGridState();
    const cardWidth = 16 + (3 * window.innerWidth) / 5;
    const nodePadding = 3;
    const visibleNodesCount = 1 + 2 * nodePadding;
    const expectedScrollPosition = cardWidth * (page - 1) * pageSize;

    if (
      this.firstVirtualCardListRender &&
      page > 1 &&
      scrollableElement.scrollLeft < expectedScrollPosition
    ) {
      this.$.style.setProperty(
        '--virtual-scroll-width',
        `${parseInt(datasetLength * cardWidth)}px`,
      );

      scrollableElement.scrollTo(expectedScrollPosition, 0);

      startNode = Math.floor(scrollableElement.scrollLeft / cardWidth) - nodePadding;
      startNode = Math.max(0, startNode);
      endNode = startNode + visibleNodesCount;

      this.firstVirtualCardListRender = false;

      return { startNode, endNode };
    }

    startNode = Math.floor(scrollableElement.scrollLeft / cardWidth) - nodePadding;

    startNode = Math.max(0, startNode);
    offsetX = startNode * cardWidth;
    endNode = startNode + visibleNodesCount;

    this.virtualCards = await gridElement.getRows({
      to: endNode,
      from: startNode,
    });

    this.$.style.setProperty('--virtual-scroll-offset', `${offsetX}px`);
    this.$.style.setProperty('--virtual-scroll-width', `${parseInt(datasetLength * cardWidth)}px`);

    if (this.queryMobile.matches) {
      gridElement.virtualPagination(endNode, hideVirtualPaginationLoader);
    }

    if (!this.$scope.$$phase) {
      this.$scope.$apply();
    }

    return { startNode, endNode };
  }

  _toDate(date) {
    return moment(date).utcOffset(-3 - moment(date).utcOffset() / 60)._d;
  }

  _selectRow(row) {
    this.$.dispatchEvent(new CustomEvent('selectRow', { detail: { row } }));
  }

  _toDatePeriod(data) {
    let endDate = this._toDate(data[1]);
    let startDate = this._toDate(data[0]);
    let description = '';

    switch (this.dateGranularity) {
      case 'mes':
        startDate = moment(startDate).format('MMMM [de] YYYY');
        description = `${startDate}`;
        break;
      case 'dia':
        startDate = moment(startDate).format('DD [de] MMMM');
        description = `${startDate}`;
        break;
      case 'hora':
        startDate = moment(startDate).format('DD [de] MMMM HH:mm:ss');
        description = `${startDate}`;
        break;
      default:
        endDate = moment(endDate).format('DD-MM-YYYY');
        startDate = moment(startDate).format('DD-MM-YYYY');
        description = `${startDate} até ${endDate}`;
        break;
    }

    return description;
  }

  _buildIntersectionObserverThreshold() {
    const thresholds = [];
    const numSteps = 20;

    for (let index = 1.0; index <= numSteps; index++) {
      thresholds.push(index / numSteps);
    }

    thresholds.push(0);

    return thresholds;
  }

  /* */

  /* Observers */
  async __onGetDataset(evt) {
    const { resetDataset, datasetLength } = evt.detail;

    this.datasetLength = datasetLength;

    if (resetDataset) {
      const scrollableElement = this.$.querySelector('.mobile-card-list-container');

      this.resetVirtualCardList = true;

      this.virtualCards = [];

      scrollableElement.scrollTo(0, 0);
      this.$.style.setProperty('--virtual-scroll-offset', `0px`);
      this.$.style.setProperty('--virtual-scroll-width', `0px`);

      if (!this.$scope.$$phase && !this.$rootScope.$$phase) {
        this.$scope.$apply();
      }

      requestAnimationFrame(async () => {
        await this._virtualScrollRender();

        this.resetVirtualCardList = false;
      });
    }
  }

  __onVirtualScroll() {
    requestAnimationFrame(() => this._virtualScrollRender());
  }

  __onPageRowsChanged(newValue) {
    if (!newValue || newValue.length === 0) {
      this.$.setAttribute('empty', '');
    } else {
      this.$.removeAttribute('empty');
    }
  }

  __onGridHeadersChanged() {
    if (!this.gridHeaders) return;

    const cardContent = [];

    let cardHeader = null;
    let cardFooter = null;

    for (const gridHeader of this.gridHeaders) {
      if (!cardHeader && gridHeader.fixed === true && gridHeader.type !== 'Icon') {
        cardHeader = { ...gridHeader };
      } else if (!cardFooter && gridHeader.fixed === true && gridHeader.type === 'Icon') {
        cardFooter = { ...gridHeader };
      } else {
        cardContent.push({ ...gridHeader });
      }
    }

    if (!cardHeader) {
      for (let index = 0; index < cardContent.length; index++) {
        if (cardContent[index].type !== 'Icon') {
          cardHeader = { ...cardContent[index] };
          cardContent.splice(index, 1);
          break;
        }
      }
    }

    this.cardHeader = cardHeader;
    this.cardFooter = cardFooter;
    this.cardContent = cardContent;
  }

  __onCardElementRendered(index) {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        if (!this.$.querySelector(`.card-index-${index}`)) return;

        this.intersectionObserver?.observe(this.$.querySelector(`.card-index-${index}`));
      });
    });
  }

  __onIntersectionObserver(entries) {
    requestAnimationFrame(() => {
      for (const entry of entries) {
        if (entry.intersectionRatio >= 0.8) {
          entry.target.classList.add('card-container-focused');
        } else {
          entry.target.classList.remove('card-container-focused');
        }
      }
    });
  }
  /* */
}

class PowerCardList {
  constructor() {
    this.template = template;
    this.bindings = {
      pageRows: '=?',
      gridHeaders: '=?',
      hasRowSelection: '=?',
    };
    this.controller = PowerCardListController;
  }
}

angular.module('power-card-list', []).component('powerCardList', new PowerCardList());

export { PowerCardListController, PowerCardList };
