/* eslint new-cap: ["error", { "newIsCap": false }] */
import angular from 'angular';
import 'ng-redux';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-period/power-period';
import '@power/power-components/components/power-radio-button-group/power-radio-button-group';
import '@power/power-components/components/power-toast/power-toast';
import '@power/power-components/components/power-toolbar/power-toolbar';
import '@power/power-components/directives/ng-tippy/ng-tippy';
import '@power/power-components/directives/infinite-scroll/infinite-scroll';
import { PowerReportController } from '@power/power-components/components/power-report/power-report';

import '@power/power-components/components/power-card-alert/power-card-alert';
import '@power/power-components/components/power-custom-checkbox-list/power-custom-checkbox-list';
import '@power/power-components/components/power-simple-card-list/power-simple-card-list';

import template from './golfleet-report-checklist.html';
import './golfleet-report-checklist.scss';

class GolfleetReportChecklistController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
      '$sce',
      '$rootScope',
      'crudServices',
      '$compile',
    ];
  }

  /**
   * Creates an instance of ReportAlertsController.
   * @memberof ReportChecklistController
   */
  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
    $sce,
    $rootScope,
    crudServices,
    $compile,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
      $sce,
      $rootScope,
    );

    Object.assign(this, { $sce, $rootScope, crudServices, $compile });

    this.__appInheritBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Storage */
        isSingleSignon: behavior.session.isSingleSignon,
        session: behavior.session,
      }),
    )(this);
  }

  $onInit() {
    super.$onInit();
  }

  $onDestroy() {
    super.$onDestroy();
  }

  _newChecklist() {
    this.$ngRedux.dispatch({
      type: 'NEXT_ROUTE',
      data: {
        routeName: 'Formulário de Checklist',
        routeLink: 'checklistForm',
        routeSubName: 'Formulário de Checklist',
        routeTail: null,
        stateConfig: { ...this.stateConfig, gridName: 'Checklist', filterConfig: [] },
      },
    });

    this.$state.go('checklistForm');
  }

  _goToLink(evt, evtParams) {
    if (evtParams.routeLink == 'print') {
      this._print(evtParams.tableRowData.id);
    } else {
      super._goToLink(evt, evtParams);
    }
  }

  _print(checklistId) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this._getChecklist(checklistId)
      .then()
      .then(result => {
        this.printChecklist = result;

        this._printChecklist();

        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
      });
  }

  _getChecklist(checklistId) {
    return this.crudServices.getData('Checklist/GetChecklist', {
      checklistId,
    });
  }

  _printChecklist() {
    setTimeout(() => {
      const printContents = JSON.parse(
        JSON.stringify(this.$.querySelector('#print-popup').innerHTML),
      );
      const popupWindow = window.open('', '_blank', 'width=800,height=600');
      popupWindow.document.open();
      popupWindow.document.write(`
            <html>
            <head>
                <title>Imprimir</title>
                <style>
                    .print-popup {
                      position: relative;
                        font-family: Ubuntu, sans-serif, Helvetica, Arial !important;

                      .print-popup-header {
                        display: flex;
                        justify-content: space-between;

                        .print-popup-header-title {
                          font-size: 24px;
                          padding: 20px;
                        }

                        .bold {
                          font-weight: bold;
                        }

                        .print-popup-header-logo {
                          width: 100px;
                          height: 100px;

                          img {
                            width: 100%;
                          }
                        }
                      }

                      .print-popup-block {
                        padding: 20px;

                        h1 {
                          padding: 10px 0;
                          color: #8300FF;
                          font-size: 16px;
                        }

                        h2 {
                          padding: 10px 0;
                          color: #555;
                          font-size: 14px;
                        }
                      }

                      .print-popup-line {
                        display: flex;
                        padding: 10px 0;
                      }

                      .print-popup-label {
                        color: #949494;
                        width: 30%;
                        font-size: 14px;
                      }

                      .print-popup-info {
                        width: 70%;
                        font-size: 14px;
                      }

                      .print-popup-photo {
                        padding: 10px 0;

                        img {
                          max-height: 300px;
                          border: 2px solid #e0e0e0;
                        }
                      }

                      .print-popup-divider {
                        margin: 10px 0;
                        border-bottom: 2px solid #e0e0e0;
                      }

                      .print-popup-signature {
                        border: 2px solid #e0e0e0;
                        padding: 10px 0;

                        img {
                          max-height: 100px;
                        }
                      }

                      .print-popup-signature-photo {
                        padding: 10px 0;

                        img {
                          max-height: 100px;
                          border: 2px solid #e0e0e0;
                        }
                      }
                    }
                </style>
            </head>
            <body onload="window.print()">
                ${printContents}
            </body>
            </html>
        `);
      popupWindow.document.close();
    }, 500);
  }

  _parseDate(date) {
    return new Date(date);
  }
}

class GolfleetReportChecklist {
  /**
   * Creates an instance of ReportAlerts.
   * @memberof ReportAlerts
   */
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetReportChecklistController;
  }
}

angular
  .module('golfleet-report-checklist', [
    'ngRedux',
    'infinite-scroll',
    'ng-tippy',
    'power-fab',
    'power-toolbar',
    'power-dropdown',
    'power-footer',
    'power-pagination',
    'power-popup',
    'power-radio-button-group',
    'power-toast',
    'power-simple-card-list',
    'power-card-alert',
    'power-custom-checkbox-list',
  ])
  .component('golfleetReportChecklist', new GolfleetReportChecklist());
