/* global H: true isIframe: true */
import moment from 'moment';

import { PowerMapHereProvider } from '../../../power-map/providers/here/power-map.here-provider';
import '../../../../helpers/is-iframe/is-iframe';
import './power-map-utilization.here-provider.scss';

class PowerMapUtilizationHereProvider extends PowerMapHereProvider {
  // eslint-disable-next-line no-useless-constructor
  constructor(context, $element, $ngRedux, $rootScope, $scope, $http, urlApi) {
    super(context, $element, $ngRedux, $scope, $http, urlApi);

    Object.assign(this, { $rootScope });

    this.routeTimelineInterval = null;
    this.routeTimelinePosition = 0;

    this.routeMarkers = null;
    this.routeLinestrings = null;
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      openPopup: this.openPopup.bind(this),
      setTimelineDataset: this.setTimelineDataset.bind(this),
      toggleTimelineControl: this.toggleTimelineControl.bind(this),
      updateTimelinePosition: this.updateTimelinePosition.bind(this),
    });

    super.$onInit();
  }

  $onDestroy() {
    clearInterval(this.context.routeTimelineEvent);
  }
  /**/

  /* Public */
  removeLayers(layerNameList = ['default']) {
    const removingTimeline =
      layerNameList.filter(layer => layer == 'routeMarkers' || layer == 'routeLinestrings').length <
      layerNameList.length;
    if (this.context.routeTimelineEvent && removingTimeline) {
      clearInterval(this.context.routeTimelineEvent);
      Object.assign(this, {
        routeTimelineEvent: null,
        routeTimelinePosition: this.routeMarkers.length - 1,
      });
    }
    if (removingTimeline) {
      Object.assign(this, {
        routeMarkers: null,
        routeLinestrings: null,
      });
    }
    super.removeLayers(layerNameList);
  }

  toggleTimelineControl(show) {
    this.$.querySelector('#route-timeline-control').style.display = show ? '' : 'none';
  }

  setTimelineDataset({ markers, linestrings }) {
    Object.assign(this, {
      routeMarkers: markers,
      routeLinestrings: linestrings,
      routeTimelinePosition: markers.length - 1,
    });
    this._renderRouteTimeline();
  }

  updateTimelinePosition({ pathPosition }) {
    this.routeTimelinePosition = pathPosition;

    if ('routeMarkers' in this.mapLayers) {
      const objects = this.mapLayers.routeMarkers.getObjects();

      for (let markerIndex = 0; markerIndex < objects.length; markerIndex++) {
        const marker = objects[markerIndex];
        const markerElement = document.querySelector(`#mrv_${marker.P.mrvId}`);

        if (markerIndex === this.routeTimelinePosition) {
          if (markerIndex != 0 && markerIndex != objects.length - 1) {
            markerElement?.setAttribute('selected', '');
            marker.icon.i?.setAttribute('selected', '');
          }

          // this.map.setZoom(16, true);
          this.map.setCenter(marker.getPosition(), true);

          this._openBubblePopup({
            position: marker.getPosition(),
            content: marker.getData().bubbleContent,
          });
        } else {
          markerElement?.removeAttribute('selected');
          marker.icon.i?.removeAttribute('selected'); // fallback
        }
      }
    }
  }
  /**/

  /* Private */
  _createMarkerIcon({ color, icon, ignicao: ignition, direcao: direction }) {
    if (!ignition || direction === null) {
      return super._createMarkerIcon({ color, icon });
    }

    const createIcon = iconString => {
      if (!iconString) return '';
      return `
          <div style="color: #fff;cursor: pointer;transform: rotate(-${direction}deg);">
            <i class="material-icons" style="margin-top: -2px;cursor: pointer;">
              ${iconString}
            </i>
          </div>
        `;
    };
    return new H.map.DomIcon(
      `
        <div style="margin: -20px 0px 0px -20px; z-index: 0;transform: matrix(1, 0, 0, 1, 378, 209); position: absolute;">
          <div style="width:40px; height:40px; transform: rotate(${direction}deg);">
            <svg xmlns="https://www.w3.org/2000/svg" width="40" height="60" style="margin-top:-20px;">

              <foreignObject x="0" y="0" width="40" height="60" style="">
                <div style="display:flex;justify-content:center;align-items: center;width: 100%;height: 100%;flex-direction: column;">
                  <i class="material-icons" style="color:${
                    color || 'var(--primary-color)'
                  };cursor: pointer;font-size: 24px;margin-bottom:1px; margin-top: -4px;">
                    navigation
                  </i>
                  <div style="background:${
                    color || 'var(--primary-color)'
                  };border-radius: 100%;display:flex;justify-content:center;align-items: center;height: 32px;width: 32px;">
                    ${createIcon(icon)}
                  </div>
                </div>
              </foreignObject>
            </svg>
          </div>
        </div>
          `,
    );
  }

  _createRouteIcon(properties) {
    const svgSize = 24;
    const svgSizeWithShadow = 28;

    const containerDefaultStyle = `
      height: ${svgSizeWithShadow}px;
      width: ${svgSizeWithShadow}px;
      margin: -${svgSizeWithShadow / 2}px 0 0 -${svgSizeWithShadow / 2}px
    `;

    let markerContent = '';

    if (properties.first) {
      markerContent = `
        <div
          id="mrv_${properties.mrvId}"
          class="power-custom-route-marker"
          has-violation=${properties.infracoes.length > 0}
          style="${containerDefaultStyle}"
        >
          <svg xmlns="https://www.w3.org/2000/svg"
            viewBox="0 0 ${svgSizeWithShadow} ${svgSizeWithShadow}"
            width="${svgSizeWithShadow}"
            height="${svgSizeWithShadow}"
          >
            <circle
              r="${svgSize / 2.5}"
              cx="${svgSizeWithShadow / 2}"
              cy="${svgSizeWithShadow / 2}"
              fill="#2196f3"
            >
            </circle>
          </svg>
        </div>
      `;
    } else if (properties.last) {
      markerContent = `
        <div
          id="mrv_${properties.mrvId}"
          class="power-custom-route-marker"
          has-violation=${properties.infracoes.length > 0}
          style="${containerDefaultStyle}"
        >
          <svg xmlns="https://www.w3.org/2000/svg"
            viewBox="0 0 ${svgSizeWithShadow} ${svgSizeWithShadow}"
            width="${svgSizeWithShadow}"
            height="${svgSizeWithShadow}"
          >
            <rect
              width="${svgSizeWithShadow * 0.64}"
              height="${svgSizeWithShadow * 0.64}"
              x="${(svgSizeWithShadow * 0.36) / 2}"
              y="${(svgSizeWithShadow * 0.36) / 2}"
              rx="2"
              style="fill:#2196f3"
            />
          </svg>
        </div>
      `;
    } else {
      markerContent = `
        <div
          id="mrv_${properties.mrvId}"
          class="power-custom-route-marker"
          has-violation=${properties.infracoes.length > 0}
          style="${containerDefaultStyle}"
        >
          <svg xmlns="https://www.w3.org/2000/svg"
            viewBox="0 0 ${svgSizeWithShadow} ${svgSizeWithShadow}"
            width="${svgSizeWithShadow}"
            height="${svgSizeWithShadow}"
          >
            <circle
              r="${svgSize / 3.5}"
              cx="${svgSizeWithShadow / 2}"
              cy="${svgSizeWithShadow / 2}"
              fill="#2196f3"
            >
            </circle>
            <circle
              r="${svgSize / 5}"
              cx="${svgSizeWithShadow / 2}"
              cy="${svgSizeWithShadow / 2}"
              fill="#2196f3"
            >
            </circle>
          </svg>
        </div>
      `;
    }

    return new H.map.DomIcon(markerContent);
  }

  _geoJsonToMarker({ geoJson, markerIcon }) {
    const [[lng, lat]] = geoJson.geometry.coordinates;
    const { eventoGerador } = geoJson.properties;
    const isVmaxEvent = eventoGerador && eventoGerador.toUpperCase() == 'VMAX';

    const marker = new H.map.DomMarker(
      { lat, lng },
      {
        icon:
          markerIcon ||
          (geoJson.properties.markerIcon === 'RouteIcon'
            ? this._createRouteIcon
            : this._createMarkerIcon)(geoJson.properties),
        data: {
          ...geoJson.properties,
          bubbleContent: `
            <div id="${!isVmaxEvent ? 'mapPopupHeader' : 'mapPopupHeaderVmax'}">
              <span>${geoJson.properties.placaComApelido}</span>
            </div>
            <div id="mapPopupBody">
              <div class="popup-info-row">
                <div class="left">
                  <i class="material-icons md-20"> gs_speed </i>
                </div>
                <div class="right">
                  <span><b>${geoJson.properties.velocidade}</b> Kmh</span>
                  <span>
                  ${
                    !geoJson.properties.ignicao
                      ? `
                        <span style="color:#980A1A;font-weight:bold;">desligada</span>
                        <i class="material-icons" style="color:#980A1A; font-size: 10px;">lens</i>
                      `
                      : `
                        <span style="color:#4AAE4E;font-weight:bold;">ligada</span>
                        <i class="material-icons" style="color:#4AAE4E; font-size: 10px;">lens</i>
                      `
                  }
                  </span>
                </div>
              </div>
              <div class="popup-info-row" style="display: ${
                geoJson.properties.logradouro ? 'flex' : 'none'
              }">
                <div class="left">
                  <i class="material-icons md-20"> gs_pos </i>
                </div>
                <div class="right">
                  ${
                    isIframe()
                      ? `
                      <span>
                        ${geoJson.properties.logradouro}
                        <br />
                        ${geoJson.properties.municipio} - ${geoJson.properties.estado}
                      </span>
                     `
                      : `
                      <span>
                        <a class="gs-link"
                          target="_blank"
                          href="https://maps.google.com/maps?layer=c&q=${lat},${lng}&cbll=${lat},${lng}&cbp=11,0,0,0,0&z=17&ll=${lat},${lng}"
                        >
                          ${geoJson.properties.logradouro}
                          <br />
                          ${geoJson.properties.municipio} - ${geoJson.properties.estado}
                        </a>
                      </span>
                     `
                  }
                </div>
              </div>
              <div class="popup-info-row">
                <div class="left">
                  <i class="material-icons md-20"> gs_date </i>
                </div>
                <div class="right">
                  <span>
                    ${
                      this._toDate(geoJson.properties.dataHora)
                        .toLocaleString()
                        .replace(',', '')
                        .split(' ')[0]
                    }
                    <b>
                      ${
                        this._toDate(geoJson.properties.dataHora)
                          .toLocaleString()
                          .replace(',', '')
                          .split(' ')[1]
                      }
                    </b>
                  </span>
                </div>
              </div>
              ${geoJson.properties.infracoes.reduce(
                (acc, infracao) => `
                  ${acc}
                  <div class="popup-info-row">
                    <div class="left">
                      <i class="material-icons md-20" style="color:#9D0000"> gs_violation </i>
                    </div>
                    <div class="right">
                      <span style="color:#9D0000">${infracao.descricao}</span>
                    </div>
                  </div>
                `,
                '',
              )}
              <div class="popup-info-row" style="background:#EDEDED">
                <div class="left">
                  <i class="material-icons md-20"> gs_driver_b1 </i>
                </div>
                <div class="right">
                  <span>${geoJson.properties.condutor || 'Não identificado'}</span>
                </div>
              </div>
            </div>
            <div id="mapPopupFooter">
              <span> Lat: ${Number(lat).toFixed(4)} </span>
              <span> Lon: ${Number(lng).toFixed(4)} </span>
            </div>
          `,
        },
      },
    );

    if (geoJson.properties.markerIcon === 'RouteIcon') {
      marker.addEventListener('tap', e => {
        this.$.dispatchEvent(
          new CustomEvent('routeMarkerTapped', {
            detail: { pathPosition: e.target.fh },
          }),
        );
      });
    }

    return marker;
  }

  _geoJsonToPolyline({ geoJson }) {
    return new H.map.Polyline(
      geoJson.geometry.coordinates.reduce((acc, coordinate) => {
        const [longitude, latitude] = coordinate;
        acc.pushLatLngAlt(latitude, longitude);
        return acc;
      }, new H.geo.LineString()),
      {
        data: {
          ...geoJson.properties,
        },
        style: {
          lineWidth: 4,
          strokeColor: this._hexToRgba(geoJson.properties.color),
        },
      },
    );
  }

  _geoJsonToRectangle({ geoJson }) {
    const [coordinates] = geoJson.geometry.coordinates;
    const [[lng, lat]] = coordinates;

    return new H.map.Rect(
      coordinates.reduce((acc, coordinate) => {
        const [longitude, latitude] = coordinate;
        acc.pushLatLngAlt(latitude, longitude);
        return acc;
      }, new H.geo.LineString()),
      {
        data: {
          ...geoJson.properties,
          bubbleContent: `
            <div id="mapPopupHeader">
              <span>${geoJson.properties.description}</span>
            </div>
            <div id="mapPopupBody">
              <div>
                <b>Descrição:</b>
                <br>
                <span>${geoJson.properties.extendedDescription || '---'}</span>
              </div>
              <div>
                <b>Categoria:</b>
                <br>
                <span>${geoJson.properties.categoryName || '---'}</span>
              </div>
              <div>
                <b>Privacidade:</b>
                <br>
                <span>${geoJson.properties.privacy || '---'}</span>
              </div>
              <div>
                <b>Status:</b>
                <br>
                <span>${geoJson.properties.providerStatusDesc || '---'}</span>
              </div>
            </div>
            <div id="mapPopupFooter">
              <span> Lat: ${Number(lat).toFixed(4)} </span>
              <span> Lon: ${Number(lng).toFixed(4)} </span>
            </div>
          `,
        },
        style: {
          fillColor: this._hexToRgba(geoJson.properties.color, 0.33),
          strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
        },
      },
    );
  }

  _toggleRouteTimeline() {
    if (!this.routeMarkers || !this.routeLinestrings)
      // eslint-disable-next-line no-console
      return console.warn('Missing routeMarkers or/and routeLinestrings Layers');

    if (!this.context.routeTimelineEvent) {
      this.context.routeTimelineEvent = setInterval(() => {
        this._renderRouteTimeline().then(() => {
          if (this.routeTimelinePosition < this.routeMarkers.length - 1) {
            this.routeTimelinePosition += 1;
            this.$scope.$emit('updateTimelineSlider', {
              value: this.routeTimelinePosition,
            });
          } else this._endRouteTimeline();
        });
      }, 2000);
      this._renderRouteTimeline().then(() => {
        if (this.routeTimelinePosition == this.routeMarkers.length - 1)
          this.routeTimelinePosition = 0;
        else this.routeTimelinePosition += 1;
        this.$scope.$emit('updateTimelineSlider', {
          value: this.routeTimelinePosition,
        });
      });
    } else {
      clearInterval(this.context.routeTimelineEvent);
      this.context.routeTimelineEvent = null;
      this._renderRouteTimeline();
    }

    return null;
  }

  _endRouteTimeline() {
    clearInterval(this.context.routeTimelineEvent);
    this.context.routeTimelineEvent = null;

    Object.assign(this, {
      routeTimelinePosition: this.routeMarkers.length - 1,
    });

    if (this.$scope.$$phase === null && this.$rootScope.$$phase === null) {
      this.$scope.$apply();
    }

    this.$scope.$emit('updateTimelineSlider', {
      value: this.routeMarkers.length - 1,
    });

    this._renderRouteTimeline();
  }

  _renderRouteTimeline() {
    if (this.mapLayers.routeMarkers) {
      if (!this.mapLayers.routeMarkers.removeAll) this.map.removeLayer(this.mapLayers.routeMarkers);
      else this.map.removeObject(this.mapLayers.routeMarkers);

      delete this.mapLayers.routeMarkers;
    }

    return this.renderDataset({
      dataset: this.routeMarkers,
      layerName: 'routeMarkers',
      type: 'MarkerFeatureGroup',
      useCluster: false,
      clusterColor: '#2196f3',
    })
      .then(() =>
        this.renderDataset({
          dataset: this.routeLinestrings,
          layerName: 'routeLinestrings',
          type: 'LinestringFeatureGroup',
        }),
      )
      .then(() => {
        if (!this.context.routeTimelineEvent && this.routeLinestrings.length > 0) {
          this.fitLayers(['routeLinestrings']);
        } else if ('getObjects' in this.mapLayers.routeMarkers) {
          const markersLayers = this.mapLayers.routeMarkers.getObjects();

          if (markersLayers.length < 1) return;

          const lastMarker = markersLayers[markersLayers.length - 1];

          this.map.setZoom(this.routeTimelinePosition <= 1 ? 16 : this.map.getZoom(), true);
          this.map.setCenter(lastMarker.getPosition(), true);

          this._openBubblePopup({
            position: lastMarker.getPosition(),
            content: lastMarker.getData().bubbleContent,
          });
        }
      });
  }

  _toDate(date) {
    return moment(date).utcOffset(-3 - moment(date).utcOffset() / 60)._d;
  }
  /**/

  /* Observers */
  /**/
}

export { PowerMapUtilizationHereProvider };
