import angular from 'angular';
import 'ng-redux';

import '../power-popup-pdf-export-prompt/power-popup-pdf-export-prompt';

import { ImportRouteConfig } from '../../utils/get-route-config';

import template from './power-toolbar.html';
import './power-toolbar.scss';

class PowerToolbarController {
  static get $inject() {
    return ['$element', '$ngRedux', '$state', '$transclude', '$http', 'urlApi'];
  }

  constructor($element, $ngRedux, $state, $transclude, $http, urlApi) {
    Object.assign(this, { $: $element[0], $ngRedux, $state, $transclude, $http, urlApi });

    this.__appBehavior = $ngRedux.connect(behavior => {
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];

      return Object({
        stateConfig: currentState ? currentState.stateConfig : {},
        currentState: currentState || {},
      });
    })(this);

    ImportRouteConfig(this.newRouteLink).then(newRouteConfig => {
      this.metadata = newRouteConfig ? newRouteConfig.metadata : {};
      this.reportConfigController = {
        reportName: this.currentState.routeName,
        exportConfig: {
          button: {
            default: {
              icons: ['gs_download'],
              class: 'button-default',
            },
            hasDownload: {
              icons: ['gs_download'],
              class: 'button-has-download',
            },
            hasNewDownload: {
              icons: ['gs_download'],
              class: 'button-has-new-download',
            },
          },
          title: 'Exportar para...',
          options: [
            {
              value: 'csv',
              description: 'Arquivo CSV',
              icon: 'insert_drive_file',
              method: this.metadata?.exportMethod,
              digital: true,
            },
            {
              value: 'xlsx',
              description: 'Arquivo XLSX',
              icon: 'insert_drive_file',
              method: this.metadata?.exportMethod,
              digital: true,
            },
            {
              value: 'pdf',
              description: 'Arquivo PDF (digital)',
              icon: 'insert_drive_file',
              method: this.metadata?.exportMethod,
              digital: true,
            },
            {
              value: 'pdf',
              description: 'Arquivo PDF (impressão)',
              icon: 'insert_drive_file',
              method: this.metadata?.exportMethod,
              digital: false,
            },
          ],
        },
      };
    });

    this.slots = {
      hasHeader: this.$transclude.isSlotFilled('headerSlot'),
      hasRight: this.$transclude.isSlotFilled('rightSlot'),
      hasLeft: this.$transclude.isSlotFilled('leftSlot'),
    };

    if (this.slots.hasRight) {
      this.$.setAttribute('has-right-slot', '');
    }
    if (this.slots.hasLeft) {
      this.$.setAttribute('has-left-slot', '');
    }
  }

  /* Lifecycle */
  $onInit() {}

  $onDestroy() {
    this.__appBehavior();
  }
  /* */

  /* Public */
  /* */

  /* Private */
  _exportOptionIsAvailable(option) {
    if (Array.isArray(this.downloadExtensions)) {
      return this.downloadExtensions.includes(option.value.toLowerCase());
    }
    return true;
  }

  _exportData(selectedItem) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this.closeReportControlMoreOptions = true;

    const tableOffsetWidth = (document.querySelector('#report-body table') || {}).offsetWidth;
    const requestTime = new Date();
    const payload = {
      name: this.currentState.routeName,
      columns: this.stateConfig.gridConfig.gridHeaders,
      request: {
        ...this.stateConfig.getDataFixedParams,
        isPaginated: true,
        page: 0,
        length: this.stateConfig.gridConfig.gridTotal,
        sort: {
          name: this.stateConfig.gridConfig.sortField,
          direction: this.stateConfig.gridConfig.sortDirection,
        },
        filter: {
          conditions: this.stateConfig.filterConditions,
        },
        navigation: (() => {
          const result = {};
          const { navigation } = this.stateConfig;

          if (!!navigation && !!navigation.date && navigation.date.length > 0) {
            result.date = navigation.date[navigation.date.length - 1];
          }

          return result;
        })(),
        exportOptions: {
          exportType: selectedItem.value || 'xlsx',
          digital: selectedItem.digital,
          offsetWidth: tableOffsetWidth || null,
        },
      },
      requestTime: new Date(requestTime - requestTime.getTimezoneOffset() * 60000),
    };

    this.$http({
      url: `${this.urlApi}/${selectedItem.method}`,
      method: 'POST',
      data: payload,
    })
      .then(async success => {
        const blob = await (
          await fetch(`data:'application/octet-stream';base64,${success.data.data.contentBase64}`)
        ).blob();
        const fileUrl = window.URL.createObjectURL(blob, { type: success.data.data.contentType });
        const downloadLink = document.createElement('a');

        downloadLink.download = `${success.data.data.fileName}${success.data.data.extension}`;
        downloadLink.href = fileUrl;
        downloadLink.click();
      })
      .finally(() => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
      });
  }

  _showPdfExportPrompt(option) {
    this.$.querySelector('power-popup-pdf-export-prompt').setup({ option });
    this.$.querySelector('power-popup-pdf-export-prompt').toggle();
  }
  /* */

  /* Observers */
  __onPdfConfirmExport(event) {
    this._exportData(event.detail.option);
  }
  /* */
}

class PowerToolbar {
  constructor() {
    this.template = template;
    this.bindings = {
      downloadExtensions: '=?',
    };
    this.transclude = {
      headerSlot: '?headerSlot',
      leftSlot: '?leftSlot',
      rightSlot: '?rightSlot',
      complementaryActions: '?complementaryActions',
    };
    this.controller = PowerToolbarController;
  }
}

angular
  .module('power-toolbar', ['ngRedux', 'power-popup-pdf-export-prompt'])
  .component('powerToolbar', new PowerToolbar());
