import angular from 'angular';

import template from './golfleet-dashboard-no-data.html';

class GolfleetDashboardNoDataController {
  static get $inject() {
    return ['$element'];
  }

  constructor($element) {
    Object.assign(this, { $: $element[0] });

    this.subtitleDefault = 'Experimente modificar os filtros e faça uma nova pesquisa.';
  }
}

class GolfleetDashboardNoData {
  constructor() {
    this.template = template;
    this.bindings = {
      icon: '=',
      title: '=',
      subtitle: '=',
    };
    this.controller = GolfleetDashboardNoDataController;
  }
}

angular
  .module('golfleet-dashboard-no-data', [])
  .component('golfleetDashboardNoData', new GolfleetDashboardNoData());
