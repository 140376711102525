/* eslint-disable camelcase */
import Grid_ActivitiesDriver_Report from './default/Grid_ActivitiesDriver_Report.json';
import Grid_ActivitiesDriver_Trip_Report from './default/Grid_ActivitiesDriver_Trip_Report.json';
import Grid_Activities_Report from './default/Grid_Activities_Report.json';
import Grid_Activities_Trip_Report from './default/Grid_Activities_Trip_Report.json';
import Grid_ActivitiesVehicle_Report from './default/Grid_ActivitiesVehicle_Report.json';
import Grid_ActivitiesVehicle_Trip_Report from './default/Grid_ActivitiesVehicle_Trip_Report.json';
import Grid_AttendanceDetail_Report from './default/Grid_AttendanceDetail_Report.json';
import Grid_AttendanceDriver_Report from './default/Grid_AttendanceDriver_Report.json';
import Grid_AttendanceVehicle_Report from './default/Grid_AttendanceVehicle_Report.json';
import Grid_Attendance_Report from './default/Grid_Attendance_Report.json';
import Grid_ConsuptionBySegmentReport_List from './default/Grid_ConsuptionBySegmentReport_List.json';
import Grid_ConsuptionBySegmentReport_Trip_List from './default/Grid_ConsuptionBySegmentReport_Trip_List.json';
import Grid_TempDriver_List from './default/Grid_TempDriver_List.json';
import Grid_TempDriver_Trip_List from './default/Grid_TempDriver_Trip_List.json';
import Grid_Driver_Trip_List from './default/Grid_Driver_Trip_List.json';
import Grid_FuelSupplyManagementReport_List from './default/Grid_FuelSupplyManagementReport_List.json';
import Grid_FuelSupplyManagementReport_Trip_List from './default/Grid_FuelSupplyManagementReport_Trip_List.json';
import Grid_GeneratedAlerts_Report from './default/Grid_GeneratedAlerts_Report.json';
import Grid_GeoFence_List from './default/Grid_GeoFence_List.json';
import Grid_GolCamReport_List from './default/Grid_GolCamReport_List.json';
import Grid_GroupAdm_List from './default/Grid_GroupAdm_List.json';
import Grid_GroupAdm_Trip_List from './default/Grid_GroupAdm_Trip_List.json';
import Grid_Group_List from './default/Grid_Group_List.json';
import Grid_Group_Trip_List from './default/Grid_Group_Trip_List.json';
import Grid_KmControlDriver_Report from './default/Grid_KmControlDriver_Report.json';
import Grid_KmControlDriver_Trip_Report from './default/Grid_KmControlDriver_Trip_Report.json';
import Grid_KmControlVehicle_Report from './default/Grid_KmControlVehicle_Report.json';
import Grid_KmControlVehicle_Trip_Report from './default/Grid_KmControlVehicle_Trip_Report.json';
import Grid_KmControl_Report from './default/Grid_KmControl_Report.json';
import Grid_KmControl_Trip_Report from './default/Grid_KmControl_Trip_Report.json';
import Grid_MotionLog_Trip_Report from './default/Grid_MotionLog_Trip_Report.json';
import Grid_MyAlertsAdm_Report from './default/Grid_MyAlertsAdm_Report.json';
import Grid_MyAlertsAdm_Trip_Report from './default/Grid_MyAlertsAdm_Trip_Report.json';
import Grid_MyAlerts_Report from './default/Grid_MyAlerts_Report.json';
import Grid_MyAlerts_Trip_Report from './default/Grid_MyAlerts_Trip_Report.json';
import Grid_OrderService_Report from './default/Grid_OrderService_Report.json';
import Grid_PeriodActivitiesDetail_Report from './default/Grid_PeriodActivitiesDetail_Report.json';
import Grid_PeriodActivitiesDetail_Trip_Report from './default/Grid_PeriodActivitiesDetail_Trip_Report.json';
import Grid_PeriodActivitiesDriver_Report from './default/Grid_PeriodActivitiesDriver_Report.json';
import Grid_PeriodActivitiesDriver_Trip_Report from './default/Grid_PeriodActivitiesDriver_Trip_Report.json';
import Grid_PeriodActivities_Report from './default/Grid_PeriodActivities_Report.json';
import Grid_PeriodActivities_Trip_Report from './default/Grid_PeriodActivities_Trip_Report.json';
import Grid_PeriodActivitiesVehicle_Report from './default/Grid_PeriodActivitiesVehicle_Report.json';
import Grid_PeriodActivitiesVehicle_Trip_Report from './default/Grid_PeriodActivitiesVehicle_Trip_Report.json';
import Grid_PointsOfInterest_Trip_List from './default/Grid_PointsOfInterest_Trip_List';
import Grid_FleetPolicyStatusAdm_Trip_List from './default/Grid_FleetPolicyStatusAdm_Trip_List.json';
import Grid_FleetPolicyStatus_Trip_List from './default/Grid_FleetPolicyStatus_Trip_List.json';
import Grid_Profiles_List from './default/Grid_Profiles_List.json';
import Grid_RankingSpeedLimitDetail_Report from './default/Grid_RankingSpeedLimitDetail_Report.json';
import Grid_RankingSpeedLimitDetail_Trip_Report from './default/Grid_RankingSpeedLimitDetail_Trip_Report.json';
import Grid_RankingSpeedLimitReportByDriver_List from './default/Grid_RankingSpeedLimitReportByDriver_List.json';
import Grid_RankingSpeedLimitReportByDriver_Trip_List from './default/Grid_RankingSpeedLimitReportByDriver_Trip_List.json';
import Grid_RankingSpeedLimitReportByRoute_List from './default/Grid_RankingSpeedLimitReportByRoute_List.json';
import Grid_RankingSpeedLimitReportByRoute_Trip_List from './default/Grid_RankingSpeedLimitReportByRoute_Trip_List.json';
import Grid_RankingSpeedLimitReportByVehicle_List from './default/Grid_RankingSpeedLimitReportByVehicle_List.json';
import Grid_RankingSpeedLimitReportByVehicle_Trip_List from './default/Grid_RankingSpeedLimitReportByVehicle_Trip_List.json';
import Grid_Reports_Trip_List from './default/Grid_Reports_Trip_List.json';
import Grid_SuspectedAccident_Trip_Report from './default/Grid_SuspectedAccident_Trip_Report.json';
import Grid_TcoAccidentReport_List from './default/Grid_TcoAccidentReport_List.json';
import Grid_TcoCostReport_List from './default/Grid_TcoCostReport_List.json';
import Grid_TcoEntryReport_List from './default/Grid_TcoEntryReport_List.json';
import Grid_TcoRentReport_List from './default/Grid_TcoRentReport_List.json';
import Grid_TcoTicketReport_List from './default/Grid_TcoTicketReport_List.json';
import Grid_TcoParkingReport_List from './default/Grid_TcoParkingReport_List.json';
import Grid_TcoTollReport_List from './default/Grid_TcoTollReport_List.json';
import Grid_PositionsReport_List from './default/Grid_PositionsReport_List.json';
import Grid_TripsDetailDriver_Report from './default/Grid_TripsDetailDriver_Report.json';
import Grid_TravelsByDriver_Trip_Report from './default/Grid_TravelsByDriver_Trip_Report.json';
import Grid_TravelsByGroup_Trip_Report from './default/Grid_TravelsByGroup_Trip_Report.json';
import Grid_TravelsByUtilization_Trip_Report from './default/Grid_TravelsByUtilization_Trip_Report.json';
import Grid_TravelsByVehicle_Trip_Report from './default/Grid_TravelsByVehicle_Trip_Report.json';
import Grid_TravelsByVehicleDriver_Trip_Report from './default/Grid_TravelsByVehicleDriver_Trip_Report.json';
import Grid_TripsDetail_Report from './default/Grid_TripsDetail_Report.json';
import Grid_TripsDetailVehicle_Report from './default/Grid_TripsDetailVehicle_Report.json';
import Grid_TripsDriver_Report from './default/Grid_TripsDriver_Report.json';
import Grid_Trips_Report from './default/Grid_Trips_Report.json';
import Grid_TripsVehicle_Report from './default/Grid_TripsVehicle_Report.json';
import Grid_User_List from './default/Grid_User_List.json';
import Grid_User_Trip_List from './default/Grid_User_Trip_List.json';
import Grid_TempUtilizationDetailDriver_Report from './default/Grid_TempUtilizationDetailDriver_Report.json';
import Grid_TempUtilizationDetailVehicle_Report from './default/Grid_TempUtilizationDetailVehicle_Report.json';
import Grid_TempUtilizationDetail_Report from './default/Grid_TempUtilizationDetail_Report.json';
import Grid_TempUtilizationDriver_Report from './default/Grid_TempUtilizationDriver_Report.json';
import Grid_TempUtilizationDriver_Trip_Report from './default/Grid_TempUtilizationDriver_Trip_Report.json';
import Grid_TempUtilizationVehicle_Report from './default/Grid_TempUtilizationVehicle_Report.json';
import Grid_TempUtilizationVehicle_Trip_Report from './default/Grid_TempUtilizationVehicle_Trip_Report.json';
import Grid_TempUtilization_Report from './default/Grid_TempUtilization_Report.json';
import Grid_TempUtilization_Trip_Report from './default/Grid_TempUtilization_Trip_Report.json';
import Grid_Utilization_Trip_Report from './default/Grid_Utilization_Trip_Report.json';
import Grid_VehicleAdm_List from './default/Grid_VehicleAdm_List.json';
import Grid_VehicleAdm_Trip_List from './default/Grid_VehicleAdm_Trip_List.json';
import Grid_Vehicle_List from './default/Grid_Vehicle_List.json';
import Grid_Vehicle_Trip_List from './default/Grid_Vehicle_Trip_List.json';
import Grid_ViolationDriver_Report from './default/Grid_ViolationDriver_Report.json';
import Grid_TempViolationDriver_Trip_Report from './default/Grid_TempViolationDriver_Trip_Report.json';
import Grid_ViolationVehicle_Report from './default/Grid_ViolationVehicle_Report.json';
import Grid_TempViolationVehicle_Trip_Report from './default/Grid_TempViolationVehicle_Trip_Report.json';
import Grid_TempViolation_Trip_Report from './default/Grid_TempViolation_Trip_Report.json';
import Grid_ViolationsSpeedLimitReport_List from './default/Grid_ViolationsSpeedLimitReport_List.json';
import Grid_ViolationsSpeedLimitReport_Trip_List from './default/Grid_ViolationsSpeedLimitReport_Trip_List.json';
import Grid_RealTimeVehicle_Trip_Report from './default/Grid_RealTimeVehicle_Trip_Report.json';
import Grid_RealTimeVehicleHistory_Trip_Report from './default/Grid_RealTimeVehicleHistory_Trip_Report.json';
import Grid_RealTimeVehicleHistoryAdm_Trip_Report from './default/Grid_RealTimeVehicleHistoryAdm_Trip_Report.json';
import Grid_RealTimePermissionAdm_Trip_Report from './default/Grid_RealTimePermissionAdm_Trip_Report.json';
import Grid_RealTimeGroupByVehiclesAdm_Trip_Report from './default/Grid_RealTimeGroupByVehiclesAdm_Trip_Report.json';
import Grid_RealTimeGroupByClientDealerAdm_Trip_Report from './default/Grid_RealTimeGroupByClientDealerAdm_Trip_Report.json';
import Grid_ViolationRankingReportSPAL_Trip_Report from './default/Grid_ViolationRankingReportSPAL_Trip_Report.json';
import Grid_VisitReport_Trip_Report from './default/Grid_VisitReport_Trip_Report.json';
import Grid_RevisionPlan_Trip_Report from './default/Grid_RevisionPlan_Trip_Report.json';
import Grid_RevisionStatus_Trip_Report from './default/Grid_RevisionStatus_Trip_Report.json';
import Grid_RevisionHistory_Trip_Report from './default/Grid_RevisionHistory_Trip_Report.json';
import Grid_Workshop_Trip_List from './default/Grid_Workshop_Trip_List.json';
import Grid_UnitaryViolation_Trip_Report from './default/Grid_UnitaryViolation_Trip_Report.json';
import Grid_ViolationByVehicle_Trip_Report from './default/Grid_ViolationByVehicle_Trip_Report.json';
import Grid_ViolationByDriver_Trip_Report from './default/Grid_ViolationByDriver_Trip_Report.json';
import Grid_ViolationByVehicleDriver_Trip_Report from './default/Grid_ViolationByVehicleDriver_Trip_Report.json';
import Grid_ViolationByGroup_Trip_Report from './default/Grid_ViolationByGroup_Trip_Report.json';
import Grid_DetailedViolation_Trip_Report from './default/Grid_DetailedViolation_Trip_Report.json';
import Grid_Requester_List from './default/Grid_Requester_List.json';
import Grid_DriversAppStatusAdm_Trip_List from './default/Grid_DriversAppStatusAdm_Trip_List.json';
import Grid_GolfleetIdAppStatusAdm_Trip_List from './default/Grid_GolfleetIdAppStatusAdm_Trip_List.json';
import Grid_Blockage_Report from './default/Grid_Blockage_Report.json';
import Grid_BlockageAdm_Report from './default/Grid_BlockageAdm_Report.json';
import Grid_Checklist_Report from './default/Grid_Checklist_Report.json';
import Grid_GolfleetIdPermissionHistoricAdm_Trip_List from './default/Grid_GolfleetIdPermissionHistoricAdm_Trip_List.json';
import Grid_UtilizationSimplified_Trip_List from './default/Grid_UtilizationSimplified_Trip_List.json';

// Tracking
import Grid_TempDriver_Tracking_List from './tracking/Grid_TempDriver_Tracking_List.json';
import Grid_Driver_Tracking_List from './tracking/Grid_Driver_Tracking_List.json';
import Grid_GeneratedAlerts_Tracking_Report from './tracking/Grid_GeneratedAlerts_Tracking_Report.json';
import Grid_FleetPolicyStatus_Tracking_List from './tracking/Grid_FleetPolicyStatus_Tracking_List.json';
import Grid_FleetPolicyStatusAdm_Tracking_List from './tracking/Grid_FleetPolicyStatusAdm_Tracking_List.json';
import Grid_PositionsReport_Tracking_List from './tracking/Grid_PositionsReport_Tracking_List.json';
import Grid_TempUtilization_Tracking_Report from './tracking/Grid_TempUtilization_Tracking_Report.json';
import Grid_TempUtilizationDriver_Tracking_Report from './tracking/Grid_TempUtilizationDriver_Tracking_Report.json';
import Grid_TempUtilizationVehicle_Tracking_Report from './tracking/Grid_TempUtilizationVehicle_Tracking_Report.json';
import Grid_Vehicle_Tracking_List from './tracking/Grid_Vehicle_Tracking_List.json';
import Grid_VehicleAdm_Tracking_List from './tracking/Grid_VehicleAdm_Tracking_List.json';
import Grid_TempViolationVehicle_Tracking_Report from './tracking/Grid_TempViolationVehicle_Tracking_Report.json';
import Grid_TempViolation_Tracking_Report from './tracking/Grid_TempViolation_Tracking_Report.json';
import Grid_TempViolationDriver_Tracking_Report from './tracking/Grid_TempViolationDriver_Tracking_Report.json';
import Grid_UnitaryViolation_Tracking_Report from './tracking/Grid_UnitaryViolation_Tracking_Report.json';
import Grid_ViolationByVehicle_Tracking_Report from './tracking/Grid_ViolationByVehicle_Tracking_Report.json';
import Grid_ViolationByDriver_Tracking_Report from './tracking/Grid_ViolationByDriver_Tracking_Report.json';
import Grid_ViolationByVehicleDriver_Tracking_Report from './tracking/Grid_ViolationByVehicleDriver_Tracking_Report.json';
import Grid_ViolationByGroup_Tracking_Report from './tracking/Grid_ViolationByGroup_Tracking_Report.json';
import Grid_Utilization_Tracking_Report from './tracking/Grid_Utilization_Tracking_Report.json';

export default {
  Grid_ActivitiesDriver_Report,
  Grid_ActivitiesDriver_Trip_Report,
  Grid_Activities_Report,
  Grid_Activities_Trip_Report,
  Grid_ActivitiesVehicle_Report,
  Grid_ActivitiesVehicle_Trip_Report,
  Grid_AttendanceDetail_Report,
  Grid_AttendanceDriver_Report,
  Grid_AttendanceVehicle_Report,
  Grid_Attendance_Report,
  Grid_ConsuptionBySegmentReport_List,
  Grid_ConsuptionBySegmentReport_Trip_List,
  Grid_TempDriver_List,
  Grid_TempDriver_Trip_List,
  Grid_Driver_Trip_List,
  Grid_FuelSupplyManagementReport_List,
  Grid_FuelSupplyManagementReport_Trip_List,
  Grid_GeneratedAlerts_Report,
  Grid_GeoFence_List,
  Grid_GolCamReport_List,
  Grid_GroupAdm_List,
  Grid_GroupAdm_Trip_List,
  Grid_Group_List,
  Grid_Group_Trip_List,
  Grid_KmControlDriver_Report,
  Grid_KmControlDriver_Trip_Report,
  Grid_KmControlVehicle_Report,
  Grid_KmControlVehicle_Trip_Report,
  Grid_KmControl_Report,
  Grid_KmControl_Trip_Report,
  Grid_MotionLog_Trip_Report,
  Grid_MyAlertsAdm_Report,
  Grid_MyAlertsAdm_Trip_Report,
  Grid_MyAlerts_Report,
  Grid_MyAlerts_Trip_Report,
  Grid_OrderService_Report,
  Grid_PeriodActivitiesDetail_Report,
  Grid_PeriodActivitiesDetail_Trip_Report,
  Grid_PeriodActivitiesDriver_Report,
  Grid_PeriodActivitiesDriver_Trip_Report,
  Grid_PeriodActivities_Report,
  Grid_PeriodActivities_Trip_Report,
  Grid_PeriodActivitiesVehicle_Report,
  Grid_PeriodActivitiesVehicle_Trip_Report,
  Grid_FleetPolicyStatusAdm_Trip_List,
  Grid_FleetPolicyStatus_Trip_List,
  Grid_PointsOfInterest_Trip_List,
  Grid_Profiles_List,
  Grid_RankingSpeedLimitDetail_Report,
  Grid_RankingSpeedLimitDetail_Trip_Report,
  Grid_RankingSpeedLimitReportByDriver_List,
  Grid_RankingSpeedLimitReportByDriver_Trip_List,
  Grid_RankingSpeedLimitReportByRoute_List,
  Grid_RankingSpeedLimitReportByRoute_Trip_List,
  Grid_RankingSpeedLimitReportByVehicle_List,
  Grid_RankingSpeedLimitReportByVehicle_Trip_List,
  Grid_Reports_Trip_List,
  Grid_SuspectedAccident_Trip_Report,
  Grid_TcoAccidentReport_List,
  Grid_TcoCostReport_List,
  Grid_TcoEntryReport_List,
  Grid_TcoRentReport_List,
  Grid_TcoTicketReport_List,
  Grid_TcoParkingReport_List,
  Grid_TcoTollReport_List,
  Grid_PositionsReport_List,
  Grid_TravelsByDriver_Trip_Report,
  Grid_TravelsByGroup_Trip_Report,
  Grid_TravelsByUtilization_Trip_Report,
  Grid_TravelsByVehicle_Trip_Report,
  Grid_TravelsByVehicleDriver_Trip_Report,
  Grid_TripsDetailDriver_Report,
  Grid_TripsDetail_Report,
  Grid_TripsDetailVehicle_Report,
  Grid_TripsDriver_Report,
  Grid_Trips_Report,
  Grid_TripsVehicle_Report,
  Grid_User_List,
  Grid_User_Trip_List,
  Grid_TempUtilizationDetailDriver_Report,
  Grid_TempUtilizationDetailVehicle_Report,
  Grid_TempUtilizationDetail_Report,
  Grid_TempUtilizationDriver_Report,
  Grid_TempUtilizationDriver_Trip_Report,
  Grid_TempUtilizationVehicle_Report,
  Grid_TempUtilizationVehicle_Trip_Report,
  Grid_TempUtilization_Report,
  Grid_TempUtilization_Trip_Report,
  Grid_Utilization_Trip_Report,
  Grid_VehicleAdm_List,
  Grid_VehicleAdm_Trip_List,
  Grid_Vehicle_List,
  Grid_Vehicle_Trip_List,
  Grid_ViolationDriver_Report,
  Grid_TempViolationDriver_Trip_Report,
  Grid_ViolationVehicle_Report,
  Grid_TempViolationVehicle_Trip_Report,
  Grid_TempViolation_Trip_Report,
  Grid_ViolationsSpeedLimitReport_List,
  Grid_ViolationsSpeedLimitReport_Trip_List,
  Grid_RealTimeVehicle_Trip_Report,
  Grid_RealTimeVehicleHistory_Trip_Report,
  Grid_RealTimeVehicleHistoryAdm_Trip_Report,
  Grid_RealTimePermissionAdm_Trip_Report,
  Grid_RealTimeGroupByVehiclesAdm_Trip_Report,
  Grid_RealTimeGroupByClientDealerAdm_Trip_Report,
  Grid_ViolationRankingReportSPAL_Trip_Report,
  Grid_VisitReport_Trip_Report,
  Grid_RevisionPlan_Trip_Report,
  Grid_RevisionStatus_Trip_Report,
  Grid_RevisionHistory_Trip_Report,
  Grid_Workshop_Trip_List,
  Grid_UnitaryViolation_Trip_Report,
  Grid_ViolationByVehicle_Trip_Report,
  Grid_ViolationByDriver_Trip_Report,
  Grid_ViolationByVehicleDriver_Trip_Report,
  Grid_ViolationByGroup_Trip_Report,
  Grid_DetailedViolation_Trip_Report,
  Grid_Requester_List,
  Grid_DriversAppStatusAdm_Trip_List,
  Grid_GolfleetIdAppStatusAdm_Trip_List,
  Grid_Blockage_Report,
  Grid_BlockageAdm_Report,
  Grid_Checklist_Report,
  Grid_GolfleetIdPermissionHistoricAdm_Trip_List,
  Grid_UtilizationSimplified_Trip_List,
  // Tracking
  Grid_TempDriver_Tracking_List,
  Grid_Driver_Tracking_List,
  Grid_GeneratedAlerts_Tracking_Report,
  Grid_FleetPolicyStatus_Tracking_List,
  Grid_FleetPolicyStatusAdm_Tracking_List,
  Grid_PositionsReport_Tracking_List,
  Grid_TempUtilization_Tracking_Report,
  Grid_TempUtilizationDriver_Tracking_Report,
  Grid_TempUtilizationVehicle_Tracking_Report,
  Grid_Vehicle_Tracking_List,
  Grid_VehicleAdm_Tracking_List,
  Grid_TempViolationVehicle_Tracking_Report,
  Grid_TempViolation_Tracking_Report,
  Grid_TempViolationDriver_Tracking_Report,
  Grid_UnitaryViolation_Tracking_Report,
  Grid_ViolationByVehicle_Tracking_Report,
  Grid_ViolationByDriver_Tracking_Report,
  Grid_ViolationByVehicleDriver_Tracking_Report,
  Grid_ViolationByGroup_Tracking_Report,
  Grid_Utilization_Tracking_Report,
};
