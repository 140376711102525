import angular from 'angular';
import 'ng-redux';

import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-toolbar-report/power-toolbar-report';
import '@power/power-components/components/power-grid/power-grid';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-crud/power-crud';
import '@power/power-components/components/power-popup-share/power-popup-share';
import '@power/power-components/components/power-popup-delete/power-popup-delete';

import '@power/power-components/components/power-toast/power-toast';
import { PowerReportController } from '@power/power-components/components/power-report/power-report';

import template from './golfleet-report-drivers-gid-historic.html';
import './golfleet-report-drivers-gid-historic.scss';

class GolfleetReportDriversGidHistoricController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    this.formDataManualEntry = new FormData();
    this.dataImportPopupEntryError = {};
    this.importOption = 0; // 0: Driver, 1: UserApp
    this.privacyPolicyStatus = null;
  }

  /* Lifecycle */
  /* */

  _onClickImportButton(importOption) {
    this.statusImport = 0;
    this.importOption = importOption;

    if (importOption === 0) {
      this._evtCallPopupLink();
    } else {
      this._privacyPolicyOpen();
    }
  }

  clearPopupManualEntry(input, label, applyScope) {
    const { popup } = this.stateConfig.toolbarConfig.importConfig[this.importOption];
    label.children[0].innerHTML = 'Nenhum arquivo selecionado';
    label.children[0].classList.remove('has-file');
    input.value = '';
    this.formDataManualEntry.delete('importFile');

    popup.footer.buttons.map(item => {
      item.disabled = item.method == 'save';
      return item;
    });

    if (applyScope) this.$scope.$apply();
  }

  evtClickBtnsPopup(method) {
    const labelManualEntry = document.querySelector('#label-manual-entry');
    const inputManualEntry = document.querySelector('#file-manual-entry');

    if (method === 'cancel') {
      this.$.querySelector('#popup-import').toggle();
      this.clearPopupManualEntry(inputManualEntry, labelManualEntry, false);
    } else if (method === 'ok') {
      this.$.querySelector('#popup-import').toggle();
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );
      this.$scope.$broadcast('getDataset', null);
    } else if (method === 'more') {
      this.clearPopupManualEntry(inputManualEntry, labelManualEntry, false);
      this.statusImport = 0;
    } else if (method === 'reform') {
      this.clearPopupManualEntry(inputManualEntry, labelManualEntry, false);
      this.statusImport = 0;
    } else {
      const methodUrl =
        this.stateConfig.toolbarConfig.importConfig[this.importOption].dataPostMethod;

      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );

      this.reportServices
        .sendFileManualEntry(methodUrl, this.formDataManualEntry)
        .then(
          () => {
            this.statusImport = 1;

            this.clearPopupManualEntry(inputManualEntry, labelManualEntry, false);
          },
          ({ data }) => {
            if (data && data.hasError) {
              this.statusImport = 2;

              this.dataImportPopupEntryError.fileName = labelManualEntry.children[0].innerHTML;
              this.dataImportPopupEntryError.countError = data.data.length;
              this.dataImportPopupEntryError.data = data.data;
            }
          },
        )
        .finally(() => {
          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          );
        });
    }
  }

  exportAs(itemReturn) {
    const getExportMethod = itemReturn.method;
    const exportOptions = { exportType: itemReturn.value || 'xlsx' };

    const gridSort = {
      name: this.stateConfig.gridConfig.sortField,
      direction: this.stateConfig.gridConfig.sortDirection,
    };

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this.reportServices
      .getExport(
        exportOptions,
        getExportMethod,
        this.stateConfig.gridConfig.gridTotal,
        this.stateConfig.getDataFixedParams,
        this.currentState.routeName,
        this.stateConfig.gridConfig.gridHeaders,
        gridSort,
        this.stateConfig.filterConditions,
      )
      .finally(() => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
      });
  }

  _callAction(action) {
    if (action.actionType === 'add' && action.type === 'Driver') {
      this._addDriver();
    } else {
      super._callAction(action);
    }
  }

  _addDriver() {
    this.$ngRedux.dispatch({
      type: 'NEXT_ROUTE',
      data: {
        routeName: 'Condutor',
        routeLink: 'driverFormAdm',
        routeTail: null,
        stateConfig: { ...this.stateConfig, gridName: 'Formulário', filterConfig: [] },
      },
    });

    this.$state.go('driverFormAdm');
  }

  _evtCallPopupLink() {
    this.$.querySelector('#popup-import').toggle();
  }

  _privacyPolicyOpen() {
    const privacyPolicyPopup = this.$.querySelector('#golfleet-popup-driver-privacy-policy-report');

    if (privacyPolicyPopup) {
      privacyPolicyPopup.show();
    }
  }

  __onFocusManualEntry() {
    const inputManualEntry = this.$.querySelector('#file-manual-entry');

    const labelManualEntry = this.$.querySelector('#label-manual-entry');

    inputManualEntry.onchange = () => {
      const { files } = inputManualEntry;

      if (files && files.length > 0) {
        const file = files[0];
        const { footer } = this.stateConfig.toolbarConfig.importConfig[this.importOption].popup;

        labelManualEntry.children[0].innerHTML = file.name;
        labelManualEntry.children[0].classList.add('has-file');

        if (file.name.indexOf('.xls') > -1 || file.name.indexOf('.xlsx') > -1) {
          this.formDataManualEntry.append('importFile', file);

          if (labelManualEntry.nextElementSibling.style.display === 'block') {
            labelManualEntry.nextElementSibling.style.display = 'none';
          }

          footer.buttons.map(item => {
            item.disabled = item.method === 'save' ? false : item.disabled;
            return item;
          });
        } else {
          labelManualEntry.nextElementSibling.style.display = 'block';

          footer.buttons.map(item => {
            item.disabled = item.method == 'save';
            return item;
          });
        }

        this.$scope.$apply();
      } else {
        this.clearPopupManualEntry(inputManualEntry, labelManualEntry, true);
      }
    };
  }
}

class GolfleetReportDriversGidHistoric {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetReportDriversGidHistoricController;
  }
}

angular
  .module('golfleet-report-drivers-gid-historic', [
    'ngRedux',
    'power-dropdown',
    'power-toolbar-report',
    'power-grid',
    'power-footer',
    'power-pagination',
    'power-popup',
    'power-crud',
    'power-popup-share',
    'power-popup-delete',
    'power-toast',
  ])
  .component('golfleetReportDriversGidHistoric', new GolfleetReportDriversGidHistoric());
