import angular from 'angular';
import { isMobile } from 'mobile-device-detect';
import '@power/power-components/components/power-popup/power-popup';

import template from './golfleet-popup-driver-privacy-policy.html';
import './golfleet-popup-driver-privacy-policy.scss';

class GolfleetPopupDriverPrivacyPolicyController {
  static get $inject() {
    return ['$element', '$ngRedux', '$scope', '$http', 'urlApi'];
  }

  constructor($element, $ngRedux, $scope, $http, urlApi) {
    Object.assign(this, { $: $element[0], $ngRedux, $scope, $http, urlApi });

    this.privacyPolicyStatus = null;

    this.__appStore = $ngRedux.connect(store =>
      Object({
        userEmail: store.session.userEmail,
        isTrip: store.session.isTrip,
        isSingleSignon: store.session.isSingleSignon,
      }),
    )(this);
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      show: this.show.bind(this),
      close: this.close.bind(this),
    });
  }

  $onDestroy() {
    this.__appStore();
  }
  /**/

  /* Public */
  show() {
    const popopElement = this.$.querySelector('#golfleet-popup-driver-privacy-policy');
    if (!popopElement.hasAttribute('open')) {
      this.$http({
        url: `${this.urlApi}/PrivacyPolicy/GetLastDriverPolicy`,
        method: 'POST',
        data: {
          application: 'DRIVER',
        },
      }).then(
        async success => {
          if (success.status && success.status === 200) {
            const response = success.data;
            this.privacyPolicyStatus = await response.privacyPolicyUserStatus;

            if (this.privacyPolicyStatus?.driverNeedUserAccept) {
              await popopElement.toggle();
            } else {
              await this.$.dispatchEvent(new CustomEvent('acceptPolicy'));
            }
          }
          if (!this.$scope.$$phase) {
            this.$scope.$apply();
          }
        },
        error => error,
      );
    }
  }

  close() {
    const popopElement = this.$.querySelector('#golfleet-popup-driver-privacy-policy');
    if (popopElement.hasAttribute('open')) {
      popopElement.toggle();
    }
  }

  /**/

  /* Private */
  _toggleCheckBoxAccept() {
    const checkBoxElement = this.$.querySelector('#golfleet-popup-driver-privacy-policy-accept');
    const buttonElement = this.$.querySelector(
      '#golfleet-popup-driver-privacy-policy-confirm-button',
    );

    if (checkBoxElement.hasAttribute('checked')) {
      checkBoxElement.removeAttribute('checked');
      buttonElement.setAttribute('disabled', '');
    } else {
      checkBoxElement.setAttribute('checked', '');
      buttonElement.removeAttribute('disabled');
    }
  }

  _toggleCheckBoxSendEmailCopy() {
    const checkBoxElement = this.$.querySelector(
      '#golfleet-popup-driver-privacy-policy-send-email-copy',
    );

    if (checkBoxElement.hasAttribute('checked')) {
      checkBoxElement.removeAttribute('checked');
    } else {
      checkBoxElement.setAttribute('checked', '');
    }
  }

  _togglePopupPdf() {
    const popupPdfElement = this.$.querySelector('#golfleet-popup-driver-privacy-policy-pdf');
    popupPdfElement.toggle();
  }

  _showPrivacyPolicyDocument() {
    if (isMobile) {
      const link = document.createElement('a');
      link.target = '_blank';
      link.href = this.privacyPolicyStatus.driverurl;
      link.click();
    } else {
      const objectElement = document.createElement('embed');
      objectElement.type = 'application/pdf';
      objectElement.src = `${this.privacyPolicyStatus.driverurl}#toolbar=0&navpanes=0&scrollbar=0`;

      const containerElement = this.$.querySelector(
        '#golfleet-popup-driver-privacy-policy-pdf-container',
      );
      containerElement.innerHTML = '';
      containerElement.appendChild(objectElement);

      this._togglePopupPdf();
    }
  }

  async _declarationOfAcceptanceButtonClick() {
    const checkBoxAcceptElement = this.$.querySelector(
      '#golfleet-popup-driver-privacy-policy-accept',
    );
    const checkBoxSendEmailCopyElement = this.$.querySelector(
      '#golfleet-popup-driver-privacy-policy-send-email-copy',
    );
    const acceptButtonElement = this.$.querySelector(
      '#golfleet-popup-driver-privacy-policy-confirm-button',
    );

    if (checkBoxAcceptElement.hasAttribute('checked')) {
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );

      const sendEmailWithCopy = checkBoxSendEmailCopyElement.hasAttribute('checked');
      const { driverid: privacyPolicyId } = this.privacyPolicyStatus;

      const response = await this.$http({
        url: `${this.urlApi}/PrivacyPolicy/DriverAccept`,
        method: 'POST',
        data: {
          email: this.userEmail,
          privacyPolicyId,
          sendEmailWithCopy,
        },
      });

      const { data } = response.data;

      if (data) {
        this.close();
        this.$.dispatchEvent(new CustomEvent('acceptPolicy'));
        checkBoxAcceptElement.removeAttribute('checked');
        checkBoxSendEmailCopyElement.removeAttribute('checked');
        acceptButtonElement.setAttribute('disabled', '');
      }

      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: false },
          bubbles: true,
          composed: true,
        }),
      );
    }
  }
  /**/
}

class GolfleetPopupDriverPrivacyPolicy {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetPopupDriverPrivacyPolicyController;
  }
}
angular
  .module('golfleet-popup-driver-privacy-policy', ['power-popup'])
  .component('golfleetPopupDriverPrivacyPolicy', new GolfleetPopupDriverPrivacyPolicy());
