/* global H: true isIframe: true */
import { PowerMapHereProvider } from '../../../power-map/providers/here/power-map.here-provider';
import '../../../../helpers/is-iframe/is-iframe';
import './power-map-vehicles.here-provider.scss';

class PowerMapVehiclesHereProvider extends PowerMapHereProvider {
  constructor(context, $element, $ngRedux, $scope, $http, urlApi) {
    super(context, $element, $ngRedux, $scope, $http, urlApi);

    this.__golfleetBehavior = $ngRedux.connect(behavior =>
      Object({
        __modules: behavior.session.modules,
      }),
    )(this);

    this.intervalFullscreen = null;
    this.msgInterval = null;
    this.context._nearbyVehicleList = [];

    this.context.pointsOfInterestController = {
      callback: null,
      type: 'point',
      positionList: [],
      // Tap Event
      tapEventListener: evt => {
        if (this.context.pointsOfInterestController.type === 'point')
          this.context.pointsOfInterestController.pointTapHandler(evt);
      },
      pointTapHandler: evt => {
        const targetDataset = 'getData' in evt.target ? evt.target.getData() : {};
        const cardGeoVehicle = document.querySelector('.card-geo-vehicle');
        const selectMarker = document.querySelector('#marker-point');

        if (targetDataset?.geoJson?.type == 'Point') {
          if (!cardGeoVehicle.hasAttribute('active')) {
            cardGeoVehicle.setAttribute('active', '');
          }
          this._searchNearBy(targetDataset.latitude, targetDataset.longitude);
        } else if (!selectMarker.hasAttribute('active')) {
          cardGeoVehicle.removeAttribute('active');
          this.context._nearbyVehicleList = [];
        }
      },
    };
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
  }

  $onDestroy() {
    super.$onDestroy();
  }
  /* */

  /* Public */
  enterFullscreen() {
    const fullscreenExitCallback = () => {
      this.$.removeChild(this.msgInterval);
      clearInterval(this.intervalFullscreen);
      this.$.removeAttribute('fullscreen');
      this.$scope.$emit('onExitFullscreen');
    };

    super.enterFullscreen(fullscreenExitCallback);

    this.$.setAttribute('fullscreen', '');

    this.msgInterval = document.createElement('div');
    this.msgInterval.innerHTML = 'Carregamento automático...';
    this.msgInterval.classList.add('msg-interval');

    this.$.appendChild(this.msgInterval);

    this.intervalFullscreen = setInterval(
      () => this.$scope.$emit('actionFullscreen'),
      1 * 60 * 1000, // 60000 = 1 min (min * secs * miliseconds)
    );

    this.$scope.$emit('onEnterFullscreen');
  }

  requestGeocoding(address) {
    this.$http({
      url: `${this.urlApi}/GeoCoding/Post`,
      method: 'POST',
      data: {
        request: { street: address },
      },
    }).then(
      success => {
        if (success.status && success.status !== 200) return;
        if (success.data.data) {
          const { x: lat, y: lng } = success.data.data;
          if (this._clickToLatLngMarker) {
            this.map.removeObject(this._clickToLatLngMarker);
          }
          this._clickToLatLngMarker = new H.map.Marker({ lat, lng });

          const markerPointElement = document.querySelector('#marker-point');
          if (markerPointElement && !markerPointElement.hasAttribute('active')) {
            this._toggleNearBySearch();
          }

          this._searchNearBy(lat, lng);
          this.map.addObject(this._clickToLatLngMarker);
          this.map.setZoom(16, true);
          this.map.setCenter({ lat, lng }, true);
        }
      },
      () => {},
    );
  }

  addMessageInfo(message) {
    const msgInfo = document.createElement('div');
    msgInfo.innerHTML = message;
    msgInfo.classList.add('msg-interval-map');

    this.$.appendChild(msgInfo);
    return msgInfo;
  }

  removeMessageInfo(element) {
    this.$.removeChild(element);
  }
  /* */

  /* Private */
  _createMarkerIcon({
    color,
    icon,
    apelido: label,
    ignicao: ignition,
    direcao: direction,
    eventoAmbulancia: ambulanceEvent,
    indicadorTemperatura: temperatureIndicator,
    temperatura: temperature,
  }) {
    const AMBULANCE_EVENT_ICON = {
      0: '',
      1: ' &#xe050',
      2: ' &#xe99d',
      3: ' &#xe050 &#xe99d',
    };

    if (icon == 'lock_open') {
      label = label.concat(' (PRÉ-BLOQUEIO)');
    }

    if (icon == 'lock') {
      label = label.concat(' (BLOQUEADO)');
    }

    const labelFormatted = label.concat(AMBULANCE_EVENT_ICON[ambulanceEvent || 0]);

    let temperatureColor = '#A8A8A8';

    switch (temperatureIndicator) {
      case 0:
        temperatureColor = '#5CAAFF';
        break;
      case 1:
        temperatureColor = '#FFF';
        break;
      case 2:
        temperatureColor = '#FF4F63';
        break;
      default:
        break;
    }

    const createIcon = iconString => {
      if (!iconString) return '';
      return `
        <div style="color: #fff;cursor: pointer;transform: rotate(-${direction}deg);">
          <i class="material-icons" style="margin-top: -2px;cursor: pointer;">
            ${iconString}
          </i>
        </div>
      `;
    };
    return new H.map.DomIcon(
      `
      <div style="margin: -20px 0px 0px -20px; z-index: 0;transform: matrix(1, 0, 0, 1, 378, 209); position: relative;">
        <div style="display: flex; align-items: center; font-family: Ubuntu, 'MaterialIcons'; color: rgb(255, 255, 255); padding: 4px 8px; font-size: 12px; border-radius: 2px; background-color: rgba(0, 0, 0, 0.75); position: absolute; top: -20px; left: 36px; white-space: pre;">
          <span>${ignition ? label : labelFormatted}</span>
          ${
            !this._hasModule('FUNC_SENSOR_TEMPERATURA') ||
            temperature === null ||
            typeof temperature === 'undefined'
              ? ''
              : `
          <span style="display: flex; align-items: center; color: ${temperatureColor}"><i style="font-size: 20px;" class="material-icons">gs_temp</i> ${temperature}°C</span>
          `
          }
        </div>
        <div style="width:40px; height:40px; transform: rotate(${direction}deg);">
          <svg xmlns="https://www.w3.org/2000/svg" width="40" height="60" style="margin-top:-20px;">
            <foreignObject x="0" y="0" width="40" height="60" style="">
              <div style="display:flex;justify-content:center;align-items: center;width: 100%;height: 100%;flex-direction: column;">
                <i class="material-icons" style="color:${
                  color || 'var(--primary-color)'
                };cursor: pointer;font-size: 24px;margin-bottom:1px; margin-top: -4px; visibility: ${
        ignition ? 'visible' : 'hidden'
      };">
                  navigation
                </i>
                <div style="background:${
                  color || 'var(--primary-color)'
                };border-radius: 100%;display:flex;justify-content:center;align-items: center;height: 32px;width: 32px;">
                  ${createIcon(icon)}
                </div>
              </div>
            </foreignObject>
          </svg>
        </div>
      </div>
        `,
    );
  }

  _createPointOfInterestMarkerIcon(color) {
    return new H.map.DomIcon(
      `
    <svg xmlns="http://www.w3.org/2000/svg"
    width="28px" height="36px" style="margin: -32px 0px 0px -14px; z-index: 3; transform: matrix(1, 0, 0, 1, 690, 192); position: absolute;">
      <path d="M 19 31 C 19 32.7 16.3 34 13 34 C 9.7 34 7 32.7 7 31 C 7 29.3 9.7 28 13 28 C 16.3 28 19 29.3 19 31 Z" fill="#000" fill-opacity=".2"></path>
      <path d="M 13 0 C 9.5 0 6.3 1.3 3.8 3.8 C 1.4 7.8 0 9.4 0 12.8 C 0 16.3 1.4 19.5 3.8 21.9 L 13 31 L 22.2 21.9 C 24.6 19.5 25.9 16.3 25.9 12.8 C 25.9 9.4 24.6 6.1 22.1 3.8 C 19.7 1.3 16.5 0 13 0 Z" fill="#fff"></path>
      <path d="M 13 2.2 C 6 2.2 2.3 7.2 2.1 12.8 C 2.1 16.1 3.1 18.4 5.2 20.5 L 13 28.2 L 20.8 20.5 C 22.9 18.4 23.8 16.2 23.8 12.8 C 23.6 7.07 20 2.2 13 2.2 Z" fill="${
        color || 'var(--primary-color)'
      }"></path></svg>
    `,
    );
  }

  _geoJsonPropertiesIsVehicle({ properties }) {
    return !!properties.placa;
  }

  _geoJsonToMarker({ geoJson, markerIcon }) {
    return this._geoJsonPropertiesIsVehicle(geoJson)
      ? this._geoJsonToVehicleMarker({ geoJson, markerIcon })
      : this._geoJsonToPointOfInterestMarker({ geoJson });
  }

  _geoJsonToVehicleMarker({ geoJson, markerIcon }) {
    const [[lng, lat]] = geoJson.geometry.coordinates;
    return new H.map.DomMarker(
      { lat, lng },
      {
        icon: markerIcon || this._createMarkerIcon(geoJson.properties),
        data: {
          ...geoJson.properties,
          bubbleContent: `
            <div id="mapPopupHeader">
              <span>${geoJson.properties.placaComApelido}</span>
            </div>
            <div id="mapPopupBody">
              <div style="display: ${geoJson.properties.endereco ? 'block' : 'none'}">
                <b>Endereço:</b>
                <br>
                <span>${geoJson.properties.endereco}</span>
              </div>
              <div style="display: ${geoJson.properties.endereco ? 'block' : 'none'}">
                <b>Cidade - Estado:</b>
                <br>
                <span>${geoJson.properties.cidade} - ${geoJson.properties.estado}</span>
              </div>
              <div>
                <b>Data Hora:</b>
                <br>
                <span>${geoJson.properties.ultimaPosicaoFormatado}</span>
              </div>
              <div>
                <b>Velocidade:</b>
                <br>
                <span>
                  <i class="material-icons"
                    style="font-size: 11px;
                    color: ${!geoJson.properties.ignicao ? '#980A1A' : '#4AAE4E'}">
                    lens
                  </i>
                  ${geoJson.properties.velocidadeFormatada}
                </span>
              </div>
              <div>
                <b>Condutor:</b>
                <br>
                <span>${geoJson.properties.motorista || 'Não identificado'}</span>
              </div>
              ${
                isIframe()
                  ? ''
                  : `
                <div style="margin-top:8px;text-align:center">
                  <a class="gs-link"
                    target="_blank"
                    href="${`https://maps.google.com/maps?layer=c&q=${lat},${lng}&cbll=${lat},${lng}&cbp=11,0,0,0,0&z=17&ll=${lat},${lng}`}">
                    Ver no StreetView
                  </a>
                </div>
              `
              }
            </div>
            <div id="mapPopupFooter">
              <span> Lat: ${lat} </span>
              <span> Lon: ${lng} </span>
            </div>
          `,
        },
      },
    );
  }

  _geoJsonToPointOfInterestMarker({ geoJson }) {
    switch (geoJson.properties.categoryType) {
      case 1:
        geoJson.properties.color = '#315A94';
        break;
      case 2:
        geoJson.properties.color = '#8A4B08';
        break;
      case 3:
        geoJson.properties.color = '#EE1131';
        break;
      case 4:
        geoJson.properties.color = '#FFBF00';
        break;
      case 5:
        geoJson.properties.color = '#04B404';
        break;
      default:
        geoJson.properties.color = '#555555';
        break;
    }

    this.map.addEventListener(
      'tap',
      this.context.pointsOfInterestController.tapEventListener,
      true,
    );

    const [lng, lat] = geoJson.geometry.coordinates;
    const marker = new H.map.DomMarker(
      { lat, lng },
      {
        icon: this._createPointOfInterestMarkerIcon(geoJson.properties.color),
        data: {
          ...geoJson.properties,
          bubbleContent: geoJson.properties.isDrawPoint
            ? ''
            : `
            <div id="mapPopupHeader">
              <span>${geoJson.properties.name}</span>
            </div>
            <div id="mapPopupBody">
              <div>
                <b>Descrição:</b>
                <br>
                <span>${geoJson.properties.description || '---'}</span>
              </div>
              <div>
                <b>Categoria:</b>
                <br>
                <span>${geoJson.properties.categoryName || '---'}</span>
              </div>
              <div>
                <b>Privacidade:</b>
                <br>
                <span>${geoJson.properties.privacy || '---'}</span>
              </div>
            </div>
            <div id="mapPopupFooter">
              <span> Lat: ${parseFloat(geoJson.properties.latitude).toFixed(6)} </span>
              <span> Lon: ${parseFloat(geoJson.properties.longitude).toFixed(6)} </span>
            </div>
          `,
        },
      },
    );
    marker.draggable = true;
    return marker;
  }

  _geoJsonToCircle({ geoJson }) {
    const [[lng, lat]] = geoJson.geometry.coordinates;
    return new H.map.Circle({ lat, lng }, geoJson.properties.radius, {
      data: {
        ...geoJson.properties,
        bubbleContent: `
            <div id="mapPopupHeader">
              <span>${geoJson.properties.description}</span>
            </div>
            <div id="mapPopupBody">
              <div>
                <b>Descrição:</b>
                <br>
                <span>${geoJson.properties.extendedDescription || '---'}</span>
              </div>
              <div>
                <b>Categoria:</b>
                <br>
                <span>${geoJson.properties.categoryName || '---'}</span>
              </div>
              <div>
                <b>Privacidade:</b>
                <br>
                <span>${geoJson.properties.privacy || '---'}</span>
              </div>
              <div>
                <b>Status:</b>
                <br>
                <span>${geoJson.properties.providerStatusDesc || '---'}</span>
              </div>
            </div>
            <div id="mapPopupFooter">
              <span> Lat: ${parseFloat(geoJson.properties.latitude).toFixed(4)} </span>
              <span> Lon: ${parseFloat(geoJson.properties.longitude).toFixed(4)} </span>
            </div>
          `,
      },
      style: {
        fillColor: this._hexToRgba(geoJson.properties.color, 0.33),
        strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
      },
    });
  }

  _geoJsonToPolygon({ geoJson }) {
    const [coordinates] = geoJson.geometry.coordinates;
    return new H.map.Polygon(
      coordinates.reduce((acc, coordinate) => {
        const [lng, lat] = coordinate;
        acc.pushLatLngAlt(lat, lng);
        return acc;
      }, new H.geo.LineString()),
      {
        data: {
          ...geoJson.properties,
          bubbleContent: `
            <div id="mapPopupHeader">
              <span>${geoJson.properties.description}</span>
            </div>
            <div id="mapPopupBody">
              <div>
                <b>Descrição:</b>
                <br>
                <span>${geoJson.properties.extendedDescription || '---'}</span>
              </div>
              <div>
                <b>Categoria:</b>
                <br>
                <span>${geoJson.properties.categoryName || '---'}</span>
              </div>
              <div>
                <b>Privacidade:</b>
                <br>
                <span>${geoJson.properties.privacy || '---'}</span>
              </div>
              <div>
                <b>Status:</b>
                <br>
                <span>${geoJson.properties.providerStatusDesc || '---'}</span>
              </div>
            </div>
            <div id="mapPopupFooter">
              <span> Lat: ${parseFloat(geoJson.properties.latitude).toFixed(4)} </span>
              <span> Lon: ${parseFloat(geoJson.properties.longitude).toFixed(4)} </span>
            </div>
          `,
        },
        style: {
          fillColor: this._hexToRgba(geoJson.properties.color, 0.33),
          strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
        },
      },
    );
  }

  _geoJsonToMultiPolygon({ geoJson }) {
    const { coordinates } = geoJson.geometry;

    return new H.map.Polygon(
      coordinates.reduce((acc, [polygonArray]) => {
        acc.push(
          new H.geo.Polygon(
            polygonArray.reduce((lineStringAcc, latLngArray) => {
              const [lng, lat] = latLngArray;
              lineStringAcc.pushLatLngAlt(lat, lng);
              return lineStringAcc;
            }, new H.geo.LineString()),
          ),
        );
        return acc;
      }, new H.geo.MultiPolygon([])),
      {
        data: {
          ...geoJson.properties,
          bubbleContent: geoJson.properties.isDrawPoint
            ? ''
            : `
          <div id="mapPopupHeader">
            <span>${geoJson.properties.description}</span>
          </div>
          <div id="mapPopupBody">
            <div>
              <b>Descrição:</b>
              <br>
              <span>${geoJson.properties.extendedDescription || '---'}</span>
            </div>
            <div>
              <b>Categoria:</b>
              <br>
              <span>${geoJson.properties.categoryName || '---'}</span>
            </div>
            <div>
              <b>Privacidade:</b>
              <br>
              <span>${geoJson.properties.privacy || '---'}</span>
            </div>
            <div>
              <b>Status:</b>
              <br>
              <span>${geoJson.properties.providerStatusDesc || '---'}</span>
            </div>
          </div>
          <div id="mapPopupFooter">
            <span> Lat: ${parseFloat(geoJson.properties.latitude).toFixed(4)} </span>
            <span> Lon: ${parseFloat(geoJson.properties.longitude).toFixed(4)} </span>
          </div>
        `,
        },
        style: {
          fillColor: this._hexToRgba(geoJson.properties.color, 0.33),
          strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
        },
      },
    );
  }

  _geoJsonToPolyline({ geoJson }) {
    return new H.map.Polyline(
      geoJson.geometry.coordinates.reduce((acc, coordinate) => {
        const [lng, lat] = coordinate;
        acc.pushLatLngAlt(lat, lng);
        return acc;
      }, new H.geo.LineString()),
      {
        data: {
          ...geoJson.properties,
          bubbleContent: `
            <div id="mapPopupHeader">
              <span>${geoJson.properties.description}</span>
            </div>
            <div id="mapPopupBody">
              <div>
                <b>Descrição:</b>
                <br>
                <span>${geoJson.properties.extendedDescription || '---'}</span>
              </div>
              <div>
                <b>Categoria:</b>
                <br>
                <span>${geoJson.properties.categoryName || '---'}</span>
              </div>
              <div>
                <b>Privacidade:</b>
                <br>
                <span>${geoJson.properties.privacy || '---'}</span>
              </div>
              <div>
                <b>Status:</b>
                <br>
                <span>${geoJson.properties.providerStatusDesc || '---'}</span>
              </div>
            </div>
            <div id="mapPopupFooter">
              <span> Lat: ${parseFloat(geoJson.properties.latitude).toFixed(4)} </span>
              <span> Lon: ${parseFloat(geoJson.properties.longitude).toFixed(4)} </span>
            </div>
          `,
        },
        style: {
          lineWidth: 6,
          strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
        },
      },
    );
  }

  _geoJsonToRectangle({ geoJson }) {
    const [coordinates] = geoJson.geometry.coordinates;
    return new H.map.Rect(
      coordinates.reduce((acc, coordinate) => {
        const [lng, lat] = coordinate;
        acc.pushLatLngAlt(lat, lng);
        return acc;
      }, new H.geo.LineString()),
      {
        data: {
          ...geoJson.properties,
          bubbleContent: `
            <div id="mapPopupHeader">
              <span>${geoJson.properties.description}</span>
            </div>
            <div id="mapPopupBody">
              <div>
                <b>Descrição:</b>
                <br>
                <span>${geoJson.properties.extendedDescription || '---'}</span>
              </div>
              <div>
                <b>Categoria:</b>
                <br>
                <span>${geoJson.properties.categoryName || '---'}</span>
              </div>
              <div>
                <b>Privacidade:</b>
                <br>
                <span>${geoJson.properties.privacy || '---'}</span>
              </div>
              <div>
                <b>Status:</b>
                <br>
                <span>${geoJson.properties.providerStatusDesc || '---'}</span>
              </div>
            </div>
            <div id="mapPopupFooter">
              <span> Lat: ${parseFloat(geoJson.properties.latitude).toFixed(4)} </span>
              <span> Lon: ${parseFloat(geoJson.properties.longitude).toFixed(4)} </span>
            </div>
          `,
        },
        style: {
          fillColor: this._hexToRgba(geoJson.properties.color, 0.33),
          strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
        },
      },
    );
  }

  _toggleNearBySearch() {
    const selectMarker = document.querySelector('#marker-point');
    const cardGeoVehicle = document.querySelector('.card-geo-vehicle');

    const clickToLatLng = evt => {
      const coord = this.map.screenToGeo(
        evt.currentPointer.viewportX,
        evt.currentPointer.viewportY,
      );
      if (this._clickToLatLngMarker) {
        this.map.removeObject(this._clickToLatLngMarker);
      }
      this._clickToLatLngMarker = new H.map.Marker({ lat: coord.lat, lng: coord.lng });
      this.map.addObject(this._clickToLatLngMarker);
      this._searchNearBy(coord.lat, coord.lng);
    };

    if (!selectMarker.hasAttribute('active')) {
      selectMarker.setAttribute('active', '');
      selectMarker.classList.add('H_active');
      cardGeoVehicle.setAttribute('active', '');

      this._clickToLatLng = clickToLatLng.bind(this);
      this.map.addEventListener('tap', this._clickToLatLng);
    } else {
      selectMarker.removeAttribute('active');
      selectMarker.classList.remove('H_active');
      cardGeoVehicle.removeAttribute('active');

      if (this._clickToLatLngMarker) {
        this.map.removeObject(this._clickToLatLngMarker);
        this._clickToLatLngMarker = null;
      }

      this.map.removeEventListener('tap', this._clickToLatLng);
      this._clickToLatLng = null;
      this.context._nearbyVehicleList = [];
    }
  }

  _searchNearBy(lat, lng) {
    const vehicles = (this.context.reportDataset || []).map(item => item.id);
    this.$http({
      url: `${this.urlApi}/Vehicle/GetVehiclesByCoordinates`,
      method: 'POST',
      data: {
        latitude: lat,
        longitude: lng,
        vehicles: vehicles.reduce((acc, item) => {
          if (acc.includes(item)) {
            return acc;
          }
          return acc.concat(item);
        }, []),
      },
    }).then(
      success => {
        this.context._nearbyVehicleList = success.data.data;
      },
      () => {
        this.context._nearbyVehicleList = [];
      },
    );
  }

  _hasModule(module) {
    return this.__modules.includes(module);
  }
  /* */

  /* Observers */
  /* */
}

export { PowerMapVehiclesHereProvider };
