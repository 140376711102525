import angular from 'angular';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import Treemap from 'highcharts/modules/treemap';
import 'highcharts-regression';

import template from './golfleet-chart-motion-log.html';
import './golfleet-chart-motion-log.scss';

Exporting(Highcharts);
OfflineExporting(Highcharts);
Treemap(Highcharts);

class GolfleetChartMotionLogController {
  static get $inject() {
    return ['$element', '$scope'];
  }

  constructor($element, $scope) {
    Object.assign(this, { $: $element[0], $scope });

    this.dataset = [];
    this.chartInstance = null;
    this.simulationProgress = 0;
    this.accidentTime = '';
  }

  // #region Lifecycle
  $onInit() {
    Object.assign(this.$, {
      updateChart: this.updateChart.bind(this),
    });

    this.$scope.$watch(() => this.dataset, this.__datasetChanged.bind(this));

    this.$.parentElement
      .querySelector('golfleet-motion-log')
      .addEventListener('update-progress', this.__onUpdateProgress.bind(this));
  }
  // #endregion Lifecycle

  // #region Public
  updateChart() {
    const self = this;

    this.chartInstance = new Highcharts.Chart({
      chart: {
        renderTo: 'report-chart-container',
        type: 'line',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        style: {
          fontFamily: 'Ubuntu',
          fontSize: '12px',
        },
      },
      title: { text: null },
      yAxis: [
        {
          labels: {
            enabled: true,
            formatter() {
              return `${this.value} mG`;
            },
          },
          title: { text: null },
          height: '50%',
        },
        {
          labels: {
            enabled: true,
            formatter() {
              return `${this.value} Km/h`;
            },
          },
          title: { text: null },
          top: '50%',
          height: '50%',
          opposite: true,
        },
      ],
      xAxis: {
        plotLines: [
          {
            color: '#8300FF',
            width: 2,
            value: this.dataset.findIndex(data => data.dataHora === this.accidentTime),
          },
        ],
        labels: {
          y: 24,
          enabled: true,
          formatter() {
            return self.dataset[this.value].time;
          },
        },
        title: { text: null },
        alternateGridColor: 'rgba(0, 0, 0, 0.05)',
      },
      tooltip: {
        enabled: true,
        shared: true,
        useHTML: true,
        backgroundColor: '#FFF',
        formatter() {
          return `
            <table>
              <tr>
                <th colspan="2" style="color: #fff;">${self.dataset[this.x].time}</th>
              </tr>
              <tr>
                <td style="color: ${this.points[0].series.color};">
                  ${this.points[0].series.name}
                </td>
                <td style="text-align: right; color: #fff;">${this.points[0].y} mG</td>
              </tr>
              <tr>
              <td style="color: ${this.points[1].series.color};">
                ${this.points[1].series.name}
              </td>
                <td style="text-align: right; color: #fff;">${this.points[1].y} mG</td>
              </tr>
              <tr>
                <td style="color: ${this.points[2].series.color};">
                  ${this.points[2].series.name}
                </td>
                <td style="text-align: right; color: #fff;">${this.points[2].y} mG</td>
              </tr>
              <tr>
                <td style="color: ${this.points[3].series.color};">
                  ${this.points[3].series.name}
                </td>
                <td style="text-align: right; color: #fff;">${this.points[3].y} Km/h</td>
              </tr>
            </table>
          `;
        },
      },
      plotOptions: {
        series: {
          marker: {
            enabled: true,
          },
          turboThreshold: 2056,
        },
      },
      exporting: { enabled: false },
      legend: { enabled: false },
      credits: { enabled: false },
      series: [
        {
          name: 'Longitudinal',
          color: '#CB4F40',
          data: this.dataset.map(data => data.x),
          // y: this.dataset.map(data => data.time),
        },
        {
          name: 'Lateral',
          color: '#EBB53E',
          data: this.dataset.map(data => data.z),
          // y: this.dataset.map(data => data.time),
        },
        {
          name: 'Vertical',
          color: '#479A5F',
          data: this.dataset.map(data => data.y),
          // y: this.dataset.map(data => data.time),
        },
        {
          name: 'Velocidade',
          color: '#2196F3',
          yAxis: 1,
          data: this.dataset.map(data => data.velocity),
          // y: this.dataset.map(data => data.time),
        },
      ],
    });
  }
  // #endregion Public

  // #region Protected
  __exportChartToPNG() {
    this.chartInstance.exportChartLocal({
      filename: `Fleet Rec`,
      type: 'application/png',
    });
  }

  __datasetChanged(newValue) {
    if (newValue) {
      this.updateChart(newValue);
    }
  }

  __onUpdateProgress(evt) {
    const containerWidth = Number.parseInt(
      getComputedStyle(this.$.querySelector('.highcharts-plot-background')).width.replace(
        /\D\w+/,
        '',
      ),
    );

    this.$.style.setProperty('--progress', `${(containerWidth * evt.detail) / 100}px`);
  }
  // #endregion Protected
}

class GolfleetChartMotionLog {
  constructor() {
    this.template = template;
    this.bindings = {
      dataset: '=?',
      simulationProgress: '=?',
      accidentTime: '=?',
    };
    this.controller = GolfleetChartMotionLogController;
  }
}

angular
  .module('golfleet-chart-motion-log', [])
  .component('golfleetChartMotionLog', new GolfleetChartMotionLog());
