import angular from 'angular';
import 'ng-redux';

import noUiSlider from 'nouislider';
import 'nouislider/distribute/nouislider.css';

import '../power-dropdown/power-dropdown';
import '../power-toolbar/power-toolbar';
import '../power-grid-positions/power-grid-positions';
import '../power-map-positions/power-map-positions';
import '../power-photo-indexation/power-photo-indexation';
import '../power-media-events/power-media-events';
import '../power-footer/power-footer';
import '../power-pagination/power-pagination';
import '../power-chart-position/power-chart-position';
import '../power-toast/power-toast';
import '../../directives/ng-tippy/ng-tippy';
import '../../directives/ng-resize/ng-resize';

import { PowerReportController } from '../power-report/power-report';

import template from './power-report-positions.html';
import './power-report-positions.scss';

class PowerReportPositionsController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    this.legendList = [];
    this.mapModeList = [
      { type: 'route', description: 'Reconstrução de Rota', selected: false },
      { type: 'all', description: 'Todos os Pontos', selected: true },
      { type: 'heat', description: 'Mapa de Calor', selected: false },
      { type: 'step', description: 'Ponto a ponto', selected: false, step: 0 },
    ];
    this.mapLayerList = [
      {
        id: 0,
        description: 'Áreas de Controle',
        method: 'GeoFence/Post',
        lastMapBounds: null,
        selected: true,
      },
    ];
    this.timelineSlider = null;
    this.divMatchRouteTooltipContent = null;
    this.tabs = [
      { name: 'feature', label: 'Funcionalidade', selected: true },
      { name: 'addon', label: 'Experimente' },
    ];
    this.featureOptions = [
      {
        name: 'featureMatchRoute',
        label: 'Rota Precisa',
        src: 'addon-match-route-on.png',
        selected: true,
      },
      { name: 'featureMatchRouteOff', label: 'Ponto a Ponto', src: 'addon-match-route-off.png' },
    ];
    this.selectedFeature = this._getSelectedFeature();

    this._appBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Storage */
        themeColors: behavior.session.themeColors,
      }),
    )(this);
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();

    this.$.addEventListener('mapLoaded', () =>
      this.$.querySelector('#report-body-map')
        .getMap()
        .onMoveEnd(() =>
          this.mapLayerList.map(mapLayer => {
            if (mapLayer.selected) this._toggleMapLayer({ ...mapLayer, selected: false });

            return mapLayer;
          }),
        ),
    );

    this.$scope.$on('updateTimelineSlider', (evt, evtParams) =>
      this._updateTimelineSlider(evtParams.value),
    );

    this.$scope.$watch(() => this.reportDataset, this.__reportDatasetChanged.bind(this));

    Object.assign(this.$, {
      showToast: this.showToast.bind(this),
    });

    this.divMatchRouteTooltipContent = document.getElementById(
      'matchRouteTooltipContent',
    )?.innerHTML;
  }
  /* */

  /* Public */
  changeView(viewMode) {
    this.stateConfig.viewMode = viewMode;
    this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });
    switch (viewMode) {
      case 'map':
        this.$.setAttribute('map-view', '');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('split-view');
        this.$.removeAttribute('chart-view');
        this.$.removeAttribute('photos-view');
        this.$.removeAttribute('indexation-view');
        if (this.$.querySelector('#report-body-map').getMap) this._adjustMap();
        break;
      case 'chart':
        this.$.setAttribute('chart-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('split-view');
        this.$.removeAttribute('photos-view');
        this.$.removeAttribute('indexation-view');
        this.$.querySelector('#report-body-chart').updateChart();
        break;
      case 'split':
        this.$.setAttribute('split-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('chart-view');
        this.$.removeAttribute('photos-view');
        this.$.removeAttribute('indexation-view');
        if (this.$.querySelector('#report-body-map').getMap) this._adjustMap();
        break;
      case 'photos':
        this.$.setAttribute('photos-view', '');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('chart-view');
        this.$.removeAttribute('split-view');
        this.$.removeAttribute('indexation-view');
        break;
      case 'indexation':
        this.$.setAttribute('indexation-view', '');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('chart-view');
        this.$.removeAttribute('split-view');
        this.$.removeAttribute('photos-view');
        break;
      default:
        this.$.setAttribute('grid-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('split-view');
        this.$.removeAttribute('chart-view');
        this.$.removeAttribute('photos-view');
        this.$.removeAttribute('indexation-view');
        break;
    }
  }

  changePage(page) {
    super.changePage(page);

    const gridElement = this.$.querySelector('#report-body-grid');
    const step = this.stateConfig.gridConfig.pageSize * (page - 1);

    gridElement.setActiveRow({ index: step });

    if (this._getSelectedMapMode().type == 'step') {
      this._renderStepMode(step);
    } else {
      this.mapModeList = this.mapModeList.map(mode =>
        mode.type == 'step' ? { ...mode, step } : mode,
      );
    }
  }

  changeRow(page, row) {
    if (page != this.stateConfig.gridConfig.page) {
      this.changePage(page);
    }

    if (row >= 0) {
      this._renderStepMode(row);
    }
  }

  changePageSize(pageSize) {
    super.changePageSize(pageSize);

    const step = 0;

    if (this._getSelectedMapMode().type == 'step') {
      this._renderStepMode(step);
    } else {
      this.mapModeList = this.mapModeList.map(mode =>
        mode.type == 'step' ? { ...mode, step } : mode,
      );
    }
  }

  showToast(event) {
    Object.assign(this, {
      toast: {
        text: event.detail.message,
      },
    });
    this.$.querySelector('#power-popup-addon-request-toast').toggle(true);
  }
  /* */

  /* Private */
  _callAction(action) {
    switch (action.actionType) {
      case 'selectAllOrNone':
        this.$.querySelector('#report-body-indexation').selectAllOrNone();
        break;
      case 'toggleSelection':
        this.$.querySelector('#report-body-indexation').toggleSelectedItems();
        break;
      case 'multiIndexation':
        this.$.querySelector('#report-body-indexation').showIndexationOptions();
        break;
      default:
        super._callAction(action);
        break;
    }
  }

  _goToLink(evt, evtParams) {
    if (evtParams.routeLink == 'map') {
      if (this.stateConfig.viewMode != 'map' && this.stateConfig.viewMode != 'split') {
        if (window.innerWidth > 878) this.changeView('split');
        else this.changeView('map');
      } else {
        this.$.querySelector('#report-body-map').zoomTo(evtParams.tableRowData);
      }

      if (this._getSelectedMapMode().type != 'step') {
        this._selectMapMode(this.mapModeList.filter(mode => mode.type == 'step')[0]);
      }

      const gridElement = this.$.querySelector('#report-body-grid');

      gridElement.setActiveRow({ index: evtParams.index }).then(() => {
        this.selectedVehicle = gridElement.getActiveRow();
      });
    } else {
      super._goToLink(evt, evtParams);
    }
  }

  _toggleMapLayer(mapLayer) {
    mapLayer.selected = !mapLayer.selected;
    this.mapLayerList = this.mapLayerList.map(layer =>
      layer.id == mapLayer.id ? mapLayer : layer,
    );
    if (mapLayer.selected) this._renderMapLayer(mapLayer.id);
    else {
      this.mapLayerList = this.mapLayerList.map(layer =>
        layer.id == mapLayer.id ? { ...layer, lastMapBounds: null } : layer,
      );
      this.$.querySelector('#report-body-map').removeLayers([mapLayer.description]);
    }
  }

  _renderMapLayer(mapLayerId) {
    const mapLayer = this.mapLayerList.filter(layer => layer.id == mapLayerId)[0];

    const mapComponent = this.$.querySelector('#report-body-map');
    const mapBounds = mapComponent.getBounds();

    if (!mapLayer.lastMapBounds || !mapLayer.lastMapBounds.containsRect(mapBounds)) {
      this.mapLayerList = this.mapLayerList.map(layer =>
        layer.id == mapLayerId ? { ...layer, lastMapBounds: mapBounds } : layer,
      );
      return this.$http({
        url: `${this.urlApi}/${mapLayer.method}`,
        method: 'POST',
        data: {
          request: {
            filter: {
              conditions: [
                {
                  id: -1,
                  field: 'bounds',
                  value: [mapBounds.asArray().join()],
                },
              ],
            },
            page: 0,
            length: 10,
            isPaginated: false,
            sort: { direction: 'asc', name: 'description' },
          },
        },
      }).then(success => {
        let dataset = success.data.data.data.map(data => ({
          ...data,
          color: data.color || data.categoryColor,
        }));
        dataset = mapComponent.sortDatasetByBounds(dataset);
        mapComponent.renderDataset({ dataset: dataset.reverse(), layerName: mapLayer.description });
      });
    }

    return null;
  }

  _selectMapMode(mapMode) {
    if (mapMode.selected) return;
    const mapComponent = this.$.querySelector('#report-body-map');
    this.mapModeList = this.mapModeList.map(mode => ({
      ...mode,
      selected: mode.type == mapMode.type,
    }));
    switch (mapMode.type) {
      case 'route':
        mapComponent.removeLayers([
          'allOn',
          'allOff',
          'allIddle',
          'allInvalid',
          'heatLayer',
          'stepMarker',
          'routeMarkers',
          'routeLinestrings',
        ]);
        mapComponent.toggleTimelineControl(true);
        // this._renderRouteMode(this.reportDataset);

        this._renderRouteMode(this.reportDataset).then(() => {
          if (!this.timelineSlider)
            // eslint-disable-next-line no-unused-expressions
            this.reportDataset.length > 0
              ? this._createTimelineSlider(this.reportDataset.length - 1)
              : null;
          else
            this._updateTimelineSlider(
              this.reportDataset.length - 1,
              this.reportDataset.length - 1,
            );
        });
        break;
      case 'all':
        mapComponent.removeLayers(['heatLayer', 'stepMarker', 'routeMarkers', 'routeLinestrings']);
        mapComponent.toggleTimelineControl(false);
        this._renderAllMode(this.reportDataset);
        break;
      case 'heat':
        mapComponent.removeLayers([
          'allOn',
          'allOff',
          'allIddle',
          'allInvalid',
          'stepMarker',
          'routeMarkers',
          'routeLinestrings',
        ]);
        mapComponent.toggleTimelineControl(false);
        this._renderHeatMode(this.reportDataset);
        break;
      case 'step':
        mapComponent.removeLayers([
          'allOn',
          'allOff',
          'allIddle',
          'allInvalid',
          'heatLayer',
          'routeMarkers',
          'routeLinestrings',
        ]);
        mapComponent.toggleTimelineControl(false);
        this.changeRow(this.stateConfig.gridConfig.page, mapMode.step);
        break;
      default:
        break;
    }
  }

  async _renderAllMode(dataset) {
    const mapComponent = this.$.querySelector('#report-body-map');

    const parsedDataset = dataset.reduce((acc, data) => {
      switch (data.statusIgnicao) {
        case 1:
          return Object.assign(acc, {
            on: acc.on.concat(
              Object.assign(data, {
                color: '#4AAE4E',
                icon: data.icon || 'directions_car',
                markerIcon: 'MarkerIcon',
              }),
            ),
          });
        case 2:
          return Object.assign(acc, {
            iddle: acc.iddle.concat(
              Object.assign(data, {
                color: '#DECE00',
                icon: data.icon || 'directions_car',
                markerIcon: 'MarkerIcon',
              }),
            ),
          });
        case 3:
          return Object.assign(acc, {
            off: acc.off.concat(
              Object.assign(data, {
                color: '#980A1A',
                icon: data.icon || 'directions_car',
                markerIcon: 'MarkerIcon',
              }),
            ),
          });
        default:
          return Object.assign(acc, {
            invalid: acc.invalid.concat(
              Object.assign(data, {
                color: '#949494',
                icon: data.icon || 'directions_car',
                markerIcon: 'MarkerIcon',
              }),
            ),
          });
      }
    }, Object({ on: [], iddle: [], off: [], invalid: [] }));

    await mapComponent.renderDataset({
      dataset: parsedDataset.on,
      layerName: 'allOn',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#4AAE4E',
    });

    await mapComponent.renderDataset({
      dataset: parsedDataset.iddle,
      layerName: 'allIddle',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#DECE00',
    });

    await mapComponent.renderDataset({
      dataset: parsedDataset.off,
      layerName: 'allOff',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#980A1A',
    });

    await mapComponent.renderDataset({
      dataset: parsedDataset.invalid,
      layerName: 'allInvalid',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#949494',
    });

    mapComponent.resizeMap();
    mapComponent.zoomTo({ latitude: -20.933402286553182, longitude: -44.95093750000001 }, 4);
  }

  async _renderHeatMode(dataset) {
    if (dataset.length == 0) return;

    const mapComponent = this.$.querySelector('#report-body-map');
    await mapComponent.renderDataset({ dataset, layerName: 'heatLayer', type: 'HeatLayer' });
    mapComponent.resizeMap();
    mapComponent.fitLayers([], 'heatLayer');
  }

  async _renderStepMode(index) {
    const mapComponent = this.$.querySelector('#report-body-map');
    this.mapModeList = this.mapModeList.map(mode =>
      mode.type == 'step' ? { ...mode, step: index } : mode,
    );
    const dataPosition = this.reportDataset[index];
    this.selectedVehicle = dataPosition;

    if (!dataPosition) return;

    switch (dataPosition.statusIgnicao) {
      case 1:
        Object.assign(dataPosition, {
          color: '#4AAE4E',
          icon: dataPosition.icon || 'directions_car',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 2:
        Object.assign(dataPosition, {
          color: '#DECE00',
          icon: dataPosition.icon || 'directions_car',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 3:
        Object.assign(dataPosition, {
          color: '#980A1A',
          icon: dataPosition.icon || 'directions_car',
          markerIcon: 'MarkerIcon',
        });
        break;
      default:
        Object.assign(dataPosition, {
          color: '#949494',
          icon: dataPosition.icon || 'directions_car',
          markerIcon: 'MarkerIcon',
        });
        break;
    }

    await mapComponent.renderDataset({
      dataset: [dataPosition],
      layerName: 'stepMarker',
      type: 'MarkerFeatureGroup',
    });

    mapComponent.fitLayers(['stepMarker']);
    mapComponent.openPopup(0, 'stepMarker');
  }

  _getSelectedMapMode() {
    return this.mapModeList.filter(mode => mode.selected)[0];
  }

  _getStepPosition() {
    return this.mapModeList.filter(mode => mode.type == 'step')[0].step;
  }

  _formatLatLng(str) {
    return str.replace(',', '.');
  }

  _adjustMap() {
    const mapComponent = this.$.querySelector('#report-body-map');
    mapComponent.resizeMap();
    switch (this._getSelectedMapMode().type) {
      case 'route':
        mapComponent.fitLayers(['routeMarkers', 'routeLinestrings']);
        break;
      case 'all':
        mapComponent.zoomTo({ latitude: -20.933402286553182, longitude: -44.95093750000001 }, 4);
        break;
      case 'heat':
        mapComponent.fitLayers([], 'heatLayer');
        break;
      case 'step':
        mapComponent.fitLayers(['stepMarker']);
        mapComponent.openPopup(0, 'stepMarker');
        break;
      default:
        break;
    }
  }

  async _renderRouteMode(dataset) {
    if (dataset.length == 0) return;

    const serviceMatchRouteIsActive = await this._hasServiceMatchRouteActive(dataset[0].placa);
    const { ativo, compRotaPrecisa } = serviceMatchRouteIsActive.data.data
      ? serviceMatchRouteIsActive.data.data
      : { ativo: false };

    this.$.querySelector('#change-mode').style.display =
      compRotaPrecisa && !ativo ? 'block' : 'none';

    if (ativo) {
      const gpxTemplate = (arr = []) => `<?xml version="1.0"?>
        <gpx version="1.0"
          xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
          xmlns="http://www.topografix.com/GPX/1/0"
          xsi:schemaLocation="http://www.topografix.com/GPX/1/0 http://www.topografix.com/GPX/1/0/gpx.xsd"
        >
          <trk>
            <trkseg>
              ${arr
                .map(
                  item =>
                    `<trkpt lat="${item.latitude.replace(',', '.')}" lon="${item.longitude.replace(
                      ',',
                      '.',
                    )}"/>`,
                )
                .join('\n        ')}
            </trkseg>
          </trk>
        </gpx>
      `;

      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );

      const request = arr =>
        this.$http({
          url: 'https://rme.cit.api.here.com/2/matchroute.json?routemode=car&app_id=Iajt61WMPoEvbbMdvMH8&app_code=Z7ncDhO8GxIIHGGDmzCkWQ',
          method: 'POST',
          headers: {
            'Content-Type': 'text/plain',
            Accept: '*/*',
          },
          data: gpxTemplate(arr),
        }).then(result => {
          const markers = result.data.TracePoints.map((tracePoint, index, tracePoints) => ({
            ...dataset[index],
            latitude:
              tracePoint.confidenceValue > 0 ? tracePoint.latMatched : dataset[index].latitude,
            longitude:
              tracePoint.confidenceValue > 0 ? tracePoint.lonMatched : dataset[index].longitude,
            first: index === 0,
            last: index === tracePoints.length - 1,
            markerIcon: 'RouteIcon',
          }));

          const linestrings = [
            {
              geoJson: {
                type: 'LineString',
                coordinates: result.data.RouteLinks.reduce(
                  (acc, routeLink) => [...acc, ...routeLink.shape.split(' ')],
                  [],
                ).reduce((acc, routeLink, index, routeLinks) => {
                  if (index % 2 != 0) {
                    acc.push([routeLink, routeLinks[index - 1]]);
                  }
                  return acc;
                }, []),
                // properties: { dataHora: dataset[i].dataHora },
              },
            },
          ];

          return {
            markers,
            linestrings,
          };
        });

      const { markers, linestrings } = await request(dataset);

      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: false },
          bubbles: true,
          composed: true,
        }),
      );

      this.$.querySelector('#report-body-map').setTimelineDataset({
        markers,
        linestrings,
      });
    } else {
      this.$.querySelector('#report-body-map').setTimelineDataset(
        dataset.reduce(
          (acc, ele, index, arr) => {
            if (index == 0)
              return Object.assign(acc, {
                markers: acc.markers.concat({
                  ...ele,
                  first: true,
                  last: false,
                  markerIcon: 'RouteIcon',
                }),
              });
            return Object.assign(acc, {
              markers: acc.markers.concat({
                ...ele,
                first: false,
                last: index == arr.length - 1,
                markerIcon: 'RouteIcon',
              }),
              linestrings: acc.linestrings.concat({
                geoJson: {
                  type: 'LineString',
                  coordinates: [
                    [
                      this._formatLatLng(arr[index - 1].longitude),
                      this._formatLatLng(arr[index - 1].latitude),
                    ],
                    [this._formatLatLng(ele.longitude), this._formatLatLng(ele.latitude)],
                  ],
                  properties: { dataHora: ele.dataHora },
                },
              }),
            });
          },
          { markers: [], linestrings: [] },
        ),
      );
    }
  }

  _createTimelineSlider(maxRange) {
    if (maxRange > 0) {
      this.timelineSlider = noUiSlider.create(this.$.querySelector('.timeline-slider'), {
        start: maxRange,
        connect: [true, false],
        range: { min: 0, max: maxRange },
        step: 0,
        behaviour: 'drag',
        tooltips: {
          from: value => parseInt(value, 10),
          to: value => {
            const index = value.toFixed(0);
            const lastIndex = this.reportDataset.length - 1;
            const data = this.reportDataset[index > lastIndex ? lastIndex : index];
            // Note: the Math.abs converts from negative to positive
            if (data) {
              const kmDiff =
                this.reportDataset.length > 0
                  ? Math.abs(this.reportDataset[0].odometro - data.odometro)
                  : 0;
              const dateDiff = data.dataHora - this.reportDataset[0].dataHora;
              const dateDiffHour = `${dateDiff / 3600000}`.split('.')[0];
              const dateDiffMinutes = `${dateDiff / 60000 - dateDiffHour * 60}`.split('.')[0];
              return `
                <div>
                  <b>Data Hora:</b>
                  <br>
                  <span>${new Date(data.dataHora).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })}</span>
                </div>
                <div>
                  <b>Tempo Percorrido:</b>
                  <br>
                  <span>${dateDiffHour < 10 ? 0 : ''}${dateDiffHour}:${
                dateDiffMinutes < 10 ? 0 : ''
              }${dateDiffMinutes}</span>
                </div>
                <div>
                  <b>Distância Percorrida:</b>
                  <br>
                  <span>${kmDiff.toFixed(2)} Km</span>
                </div>`;
            }
            return '';
          },
        },
      });
      this.timelineSlider.on('update', values =>
        this.$.querySelector('#report-body-map').updateTimelinePosition({
          pathPosition: parseInt(values[0], 10),
        }),
      );
    }
  }

  _updateTimelineSlider(value, maxRange) {
    if (maxRange) this.timelineSlider.updateOptions({ range: { min: 0, max: maxRange } });
    this.timelineSlider.set(value);
  }

  async _hasServiceMatchRouteActive(plate) {
    return this.reportServices.genericRequest('/TelematicsReport/ServiceMatchRouteIsActive', {
      request: {
        plate,
      },
    });
  }

  _eventPopupAddonMatchRouteSwitch() {
    this.stateConfig.serviceName = this.stateConfig.serviceName || 'ROTA_PRECISA';
    this.stateConfig.serviceId = 14; // Receber esse valor do back-end
    this.$.querySelector('#popup-addon-match-route').toggle();
  }

  _getSelectedTab() {
    return this.tabs.filter(item => item.selected)[0].name;
  }

  _selectTab(tab) {
    this.tabs.forEach(item => {
      item.selected = tab.name === item.name;
    });
  }

  _selectFeature(feature) {
    this.featureOptions.forEach(item => {
      item.selected = feature.name === item.name;
    });
    this.selectedFeature = this._getSelectedFeature();
  }

  _getSelectedFeature() {
    return this.featureOptions.filter(item => item.selected)[0];
  }

  /* Observers */
  __reportDatasetChanged(newValue) {
    if (newValue) {
      this.legendList = this.stateConfig.gridConfig.gridHeaders.reduce(
        (acc, ele) => (ele.icons ? acc.concat(ele) : acc),
        [],
      );

      this._selectMapMode(Object.assign(this._getSelectedMapMode(), { selected: false }));

      if (this.isTrip) {
        this.$.querySelector('#report-body-photos').getDataset(newValue);
        this.$.querySelector('#report-body-indexation').getDataset(newValue);
      }

      this.chartDataset = newValue.map(ele => ({
        x: ele.dataHora,
        y: ele.velocidade,
        tooltipDataset: {
          ignicao: ele.ignicao,
          dataHora: ele.dataHora,
          velocidade: ele.velocidade,
          placaComApelido: ele.placaComApelido,
        },
      }));
    }
  }

  __onRequestSyncVisualization(evt) {
    super.__onRequestSyncVisualization(evt);

    const gridElement = this.$.querySelector('#report-body-grid');
    const activeRow = gridElement.getActiveRow();

    this._renderStepMode(activeRow._index);
  }
  /* */
}

class PowerReportPositions {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = PowerReportPositionsController;
  }
}

angular
  .module('power-report-positions', [
    'ngRedux',
    'ng-tippy',
    'power-dropdown',
    'power-toolbar',
    'power-grid-positions',
    'power-map-positions',
    'power-photo-indexation',
    'power-media-events',
    'power-footer',
    'power-pagination',
    'power-chart-position',
    'power-toast',
    'ng-resize',
  ])
  .component('powerReportPositions', new PowerReportPositions());

export { PowerReportPositionsController };
