import lzjs from 'lzjs';
import { stringify, parse } from 'zipson';

function memorySize(string) {
  return new Blob([string]).size / 1000000;
}

function compressState(obj) {
  const raw = JSON.stringify(obj);
  if (memorySize(raw) <= 4) return { result: raw, level: 'raw' };

  const withZipson = stringify(obj);
  if (memorySize(withZipson) <= 4) return { result: withZipson, level: 'simple' };

  const compound = lzjs.compress(withZipson);
  if (memorySize(compound) <= 4) return { result: compound, level: 'compound' };

  const withLzjs = lzjs.compress(raw);
  return { result: withLzjs, level: 'complex' };
}

function decompressState(str, level) {
  switch (level) {
    case 'simple':
      return parse(str);
    case 'compound':
      return parse(lzjs.decompress(str));
    case 'complex':
      return JSON.parse(lzjs.decompress(str));
    default:
      return JSON.parse(str);
  }
}

export { compressState, decompressState };
