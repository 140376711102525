/** @typedef {import('@power/power-components/components/power-popup-users/power-popup-users').User} User */

import angular from 'angular';

import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-popup-users/power-popup-users';
import '@power/power-components/components/power-toast/power-toast';

import template from './golfleet-popup-report-share.html';
import './golfleet-popup-report-share.scss';

export class GolfleetPopupReportShareController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$state',
      '$rootScope',
      '$scope',
      '$http',
      'urlApi',
      'reportServices',
    ];
  }

  constructor($element, $ngRedux, $state, $rootScope, $scope, $http, urlApi, reportServices) {
    Object.assign(this, {
      $: $element[0],
      $ngRedux,
      $state,
      $rootScope,
      $scope,
      $http,
      urlApi,
      reportServices,
    });

    this.__appBehavior = $ngRedux.connect(behavior => {
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];
      const { userId, userEmail, userName, adminNavigationList, navigationList } = behavior.session;

      return Object({
        sessionState: { userId, userEmail, userName, adminNavigationList, navigationList },
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
      });
    })(this);

    this.model = this._createModel();
    this.permissions = [
      { canEdit: true, description: 'Edição', icon: 'edit' },
      { canEdit: false, description: 'Visualização', icon: 'visibility' },
    ];

    this.popupUsersController = {
      toggle: async () => {
        this.$.querySelector('#golfleet-popup-report-share-users').toggle();
      },

      /* Callbacks */
      addSelectedCallback: selectedItems => {
        selectedItems.forEach(item => {
          if (!this.model.sharedUsers.find(user => user.id === item.id)) {
            const { icon, id, name, email } = item;
            this.model.sharedUsers.push({ icon, id, name, email, canEdit: false, disabled: false });
            this.model.removedUsers = this.model.removedUsers.filter(u => u.id !== item.id);
          }
        });
      },
    };
  }

  // #region /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
      loadData: this.loadData.bind(this),
    });
  }

  $onDestroy() {
    this.__appBehavior();
  }
  // #endregion

  // #region /* Public */

  async loadData(ids) {
    this._dispatchEvent('loadData', { ids });

    this.model = this._createModel();
    const data = await this._getUserReportConfiguration(ids);

    const reportConfigs = data.reduce((acc, config) => {
      if (config) {
        const currentUser = config.sharedUsers.find(
          user => user.userId === this.sessionState.userId,
        );

        if (currentUser && currentUser.canEdit) {
          return acc.concat(config);
        }
      }
      return acc;
    }, []);

    if (reportConfigs.length > 0) {
      reportConfigs.forEach(config => {
        this.model.reports.push(config._id);

        config.sharedUsers.forEach(sharedUser => {
          const item = this.model.sharedUsers.find(i => i.id === sharedUser.userId);

          if (item) {
            item.reportsCount += 1;
          } else {
            this.model.sharedUsers.push({
              icon: 'person',
              id: sharedUser.userId,
              name: sharedUser.userEmail,
              email: sharedUser.userEmail,
              canEdit: sharedUser.canEdit,
              disabled: this.sessionState.userId === sharedUser.userId,
              reportsCount: 1,
            });
          }
        });
      });

      this.model.sharedUsers.forEach(user => {
        if (user.reportsCount !== this.model.reports.length) {
          user.canEdit = undefined;
        } else {
          user.canEdit = reportConfigs.reduce((result, config) => {
            if (result === undefined) return result;

            const item = config.sharedUsers.find(u => u.userId === user.id);
            return result === item.canEdit ? result : undefined;
          }, user.canEdit);
        }
      });

      const popupUsersElement = this.$.querySelector('#golfleet-popup-report-share-users');
      const users = await popupUsersElement.requestData();

      this.model.sharedUsers.forEach(sharedUser => {
        const user = users.find(u => u.id === sharedUser.id);
        if (user) {
          sharedUser.name = user.name;
        }
      });

      const currentUser = this.model.sharedUsers.find(item => item.id === this.sessionState.userId);
      this.model.sharedUsers = this.model.sharedUsers
        .filter(item => item.id !== this.sessionState.userId)
        .sort((a, b) => (a.name > b.name ? 1 : -1));

      if (currentUser) {
        this.model.sharedUsers = [currentUser, ...this.model.sharedUsers];
      }
    }

    this._dispatchEvent('loadedData', this.model);
    return this.model;
  }

  async save() {
    this._dispatchEvent('save', null);
    this._showLoader(true);

    const { reports, sharedUsers, removedUsers } = this.model;
    const users = sharedUsers.filter(u => u.canEdit !== undefined);

    const exportUserData = user => ({
      userId: user.id,
      userEmail: user.email,
    });

    const payload = {
      reports: [...reports],
      editors: [...users.filter(u => u.canEdit).map(exportUserData)],
      viewers: [...users.filter(u => !u.canEdit).map(exportUserData)],
      excluded: [...removedUsers.map(exportUserData)],
    };

    const response = await this.reportServices.upsertUserReportShares(payload);

    if (response.status && response.status !== 200) {
      Object.assign(this, { _toastText: 'Ocorreu um erro inesperado.' });
    } else {
      Object.assign(this, { _toastText: 'As alterações foram realizadas com sucesso!' });
      this.toggle();
    }

    if (this.$scope.$$phase === null && this.$rootScope.$$phase === null) {
      this.$scope.$apply();
    }

    this._showLoader(false);
    this.$.querySelector('power-toast#golfleet-popup-report-share-toast').toggle(true);

    this._dispatchEvent('saved', payload);
  }

  toggle() {
    this.$.querySelector('#golfleet-popup-report-share').toggle();
  }
  // #endregion

  // #region /* Private */
  _getModalInfo() {
    const reportsCount = this.model.reports.length;
    const isMultiSelect = reportsCount !== 1;

    return `${reportsCount} relatório${isMultiSelect ? 's' : ''} em edição`;
  }

  _createModel() {
    return {
      reports: [],
      sharedUsers: [],
      removedUsers: [],
    };
  }

  _showLoader(showLoader) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader },
        bubbles: true,
        composed: true,
      }),
    );
  }

  _changeUserPermission(user, canEdit) {
    if (!user.disabled) {
      user.canEdit = canEdit;
    }
  }

  _removeSharedUser(user) {
    if (!user.disabled) {
      this.model.removedUsers.push(user);
      this.model.sharedUsers = this.model.sharedUsers.filter(u => u.id !== user.id);
    }
  }

  async _getUserReportConfiguration(ids) {
    const getConfiguration = async _id =>
      new Promise((resolve, reject) =>
        this.reportServices.getUserReportConfiguration({ _id }).then(response => {
          if (response.status === 200) {
            const { data } = response.data;
            resolve(data);
          } else {
            reject();
          }
        }),
      );

    const promises = [];
    ids.forEach(id => {
      promises.push(getConfiguration(id));
    });

    return Promise.all(promises);
  }

  _dispatchEvent(name, detail) {
    this.$.dispatchEvent(new CustomEvent(name, detail));
  }
  // #endregion
}

export class GolfleetPopupReportShare {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetPopupReportShareController;
  }
}

angular
  .module('golfleet-popup-report-share', [
    'ngRedux',
    'power-dropdown',
    'power-popup',
    'power-popup-users',
    'power-toast',
  ])
  .component('golfleetPopupReportShare', new GolfleetPopupReportShare());
