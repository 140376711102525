/* global H: true isIframe: true */
import { PowerMapHereProvider } from '@power/power-components/components/power-map/providers/here/power-map.here-provider';
import '@power/power-components/helpers/is-iframe/is-iframe';
import './golfleet-map-suspected-accident.here-provider.scss';

class GolfleetMapSuspectedAccidentHereProvider extends PowerMapHereProvider {
  // eslint-disable-next-line no-useless-constructor
  constructor(context, $element, $ngRedux, $scope, $http, urlApi) {
    super(context, $element, $ngRedux, $scope, $http, urlApi);
  }

  /* Public */
  async renderDataset({
    dataset,
    layerName = 'default',
    type = 'FeatureGroup',
    useCluster,
    clusterColor = '#D60F2C',
    icon,
  }) {
    const markerIcon = icon ? this._createMarkerIcon({ color: clusterColor, icon }) : null;
    const objectList = await this._createDatasetLayer({ type, dataset, markerIcon });

    if (useCluster) {
      this._createCluster({ layerName, clusterColor, objectList, markerIcon });
    } else if (type === 'HeatLayer') {
      this._createHeatmap({ layerName, objectList });
    } else {
      if (layerName in this.mapLayers) {
        this.mapLayers[layerName].removeAll();
      } else {
        this.mapLayers[layerName] = new H.map.Group();
        this.map.addObject(this.mapLayers[layerName]);
      }
      this.mapLayers[layerName].addObjects(objectList);
    }
  }
  /**/

  /* Private */
  async _createDatasetLayer({ type, dataset, markerIcon }) {
    let circleDataset = [];
    let geoJsonDataset = [];
    let datasetLayer = [];

    switch (type) {
      case 'Cluster':
        geoJsonDataset = await this.workerServices.parseToGeoJsonDataset({ dataset });
        datasetLayer = geoJsonDataset;
        break;
      case 'HeatLayer':
        datasetLayer = this._geoJsonToHeat({
          geoJsonList: await this.workerServices.parseToGeoJsonDataset({ dataset }),
        });
        break;
      case 'MarkerFeatureGroup':
        geoJsonDataset = await this.workerServices.parseToGeoJsonDataset({ dataset });
        datasetLayer = geoJsonDataset.map(data =>
          this._geoJsonToMarker({ geoJson: data, markerIcon }),
        );
        break;
      case 'CircleFeatureGroup':
        circleDataset = await this.workerServices.parseToCircleDataset({ dataset });
        datasetLayer = circleDataset.map(data => this._geoJsonToCircle({ geoJson: data }));
        break;
      case 'PolygonFeatureGroup':
      case 'LinestringFeatureGroup':
        geoJsonDataset = await this.workerServices.parseToGeoJsonDataset({ dataset });
        datasetLayer = geoJsonDataset.map(data => this._geoJsonToPolyline({ geoJson: data }));
        break;
      case 'RectangleFeatureGroup':
        geoJsonDataset = await this.workerServices.parseToGeoJsonDataset({ dataset });
        datasetLayer = geoJsonDataset.map(data => this._geoJsonToRectangle({ geoJson: data }));
        break;
      case 'FeatureGroup':
      default:
        circleDataset = await this.workerServices.parseToCircleDataset({ dataset });
        datasetLayer = circleDataset.map(data =>
          this._geoJsonToLGeoJson({ geoJson: data, markerIcon }),
        );
        break;
    }

    return datasetLayer;
  }

  _createCluster({ layerName, clusterColor, objectList, markerIcon }) {
    const clusterDataPoints = objectList.map(object => {
      const [[lng, lat]] = object.geometry.coordinates;
      return new H.clustering.DataPoint(lat, lng, null, object);
    });

    const getClusterPresentation = cluster => {
      const marker = this._createClusterMarker({ cluster, clusterColor });
      const { lat, lng } = marker.getPosition();

      marker.addEventListener(
        'tap',
        () => {
          const maxZoom = marker.getMax();

          this.map.setZoom(maxZoom < 20 ? maxZoom + 1 : 20, true);
          this.map.setCenter({ lat, lng }, true);

          if (this.map.getZoom() === 20) {
            const translateDataPoint = index => {
              const alfa = 0.0001;
              const beta = 0.075 - Math.log(1 + 0.00025 * index);
              const teta = index / (2 + index * 0.02);
              const r = alfa * Math.E ** (beta * teta);
              return { lat: r * Math.sin(teta), lng: r * Math.cos(teta) };
            };
            let count = 0;

            if ('open-cluster' in this.mapLayers) this.mapLayers['open-cluster'].removeAll();
            else {
              this.mapLayers['open-cluster'] = new H.map.Group();
              this.map.addObject(this.mapLayers['open-cluster']);
            }

            this.clusterController.marker = marker;
            this.clusterController.minZoom = 20; // marker.getMin();
            this.clusterController.marker.ea = 20; // 19;

            this.map.addEventListener('tap', this.clusterController.tapEventListener, true);
            this.map.addEventListener(
              'mapviewchange',
              this.clusterController.changeEventListener,
              true,
            );

            cluster.forEachDataPoint(dataPoint => {
              count += 1;
              const { lat: sumLat, lng: sumLng } = translateDataPoint(count);
              const noiseMarker = this._geoJsonToMarker({ geoJson: dataPoint.getData() });
              const clusterLineStrip = new H.geo.Strip();
              clusterLineStrip.pushLatLngAlt(lat, lng);
              clusterLineStrip.pushLatLngAlt(lat + sumLat, lng + sumLng);
              noiseMarker.Ga = 20;
              noiseMarker.setPosition({ lat: lat + sumLat, lng: lng + sumLng });
              this.mapLayers['open-cluster'].addObjects([
                noiseMarker,
                new H.map.Polyline(clusterLineStrip, { min: 20 }),
              ]);
            });
          }
        },
        true,
      );

      return marker;
    };

    const getNoisePresentation = noisePoint => {
      const marker = this._geoJsonToMarker({
        geoJson: noisePoint.getData(),
        markerIcon,
      });
      marker.Ga = noisePoint.getMinZoom();
      return marker;
    };

    if (!(layerName in this.mapLayers)) {
      const clusteredDataProvider = new H.clustering.Provider([], {
        theme: { getClusterPresentation, getNoisePresentation },
        clusteringOptions: { eps: 48, minWeight: 2, strategy: 'FASTGRID' },
      });

      this.mapLayers[layerName] = new H.map.layer.ObjectLayer(clusteredDataProvider);

      this.map.addLayer(this.mapLayers[layerName]);
    }

    this.mapLayers[layerName].getProvider().setDataPoints(clusterDataPoints);
  }

  _createMarkerIcon({ color, icon }) {
    const createIcon = iconString => {
      if (!iconString) return '';
      return `<foreignObject x="8" y="7" width="24" height="24">
        <i class="material-icons" style="color: #fff; cursor: pointer;">
          ${iconString}
        </i>
      </foreignObject>`;
    };

    return new H.map.DomIcon(
      `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" style="margin: -20px 0 0 -20px;;">
        <circle cx="20" cy="20" r="16" fill="${color || 'var(--primary-color)'}"></circle>
        ${createIcon(icon)}
      </svg>`,
    );
  }

  _geoJsonToMarker({ geoJson, markerIcon }) {
    const [[lng, lat]] = geoJson.geometry.coordinates;
    return new H.map.DomMarker(
      { lat, lng },
      {
        icon:
          markerIcon ||
          this._createMarkerIcon({
            ...geoJson.properties,
            icon: geoJson.properties.icon || 'directions_car',
          }),
        data: {
          ...geoJson.properties,
          bubbleContent: `
            <div id="mapPopupHeader">
              <span>${geoJson.properties.placa}</span>
            </div>
            <div id="mapPopupBody">
              <div style="display: ${geoJson.properties.rua ? 'block' : 'none'}">
                <b>Endereço:</b>
                <br>
                <span>${geoJson.properties.rua}</span>
              </div>
              <div style="display: ${geoJson.properties.rua ? 'block' : 'none'}">
                <b>Cidade - Estado:</b>
                <br>
                <span>${geoJson.properties.municipio} - ${geoJson.properties.estado}</span>
              </div>
              <div>
                <b>Data Hora:</b>
                <br>
                <span>${new Date(geoJson.properties.dataHora).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })}</span>
              </div>
              <div>
                <b>Condutor:</b>
                <br>
                <span>${geoJson.properties.condutor || 'Não identificado'}</span>
              </div>
              ${
                isIframe()
                  ? ''
                  : `
                <div style="margin-top:8px;text-align:center">
                  <a class="gs-link"
                    target="_blank"
                    href="${`https://maps.google.com/maps?layer=c&q=${lat},${lng}&cbll=${lat},${lng}&cbp=11,0,0,0,0&z=17&ll=${lat},${lng}`}">
                    Ver no StreetView
                  </a>
                </div>
              `
              }
            </div>
            <div id="mapPopupFooter">
              <span> Lat: ${parseFloat(lat).toFixed(4)} </span>
              <span> Lon: ${parseFloat(lng).toFixed(4)} </span>
            </div>
          `,
        },
      },
    );
  }

  _geoJsonToLGeoJson({ geoJson, markerIcon }) {
    switch (geoJson.geometry.type) {
      case 'Polygon':
        return this._geoJsonToPolygon({ geoJson });
      case 'MultiPolygon':
        return this._geoJsonToMultiPolygon({ geoJson });
      case 'Polyline':
        return this._geoJsonToPolyline({ geoJson });
      case 'LineString':
        return this._geoJsonToPolyline({ geoJson });
      case 'Rectangle':
        return this._geoJsonToRectangle({ geoJson });
      default:
        return geoJson.properties.radius
          ? this._geoJsonToCircle({ geoJson })
          : this._geoJsonToMarker({ geoJson, markerIcon });
    }
  }
  /**/
}

export { GolfleetMapSuspectedAccidentHereProvider };
