import angular from 'angular';
import 'angular-drag-and-drop-lists';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-toolbar/power-toolbar';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-grid/power-grid';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-crud/power-crud';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-popup-share/power-popup-share';
import '@power/power-components/components/power-popup-delete/power-popup-delete';
import '@power/power-components/components/power-toast/power-toast';
import { PowerReportController } from '@power/power-components/components/power-report/power-report';

import template from './golfleet-report-visit.html';
import './golfleet-report-visit.scss';

class GolfleetReportVisitController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    this.formDataManualEntry = new FormData();
    this.dataImportPopupEntryError = {};
  }

  _amountShowingHeaders() {
    return this.stateConfig.gridConfig && this.stateConfig.gridConfig.gridHeaders
      ? this.stateConfig.gridConfig.gridHeaders.filter(ele => ele.show && !ele.isEdit).length
      : 0;
  }
}

class GolfleetReportVisit {
  constructor() {
    this.template = template;
    this.transclude = {
      addActionSlot: '?addActionSlot',
      footerActionSlot: '?footerActionSlot',
    };
    this.bindings = {
      actualView: '=?',
      hasMapView: '=?',
      hasAddAction: '=?',
      hasFooterAction: '=?',
    };
    this.controller = GolfleetReportVisitController;
  }
}

angular
  .module('golfleet-report-visit', [
    'power-fab',
    'power-toolbar',
    'power-dropdown',
    'power-grid',
    'power-footer',
    'power-pagination',
    'power-popup',
    'power-crud',
    'power-popup-share',
    'power-popup-delete',
    'power-toast',
    'dndLists',
  ])
  .component('golfleetReportVisit', new GolfleetReportVisit());
