import angular from 'angular';
import '../../helpers/is-iframe/is-iframe';

import { PowerMapPositionsHereProvider } from './providers/here/power-map-positions.here-provider';
import { PowerMapPositionsLeafletProvider } from './providers/leaflet/power-map-positions.leaflet-provider';
import { PowerMapController } from '../power-map/power-map';

import template from './power-map-positions.html';

class PowerMapPositionsController extends PowerMapController {
  static get $inject() {
    return ['$element', '$ngRedux', '$rootScope', '$scope', '$http', 'urlApi'];
  }

  constructor($element, $ngRedux, $rootScope, $scope, $http, urlApi) {
    super($element, $ngRedux, $scope, $http, urlApi);

    if (this.providerName === 'HERE') {
      this.provider = new PowerMapPositionsHereProvider(
        this,
        $element,
        $ngRedux,
        $rootScope,
        $scope,
        $http,
        urlApi,
      );
    } else {
      // this.providerName === 'LEAFLET'
      this.provider = new PowerMapPositionsLeafletProvider(
        this,
        $element,
        $ngRedux,
        $rootScope,
        $scope,
        $http,
        urlApi,
      );
    }
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      setTimelineDataset: this.setTimelineDataset.bind(this),
      toggleTimelineControl: this.toggleTimelineControl.bind(this),
      updateTimelinePosition: this.updateTimelinePosition.bind(this),
    });

    super.$onInit();
  }

  $onDestroy() {
    clearInterval(this.routeTimelineEvent);
    super.$onDestroy();
  }
  /**/

  /* Public */
  removeLayers(layerNameList = ['default']) {
    this.provider.removeLayers(layerNameList);
  }

  toggleTimelineControl(show) {
    this.provider.toggleTimelineControl(show);
  }

  setTimelineDataset({ markers, linestrings }) {
    this.provider.setTimelineDataset({ markers, linestrings });
  }

  updateTimelinePosition(evtParams) {
    this.provider.updateTimelinePosition(evtParams);
  }
  /**/

  /* Private */
  _toggleRouteTimeline() {
    return this.provider._toggleRouteTimeline();
  }

  _endRouteTimeline() {
    this.provider._endRouteTimeline();
  }

  _renderRouteTimeline() {
    return this.provider._renderRouteTimeline();
  }
  /**/
}

class PowerMapPositions {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = PowerMapPositionsController;
  }
}

angular
  .module('power-map-positions', ['power-map'])
  .component('powerMapPositions', new PowerMapPositions());

export { PowerMapPositionsController };
