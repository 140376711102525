import angular from 'angular';
import { PowerGridController } from '@power/power-components/components/power-grid/power-grid';

import template from './golfleet-grid-workshop.html';
import './golfleet-grid-workshop.scss';

class GolfleetGridWorkshopController extends PowerGridController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$scope',
      '$state',
      '$http',
      '$timeout',
      '$filter',
      'commonServices',
      'urlApi',
      'crudServices',
    ];
  }

  constructor(
    $element,
    $ngRedux,
    $scope,
    $state,
    $http,
    $timeout,
    $filter,
    commonServices,
    urlApi,
    crudServices,
  ) {
    super($element, $scope, $state, $http, $timeout, $filter, commonServices, urlApi, $ngRedux);
    Object.assign(this, {
      $: $element[0],
      $ngRedux,
      $scope,
      $state,
      $http,
      $timeout,
      $filter,
      commonServices,
      urlApi,
      crudServices,
    });

    this.customActionsController = {
      editWorkshop: data => {
        document.querySelector('#report-golfleet-popup-workshop').setDataSet(data);
        document.querySelector('#report-golfleet-popup-workshop').togglePopup();
      },
    };
  }

  _customAction(options) {
    const defaults = { action: '', data: {} };
    const result = Object.assign(defaults, options);

    const customAction = this.customActionsController[result.action];
    if (customAction) {
      customAction(result.data);
    }
  }
}

class GolfleetGridWorkshop {
  constructor() {
    this.template = template;
    this.bindings = {
      /* common */
      datasetMethod: '=?',
      isPaginated: '=?',
      page: '=?',
      pageSize: '=?',
      pageRows: '=?',
      lastPage: '=?',
      datasetLength: '=?',
      hasRowSelection: '=?',
      /* underscore */
      gridHeaders: '=?',
      gridDataset: '=?',
      gridHeadersCategories: '=?',
      sortHeader: '=?',
      sortDirection: '=?',
      selectedRows: '=?',
      /* duble underscore */
      mainHeader: '=?',
      mongoGridId: '=?',
      headerParams: '=?',
      datasetParams: '=?',
    };
    this.controller = GolfleetGridWorkshopController;
  }
}

angular
  .module('golfleet-grid-workshop', [])
  .component('golfleetGridWorkshop', new GolfleetGridWorkshop());
