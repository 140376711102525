import angular from 'angular';
import moment from 'moment';

import './golfleet-dashboard-route.scss';

import '@power/power-components/components/power-toolbar/power-toolbar';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '../golfleet-main-dashboard/golfleet-main-dashboard';
import '@power/power-components/components/power-navigation-date/power-navigation-date';
import '../golfleet-navigation-hierarchy/golfleet-navigation-hierarchy';
import { FilterCondition, PowerFilterDataKey } from '@power/power-components/components/power-filter/power-filter';
import {
  RecordStateConfig,
  ReportStateConfig,
} from '@power/power-components/components/power-state-config/power-state-config';
import template from './golfleet-dashboard-route.html';

class GolfleetDashboardRouteController {
  static get $inject() {
    return [
      '$element',
      '$rootScope',
      '$ngRedux',
      '$state',
      '$scope',
      '$http',
      'urlApi',
      'dashboardServices',
      'filterServices',
    ];
  }

  constructor(
    $element,
    $rootScope,
    $ngRedux,
    $state,
    $scope,
    $http,
    urlApi,
    dashboardServices,
    filterServices,
  ) {
    Object.assign(this, {
      $: $element[0],
      $rootScope,
      $ngRedux,
      $state,
      $scope,
      $http,
      urlApi,
      dashboardServices,
      filterServices,
    });

    this.__appBehavior = $ngRedux.connect(behavior => {
      const routeListSize = behavior.state.routeList.length;
      const currentState = behavior.state.routeList[routeListSize - 1];

      return Object({
        /* State Storage */
        lastState: routeListSize > 1 ? behavior.state.routeList[routeListSize - 2] : null,
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
      });
    })(this);

    /* Attribute */
    /* */

    /* Property */
    this.title = '';
    this.navigationDateConfig = {};
    this.navigationHierarchyConfig = [];
    /* */
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      requestDataset: this.requestDataset.bind(this),
    });

    this.$scope.$on('goToLink', this._goToLink.bind(this));

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: false },
        bubbles: true,
        composed: true,
      }),
    );

    this.dashboardServices.getDashboardConfig(this.stateConfig.toolbarName).then(response => {
      if (!this.stateConfig.modules) {
        this.stateConfig.modules = response.modules;
      } else {
        this.stateConfig.modules = response.modules.map((module, index) =>
          index in this.stateConfig.modules
            ? { ...module, ...this.stateConfig.modules[index] }
            : module,
        );
      }
      this.navigationDateConfig = response.navigationDateConfig;
      this.navigationHierarchyConfig = response.navigationHierarchyConfig;

      const filterMenuElement = document
        .querySelector('#app-component')
        .querySelector('power-filter-menu');

      if (!this.stateConfig.filterConfig || this.stateConfig.filterConfig.length === 0) {
        this.filterServices
          .getPowerFilters(this.stateConfig.screenName, this.stateConfig.toolbarName)
          .then(getFiltersResult => {
            if (getFiltersResult) {
              const { drilldownMap } = getFiltersResult;
              const filterConfigList = getFiltersResult.filters;

              this.stateConfig.filterConfig = filterConfigList.map(filterConfig =>
                Object.clone({}, filterConfig),
              );

              if (
                this.lastState &&
                drilldownMap.length > 0 &&
                this.lastState.stateConfig.filterConfig
              ) {
                const lastFilterConfigList = this.lastState.stateConfig.filterConfig;
                this.stateConfig.filterConfig = this.stateConfig.filterConfig.map(actualFilter => {
                  const [drilldown] = drilldownMap.filter(
                    drilldown2 => drilldown2.toId == actualFilter.id,
                  );
                  if (drilldown) {
                    const [previousFilter] = lastFilterConfigList.filter(
                      filter => filter.id == drilldown.fromId,
                    );
                    return {
                      ...actualFilter,
                      condition: !previousFilter ? null : Object.clone(previousFilter.condition),
                    };
                  }
                  return actualFilter;
                });
              }

              if (
                this.userReportConfiguration &&
                this.userReportConfiguration.filterConfig &&
                this.userReportConfiguration.filterConfig.length > 0
              ) {
                const config = this.stateConfig.filterConfig.map(current => {
                  const [filter] = this.userReportConfiguration.filterConfig.filter(
                    userFilterConfig =>
                      PowerFilterDataKey(userFilterConfig) == PowerFilterDataKey(current),
                  );

                  if (filter) {
                    if (filter.type === 'calendar') {
                      if (filter.condition.preset) {
                        filter.condition.value = this._convertCalendarPresetToDates(
                          filter.condition.preset,
                        );
                      }
                    }

                    const { isArray } = Array;
                    const isAdvanced = filter.advanced && current.advanced;
                    const selectorIsArray = isArray(current.selectors) && isArray(filter.selectors);
                    const conditionIsArray = isArray(filter.condition);

                    if (isAdvanced && selectorIsArray) {
                      current.selectors.forEach(item => {
                        item.selected = !!(
                          filter.selectors.find(s => s.selector === item.selector) || {}
                        ).selected;
                      });

                      if (!conditionIsArray) {
                        filter.condition = [filter.condition];
                      }
                    } else if (filter.advanced && !current.advanced && conditionIsArray) {
                      const [condition] = filter.condition;
                      filter.condition = condition;
                    }

                    return Object.assign(current, {
                      activeEntity: filter.activeEntity,
                      activeView: filter.activeView,
                      condition: filter.condition,
                    });
                  }

                  return current;
                });

                this.stateConfig.filterConfig = config;

                this.currentState.userReportId = this.userReportConfiguration._id;
                this.currentState.userReportName = this.userReportConfiguration.reportName;
                this.currentState.userReportCanEdit =
                  !!this.userReportConfiguration.sharedUsers.find(
                    user => user.userId == this.userId && user.canEdit,
                  );
                this.currentState.userReportConfig = JSON.parse(
                  JSON.stringify(this.userReportConfiguration),
                );
                this.currentState.userReportCreatedBy = this.userReportConfiguration.createdBy;
                this.currentState.userReportCreatedAt = this.userReportConfiguration.createdAt;
              }

              if (this.stateConfig.getDataFilters) {
                this.stateConfig.filterConfig = this.stateConfig.filterConfig.map(actualFilter => {
                  const [fixedFilters] = this.stateConfig.getDataFilters.filter(
                    condition => condition.id == actualFilter.id,
                  );

                  return fixedFilters
                    ? {
                        ...actualFilter,
                        condition: new FilterCondition(
                          actualFilter.id,
                          actualFilter.field || actualFilter.filters[fixedFilters.activeView].field,
                          fixedFilters.default,
                        ),
                      }
                    : actualFilter;
                });
              }

              filterMenuElement.validateFilters();
              filterMenuElement.applyFilters();

              this.$.dispatchEvent(
                new CustomEvent('toggleLoader', {
                  detail: { showLoader: false },
                  bubbles: true,
                  composed: true,
                }),
              );
            }
          });
      } else {
        filterMenuElement.validateFilters();
        filterMenuElement.applyFilters();

        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
      }
    });
  }

  $onDestroy() {
    this.__appBehavior();
  }
  /* */

  /* Private */
  _goToLink(evt, evtParams) {
    let getDataFilters = [];
    const getDataFixedParams = {};

    if (evtParams.backPagination === null) {
      evtParams.backPagination = true;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const attr in evtParams.stateFixedParams) {
      if (evtParams.dataset[evtParams.stateFixedParams[attr]]) {
        getDataFixedParams[attr] = evtParams.dataset[evtParams.stateFixedParams[attr]];
      } else if (
        this.stateConfig.getDataFixedParams &&
        this.stateConfig.getDataFixedParams[evtParams.stateFixedParams[attr]]
      ) {
        getDataFixedParams[attr] =
          this.stateConfig.getDataFixedParams[evtParams.stateFixedParams[attr]];
      } else {
        getDataFixedParams[attr] = evtParams.stateFixedParams[attr];
      }
    }

    if (evtParams.fixedFilters)
      getDataFilters = evtParams.fixedFilters.map(filter => {
        const filterDefault = filter.condition.map((condition, index) => {
          const data = evtParams.dataset[condition.field];

          if (filter.type == 'DateTime') {
            let conditionDate = moment(data);

            conditionDate = conditionDate.subtract(condition.value, 'days');

            if (!filter.keepTime) {
              if (index == 0) conditionDate.hour(0).minute(0).second(0).millisecond(0);
              else if (index == 1) conditionDate.hour(23).minute(59).second(59).millisecond(0);
            }

            return conditionDate._d;
          }
          if (condition.value) {
            return data ? data + condition.value : condition.value;
          }

          return data;
        });

        const hasHistoricalData = filter.condition.reduce((acc, condition) => {
          if ('incluedHistoricalData' in condition) {
            if (typeof condition.incluedHistoricalData !== 'boolean') {
              return evtParams.tableRowData[condition.incluedHistoricalData] || acc;
            }
            return condition.incluedHistoricalData || acc;
          }

          return acc;
        }, false);

        return {
          id: filter.id,
          default: filterDefault,
          activeView: filter.activeView,
          incluedHistoricalData: hasHistoricalData,
        };
      });

    switch (evtParams.routeLink) {
      case 'record':
      case 'recordVehicle': {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );

        const stateConfig = new RecordStateConfig({
          isAdm: this.onAdmNavigationMode,
          getDataMethod: evtParams.getDataMethod,
          getDataFixedParams,
        });

        // eslint-disable-next-line guard-for-in, no-restricted-syntax
        for (const attr in evtParams.linkStateConfig) {
          stateConfig[attr] = evtParams.linkStateConfig[attr];
        }

        this.recordServices.getRecordConfig(stateConfig.type).then(
          result => {
            // eslint-disable-next-line guard-for-in, no-restricted-syntax
            for (const attr in result) {
              stateConfig[attr] = result[attr];
            }

            this.$ngRedux.dispatch({
              type: 'NEXT_ROUTE',
              data: {
                routeName: evtParams.dataset[evtParams.routeName] || evtParams.routeName,
                routeLink: evtParams.routeLink,
                routeSubName: evtParams.linkStateConfig?.routeSubName,
                routeTail: getDataFixedParams.id,
                stateConfig,
              },
            });

            this.$state.go(evtParams.routeLink, {
              tail: getDataFixedParams.id,
            });
          },
          () => {
            this.$.dispatchEvent(
              new CustomEvent('toggleLoader', {
                detail: { showLoader: false },
                bubbles: true,
                composed: true,
              }),
            );
          },
        );

        return;
      }
      case 'report': {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );

        const stateConfig = new ReportStateConfig({
          isAdm: this.onAdmNavigationMode,
          viewMode: 'grid',
          getDataMethod: evtParams.getDataMethod,
          backPagination: evtParams.backPagination,
          getDataFilters,
          getDataFixedParams,
        });

        // eslint-disable-next-line guard-for-in, no-restricted-syntax
        for (const attr in evtParams.linkStateConfig) {
          stateConfig[attr] = evtParams.linkStateConfig[attr];
        }

        this.$ngRedux.dispatch({
          type: 'NEXT_ROUTE',
          data: {
            routeName: evtParams.dataset[evtParams.routeName] || evtParams.routeName,
            routeLink: evtParams.routeLink,
            routeSubName: evtParams.linkStateConfig?.routeSubName,
            routeTail: getDataFixedParams.id,
            stateConfig,
          },
        });

        this.$state.go(evtParams.routeLink, { tail: getDataFixedParams.id });

        return;
      }
      case 'streetView': {
        if (getDataFixedParams.paramsStreetView) {
          // 0. latitude, 1. longitude, 2. heading, 3. pitch, 4. zoom, 5. panoid
          const paramsStreetView = getDataFixedParams.paramsStreetView.split('|');
          window.open(
            'https://maps.google.com/maps?layer=c&q=' +
              `&cbll=${paramsStreetView[0]},${paramsStreetView[1]}` +
              `&cbp=11,${paramsStreetView[2]},0,` +
              `${paramsStreetView[4]},${paramsStreetView[3]}` +
              `&panoid=${paramsStreetView[5]}`,
            '_blank',
          );
        } else
          window.open(
            'https://maps.google.com/maps?layer=c' +
              `&q=${getDataFixedParams.latitude},${getDataFixedParams.longitude}` +
              `&cbll=${getDataFixedParams.latitude},${getDataFixedParams.longitude}` +
              '&cbp=11,0,0,0,0&z=17' +
              `&ll=${getDataFixedParams.latitude},${getDataFixedParams.longitude}`,
            '_blank',
          );

        return;
      }
      case 'form': {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );

        this.$ngRedux.dispatch({
          type: 'NEXT_ROUTE',
          data: {
            routeName: evtParams.routeName, // evtParams.dataset[evtParams.routeName] || evtParams.routeName,
            routeLink: evtParams.linkStateConfig.type, // evtParams.routeLink,
            routeSubName: evtParams.linkStateConfig?.routeSubName,
            routeTail: getDataFixedParams.id,
            stateConfig: {
              ...this.stateConfig,
              getDataMethod: evtParams.getDataMethod,
              gridName: 'Formulário',
              filterConfig: [],
            },
          },
        });

        this.$state.go(evtParams.linkStateConfig.type, {
          tail: getDataFixedParams.id,
        });

        return;
      }
      default: {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );

        const stateConfig = new ReportStateConfig({
          isAdm: this.onAdmNavigationMode,
          viewMode: 'grid',
          getDataMethod: evtParams.getDataMethod,
          backPagination: evtParams.backPagination,
          getDataFilters,
          getDataFixedParams,
        });

        // eslint-disable-next-line guard-for-in, no-restricted-syntax
        for (const attr in evtParams.linkStateConfig) {
          stateConfig[attr] = evtParams.linkStateConfig[attr];
        }

        this.$ngRedux.dispatch({
          type: 'NEXT_ROUTE',
          data: {
            routeName: evtParams.dataset[evtParams.routeName] || evtParams.routeName,
            routeLink: evtParams.routeLink,
            routeSubName: evtParams.linkStateConfig?.routeSubName,
            routeTail: getDataFixedParams.id,
            stateConfig,
          },
        });

        this.$state.go(evtParams.routeLink, { tail: getDataFixedParams.id });
      }
    }
  }

  _updateRoute() {
    this._requestUpdate();
    this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });
  }

  _requestUpdate() {
    if (!this.$scope.$$phase && !this.$rootScope.$$phase) {
      this.$scope.$apply();
    }
  }
  /* */

  /* Public */
  async requestDataset() {
    const [calendarFilter] = this.stateConfig.filterConfig.filter(
      filter => filter.field === 'date',
    );

    const [calendarStartDate, calendarEndDate] = calendarFilter.condition.value;
    const stateNavigationDate = this.stateConfig.navigation.date;

    if (
      stateNavigationDate.length === 0 ||
      !moment(calendarEndDate).isSame(stateNavigationDate[0].endDate) ||
      !moment(calendarStartDate).isSame(stateNavigationDate[0].startDate)
    ) {
      const path = [];

      path.push({
        endDate: calendarFilter.condition.value[1],
        startDate: calendarFilter.condition.value[0],
        granularity: 'mes',
      });

      this.stateConfig.navigation.date = path;
    }

    await this.$.querySelector('power-navigation-date').setup({
      path: this.stateConfig.navigation.date,
    });

    await Promise.all(
      this.navigationHierarchyConfig.map(async hierarchyConfig => {
        const { field } = hierarchyConfig;
        const hierarchyComponent = this.$.querySelector(
          `golfleet-navigation-hierarchy[field="${field}"]`,
        );

        if (field in this.stateConfig.navigation.hierarchy) {
          await hierarchyComponent.setup({ path: this.stateConfig.navigation.hierarchy[field] });

          return;
        }

        await hierarchyComponent.setup({ path: [] });

        this.stateConfig.navigation.hierarchy[field] = hierarchyComponent.getPath();
      }),
    );

    const datePath = this.stateConfig.navigation.date;

    const navigationDate = datePath[datePath.length - 1];
    const navigationHierarchy = {};

    Object.keys(this.stateConfig.navigation.hierarchy).forEach(hierarchyKey => {
      const hierarchyPath = this.stateConfig.navigation.hierarchy[hierarchyKey];

      navigationHierarchy[hierarchyKey] = hierarchyPath[hierarchyPath.length - 1];
    });

    await this.$.querySelector('golfleet-main-dashboard').setup({
      navigationDate,
      navigationHierarchy,
    });
  }
  /* */

  /* Observers */
  async __navigationDateDrillDown(evt) {
    const { date } = evt.detail;
    const dateComponent = this.$.querySelector('power-navigation-date');

    await dateComponent.setDrillDown({ date });

    this.stateConfig.navigation.date = dateComponent.getPath();

    this._updateRoute();
  }

  async __updateDashboardNavigationDate() {
    const datePath = this.$.querySelector('power-navigation-date').getPath();
    const navigationDate = datePath[datePath.length - 1];

    this.stateConfig.navigation.date = datePath;

    await this.$.querySelector('golfleet-main-dashboard').updateNavigationDate({ navigationDate });

    this._updateRoute();
  }

  async __navigationHierarchyDrillDown(evt) {
    const { field, hierarchy } = evt.detail;
    const hierarchyComponent = this.$.querySelector(
      `golfleet-navigation-hierarchy[field="${field}"]`,
    );

    await hierarchyComponent.setDrillDown({ hierarchy });

    this.stateConfig.navigation.hierarchy[field] = hierarchyComponent.getPath();

    this._updateRoute();
  }

  async __updateDashboardNavigationHierarchy(field) {
    const hierarchyPath = this.$.querySelector(
      `golfleet-navigation-hierarchy[field="${field}"]`,
    ).getPath();
    const navigationHierarchy = hierarchyPath[hierarchyPath.length - 1];

    this.stateConfig.navigation.hierarchy[field] = hierarchyPath;

    await this.$.querySelector('golfleet-main-dashboard').updateNavigationHierarchy({
      field,
      navigationHierarchy,
    });

    this._updateRoute();
  }
  /* */
}

class GolfleetDashboardRoute {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetDashboardRouteController;
  }
}

angular
  .module('golfleet-dashboard-route', [
    'power-toolbar',
    'power-dropdown',
    'golfleet-main-dashboard',
    'power-navigation-date',
    'golfleet-navigation-hierarchy',
  ])
  .component('golfleetDashboardRoute', new GolfleetDashboardRoute());
