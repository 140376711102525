import angular from 'angular';
import '../../helpers/is-iframe/is-iframe';

import { PowerMapRankingDetailsSpeedLimitHereProvider } from './providers/here/power-map-ranking-details-speed-limit.here-provider';
import { PowerMapRankingDetailsSpeedLimitLeafletProvider } from './providers/leaflet/power-map-ranking-details-speed-limit.leaflet-provider';
import { PowerMapController } from '../power-map/power-map';

import template from './power-map-ranking-details-speed-limit.html';

class PowerMapRankingDetailsSpeedLimitController extends PowerMapController {
  static get $inject() {
    return ['$element', '$ngRedux', '$scope', '$http', 'urlApi'];
  }

  constructor($element, $ngRedux, $scope, $http, urlApi) {
    super($element, $ngRedux, $scope, $http, urlApi);

    if (this.providerName === 'HERE') {
      this.provider = new PowerMapRankingDetailsSpeedLimitHereProvider(
        this,
        $element,
        $ngRedux,
        $scope,
        $http,
        urlApi,
      );
    } else {
      // this.providerName === 'LEAFLET'
      this.provider = new PowerMapRankingDetailsSpeedLimitLeafletProvider(
        this,
        $element,
        $ngRedux,
        $scope,
        $http,
        urlApi,
      );
    }
  }
}

class PowerMapRankingDetailsSpeedLimit {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = PowerMapRankingDetailsSpeedLimitController;
  }
}

angular
  .module('power-map-ranking-details-speed-limit', [])
  .component('powerMapRankingDetailsSpeedLimit', new PowerMapRankingDetailsSpeedLimit());
