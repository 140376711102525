import angular from 'angular';

import '@power/power-components/components/power-popup-list/power-popup-list';
import '@power/power-components/directives/ng-resize/ng-resize';
import { PowerGridController } from '@power/power-components/components/power-grid/power-grid';

import template from './golfleet-grid-multi-entities.html';
import './golfleet-grid-multi-entities.scss';

class GolfleetGridMultiEntitiesController extends PowerGridController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$scope',
      '$state',
      '$http',
      '$timeout',
      '$filter',
      'commonServices',
      'urlApi',
    ];
  }

  constructor(
    $element,
    $ngRedux,
    $scope,
    $state,
    $http,
    $timeout,
    $filter,
    commonServices,
    urlApi,
  ) {
    super($element, $scope, $state, $http, $timeout, $filter, commonServices, urlApi, $ngRedux);
    Object.assign(this, { $ngRedux });

    this.__appBehavior = $ngRedux.connect(behavior => {
      const routeListSize = behavior.state.routeList.length;
      const currentState = behavior.state.routeList[routeListSize - 1];

      return Object({
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
      });
    })(this);
  }

  /* Lifecycle */
  $onDestroy() {
    super.$onDestroy();

    this.__appBehavior();
  }
  /**/

  getDataset(_, payload) {
    let resetDataset = false;

    if (!payload || this.page >= payload.page) {
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );
    }

    this.$scope.$emit('finishFilterConditions', {});

    if (payload) {
      if (!payload.keepDataset) {
        resetDataset = true;
        this.$.querySelector('.table-container').scrollTo(0, 0);
      }

      if (payload?.async) {
        Object.assign(this, {
          isPaginated: payload.isPaginated,
          datasetParams: { ...this.datasetParams, ...payload },
        });
      } else {
        Object.assign(this, {
          page: payload.page >= 0 ? payload.page : this.page,
          isPaginated: payload.isPaginated,
          datasetParams: { ...this.datasetParams, ...payload },
        });
      }

      if (payload.sort) {
        const { name, direction } = payload.sort;

        this.sortHeader = name;
        this.sortDirection = direction;

        this.gridHeaders = this.gridHeaders.map(gridHeader => {
          if (
            (gridHeader.columns &&
              gridHeader.columns.some(
                column => column.sortable === name || column.field === name,
              )) ||
            gridHeader.sortable === name ||
            gridHeader.field === name
          ) {
            gridHeader.show = true;

            if (gridHeader.columns && gridHeader.selectors) {
              const column = gridHeader.columns.find(
                item => item.sortable === name || item.field === name,
              );
              gridHeader.selectors.forEach(item => {
                item.selected = item.selector === column.selector;
              });
            }
          }

          return gridHeader;
        });
      }

      if (payload.visibleColumns) {
        const { visibleColumns } = payload;

        this.gridHeaders = this.gridHeaders.map(gridHeader => {
          if (
            (gridHeader.columns &&
              gridHeader.columns.some(column => visibleColumns.includes(column.field))) ||
            visibleColumns.includes(gridHeader.field)
          ) {
            gridHeader.show = true;

            if (gridHeader.columns && gridHeader.selectors) {
              const column = gridHeader.columns.find(item => visibleColumns.includes(item.field));
              if (gridHeader.selectors) {
                gridHeader.selectors.forEach(item => {
                  item.selected = item.selector === column.selector;
                });
              }
            }
          }

          return gridHeader;
        });
      }
    }

    return this.$http({
      url: `${this.urlApi}/${this.datasetMethod}`,
      method: 'POST',
      data: {
        request: {
          ...this.datasetParams,
          page: payload?.page - 1 || this.page - 1,
          length: this.pageSize,
          sort: {
            name: this.sortHeader,
            direction: this.sortDirection,
          },
        },
      },
    })
      .then(
        async success => {
          if (success.status && success.status === 200) {
            const columns = await this.workerService.columns;
            const response = success.data.data;

            if (columns.length === 0) {
              await this.workerService.setColumns({ columns: this.gridHeaders });
            }

            this.datasetLength = response.total;
            this.lastPage = this._calcGridMaxPage(response.total, this.pageSize);
            this.page = (this.page > this.lastPage ? 1 : this.page) || 1;

            await this.workerService.setPageSize({ pageSize: this.pageSize });
            await this.workerService.setPage({ page: this.page });

            if (this.datasetLength === 0) {
              this.virtualRows = [];
            }

            if (this.isPaginated) {
              await this.workerService.addToDataset({
                page: payload?.page - 1 || this.page - 1,
                rows: response.data,
                rowsLength: response.total,
                isPaginated: this.isPaginated,
                resetDataset,
                useVirtualScroll: true,
              });
            } else {
              await this.workerService.setDataset({
                rows: response.data,
                rowsLength: response.total,
                isPaginated: this.isPaginated,
              });
            }

            this.page = await this.workerService.page;
            this.pageSize = await this.workerService.pageSize;
            this.lastPage = await this.workerService.lastPage;
            this.pageRows = await this.workerService.pageRows;
            this.activeRow = await this.workerService.activeRow;
            this.gridDataset = await this.workerService.rows;
            this.selectedRows = await this.workerService.selectedRows;
            this.toggleAllIcon = await this.workerService.toggleAllIcon;
            this.isAllSelected = await this.workerService.isAllSelected;

            if (this._getCompoundCustomFilter()) {
              this.getHeaders();
            }

            if (!this.$scope.$$phase) {
              this.$scope.$apply();
            }

            this.$scope.$emit('loadDatasetComplete');
          }
        },
        async () => {
          await this.workerService.setPageSize({ pageSize: this.pageSize });
          await this.workerService.setPage({ page: 1 });
          await this.workerService.setDataset({ rows: [], isPaginated: this.isPaginated });

          this.page = await this.workerService.page;
          this.pageSize = await this.workerService.pageSize;
          this.lastPage = await this.workerService.lastPage;
          this.pageRows = await this.workerService.pageRows;
          this.gridDataset = await this.workerService.rows;
          this.selectedRows = await this.workerService.selectedRows;
          this.toggleAllIcon = await this.workerService.toggleAllIcon;
          this.isAllSelected = await this.workerService.isAllSelected;

          if (!this.$scope.$$phase) {
            this.$scope.$apply();
          }
        },
      )
      .finally(() => {
        this.$timeout(() => this.$scope.$emit('UPDATE_ROUTE'));

        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
      });
  }

  getHeaders(_, payload) {
    if (payload) this.headerParams = { ...payload };

    const { screenName, gridName } = this.headerParams;
    const { userGridConfig = { gridHeaders: [] } } = this.headerParams;

    const compoundCustom = this._getCompoundCustomFilter();
    const entityName = this._getCompoundCustomEntityName(compoundCustom);

    return this.commonServices
      .getGridHeaders({ screenName, entityName, gridName })
      .then(
        async data => {
          function getHeaders() {
            if (userGridConfig.gridHeaders.length > 0) {
              const headers = userGridConfig.gridHeaders.reduce((acc, gridHeader, index) => {
                const header = data.columns.find(column => column.field === gridHeader.field);

                if (header) {
                  const { show, selectors } = gridHeader;

                  if (Array.isArray(header.selectors) && Array.isArray(selectors)) {
                    header.selectors.forEach(item => {
                      item.selected = !!(selectors.find(s => s.selector === item.selector) || {})
                        .selected;
                    });
                  }

                  return acc.concat({ ...header, show, id: index });
                }

                return acc;
              }, []);

              const extraHeaders = data.columns.filter(
                column =>
                  !userGridConfig.gridHeaders.find(gridHeader => column.field === gridHeader.field),
              );

              return [
                ...headers,
                ...extraHeaders.map((column, index) => ({
                  ...column,
                  show: false,
                  id: headers.length + index,
                })),
              ];
            }

            return [...data.columns.map((column, index) => ({ ...column, id: index }))];
          }

          const headers = getHeaders();

          await this.workerService.setColumns({ columns: headers });

          if (this.mongoGridId !== data._id) {
            Object.assign(this, {
              page: this.headerParams.page || 1,
              pageSize: userGridConfig.pageSize || this.headerParams.pageSize || 10,
              mainHeader: data.mainDescription,
              gridHeadersCategories: data.categories,
              gridHeaders: headers,
              mongoGridId: data._id,
              sortHeader: userGridConfig.sortField || data.sortable,
              sortDirection: userGridConfig.sortDirection || data.sortableOrder,
            });
          }
        },
        async () => {
          await this.workerService.setColumns({ columns: [] });

          Object.assign(this, { gridHeaders: [] });
        },
      )
      .catch(async () => {
        await this.workerService.setColumns({ columns: [] });

        Object.assign(this, { gridHeaders: [] });
      })
      .finally(() => this.$timeout(() => this.$scope.$emit('UPDATE_ROUTE')));
  }

  _getCompoundCustomFilter() {
    return this.stateConfig.filterConfig.find(i => i.type === 'compoundCustom');
  }

  _getCompoundCustomEntityName(compoundCustom) {
    let entityName = '';

    if (compoundCustom) {
      if (compoundCustom.activeView === 'entity' && compoundCustom.activeEntity === 'driver') {
        entityName = 'Driver';
      } else {
        entityName = 'Vehicle';
      }
    }

    return entityName;
  }

  _selectRow(column, row) {
    return (super._selectRow(column, row) || !!column.action) && !!row[column.field];
  }
}

class GolfleetGridMultiEntities {
  constructor() {
    this.template = template;
    this.bindings = {
      /* common */
      datasetMethod: '=?',
      isPaginated: '=?',
      page: '=?',
      pageSize: '=?',
      pageRows: '=?',
      lastPage: '=?',
      datasetLength: '=?',
      hasRowSelection: '=?',
      dateGranularity: '=?',
      /* underscore */
      gridHeaders: '=?',
      gridDataset: '=?',
      gridHeadersCategories: '=?',
      sortHeader: '=?',
      sortDirection: '=?',
      selectedRows: '=?',
      /* duble underscore */
      mainHeader: '=?',
      mongoGridId: '=?',
      headerParams: '=?',
      datasetParams: '=?',
    };
    this.controller = GolfleetGridMultiEntitiesController;
  }
}

angular
  .module('golfleet-grid-multi-entities', ['power-popup-list', 'ng-resize'])
  .component('golfleetGridMultiEntities', new GolfleetGridMultiEntities());

export { GolfleetGridMultiEntitiesController, GolfleetGridMultiEntities };
