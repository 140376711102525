import angular from 'angular';

import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-toast/power-toast';
import '../golfleet-support-helper/golfleet-support-helper';

import template from './golfleet-issue-tracker.html';
import './golfleet-issue-tracker.scss';

class GolfleetIssueTrackerController {
  static get $inject() {
    return ['$element', '$scope', '$ngRedux', '$http', 'urlApi'];
  }

  constructor($element, $scope, $ngRedux, $http, urlApi) {
    Object.assign(this, { $: $element[0], $scope, $http, urlApi });

    this.issueTrackerController = {
      fields: [
        {
          field: 'issue-tracker-name',
          label: 'Nome',
          type: 'input',
          value: null,
          size: 100,
          error: null,
        },
        {
          field: 'issue-tracker-email',
          label: 'Email',
          type: 'input',
          value: null,
          size: 100,
          error: null,
        },
        {
          field: 'issue-tracker-title',
          label: 'Assunto',
          type: 'input',
          value: null,
          size: 100,
          error: null,
        },
        {
          field: 'issue-tracker-description',
          label: 'Mensagem',
          type: 'textarea',
          value: null,
          size: 1024,
          error: null,
        },
      ],
    };

    this.__appInheritBehavior = $ngRedux.connect(behavior =>
      Object({ session: behavior.session, state: behavior.state }),
    )(this);
  }

  /**
   * Lifecycle
   */
  $onInit() {
    Object.assign(this.$, {
      openTicket: this.openTicket.bind(this),
      tooglePopup: this.tooglePopup.bind(this),
      actionEventHandler: this.actionEventHandler.bind(this),
    });
  }

  $onDestroy() {
    // TODO: remove event listeners
  }
  /* Lifecycle */

  tooglePopup() {
    setTimeout(() => {
      this.$.querySelector('#golfleet-issue-tracker-popup').toggle();
      this.issueTrackerController.fields.forEach(field => {
        field.value = null;
        field.error = null;
      });
      this.issueTrackerController.fields[0].value = this.session.userName;
      this.issueTrackerController.fields[1].value = this.session.userEmail;
    });
  }

  actionEventHandler(action) {
    if (action == 'cancel') {
      this.tooglePopup();
    } else if (action == 'send') {
      const emptyValues = [];

      this.issueTrackerController.fields.forEach(field => {
        if (!field.value) {
          field.error = true;
          emptyValues.push(field);
        }
      });

      if (emptyValues.length > 0) {
        Object.assign(this, {
          toast: {
            text: 'Algumas informações são obrigatórias e precisam ser preenchidas.',
          },
        });
        this.$.querySelector('#golfleet-issue-tracker-toast').toggle(true);
      } else {
        const getSessionData = session => ({
          userId: session.userId,
          userName: session.userName,
          userEmail: session.userEmail,
          companyName: session.companyName,
          isSingleSignon: session.isSingleSignon,
          // isDriver: session.isDriver,
          isTrip: session.isTrip,
          isAdm: session.isAdm,
        });

        const getStateData = state => ({
          routeList: state.routeList.map(route => ({
            routeName: route.routeName,
            routeLink: route.routeLink,
            routeTail: route.routeTail,
          })),
        });

        const session = getSessionData(this.session);
        const state = getStateData(this.state);

        const data = {
          userName: this.issueTrackerController.fields[0].value,
          userEmail: this.issueTrackerController.fields[1].value,
          userCompany: session.companyName,
          routeName: state.routeList[state.routeList.length - 1].routeName,
          title: this.issueTrackerController.fields[2].value,
          description: this.issueTrackerController.fields[3].value,
          data: JSON.stringify({ session, state }),
        };

        this.$http({
          url: `${this.urlApi}/IssueTracker/Send`,
          method: 'POST',
          data: {
            request: {
              ...data,
            },
          },
        }).then(success => {
          if (success.status && success.status !== 200) {
            Object.assign(this, { toast: { text: success.data.text } });
            this.$.querySelector('#golfleet-issue-tracker-toast').toggle(true);
            return;
          }
          Object.assign(this, {
            toast: { text: 'Feedback enviado com sucesso!' },
          });
          this.$.querySelector('#golfleet-issue-tracker-toast').toggle(true);
          this.tooglePopup();
        });
      }
    }
  }

  openTicket() {
    this.$http({
      url: `${this.urlApi}/DeskManager/HaveAccess`,
      method: 'POST',
      data: {},
    }).then(
      success => {
        if (success.status == 200) {
          const { data } = success.data;

          if (data) {
            this.$.querySelector('golfleet-support-helper').tooglePopup();
          } else {
            this.tooglePopup();
          }
        } else {
          this.tooglePopup();
        }
      },
      () => {
        this.tooglePopup();
      },
    );
  }

  _removeErrorAttr(item) {
    item.error = false;
  }
}

class GolfleetIssueTracker {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetIssueTrackerController;
  }
}

angular
  .module('golfleet-issue-tracker', ['power-popup', 'power-toast', 'golfleet-support-helper'])
  .component('golfleetIssueTracker', new GolfleetIssueTracker());

export { GolfleetIssueTrackerController };
