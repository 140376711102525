import angular from 'angular';

import '../power-dropdown/power-dropdown';

import template from './power-crud-combobox-multi-select.html';
import './power-crud-combobox-multi-select.scss';

class ComboBoxDropdownItem {
  constructor(id, description, selected, value, enable) {
    this.id = id;
    this.description = description;
    this.selected = selected;
    this.value = value;
    this.enable = enable;
  }
}

class PowerCrudComboboxMultiSelectController {
  static get $inject() {
    return ['$element', '$scope', '$attrs', 'crudServices'];
  }

  constructor($element, $scope, $attrs, crudServices) {
    Object.assign(this, { $: $element[0], $scope, $attrs, crudServices });

    this.description = '';
    this.hasSearch = false;
    this.searchItem = '';
    this.$scope.$watch(() => this.value, this._onValueChanged.bind(this));
    this.$scope.$watch(() => this.ngValue, this._onNgValueChanged.bind(this));
    this.$scope.$watch(() => this.$attrs.disabled, this._disabledChanged.bind(this));
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      removeSelectedById: this.removeSelectedById.bind(this),
      updateComboBoxOptions: this.updateComboBoxOptions.bind(this),
    });

    this.value = this.value && this.value.length > 0 ? this.value : [];
    this.disabled = !!this.disabled;

    this.options = this.options || [];

    this.hasSearch = this.hasSearch || false;

    if (this.value.length > 0 && this.options)
      this.options = this.options.map(option => ({
        ...option,
        selected: this.value.includes(option.id),
      }));

    if (this.getDataMethod) this._getComboBoxOptions();
  }
  /* */

  /* Private */
  _getComboBoxOptions() {
    const payload = { ...this.getDataParams };
    if (this.dataset)
      // eslint-disable-next-line guard-for-in, no-restricted-syntax
      for (const attr in payload) {
        if (typeof payload[attr] != 'number') {
          payload[attr] = this.dataset[payload[attr]];
        }
      }

    this.crudServices.getData(this.getDataMethod, payload).then(result => {
      let data = result;

      if (this.filterOptions) {
        data = result.filter(item => {
          if (this.filterOptions.value) {
            return item[this.filterOptions.name] == this.filterOptions.value;
          }
          return !!item[this.filterOptions.name] == this.filterOptions.hasValue;
        });
      }

      this.options = data.map(
        item =>
          new ComboBoxDropdownItem(
            item.id,
            item.description,
            this.value.includes(item.id),
            item.value,
            item.enable != false,
          ),
      );

      if (!this.options.some(option => option.selected)) this.value = null;
      if (this.value && this.value.length > 0) {
        this._syncOptionSelectedChange();
      }
    });
  }

  _evtClickOptionSelected(selectedOption) {
    selectedOption.selected = !selectedOption.selected;
    this.value = this.options.filter(opt => opt.selected).map(opt => opt.id);
  }

  _getDescription() {
    const selectedOptions = this.options.filter(option => option.selected);
    if (selectedOptions.length === 1) {
      return '1 item selecionado';
    }
    if (selectedOptions.length > 1) {
      return `${selectedOptions.length} itens selecionados`;
    }
    return 'Selecione...';
  }

  _onValueChanged() {
    // if (this.value && this.value.length > 0 && this.options) {
    this._syncOptionSelectedChange();
    // }
  }

  _onNgValueChanged(newValue) {
    if (this.ngValue && this.ngValue.length > 0 && this.options.length > 0) {
      newValue.forEach(id => {
        const [selectedOption] = this.options.filter(option => option.value === id);
        if (selectedOption) {
          this._evtClickOptionSelected(selectedOption);
        }
      });

      this._syncOptionSelectedChange();
    } else if (this.ngValue && this.ngValue.length > 0) {
      this.value = newValue;
    }
  }

  _syncOptionSelectedChange() {
    this.$scope.$emit(
      'optionSelectedChange',
      this.options.filter(opt => opt.selected),
    );
  }

  _disabledChanged(newValue) {
    if (typeof newValue == 'boolean') {
      this.disabled = newValue;
    }
  }
  /* */

  /* Public */
  removeSelectedById(id) {
    const selectedOption = this.options.find(opt => opt.id === id);
    if (selectedOption) {
      this._evtClickOptionSelected(selectedOption);
    }
  }

  updateComboBoxOptions(dataParams) {
    this.value = this.value && this.value.length > 0 ? this.value : [];
    this.getDataParams = dataParams;
    this._getComboBoxOptions();
  }

  /* */
}

class PowerCrudComboboxMultiSelect {
  constructor() {
    this.template = template;
    this.bindings = {
      key: '=',
      value: '=?',
      name: '=',
      hint: '=?',
      warning: '=?',
      options: '=?',
      required: '=?',
      disabled: '=?',
      getDataMethod: '=?',
      getDataParams: '=?',
      dataset: '=?',
      hasSearch: '=?',
      filterOptions: '=?',
      ngValue: '=?',
    };
    this.controller = PowerCrudComboboxMultiSelectController;
  }
}

angular
  .module('power-crud-combobox-multi-select', ['power-dropdown'])
  .component('powerCrudComboboxMultiSelect', new PowerCrudComboboxMultiSelect());
