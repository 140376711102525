import angular from 'angular';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import Treemap from 'highcharts/modules/treemap';
import Regression from 'highcharts-regression';

import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/helpers/format-number/format-number';

import template from './golfleet-dashboard-chart-grid.html';

Exporting(Highcharts);
OfflineExporting(Highcharts);
Treemap(Highcharts);
Regression(Highcharts);
class GolfleetDashboardChartGridController {
  static get $inject() {
    return ['$element', '$timeout', '_today', '_months', 'dashboardServices'];
  }

  constructor($element, $timeout, _today, _months, dashboardServices) {
    Object.assign(this, {
      $: $element[0],
      $timeout,
      _today,
      _months,
      dashboardServices,
    });

    this.chart = null;
    this.optChartSelected = null;
    this.moreOptionsChart = false;
    this.itemSelected = null;
    this.chartLoading = false;
  }

  /* Lifecycle */
  $onInit() {
    this.optChartSelected = this.configData.config.optionsConfig.actions[0];
    this.itemSelected = this.configData.data.grid[0];

    this.$timeout(() => {
      const data = {};

      const lastItem = this.configData.data.chart[this.configData.data.chart.length - 1];

      const lastItemPeriod = lastItem.period.split('-');

      data.data = this.configData.data.chart.map((item, index) => [index, parseFloat(item.value)]);
      data.hasLastMonth =
        this._today.getFullYear() == parseInt(lastItemPeriod[0], 10) &&
        this._today.getMonth() == parseInt(lastItemPeriod[1], 10) - 1;
      data.fillColor = this.itemSelected.config.color;
      data.categories = this.configData.data.chartConfig.categories;

      this._createChart('gs-cg-dashboard-chart-container', data, false);
    });
  }
  /* */

  /* Private */
  _createChart(element, data, hasRegression, chartType) {
    let markerObj = {};

    const dataset = [];

    data.data.forEach(item => {
      if (data.hasLastMonth && item[0] == data.data.length - 1) {
        markerObj = {
          fillColor: data.fillColor,
          lineColor: null,
          radius: 5,
        };
      } else {
        markerObj = {
          radius: 5,
        };
      }

      dataset.push({
        marker: markerObj,
        x: item[0],
        y: item[1],
      });
    });

    this.chart = new Highcharts.Chart({
      chart: {
        type: chartType || 'line',
        renderTo: this.$.querySelector(`#${element}`),
        spacing: [8, 8, 10, 8],
        style: {
          fontFamily: 'Ubuntu',
          fontSize: '12px',
        },
      },
      title: {
        text: null,
      },
      xAxis: {
        alternateGridColor: 'rgba(0, 0, 0, 0.05)',
        categories: data.categories,
        labels: {
          y: 28,
        },
        crosshair: {
          dashStyle: 'dash',
        },
      },
      yAxis: {
        type: 'linear',
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        min: 0,
      },
      plotOptions: {
        series: {
          marker: {
            symbol: 'circle',
          },
          point: {
            events: {
              mouseOver() {
                if (this.series.xAxis.labelGroup.element.children)
                  this.series.xAxis.labelGroup.element.children[this.x].classList.add(
                    'gs-cg-dashboard-label-selected',
                  );
                else if (this.series.xAxis.labelGroup.element.getElementsByTagName('text'))
                  this.series.xAxis.labelGroup.element
                    .getElementsByTagName('text')
                    [this.x].classList.add('gs-cg-dashboard-label-selected');
              },
              mouseOut() {
                if (this.series.xAxis.labelGroup.element.children)
                  this.series.xAxis.labelGroup.element.children[this.x].classList.remove(
                    'gs-cg-dashboard-label-selected',
                  );
                else if (this.series.xAxis.labelGroup.element.getElementsByTagName('text'))
                  this.series.xAxis.labelGroup.element
                    .getElementsByTagName('text')
                    [this.x].classList.remove('gs-cg-dashboard-label-selected');
              },
            },
          },
        },
      },
      tooltip: {
        useHTML: true,
        borderRadius: 8,
        padding: 0,
        customOptionsTooltip: {
          configData: this.configData,
          itemSelected: this.itemSelected,
          months: this._months,
        },
        formatter() {
          const period =
            this.series.tooltipOptions.customOptionsTooltip.configData.data.chart[
              this.point.x
            ].period.split('-');

          return (
            `${
              "<div class='flex-column i-center gs-cg-dashboard-chart-tooltip'>" +
              "<span style='color:"
            }${this.series.tooltipOptions.customOptionsTooltip.itemSelected.config.color}'>${
              this.series.tooltipOptions.customOptionsTooltip.months[parseInt(period[1], 10) - 1]
                .desc
            } de ${period[0]}</span>` +
            `<span>${
              this.series.tooltipOptions.customOptionsTooltip.itemSelected.config.unit == 'km'
                ? `${this.series.tooltipOptions.customOptionsTooltip.configData.data.chart[
                    this.point.x
                  ].value.formatNumber(2, ',', '.')} Km`
                : this.series.tooltipOptions.customOptionsTooltip.configData.data.chart[
                    this.point.x
                  ].formattedValue
            }</span>` +
            `</div>`
          );
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      exporting: { enabled: false },
      series: [
        {
          regression: hasRegression,
          regressionSettings: {
            type: 'linear',
            enableMouseTracking: false,
            color: 'rgba(255, 234, 59, 1)',
            zIndex: 1,
          },
          lineWidth: 2,
          color: '#fff',
          zoneAxis: 'x',
          zones: data.hasLastMonth
            ? [
                {
                  value: data.data.length - 2,
                },
                {
                  dashStyle: 'Dash',
                },
              ]
            : [],
          data: dataset,
          zIndex: 2,
        },
      ],
    });
  }

  _evtClickOptionChartSelected(itemReturn) {
    this.optChartSelected = itemReturn;
    switch (itemReturn.value) {
      case 'tendenceLine': {
        const data = {};

        const lastItem = this.configData.data.chart[this.configData.data.chart.length - 1];

        const lastItemPeriod = lastItem.period.split('-');

        data.data = this.configData.data.chart.map((item, index) => [
          index,
          parseFloat(item.value),
        ]);
        data.hasLastMonth =
          this._today.getFullYear() == parseInt(lastItemPeriod[0], 10) &&
          this._today.getMonth() == parseInt(lastItemPeriod[1], 10) - 1;
        data.fillColor = this.itemSelected.config.color;
        data.categories = this.configData.data.chartConfig.categories;

        this._createChart('gs-cg-dashboard-chart-container', data, true);
        break;
      }
      default: {
        this.chart.series[0].update({
          type: itemReturn.value,
        });

        // Regression false
        if (this.chart.series[1]) {
          this.chart.series[1].remove();
        }
        break;
      }
    }

    this.moreOptionsChart = false;
  }

  _evtClickGridSelectLine(item) {
    if (this.itemSelected.tipoInfracao === item.tipoInfracao) {
      return;
    }
    this.chartLoading = true;
    this.configData.params.typeReport = item.tipoInfracao;

    this.dashboardServices.getGraphic(this.configData.params).then(g => {
      this.itemSelected = item;

      this.chartLoading = false;

      const data = {};

      const optChart = this.optChartSelected ? this.optChartSelected.value : null;

      this.configData.data.chart = g;
      this.configData.data.chartConfig.comparative = [
        {
          desc: `${this._months[parseInt(g[0].period.split('-')[1], 10) - 1].desc} de ${
            g[0].period.split('-')[0]
          }`,
          formattedValue: g[0].formattedValue,
          value: g[0].value,
        },
        {
          desc: `${this._months[parseInt(g[g.length - 1].period.split('-')[1], 10) - 1].desc} de ${
            g[g.length - 1].period.split('-')[0]
          }${data.hasLastMonth ? ' (parcial)' : ''}`,
          formattedValue: g[g.length - 1].formattedValue,
          value: g[g.length - 1].value,
        },
      ];

      const lastItem = this.configData.data.chart[this.configData.data.chart.length - 1];

      const lastItemPeriod = lastItem.period.split('-');

      // eslint-disable-next-line no-shadow
      data.data = this.configData.data.chart.map((item, index) => [index, parseFloat(item.value)]);
      data.hasLastMonth =
        this._today.getFullYear() == parseInt(lastItemPeriod[0], 10) &&
        this._today.getMonth() == parseInt(lastItemPeriod[1], 10) - 1;
      data.fillColor = this.itemSelected.config.color;
      // eslint-disable-next-line no-shadow
      data.categories = g.map(item => {
        const date = item.period.split('-');
        return this._months[parseInt(date[1], 10) - 1].desc.substr(0, 3).toUpperCase();
      });

      this._createChart(
        'gs-cg-dashboard-chart-container',
        data,
        optChart == 'tendenceLine',
        optChart != 'tendenceLine' ? optChart : 'line',
      );
    });
  }
  /* */
}

class GolfleetDashboardChartGrid {
  constructor() {
    this.template = template;
    this.bindings = {
      configData: '=',
    };
    this.controller = GolfleetDashboardChartGridController;
  }
}

angular
  .module('golfleet-dashboard-chart-grid', ['power-dropdown'])
  .component('golfleetDashboardChartGrid', new GolfleetDashboardChartGrid());
