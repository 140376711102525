import angular from 'angular';
import { app } from '../app.module';

class AuthInterceptor {
  static get $$ngIsClass() {
    return true;
  }

  static get $inject() {
    return ['$state', '$ngRedux', '$q', '$location', 'version', 'tipoApp', 'mobile'];
  }

  constructor($state, $ngRedux, $q, $location, version, tipoApp, mobile) {
    Object.assign(this, { $state, $ngRedux, $q, $location, version, tipoApp, mobile });

    this.__appStore = $ngRedux.connect(store =>
      Object({
        state: store.state,
        session: store.session,
      }),
    )(this);

    this.request = this.request.bind(this);
    this.response = this.response.bind(this);
    this.responseError = this.responseError.bind(this);
  }

  request(request) {
    if (this.state.routeList && this.state.routeList.length > 0) {
      const currentState = this.state.routeList[this.state.routeList.length - 1];
      if (currentState.stateConfig && currentState.stateConfig.isAdm && !this.session.isAdm) {
        this.$state.go('dashboard');
      }
    }
    Object.assign(request, {
      headers: request.headers || {},
      params: request.params || {},
    });

    if (
      this.session.token &&
      request.url.indexOf('/token') == -1 &&
      request.url.indexOf('api.here.com') == -1 &&
      request.url.indexOf('vimeo.com') == -1
    ) {
      request.headers.Authorization = `Bearer ${this.session.token}`;
    }
    if (request.url.indexOf('/UploadFile') != -1) return request;
    if (request.url.indexOf('api.here.com') != -1) return request;
    if (request.url.indexOf('/token') == -1) {
      // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const timezone = 'America/Sao_Paulo';

      if (request.method == 'POST' || request.method == 'DELETE') {
        if (request.data) {
          const configData = angular.fromJson(request.data);
          Object.assign(configData, {
            versao: this.version,
            mobile: this.mobile,
            tipoApp: this.tipoApp,
            timezone,
          });
          request.data = angular.toJson(configData);
        } else
          request.data = angular.toJson({
            versao: this.version,
            mobile: this.mobile,
            tipoApp: this.tipoApp,
            timezone,
          });
      } else
        Object.assign(request.params, {
          versao: this.version,
          mobile: this.mobile,
          tipoApp: this.tipoApp,
          timezone,
        });
      return request;
    }
    return request;
  }

  response(response) {
    if (response.data) {
      if (response.data.invalidAccount == true || response.data.changePassword == true) {
        window.localStorage.removeItem('authorizationData');
        document.querySelector('power-loader').toggle(false);
        this.$ngRedux.dispatch({ type: 'SIGNOUT' });
        this.$ngRedux.dispatch({ type: 'CLEAR_ROUTE_LIST' });
        this.$state.go('login');
        return response;
      }
      if (typeof response.data.isAdm !== 'undefined') {
        if (JSON.parse(response.data.isAdm) != this.session.isAdm) {
          this.$ngRedux.dispatch({
            type: 'UPDATE_IS_ADM',
            data: {
              isAdm: JSON.parse(response.data.isAdm),
            },
          });

          if (this.session.clientId == response.data.cliId) {
            window.location.reload();
            return response;
          }
        }
        if (response.data.fleetCamProvider != this.session.fleetCamProvider) {
          this.$ngRedux.dispatch({
            type: 'UPDATE_FLEET_CAM_PROVIDER',
            data: {
              fleetCamProvider: response.data.fleetCamProvider,
            },
          });
        }
      }
      if (response.data.changeToken)
        this.$ngRedux.dispatch({
          type: 'APP_HAS_CHANGE_TOKEN',
          data: {
            hasChangeToken: !!response.data.changeToken,
          },
        });
      if (response.data.atualizacaoNecessaria)
        this.$ngRedux.dispatch({
          type: 'APP_HAS_UPDATE',
          data: {
            hasRequiredUpdate: !!response.data.atualizacaoObrigatoria,
          },
        });

      const { locationService } = this.$state.router;
      const sessionNeedUserAccept = this.session.privacyPolicyStatus?.needUserAccept;
      const responseNeedUserAccept = response.data.privacyPolicyUserStatus?.needUserAccept;

      if (
        locationService.$location.$$path !== '/login' &&
        sessionNeedUserAccept !== responseNeedUserAccept
      ) {
        this.$ngRedux.dispatch({
          type: 'APP_HAS_PRIVACY_POLICY_UPDATE',
          data: {
            privacyPolicyStatus: response.data.privacyPolicyUserStatus,
          },
        });
      }
    }

    window.Sentry.configureScope(scope => {
      scope.setExtra('requestResponse', response);
    });

    return response;
  }

  responseError(rejection) {
    if (rejection.status == 401 || rejection.status == 403) {
      document.querySelector('power-loader').toggle(false);
      this.$ngRedux.dispatch({ type: 'SIGNOUT' });
      this.$ngRedux.dispatch({ type: 'CLEAR_ROUTE_LIST' });
      this.$state.go('login');
    }
    return this.$q.reject(rejection);
  }
}

app.factory('authInterceptorService', AuthInterceptor);
