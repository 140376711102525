import angular from 'angular';

import { PowerMapUtilizationHereProvider } from './providers/here/power-map-utilization.here-provider';
import { PowerMapUtilizationLeafletProvider } from './providers/leaflet/power-map-utilization.leaflet-provider';
import { PowerMapController } from '../power-map/power-map';

import template from './power-map-utilization.html';

class PowerMapUtilizationController extends PowerMapController {
  static get $inject() {
    return ['$element', '$ngRedux', '$rootScope', '$scope', '$http', 'urlApi'];
  }

  constructor($element, $ngRedux, $rootScope, $scope, $http, urlApi) {
    super($element, $ngRedux, $scope, $http, urlApi);

    if (this.providerName === 'HERE') {
      this.provider = new PowerMapUtilizationHereProvider(
        this,
        $element,
        $ngRedux,
        $rootScope,
        $scope,
        $http,
        urlApi,
      );
    } else {
      // this.providerName === 'LEAFLET'
      this.provider = new PowerMapUtilizationLeafletProvider(
        this,
        $element,
        $ngRedux,
        $rootScope,
        $scope,
        $http,
        urlApi,
      );
    }
  }

  $onInit() {
    super.$onInit();
  }

  /* Public */
  removeLayers(layerNameList = ['default']) {
    this.provider.removeLayers(layerNameList);
  }

  toggleTimelineControl(show) {
    this.provider.toggleTimelineControl(show);
  }

  setTimelineDataset({ markers, linestrings }) {
    this.provider.setTimelineDataset({ markers, linestrings });
  }

  updateTimelinePosition(evtParams) {
    this.provider.updateTimelinePosition(evtParams);
  }
  /**/

  /* Private */
  _toggleRouteTimeline() {
    return this.provider._toggleRouteTimeline();
  }

  _endRouteTimeline() {
    this.provider._endRouteTimeline();
  }

  _renderRouteTimeline() {
    return this.provider._renderRouteTimeline();
  }
  /**/
}

class PowerMapUtilization {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = PowerMapUtilizationController;
  }
}

angular
  .module('power-map-utilization', ['power-map'])
  .component('powerMapUtilization', new PowerMapUtilization());

export { PowerMapUtilizationController };
