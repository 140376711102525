import angular from 'angular';
import 'ng-redux';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-toolbar/power-toolbar';
import '@power/power-components/components/power-photo-indexation-stand-alone/power-photo-indexation-stand-alone';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-pagination/power-pagination';
import { PowerReportController } from '@power/power-components/components/power-report/power-report';

import template from './golfleet-report-golcam.html';
import './golfleet-report-golcam.scss';

class GolfleetReportGolcamController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    this.dataKeys = [];
    this.selectedRows = [];
    this.driverId = this.stateConfig.getDataFixedParams.driverId;
    this.ulid = this.stateConfig.getDataFixedParams.ulid;
  }

  /* Lifecycle */
  $onInit() {
    this.changeView('indexation');

    this.commonServices.getToolbarOptions(
      this.stateConfig.screenName,
      this.stateConfig.toolbarName,
      getToolbarResult => {
        const { addConfig, editConfig, viewsConfig, exportConfig, importConfig, actionConfig } =
          getToolbarResult;
        Object.assign(this.stateConfig.toolbarConfig, {
          addConfig,
          editConfig,
          viewsConfig,
          exportConfig,
          actionConfig,
          importConfig,
        });
        this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });
      },
    );
  }
  /* */

  /* Public */
  changeView(viewMode) {
    this.stateConfig.viewMode = viewMode;
    this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });
    switch (viewMode) {
      case 'indexation':
        this.$.setAttribute('indexation-view', '');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('split-view');
        break;
      default:
        this.$.setAttribute('indexation-view', '');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('split-view');
        break;
    }
  }

  changeRow(page) {
    if (page != this.stateConfig.gridConfig.page) this.changePage(page);
  }
  /* */

  /* Private */
  _callAction(action) {
    switch (action.actionType) {
      case 'removeAndReindex':
        this.$.querySelector('power-photo-indexation-stand-alone').removeAndReindex();
        break;
      default:
        break;
    }
  }
  /* */
}

class GolfleetReportGolcam {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetReportGolcamController;
  }
}

angular
  .module('golfleet-report-golcam', [
    'ngRedux',
    'power-fab',
    'power-dropdown',
    'power-toolbar',
    'power-photo-indexation-stand-alone',
    'power-footer',
    'power-pagination',
  ])
  .component('golfleetReportGolcam', new GolfleetReportGolcam());
