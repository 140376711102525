class ToolbarConfig {
  constructor(params = {}) {
    const { saveConfig, viewsConfig, exportConfig, actionConfig, autoSendConfig } = params;

    this.viewsConfig = viewsConfig;
    this.exportConfig = exportConfig;
    this.actionConfig = actionConfig;
    this.saveConfig = saveConfig;
    this.autoSendConfig = autoSendConfig;
  }
}

class GridConfig {
  constructor(params = {}) {
    const {
      page = 1,
      dataset,
      lastPage = 0,
      pageSize = 10,
      gridTotal,
      sortField,
      gridMongoId,
      activeField,
      gridHeaders,
      sortDirection,
      backPagination = false,
      hasRowSelection = false,
      descriptionField,
      complementHeaderField,
      complementHeaderText,
      replaceHeaderText,
    } = params;

    this.page = page;
    this.dataset = dataset;
    this.lastPage = lastPage;
    this.pageSize = pageSize;
    this.gridTotal = gridTotal;
    this.sortField = sortField;
    this.gridHeaders = gridHeaders;
    this.activeField = activeField;
    this.gridMongoId = gridMongoId;
    this.sortDirection = sortDirection;
    this.backPagination = backPagination;
    this.hasRowSelection = hasRowSelection;
    this.descriptionField = descriptionField;
    this.complementHeaderField = complementHeaderField;
    this.complementHeaderText = complementHeaderText;
    this.replaceHeaderText = replaceHeaderText;
  }
}

class DashStateConfig {
  constructor(params = {}) {
    const {
      screenName,
      toolbarName,
      filterConfig = [],
      filterConditions = [],
      checkDefaulterRules = false,
    } = params;

    this.isAdm = false;
    this.navigation = { date: [], hierarchy: {} };
    this.screenName = screenName;
    this.toolbarName = toolbarName;
    this.filterConfig = filterConfig;
    this.toolbarConfig = new ToolbarConfig();
    this.filterConditions = filterConditions;
    this.checkDefaulterRules = checkDefaulterRules;
  }
}

class ReportStateConfig {
  constructor(params = {}) {
    const {
      type,
      isAdm = false,
      viewMode = 'grid',
      gridName,
      screenName,
      toolbarName,
      filterConfig = [],
      getDataMethod,
      isCustomReport = false,
      customReportId,
      backPagination,
      getDataFilters,
      hasRowSelection,
      filterConditions = [],
      getDataFixedParams,
      hasMaxRows = false,
      maxRowsQty = 10000,
      checkDefaulterRules = false,
    } = params;

    this.type = type;
    this.isAdm = isAdm;
    this.viewMode = viewMode;
    this.gridName = gridName;
    this.navigation = { date: [], hierarchy: {} };
    this.gridConfig = new GridConfig({ backPagination, hasRowSelection });
    this.screenName = screenName;
    this.toolbarName = toolbarName;
    this.filterConfig = filterConfig;
    this.toolbarConfig = new ToolbarConfig();
    this.getDataMethod = getDataMethod;
    this.isCustomReport = isCustomReport;
    this.customReportId = customReportId;
    this.getDataFilters = getDataFilters;
    this.filterConditions = filterConditions;
    this.getDataFixedParams = getDataFixedParams;
    this.hasMaxRows = hasMaxRows;
    this.maxRowsQty = maxRowsQty;
    this.checkDefaulterRules = checkDefaulterRules;
  }
}

class RecordStateConfig {
  constructor(params = {}) {
    const {
      tabs,
      type,
      isAdm = false,
      title,
      belong,
      summary,
      getDataMethod,
      getDataFixedParams,
    } = params;

    this.tabs = tabs;
    this.type = type;
    this.isAdm = isAdm;
    this.title = title;
    this.belong = belong;
    this.summary = summary;
    this.getDataMethod = getDataMethod;
    this.getDataFixedParams = getDataFixedParams;
  }
}

export { ToolbarConfig, GridConfig, DashStateConfig, ReportStateConfig, RecordStateConfig };
