import angular from 'angular';

import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-toast/power-toast';

import template from './golfleet-support-helper.html';
import './golfleet-support-helper.scss';

import '../golfleet-support-slider/golfleet-support-slider';

class GolfleetSupportHelperController {
  static get $inject() {
    return ['$element', '$scope', '$ngRedux', '$http', 'urlApi', 'commonServices'];
  }

  constructor($element, $scope, $ngRedux, $http, urlApi, commonServices) {
    Object.assign(this, { $: $element[0], $scope, $http, urlApi, commonServices });

    this.id = `single-checkbox-remind-me-support`;
    this.name = 'single-checkbox';

    this.__appInheritBehavior = $ngRedux.connect(behavior =>
      Object({ session: behavior.session, state: behavior.state }),
    )(this);

    this.loginUrl = null;
    this.fetchingData = true;
  }

  /**
   * Lifecycle
   */
  $onInit() {
    Object.assign(this.$, {
      actionEventHandler: this.actionEventHandler.bind(this),
      tooglePopup: this.tooglePopup.bind(this),
    });
  }

  $onDestroy() {}
  /* Lifecycle */

  tooglePopup() {
    setTimeout(() => {
      this.$.querySelector('#golfleet-support-helper-popup').toggle();
    });
  }

  openDeskManager(openDirect) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this.$http({
      url: `${this.urlApi}/DeskManager/GetToken`,
      method: 'POST',
      data: {},
    })
      .then(
        success => {
          if (success.status == 200) {
            const { data } = success.data;

            if (data || data == null) {
              if (openDirect || this.isEnabledOrDisabledSupportTip) {
                window.open(data.uriLogin, '_blank');
              }
              if (!this.isEnabledOrDisabledSupportTip) {
                document.querySelector('golfleet-support-helper').tooglePopup();
              }
            } else {
              this.tooglePopup();
            }
          }
        },
        () => {
          this.tooglePopup();
        },
      )
      .finally(() => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
      });
  }

  async openNewDeskManager(openDirect) {
    if (this.loginUrl || this.loginUrl == null) {
      if (openDirect || this.isEnabledOrDisabledSupportTip) {
        window.open(this.loginUrl, '_blank');
      }
      if (!this.isEnabledOrDisabledSupportTip) {
        document.querySelector('golfleet-support-helper').tooglePopup();
      }
    } else {
      this.tooglePopup();
    }
  }

  actionEventHandler(action) {
    if (action == 'cancel') {
      this.$.querySelector('#golfleet-support-helper-popup').toggle();
    } else if (action == 'send') {
      // this.openDeskManager(true);
      this.openNewDeskManager(true);
    }
  }

  _removeErrorAttr(item) {
    item.error = false;
  }

  _loadDeskInfo() {
    this.fetchingData = true;

    this.$http({
      url: `${this.urlApi}/DeskManager/GetToken`,
      method: 'POST',
      data: {},
    })
      .then(
        success => {
          if (success.status == 200) {
            const { data } = success.data;
            this.loginUrl = data.uriLogin;
          }
        },
        () => {
          this.tooglePopup();
        },
      )
      .finally(() => {
        this.fetchingData = false;
      });
  }
}

class GolfleetSupportHelper {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetSupportHelperController;
  }
}

angular
  .module('golfleet-support-helper', ['power-popup', 'power-toast', 'golfleet-support-slider'])
  .component('golfleetSupportHelper', new GolfleetSupportHelper());

export { GolfleetSupportHelperController };
