import angular from 'angular';
import 'ng-redux';

import '@power/power-components/components/power-toast/power-toast';
import { isValidEmail } from '@power/power-components/utils/email-util.js';
import '../golfleet-popup-golfleet-id-app-invite/golfleet-popup-golfleet-id-app-invite';
import '../golfleet-popup-driver-privacy-policy/golfleet-popup-driver-privacy-policy';
import '../golfleet-popup-golfleet-id-privacy-policy/golfleet-popup-golfleet-id-privacy-policy';
import '../golfleet-popup-driver-app-invite/golfleet-popup-driver-app-invite';

import { PowerFormDriverCrudController } from '@power/power-components/components/power-form-driver-crud/power-form-driver-crud';
import template from './golfleet-form-driver-crud.html';
import './golfleet-form-driver-crud.scss';

class GolfleetFormDriverCrudController extends PowerFormDriverCrudController {
  static get $inject() {
    return [
      '$element',
      '$sce',
      '$ngRedux',
      '$rootScope',
      '$state',
      '$http',
      '$scope',
      'commonServices',
      'urlApi',
      'crudServices',
    ];
  }

  /**
   * Creates an instance of GolfleetFormDriverCrudController.
   * @memberof GolfleetFormDriverCrudController
   */
  // eslint-disable-next-line no-useless-constructor
  constructor(
    $element,
    $sce,
    $ngRedux,
    $rootScope,
    $state,
    $http,
    $scope,
    commonServices,
    urlApi,
    crudServices,
  ) {
    super(
      $element,
      $sce,
      $ngRedux,
      $rootScope,
      $state,
      $http,
      $scope,
      commonServices,
      urlApi,
      crudServices,
    );

    this.btnAppDriverInviteChecked = false;
    this.btnAppGolfleetIdChecked = false;
  }

  /* Private */
  _getFormData() {
    if (this.driverEditConfig) {
      const { idDriver, getDataMethod } = this.driverEditConfig;
      const payload = {
        id: idDriver,
      };
      this.crudServices
        .getData(getDataMethod, payload)
        .then(data => {
          this.data = data;

          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: true },
              bubbles: true,
              composed: true,
            }),
          );

          if (data) {
            this.formConfig.map(config => {
              if (config.sections) {
                config.sections.forEach(section => {
                  if (section.items && section.items.length > 0) {
                    section.items.map(item => {
                      const field = this.data[`${item.field}`];
                      item.value = field;

                      if (item.type == 'combobox' && item.value) {
                        item.options.forEach(option => {
                          option.selected = option.value == item.value;
                        });

                        if (item.field == 'idDriver') {
                          item.disabled = true;
                          item.triggerBlock = true;
                        }
                      }

                      if (item.inputMaskFormat && item.value) {
                        item.value = item.value.toString();
                      }

                      if (item.type === 'Chip') {
                        if (typeof item.value == 'undefined') {
                          item.value = [];
                        }

                        // To post accessories, it is necessary to pass the
                        // description in the id field
                        if (item.fieldId) {
                          item.value.map(row => {
                            row.id = row[item.fieldId];
                            return row;
                          });
                        }
                      }

                      return item;
                    });
                  }
                });
              }
              return config;
            });
          } else {
            this.$.querySelector('power-toast#form-driver-crud-toast').toggle(true);
            Object.assign(this, {
              toast: {
                text: 'Nenhum dado encontrado.',
              },
            });
          }
        })
        .finally(() => {
          if (this.data) {
            const datepickers = this.$.querySelectorAll('power-crud-datepicker');
            for (let i = 0; i < datepickers.length; i++) {
              const field = datepickers[i].getAttribute('field');
              datepickers[i].setDate(this._FormatStringDate(this.data[field]));
            }
          }

          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          );
        });
    } else {
      this.formConfig.map(config => {
        if (config.sections) {
          config.sections.forEach(section => {
            if (section.items && section.items.length > 0) {
              section.items.map(item => {
                if (
                  // Por padrão o valor vem como True, esta alteração é feita para quando o
                  // botão de envio de convite power ID não estiver na tela e não seja enviado true para o backend
                  item.field == 'sendGolfleetIdInvite' &&
                  !this.modules.includes('IDENTIFICACAO_IA') &&
                  !this.modules.includes('IDENT_BLUETOOTH_BUZZER') &&
                  !this.modules.includes('IDENT_BLUETOOTH_DESBLOQUEIO')
                ) {
                  item.value = false;
                }
                return item;
              });
            }
          });
        }
        return config;
      });

      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: false },
          bubbles: true,
          composed: true,
        }),
      );
    }

    const popupGolfleetIdInvite = this.$.querySelector('golfleet-popup-golfleet-id-app-invite');

    if (popupGolfleetIdInvite) {
      popupGolfleetIdInvite.setup(this.userEmail || '');
    }

    this.formReady = true;
  }

  _evtCallOpenInviteAppPopup(field) {
    if (field == 'sendGolfleetDriverInvite') {
      const popupDriver = this.$.querySelector('golfleet-popup-driver-app-invite');

      if (popupDriver) {
        popupDriver.toggle();
      }
    }

    if (field == 'sendGolfleetIdInvite') {
      const popupGolfleetIdInvite = this.$.querySelector('golfleet-popup-golfleet-id-app-invite');

      if (popupGolfleetIdInvite) {
        popupGolfleetIdInvite.toggle(1);
      }
    }
  }

  _evtCallInsert() {
    setTimeout(() => {
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );
    }, 100);

    this._createDriver(this.parsedDataInsert);
  }

  _evtCallPopupDriverAfterClose() {
    if (this.parsedDataInsert.sendGolfleetIdInvite) {
      this._privacyPolicyGolfleetIdOpen();
    } else {
      this._evtCallInsert();
    }
  }

  _privacyPolicyVerifyOpen() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: false },
        bubbles: true,
        composed: true,
      }),
    );

    if (this.parsedDataInsert.sendGolfleetDriverInvite) {
      const privacyPolicyPopup = this.$.querySelector(
        '#golfleet-popup-driver-privacy-policy-report',
      );

      if (privacyPolicyPopup) {
        privacyPolicyPopup.show();
      }
    } else {
      this._privacyPolicyGolfleetIdOpen();
    }
  }

  _privacyPolicyGolfleetIdOpen() {
    const privacyPolicyGidPopup = this.$.querySelector(
      '#golfleet-popup-golfleet-id-privacy-policy-report',
    );

    if (privacyPolicyGidPopup) {
      privacyPolicyGidPopup.show();
    }
  }

  _validateFields() {
    const emptyValues = [];
    let auxItemEmail = {};
    this.formConfig.map(config => {
      if (config.sections) {
        config.sections.forEach(section => {
          if (section.items && section.items.length > 0) {
            section.items.map(item => {
              item.warning = '';
              if (item.required && !item.value) {
                item.warning = 'Campo obrigatório';
                emptyValues.push(item);
              }
              if (item.field === 'email') {
                auxItemEmail = item;
                if (item.value) {
                  if (!isValidEmail(item.value)) {
                    item.warning = 'Email inválido';
                    emptyValues.push(item);
                  }
                }
              }

              if (
                (item.field === 'sendGolfleetDriverInvite' &&
                  item.value &&
                  !auxItemEmail.value &&
                  !this.driverEditConfig) ||
                (item.field === 'sendGolfleetIdInvite' &&
                  item.value &&
                  !auxItemEmail.value &&
                  (this.modules.includes('IDENTIFICACAO_IA') ||
                    this.modules.includes('IDENT_BLUETOOTH_BUZZER') ||
                    this.modules.includes('IDENT_BLUETOOTH_DESBLOQUEIO')) &&
                  !this.driverEditConfig)
              ) {
                if (emptyValues.indexOf(auxItemEmail) === -1) {
                  auxItemEmail.warning = 'Campo obrigatório para envio de convite';
                  emptyValues.push(auxItemEmail);
                }
              }
              return item;
            });
          }
        });
      }
      return config;
    });
    if (emptyValues.length > 0) {
      return false;
    }
    return true;
  }

  _confirmCrudForm() {
    const parsedData = {};
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    // fields validation
    if (this._validateFields()) {
      if (this.driverEditConfig) {
        parsedData.id = this.driverEditConfig.idDriver;
      }
      // recover form values
      this.formConfig.forEach(form =>
        form.sections.forEach(section =>
          section.items
            .filter(x => x.value)
            .map(item => {
              if (this.driverEditConfig && item.value.id) {
                parsedData[item.field] = item.value.id;
              } else if (item.type === 'Chip') {
                parsedData[item.field] = item.value.map(data => ({ id: data.id }));
              } else {
                parsedData[item.field] = item.value;
              }
              return parsedData;
            }),
        ),
      );
      if (this.driverEditConfig) {
        this._updateDriver(parsedData);
      } else if (parsedData.sendGolfleetDriverInvite || parsedData.sendGolfleetIdInvite) {
        this.parsedDataInsert = parsedData;
        this._privacyPolicyVerifyOpen();
      } else {
        this._createDriver(parsedData);
      }
    } else {
      this.$.querySelector('power-toast#form-driver-crud-toast').toggle(true);
      Object.assign(this, {
        toast: {
          text: 'Algumas informações são obrigatórias e precisam ser preenchidas.',
        },
      });
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: false },
          bubbles: true,
          composed: true,
        }),
      );
    }
  }
  /* */
}

class GolfleetFormDriverCrud {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetFormDriverCrudController;
  }
}

angular
  .module('golfleet-form-driver-crud', [
    'ng-tippy',
    'power-toast',
    'golfleet-popup-golfleet-id-app-invite',
    'golfleet-popup-driver-privacy-policy',
    'golfleet-popup-golfleet-id-privacy-policy',
    'golfleet-popup-driver-app-invite',
    'golfleet-file-list',
  ])
  .component('golfleetFormDriverCrud', new GolfleetFormDriverCrud());
