/* global L: true isIframe: true */
import { PowerMapLeafletProvider } from '@power/power-components/components/power-map/providers/leaflet/power-map.leaflet-provider';
import '@power/power-components/helpers/is-iframe/is-iframe';
import './golfleet-map-suspected-accident.leaflet-provider.scss';

class GolfleetMapSuspectedAccidentLeafletProvider extends PowerMapLeafletProvider {
  // eslint-disable-next-line no-useless-constructor
  constructor(context, $element, $ngRedux, $scope, $http, urlApi) {
    super(context, $element, $ngRedux, $scope, $http, urlApi);
  }

  /* Public */
  async renderDataset({
    dataset,
    layerName = 'default',
    type = 'FeatureGroup',
    useCluster,
    clusterColor = '#D60F2C',
    icon,
  }) {
    const markerIcon = icon ? this._createMarkerIcon({ color: clusterColor, icon }) : null;
    const objectList = await this._createDatasetLayer({ type, dataset, markerIcon });

    if (this.mapFeatureLayers[layerName]) {
      this.mapLayer.removeLayer(this.mapFeatureLayers[layerName]);
    }

    if (useCluster) {
      this.mapFeatureLayers[layerName] = this._createCluster({ clusterColor }).addLayer(objectList);
    } else {
      this.mapFeatureLayers[layerName] = objectList;
    }

    this.mapLayer.addLayer(this.mapFeatureLayers[layerName]);
  }
  /**/

  /* Private */
  async _createDatasetLayer({ type, dataset, markerIcon }) {
    let circleDataset = [];
    let geoJsonDataset = [];
    let datasetLayer = [];

    switch (type) {
      case 'Cluster':
        geoJsonDataset = await this.workerServices.parseToGeoJsonDataset({ dataset });
        datasetLayer = L.featureGroup(
          geoJsonDataset.map(geoJson => this._geoJsonToMarker({ geoJson, markerIcon })),
        );
        break;
      case 'HeatLayer':
        datasetLayer = L.heatLayer(
          this._geoJsonToHeat({
            geoJsonList: await this.workerServices.parseToGeoJsonDataset({ dataset }),
          }),
          this.mapHeatLayerOptions,
        );
        break;
      case 'MarkerFeatureGroup':
        geoJsonDataset = await this.workerServices.parseToGeoJsonDataset({ dataset });
        datasetLayer = L.featureGroup(
          geoJsonDataset.map(data => this._geoJsonToMarker({ geoJson: data, markerIcon })),
        );
        break;
      case 'CircleFeatureGroup':
        circleDataset = await this.workerServices.parseToCircleDataset({ dataset });
        datasetLayer = L.featureGroup(
          circleDataset.map(data => this._geoJsonToCircle({ geoJson: data })),
        );
        break;
      case 'PolygonFeatureGroup':
        geoJsonDataset = await this.workerServices.parseToGeoJsonDataset({ dataset });
        datasetLayer = L.featureGroup(
          geoJsonDataset.map(data => this._geoJsonToPolygon({ geoJson: data })),
        );
        break;
      case 'LinestringFeatureGroup':
        geoJsonDataset = await this.workerServices.parseToGeoJsonDataset({ dataset });
        datasetLayer = L.featureGroup(
          geoJsonDataset.map(data => this._geoJsonToPolyline({ geoJson: data })),
        );
        break;
      case 'RectangleFeatureGroup':
        geoJsonDataset = await this.workerServices.parseToGeoJsonDataset({ dataset });
        datasetLayer = L.featureGroup(
          geoJsonDataset.map(data => this._geoJsonToRectangle({ geoJson: data })),
        );
        break;
      case 'FeatureGroup':
      default:
        circleDataset = await this.workerServices.parseToCircleDataset({ dataset });
        datasetLayer = L.featureGroup(
          circleDataset.map(data => this._geoJsonToLGeoJson({ geoJson: data, markerIcon })),
        );
        break;
    }

    return datasetLayer;
  }

  _geoJsonToMarker({ geoJson, markerIcon }) {
    return L.geoJSON(geoJson, {
      pointToLayer: (feature, latlng) =>
        L.marker(latlng, {
          ...feature.properties,
          icon:
            markerIcon ||
            this._createMarkerIcon({
              ...feature.properties,
              icon: geoJson.properties.icon || 'directions_car',
            }),
        }).bindPopup(`
          <div id="mapPopupHeader">
            <span>${feature.properties.placa}</span>
          </div>
          <div id="mapPopupBody">
            <div style="display: ${feature.properties.rua ? 'block' : 'none'}">
              <b>Endereço:</b>
              <br>
              <span>${feature.properties.rua}</span>
            </div>
            <div style="display: ${feature.properties.rua ? 'block' : 'none'}">
              <b>Cidade - Estado:</b>
              <br>
              <span>${feature.properties.municipio} - ${feature.properties.estado}</span>
            </div>
            <div>
              <b>Data Hora:</b>
              <br>
              <span>${new Date(feature.properties.dataHora).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })}</span>
            </div>
            <div>
              <b>Condutor:</b>
              <br>
              <span>${feature.properties.condutor || 'Não identificado'}</span>
            </div>
            ${
              isIframe()
                ? ''
                : `
              <div style="margin-top:8px;text-align:center">
                <a class="gs-link"
                  target="_blank"
                  href="${`https://maps.google.com/maps?layer=c&q=${latlng.lat},${latlng.lng}&cbll=${latlng.lat},${latlng.lng}&cbp=11,0,0,0,0&z=17&ll=${latlng.lat},${latlng.lng}`}">
                  Ver no StreetView
                </a>
              </div>
            `
            }
          </div>
          <div id="mapPopupFooter">
            <span> Lat: ${parseFloat(latlng.lat).toFixed(4)} </span>
            <span> Lon: ${parseFloat(latlng.lng).toFixed(4)} </span>
          </div>
					`),
    }).getLayers()[0];
  }
  /**/
}

export { GolfleetMapSuspectedAccidentLeafletProvider };
