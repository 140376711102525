import angular from 'angular';
import 'ng-redux';
import { toDate, toDateTimeInput } from '@power/power-components/utils/date-util.js';

import '@power/power-components/components/power-toast/power-toast';
import template from './golfleet-form-announcement-crud.html';
import './golfleet-form-announcement-crud.scss';

import { RoutesConfig } from '../../configs/routes/routes.config';

/**
 * Item Type (Enum)
 */
export const AnnouncementTypeEnum = {
  DESTAQUE: 1,
  CARD: 2,
  VIDEO: 3,
};

class GolfleetFormAnnouncementCrudController {
  static get $inject() {
    return [
      '$element',
      '$sce',
      '$ngRedux',
      '$rootScope',
      '$state',
      '$http',
      '$scope',
      'commonServices',
      'urlApi',
      'crudServices',
      '$sce',
    ];
  }

  /**
   * Creates an instance of GolfleetFormAnnouncementCrudController.
   * @memberof GolfleetFormAnnouncementCrudController
   */
  constructor(
    $element,
    $sce,
    $ngRedux,
    $rootScope,
    $state,
    $http,
    $scope,
    commonServices,
    urlApi,
    crudServices,
  ) {
    Object.assign(this, {
      $: $element[0],
      $sce,
      $ngRedux,
      $rootScope,
      $state,
      $http,
      $scope,
      commonServices,
      urlApi,
      crudServices,
    });

    this.__appInheritBehavior = $ngRedux.connect(behavior => {
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];
      return Object({
        /* Session Storage */
        state: behavior.state,
        session: behavior.session,

        /* State Storage */
        stateConfig: currentState ? currentState.stateConfig : {},
      });
    })(this);

    this.formConfig = [];
    this.form = [];
    this.staticFormData = [];
    this.canSubmit = true;
    this.data = null;

    this.formConfigReady = false;
    this.formViewData = [];
    this.comboboxToLoad = [];
    this.staticFormData = [];
    this.announcementEditConfig = this._getAnnouncementIdFromState();
    this.dataset = null;
    this.announcementType = {
      field: 'announcementtype',
      value: AnnouncementTypeEnum.DESTAQUE,
    };
    this.typeLink = 1;
    this.popupVideo = {
      fields: {
        midiaSource: '',
        plataformCode: '',
        title: '',
        duration: '',
        platformId: '',
        defaultThumbnailSource: '',
      },
    };
  }

  /* Lifecycle */
  $onInit() {
    this.dataset = Object.clone({}, this.crudDataset);
    this.$scope.$watch(() => this.popupVideo.fields.midiaSource, this._getVideoInfo.bind(this));

    this._getFormConfig();
    this._checkReady();
  }

  _checkReady() {
    setTimeout(() => {
      if (this.formConfigReady) {
        this._getFormData();
        this._formViewDataParser();
        this._setDeleteButtonState();
        this.$scope.$apply();
      } else {
        this._checkReady();
      }
    }, 300);
  }

  _getAnnouncementIdFromState() {
    const route = this.state.routeList.filter(x => x.routeTail);
    if (route[0]) {
      const { stateConfig } = route[0];
      return {
        idAnnouncement: route[0].routeTail,
        getDataMethod: stateConfig.getDataMethod,
      };
    }
    return null;
  }

  _getFormConfig() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    this.commonServices.getFormConfiguration('Announcement').then(data => {
      if (data.form) {
        this.formConfig = data.form;
        const sections = this.formConfig.map(item => item.sections);
        this.comboboxToLoad = sections.forEach(section => {
          section.forEach(arg => {
            arg.items.forEach(item => {
              if (item.type == 'combobox' && item.loadFrom == 'RoutesConfig') {
                const routes = Object.keys(RoutesConfig);
                routes.forEach(route => {
                  if (
                    RoutesConfig[route].data &&
                    RoutesConfig[route].data.routeLink &&
                    RoutesConfig[route].data.routeName
                  ) {
                    const routeName =
                      RoutesConfig[route].data.routeLink.toUpperCase().includes('ADM') &&
                      !RoutesConfig[route].data.routeName.toUpperCase().includes('ADM')
                        ? `${RoutesConfig[route].data.routeName} Adm`
                        : RoutesConfig[route].data.routeName;
                    if (
                      !RoutesConfig[route].data.routeLink.toUpperCase().includes('TEMP') &&
                      !RoutesConfig[route].data.routeLink.toUpperCase().includes('FORM') &&
                      !RoutesConfig[route].data.routeLink.toUpperCase().includes('INFOADDON') &&
                      !RoutesConfig[route].data.routeLink
                        .toUpperCase()
                        .includes('TRAVELSBYUTILIZATION')
                    ) {
                      item.options.push({
                        id: RoutesConfig[route].data.routeLink,
                        description: RoutesConfig[route].data.routeSubName
                          ? RoutesConfig[route].data.routeSubName
                          : routeName,
                        value: RoutesConfig[route].data.routeLink,
                        selected: false,
                      });
                    }
                  }
                });
                item.options.sort((a, b) =>
                  a.description.replace(/[ÀÁÂÃÄÅ]/g, 'A') > b.description.replace(/[ÀÁÂÃÄÅ]/g, 'A')
                    ? 1
                    : -1,
                );
              }
            });
          });
        });
      }
      this.formConfigReady = true;
    });
  }

  _getDataSet(dataMethod, payload) {
    this.crudServices.getData(dataMethod, payload).then(result => {
      this.itemsAccident = result;
    });
  }

  _formViewDataParser() {
    this._syncScope();
  }

  _syncScope() {
    if (this.$scope.$$phase === null && this.$rootScope.$$phase === null) this.$scope.$apply();
  }

  _getFormData() {
    if (this.announcementEditConfig) {
      const { idAnnouncement, getDataMethod } = this.announcementEditConfig;
      const payload = {
        request: {
          id: idAnnouncement,
        },
      };
      this.crudServices
        .getData(getDataMethod, payload)
        .then(data => {
          this.data = data;

          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: true },
              bubbles: true,
              composed: true,
            }),
          );

          if (data) {
            this.formConfig.map(config => {
              if (config.sections) {
                config.sections.forEach(section => {
                  if (section.items && section.items.length > 0) {
                    section.items.map(item => {
                      const field = this.data[`${item.field}`];
                      item.value = field;

                      if (item.field == 'announcementtype') {
                        item.disabled = true;
                        item.triggerBlock = true;
                      }

                      if (item.type == 'combobox' && item.value) {
                        if (item.field == 'applicationItems') {
                          item.options.forEach(option => {
                            if (item.value.length == 1) {
                              option.selected = option.value[0].id == item.value[0].id;
                            } else {
                              option.selected = option.value.length == item.value.length;
                            }
                          });
                        } else {
                          if (item.options && item.options.length > 0) {
                            item.options.forEach(option => {
                              option.selected = option.value == item.value;
                            });
                          }

                          if (item.field == 'idAnnouncement') {
                            item.disabled = true;
                            item.triggerBlock = true;
                          }

                          this._changeFilterValue(item);
                        }
                      }

                      if (item.inputType == 'DateTimeOffset') {
                        item.value = toDateTimeInput({ date: toDate(field) });
                      }

                      if (item.inputMaskFormat && item.value) {
                        item.value = item.value.toString();
                      }

                      if (item.type === 'Chip') {
                        if (typeof item.value == 'undefined') {
                          item.value = [];
                        }

                        // To post accessories, it is necessary to pass the
                        // description in the id field
                        if (item.fieldId) {
                          item.value.map(row => {
                            row.id = row[item.fieldId];
                            return row;
                          });
                        }
                      }

                      if (item.type == 'FileList' && item.value) {
                        this.$.querySelector(`#${item.field}-file-list`).addFileList({
                          name: field,
                          contentUrl: field,
                          type: 'url',
                        });
                      }

                      if (item.type == 'VideoList') {
                        item.value = item.value.map(video => ({
                          ...video,
                          updating: false,
                        }));
                      }

                      return item;
                    });
                  }
                });
              }
              return config;
            });
          } else {
            this.$.querySelector('power-toast#form-announcement-crud-toast').toggle(true);
            Object.assign(this, {
              toast: {
                text: 'Nenhum dado encontrado.',
              },
            });
          }
        })
        .finally(() => {
          if (this.data) {
            const datepickers = this.$.querySelectorAll('power-crud-datepicker');
            for (let i = 0; i < datepickers.length; i++) {
              const field = datepickers[i].getAttribute('field');
              datepickers[i].setDate(this.data[field]);
            }
          }

          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          );
        });
    } else {
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: false },
          bubbles: true,
          composed: true,
        }),
      );
    }
    this.formReady = true;
  }

  _setDeleteButtonState() {
    const button = this.$.querySelector('.delete-btn');
    if (button) {
      if (!this.announcementEditConfig) {
        button.setAttribute('disabled', '');
      } else if (this.announcementEditConfig && button.hasAttribute('disabled')) {
        button.removeAttribute('disabled');
      }
    }
  }

  _deleteCrudForm() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    const request = { id: this.announcementEditConfig.idAnnouncement };

    this.crudServices
      .callApiMethod('Announcement/RemoveAnnouncementById', request)
      .then(
        success => {
          if (success.data.data) {
            this.$.querySelector('power-toast#form-announcement-crud-toast').toggle(true);
            Object.assign(this, { toast: { text: 'Comunicado excluído' } });
            setTimeout(() => {
              this._backTo(1);
            }, 1500);
          } else {
            this.$.querySelector('power-toast#form-announcement-crud-toast').toggle(true);
            Object.assign(this, { toast: { text: 'Ocorreu um erro ao excluir o comunicado' } });
          }
        },
        () => {},
      )
      .finally(() =>
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        ),
      );
  }

  _validateNumberFields(item) {
    if (item.simpleValue == 0) {
      item.simpleValue = null;
    }

    return item.simpleValue;
  }

  _validateFields() {
    const emptyValues = [];
    this.formConfig.map(config => {
      if (config.sections) {
        config.sections.forEach(section => {
          if (section.items && section.items.length > 0) {
            section.items.map(item => {
              item.warning = '';
              if (item.required && !item.value && item.inputType != 'Boolean') {
                item.warning = 'Campo obrigatório';
                emptyValues.push(item);
              }

              if (item.field == 'midiaSource') {
                if (this.announcementType.value != AnnouncementTypeEnum.VIDEO) {
                  const fileList = this.$.querySelector(`#${item.field}-file-list`);
                  if (fileList) {
                    const imageList = fileList.getFileList();
                    if (imageList.length == 0) {
                      item.warning = 'Campo obrigatório';
                      emptyValues.push(item);
                    }
                  }
                }
              }

              if (
                item.field == 'mobileMidiasource' &&
                this.announcementType.value == AnnouncementTypeEnum.DESTAQUE
              ) {
                const fileList = this.$.querySelector(`#${item.field}-file-list`);
                if (fileList) {
                  const imageList = fileList.getFileList();
                  if (imageList.length == 0) {
                    item.warning = 'Campo obrigatório';
                    emptyValues.push(item);
                  }
                }
              }

              if (
                this.announcementType.value != AnnouncementTypeEnum.VIDEO &&
                item.field == 'link' &&
                item.typeLink.indexOf(this.typeLink) > -1
              ) {
                if (!item.value) {
                  item.warning = 'Campo obrigatório';
                  emptyValues.push(item);
                }
              }

              if (
                this.announcementType.value == AnnouncementTypeEnum.VIDEO &&
                item.field == 'announcementItems' &&
                !item.value
              ) {
                item.warning = 'Campo obrigatório';
                emptyValues.push(item);
              }

              if (
                this.announcementType.value == AnnouncementTypeEnum.VIDEO &&
                item.field == 'tagItems' &&
                (!item.value || item.value.length == 0)
              ) {
                item.warning = 'Campo obrigatório';
                emptyValues.push(item);
              }

              return item;
            });
          }
        });
      }
      return config;
    });
    if (emptyValues.length > 0) {
      return false;
    }
    return true;
  }

  _confirmCrudForm() {
    const parsedData = {};
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    // fields validation
    if (this._validateFields()) {
      if (this.announcementEditConfig) {
        parsedData.id = this.announcementEditConfig.idAnnouncement;
      }
      // recover form values
      this.formConfig.forEach(form =>
        form.sections.forEach(section =>
          section.items
            .filter(
              x =>
                x.value ||
                x.type == 'FileList' ||
                x.field == 'expirationDate' ||
                x.field == 'announcementItems' ||
                x.inputType == 'Boolean',
            )
            .map(item => {
              if (item.type === 'Chip') {
                parsedData[item.field] = item.value.map(data => ({ id: data.id }));
              } else if (item.type == 'FileList') {
                const fileList = this.$.querySelector(`#${item.field}-file-list`);
                if (fileList) {
                  const imageList = fileList.getFileList();
                  if (imageList.length != 0) {
                    parsedData[item.field] = imageList[0].contentBase64;
                  }
                }
              } else if (item.field == 'link') {
                if (this.typeLink == 1 && item.typeLink.indexOf(this.typeLink) > -1) {
                  parsedData.route = item.value;
                } else if (this.typeLink == 2 && item.typeLink.indexOf(this.typeLink) > -1) {
                  if (!item.value.includes('http') && !item.value.includes('https')) {
                    parsedData[item.field] = `https://${item.value}`;
                  } else {
                    parsedData[item.field] = item.value;
                  }
                } else if (item.typeLink.indexOf(this.typeLink) > -1) {
                  parsedData[item.field] = item.value;
                }
              } else if (item.field == 'announcementItems') {
                if (this.announcementEditConfig) {
                  parsedData[item.field] = item.value.filter(i => i.updating);
                  if (parsedData[item.field].length == 0) delete parsedData.announcementItems;
                } else if (this.announcementType.value != AnnouncementTypeEnum.VIDEO) {
                  const tipoComunicado =
                    this.announcementType.value == AnnouncementTypeEnum.DESTAQUE
                      ? 'Destaque'
                      : 'Card';
                  parsedData[item.field] = [
                    {
                      title: parsedData.title,
                      observation: `Item gerado automaticamente para comunicado tipo ${tipoComunicado}`,
                      active: true,
                    },
                  ];
                } else {
                  parsedData[item.field] = item.value;
                }

                if (item.value && item.value.filter(val => val.active == true).length == 0) {
                  parsedData.active = false;
                }
              } else if (item.field == 'expirationDate' && !item.value) {
                const expirationDate = new Date();
                expirationDate.setFullYear(2099);
                parsedData[item.field] = expirationDate;
              } else {
                parsedData[item.field] = item.value;
              }
              return parsedData;
            }),
        ),
      );
      if (this.announcementEditConfig) {
        this._updateAnnouncement(parsedData);
      } else {
        this._createAnnouncement(parsedData);
      }
    } else {
      this.$.querySelector('power-toast#form-announcement-crud-toast').toggle(true);
      Object.assign(this, {
        toast: {
          text: 'Algumas informações são obrigatórias e precisam ser preenchidas.',
        },
      });
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: false },
          bubbles: true,
          composed: true,
        }),
      );
    }
  }

  _updateAnnouncement(request) {
    this.crudServices
      .callApiMethod('Announcement/UpsertAnnouncements', request)
      .then(
        success => {
          if (success.status == 200 && success.data.data && success.data.hasError === false) {
            this.$.querySelector('power-toast#form-announcement-crud-toast').toggle(true);
            Object.assign(this, { toast: { text: 'Editado com sucesso!' } });
            setTimeout(() => {
              this._cancelCrudForm();
            }, 1500);
          } else {
            switch (success.status) {
              case 406:
              case 422:
                this.toastText = success.data.data.toString();
                break;
              default:
                this.toastText = success.data.data
                  ? success.data.data.toString()
                  : 'Ocorreu um erro ao salvar o comunicado';
                break;
            }
            this.$.querySelector('power-toast#form-announcement-crud-toast').toggle(true);
            Object.assign(this, { toast: { text: this.toastText } });
          }
        },
        () => {},
      )
      .finally(() =>
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        ),
      );
  }

  _createAnnouncement(request) {
    this.crudServices
      .callApiMethod('Announcement/UpsertAnnouncements', request)
      .then(
        success => {
          if (success.status == 200 && success.data.data && success.data.hasError === false) {
            this.$.querySelector('power-toast#form-announcement-crud-toast').toggle(true);
            Object.assign(this, { toast: { text: 'Comunicado adicionado.' } });
            setTimeout(() => {
              this._cancelCrudForm();
            }, 1500);
          } else {
            switch (success.status) {
              case 406:
              case 422:
                this.toastText = success.data.data.toString();
                break;
              default:
                this.toastText = success.data.data
                  ? success.data.data.toString()
                  : 'Ocorreu um erro ao salvar o comunicado';
                break;
            }
            this.$.querySelector('power-toast#form-announcement-crud-toast').toggle(true);
            Object.assign(this, { toast: { text: this.toastText } });
          }
        },
        () => {},
      )
      .finally(() =>
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        ),
      );
  }

  _cancelCrudForm() {
    this._backTo(1);
  }

  _backTo(index) {
    const backToState = this._reverseRouteList()[index];
    this.$ngRedux.dispatch({
      type: 'PREVIOUS_ROUTE',
      data: { index: this.state.routeList.length - 1 - index },
    });
    this.$state.go(backToState.routeLink, { tail: backToState.routeTail });
  }

  _reverseRouteList() {
    return Object.assign([], this.state.routeList).reverse();
  }

  _actionFunction(itemReturn) {
    switch (itemReturn.actionType) {
      case 'share': {
        const json = {};
        this.__callApiMethod(
          itemReturn.getDataMethod,
          json,
          {
            success: null,
            error: 'Ops! Erro ao carregar as informações',
          },
          true,
        ).then(result => {
          if (result && !result.hasError) {
            this.sharePopup = {
              open: true,
              title: itemReturn.actionTitle,
              icon: itemReturn.actionIcon,
              list: result.data,
              method: itemReturn.actionMethod,
              itemSelected: [],
              json: {
                type: this.stateConfig ? this.stateConfig.type : 'Announcement',
                objects: [
                  {
                    objectId: this.announcementEditConfig
                      ? this.announcementEditConfig.idAnnouncement
                      : '',
                  },
                ],
              },
              text: `${itemReturn.text} foi ${itemReturn.actionText}`,
              actionField: itemReturn.actionField,
            };

            this.formConfig
              .filter(config => config.sections)
              .map(config => config.sections)
              .reduce((acc, sections) => acc.concat(...sections), [])
              .filter(section => section.items && section.items.length > 0)
              .forEach(section => {
                section.items.forEach(item => {
                  if (item.field === itemReturn.actionField) {
                    if (item.value && item.value.length > 0) {
                      item.value.forEach(data => {
                        const selectedItem = this.sharePopup.list.find(
                          listItem => listItem.id === data.id,
                        );
                        if (selectedItem) {
                          selectedItem.extra = 'SELECIONADO';
                        }
                      });
                    }
                  }
                });
              });

            this.$.querySelector('#popup-share').toggle();
          }
        });
        break;
      }
      case 'video': {
        this.popupVideo = {
          open: true,
          popupTitle: itemReturn.actionTitle,
          fields: {
            videoId: null,
            title: null,
            midiaSource: null,
            duration: 0,
            platformId: 2,
            defaultThumbnailSource: null,
            active: true,
          },
          icon: itemReturn.actionIcon,
          method: itemReturn.actionMethod,
          json: {
            type: this.stateConfig ? this.stateConfig.type : 'Announcement',
            objects: [
              {
                objectId: this.announcementEditConfig
                  ? this.announcementEditConfig.idAnnouncement
                  : '',
              },
            ],
          },
          text: `${itemReturn.text} foi ${itemReturn.actionText}`,
          actionField: itemReturn.actionField,
          loadInfo: true,
        };
        this.$.querySelector('#popup-video').toggle();
        break;
      }
      default:
        break;
    }
  }

  __callApiMethod(method, messages, showLoader) {
    const msgSuccess = (messages && messages.success) || null;

    const msgError = (messages && messages.error) || null;

    if (showLoader)
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );

    return this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'POST',
    })
      .then(
        success => {
          if (success.status && success.status !== 200) {
            if (msgError) {
              this.toastObj = {
                text: msgError,
              };
              this.$.querySelector('#form-announcement-crud-toast').toggle(true);
            }
            return null;
          }
          if (success.data && !success.data.hasError) {
            if (msgSuccess) {
              this.toastObj = {
                text: msgSuccess,
              };
              this.$.querySelector('#form-announcement-crud-toast').toggle(true);
            }

            return success.data;
          }
          if (msgError) {
            this.toastObj = {
              text: msgError,
            };
            this.$.querySelector('#form-announcement-crud-toast').toggle(true);
          }

          return null;
        },
        error => {
          // eslint-disable-next-line no-console
          console.error(error);
          if (msgError) {
            this.toastObj = {
              text: msgError,
            };
            this.$.querySelector('#form-announcement-crud-toast').toggle(true);
          }

          return null;
        },
      )
      .finally(() => {
        if (showLoader)
          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          );
      });
  }

  _evtClickBtnPopup(type, popupId, dataset) {
    if (type == 'close') {
      switch (popupId) {
        case 'popup-share': {
          this.sharePopup.itemSelected = null;
          this.$.querySelector('#popup-share').toggle();
          break;
        }
        case 'popup-confirmation': {
          this.confirmationPopup.cancel();
          break;
        }
        case 'popup-video': {
          this.$.querySelector('#popup-video').toggle();
          break;
        }
        default: {
          break;
        }
      }
    } else if (type == 'primaryBtn') {
      switch (popupId) {
        case 'popup-share': {
          this.$.querySelector('#popup-share').toggle();
          this.formConfig
            .filter(config => config.sections)
            .map(config => config.sections)
            .reduce((acc, sections) => acc.concat(...sections), [])
            .filter(section => section.items && section.items.length > 0)
            .forEach(section => {
              section.items.forEach(item => {
                if (item.field === this.sharePopup.actionField) {
                  this.sharePopup.itemSelected.forEach(selectedItem => {
                    item.value.push({
                      id: selectedItem.id,
                      description: selectedItem.description,
                      extra: this.sharePopup.extra,
                      icon: selectedItem.icon,
                    });
                  });
                }
              });
            });

          break;
        }
        case 'popup-confirmation': {
          this.confirmationPopup.confirm();
          break;
        }
        case 'popup-video': {
          this.$.querySelector('#popup-video').toggle();
          this.formConfig
            .filter(config => config.sections)
            .map(config => config.sections)
            .reduce((acc, sections) => acc.concat(...sections), [])
            .filter(section => section.items && section.items.length > 0)
            .forEach(section => {
              section.items.forEach(item => {
                if (item.field === this.popupVideo.actionField) {
                  let video = this.popupVideo.fields;

                  const fileList = this.$.querySelector('#custom-thumbnail-file-list');
                  if (fileList) {
                    const imageList = fileList.getFileList();
                    if (imageList.length != 0) {
                      video.customThumbnailSrc = imageList[0].contentBase64;
                    }
                  }

                  if (!this.popupVideo.fields.updating) {
                    if (!item.value) {
                      item.value = [];
                    }
                    if (this.announcementEditConfig) {
                      video = { ...video, updating: true };
                    }
                    item.value.push(video);
                  } else {
                    item.value = item.value.map(obj => {
                      if (video.id == obj.id) {
                        return {
                          ...obj,
                          active: video.active,
                          defaultThumbnailSource: video.defaultThumbnailSource,
                          duration: video.duration,
                          midiaSource: video.midiaSource,
                          plataformCode: video.plataformCode,
                          platformId: video.platformId,
                          title: video.title,
                          updating: true,
                        };
                      }
                      return obj;
                    });
                  }
                }
              });
            });
          break;
        }
        default: {
          break;
        }
      }
    } else if (type == 'item') {
      if (this.sharePopup.itemSelected.find(item => item.id === dataset.id)) {
        this.sharePopup.itemSelected = this.sharePopup.itemSelected.filter(
          item => item.id !== dataset.id,
        );
      } else {
        this.sharePopup.itemSelected.push(dataset);
      }
    }
  }

  _popupItemSelected(item) {
    if (this.sharePopup.itemSelected) {
      return this.sharePopup.itemSelected.find(i => i.id === item.id);
    }
    return false;
  }

  _removeChipItem(itemToBeRemoved, list, msgQuestion, msgText, msgQuestionValue, msgTextValue) {
    this.$.querySelector('#popup-confirmation').toggle();
    this.confirmationPopup = {
      open: true,
      text:
        msgQuestion && msgQuestionValue != ''
          ? msgQuestion.split('<item>').join(msgQuestionValue)
          : msgQuestion || 'Deseja realmente executar esta ação?',
      subtext:
        msgText && msgTextValue != ''
          ? msgText.split('<item>').join(msgTextValue)
          : msgText || null,
      confirm: () => {
        this.formConfig.map(config => {
          if (config.sections) {
            config.sections.forEach(section => {
              if (section.items && section.items.length > 0) {
                section.items.map(item => {
                  if (item.field === list) {
                    item.value = item.value.filter(i => i.id !== itemToBeRemoved.id);
                    if (item.value.length == 0)
                      section.items.push({ field: 'clearTags', value: true, announcementtype: [] });
                  }

                  return item.value;
                });
              }
            });
          }
          return config;
        });

        this.$.querySelector('#popup-confirmation').toggle();
      },
      cancel: () => {
        this.$.querySelector('#popup-confirmation').toggle();
      },
    };
  }

  _FormatStringDate(data) {
    if (!data) return null;

    const day = data.split('/')[0];
    const month = data.split('/')[1];
    const year = data.split('/')[2];

    return `${year}-${`0${month}`.slice(-2)}-${`0${day}`.slice(-2)}`;
  }

  _showField(item) {
    if (item.field == 'link') {
      return item.typeLink.some(value => value === this.typeLink);
    }
    return item[this.announcementType.field].some(value => value == this.announcementType.value);
  }

  _changeFilterValue(item) {
    if (item.value) {
      if (item.field == this.announcementType.field) {
        this.announcementType.value = item.value;
      }
      if (item.field == 'linkType') {
        this.typeLink = item.value;
      }
    }
  }

  _getFieldName(item) {
    if (item.field == 'link' && this.typeLink != 1) {
      return this.typeLink == 2 ? 'Link Externo' : 'Vídeo';
    }
    if (item.name instanceof Object) {
      return item.name[this.announcementType.value];
    }
    return item.name;
  }

  _getVideoInfo(videoUrl) {
    if (
      this.popupVideo.loadInfo &&
      this.popupVideo.fields.midiaSource &&
      this.popupVideo.fields.midiaSource.includes('vimeo.com')
    ) {
      const apiUrl = `https://vimeo.com/api/oembed.json?url=${videoUrl}`;
      this.$http({
        url: apiUrl,
        method: 'GET',
      }).then(
        success => {
          if (success.data && success.status == 200) {
            const regex = /https[^"\r\n]*/;
            const match = success.data.html.match(regex);

            this.popupVideo.fields.midiaSource = `${match}&amp;dnt=1&amp;title=0&amp;portrait=0&amp;byline=0&amp;transparent=0`;
            this.popupVideo.fields.plataformCode = success.data.video_id;
            this.popupVideo.fields.title = success.data.title;
            this.popupVideo.fields.duration = success.data.duration;
            this.popupVideo.fields.platformId = 2;
            this.popupVideo.fields.defaultThumbnailSource = success.data.thumbnail_url;
          }
        },
        () => {
          this.popupVideo = {
            ...this.popupVideo,
            loadInfo: true,
            fields: {
              plataformCode: '',
              title: '',
              duration: '',
              platformId: '',
              defaultThumbnailSource: '',
            },
          };
          this.$.querySelector('power-toast#form-announcement-crud-toast').toggle(true);
          Object.assign(this, {
            toast: {
              text: 'Nenhum vídeo encontrado.',
            },
          });
        },
      );
    }
  }

  _parseMinute(time) {
    if (!time || time === 0) {
      return '00:00';
    }
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  _getBase64Image(imgUrl, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', imgUrl, false);
    xhr.send(null);

    xhr.onload = () => {
      const reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = () => callback(reader.result);
    };
  }

  _openVideo(item, video) {
    this.popupVideo = {
      open: true,
      popupTitle: item.action.actionTitle,
      actionField: item.action.actionField,
      fields: {
        id: video.id,
        plataformCode: video.plataformCode,
        videoId: video.videoId,
        title: video.title,
        midiaSource: video.midiaSource,
        duration: video.duration,
        platformId: 2,
        defaultThumbnailSource: video.defaultThumbnailSource,
        active: video.active,
        updating: true,
      },
      icon: item.action.actionIcon,
      method: item.action.actionMethod,
      json: {
        type: this.stateConfig ? this.stateConfig.type : 'Announcement',
        objects: [
          {
            objectId: this.announcementEditConfig ? this.announcementEditConfig.idAnnouncement : '',
          },
        ],
      },
      text: `${item.text} ${item.action.actionText}`,
      loadInfo: false,
    };
    this.$.querySelector('#popup-video').toggle();
  }

  _parseVideoUrl(url) {
    if (url) return url.substring(0, url.lastIndexOf('?'));
    return '';
  }

  _getItemHint(item) {
    if (item.field == 'announcementtype') {
      if (this.announcementType.value == AnnouncementTypeEnum.DESTAQUE) {
        return item.hint;
      }

      return '';
    }
    return item.hint;
  }

  _removeVideo(sectionItem, index) {
    let emptyList = false;
    this.formConfig.forEach(config => {
      if (config.sections) {
        config.sections.forEach(section => {
          if (section.items && section.items.length > 0) {
            section.items.forEach(item => {
              if (item.field == 'announcementItems') {
                if (item.value && item.value.length <= 1) {
                  Object.assign(this, {
                    toast: {
                      text: 'Item obrigatório! Não é possível remover todos vídeos do comunicado.',
                    },
                  });
                  this.$.querySelector('#form-announcement-crud-toast').toggle(true);
                  emptyList = true;
                  return '';
                }
              }
              return '';
            });
          }
        });
      }
    });

    if (emptyList) return null;

    this.$.querySelector('#popup-confirmation').toggle();
    this.confirmationPopup = {
      open: true,
      text:
        sectionItem.msgQuestion && sectionItem.msgQuestionValue != ''
          ? sectionItem.msgQuestion.split('<item>').join(sectionItem.msgQuestionValue)
          : sectionItem.msgQuestion || 'Deseja realmente executar esta ação?',
      subtext:
        sectionItem.msgText && sectionItem.msgTextValue != ''
          ? sectionItem.msgText.split('<item>').join(sectionItem.msgTextValue)
          : sectionItem.msgText || null,
      confirm: () => {
        this.formConfig.map(config => {
          if (config.sections) {
            config.sections.forEach(section => {
              if (section.items && section.items.length > 0) {
                section.items.forEach(item => {
                  if (item.field == 'announcementItems') {
                    if (item.value[index].id) {
                      this.$http({
                        url: `${this.urlApi}/Announcement/RemoveAnnouncementItemById`,
                        method: 'POST',
                        data: {
                          request: {
                            id: item.value[index].id,
                          },
                        },
                      }).then(
                        success => {
                          if (success.data && !success.data.hasError) {
                            if (item.msgSuccess) {
                              Object.assign(this, {
                                toast: {
                                  text: item.msgSuccess,
                                },
                              });
                              this.$.querySelector('#form-announcement-crud-toast').toggle(true);
                            }

                            return success.data;
                          }
                          if (item.msgError) {
                            Object.assign(this, {
                              toast: {
                                text: item.msgError,
                              },
                            });
                            this.$.querySelector('#form-announcement-crud-toast').toggle(true);
                          }
                          return '';
                        },
                        error => {
                          // eslint-disable-next-line no-console
                          console.error(error);
                          if (item.msgError) {
                            Object.assign(this, {
                              toast: {
                                text: item.msgError,
                              },
                            });
                            this.$.querySelector('#form-announcement-crud-toast').toggle(true);
                          }

                          return null;
                        },
                      );
                    }
                    item.value.splice(index, 1);
                  }
                });
              }
            });
          }
          return config;
        });
        this.$.querySelector('#popup-confirmation').toggle();
      },
      cancel: () => {
        this.$.querySelector('#popup-confirmation').toggle();
      },
    };
    return null;
  }
}

class GolfleetFormAnnouncementCrud {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetFormAnnouncementCrudController;
  }
}

angular
  .module('golfleet-form-announcement-crud', ['ng-tippy', 'power-toast', 'golfleet-file-list'])
  .component('golfleetFormAnnouncementCrud', new GolfleetFormAnnouncementCrud());
