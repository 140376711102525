import angular from 'angular';
import * as Comlink from 'comlink';
import 'angular-sanitize';

import { toDate } from '../../utils/date-util.js';
import '../../directives/ng-resize/ng-resize';

import { PowerGridController } from '../power-grid/power-grid';
import template from './power-grid-speed-limit.html';
import './power-grid-speed-limit.scss';

class PowerGridSpeedLimitController extends PowerGridController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$scope',
      '$state',
      '$http',
      '$timeout',
      '$filter',
      'commonServices',
      'urlApi',
    ];
  }

  constructor(
    $element,
    $ngRedux,
    $scope,
    $state,
    $http,
    $timeout,
    $filter,
    commonServices,
    urlApi,
  ) {
    super($element, $scope, $state, $http, $timeout, $filter, commonServices, urlApi, $ngRedux);

    this.vioBlockModalData = {};
    this.vioCustomizeModalData = {};
    this.checkViolationDesconsider = false;
    this.toastSpeedLimit = {
      text: null,
    };
    this.justificationInputValue = null;
    this.isSpeedLimitJustifyContent = false;
    this.hasReferenceId = false;
    this.isBlockSuccessContent = false;
    this.document = null;
    this.showHistoric = null;
    this.historic = [];
    this.vioConfirmButtonEnabled = false;
    this.vioBlockButtonEnabled = false;
    this.canSubmit = true;

    this.initialSpeedValue = null;
    this.initialSpeedSegmentValue = null;
    this.initialSpeedIsCustomLocalMode = false;
    this.initialSpeedIsAutomaticMode = false;
    this.initialSpeedIsSegmentMode = false;
    this.validateViolationCustomize = false;

    this.__appBehavior = $ngRedux.connect(behavior =>
      Object({
        permissions: behavior.session.permissions,
      }),
    )(this);

    if (this.worker) this.worker.terminate();

    this.worker = new Worker('./power-grid-speed-limit.worker.js');
    this.workerService = Comlink.wrap(this.worker);

    this.popupLimitTabSelected = 1;
    this.isImageExampleContent = false;
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();

    Object.assign(this.$, {
      updateGridSelectedRows: this.updateGridSelectedRows.bind(this),
    });

    this.$scope.$watch(
      () => this.vioCustomizeModalData.localMode,
      this.__onChangeSpeedLocalMode.bind(this),
    );
    this.$scope.$watch(
      () => this.vioCustomizeModalData.automaticMode,
      this.__onChangeSpeedAutomaticMode.bind(this),
    );
  }

  $onDestroy() {
    super.$onDestroy();

    this.__appBehavior();
  }
  /* */

  /* Public */
  async updateGridSelectedRows(occurrences, violationDesconsiderStatus) {
    await this.workerService.updateViolationConsideration({
      occurrences,
      violationDesconsiderStatus,
    });

    this.pageRows = await this.workerService.pageRows;
    this.selectedRows = await this.workerService.selectedRows;
    this.isAllSelected = await this.workerService.isAllSelected;

    if (!this.$scope.$$phase) {
      this.$scope.$apply();
    }
  }
  /* */

  /* Private */
  _getVisualizationFilter() {
    return this.stateConfig.filterConfig.find(
      i => i.type === 'select' && i.field === 'visualization',
    );
  }

  _goToLink(
    index,
    tableRowData,
    routeName,
    routeLink,
    stateFixedParams,
    linkStateConfig,
    getDataMethod,
    backPagination,
    fixedFilters,
  ) {
    if (!routeName && !routeLink) {
      return;
    }

    this.$scope.$emit('goToLink', {
      index,
      tableRowData,
      routeName,
      routeLink,
      stateFixedParams,
      linkStateConfig,
      getDataMethod,
      backPagination,
      fixedFilters,
    });
  }

  _formatStringData(date) {
    return date
      .toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(/\//g, '-');
  }

  _getLens(value, isInconsiderate) {
    // this is just a default value,
    // but we have no rules to stay green
    let violationColor = 'green';

    if (isInconsiderate) {
      violationColor = 'gray'; // desconsiderado
    } else if (value <= 20) {
      violationColor = 'orange'; // média
    } else if (value > 20 && value <= 50) {
      violationColor = 'red'; // grave
    } else if (value > 50) {
      violationColor = 'brown'; // gravíssima
    }

    return violationColor;
  }

  _clickCheckboxViolation() {
    this.vioConfirmButtonEnabled = !this.vioConfirmButtonEnabled;
    this.checkViolationDesconsider = !this.checkViolationDesconsider;
  }

  _canEdit() {
    return this.permissions.includes('EDIT_SPEED_LIMIT');
  }

  _switchBlockContent() {
    this.isBlockSuccessContent = !this.isBlockSuccessContent;
  }

  _updateConfirmButtonClass() {
    let className = 'confirm';
    if (!this.vioConfirmButtonEnabled) {
      className += ' disabled';
    }

    return className;
  }

  _updateConfirmButtonClassViolationCustomize() {
    let className = 'confirm';
    this.canSubmit = true;
    if (this.initialSpeedIsAutomaticMode !== this.vioCustomizeModalData.automaticMode) {
      return className;
    }
    if (this.initialSpeedIsCustomLocalMode !== this.vioCustomizeModalData.localMode) {
      return className;
    }
    if (
      this.vioCustomizeModalData.localMode &&
      this.initialSpeedValue !== this.vioCustomizeModalData.vVia
    ) {
      return className;
    }
    if (this.initialSpeedIsSegmentMode !== this.vioCustomizeModalData.segmentMode) {
      return className;
    }
    if (
      this.vioCustomizeModalData.segmentMode &&
      this.initialSpeedSegmentValue !== this.vioCustomizeModalData.velocidadeSegmento
    ) {
      return className;
    }
    className += ' disabled';
    this.canSubmit = false;
    return className;
  }

  _evtClickModalVvia(data, modalType, action) {
    switch (modalType) {
      case 'customize':
        if (data) {
          this.vioCustomizeModalData = {
            via: data.rua,
            municipio: data.municipio,
            estado: data.estado,
            vVia: data.velocidadeVia,
            velocidadeSegmento: data.velocidadeVia,
            referenceId: data.referenceId,
            cVvia: data.isCustomized,
            defaultData: data,
          };
          if (data.referenceId === undefined || data.referenceId === null) {
            this.hasReferenceId = false;
          } else {
            this.hasReferenceId = true;
          }
        }

        switch (action) {
          case 'open':
            this.popupLimitTabSelected = 1;
            this.isImageExampleContent = false;
            this.isSpeedLimitJustifyContent = false;
            this.$.querySelector('.tabclass').setAttribute('tab', 1);

            this.$http({
              url: `${this.urlApi}/SpeedLimitModule/GetStreetSpeedLimitHistoric`,
              method: 'POST',
              data: {
                request: {
                  streetId: this.vioCustomizeModalData.defaultData.streetId,
                  cityId: this.vioCustomizeModalData.defaultData.cityId,
                  vehicleId: this.vioCustomizeModalData.defaultData.veiId,
                  referenceId: this.vioCustomizeModalData.defaultData.referenceId,
                  dateTime: this._formatStringData(
                    new Date(this.vioCustomizeModalData.defaultData.dataHora),
                  ),
                },
              },
            }).then(success => {
              const response = success.data.data;

              if ((success.status && success.status !== 200) || !response) {
                this.toastSpeedLimit.text =
                  'Ocorreu um erro ao processar sua solicitação, por favor tente novamente';
                this.$.querySelector('#toast-speedlimit').toggle(true);

                return;
              }

              this.vioCustomizeModalData.vVia = data.velocidadeVia;

              this.vioCustomizeModalData.initialSpeedValue = data.velocidadeVia;
              this.initialSpeedValue = data.velocidadeVia;

              this.showHistoric = response.data.showHistoric;
              this.document = response.data.document;
              this.historic = response.data.historic;

              this.historic.forEach(item => {
                item.dateTime = this._toDate(item.dateTime);
              });

              const activeHistoric = this.historic.filter(item => item.isActive);
              const activeStreetHistoric = activeHistoric.filter(
                item => !item.isReferenceHistory,
              )[0];
              const activeSegmentHistoric = activeHistoric.filter(
                item => item.isReferenceHistory,
              )[0];

              if (activeStreetHistoric) {
                if (activeStreetHistoric.speedTrack) {
                  this.vioCustomizeModalData.vVia = activeStreetHistoric.speedTrack;
                  this.initialSpeedValue = activeStreetHistoric.speedTrack;
                  this.vioCustomizeModalData.localMode = true;
                  this.vioCustomizeModalData.automaticMode = false;
                  this.initialSpeedIsAutomaticMode = false;
                  this.initialSpeedIsCustomLocalMode = true;
                } else {
                  this.vioCustomizeModalData.localMode = false;
                  this.vioCustomizeModalData.automaticMode = true;
                  this.initialSpeedIsAutomaticMode = true;
                  this.initialSpeedIsCustomLocalMode = false;
                }
              } else {
                this.vioCustomizeModalData.automaticMode = true;
                this.initialSpeedIsAutomaticMode = true;
                this.initialSpeedIsCustomLocalMode = false;
              }

              if (activeSegmentHistoric) {
                if (activeSegmentHistoric.speedTrack) {
                  this.vioCustomizeModalData.velocidadeSegmento = activeSegmentHistoric.speedTrack;
                  this.initialSpeedSegmentValue = activeSegmentHistoric.speedTrack;
                  this.vioCustomizeModalData.segmentMode = true;
                  this.initialSpeedIsSegmentMode = true;
                } else {
                  this.vioCustomizeModalData.velocidadeSegmento = this.vioCustomizeModalData.vVia;
                  this.vioCustomizeModalData.segmentMode = false;
                  this.initialSpeedIsSegmentMode = false;
                }
              } else {
                this.vioCustomizeModalData.segmentMode = false;
                this.initialSpeedIsSegmentMode = false;
              }

              this.validateViolationCustomize = true;
            });
            this.$.querySelector('#popup-violation-customize').toggle();
            break;

          case 'cancel':
            this.$.querySelector('#popup-violation-customize').toggle();

            setTimeout(() => {
              this.justificationInputValue = null;
              this.historic = [];
              this.vioConfirmButtonEnabled = false;
              this.isSpeedLimitJustifyContent = false;
              this.validateViolationCustomize = false;
              this.initialSpeedValue = null;
              this.initialSpeedIsCustomLocalMode = false;
            }, 300);
            break;

          case 'apply':
            if (!this.canSubmit) {
              return;
            }
            if (this.vioCustomizeModalData.localMode) {
              if (!this.vioCustomizeModalData.vVia) {
                this.toastSpeedLimit.text =
                  'O campo da nova velocidade máxima por município precisa ser preenchido';
                this.$.querySelector('#toast-speedlimit').toggle(true);
                return;
              }
              if (this.vioCustomizeModalData.vVia < 20) {
                this.toastSpeedLimit.text =
                  'O valor da nova velocidade máxima por município não pode ser menos de 20 km/h';
                this.$.querySelector('#toast-speedlimit').toggle(true);
                return;
              }
              if (this.vioCustomizeModalData.vVia > 150) {
                this.toastSpeedLimit.text =
                  'O valor da nova velocidade máxima por município não pode exceder 150 km/h';
                this.$.querySelector('#toast-speedlimit').toggle(true);
                return;
              }
            }

            if (this.vioCustomizeModalData.segmentMode) {
              if (!this.vioCustomizeModalData.velocidadeSegmento) {
                this.toastSpeedLimit.text =
                  'O campo da nova velocidade máxima por segmento precisa ser preenchido';
                this.$.querySelector('#toast-speedlimit').toggle(true);
                return;
              }
              if (this.vioCustomizeModalData.velocidadeSegmento < 20) {
                this.toastSpeedLimit.text =
                  'O valor da nova velocidade máxima por segmento não pode ser menos de 20 km/h';
                this.$.querySelector('#toast-speedlimit').toggle(true);
                return;
              }
              if (this.vioCustomizeModalData.velocidadeSegmento > 150) {
                this.toastSpeedLimit.text =
                  'O valor da nova velocidade máxima por segmento não pode exceder 150 km/h';
                this.$.querySelector('#toast-speedlimit').toggle(true);
                return;
              }
            }

            this.isSpeedLimitJustifyContent = true;
            break;
          case 'confirm':
            // if (!this.vioConfirmButtonEnabled) {
            //   return;
            // }
            if (!this.vioCustomizeModalData.justificativa) {
              this.toastSpeedLimit.text = 'O campo de justificativa precisa ser preenchido';
              this.$.querySelector('#toast-speedlimit').toggle(true);
              return;
            }
            if (this.vioCustomizeModalData.justificativa.length > 500) {
              this.toastSpeedLimit.text = 'A justificativa não pode exceder 500 caractéres';
              this.$.querySelector('#toast-speedlimit').toggle(true);
              return;
            }
            if (this.vioCustomizeModalData.justificativa.length < 10) {
              this.toastSpeedLimit.text = 'A justificativa precisa ter pelo menos 10 caractéres';
              this.$.querySelector('#toast-speedlimit').toggle(true);
              return;
            }

            this.vioCustomizeModalData.vVia = parseInt(this.vioCustomizeModalData.vVia, 10);
            this.vioCustomizeModalData.velocidadeSegmento = parseInt(
              this.vioCustomizeModalData.velocidadeSegmento,
              10,
            );

            if (!this.canSubmit) {
              return;
            }

            this.canSubmit = false;

            this.$.dispatchEvent(
              new CustomEvent('toggleLoader', {
                detail: { showLoader: true },
                bubbles: true,
                composed: true,
              }),
            );

            this.$http({
              url: `${this.urlApi}/SpeedLimitModule/UpdateStreetSpeedLimit`,
              method: 'POST',
              data: {
                request: {
                  streetId: this.vioCustomizeModalData.defaultData.streetId,
                  cityId: this.vioCustomizeModalData.defaultData.cityId,
                  maxSpeed: this.vioCustomizeModalData.localMode
                    ? this.vioCustomizeModalData.vVia
                    : null,
                  maxSegmentSpeed: this.vioCustomizeModalData.segmentMode
                    ? this.vioCustomizeModalData.velocidadeSegmento
                    : null,
                  referenceId: this.vioCustomizeModalData.referenceId,
                  justification: this.vioCustomizeModalData.justificativa,
                },
              },
            }).then(success => {
              const response = success.data.data;

              this.$.dispatchEvent(
                new CustomEvent('toggleLoader', {
                  detail: { showLoader: false },
                  bubbles: true,
                  composed: true,
                }),
              );

              this.canSubmit = true;

              if ((success.status && success.status !== 200) || !response) {
                this.toastSpeedLimit.text =
                  'Ocorreu um erro ao processar sua solicitação, por favor tente novamente';
                this.$.querySelector('#toast-speedlimit').toggle(true);
              } else {
                this.toastSpeedLimit.text = 'Nova configuração de limite da via salvo com sucesso!';
                this.$.querySelector('#toast-speedlimit').toggle(true);
                this.validateViolationCustomize = false;
                this.$.querySelector('#popup-violation-customize').toggle();
              }
            });

            break;
          case 'back':
            this.isImageExampleContent = false;
            this.isSpeedLimitJustifyContent = false;
            break;
          default:
            break;
        }

        break;

      case 'block':
        if (data) {
          this.vioBlockModalData = {
            placa: data.veiculo,
            via: data.rua,
            vioType: data.descInfracao.split(' ')[1].toUpperCase(),
            vVia: data.velocidadeVia,
            vVehicle: data.velocidadeVeiculo,
            vioClassColor: this._getLens(data.percentualAcimaVia),
            date: new Date(data.dataHora).toLocaleString('pt-BR', {
              timeZone: 'America/Sao_Paulo',
            }),
            defaultData: data,
          };
        }

        switch (action) {
          case 'open':
            this.$http({
              url: `${this.urlApi}/SpeedLimitModule/GetViolationStatusHistoric`,
              method: 'POST',
              data: {
                request: {
                  occurrenceId: this.vioBlockModalData.defaultData.occurrenceId,
                  vehicleId: this.vioBlockModalData.defaultData.veiId,
                },
              },
            }).then(success => {
              const response = success.data.data;

              if ((success.status && success.status !== 200) || !response) {
                this.toastSpeedLimit.text =
                  'Ocorreu um erro ao processar sua solicitação, por favor tente novamente';
                this.$.querySelector('#toast-speedlimit').toggle(true);

                return;
              }

              this.checkViolationDesconsider = this.vioBlockModalData.defaultData.isInconsiderate;

              this.document = response.data.document;
              this.historic = response.data.historic;

              this.historic.forEach(item => {
                item.dateTime = this._toDate(item.dateTime);
              });
            });

            this.$.querySelector('#popup-violation-block').toggle();
            break;

          case 'cancel':
            this.checkViolationDesconsider = false;
            this.justificationInputValue = null;
            this.historic = [];

            this.$.querySelector('#popup-violation-block').toggle();

            setTimeout(() => {
              this.vioConfirmButtonEnabled = false;
              this.isBlockSuccessContent = false;
            }, 300);
            break;

          case 'confirm':
            if (!this.vioConfirmButtonEnabled) {
              return;
            }

            if (!this.justificationInputValue) {
              this.toastSpeedLimit.text = 'O campo de justificativa precisa ser preenchido';
              this.$.querySelector('#toast-speedlimit').toggle(true);
              return;
            }
            if (this.justificationInputValue.length > 500) {
              this.toastSpeedLimit.text = 'A justificativa não pode exceder 500 caractéres';
              this.$.querySelector('#toast-speedlimit').toggle(true);
              return;
            }
            if (this.justificationInputValue.length < 10) {
              this.toastSpeedLimit.text = 'A justificativa precisa ter pelo menos 10 caractéres';
              this.$.querySelector('#toast-speedlimit').toggle(true);
              return;
            }

            if (!this.canSubmit) {
              return;
            }

            this.canSubmit = false;

            this.$.dispatchEvent(
              new CustomEvent('toggleLoader', {
                detail: { showLoader: true },
                bubbles: true,
                composed: true,
              }),
            );

            this.$http({
              url: `${this.urlApi}/SpeedLimitModule/UpdateViolationStatus`,
              method: 'POST',
              data: {
                request: {
                  occurrenceId: this.vioBlockModalData.defaultData.occurrenceId,
                  isConsiderate: !this.checkViolationDesconsider,
                  reason: this.justificationInputValue,
                },
              },
            }).then(success => {
              const response = success.data.data;

              this.$.dispatchEvent(
                new CustomEvent('toggleLoader', {
                  detail: { showLoader: false },
                  bubbles: true,
                  composed: true,
                }),
              );

              this.canSubmit = true;

              if ((success.status && success.status !== 200) || !response) {
                this.toastSpeedLimit.text =
                  'Ocorreu um erro ao processar sua solicitação, por favor tente novamente';
                this.$.querySelector('#toast-speedlimit').toggle(true);

                return;
              }

              this.updateGridSelectedRows(
                [this.vioBlockModalData.defaultData.occurrenceId],
                this.checkViolationDesconsider,
              );
              this._switchBlockContent();
            });

            break;

          default:
            break;
        }
        break;

      default:
        break;
    }
  }

  _selectTab(tab) {
    this.tabs.forEach(item => {
      item.selected = tab.name === item.name;
    });
  }

  _getSelectedTab() {
    return this.tabs.filter(item => item.selected)[0].name;
  }

  _focusSpeedInput() {
    this.$.querySelector('#speed-value').focus();
  }

  _selectRow(column, row) {
    return (
      super._selectRow(column, row) ||
      ((column.canCustomize || column.canConfigurate) && this._canEdit()) ||
      (column.type === 'Icon' && column.link)
    );
  }

  _toDate(date) {
    return toDate(date);
  }

  _evtClickPopupTab(tabNumber) {
    if (tabNumber !== this.popupLimitTabSelected) {
      this.popupLimitTabSelected = tabNumber;
      this.$.querySelector('.tabclass').setAttribute('tab', tabNumber);
    }
  }

  _showImageExample() {
    this.isImageExampleContent = true;
  }
  /* */

  /* Observers */
  __onChangeSpeedLocalMode() {
    if (!this.validateViolationCustomize) return;
    if (this.vioCustomizeModalData.localMode) {
      this.vioCustomizeModalData.automaticMode = false;
    } else if (!this.vioCustomizeModalData.automaticMode && !this.vioCustomizeModalData.localMode) {
      this.vioCustomizeModalData.automaticMode = true;
    }
  }

  __onChangeSpeedAutomaticMode() {
    if (!this.validateViolationCustomize) return;
    if (this.vioCustomizeModalData.automaticMode) {
      this.vioCustomizeModalData.localMode = false;
    } else if (!this.vioCustomizeModalData.automaticMode && !this.vioCustomizeModalData.localMode) {
      this.vioCustomizeModalData.automaticMode = true;
    }
  }
  /* */
}

class PowerGridSpeedLimit {
  constructor() {
    this.template = template;
    this.bindings = {
      /* common */
      datasetMethod: '=?',
      isPaginated: '=?',
      page: '=?',
      pageSize: '=?',
      pageRows: '=?',
      lastPage: '=?',
      datasetLength: '=?',
      hasRowSelection: '=?',
      dateGranularity: '=?',
      /* underscore */
      gridHeaders: '=?',
      gridDataset: '=?',
      gridHeadersCategories: '=?',
      sortHeader: '=?',
      sortDirection: '=?',
      selectedRows: '=?',
      /* duble underscore */
      mainHeader: '=?',
      mongoGridId: '=?',
      headerParams: '=?',
      datasetParams: '=?',
    };
    this.controller = PowerGridSpeedLimitController;
  }
}

angular
  .module('power-grid-speed-limit', ['ngSanitize', 'power-popup', 'power-toast', 'ng-resize'])
  .component('powerGridSpeedLimit', new PowerGridSpeedLimit());

export { PowerGridSpeedLimitController };
