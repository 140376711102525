import angular from 'angular';
import 'ng-redux';

import '@power/power-components/components/power-toast/power-toast';
import '../golfleet-popup-golfleet-id-app-invite/golfleet-popup-golfleet-id-app-invite';
import '../golfleet-popup-driver-privacy-policy/golfleet-popup-driver-privacy-policy';
import '../golfleet-popup-golfleet-id-privacy-policy/golfleet-popup-golfleet-id-privacy-policy';
import '../golfleet-popup-driver-app-invite/golfleet-popup-driver-app-invite';
import '../golfleet-file-list-options/golfleet-file-list-options';
import '@power/power-components/components/power-crud-bar-select/power-crud-bar-select';
import '@power/power-components/components/power-crud-signature/power-crud-signature';
import '@power/power-components/components/power-crud-signature-photo/power-crud-signature-photo';

import template from './golfleet-form-checklist.html';
import './golfleet-form-checklist.scss';

class GolfleetFormChecklistController {
  static get $inject() {
    return [
      '$element',
      '$sce',
      '$ngRedux',
      '$rootScope',
      '$state',
      '$http',
      '$scope',
      'commonServices',
      'urlApi',
      'crudServices',
      '$stateParams',
    ];
  }

  constructor(
    $element,
    $sce,
    $ngRedux,
    $rootScope,
    $state,
    $http,
    $scope,
    commonServices,
    urlApi,
    crudServices,
    $stateParams,
  ) {
    Object.assign(this, {
      $: $element[0],
      $sce,
      $ngRedux,
      $rootScope,
      $state,
      $http,
      $scope,
      commonServices,
      urlApi,
      crudServices,
      $stateParams,
    });

    this.__appInheritBehavior = $ngRedux.connect(behavior => {
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];
      return Object({
        /* Session Storage */
        state: behavior.state,
        session: behavior.session,

        /* State Storage */
        stateConfig: currentState ? currentState.stateConfig : {},
      });
    })(this);

    this.btnAppDriverInviteChecked = false;
    this.btnAppGolfleetIdChecked = false;

    this.status = 0;

    this.isEditing = false;
  }

  $onInit() {
    this.dataset = Object.clone({}, this.crudDataset);

    this.$scope.$on('UPDATE_ROUTE', () => this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' }));

    this._getFormConfig();
    this._checkReady();

    this.$scope.$on('triggerRequest', (event, data) => {
      if (data.triggerOf === 'changedVehicle') {
        this._getChecklist(data.triggerValue, null);
      } else {
        this.__dateChanged(data.triggerValue, this.checklist.vehicleId, data.triggerOf);
      }
    });
  }

  _getFormConfig() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    this.commonServices.getFormConfiguration('Checklist').then(data => {
      if (data.form) {
        this.formConfig = data.form;
        setTimeout(() => {
          this.formConfig.forEach(mainSection => {
            mainSection.sections.forEach(section => {
              section.items.forEach(item => {
                item.disabledStatus = item.editableStatus && item.editableStatus !== this.status;

                if (item.field === 'vehicleId') {
                  item.disabledStatus = false;
                }
              });
            });
          });

          this._requestUpdate();
          this.formConfigReady = true;

          if (this.$stateParams.tail) {
            this._getChecklist(null, this.$stateParams.tail);
          } else {
            this.getChecklistReady = true;
          }
        }, 300);
      }
    });
  }

  _checkReady() {
    setTimeout(() => {
      if (this.formConfigReady && this.getChecklistReady) {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
        this.$scope.$apply();
      } else {
        this._checkReady();
      }
    }, 300);
  }

  /* Private */
  _getChecklist(vehicleId, checklistId) {
    if (!this.isEditing) {
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );
      if (vehicleId === undefined) {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
      } else {
        this.crudServices
          .getData('Checklist/GetChecklist', {
            vehicleId,
            checklistId,
          })
          .then(result => {
            this.checklist = result;
            this.status = this.checklist.statusId;
            this.checklistIdPad = `${this.checklist.id}`.padStart(4, '0');
            setTimeout(() => {
              this.formConfig.forEach(mainSection => {
                mainSection.sections.forEach(section => {
                  section.items.forEach(item => {
                    item.disabledStatus =
                      item.editableStatus && item.editableStatus !== this.status;

                    if (item.field === 'vehicleId' && checklistId) {
                      item.disabledStatus = true;
                      this.isEditing = true;
                    }

                    item.warning = '';

                    if (this.checklist[item.field]) {
                      item.value = this.checklist[item.field];

                      if (item.type === 'combobox' && item.value) {
                        item.options.forEach(option => {
                          option.selected = option.value == item.value;
                        });
                      }

                      if (item.type === 'signature') {
                        item.signatureUrl = this.checklist[`${item.field}Url`];
                      }

                      if (item.type === 'signaturePhoto') {
                        item.signaturePhotoUrl = this.checklist[`${item.field}Url`];
                      }

                      if (item.inputType === 'datetime') {
                        item.value = new Date(item.value);
                      }
                    } else {
                      item.value = item.type === 'FileListOptions' ? [] : undefined;
                      if (item.type === 'combobox') {
                        item.options.forEach(option => {
                          option.selected = false;
                        });
                      }

                      if (item.type === 'signature') {
                        item.signatureUrl = undefined;
                      }

                      if (item.type === 'signaturePhoto') {
                        item.signaturePhotoUrl = undefined;
                      }

                      if (item.field === 'userName') {
                        item.value = this.session.userName;
                      }
                    }
                  });
                });
              });

              this._requestUpdate();
              this.getChecklistReady = true;
              this.$.dispatchEvent(
                new CustomEvent('toggleLoader', {
                  detail: { showLoader: false },
                  bubbles: true,
                  composed: true,
                }),
              );
            }, 300);
          });
      }
    }
  }

  _cancelCrudForm() {
    this._backTo(1);
  }

  _backTo(index) {
    const backToState = this._reverseRouteList()[index];
    this.$ngRedux.dispatch({
      type: 'PREVIOUS_ROUTE',
      data: { index: this.state.routeList.length - 1 - index },
    });
    this.$state.go(backToState.routeLink, { tail: backToState.routeTail });
  }

  _reverseRouteList() {
    return Object.assign([], this.state.routeList).reverse();
  }

  _clearForm() {
    this.formConfig.forEach(mainSection => {
      mainSection.sections.forEach(section => {
        section.items.forEach(item => {
          item.value = item.type === 'FileListOptions' ? [] : null;
          if (item.type === 'combobox' && item.field !== 'vehicleId') {
            item.options.forEach(option => {
              option.selected = false;
            });
          }
        });
      });
    });
  }

  _saveCrudForm(isSignature) {
    if (this._validateFields(isSignature, true)) {
      const parsedData = {};
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );
      // recover form values
      parsedData.id = this.checklist.id;
      parsedData.statusId = this.status;

      if (isSignature) {
        parsedData.statusId += 1;
      }

      this.formConfig.forEach(form =>
        form.sections.forEach(section =>
          section.items
            .filter(i => i.value || i.field === 'removalFuelLevel' || i.field === 'returnFuelLevel')
            .forEach(item => {
              parsedData[item.field] = item.value;
            }),
        ),
      );
      this.crudServices.callApiMethod('Checklist/UpsertChecklist', parsedData).then(
        success => {
          if (
            success &&
            success.status == 200 &&
            success.data &&
            success.data.data &&
            success.data.data.success
          ) {
            const signatureText =
              parsedData.statusId === 2 ? 'Retirada concluída' : 'Retorno concluído';

            const toast = isSignature ? signatureText : 'Rascunho salvo';

            this.$.querySelector('power-toast#form-checklist-toast').toggle(true);

            Object.assign(this, { toast: { text: toast } });

            setTimeout(() => {
              this._cancelCrudForm();
            }, 1500);
          } else {
            if (success && success.data && success.data.data && !success.data.data.success) {
              this.toastText = success.data.data.errorMessage;
            } else {
              this.toastText = 'Ocorreu um erro ao salvar o checklist';
            }

            this.$.querySelector('power-toast#form-checklist-toast').toggle(true);

            Object.assign(this, { toast: { text: this.toastText } });

            this.$.dispatchEvent(
              new CustomEvent('toggleLoader', {
                detail: { showLoader: false },
                bubbles: true,
                composed: true,
              }),
            );
          }
        },
        () => {},
      );
    }
  }

  _requestUpdate() {
    if (!this.$scope.$$phase && !this.$rootScope.$$phase) {
      this.$scope.$apply();
    }
  }

  __dateChanged(dateTime, vehicleId, field) {
    if (
      (field === 'removalOdometer' && this.status === 1) ||
      (field === 'returnOdometer' && this.status === 2)
    ) {
      if (dateTime) {
        const payload = {
          dateTime,
          vehicleId,
        };
        const item = this._getItemByField(field);
        if (this.getChecklistReady) {
          // item && !item.value
          this.crudServices.getData('Vehicle/GetOdometerByDateTime', payload).then(success => {
            if (success) {
              item.value = success.odometer;
            }
          });
        }
      }
    }
  }

  _getItemByField(field) {
    let item;
    this.formConfig.forEach(form =>
      form.sections.forEach(section =>
        section.items
          .filter(i => i.field === field)
          .forEach(i => {
            item = i;
          }),
      ),
    );
    return item;
  }

  _validateFields(isSignature) {
    const emptyValues = [];
    this.formConfig.map(config => {
      if (config.sections) {
        config.sections.forEach(section => {
          if (section.items && section.items.length > 0) {
            section.items.map(item => {
              item.warning = '';
              if (item.required && !item.value && item.editableStatus === this.status) {
                item.warning = 'Campo obrigatório';
                emptyValues.push(item);
              } else if (item.field === 'returnDateTime') {
                if (!this._validateDates(item)) {
                  emptyValues.push(item);
                }
              }
              if (
                isSignature &&
                item.requiredToSignature &&
                !item.value &&
                item.editableStatus === this.status
              ) {
                item.warning = 'Campo obrigatório';
                emptyValues.push(item);
              }

              return item;
            });
          }
        });
      }
      return config;
    });
    if (emptyValues.length > 0) {
      return false;
    }
    return true;
  }

  _validateDates(returnItem) {
    const removalItem = this._getItemByField('removalDateTime');
    if (returnItem.value <= removalItem.value) {
      returnItem.warning = 'Data não pode ser anterior à retirada';
      return false;
    }
    return true;
  }

  _validateForDisableSaveButton() {
    const emptyValues = [];
    this.formConfig.map(config => {
      if (config.sections) {
        config.sections.forEach(section => {
          if (section.items && section.items.length > 0) {
            section.items.map(item => {
              if (item.requiredToSignature && item.value && item.editableStatus === this.status) {
                emptyValues.push(item);
              }

              return item;
            });
          }
        });
      }
      return config;
    });
    if (emptyValues.length > 0) {
      return true;
    }
    return false;
  }

  _disabledSaveButton() {
    return (this.status !== 1 && this.status !== 2) || this._validateForDisableSaveButton();
  }

  _disabledCompleteButton() {
    return this.status !== 1 && this.status !== 2;
  }
  /* */
}

class GolfleetFormChecklist {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetFormChecklistController;
  }
}

angular
  .module('golfleet-form-checklist', [
    'ng-tippy',
    'power-toast',
    'golfleet-file-list-options',
    'power-crud-bar-select',
    'power-crud-signature',
    'power-crud-signature-photo',
  ])
  .component('golfleetFormChecklist', new GolfleetFormChecklist());
