import angular from 'angular';

import './ng-resize.scss';

class NgResizeLink {
  constructor($scope, element) {
    Object.assign(this, { $: element[0], $scope });

    const arrowLeftIcon = document.createElement('i');
    arrowLeftIcon.classList.add('material-icons');
    arrowLeftIcon.innerText = 'keyboard_arrow_left';

    const arrowRightIcon = document.createElement('i');
    arrowRightIcon.classList.add('material-icons');
    arrowRightIcon.innerText = 'keyboard_arrow_right';

    const container = document.createElement('div');
    container.classList.add('ng-resize-container');

    this.resizeElement = document.createElement('button');
    this.resizeElement.setAttribute('ng-resize-handler', '');

    this.resizeElement.appendChild(arrowLeftIcon);
    this.resizeElement.appendChild(arrowRightIcon);
    container.appendChild(this.resizeElement);
    this.$.appendChild(container);

    this.resizeElement.addEventListener('mousedown', this.__onResizeStart.bind(this));
    this.resizeElement.addEventListener('touchstart', this.__onResizeStart.bind(this));
  }

  _updateWidth(position) {
    const { x: leftOffset } = this.$.getBoundingClientRect();

    this.$.style.setProperty('--width', `${position - leftOffset}px`);
  }

  __onResizeStart(resizeEvent) {
    const ctrl = this;

    let moveEvent;
    let upEvent;
    let getPageX;

    if (resizeEvent instanceof TouchEvent) {
      moveEvent = 'touchmove';
      upEvent = 'touchend';
      getPageX = e => e.touches[0].pageX;
    } else {
      moveEvent = 'mousemove';
      upEvent = 'mouseup';
      getPageX = e => e.pageX;
    }
    if (resizeEvent.cancelable) {
      resizeEvent.preventDefault();
    }

    const moveResize = evt => {
      this._updateWidth(getPageX(evt));
    };

    const __onMoveEvent = evt => {
      this.didResize = true;
      moveResize(evt);
      this.$.dispatchEvent(new CustomEvent('resizeMoved'));
    };

    document.addEventListener(moveEvent, __onMoveEvent);
    moveResize(resizeEvent);

    document.addEventListener(upEvent, function __onUpEvent() {
      document.removeEventListener(moveEvent, __onMoveEvent);

      this.removeEventListener(upEvent, __onUpEvent);

      ctrl.$.dispatchEvent(new CustomEvent('resizeEnded'));
    });

    this.$.dispatchEvent(new CustomEvent('resizeStarted'));
  }
}

class NgResize {
  static get $$ngIsClass() {
    return true;
  }

  constructor() {
    this.restrict = 'A';
  }

  link($scope, element) {
    return new NgResizeLink($scope, element);
  }
}

angular.module('ng-resize', []).directive('ngResize', NgResize);
