/* global L: true isIframe: true */
import { PowerMapLeafletProvider } from '@power/power-components/components/power-map/providers/leaflet/power-map.leaflet-provider';
import '@power/power-components/helpers/is-iframe/is-iframe';
import './golfleet-map-fuel-supply-management.leaflet-provider.scss';

class GolfleetMapFuelSupplyManagementLeafletProvider extends PowerMapLeafletProvider {
  // eslint-disable-next-line no-useless-constructor
  constructor(context, $element, $ngRedux, $scope, $http, urlApi) {
    super(context, $element, $ngRedux, $scope, $http, urlApi);
  }

  /* Private */
  _geoJsonToMarker({ geoJson }) {
    return L.geoJSON(geoJson, {
      pointToLayer: (feature, { lat, lng }) =>
        L.marker(
          { lat, lng },
          {
            ...feature.properties,
            icon: this._createMarkerIcon(feature.properties),
          },
        ).bindPopup(`
          <div id="mapPopupHeader">
            <span>${feature.properties.title}</span>
          </div>
          <div id="mapPopupBody">
            <div style="display: ${feature.properties.address ? 'block' : 'none'}">
              <b>Endereço:</b>
              <br>
              <span>${feature.properties.address}</span>
            </div>
            <div style="display: ${feature.properties.city ? 'block' : 'none'}">
              <b>Cidade - Estado:</b>
              <br>
              <span>${feature.properties.city} - ${feature.properties.state}</span>
            </div>
            <div>
              <b>Data Hora:</b>
              <br>
              <span>${new Date(feature.properties.date).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })}</span>
            </div>
            ${
              isIframe()
                ? ''
                : `<div style="margin-top:8px; text-align:center">
                <a href="${
                  !geoJson.properties.streetView
                    ? `https://maps.google.com/maps?layer=c&q=${lat},${lng}` +
                      `&cbll=${lat},${lng}&cbp=11,0,0,0,0&z=17&ll=${lat},${lng}`
                    : `https://maps.google.com/maps?layer=c&q=&cbll=${geoJson.properties.streetView[0]},${geoJson.properties.streetView[1]}&cbp=11,${geoJson.properties.streetView[2]},0,${geoJson.properties.streetView[4]},${geoJson.properties.streetView[3]}&panoid=${geoJson.properties.streetView[5]}`
                }"
                  target="_blank"
                  class="gs-link">
                  Ver no StreetView
                </a>
              </div>
            `
            }
          </div>
          <div id="mapPopupFooter">
            <span> Lat: ${Number(lat).toFixed(4)} </span>
            <span> Lon: ${Number(lng).toFixed(4)} </span>
          </div>
        `),
    }).getLayers()[0];
  }

  _geoJsonToLGeoJson({ geoJson, markerIcon }) {
    return L.geoJSON(geoJson, {
      style: { color: geoJson.properties.color, weight: geoJson.properties.weight },
      onEachFeature: (feature, layer) => {
        layer.bindPopup(`
          <div id="mapPopupBody" style="border-radius: 8px;">
            <div>
              <b>Distância:</b>
              <br>
              <span>${geoJson.properties.distance} Km</span>
            </div>
          </div>
        `);
      },
      pointToLayer: (feature, latlng) => {
        switch (feature.type) {
          case 'Polygon':
            return L.polygon(latlng, feature.properties);
          case 'Polyline':
            return L.polyline(latlng, feature.properties);
          case 'Rectangle':
            return L.rectangle(latlng, feature.properties);
          default:
            return feature.properties.radius
              ? L.circle(latlng, feature.properties)
              : L.marker(latlng, {
                  ...feature.properties,
                  icon: markerIcon || this._createMarkerIcon(feature.properties.color),
                });
        }
      },
    }).getLayers()[0];
  }
  /* */
}

export { GolfleetMapFuelSupplyManagementLeafletProvider };
