/* global drawImage: true */

import angular from 'angular';
import 'ng-redux';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-toolbar/power-toolbar';
import '@power/power-components/components/power-photo-indexation/power-photo-indexation';
import '@power/power-components/components/power-toast/power-toast';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-pagination/power-pagination';
import '../golfleet-grid-temp-utilization/golfleet-grid-temp-utilization';
import { PowerReportController } from '@power/power-components/components/power-report/power-report';

import template from './golfleet-report-temp-utilization.html';
import './golfleet-report-temp-utilization.scss';

class GolfleetReportTempUtilizationController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    this.legendList = [];
    this.driverList = [{ id: null, description: 'Não identificado' }, { holder: true }];
    this.driverImagePopup = {
      open: false,
      showIndex: 0,
      detailList: [],
    };

    this.toastObj = {
      text: '',
    };
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();

    this._getDriverList().then(result => {
      if (result && result.data && !result.data.hasError)
        Object.assign(this.driverList, this.driverList.concat(result.data.data));
    });

    this.$scope.$on('showPopupDriver', this.__showPopupDriver.bind(this));

    this.$scope.$watch(() => this.reportDataset, this.__reportDatasetChanged.bind(this));
  }
  /* */

  /* Public */
  changePage(page) {
    if (this.currentState.routeTail) {
      this.$scope.$broadcast('changePage', {
        page,
        payload: {
          filter: {
            conditions: this.stateConfig.filterConditions,
          },
          id: this.currentState.routeTail,
          isVehicle: true,
          isPaginated: this.stateConfig.gridConfig.backPagination,
        },
      });
    } else {
      this.$scope.$broadcast('changePage', {
        page,
        payload: {
          filter: {
            conditions: this.stateConfig.filterConditions,
          },
          isPaginated: this.stateConfig.gridConfig.backPagination,
        },
      });
    }
  }

  changeView(viewMode) {
    this.stateConfig.viewMode = viewMode;
    this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });
    switch (viewMode) {
      case 'indexation':
        this.$.setAttribute('indexation-view', '');
        this.$.removeAttribute('grid-view');
        break;
      default:
        this.$.setAttribute('grid-view', '');
        this.$.removeAttribute('indexation-view');
        break;
    }
  }
  /* */

  /* Private */
  _callAction(action) {
    switch (action.actionType) {
      case 'selectAllOrNone':
        this.$.querySelector('#report-body-indexation').selectAllOrNone();
        break;
      case 'toggleSelection':
        this.$.querySelector('#report-body-indexation').toggleSelectedItems();
        break;
      case 'multiIndexation':
        this.$.querySelector('#report-body-indexation').showIndexationOptions();
        break;
      default:
        super._callAction(action);
        break;
    }
  }

  _getDriverList() {
    return this.$http({
      url: `${this.urlApi}/Driver/GetNames`,
      method: 'POST',
    });
  }

  _setDriver(driverId) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    const driverImageDetail = this.driverImagePopup.detailList[this.driverImagePopup.showIndex];

    this.$http({
      url: `${this.urlApi}/FleetCam/IdentifyDriver`,
      method: 'POST',
      data: {
        request: {
          tmtId: driverId,
          tmsId: driverImageDetail.tmsId,
          mrvId: driverImageDetail.mrvId,
          mrvVeiId: driverImageDetail.mrvVeiId,
          mrvDataHoraExibicao: new Date(driverImageDetail.mrvDataHoraExibicao),
          evento: driverImageDetail.evento,
          latitude: driverImageDetail.latitude,
          longitude: driverImageDetail.longitude,
          imageName: driverImageDetail.driverFaces,
        },
      },
    })
      .then(
        result => {
          if (result && result.data && !result.data.hasError) {
            this.$.querySelector('power-toast#report-toast').toggle(true);
            Object.assign(this.toastObj, {
              text: 'Condutor selecionado',
            });
            this.requestDataset();
          } else {
            this.$.querySelector('power-toast#report-toast').toggle(true);
            Object.assign(this.toastObj, {
              text: 'Ops, não foi possível selecionar o Condutor',
            });
          }
        },
        error => {
          console.error(error);
          this.$.querySelector('power-toast#report-toast').toggle(true);
          Object.assign(this.toastObj, {
            text: 'Ops, não foi possível selecionar o Condutor',
          });
        },
      )
      .finally(() =>
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        ),
      );
  }

  _getDriverDescription(detail, defaultText) {
    if (!detail) return defaultText;

    const selectedDriver = detail.driverList.filter(
      driver => driver.id == detail.selectedDriver,
    )[0];

    if (selectedDriver) return selectedDriver.description;

    return defaultText;
  }
  /* */

  /* Protected */
  __showPopupDriver(evt, evtParams) {
    if (evtParams.type == 'close') {
      switch (evtParams.popupId) {
        case 'driver-image-popup': {
          this.$.querySelector(`#${evtParams.popupId}`).toggle();
          break;
        }
        default:
          break;
      }
    } else if (evtParams.type == 'open') {
      switch (evtParams.popupId) {
        case 'driver-image-popup': {
          this.$.querySelector(`#${evtParams.popupId}`).toggle();
          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: true },
              bubbles: true,
              composed: true,
            }),
          );

          this.$http({
            url: `${this.urlApi}FleetCam/GetDriversFace`,
            method: 'POST',
            data: {
              request: {
                identificationType: evtParams.dataset.tipoIdentificacao,
                beginDate: this.__parseDate(new Date(evtParams.dataset.dataHoraIgnicaoLigada)),
                endDate: this.__parseDate(
                  new Date(
                    evtParams.dataset.dataHoraFinalViagem ||
                      evtParams.dataset.dataHoraFinalViagemRegra,
                  ),
                ),
                vehicleId: evtParams.dataset.id,
              },
            },
          })
            .then(
              result => {
                Object.assign(this.driverImagePopup, {
                  open: true,
                  showIndex: 0,
                  detailList: result.data.data.map(detail =>
                    Object.assign(detail, {
                      latitude: evtParams.dataset.latitude,
                      longitude: evtParams.dataset.longitude,
                      driverList: this.__parseDriverList(detail),
                      selectedDriver: detail.mrvTmtId || null,
                      previousSelectedDriver: detail.mrvTmtId || null,
                    }),
                  ),
                });
              },
              error => {
                this.$.querySelector('power-toast#report-toast').toggle(true);
                Object.assign(this.toastObj, {
                  text:
                    error.data && error.data.data
                      ? error.data.data[0]
                      : 'Ops, ocorreu um erro ao carregar as informações',
                });
              },
            )
            .finally(() => {
              this.$.dispatchEvent(
                new CustomEvent('toggleLoader', {
                  detail: { showLoader: false },
                  bubbles: true,
                  composed: true,
                }),
              );
              const drawImageInterval = setInterval(() => {
                if (document.querySelector('#driver-image-popup-body').clientWidth) {
                  drawImage(
                    `data:image/jpeg;base64,${this.driverImagePopup.detailList[0].image.result}`,
                    document.createElement('canvas'),
                    document.querySelector('#driver-image-popup-body'),
                  );

                  if (this.session.trainingMode) {
                    const canvas = this.$.querySelector('#driver-image-popup-body > canvas');
                    canvas.classList.add('blurred');
                  }
                  clearInterval(drawImageInterval);
                }
              }, 250);
            });

          break;
        }
        default:
          break;
      }
    }
  }

  __parseDriverList(detail) {
    const driverList = Object.clone([], this.driverList);
    if (driverList.find(driver => driver.id == detail.mrvTmtId)) return driverList;
    return driverList.map(driver =>
      !driver.holder
        ? driver
        : Object.assign(driver, {
            id: detail.mrvTmtId,
            description: detail.tmtNome,
          }),
    );
  }

  __parseDate(date) {
    return new Date(date.setHours(date.getHours() - date.getTimezoneOffset() / 60));
  }
  /* */

  /* Observers */
  __reportDatasetChanged(newValue) {
    if (newValue) {
      this.legendList = this.stateConfig.gridConfig.gridHeaders.reduce(
        (acc, ele) => (ele.icons ? acc.concat(ele) : acc),
        [],
      );
      if (this.isTrip) this.$.querySelector('#report-body-indexation').getDataset(newValue);
    }
  }
  /* */
}

class GolfleetReportTempUtilization {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetReportTempUtilizationController;
  }
}

angular
  .module('golfleet-report-temp-utilization', [
    'ngRedux',
    'power-fab',
    'power-dropdown',
    'power-toolbar',
    'golfleet-grid-temp-utilization',
    'power-photo-indexation',
    'power-toast',
    'power-footer',
    'power-pagination',
  ])
  .component('golfleetReportTempUtilization', new GolfleetReportTempUtilization());
