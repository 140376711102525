import angular from 'angular';
import '../../utils/web-animations.min';

import template from './power-accordion.html';
import './power-accordion.scss';

class PowerAccordionController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$state',
      '$http',
      '$timeout',
      '$filter',
      'commonServices',
      'urlApi',
    ];
  }

  constructor($element, $scope, $state, $http, $timeout, $filter, commonServices, urlApi) {
    Object.assign(this, {
      $: $element[0],
      $scope,
      $state,
      $http,
      $timeout,
      $filter,
      commonServices,
      urlApi,
    });

    this.open = false;
    this.alwaysOpen = false;
    this.hasSublist = false;

    this._toggleSublist = null;

    this._subListNodesLength = 0;
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
    });

    this.$scope.$watch(
      () => this.hasSublist,
      hasSublist => {
        if (hasSublist) {
          this.$.setAttribute('hasSublist', '');
        } else {
          this.$.removeAttribute('hasSublist');
        }
      },
    );

    this.$scope.$watch(
      () => this.alwaysOpen,
      alwaysOpen => {
        if (alwaysOpen) {
          this.$.setAttribute('alwaysOpen', '');

          if (this._toggleSublist) {
            this.open = false;
            this._toggleSublist.cancel();
          }
        } else {
          this.$.removeAttribute('alwaysOpen');
        }
      },
    );
  }

  $onDestroy() {}
  /* */

  /* Public */
  async toggle() {
    if (!this.hasSublist && !this.alwaysOpen) return;

    this.open = !this.open;

    if (this.open) {
      this.$.setAttribute('open', '');
    } else {
      this.$.removeAttribute('open');
    }

    this._updateAnimation();

    this.$.dispatchEvent(new CustomEvent('submenuToggle', { detail: { open: this.open } }));

    await this._toggleAnimation();
  }
  /* */

  /* Private */
  _toggleAnimation() {
    return new Promise(resolve => {
      this._toggleSublist.onfinish = () => {
        this._toggleSublist.onfinish = null;
        resolve();
      };
      this._toggleSublist.reverse();
    });
  }

  _updateAnimation() {
    const itemNode = this.$.querySelector(':not([slot]');
    const subListSlotNode = this.$.querySelector('[ng-transclude="listSlot"]');
    const subListSlotedNode = this.$.querySelector('list-slot');

    if (this._subListNodesLength !== subListSlotedNode.children.length) {
      this._subListNodesLength = subListSlotedNode.children.length;

      const overflowMaxHeight = window
        .getComputedStyle(subListSlotNode)
        .maxHeight.replace(/[^0-9]/g, '');

      const minHeight = itemNode.offsetHeight;
      const maxHeight =
        minHeight + (overflowMaxHeight > 0 ? overflowMaxHeight : subListSlotedNode.offsetHeight);

      const animationTime = (calculatedTime => {
        if (calculatedTime < 96) {
          return 96;
        }
        if (calculatedTime > 500) {
          return 500;
        }
        return calculatedTime;
      })(maxHeight / 1.33);

      this._toggleSublist = this.$.animate(
        [{ maxHeight: `${maxHeight}px` }, { maxHeight: `${minHeight}px` }],
        {
          fill: 'both',
          easing: 'ease-in-out',
          duration: animationTime > 96 ? animationTime : 96,
        },
      );

      this._toggleSublist.finish();
    }
  }
  /* */

  /* Observers */
  /* */
}

class PowerAccordion {
  constructor() {
    this.template = template;
    this.bindings = {
      alwaysOpen: '=?',
      hasSublist: '=?',
    };
    this.transclude = {
      buttonSlot: '?buttonSlot',
      listSlot: '?listSlot',
    };
    this.controller = PowerAccordionController;
  }
}

angular
  .module('power-accordion', ['ngSanitize'])
  .component('powerAccordion', new PowerAccordion());

export { PowerAccordionController, PowerAccordion };
