import { combineReducers } from 'redux';
import { app } from '../app.module';
import { stateReducer, sessionReducer } from './reducers';

app.config([
  '$ngReduxProvider',
  $ngReduxProvider => {
    $ngReduxProvider.createStoreWith(
      combineReducers({
        state: stateReducer,
        session: sessionReducer,
      }),
    );
  },
]);
