/* eslint-disable no-restricted-syntax */
/* global H: true, isIframe: true */
import * as Comlink from 'comlink';
import '../../../../helpers/is-iframe/is-iframe';
import './power-map.here-provider.scss';

class PowerMapHereProvider {
  constructor(context, $element, $ngRedux, $scope, $http, urlApi) {
    Object.assign(this, { context, $: $element[0], $ngRedux, $scope, $http, urlApi });

    this.mapLayers = { default: null };
    this.clusterController = {
      marker: null,
      minZoom: 0,
      closeCluster: () => {
        this.mapLayers['open-cluster'].removeAll();
        this.clusterController.marker.ea = this.clusterController.minZoom;
        this.map.removeEventListener('tap', this.clusterController.tapEventListener, true);
        this.map.removeEventListener(
          'mapviewchange',
          this.clusterController.changeEventListener,
          true,
        );
      },
      tapEventListener: evt => {
        if (evt.target === this.map) this.clusterController.closeCluster();
      },
      changeEventListener: () => {
        const { lat, lng } = this.clusterController.marker.getPosition();
        const outsideFov = !this.map.getViewBounds().containsLatLng(lat, lng);
        if (outsideFov || this.map.getZoom() < 20) this.clusterController.closeCluster();
      },
    };
    this.bubbleController = {
      bubblePopup: null,
      eventListener: evt => {
        if ('getData' in evt.target && evt.target.getData() && evt.target.getData().bubbleContent) {
          this._openBubblePopup({
            position:
              'getPosition' in evt.target
                ? evt.target.getPosition()
                : this.map.screenToGeo(evt.currentPointer.viewportX, evt.currentPointer.viewportY),
            content: evt.target.getData().bubbleContent,
          });
        } else if (this.bubbleController.bubblePopup) this.bubbleController.bubblePopup.close();
      },
    };
    this.mapController = {
      dragEndEventListener: evt => {
        if (evt.target && 'getElement' in evt.target) {
          evt.target.getElement().style.cursor = 'grab';
        }
      },
      dragStartEventListener: evt => {
        if (evt.target && 'getElement' in evt.target) {
          evt.target.getElement().style.cursor = 'grabbing';
        }
      },
      pointerEnterEventListener: evt => {
        if (evt.target && 'getElement' in evt.target) {
          evt.target.getElement().style.cursor = 'grab';
        }
      },
      pointerLeaveEventListener: evt => {
        if (evt.target && 'getElement' in evt.target) {
          evt.target.getElement().style.cursor = 'pointer';
        }
      },
    };
    this.fullscreenController = {
      exitCallback: () => {},
      fullscreenChangeEventListener: () => {
        const trigger = this.$.querySelector('#fullscreen-trigger');
        const iconKey = trigger.lastElementChild.innerText;

        if (iconKey.indexOf('_exit') > 0) {
          [trigger.lastElementChild.innerText] = iconKey.split('_');
          this.fullscreenController.exitCallback();
        } else {
          trigger.lastElementChild.innerText += '_exit';
        }

        setTimeout(() => {
          this.resizeMap();
        }, 300);
      },
    };

    this.worker = new Worker('./power-map.here-provider.worker.js');
    this.workerServices = Comlink.wrap(this.worker);
  }

  /* Lifecycle */
  $onInit() {
    this._initializeMap();

    this.$.dispatchEvent(
      new CustomEvent('mapLoaded', {
        detail: {},
        bubbles: true,
        composed: true,
      }),
    );

    this.awaitRender().then(this.resizeMap.bind(this));
  }

  $onDestroy() {
    this.worker.terminate();
    this.map.removeEventListener('tap', this.bubbleController.eventListener, true);
    this.map.removeEventListener('dragstart', this.mapController.dragStartEventListener, true);
    this.map.removeEventListener('dragend', this.mapController.dragEndEventListener, true);
    this.map.removeEventListener('pointermove', this.mapController.pointerEnterEventListener, true);
    this.map.removeEventListener(
      'pointerleave',
      this.mapController.pointerLeaveEventListener,
      true,
    );
  }
  /* */

  /* Public */
  async awaitRender() {
    return new Promise(resolve => {
      const resizeInterval = setInterval(() => {
        if (this.$.offsetWidth > 0) {
          clearInterval(resizeInterval);
          resolve(true);
        }
      });
    });
  }

  requestGeocoding(address) {
    this.$http({
      url: `${this.urlApi}/GeoCoding/Post`,
      method: 'POST',
      data: {
        request: { street: address },
      },
    }).then(
      success => {
        if (success.status && success.status !== 200) return;
        if (success.data.data) {
          const { x: lat, y: lng } = success.data.data;

          this._removeSearchMarker();

          this._clickToLatLngMarker = new H.map.Marker({ lat, lng });
          this.map.addObject(this._clickToLatLngMarker);

          this.map.setZoom(18, true);
          this.map.setCenter({ lat, lng }, true);
        }
      },
      () => {},
    );
  }

  async renderDataset({
    dataset,
    layerName = 'default',
    type = 'FeatureGroup',
    useCluster,
    clusterColor = '#D60F2C',
  }) {
    const objectList = await this._createDatasetLayer({ type, dataset });

    if (useCluster) {
      this._createCluster({ layerName, clusterColor, objectList });
    } else if (type === 'HeatLayer') {
      this._createHeatmap({ layerName, objectList });
    } else {
      if (layerName in this.mapLayers) {
        this.mapLayers[layerName].removeAll();
      } else {
        this.mapLayers[layerName] = new H.map.Group();
        this.map.addObject(this.mapLayers[layerName]);
      }
      this.mapLayers[layerName].addObjects(objectList);
    }
  }

  removeLayers(layerNameList = ['default']) {
    if (this.bubbleController.bubblePopup) this.bubbleController.bubblePopup.close();
    layerNameList.forEach(layerName => {
      if (this.mapLayers[layerName]) {
        if ('removeAll' in this.mapLayers[layerName]) this.mapLayers[layerName].removeAll();
        else if ('getProvider' in this.mapLayers[layerName]) {
          const layerProvider = this.mapLayers[layerName].getProvider();
          if ('clear' in layerProvider) layerProvider.clear();
          else if ('setDataPoints' in layerProvider) layerProvider.setDataPoints([]);
        }
      }
    });
  }

  resizeMap() {
    if (this.map) this.map.getViewPort().resize();
  }

  async zoomTo(data, zoom = 18) {
    let geoJson = {};
    if (!('geometry' in data)) {
      [geoJson] = await this.workerServices.parseToCircleDataset({ dataset: [data] });
    } else {
      geoJson = data;
    }
    const [[lng, lat]] = geoJson.geometry.coordinates;

    if (!!lng && !!lat) {
      this.map.setZoom(zoom, true);
      this.map.setCenter({ lat, lng }, true);
    }
  }

  fitBounds(bounds) {
    const { zoom, position } = this.map.getCameraDataForBounds(bounds);
    this.map.setZoom(parseInt(zoom, 10), true);
    this.map.setCenter(position, true);
  }

  fitLayers(layerNameList = ['default'], heatLayerName) {
    if (heatLayerName) {
      this.fitBounds(this.getBounds(layerNameList, heatLayerName));
    }

    if (layerNameList.length > 0) {
      this.fitBounds(this.getBounds(layerNameList));
    }
  }

  getBounds(layerNameList = ['default'], heatLayerName) {
    const getBoundsFunctions = bounds => ({
      asArray: () => [
        bounds.ga, // SouthWest Longitude,
        bounds.ja, // SouthWest Latitude,
        bounds.ha, // NorthEast Longitude,
        bounds.ka, // NorthEast Latitude
      ],
    });

    if (heatLayerName && heatLayerName in this.mapLayers) {
      const heatLayerBounds = this.mapLayers[heatLayerName]._bounds;
      return Object.assign(heatLayerBounds, { ...getBoundsFunctions(heatLayerBounds) });
    }

    const bounds = layerNameList.reduce((acc, name) => {
      if (!this.mapLayers[name]) return acc;

      let layerBounds = this.map.getViewBounds();

      if ('getBounds' in this.mapLayers[name]) {
        layerBounds = this.mapLayers[name].getBounds();
      } else {
        layerBounds = this._getBoundsFromLatLng(this.mapLayers[name].getProvider().a.a);
      }

      if (!this.mapLayers[name] || !layerBounds) return acc;

      return this.map.getViewBounds().equals(acc) ? layerBounds : acc.mergeRect(layerBounds);
    }, this.map.getViewBounds());

    return Object.assign(bounds, { ...getBoundsFunctions(bounds) });
  }

  sortDatasetByBounds(dataset) {
    return [...dataset].sort((a, b) => {
      const aBound = new H.geo.Rect(
        a.geoJson.bbox[1],
        a.geoJson.bbox[0],
        a.geoJson.bbox[3],
        a.geoJson.bbox[2],
      );
      const bBound = new H.geo.Rect(
        b.geoJson.bbox[1],
        b.geoJson.bbox[0],
        b.geoJson.bbox[3],
        b.geoJson.bbox[2],
      );

      if (aBound.intersects(bBound)) {
        if (aBound.containsRect(bBound)) return 1;
        return -1;
      }
      return 0;
    });
  }

  getMap() {
    return this.map;
  }

  getLayer(layerName) {
    return layerName in this.mapLayers ? this.mapLayers[layerName] : this.mapLayers.default;
  }

  getDataFromLayer(layerName = 'default', filter) {
    const layerObjects = this.getLayer(layerName).getObjects();
    return !filter ? layerObjects : layerObjects.filter(filter);
  }

  openPopup(index = 0, layer = 'default') {
    if (layer in this.mapLayers && 'getObjects' in this.mapLayers[layer]) {
      setTimeout(() => {
        const object = this.mapLayers[layer].getObjects()[index];
        if (object && 'getData' in object && object.getData().bubbleContent) {
          if ('getPosition' in object) {
            this._openBubblePopup({
              position: object.getPosition(),
              content: object.getData().bubbleContent,
            });
          } else if ('getBounds' in object) {
            this._openBubblePopup({
              position: object.getBounds().getCenter(),
              content: object.getData().bubbleContent,
            });
          }
        }
      }, 700);
    }
  }

  enterFullscreen(fullscreenExitCallback) {
    if (this.$.requestFullscreen) this.$.requestFullscreen();
    else if (this.$.mozRequestFullScreen) this.$.mozRequestFullScreen();
    else if (this.$.webkitRequestFullscreen) this.$.webkitRequestFullscreen();
    else if (this.$.msRequestFullscreen) this.$.msRequestFullscreen();

    if (fullscreenExitCallback) {
      this.fullscreenController.exitCallback = fullscreenExitCallback;
    }
  }

  exitFullscreen() {
    if (document.exitFullscreen) document.exitFullscreen();
    else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
    else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
    else if (document.msExitFullscreen) document.msExitFullscreen();
  }

  getBubbleController() {
    return this.bubbleController;
  }
  /* */

  /* Private */
  _initializeMap(params = {}) {
    const { latitude, longitude } = { latitude: -15.34, longitude: -53.74, ...params };
    const { mapConfigs } = this.context;

    this.platform = new H.service.Platform({ ...mapConfigs.here });
    this.defaultLayers = this.platform.createDefaultLayers();

    this.map = new H.Map(this.$.querySelector('#map'), this.defaultLayers.normal.map, {
      zoom: 4,
      center: { lat: latitude, lng: longitude },
      noWrap: true,
    });

    this.events = new H.mapevents.MapEvents(this.map);
    this.behavior = new H.mapevents.Behavior(this.events);
    this.ui = H.ui.UI.createDefault(this.map, this.defaultLayers, 'pt-BR');

    this.ui.getControl('zoom').setAlignment('left-top');

    this.ui.addControl(
      'distancemeasurement',
      new H.ui.DistanceMeasurement({
        lineStyle: {
          strokeColor: 'rgba(40, 40, 40, .8)',
          lineWidth: 6,
        },
      }),
    );

    this.mapLayers.default = new H.map.Group();
    this.map.addObject(this.mapLayers.default);

    this.map.addEventListener('tap', this.bubbleController.eventListener, true);
    this.map.addEventListener('dragstart', this.mapController.dragStartEventListener, true);
    this.map.addEventListener('dragend', this.mapController.dragEndEventListener, true);
    this.map.addEventListener('pointermove', this.mapController.pointerEnterEventListener, true);
    this.map.addEventListener('pointerleave', this.mapController.pointerLeaveEventListener, true);

    this._addFullScreenControl();
    this._removeMapProviderLogo();

    this.$.addEventListener(
      'fullscreenchange',
      this.fullscreenController.fullscreenChangeEventListener,
      true,
    );

    Object.assign(this.map, {
      onMoveEnd: callback => {
        this.map.addEventListener('dragend', callback);
      },
    });

    return this.map;
  }

  _addFullScreenControl() {
    if (!isIframe()) {
      const zoomControl = this.ui.getControl('zoom');

      const fullscreenAction = document.createElement('div');
      fullscreenAction.id = 'fullscreen-trigger';
      fullscreenAction.className = 'H_btn';

      const iconFullscreen = document.createElement('i');
      iconFullscreen.className = 'material-icons';
      iconFullscreen.append('fullscreen');

      fullscreenAction.appendChild(iconFullscreen);
      zoomControl.getElement().appendChild(fullscreenAction);

      fullscreenAction.addEventListener('click', () => {
        if (iconFullscreen.innerText.indexOf('_exit') > 0) {
          this.exitFullscreen();
        } else {
          this.enterFullscreen();
        }
      });
    }
  }

  _removeMapProviderLogo() {
    const map = this.$.querySelector('#map');

    if (map && map.firstChild) {
      const elements = map.firstChild.childNodes;
      const target = elements[elements.length - 2];

      if (target && 'style' in target) {
        target.style.display = 'none';
      }
    }
  }

  async _createDatasetLayer({ type, dataset }) {
    let circleDataset = [];
    let geoJsonDataset = [];
    let datasetLayer = [];

    switch (type) {
      case 'Cluster':
        geoJsonDataset = await this.workerServices.parseToGeoJsonDataset({ dataset });
        datasetLayer = geoJsonDataset;
        break;
      case 'HeatLayer':
        datasetLayer = this._geoJsonToHeat({
          geoJsonList: await this.workerServices.parseToGeoJsonDataset({ dataset }),
        });
        break;
      case 'MarkerFeatureGroup':
        geoJsonDataset = await this.workerServices.parseToGeoJsonDataset({ dataset });
        datasetLayer = geoJsonDataset.map(data => this._geoJsonToMarker({ geoJson: data }));
        break;
      case 'CircleFeatureGroup':
        circleDataset = await this.workerServices.parseToCircleDataset({ dataset });
        datasetLayer = circleDataset.map(data => this._geoJsonToCircle({ geoJson: data }));
        break;
      case 'PolygonFeatureGroup':
      case 'LinestringFeatureGroup':
        geoJsonDataset = await this.workerServices.parseToGeoJsonDataset({ dataset });
        datasetLayer = geoJsonDataset.map(data => this._geoJsonToPolyline({ geoJson: data }));
        break;
      case 'RectangleFeatureGroup':
        geoJsonDataset = await this.workerServices.parseToGeoJsonDataset({ dataset });
        datasetLayer = geoJsonDataset.map(data => this._geoJsonToRectangle({ geoJson: data }));
        break;
      case 'FeatureGroup':
      default:
        circleDataset = await this.workerServices.parseToCircleDataset({ dataset });
        datasetLayer = circleDataset.map(data => this._geoJsonToLGeoJson({ geoJson: data }));
        break;
    }

    return datasetLayer;
  }

  _createHeatmap({ layerName, objectList }) {
    if (layerName in this.mapLayers) {
      const heatmapProvider = this.mapLayers[layerName].getProvider();
      heatmapProvider.clear();
      heatmapProvider.addData(objectList);
    } else {
      const heatmapProvider = new H.data.heatmap.Provider({
        colors: new H.data.heatmap.Colors(
          {
            0: '#0000FF',
            0.125: '#00FFFF',
            0.25: '#00FF00',
            0.375: '#FFFF00',
            0.45: '#FF0000',
          },
          true,
        ),
        assumeValues: false,
      });

      heatmapProvider.addData(objectList);

      this.mapLayers[layerName] = new H.map.layer.TileLayer(heatmapProvider, {
        opacity: 0.6,
      });

      this.map.addLayer(this.mapLayers[layerName]);
    }

    Object.assign(this.mapLayers[layerName], {
      _bounds: this._getBoundsFromLatLng(objectList),
    });
  }

  _createCluster({ layerName, clusterColor, objectList }) {
    const clusterDataPoints = objectList.map(object => {
      const [[lng, lat]] = object.geometry.coordinates;
      return new H.clustering.DataPoint(lat, lng, null, object);
    });

    const getClusterPresentation = cluster => {
      const marker = this._createClusterMarker({ cluster, clusterColor });
      const { lat, lng } = marker.getPosition();

      marker.addEventListener(
        'tap',
        () => {
          const maxZoom = marker.getMax();

          this.map.setZoom(maxZoom < 20 ? maxZoom + 1 : 20, true);
          this.map.setCenter({ lat, lng }, true);

          if (this.map.getZoom() === 20) {
            const translateDataPoint = index => {
              const alfa = 0.0001;
              const beta = 0.075 - Math.log(1 + 0.00025 * index);
              const teta = index / (2 + index * 0.02);
              const r = alfa * Math.E ** (beta * teta);
              return { lat: r * Math.sin(teta), lng: r * Math.cos(teta) };
            };
            let count = 0;

            if ('open-cluster' in this.mapLayers) this.mapLayers['open-cluster'].removeAll();
            else {
              this.mapLayers['open-cluster'] = new H.map.Group();
              this.map.addObject(this.mapLayers['open-cluster']);
            }

            this.clusterController.marker = marker;
            this.clusterController.minZoom = 20; // marker.getMin();
            this.clusterController.marker.ea = 20; // 19;

            this.map.addEventListener('tap', this.clusterController.tapEventListener, true);
            this.map.addEventListener(
              'mapviewchange',
              this.clusterController.changeEventListener,
              true,
            );

            cluster.forEachDataPoint(dataPoint => {
              count += 1;
              const { lat: sumLat, lng: sumLng } = translateDataPoint(count);
              const noiseMarker = this._geoJsonToMarker({ geoJson: dataPoint.getData() });
              const clusterLineStrip = new H.geo.Strip();
              clusterLineStrip.pushLatLngAlt(lat, lng);
              clusterLineStrip.pushLatLngAlt(lat + sumLat, lng + sumLng);
              noiseMarker.Ga = 20;
              noiseMarker.setPosition({ lat: lat + sumLat, lng: lng + sumLng });
              this.mapLayers['open-cluster'].addObjects([
                noiseMarker,
                new H.map.Polyline(clusterLineStrip, { min: 20 }),
              ]);
            });
          }
        },
        true,
      );

      return marker;
    };
    const getNoisePresentation = noisePoint => {
      const marker = this._geoJsonToMarker({ geoJson: noisePoint.getData() });
      marker.Ga = noisePoint.getMinZoom();
      return marker;
    };

    if (layerName in this.mapLayers) {
      const layer = this.mapLayers[layerName];

      if (typeof layer.getProvider === 'function') {
        const provider = this.mapLayers[layerName].getProvider();

        if (typeof provider.setDataPoints === 'function') {
          provider.setDataPoints(clusterDataPoints);
        }
      }
    } else {
      const clusteredDataProvider = new H.clustering.Provider(clusterDataPoints, {
        theme: { getClusterPresentation, getNoisePresentation },
        clusteringOptions: { eps: 48, minWeight: 2, strategy: 'FASTGRID' },
      });

      this.mapLayers[layerName] = new H.map.layer.ObjectLayer(clusteredDataProvider);

      this.map.addLayer(this.mapLayers[layerName]);
    }
  }

  _openBubblePopup({ position, content }) {
    if (!this.bubbleController.bubblePopup) {
      this.bubbleController.bubblePopup = new H.ui.InfoBubble(position, {
        content,
      });
      this.ui.addBubble(this.bubbleController.bubblePopup);
    } else {
      this.bubbleController.bubblePopup.setPosition(position);
      this.bubbleController.bubblePopup.setContent(content);
      this.bubbleController.bubblePopup.open();
    }

    this.map.setCenter(position, true);
  }

  _createClusterMarker({ cluster, clusterColor }) {
    const clustterSize = clusterSize => {
      if (clusterSize <= 10) return 'power-cluster-icon power-small-cluster-icon';
      if (clusterSize <= 100) return 'power-cluster-icon power-medium-cluster-icon';
      return 'power-cluster-icon power-large-cluster-icon';
    };
    let childLength = 0;
    const childCounter = entry => {
      if (entry.isCluster()) entry.forEachEntry(childCounter);
      else childLength += 1;
    };
    cluster.forEachEntry(childCounter);
    return new H.map.DomMarker(cluster.getPosition(), {
      icon: new H.map.DomIcon(
        `<div class="${clustterSize(childLength)}" style="border: 5px solid ${this._hexToRgba(
          clusterColor,
          0.2,
        )};">
            <div style="border: 5px solid ${this._hexToRgba(clusterColor, 0.5)};">
              <div style="background-color: ${this._hexToRgba(clusterColor, 1)};">
                <span>${childLength}</span>
              </div>
            </div>
          </div>`,
      ),
      min: cluster.getMinZoom(),
      max: cluster.getMaxZoom(),
    });
  }

  _createMarkerIcon({ color, icon, label }) {
    const createIcon = iconString => {
      if (!iconString) return '';
      return `
        <foreignObject x="8" y="7" width="24" height="24">
          <i class="material-icons"
            style="color: #fff;
            cursor: pointer;">
            ${iconString}
          </i>
        </foreignObject>
      `;
    };

    if (label) {
      return new H.map.DomIcon(
        `
        <div ${label ? `data-label="${label}"` : ''} style="margin: -20px 0 0 -20px;">
          <svg xmlns="https://www.w3.org/2000/svg"
            width="40"
            height="40"
          >
            <circle cx="20"
              cy="20"
              r="16"
              fill="${color || 'var(--primary-color)'}">
            </circle>
            ${createIcon(icon)}
          </svg>
        </div>
        `,
      );
    }
    return new H.map.DomIcon(
      `
      <svg xmlns="https://www.w3.org/2000/svg"
        width="40"
        height="40"
        style="margin: -20px 0 0 -20px;">
        <circle cx="20"
          cy="20"
          r="16"
          fill="${color || 'var(--primary-color)'}">
        </circle>
        ${createIcon(icon)}
      </svg>
      `,
    );
  }

  _geoJsonToMarker({ geoJson, markerIcon }) {
    const [[lng, lat]] = geoJson.geometry.coordinates;
    return new H.map.DomMarker(
      { lat, lng },
      {
        icon: markerIcon || this._createMarkerIcon(geoJson.properties),
        data: { ...geoJson.properties },
      },
    );
  }

  _geoJsonToHeat({ geoJsonList }) {
    return geoJsonList.map(item => {
      const [[lng, lat]] = item.geometry.coordinates;
      return { lat, lng };
    });
  }

  _geoJsonToCircle({ geoJson }) {
    const [[lng, lat]] = geoJson.geometry.coordinates;
    return new H.map.Circle({ lat, lng }, geoJson.properties.radius, {
      data: { ...geoJson.properties },
      style: {
        fillColor: this._hexToRgba(geoJson.properties.color, 0.33),
        strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
      },
    });
  }

  _geoJsonToPolygon({ geoJson }) {
    const [coordinates] = geoJson.geometry.coordinates;
    return new H.map.Polygon(
      coordinates.reduce((acc, coordinate) => {
        const [lng, lat] = coordinate;
        acc.pushLatLngAlt(lat, lng);
        return acc;
      }, new H.geo.Strip()),
      {
        data: { ...geoJson.properties },
        style: {
          fillColor: this._hexToRgba(geoJson.properties.color, 0.33),
          strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
        },
      },
    );
  }

  _geoJsonToMultiPolygon({ geoJson }) {
    const { coordinates } = geoJson.geometry;

    return new H.map.Polygon(
      coordinates.reduce((acc, [polygonArray]) => {
        acc.push(
          new H.geo.Polygon(
            polygonArray.reduce((lineStringAcc, latLngArray) => {
              const [lng, lat] = latLngArray;
              lineStringAcc.pushLatLngAlt(lat, lng);
              return lineStringAcc;
            }, new H.geo.LineString()),
          ),
        );
        return acc;
      }, new H.geo.MultiPolygon([])),
      {
        data: { ...geoJson.properties },
        style: {
          fillColor: this._hexToRgba(geoJson.properties.color, 0.33),
          strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
        },
      },
    );
  }

  _geoJsonToPolyline({ geoJson }) {
    return new H.map.Polyline(
      geoJson.geometry.coordinates.reduce((acc, coordinate) => {
        const [lng, lat] = coordinate;
        acc.pushLatLngAlt(lat, lng);
        return acc;
      }, new H.geo.Strip()),
      {
        data: { ...geoJson.properties },
        style: {
          lineWidth: 4,
          strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
        },
      },
    );
  }

  _geoJsonToRectangle({ geoJson }) {
    const [coordinates] = geoJson.geometry.coordinates;
    return new H.map.Rect(
      coordinates.reduce((acc, coordinate) => {
        const [lng, lat] = coordinate;
        acc.pushLatLngAlt(lat, lng);
        return acc;
      }, new H.geo.Strip()),
      {
        data: { ...geoJson.properties },
        style: {
          fillColor: this._hexToRgba(geoJson.properties.color, 0.33),
          strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
        },
      },
    );
  }

  _geoJsonToLGeoJson({ geoJson }) {
    switch (geoJson.geometry.type) {
      case 'Polygon':
        return this._geoJsonToPolygon({ geoJson });
      case 'MultiPolygon':
        return this._geoJsonToMultiPolygon({ geoJson });
      case 'Polyline':
        return this._geoJsonToPolyline({ geoJson });
      case 'LineString':
        return this._geoJsonToPolyline({ geoJson });
      case 'Rectangle':
        return this._geoJsonToRectangle({ geoJson });
      default:
        return geoJson.properties.radius
          ? this._geoJsonToCircle({ geoJson })
          : this._geoJsonToMarker({ geoJson });
    }
  }

  _hexToRgba(hex, opacity = 1) {
    const curry =
      fn =>
      (...args) =>
        fn.bind(null, ...args);
    const compose =
      (...fns) =>
      x =>
        fns.reduceRight((v, f) => f(v), x);
    const exec = curry((regex, str) => regex.exec(str));
    const replace = curry((param, fn, str) => str.replace(param, fn));
    const sumMrgb = (m, r, g, b) => r + r + g + g + b + b;
    const rgbArrToRgbString = rgbArr =>
      rgbArr
        ? 'rgba(' +
          `${parseInt(rgbArr[1], 16)}, ` +
          `${parseInt(rgbArr[2], 16)}, ` +
          `${parseInt(rgbArr[3], 16)}, ` +
          `${opacity})`
        : '0, 0, 0, 1';
    const execRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
    const replaceRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const composeHexToRgb = compose(
      rgbArrToRgbString,
      exec(execRegex),
      replace(replaceRegex, sumMrgb),
    );
    return composeHexToRgb(hex);
  }

  _getBoundsFromLatLng(latLngs) {
    const tempGroup = new H.map.Group();
    const tempMarkers = new Set();

    for (const latLng of latLngs) {
      const { lat, lng } = latLng;
      tempMarkers.add(new H.map.Marker(new H.geo.Point(lat, lng)));
    }
    tempGroup.addObjects([...tempMarkers]);

    return tempGroup.getBounds();
  }

  _removeSearchMarker() {
    const markerSearch = this.map.getObjects();
    for (let i = 0; i < markerSearch.length; i++) {
      // eslint-disable-next-line no-undef
      if (markerSearch[i] instanceof H.map.Marker) {
        if (markerSearch[i] === this._clickToLatLngMarker) this.map.removeObject(markerSearch[i]);
      }
    }
  }
  /* */

  /* Observers */
  /* */
}

export { PowerMapHereProvider };
