/* global L: true isIframe: true */
import { PowerMapLeafletProvider } from '../../../power-map/providers/leaflet/power-map.leaflet-provider';
import '../../../../helpers/is-iframe/is-iframe';
import './power-map-generated-alerts.leaflet-provider.scss';

class PowerMapGeneratedAlertsLeafletProvider extends PowerMapLeafletProvider {
  // eslint-disable-next-line no-useless-constructor
  constructor(context, $element, $ngRedux, $scope, $http, urlApi) {
    super(context, $element, $ngRedux, $scope, $http, urlApi);
  }

  /* Private */
  _geoJsonToMarker({ geoJson, markerIcon }) {
    return L.geoJSON(geoJson, {
      pointToLayer: (feature, latlng) => {
        const conditionLen = feature.properties.conditions.length;
        switch (feature.type) {
          case 'Feature': {
            return L.marker(latlng, {
              ...feature.properties,
              icon: markerIcon || this._createMarkerIcon(feature.properties),
            }).bindPopup(`
              <div class="flex-row i-center information-card-header">
                <i class="material-icons">notifications</i>
                <span>
                  ${
                    !conditionLen
                      ? 'Nenhuma condição'
                      : `${conditionLen} ${conditionLen > 1 ? 'condições' : 'condição'}`
                  }
                </span>
              </div>
              <div class="flex-column list-conditions">
                ${
                  !conditionLen
                    ? 'Nenhuma condição'
                    : feature.properties.conditions.reduce(
                        (ac, ele) => `${ac}<div class="flex-row i-center">
                  <i class="material-icons">${ele.icon}</i>
                  <span>${ele.descriptionWithValue}</span>
                </div>`,
                        '',
                      )
                }
              </div>
              <div id="mapPopupHeader">
                <span>${feature.properties.vehiclePlate}</span>
              </div>
              <div id="mapPopupBody">
                <div style="display: ${feature.properties.endereco ? 'block' : 'none'}">
                  <b>Endereço:</b>
                  <br>
                  <span>${feature.properties.address}</span>
                </div>
                <div>
                  <b>Data Hora:</b>
                  <br>
                  <span>${feature.properties.formattedDate}</span>
                </div>
                <div>
                  <b>Velocidade:</b>
                  <br>
                  <span>
                    ${feature.properties.speed}
                  </span>
                </div>
                <div>
                  <b>Condutor:</b>
                  <br>
                  <span>${feature.properties.driverName || 'Não identificado'}</span>
                </div>
                ${
                  isIframe()
                    ? ''
                    : `
                  <div style="margin-top:8px;text-align:center">
                    <a class="gs-link"
                      target="_blank"
                      href="${`https://maps.google.com/maps?layer=c&q=${latlng.lat},${latlng.lng}&cbll=${latlng.lat},${latlng.lng}&cbp=11,0,0,0,0&z=17&ll=${latlng.lat},${latlng.lng}`}">
                      Ver no StreetView
                    </a>
                  </div>
                `
                }
              </div>
              <div id="mapPopupFooter">
                <span> Lat: ${parseFloat(latlng.lat).toFixed(4)} </span>
                <span> Lon: ${parseFloat(latlng.lng).toFixed(4)} </span>
              </div>
            `);
          }
          default:
            break;
        }

        return feature;
      },
    }).getLayers()[0];
  }

  _geoJsonToLGeoJson({ geoJson, markerIcon }) {
    return L.geoJSON(geoJson, {
      style: { color: geoJson.properties.color },
      onEachFeature: (feature, layer) => {
        layer.bindPopup(`
          <div id="mapPopupHeader">
            <span>${feature.properties.description}</span>
          </div>
          <div id="mapPopupBody">
            <div>
              <b>Descrição:</b>
              <br>
              <span>${feature.properties.extendedDescription || '---'}</span>
            </div>
            <div>
              <b>Categoria:</b>
              <br>
              <span>${feature.properties.categoryName || '---'}</span>
            </div>
            <div>
              <b>Privacidade:</b>
              <br>
              <span>${feature.properties.privacy || '---'}</span>
            </div>
            <div>
              <b>Status:</b>
              <br>
              <span>${feature.properties.providerStatusDesc || '---'}</span>
            </div>
          </div>
          <div id="mapPopupFooter">
            <span> Lat: ${parseFloat(feature.properties.latitude).toFixed(4)} </span>
            <span> Lon: ${parseFloat(feature.properties.longitude).toFixed(4)} </span>
          </div>
			  `);
      },
      pointToLayer: (feature, latlng) => {
        switch (feature.type) {
          case 'Polygon':
            return L.polygon(latlng, feature.properties);
          case 'Polyline':
            return L.polyline(latlng, feature.properties);
          case 'Rectangle':
            return L.rectangle(latlng, feature.properties);
          default:
            return feature.properties.radius
              ? L.circle(latlng, feature.properties)
              : L.marker(latlng, {
                  ...feature.properties,
                  icon: markerIcon || this._createMarkerIcon(feature.properties),
                });
        }
      },
    }).getLayers()[0];
  }
  /* */
}

export { PowerMapGeneratedAlertsLeafletProvider };
