import angular from 'angular';
import moment from 'moment';
import 'ng-redux';

import '../power-toast/power-toast';
import '../../directives/ng-tippy/ng-tippy';

import template from './power-card-alert.html';
import './power-card-alert.scss';

class PowerCardAlertController {
  static get $inject() {
    return ['$element', '$scope', '$state', '$ngRedux', '$timeout', 'reportServices'];
  }

  constructor($element, $scope, $state, $ngRedux, $timeout, reportServices) {
    Object.assign(this, {
      $: $element,
      $scope,
      $state,
      $ngRedux,
      $timeout,
      reportServices,
    });

    this.__appBehavior = $ngRedux.connect(behavior => {
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];
      return Object({
        /* State Storage */
        lastState:
          behavior.state.routeList.length > 1
            ? behavior.state.routeList[behavior.state.routeList.length - 2]
            : null,
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
      });
    })(this);

    this.toastComponent = {
      open: false,
      text: '',
    };
  }

  // #region Lifecycle
  $onInit() {
    this.$scope.$on('UPDATE_ROUTE', () => this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' }));
  }

  $onDestroy() {
    this.__appBehavior();
  }
  // #endregion Lifecycle

  evtClickSwitchButtonControl(card) {
    card.updatingAlert = true;

    this.reportServices
      .genericRequest(`/${this.stateConfig.isAdm ? 'AlertAdm' : 'Alert'}/UpdateAlertStatus`, {
        id: card.id,
        deleteInativateAlert: card.active ? 1 : 0,
      })
      .then(
        success => {
          card.updatingAlert = false;
          if ((success.data && success.data.hasError) || !success.data.data) {
            this.$scope.$emit('showToast', {
              text: 'Ops! Falha ao salvar, tente novamente.',
            });
            card.active = !card.active; // Volta ao status anterior
            return;
          }

          if (success.data.data) {
            this.$scope.$emit('showToast', { text: 'Alerta atualizado!' });
            if (card.active && !card.notExpire && card.isExpired) {
              card.isExpired = false;
              card.notExpire = true;
            }
          }
        },
        error => {
          console.error(error);
          card.updatingAlert = false;
          this.$scope.$emit('showToast', {
            text: 'Ops! Falha ao salvar, tente novamente.',
          });
          card.active = !card.active; // Volta ao status anterior
        },
      );
  }

  evtClickOpenPopupAlert(card) {
    this.$scope.$emit('requestOpenPopupAlert', { id: card.id });
  }

  toDate(date, format) {
    return moment(date)
      .utcOffset(-3 - moment(date).utcOffset() / 60)
      .format(format || 'DD/MM/YYYY');
  }
}

class PowerCardAlert {
  constructor() {
    this.template = template;
    this.bindings = {
      cardDataset: '=',
    };
    this.controller = PowerCardAlertController;
  }
}

angular
  .module('power-card-alert', ['ngRedux', 'power-toast', 'ng-tippy'])
  .component('powerCardAlert', new PowerCardAlert());

export { PowerCardAlertController };
